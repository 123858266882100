<template>
    <div class="player" style="width: 100%">
      <div class="mmm" v-if="mmm"></div>
      <div class="loadding" v-show="!show">
          <a-spin />
      </div>
      <transition name="fades">
      <div v-show="show && playerHeight" class="playerWrap" style="height: 100%">
        <div class="n-top">
          <div class="header">
            <img src="../../../assets/images/icon-lenove-back.png" @click="backWay" />
            <p class="header-back" @click="backWay">返回课程详情</p>
          </div>
          <p class="course-tit text-line-1">{{course.title}}</p>
        </div>
        <div ref="nplay" class="player-inner" :style="{height: playerHeight+'px'}">
          <div class="online-icon" v-if="course.type == 'live'">在线人数：{{max_online_num}}</div>
          
          <!-- <div class="player-inner-title">
            <div class="player-back" @click="backWay()">
              <img src="../../../assets/images/back.png" alt="">
            </div>
            <p>{{course.title}}</p>
          </div> -->
          <div class="pdf" v-show="lessonOne.file_ext == 'pdf'">
              <pdf
              style="width: 100%;"
              :src="pdfUrl"
              :page="pageNum"
              :rotate="pageRotate"
              @progress="loadedRatio = $event"
              @page-loaded="pageLoaded($event)"
              @num-pages="pageTotalNum=$event"
              @error="pdfError($event)"
              @link-clicked="page = $event">></pdf>
          </div>
          <div class="prism-player" id="player-con" v-show="$store.state.playerVisible && lessonOne.file_ext != 'pdf'"></div>
          <div class="cover-mp3" v-show="lessonOne.file_ext == 'mp3'">
              <img :src="lessonOne.picture" alt="">
          </div>
          <div class="player-custom-controller" v-show="course.type == 'live' && lessonOne.live_status == 'living'">
              <img class="player-icon-controller"
              @click="toggle" :src="!playIconFlag? require('../../../assets/images/play-icon-controller.png'): require('../../../assets/images/pause-icon-controller.png')" :title="!playIconFlag? '播放': '暂停'">
              <img class="player-icon-controller"
              @click="refreshPlayer"
              style="width: 22px;height: 22px;" src="../../../assets/images/refresh-icon-controller.png" title="刷新">
          </div>
          <div class="errTips" v-show="!$store.state.playerVisible && lessonOne.file_ext != 'pdf'">
              <span>{{errTipsTxt}}</span>
              <a-button v-if="$store.state.open_trial == 0" @click="retry()" size="small">{{errBtnTxt}}</a-button>
          </div>

          <div class="player-mask" :style="{height: videoHeight? videoHeight+'px': '100%'}" v-show="abnormal">
              <div class="title-mask">
                <img src="../../../assets/images/warn_mask.png" alt="">
                <h3>学习进度异常！</h3>
              </div>
              <p>1、是否存在拖动进度条的行为</p>
              <p>2、网络不稳定，导致记录错误</p>
              <a-button
                :style="{
                  width: '100px',
                  border: 'none',
                  marginTop: '20px',
                }"
                @click="refreshPlayer()">刷新重试</a-button>
          </div>

          <!-- 播放器底部工具栏 -->
          <div class="player-bot-do">

            <!-- 视频进度状态栏 -->
            <!-- <div class="signal" v-if="statusVisible">
                <div class="signalColor" :style="{background: sigColor}"></div>
                <span v-if="signalFlag == 1" style="color: #52c41a">正常记录学习进度</span>
                <span v-else-if="signalFlag == 0" style="color: #c00">学习进度异常</span>
                <span v-else-if="signalFlag == 2" style="color: gold">正在回看</span>
                <span v-else-if="signalFlag == 3" style="color: #eee">视频暂停</span>
            </div> -->

            <!-- <div class="signal" v-if="!statusVisible">
              <span style="color: #52c41a">该视频可试看{{$store.state.trial_length}}分钟</span>
            </div> -->

            <!-- <div class="tools" v-if="lessonOne.file_ext == 'pdf'">
              <a-button style="margin-right: 15px;" @click.stop="prePage"> 上一页</a-button>
              <a-button @click.stop="nextPage"> 下一页</a-button>
              <div class="page">{{pageNum}}/{{pageTotalNum}} </div>
            </div> -->

            <!-- <div class="n-course-info" @click="openVisibleCourse">
              <img src="../../../assets/images/icon-course-introduce.png">
              <p class="">课程介绍</p>
            </div> -->

            <!-- 课堂小功能模块 -->
            <!-- <div class="player-do-btn" v-show="signalVisible">
              <a-button v-if="statusVisible" @click="openVisibleTest" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">随堂小测</a-button>
              <a-button v-if="statusVisible" @click="openVisibleQues" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">我要提问</a-button>
              <a-button @click="openVisibleCourse" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">课程介绍</a-button>
            </div> -->

          </div>
          <!-- <div class="air-msg" :style="{left: airPositionLeft+'px'}">
            <img src="../../../assets/images/warn_radius.png" alt="">
            <p>请不要拖动进度条，否则会停止记录学习进度</p>
          </div>
          <div class="air-msg" :style="{width: '170px', right: airPositionRight+'px'}">
            <img src="../../../assets/images/r.png" alt="">
            <p>课时已学习完成</p>
          </div> -->
        </div>

        <div class="player-normal-menu" v-if="course.type == 'normal'" :style="{width: sidebarWidth+'px',height: playerHeight+'px'}">
          <!-- <div class="progress-wrap" v-show="!packFlag">
            <div class="progress-title">
              <span>当前课程进度</span>
              <span>{{course_member.complete_percent}}%</span>
            </div>
            <a-progress :percent="course_member.complete_percent" strokeColor="#6AC148" :showInfo="false"/>
          </div> -->
          <div class="player-menu-title-wrap" v-show="!packFlag">
              <h3 class="player-menu-title">课程目录</h3>
          </div>
          <div class="player-normal-item-scroll" v-show="!packFlag">
            <ul class="player-normal-item-wrap">
              <li class="player-normal-item" v-for="(item, i) in chapters" :key="i">
                <h3 class="chapter" v-if="course.is_chapter">{{item.title}}</h3>
                <div class="item-wrap-flex" v-for="(items, is) in item.lessons" :key="is" @click="play(item, items, is)" :style="{background: checkId == items.id? '#0063B1': ''}">
                  <p class="n-course-tit text-line-1" :style="{color: checkId == items.id? '#fff': '#434347'}">{{items.title}}</p>
                  <p class="n-course-time" :style="{color: checkId == items.id? '#fff': '#A1A1A3'}">{{items.length_format}}</p>
                  <!-- <div class="item-title-wrap-flex">
                      <div class="item-title-wrap">
                        <img :src="checkId == items.id? require('../../../assets/images/play.png'): require('../../../assets/images/pause.png')" alt="">
                        <p :style="{color: checkId == items.id? '#0071F5': ''}">{{items.title}}</p>
                      </div>
                      <div class="sub-wrap">
                        <p class="icon" v-if="course.course_type == 'tenant_course'">{{iconFilter(items.file_ext).name}}</p>
                        <p class="course-time">需用时{{items.length_format}}</p>
                        <p v-if="items.file_ext && items.file_ext == 'pdf'" class="course-time">已学{{items.watch_time_format}}</p>
                        <p v-if="items.file_ext == 'pdf'" class="course-time">{{items.learn_page+'/'+items.pages}}</p>
                      </div>
                  </div> -->
                  <!-- <a-progress v-if="items.learn_percent > 0 && items.learn_percent < 100" type="circle" :percent="parseInt(items.learn_percent)" :width="30" strokeColor="#1D75FA" />
                  <img style="width:28px;height:28px;" v-if="items.learn_percent == 100" src="../../../assets/images/refundIcon.png" alt=""> -->
                </div>
              </li>
            </ul>
          </div>
          <!-- <div class="sidebar-btn" @click="packUp()">
            <img :src="packFlag? require('../../../assets/images/leftsidebar.png'): require('../../../assets/images/rightsidebar.png')" alt="">
          </div> -->
        </div>
        <div class="playerModules" v-if="course.type == 'live'" :style="{width: sidebarWidth+'px'}">
            <ul class="tab" v-if="!packFlag">
                <li @click="tabMethod(item, i)" :style="index == i?{background: $store.state.themeColor}:{}" v-for="(item, i) in tab" :key="i">{{item.name}}</li>
            </ul>
            <div class="player-content">
                <div class="player-list" v-show="index == 0" :style="{height: replayWrapHeight+'px'}">
                    <div @click="checkList(item, i)" class="player-item" v-for="(item, i) in replay_videos" :key="i" :style="listIndex == item.video_id? {color: $store.state.themeColor, border: `1px solid ${$store.state.themeColor}`}: {}">
                        <div class="player-item-wrap">
                          <div class="playback">
                              <span class="playback-icon" :style="listIndex == item.video_id? {borderColor: $store.state.themeColor}: {}">回放</span>
                              <p class="title">{{item.name}}</p>
                          </div>
                          <div>{{item.start_time}}</div>
                        </div>
                        <div>{{item.percent}}%</div>
                    </div>
                </div>
                <div class="talkingRoom" v-show="index == 1">
                    <div class="txtWrap">
                        <div v-for="(item, i) in talkingList" :key="i">
                            <div style="color:#666;font-size: 12px">{{item.created_at}}</div>
                            <div class="talkingItem" :style="item.user_id == from_client_id? {flexDirection: 'row-reverse'}:{}">
                                <div class="pho">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="talkingInfo" :style="item.user_id == from_client_id? {alignItems: 'flex-end'}: {}">
                                    <span class="name">{{item.username}}</span>
                                    <p class="txt">{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sendWrap">
                        <a-input @keyup.enter="sendMsg()" v-model="sendVal" class="sendTxt" placeholder="请输入消息..." />
                        <a-button @click="sendMsg()" class="sendBtn">发言</a-button>
                    </div>
                </div>
                <div class="sidebar-btn" style="left: -18px" @click="packUp()">
                  <img :src="packFlag? require('../../../assets/images/leftsidebar.png'): require('../../../assets/images/rightsidebar.png')" alt="">
                </div>
            </div>
          <div class="bot-div" style="height: 80px;background: #151515;"></div>

        </div>
      </div>
      </transition>
      <div class="n-play-foot">
          <img src="../../../assets/images/icon-course-foot.png">
        </div>
      <!-- <a-modal title="提示" :footer="null" v-model="visible" :bodyStyle="{textAlign:'center'}" width='300px'>
      <p>该课时已学习完成</p>
      <div style="margin-bottom: 30px;">
        <span style="color: red;">（{{timeout}}）</span>
        <span>秒后自动播放下一课</span>
      </div>
          <a-button class="okBtn" @click="handleOK" >确定</a-button>
      </a-modal> -->
      <a-modal title="友情提示" :footer="null" v-model="visibleThree" :bodyStyle="{textAlign:'center',padding: '30px'}" width='700px'>
          <div>
              <div class="logo">
                  <img class="img_a" :src="$store.state.logo" alt="">
              </div>
              <p class="notice">学员你好！{{$store.state.website_name}}实行严格的学习审查制度，将实时监测学员账户的学习过程及学习行为。若发现账户存在异常，我们将对该账户及相关异常数据进行记录存档，并反馈给相关部门，由此带来的处理后果由学员自行承担。{{$store.state.website_name}}提醒学员务必诚信学习，感谢支持！</p>
          </div>
          <div class="checkWrap">
              <a-checkbox @change="onChange">不再提示</a-checkbox>
          </div>
          <a-button class="okBtn" @click="handleOK3" >确定</a-button>
      </a-modal>
      <a-modal :closable="false" :maskClosable='false' title="提示" :footer="null" v-model="visibleGo" :bodyStyle="{textAlign:'center'}" width='300px'>
      <p>点击确定，继续学习</p>
          <a-button class="okBtn" @click="handleOKGo" >确定</a-button>
      </a-modal>
      <a-modal v-if="visibleTest" v-model="visibleTest" :bodyStyle="{padding: 0}" :maskClosable='false' title="随堂小测" :footer="null" width='500px'>
        <div class="test-title">
          <span>{{answerObjLength}}/</span>
          <span>&nbsp;{{questionArr.length}}</span>
        </div>
        <div class="classes-test">
          <div v-for="(item, i) in questionArr" :key="i">
            <h3>{{item.question.stem}}</h3>
            <a-radio-group v-if="is_again == 1" :name="item.question.id.toString()" :options="item.question.metas.choices" @change="onChangeRadio"/>

            <div class="answer-item" v-for="(items, is) in item.question.metas.choices" :key="is" v-if="is_again == 0">
              <span>{{items.label}}</span>
              <img class="qesIcon" v-if="item.question.answer[0] == is" src="../../../assets/images/r.png" alt="">
              <img class="qesIcon" v-else-if="item.question.answer[0] == is && item.choices.choice_status == 'right'" src="../../../assets/images/r.png" alt="">
              <img class="qesIcon" v-else-if="item.choices.choice_answer[0] == is && item.choices.choice_status == 'wrong'" src="../../../assets/images/w.png" alt="">
            </div>
          </div>

        </div>
        <div class="exam-btn-wrap">
          <a-button @click="commitExam" type="primary">{{is_again == 1?'提交':'再测一次'}}</a-button>
        </div>
      </a-modal>
      <a-modal v-model="visibleQues" :maskClosable='false' title="我要提问" :footer="null" width='500px'>
        <div class="qes-talk-wrap">
          <h3>{{lessonOne.title}}</h3>
          <div class="qes-talk-room" ref="qestalkroom">
            <div class="qes-talk-item" v-for="(item, i) in questionList" :key="i" :style="{flexDirection: item.is_teacher?'':'row-reverse'}">
              <div class="qes-people-wrap">
                  <img class="qes-talk-avatar" :src="item.user.avatar" alt="">
                  <span class="qes-talk-name">{{item.is_teacher?'老师': '我'}}</span>
              </div>
              <div class="qes-talk-info" :style="{background: item.is_teacher?'#f0f6fc': ''}">
                <p :style="{color: item.is_teacher?'#0071F5': ''}">{{item.content}}</p>
                <span>{{item.created_time}}</span>
              </div>
            </div>
          </div>
          <p class="refresh" @click="refresh">刷新</p>
          <div class="qes-do-wrap">
            <textarea class="qes-txt" v-model="askContent" placeholder="在这里输入想问老师的问题吧～"/>
            <a-button type="primary" @click="submitQestion">确定</a-button>
          </div>
        </div>

      </a-modal>
      <!-- <a-modal v-model="visibleCourse" :maskClosable='false' title="课程介绍" :footer="null" width='500px'>
        <p style="min-height: 150px;">{{course.infos}}</p>
        <div class="btn-wrap">
          <a-button @click="courseOk" type="primary">确定</a-button>
        </div>
      </a-modal> -->
      <a-modal
      v-model="photoVisible" @cancel="photoCancel" title="学习前的准备工作" footer="" :maskClosable="false" :centered="true">
        <div class="people-module">
          <div class="item-bg" v-if="open_check_photo == 1"
          :style="{background: is_id_photo == 1? '#fff1f0': '#f6ffed'}">
            <div class="people-title">
              <img :src="is_id_photo == 1?require('../../../assets/images/er.png'): require('../../../assets/images/suc.png')" alt="">
              <h3>证件照上传</h3>
            </div>
            <p>{{is_id_photo == 1? '课程已配置需证件照的证书，请前往个人中心上传证件照': '已上传'}}</p>
          </div>
          <div class="item-bg" v-if="$store.state.open_check_realname == 1"
          :style="{background: is_certification_idcard == 1? '#fff1f0': '#f6ffed'}">
            <div class="people-title">
              <img :src="is_certification_idcard == 1?require('../../../assets/images/er.png'): require('../../../assets/images/suc.png')" alt="">
              <h3>实名认证</h3>
            </div>
            <p>{{is_certification_idcard == 1? '课程已开通实名认证，请前往个人中心进行实名认证': '已上传'}}</p>
          </div>
          <a-button @click="goUser" size="large" :style="{ border: 'none'}">前往补全信息</a-button>
        </div>
      </a-modal>
      <a-modal v-model="$store.state.tryVisible" width="320px" :title="null" :footer="null" :centered="true" :maskClosable='false' :closable="false">
        <div class="try-content">
          <p v-if="!$store.state.token && lessonOne.file_ext != 'pdf'">试看已结束，请登录后继续观看！</p>
          <p v-if="$store.state.token && course.order_status == 0 && lessonOne.file_ext != 'pdf'">试看已结束，请购买后继续观看！</p>
          <p v-if="lessonOne.file_ext == 'pdf' && !$store.state.token">文档不支持试看，请登录后观看</p>
          <p v-if="lessonOne.file_ext == 'pdf' && $store.state.token && course.order_status == 0">文档不支持试看，请购买后学习！</p>
          <div class="try-btn-wrap">
            <a-button @click="tryCancel()">退出</a-button>
            <a-button v-if="!$store.state.token" @click="tryOk()" >确定</a-button>
            <a-button v-if="$store.state.token && course.order_status == 0" @click="tryBuy()" :style="{background: 'rgb(255, 134, 17)'}">立即购买</a-button>
          </div>
        </div>
        </a-modal>
      <a-modal width="300px" v-model="examVisible" :title="null" :footer="null" :closable="false" :centered="true" :maskClosable="false">
        <div class="exam-content">
          <p>恭喜！学习已完成！该课程已设置考试环节，点击前往考试</p>
          <div class="exam-do-wrap">
            <a-button @click="waiting">再等等</a-button>
            <a-button @click="doExam" :style="{border: 'none', marginLeft: '20px'}">去考试</a-button>
          </div>
        </div>
      </a-modal>
      <remote-css href="https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css"></remote-css>
      <remote-js src="https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js"></remote-js>
    </div>
</template>

<script>
import Player from '../../../common/lenovoPlayer'
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import coo from '../../../common/cookie.js'
import {
    playerDetail,
    coursePush,
    askList,
    ask,
    quizList,
    quizCommit,
    quickVerify
} from '../../../http/api'
export default {
    name: 'lenovoplayer',
    data() {
        return {
            errTipsTxt: '',
            token: '',
            platform_id: 6,
            lessonId: '',
            show: false,
            ws: {},
            course: {},
            course_member: {},
            chapters: [],
            exams: [],
            learn: {},
            lessonOne: {},
            replay_videos: [],
            player: null,
            sendVal: "",
            index: 1,
            listIndex: 0,
            tab: [
                {
                    name: '视频列表'
                },
                {
                    name: '聊天室'
                }
            ],
            from_client_id: '',
            talkingList: [],
            currentTime: '',
            timeInterval: '',
            title: "",
            visible: false,
            endFlag: true,
            visibleThree: false,
            signalFlag: -1,
            sigColor: '#151515',
            signalVisible: false,
            hostname: "",
            timeFlag: true,
            alertFlag: true,
            timeFlag2: true,
            alertFlag2: true,
            timeFlag3: true,
            alertFlag3: true,
            timeFlag4: true,
            alertFlag4: true,
            timeFlag5: true,
            alertFlag5: true,
            alertTime: '',
            visibleGo: false,
            pre_play_time: 1,
            errBtnTxt: '点击重试',
            visibleTest: false,
            visibleQues: false,
            visibleCourse: false,
            askContent: '',
            questionList: [],
            page: 1,
            page_size: 10,
            pageFlag: true,
            scrollFlag: false,
            checkId: 0,
            questionArr: [],
            answerObj: {},
            answerObjLength: 0,
            is_again: 0,
            timeArr: [],
            timeout: 5,
            liveInterval: '',
            replay_id: '',
            max_online_num: 0,
            course_lesson_id_param: '',
            photoVisible: false,
            mmm: false,
            playIconFlag: false,
            once: true,
            replayWrapHeight: 0,
            timeoutInterval: '',
            isPause: false,
            sidebarWidth: 380,
            packFlag: false,
            airPositionLeft: -340,
            airPositionRight: -170,
            videoHeight: 0,
            abnormal: false,
            fullScreenStatus: false,
            airFlag: true,
            // ↓↓↓↓-------pdf参数
            pdfUrl: "",
            pageNum: 1,
            pageTotalNum: 1,
            pageRotate: 0,
            // 加载进度
            loadedRatio: 0,
            curPageNum: 0,
            pdfCurrentTime: '',
            is_certification_idcard: null,  // 是否上传实人认证
            is_id_photo: null,              // 是否上传证件照
            open_check_photo: null,
            statusVisible: null,
            tryTimer: null,
            examVisible: false,
            playerHeight: 0
        }
    },
    components: {
        pdf,
        'remote-js': {
            render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src }})
            },
            props: {
                src: { type: String, required: true }
            }
        },
        'remote-css': {
            render(createElement) {
                return createElement('link', { attrs: { rel: 'stylesheet', href: this.href }})
            },
            props: {
                href: { type: String, required: true }
            }
        }
    },
    created() {
        this.hostname = window.location.hostname
        this.$store.commit("TRYSHOW", false)
        this.$store.commit("SHOWLOGIN", false)
        coo.setLinkCookie(this.$router.history.current.fullPath)
        //  var w = document.body.clientWidth - this.sidebarWidth - 40 - 20
        //   this.playerHeight = w * (366/652) + 42
        // this.onPageSize()
    },
    mounted() {
        if(this.userAgent(navigator.userAgent)) {
          if(!localStorage.checked || localStorage.checked == 0) {
              // this.visibleThree = true
          } else if(localStorage.checked && localStorage.checked == 1) {
              this.visibleThree = false
          }
        }

        this.title = this.$route.query.title
        this.lessonId = this.$route.query.lessonId
        this.index = 0

        this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, '')
        

        window.addEventListener("popstate", function(e) {
          localStorage.removeItem("firstPlayer")
        }, false)
        window.onresize = this.onPageSize
        this.onPageSize()
    },
    watch: {
        'course.type'(n) {
            if(n == 'live') {
                this.talking()
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timeInterval)
        this.player = null
    },
    methods: {
      onPageSize(){
        if (document.body.clientWidth < 1200) {
          this.sidebarWidth = 410;
        } else if (document.body.clientWidth < 1600) {
          this.sidebarWidth = 440;
        } else if (document.body.clientWidth < 2000) {
          this.sidebarWidth = 470;
        } else {
          this.sidebarWidth = 500
        }
        if (this.$refs.nplay) {
          var w = this.$refs.nplay.offsetWidth
          this.playerHeight = w * (366/652) + 42
        } else {
          console.log('---------');
          var w = document.body.clientWidth - this.sidebarWidth - 40 - 20
          this.playerHeight = w * (366/652) + 42
          console.log(this.playerHeight);
        }
      },
      tryBuy() {
        if(this.$store.state.token) {
          this.$router.push({
              path: '/pay',
              query: {
                course_id: this.course.id,
                course_type: this.course.course_type
              }
          })
        } else {
            this.$store.commit("SHOWLOGIN", true)
            this.$store.commit("TRYSHOW", false)
        }
      },
      tryOk() {
        this.$store.commit("SHOWLOGIN", true)
        this.$store.commit("TRYSHOW", false)
      },
      tryCancel() {
        this.$store.commit("TRYSHOW", false)
        this.$router.push({
          path: '/lenovelcourse',
          query: this.$route.query
        })
      },
      waiting() {
        this.examVisible = false
      },
      doExam() {
        this.$route.query['index'] = 1
        this.$router.push({
          path: '/lenovelcourse',
          query: this.$route.query
        })
      },
      goUser() {
        this.$router.push('/user/setuser')
      },
			prePage() {     // 上一页函数
				var page = this.pageNum
				page = page > 1 ? page - 1 : this.pageTotalNum
				this.pageNum = page
        document.querySelector('.pdf').scrollTo(0, 0)
			},
			nextPage() {    // 下一页函数
				var page = this.pageNum
				page = page < this.pageTotalNum ? page + 1 : 1
				this.pageNum = page
        document.querySelector('.pdf').scrollTo(0, 0)
			},
			pageLoaded(e) {   // 页面加载回调函数，其中e为当前页数
				this.curPageNum = e
			},
			pdfError(error) {   // 其他的一些回调函数
				console.error(error)
			},
      iconFilter(param) {
        if(param == 'mp4') {
          return {
            name: '视频',
            className: 'mp4-color icon'
          }
        } else if(param == 'mp3') {
          return {
            name: '音频',
            className: 'mp3-color icon'
          }
        } else if(param == 'pdf') {
          return {
            name: '文档',
            className: 'doc-color icon'
          }
        }
      },
      toggle() {
        if(!this.playIconFlag) {
          this.player.play()
          this.playIconFlag = true
        } else {
          this.player.pause()
          this.playIconFlag = false
        }
      },
      // 强制退出方法
      signOut() {
        this.$store.commit("DELETETOKEN")
        this.btnLoading = false
        this.$router.push({
          path: '/'
        })
        localStorage.removeItem('state')
      },
      onChangeRadio(e) {
        this.answerObj[e.target.name] = e.target.value
        this.answerObjLength = Object.keys(this.answerObj).length
      },
      commitExam() {
        if(this.is_again == 0) {
        this.answerObj = {}
        this.quizListUpdate(1)
        } else {
          quizCommit({
            course_id: this.$route.query.id,
            course_type: this.$route.query.course_type,
            lesson_id: this.lessonOne.id,
            quiz: this.answerObj
          }).then(res => {
            if(res.data.code == 200) {
              this.$message.success(res.data.message)
                this.quizListUpdate(0)
            } else {
              this.$message.error(res.data.message)
            }
          })
        }

      },
      refresh() {
        this.page = 1
        this.scrollFlag = false
        this.questionList = []
        this.questionUpdate()
      },
      questionUpdate() {
        if(this.pageFlag) {
          this.pageFlag = false
          askList({
            course_id: this.$route.query.id,
            course_type: this.$route.query.course_type,
            lesson_id: this.lessonOne.id,
            page: this.page,
            page_size: this.page_size
          }).then(res => {
            if(res.data.code == 200) {
              this.pageFlag = true
              this.page++
              res.data.data.map((item, i) => {
                this.questionList.push(item)
              })
              let wrap = document.querySelector(".qes-talk-room")
              this.$nextTick(() => {
                if(!this.scrollFlag) {
                  wrap.scrollTop = wrap.scrollHeight
                }
                this.$refs.qestalkroom.addEventListener('scroll', () => {
                  if(wrap.scrollTop == 0 && this.page <= res.data.ext.total_pages) {
                    this.scrollFlag = true
                    wrap.scrollTop = 200
                    this.questionUpdate()
                  }
                }, false)
              })
            }
          })
        }

      },
      openVisibleQues() {
        this.visibleQues = true
        this.page = 1
        this.scrollFlag = false
        this.questionList = []
        this.questionUpdate()
        let wrap = document.querySelector(".qes-talk-room")
        this.$nextTick(() => {
          wrap.scrollTop = wrap.scrollHeight
        })

      },
      submitQestion() {
        ask({
          course_id: this.$route.query.id,
          course_type: this.$route.query.course_type,
          lesson_id: this.lessonOne.id,
          content: this.askContent,
        }).then(res => {
          this.pageFlag = true
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            this.askContent = ''
            let obj = {
              content: res.data.data.content,
              created_time: res.data.data.created_time,
              user: {
                avatar: res.data.data.user.avatar
              },
              is_teacher: res.data.data.is_teacher
            }
            this.questionList.push(obj)
            let wrap = document.querySelector(".qes-talk-room")
            this.$nextTick(() => {
              wrap.scrollTop = wrap.scrollHeight
            })
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      // 温馨提示
      onChange(e) {
          if(e.target.checked) {
              localStorage.checked = 1
          } else {
              localStorage.checked = 0
          }
      },
      handleOK(e) {
          clearInterval(this.timeoutInterval)
          this.visible = false
          this.timeout = 5
          if(this.lessonOne.file_ext == 'pdf') {
            if(this.lessonOne.next_lesson.lesson_id != 0) {
              this.$router.replace({
                query: {
                  id: this.$route.query.id,
                  course_type: this.$route.query.course_type,
                  lessonId: this.lessonOne.next_lesson.lesson_id
                }
              })
              this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
            }
          } else {
            if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {  // 回放
              if(this.replayOne.next_replay_id != 0) {
                this.$router.replace({
                    query: {
                      id: this.$route.query.id,
                      course_type: this.$route.query.course_type,
                      lessonId: this.lessonOne.next_lesson.lesson_id
                    }
                })
                this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, this.replayOne.next_replay_id)
              }
            } else {  //  点播
              if(this.lessonOne.next_lesson.lesson_id != 0) {
                this.$router.replace({
                  query: {
                    id: this.$route.query.id,
                    course_type: this.$route.query.course_type,
                    lessonId: this.lessonOne.next_lesson.lesson_id
                  }
                })
                this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
              }
            }
          }
      },
      handleOK3(e) {
          this.visibleThree = false
      },
      handleOKGo(e) {
        let currentTime
        if(this.lessonOne.file_ext == 'pdf') {
          currentTime = this.pdfCurrentTime? this.pdfCurrentTime: this.learn.watch_time
        } else {
          currentTime = this.player.getCurrentTime() + this.learn.learn_time
        }
        quickVerify({
          course_id: this.course.id,
          lesson_id: this.lessonOne.id,
          play_time: currentTime,
          course_type: this.course.course_type
        }).then(res => {
          if(res.data.code == 200) {
            this.visibleGo = false
            if(this.lessonOne.file_ext != 'pdf') {
              this.player.play()
            } else {
              this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, '')
            }
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      // 聊天室初始化
      talking() {
          if(process.env.NODE_ENV == 'release' || process.env.NODE_ENV == 'development') {
              this.ws = new WebSocket("wss://chattest.adksedu.com/wss")  // 测试
          } else if(process.env.NODE_ENV == 'preview') {
              this.ws = new WebSocket("wss://chattest1.adksedu.com:7273")
          } else if(process.env.NODE_ENV == 'master') {
              this.ws = new WebSocket("wss://chat.adksedu.com/wss") // 生产
          }
          this.ws.onopen = (evt) => {
              this.token = this.$store.state.token
              this.ws.send(
                  '{"type":"login","platform_id":"' + this.platform_id + '","course_id":' + this.$route.query.id + ',"lesson_id":' + this.$route.query.lessonId + '}'
              )
          }

          this.ws.onmessage = (evt) => {
              let res = JSON.parse(evt.data)
              if(res.type == 'ping') {
                  this.ws.send(
                      '{"type": "pong"}'
                  )

              } else if(res.type == 'login') {
                  this.from_client_id = res.client_id
              } else if(res.type == 'say') {
                  let obj = {
                      username: res.from_client_name,
                      user_id: res.from_client_id,
                      avatar: res.avatar,
                      content: res.content,
                      created_at: res.time
                  }
                  this.talkingList.push(obj)
                  setTimeout(() => {
                      this.setScroll()
                  },100)

              }
          }
          this.ws.onerror = (e) => {
            console.info(e)
          }
          this.ws.onclose = (evt) => {}
      },
      // 切换点播列表
      play(item, items, is) {
          this.lessonId = items.id
          this.$router.replace({
            query: {
              id: this.$route.query.id,
              course_type: this.$route.query.course_type,
              lessonId: items.id
            }
          })
          this.updatePlayer(this.course.id, this.lessonId, this.course.course_type, '')
          this.checkId = items.id
          this.signalVisible = false
          this.endFlag = true
      },
      retry() {
          if(this.course.order_status == 1) {
            window.location.reload()
          } else if(this.course.order_status == 0) {
            this.$router.push({
              path: '/pay',
              query: {
                course_id: this.course.id,
                course_type: this.course.course_type
              }
            })
          } else {
            window.location.reload()
          }

      },
      // 浏览器限制
      userAgent(str) {
        if(str.indexOf('Chrome') != -1) {
          return true
        } else if(str.indexOf('Firefox') != -1) {
          return true
        } else if(str.indexOf('Safari') != -1) {
          return true
        } else {  // other
          return false
        }
      },
      refreshPlayer() {
        if(this.course.type == 'live' && this.lessonOne.live_status == 'done') { // 回放
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, this.replayOne.video_id)
        } else {  // 点播
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, '')
        }
      },
      // update
      updatePlayer(id, lessonId, course_type, replay_id){
          this.airPositionLeft = -340
          this.airPositionRight = -170
          try {
              this.player.dispose()
              clearInterval(this.timeInterval)
              clearInterval(this.airTimer)
          } catch (error) {
          }

          playerDetail({
              id: id,
              lesson_id: lessonId,
              course_type: course_type,
              replay_id: replay_id,
              class_id: this.$route.query.class_id
          }).then(res => {
            if(res.data.code == 200) {
                if(res.data.data.course_member.course_is_finished == 1) {
                  this.examVisible = true
                }
                let url = window.location.pathname+window.location.search
                let newUrl = url.split('&lessonId=')[0]
                url = newUrl + '&lessonId='+res.data.data.lessonOne.id
                sessionStorage.faceCourseUrl = url

                this.abnormal = false
                if(!res.data.data.learn && res.data.data.course.type != 'live' && this.$store.state.open_trial == 0) {
                    clearInterval(this.timeInterval)
                    this.endFlag = false
                    this.signalFlag = 0
                    this.sigColor = "#c00"
                    this.abnormal = true
                    this.player.pause()
                }
                this.course = res.data.data.course
                this.max_online_num = res.data.data.lessonOne.max_online_num
                this.course_member = res.data.data.course_member
                this.chapters = res.data.data.chapters
                this.exams = res.data.data.exams
                this.lessonOne = res.data.data.lessonOne
                this.lessonId = this.lessonOne.id
                this.replay_videos = res.data.data.replay_videos
                this.show = true
                this.learn = res.data.data.learn
                this.airFlag = true
                this.is_certification_idcard = res.data.data.is_certification_idcard
                this.open_check_photo = res.data.data.open_check_photo
                this.is_id_photo = res.data.data.is_id_photo
                const that = this
                setTimeout(() => {
                  console.log(that.$refs.nplay.offsetWidth);
                  var w = that.$refs.nplay.offsetWidth
                  that.playerHeight = w * (366/652) + 42
                }, 50);
                if(this.course.order_status == 1) {
                  this.statusVisible = true
                } else if(this.course.order_status == 0) {
                  this.statusVisible = false
                }
                if(this.lessonOne.file_ext == 'pdf') {
                  // pdf试看拦截 => 不支持试看
                  if(this.$store.state.open_trial == 1) {
                    if(!this.$store.state.token) {
                      this.$store.commit("TRYSHOW", true)
                      return false
                    } else {
                      if(this.course.order_status == 0) {
                        this.$store.commit("TRYSHOW", true)
                        return false
                      }
                    }
                  }
                  if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                    this.photoVisible = true
                  } else {
                    this.pageNum = 1
                    if(res.data.data.is_face == 1) {
                      if(this.$store.state.listen_user_study_face == 1) {
                        let sessionObj = JSON.parse(sessionStorage.session)
                        let session = {
                          certification: sessionObj.certification,
                          token: sessionObj.token,
                          type: 2 // 监听
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit("SETTOKEN", sessionObj.token)
                        this.$store.commit("COURSEINFO", {
                          course_id: this.course.id,
                          course_type: this.course.course_type,
                          lesson_id: this.lessonId
                        })
                        this.$store.commit("PLAYTIME", this.learn.watch_time)
                        this.$store.commit('FACEVISIBLE', true)
                      } else if(this.$store.state.listen_user_study == 1) {
                        this.visibleGo = true
                      }
                    }
                    if(this.learn.status == 'learning') {
                      this.signalFlag = 1
                      this.sigColor = "#52c41a"
                    } else {
                      this.signalFlag = 2
                      this.sigColor = 'gold'
                    }
                    let h = document.body.offsetHeight - 66 -80
                    document.querySelector(".pdf").style.height = h+'px'
                    this.pdfUrl = pdf.createLoadingTask({url: this.lessonOne.videos, CMapReaderFactory})

                    this.signalVisible = true
                    let currentTime = new Date().getTime() / 1000 - this.learn.watch_time  //当前时间开始计时
                    console.info(currentTime)
                    clearInterval(this.timeInterval)
                    if ((this.$store.state.open_valid_length == 1 && res.data.data.is_face == 0 && this.signalFlag == 2) || (this.signalFlag == 1 && res.data.data.is_face == 0 && this.$store.state.open_valid_length == 0)) {
                      
                    // if(this.signalFlag == 1 && res.data.data.is_face == 0) {
                    // if(res.data.data.is_face == 0) {  //绿色才push 且开关实人认证和证件照限制
                      this.timeInterval = setInterval(() => {
                        // if(this.course.type == 'normal' && this.learn.status == 'learning') {
                        if(this.course.type == 'normal') {
                          let pastTime = new Date().getTime() / 1000 + - currentTime
                          this.pdfCurrentTime = pastTime
                          let isFinishType
                          if(pastTime < parseFloat(this.lessonOne.length)) {
                            isFinishType = 0
                          } else {
                            isFinishType = 1
                          }
                          this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.curPageNum}`
                          // coursePush({
                          //     course_lesson_id: this.course_lesson_id_param,
                          //     play_time: this.signalFlag == 1 ? pastTime : "",
                          //     is_finish: isFinishType
                          // }).then(res => {
                          //     if(res.data.code == 200) {
                          //       this.chapters.map((item, i) => {
                          //           item.lessons.map((items, is) => {
                          //             if(items.id == this.lessonOne.id) {
                          //               items.learn_percent = res.data.data.lesson_finish_percent
                          //               this.course_member.complete_percent = res.data.data.course_finish_percent
                          //             }
                          //           })
                          //         })
                          //       if(res.data.data.course_is_finished == 1) {
                          //         this.examVisible = true
                          //       }
                          //       if(res.data.data.is_least_learn == 1) {
                          //         this.airPositionRight = 0
                          //         clearInterval(this.timeInterval)
                          //         // pdf查看进度完成，继续播放下一课件
                          //         if(this.lessonOne.next_lesson.lesson_id != 0) {
                          //           this.visible = true
                          //           this.timeoutInterval = setInterval(() => {
                          //             if(this.timeout > 1) {
                          //               this.timeout --
                          //             } else {
                          //               this.visible = false
                          //               this.timeout = 5
                          //               this.$router.replace({
                          //                 query: {
                          //                   id: this.$route.query.id,
                          //                   course_type: this.$route.query.course_type,
                          //                   lessonId: this.lessonOne.next_lesson.lesson_id
                          //                 }
                          //               })
                          //               this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
                          //               clearInterval(this.timeoutInterval)
                          //             }
                          //           }, 1000)
                          //         }
                          //       }
                          //     } else if(res.data.code == 111) {
                          //       clearInterval(this.timeInterval)
                          //       if(this.$store.state.listen_user_study_face == 1) {
                          //         let sessionObj = JSON.parse(sessionStorage.session)
                          //         let session = {
                          //           realname: sessionObj.realname,
                          //           idcard: sessionObj.idcard,
                          //           certification: sessionObj.certification,
                          //           token: sessionObj.token,
                          //           type: 2 // 监听
                          //         }
                          //         let sessionStr = JSON.stringify(session)
                          //         sessionStorage.setItem('session', sessionStr)
                          //         this.$store.commit("SETTOKEN", sessionObj.token)
                          //         this.$store.commit("PLAYTIME", this.pdfCurrentTime)
                          //         this.$store.commit('FACEVISIBLE', true)
                          //       } else if(this.$store.state.listen_user_study == 1) {
                          //         this.visibleGo = true
                          //       }
                          //     } else {
                          //       this.signalFlag = 0
                          //       this.sigColor = "#c00"
                          //       clearInterval(this.timeInterval)
                          //       this.endFlag = false
                          //       this.abnormal = true
                          //     }
                          // }).catch(err => {
                          //   this.signalFlag = 0
                          //   this.sigColor = "#c00"
                          //   clearInterval(this.timeInterval)
                          //   this.endFlag = false
                          //   this.abnormal = true
                          // })
                        }
                      }, 10000)
                    }
                  }
                }
                if(res.data.data.course.type == 'normal') {
                    this.checkId = this.lessonOne.id
                    if(this.$store.state.open_trial == 0) {  // 不支持试看
                      if(res.data.data.course.order_status == 0) {
                        this.$store.commit("PLAYERVISIBLE", false)
                        this.errTipsTxt = "请购买后再进行学习"
                        this.errBtnTxt = "立即购买"
                        return false
                      } else if(res.data.data.course.order_status == 2) {
                        this.errTipsTxt = "课程正在退款中...不能观看！"
                        this.$store.commit("PLAYERVISIBLE", false)
                      }
                      if(res.data.data.lessonOne.videos == "") {
                          this.$store.commit("PLAYERVISIBLE", false)
                          this.errTipsTxt = "该课程已下架..."
                          return false
                      }
                      if(res.data.data.learn) {
                        if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                          this.photoVisible = true
                        } else {
                          let player = new Player()   // 普通视频
                          this.player = player.init(this.lessonOne.videos)
                        }
                      } else {
                        setTimeout(() => {
                          this.$store.commit("PLAYERVISIBLE", false)
                          this.errTipsTxt = "课程数据初始化中...请稍后再试"
                        }, 1000)
                      }
                    } else if(this.$store.state.open_trial == 1) {   //  可以试看 => 生成播放器
                      if(this.$store.state.token) {
                        if(this.course.order_status == 1) {
                          if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                            this.photoVisible = true
                          } else {
                            let player = new Player()   // 普通视频
                            this.player = player.init(this.lessonOne.videos)
                          }
                        } else if(this.course.order_status == 0) {
                            let player = new Player()   // 普通视频
                            this.player = player.init(this.lessonOne.videos)
                        }
                      } else {
                        let player = new Player()   // 普通视频
                        this.player = player.init(this.lessonOne.videos)
                      }
                    }
                } else if (res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                    this.replayOne = res.data.data.replayOne
                    this.listIndex = this.replayOne.video_id
                    this.$store.commit("PLAYERVISIBLE", true)
                    this.tab = [
                        {
                            name: '视频列表'
                        },
                        {
                            name: '聊天室'
                        }
                    ]
                    this.talkingList = res.data.data.chats
                    setTimeout(() => {
                        this.setScroll()
                    },100)
                    if(res.data.data.learn) {
                      let player = new Player()   // 直播回放
                      this.player = player.init(res.data.data.replayOne.source)
                    } else {
                      setTimeout(() => {
                        this.$store.commit("PLAYERVISIBLE", false)
                        this.errTipsTxt = "回放数据初始化中...请稍后再试"
                      }, 1000)
                    }

                    this.index = 0
                    this.$nextTick(() => {
                      this.replayWrapHeight = document.querySelector('.player-content').offsetHeight - 10
                    })
                } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'living') {
                    this.$store.commit("PLAYERVISIBLE", true)
                    this.tab = [
                        {
                            name: '聊天室'
                        }
                    ]
                    let player = new Player(true)   // 直播
                    this.player = player.init(res.data.data.lessonOne.streaming_url_artc+'&aliyun_uuid='+this.$store.state.uuid)
                    this.index = 1
                } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'default') {
                    this.$store.commit("PLAYERVISIBLE", false)
                    this.errTipsTxt = "直播未开始！"
                }

                if(this.lessonOne.file_ext != 'pdf') {
                  this.player.on('ready',() => {
                    if(res.data.data.is_face == 1) {
                      if(this.$store.state.listen_user_study_face == 1) {
                        let sessionObj = JSON.parse(sessionStorage.session)
                        let session = {
                          certification: sessionObj.certification,
                          token: sessionObj.token,
                          type: 2 // 监听
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit("SETTOKEN", sessionObj.token)
                        this.$store.commit("PLAYTIME", this.learn.watch_time)
                        this.$store.commit("COURSEINFO", {
                          course_id: this.course.id,
                          course_type: this.course.course_type,
                          lesson_id: this.lessonId
                        })
                        this.$store.commit('FACEVISIBLE', true)
                        this.player.pause()
                      } else if(this.$store.state.listen_user_study == 1) {
                        this.visibleGo = true
                      }
                    }
                    this.$store.commit("PLAYERVISIBLE", true)

                    let videoElement = document.querySelector("video")

                    if(sessionStorage.vol) {
                      videoElement.volume = sessionStorage.vol
                    } else {
                      sessionStorage.vol = videoElement.volume
                    }
                    videoElement.addEventListener("volumechange", function() {
                      console.info(videoElement.volume+'change')
                      sessionStorage.vol = videoElement.volume
                    })
                    if(this.hostname != 'desikai.bjadks.com') {
                      if(!this.userAgent(navigator.userAgent)) {
                        localStorage.removeItem("firstPlayer")
                        this.mmm = true
                        this.player.pause()
                        let prompt = confirm("为保证上课体验，本站仅支持使用以下浏览器学习：谷歌Chrome, 火狐Firefox, 微软Edge，点击“确定”跳转下载浏览器页面。")
                        if(prompt || !prompt) {
                          this.$router.back(-1)
                        }
                      }
                    }
                  })
                  this.player.on('pause', () => {
                    this.playIconFlag = false
                    if(this.signalFlag == 0) {  // 当出现异常时，暂停来源为false 避免刷新重试时不进入seek
                      this.isPause = false
                    } else {
                      this.isPause = true
                    }
                    clearInterval(this.timeInterval)
                    if(this.signalFlag != 0) {
                      this.signalFlag = 3
                      this.sigColor = "#eee"
                    }
                  })
                  this.player.on('play', () => {
                      this.videoHeight = document.querySelector(".prism-player").offsetHeight
                      this.playIconFlag = true

                      // 试看x分钟计时器
                      if(this.course.order_status == 0) {
                        // clearInterval(this.tryTimer)
                        // this.tryTimer = setInterval(() => {
                        //   if(this.player.getCurrentTime() > this.$store.state.trial_length*60) {
                        //     this.player.pause()
                        //     clearInterval(this.tryTimer)
                        //     this.$store.commit("TRYSHOW", true)
                        //   }
                        // }, 1000)
                      }

                      if(this.course.type != 'live') {
                          this.signalVisible = true
                      } else if(this.course.type == 'live' && this.lessonOne.live_status == 'living') {
                          clearInterval(this.liveInterval)
                          this.liveInterval = setInterval(() => {
                            // coursePush({
                            //     course_lesson_id: `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}`,
                            //     play_time: this.player.getCurrentTime() + this.learn.learn_time,
                            //     is_finish: 0
                            // }).then(res => {
                            //     if(res.data.code == 200) {
                            //       this.signalFlag = 1
                            //       this.sigColor = "#52c41a"
                            //       this.max_online_num = res.data.data.max_online_num
                            //     } else {
                            //       this.signalFlag = 0
                            //       this.sigColor = "#c00"
                            //       this.abnormal = true
                            //       this.player.pause()
                            //       clearInterval(this.liveInterval)
                            //       this.player.fullscreenService.cancelFullScreen()
                            //     }

                            // }).catch(err => {
                            //   clearInterval(this.liveInterval)
                            // })
                          }, 10000)
                      }
                      // 点播和直播回放
                      if(res.data.data.course.type == 'normal' || res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                          if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {  // 回放
                            if(this.replayOne.percent == 100) {
                              this.signalFlag = 2
                              this.sigColor = 'gold'
                            } else {
                              this.signalFlag = 1
                              this.sigColor = "#52c41a"
                            }
                            if(this.replayOne.percent == 0 && this.airFlag) {  // 第一次播放气泡提示
                              this.airPositionLeft = 0
                              this.airFlag = false   // 只弹起一次，避免暂停后继续播放再次弹出
                              this.airTimer = setTimeout(() => {
                                this.airPositionLeft = -340
                              }, 5000)
                            }
                            this.replay_id = this.replayOne.video_id
                            if(this.replayOne.percent != 10 && this.replayOne.percent != 100 && !this.isPause) {   // isPause是否暂停后播放
                              if(!this.fullScreenStatus) {
                                this.player.seek(this.replayOne.watch_time)
                              }
                            } else {
                              this.player.play()
                            }
                          } else {  //  点播
                          
                            if(this.lessonOne.learn_percent == 100) {
                              this.signalFlag = 2
                              this.sigColor = 'gold'
                            } else {
                              this.signalFlag = 1
                              this.sigColor = "#52c41a"
                            }
                            if(this.lessonOne.learn_percent == 0 && this.airFlag && this.$store.state.open_trial == 0) {  // 第一次播放气泡提示
                              this.airPositionLeft = 0
                              this.airFlag = false   // 只弹起一次，避免暂停后继续播放再次弹出
                              this.airTimer = setTimeout(() => {
                                this.airPositionLeft = -340
                              }, 5000)
                            }
                            if(this.lessonOne.learn_percent != 0 && this.lessonOne.learn_percent != 100 && !this.isPause) {
                              console.info(this.fullScreenStatus)
                              if(!this.fullScreenStatus) {
                                this.player.seek(this.learn.watch_time)
                              }
                            } else {
                              this.player.play()
                            }
                          }
                          this.isPause = false
                          clearInterval(this.timeInterval)
                          if ((this.$store.state.open_valid_length == 1 && this.course.order_status == 1 && this.signalFlag == 2) || (this.signalFlag == 1 && this.course.order_status == 1)) {

                          // if(this.course.order_status == 1) {  //绿色才push
                            this.timeInterval = setInterval(() => {
                              if(res.data.data.course.type == 'normal') {
                              // if(res.data.data.course.type == 'normal' && this.learn.status == 'learning') {
                                this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}`
                              } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                                this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.replay_id}`
                              }
                              var params = {
                                  course_lesson_id: this.course_lesson_id_param,
                                  play_time: this.player.getCurrentTime(),
                                  is_finish: 0
                              }
                              if (this.signalFlag == 2) {
                                params = {
                                  course_lesson_id: this.course_lesson_id_param,
                                  is_finish: 0,
                                  play_time: 10
                                }
                              }

                              // coursePush(params).then(res => {
                              //     if(res.data.code == 200) {
                              //       if(this.player.getCurrentTime() > this.learn.watch_time) {
                              //         this.signalFlag = 1
                              //         this.sigColor = "#52c41a"
                              //       }
                              //       this.pre_play_time = res.data.data.pre_play_time
                              //       this.learn.watch_time = res.data.data.pre_play_time
                              //       if(res.data.data.is_least_learn == 1) {
                              //         this.airPositionRight = 0
                              //         clearInterval(this.timeInterval)
                              //       }
                              //       this.chapters.map((item, i) => {
                              //         item.lessons.map((items, is) => {
                              //           if(items.id == this.lessonOne.id) {
                              //             items.learn_percent = res.data.data.lesson_finish_percent
                              //             this.course_member.complete_percent = res.data.data.course_finish_percent
                              //           }
                              //         })
                              //       })
                              //     } else if(res.data.code == 111) {
                              //       clearInterval(this.timeInterval)
                              //       if(this.$store.state.listen_user_study_face == 1) {
                              //         let sessionObj = JSON.parse(sessionStorage.session)
                              //         let session = {
                              //           realname: sessionObj.realname,
                              //           idcard: sessionObj.idcard,
                              //           certification: sessionObj.certification,
                              //           token: sessionObj.token,
                              //           type: 2 // 监听
                              //         }
                              //         let sessionStr = JSON.stringify(session)
                              //         sessionStorage.setItem('session', sessionStr)
                              //         this.$store.commit("SETTOKEN", sessionObj.token)
                              //         this.$store.commit("PLAYTIME", this.player.getCurrentTime())
                              //         this.$store.commit('FACEVISIBLE', true)
                              //       } else if(this.$store.state.listen_user_study == 1) {
                              //         this.visibleGo = true
                              //       }
                              //       this.player.pause()
                              //     } else {
                              //       this.player.fullscreenService.cancelFullScreen()
                              //       this.signalFlag = 0
                              //       this.sigColor = "#c00"
                              //       this.abnormal = true
                              //       this.player.pause()
                              //       clearInterval(this.timeInterval)
                              //       this.endFlag = false
                              //     }
                              // })
                            }, 10000)
                          }
                      }
                  })
                  this.player.on('ended', () => {
                      clearInterval(this.timeInterval)
                      if (this.$store.state.open_valid_length == 1 && res.data.data.course.type == 'normal' && this.learn.status == 'finished') {
                        // coursePush({
                        //       course_lesson_id: this.course_lesson_id_param,
                        //       play_time: (this.player.getCurrentTime() % 10).toFixed(2),
                        //       is_finish: 1
                        //   }).then(res => {

                        //   }).catch(err => {

                        //   })
                      }
                      if(res.data.data.course.type == 'normal' && this.learn.status == 'learning' && this.endFlag || res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done' && this.endFlag)  {
                        if(res.data.data.course.type == 'normal' && this.learn.status == 'learning') {
                          this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}`
                        } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                          this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.replay_id}`
                        }
                        if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done' && this.replayOne.percent == 100) {  //回放学完100%

                        } else if(res.data.data.course.type == 'normal' && this.learn.status == 'learning' && this.learn_percent == 100) {  // 点播学完100%

                        } else {
                          // coursePush({
                          //     course_lesson_id: this.course_lesson_id_param,
                          //     play_time: this.player.getCurrentTime(),
                          //     is_finish: 1
                          // }).then(res => {
                          //     if(res.data.code == 200) {
                          //       if(res.data.data.is_least_learn == 1) {
                          //         this.airPositionRight = 0
                          //         clearInterval(this.timeInterval)
                          //         if(res.data.data.course_is_finished == 1) {
                          //           this.examVisible = true
                          //         }
                          //       }
                          //       this.chapters.map((item, i) => {
                          //         item.lessons.map((items, is) => {
                          //           if(items.id == this.lessonOne.id) {
                          //             items.learn_percent = res.data.data.lesson_finish_percent
                          //             this.course_member.complete_percent = res.data.data.course_finish_percent
                          //           }
                          //         })
                          //       })
                          //     }
                          // }).catch(err => {

                          // })
                        }
                      }
                      if(this.hostname != "iteq.zckt.tv") {
                        this.visible = true
                        this.timeoutInterval = setInterval(() => {
                          if(this.timeout > 1) {
                            this.timeout --
                          } else {
                            this.visible = false
                            this.timeout = 5
                            if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {  // 回放
                              if(this.replayOne.next_replay_id != 0) {
                                this.$router.replace({
                                  query: {
                                    id: this.$route.query.id,
                                    course_type: this.$route.query.course_type,
                                    lessonId: this.lessonOne.next_lesson.lesson_id
                                  }
                                })
                                this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, this.replayOne.next_replay_id)
                              }
                            } else {  //  点播
                              console.info('进入点播')
                              if(this.lessonOne.next_lesson.lesson_id != 0) {
                                this.$router.replace({
                                  query: {
                                    id: this.$route.query.id,
                                    course_type: this.$route.query.course_type,
                                    lessonId: this.lessonOne.next_lesson.lesson_id
                                  }
                                })
                                this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
                              }
                            }
                            clearInterval(this.timeoutInterval)
                          }
                        }, 1000)
                      }
                  })
                  this.player.on('startSeek', (data) => {
                      this.currentTime = this.player.getCurrentTime()
                  })
                  this.player.on('requestFullScreen', () => {
                    console.info('进入全屏')
                    this.fullScreenStatus = true
                  })
                  this.player.on('cancelFullScreen', () => {
                    console.info('退出全屏')
                    this.fullScreenStatus = false
                  })
                  this.player.on('completeSeek', (data) => {
                    console.info(data.paramData)
                    if(this.course.order_status == 1) {
                      // 往后拖动不再推进度
                      // if(data.paramData > this.currentTime && this.signalFlag != 2) {
                      //     this.player.fullscreenService.cancelFullScreen()
                      //     clearInterval(this.timeInterval)
                      //     this.endFlag = false
                      //     this.signalFlag = 0
                      //     this.sigColor = "#c00"
                      //     this.abnormal = true
                      //     this.player.pause()
                      // }
                    } else if(this.course.order_status == 0) {
                      // if(data.paramData > this.$store.state.trial_length * 60) {
                      //   this.player.pause()
                      //   this.$store.commit("TRYSHOW", true)
                      // }
                    }
                      

                  })
                  this.player.on('error', error => {
                      this.$store.commit("PLAYERVISIBLE", false)
                      this.errTipsTxt = "视频中断，请检查网络！"
                  })
                }

            }
          }).catch(err => {
              this.show = true
          })
      },
      photoHandleOk() {
        this.photoVisible = false
        this.$router.push('/user/setuser')
      },
      photoCancel() {
        this.photoVisible = false
        this.$router.go(-1)
      },
      // 切换回放列表
      checkList(item, i) {
          this.listIndex = item.video_id
          this.replay_id = item.video_id
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, item.video_id)
      },
      tabMethod(item, i) {
          if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {
            this.index = i
          }
      },
      // 发送聊天消息
      sendMsg() {
          if(this.$store.state.token) {
              if(this.sendVal != "") {
                  if(this.lessonOne.live_status == 'done') {
                      this.$message.error("直播已结束，不能发言")
                  } else {
                      this.ws.send(
                          '{"type": "say","to_client_id": "all","content":"'+this.sendVal+'", "access_token": "'+this.token.replace('Bearer ', "")+'"}'
                      )
                      this.sendVal = ""
                  }

              } else {
                  this.$message.error("不能发送空消息！")
              }
          } else {
              this.$store.commit("SHOWLOGIN", true)
          }
      },
      // 聊天室设置滚动条到最底部
      setScroll() {
          let d = document.querySelector(".txtWrap")
          d.scrollTop = d.scrollHeight - d.clientHeight
      },
      backWay() {
        localStorage.removeItem("firstPlayer")
        let params = window.location.search.split('&lessonId=')[0]
        this.$router.push({
          path: '/lenovelcourse'+params
        })
      },
      openVisibleTest() {
        this.quizListUpdate(0)
      },
      quizListUpdate(n) {
        quizList({
          course_id: this.$route.query.id,
          course_type: this.$route.query.course_type,
          lesson_id: this.lessonOne.id,
          is_again: n
        }).then(res => {

          if(res.data.code == 200) {
            this.questionArr = res.data.data.data
            this.is_again = res.data.data.is_again
            this.visibleTest = true
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      openVisibleCourse() {
        this.visibleCourse = true
      },
      courseOk() {
        this.visibleCourse = false
      },
      packUp() {
        if(!this.packFlag) {
          if(this.course.type == 'live') {
            this.sidebarWidth = 0
          } else {
            this.sidebarWidth = 18
          }
          this.packFlag = true
        } else {
          this.sidebarWidth = 380
          this.packFlag = false
        }
      }
    },
    destroyed() {
      this.$store.commit("FACEVISIBLE", false)
    }
}
</script>

<style scoped>

.n-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #f3f3f3;
}

.header img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.header p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.course-tit {
  width: 100%;
  padding: 0 26px;
  height: 64px;
  line-height: 64px;
  color: #2C2B31;
  font-size: 18px;
  font-weight: bold;
}

.n-course-info {
  display: flex;
  cursor: pointer;
}

.n-course-info img {
  width: 20px;
  height: 20px;
}

.n-course-info p {
  columns: #434347;
  font-size: 14px;
  margin-left: 5px;
}

.n-play-foot {
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #F3F3F3;
  margin-top: 20px;
}

.n-play-foot img {
  width: 416px;
  height: 38px;
}






.player-mask {
  position: absolute;
  left: 0;
  top: 66px;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 99999999999999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.title-mask img{
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.title-mask h3 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
}
.player-mask p {
  color: #fff;
}
.air-msg {
  width: 340px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 140px;
  padding: 5px 10px;
  transition: all .5s;
}
.air-msg img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.air-msg p {
  font-size: 24rpx;
  color: #888;
  margin-bottom: 0;
}
.player-item-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.player-custom-controller {
  position: absolute;
  left: 75px;
  bottom: 80px;
  z-index: 9999999999999999;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-icon-controller {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 5px;
}
.player {
    width: 1200px;
    margin: 0 auto;
    padding: 0!important;
}
.player .ant-breadcrumb{
    text-align: left;
    margin: 10px 0;
    width: 450px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.player .errTips {
    width: 100%;
    height: 100%;
    background: #333;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.player .errTips span {
  margin-bottom: 10px;
}
.player .errTips button {
  border: none;
}
.player .playerWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    /* padding-top: 130px; */
    padding: 130px 20px 0;
}
.online-icon {
  background: rgba(0, 0, 0, .5);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7F000000,endcolorstr=#7F000000);
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 76px;
  padding: 5px 8px;
  border-radius: 4px;
  z-index: 99999999999999;
}
.player-inner {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.prism-player {
  flex: 1;
}
.player-normal-menu {
  background: #F8FAFF;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin-left: 20px;
  box-sizing: border-box;
  position: relative;
  transition: all .5s;
  border-radius: 4px;
}
.progress-wrap {
  padding: 30px 20px;
}
.progress-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 16px;
  margin-bottom: 20px;
}
.player-menu-title-wrap {
  height: 36px;
  padding-left: 20px;
  text-align: left;
  padding: 0 8px;
  /* margin-bottom: 15px; */
}
.player-menu-title {
  width: auto;
  color: #A1A1A3;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  font-size: 14px;
}
.player-normal-item {
  padding-bottom: 10px;
}
.player-normal-item-scroll {
  flex: 1;
  display: flex;
  height: 0;
}
.player-normal-item-wrap {
  padding: 0 10px;
  flex: 1;
  overflow-y: auto;
}
.player-normal-item .chapter {
  color: #999;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  text-align: left;
  margin-bottom: 15px;
}
.item-wrap-flex {
  padding: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  height: 36px;
  border-radius: 4px;
}
.n-course-tit {
  color: #434347;
  font-size: 14px;
  flex: 1;
}
.n-course-time {
  text-align: left;
  padding-left: 10px;
  color: #A1A1A3;
  font-size: 12px;
  height: 36px;
  margin-bottom: 0;
  line-height: 36px;
  flex-shrink: 0;
}

.item-title-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.item-title-wrap img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.item-title-wrap p {
  color: #999;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  height: 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}
/* .player-menu-title::before {
  content: '';
  width: 4px;
  border-radius: 2px;
  left: -10px;
  top: 4px;
  height: 12px;
  background: #3091FD;
  display: block;
  position: absolute;
} */
.player-inner-title {
  background: #151515;
  height: 66px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  z-index: 9999999999999999;
}
.player-back {
  cursor: pointer;
  width: 100px;
  background: #212121;
  height: 66px;
  line-height: 66px;
}
.player-back img {
  width: 32px;
  height: 32px;
}
.player-inner-title p {
  font-size: 16px;
  color: #666;
  margin: 0 0 0 20px;
}
.player-bot-do {
  background: #fff;
  height: 40px;
  min-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  position: relative;
  border-left: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;
  border-right: 1px solid #F3F3F3;
}
.player-do-btn button {
  background: #151515!important;
  border: 1px solid #fff!important;
  margin-left: 20px;
  height: 36px;
  width: 98px;
}
.player-do-btn button:hover {
  background: #0071F5!important;
  border: none!important;
}
.player .playerModules {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9;
    position: relative;
    transition: all .5s;
}
.player .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid #eee;
    background: #fff;
}
.player .tab li {
    flex: 1;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: all .2s;
}
.player .player-content {
    flex: 1;
    background: #e9ebf5;
    padding-bottom: 10px;
}
.checkWrap {
    margin-bottom: 10px;
}
.player .player-list{
    /* height: 100%; */
    padding:8px;
    overflow: auto;
}
.player .player-item {
    background: #fff;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;
    margin-bottom: 10px;
}
.player .playback {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.player .playback .playback-icon {
    display: block;
    width: 46px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid #aaa;
    margin-right: 10px;
    font-size: 12px;
}
.player .playback .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.classes-test::-webkit-scrollbar, .player-normal-item-wrap::-webkit-scrollbar, .qes-talk-room::-webkit-scrollbar, .txtWrap::-webkit-scrollbar, .player-list::-webkit-scrollbar {
    width:3px;
}
.classes-test::-webkit-scrollbar-thumb, .player-normal-menu::-webkit-scrollbar-thumb, .qes-talk-room::-webkit-scrollbar-thumb, .txtWrap::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
    background-color:#aaa;
}
.player .talkingItem {
    display: flex;
    justify-content: flex-start;
}
.player .talkingInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.player .talkingRoom {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.player .talkingRoom .pho {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}
.player .talkingInfo .name {
    font-weight: bold;
    line-height: 14px;
    padding: 18px 10px;
    display: block;
}
.player .talkingInfo .txt {
    background: #fff;
    border-radius: 6px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 0;
}
.player .sendWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.player .txtWrap {
    flex: 1;
    overflow: auto;
    padding: 8px;
    max-height: 80vh;
}
.player .txtWrap>div {
  padding-bottom: 40px;
}
.player .sendBtn {
    margin-left: 8px;
    height: 40px;
    color: #fff;
    width: 80px;
}
.player .sendTxt {
    height: 40px;
    flex: 1;
}
.logo {
    width: 140px;
    height: 50px;
    margin: 0 auto 30px;
}
.notice {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    text-indent: 2em;
    color: #333;
}
.answer-item {
  background: #Fff;
  padding:15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.player .top {
    height: 68px;
    background: rgb(251, 191, 48);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 18px;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.player .top p {
    color: rgb(167, 118, 0);
    margin-bottom: 0;
    margin-right: 30px;
}
.player .top a {
    color: #fff;
}
.player .noMsgBtn {
    width: 100px;
    height: 32px;
    background: #fff;
    color: rgb(167, 118, 0)!important;
    border-radius: 16px;
    font-size: 16px;
    cursor: pointer;
    border: 0;
}
.signal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 9999;
    min-width: 350px;
}
.signalColor {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}
.btn-wrap {
  text-align: center;
}
.qes-talk-wrap {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.qes-talk-room {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}
.qes-do-wrap {
  border-radius: 6px;
  width: 440px;
  height: 110px;
  border: 1px solid #E1E8EB;
}
.qes-talk-avatar {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-bottom: 5px;
}
.qes-talk-info {
  margin-top: 17px;
  background: #FAFAFA;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  flex: 1;
}
.qes-talk-info p {
  color: #666;
}
.qes-talk-info span {
  color: #999;
}
.qes-people-wrap {
  min-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qes-talk-name {
  font-size: 12px;
}
.qes-talk-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.qes-do-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding:10px;
}
.qes-do-wrap .qes-txt {
  flex: 1;
  height: 100%;
  outline: none;
  border: none;
  resize: none;
}
.qes-do-wrap button {
  width: 88px;
  height: 36px;
}
.test-title {
  background: #FAFAFA;
  padding: 20px 20px 0;
}
.qesIcon {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.test-title>span {
  color: #1D75FA;
}
.test-title>span:first-child {
  font-size: 22px;
}
.classes-test {
  height: 480px;
  overflow-y: auto;
  background: #FAFAFA;
  padding:20px;
}
.classes-test>h3 {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.refresh {
  font-size: 16px;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}
.exam-btn-wrap {
  text-align: center;
  padding: 25px 0;
}
.sidebar-btn {
  width: 18px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: -30px;
}
.sidebar-btn img {
  width: 16px;
  height: 16px;
}
/* @media screen and (max-width: 1024px) {
  .player-inner-title {
    height: 50px;
  }
  .player-back {
    width: 50px;
  }
  .player-inner {
    height: 370px;
  }
  .signal {
    font-size: 12px;
  }
  .playerWrap {
    flex-direction: column;
    background: #333;
  }
  .player-do-btn button {
    width: 78px;
    height: 28px;
    font-size: 12px;
    margin-left: 10px;
  }
  .player-inner {
    margin-bottom: 20px;
  }
  .player-normal-menu {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  .qes-talk-wrap {
    height: 400px;
  }
  .qes-do-wrap {
    width: 100%;
  }
  .bot-div {
    display: none;
  }
  .player .txtWrap {
    max-height: 30vh;
  }
  .player .playerModules {
    width: 100%;
  }
  .player-bot-do {
    min-height: 60px!important;
    height: 60px!important;
  }
} */
.mmm {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  z-index: 999999999999;
}
.pdf {
  width: 100%;
  overflow: auto;
}
.tools {
  margin: 0 auto;
}
.tools button {
  height: 30px;
  background: rgb(0, 113, 245);
  color: #fff;
  border: 0;
}
.page {
  padding-top: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}
.pdf::-webkit-scrollbar {
  width: 3px;
}
.pdf::-webkit-scrollbar-thumb {
  background: #aaa;
}
.sub-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon {
  font-size: 12px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: content-box;
  color: #888;
  border: 1px solid #888;
  margin-bottom: 0;
}
.cover-mp3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cover-mp3 img {
  object-fit: cover;
}
.people-module {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.people-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.item-bg {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.item-bg p {
  margin-bottom: 0;
}
.people-title h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.people-title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.try-content {
  padding: 20px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.try-btn-wrap {
  padding-top: 10px;
}
.try-btn-wrap button {
  margin: 0 10px;
}
.exam-content {
  padding: 20px 20px 0 20px;
}
.exam-do-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
