<template>
  <div class="mycourse">
    <ul class="tab" :style="{borderColor: $store.state.themeColor}">
      <li @click="tabs(item, i)" class="item" :style="i == index?{background: $store.state.themeColor, color:'#fff'}:{}" v-for="(item, i) in tab" :key=i>{{item.name}}</li>
    </ul>
    <div class="empty" v-if="list.length == 0">
      <img src="../../assets/images/weipingjia.png" alt="">
      <span>暂无课程内容~</span>
    </div>
    <div class="tabContent" v-if="list.length > 0">
      <div class="loadding" v-show="!show">
        <a-spin />
      </div>
      <transition name="fades">
      <ul v-if="show" class="itemWrap">
        <li class="tcItem" v-for="(item, i) in list" :key="i">
          <div class="img">
            <img class="img_a" :src="item.picture" alt="">
            <span v-if="item.is_show == 2" class="status-icon status-delete-icon">已删除</span>
            <span v-if="item.is_show == 0" class="status-icon status-shelves-down-icon">已下架</span>
          </div>
          <div class="info">
            <div class="top">
              <h3 class="title" @click="go(item, i)">{{item.title}}</h3>
              <p class="describe">{{item.infos}}</p>
              <span>总课时：{{item.period}}</span>
              <div class="progress">
                <span>学习进度：</span>
                <a-progress :percent="parseInt(item.finish_course_percent)" :strokeColor="strokeColor" status="active" />
              </div>
            </div>
          </div>
          <div class="bot">
              <a-button v-if="item.type != 'live'" @click="go(item, i, 0)" size="large" :style="{width: '120px',background: studyBtnState(item.learn_status).bgColor, color: studyBtnState(item.learn_status).color, borderColor: studyBtnState(item.learn_status).bdColor}">{{studyBtnState(item.learn_status).msg}}</a-button>
              <a-button v-if="item.type == 'live'" @click="go(item, i, 0)" size="large" :style="{width: '120px',background: 'rgb(255, 134, 17)', color: '#fff', borderColor: 'rgb(255, 134, 17)'}">查看课程</a-button>
            </div>
        </li>
      </ul>
      </transition>
      <a-pagination v-show="show" @change="changePage" :defaultCurrent="page" :defaultPageSize="page_size" :total="total" />
    </div>

    <a-modal v-model="visible" width="380px" :centered="true" :footer="null" :title="null">
      <div class="txt-wrap">
        <p class="sync-txt">该课程属于 {{tenant_name}} - {{platform_name}}, 是否切换平台？</p>
      </div>
      <div class="do-some">
        <a-button 
          @click="cancel"
          :style="{
            color: $store.state.themeColor+'!important',
            border: '1px solid'+ $store.state.themeColor+'!important',
            marginRight: '20px'
          }">取消</a-button>
        <a-button 
          @click="ok"
          :style="{
            background: $store.state.themeColor,
            color: '#fff!important'
          }">立即切换</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  myCourse
} from '../../http/api'
export default {
    data() {
        return {
          index: 0,
          tab: [
            {
              name: '正在学习'
            },
            {
              name: '已完成'
            }
          ],
          list: [],
          show: false,
          page: 1,
          page_size: 10,
          total: 0,
          strokeColor: '',
          visible: false,
          tenant_name: '',
          platform_name: '',
          domain: ''
        }
    },
    watch:{
      '$store.state.listenEnterprise'(n, o) {
        this.list = []
        this.update(this.index, 1)
      }
    },
    created() {
      this.strokeColor = this.$store.state.themeColor
      this.update(0, 1)
    },
    mounted() {
      
    },
    methods: {
      ok() {
        window.location.href = this.domain
      },
      cancel() {
        this.visible = false
      },
      changePage(page, pageSize) {
        this.update(this.index, page)
      },
      update(i, page) {
          this.show = false
          myCourse({
            is_learned: i,
            page: page,
            page_size: this.page_size,
            platform_id: this.$store.state.product_id,
            tenant_id: this.$store.state.tenant_id
          }).then(res => {
            this.list = res.data.data.courses
            this.total = res.data.data.ext.total
            this.show = true
          }).catch(err => {
            
          })
        
      },
      tabs(item, i) {
        this.index = i
        this.update(i, 1)
      },
      studyBtnState(state) {
        if(state == 0) {
          return {
            msg: '开始学习',
            bgColor: this.$store.state.themeColor,
            color: '#fff',
            bdColor: '#fff'
          }
        }else if(state == 1) {
          return {
            msg: '继续学习',
            bgColor: '#ff8611',
            color: '#fff',
            bdColor: '#fff'
          }
        } else if(state == 2) {
          return {
            msg: '已完成',
            bgColor: '#fff',
            color: this.$store.state.themeColor,
            bdColor: this.$store.state.themeColor
          }
        }
      },
      go(item, i, tabIndex) {
        if(item.is_local == 0) {
          this.tenant_name = item.tenant.tenant_name
          this.platform_name = item.tenant.platform_name
          this.domain = item.tenant.domain
          this.visible = true
        } else {
          this.$router.push({
            path: '/course/studydetail',
            query: {
              id: item.id,
              course_type: item.course_type,
              index: tabIndex
            }
          })
        }
      }
    }
}
</script>

<style scoped>
.empty {
  padding: 120px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
button {
  font-size: 14px;
}
.loadding {
  height: 300px;
}
.mycourse .tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #0071f5;
  margin-bottom: 0;
}
.mycourse .tab .item {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
}
.active {
  background: #0071f5;
  color: #fff;
}
.mycourse .tcItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
.mycourse .tcItem .describe {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    margin-bottom: 30px;
    color: #666;
  }
.mycourse .img {
  width: 330px;
  height: 200px;
  margin-right: 20px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.mycourse .itemWrap {
  margin-bottom: 20px;
}
.mycourse .img img {
  object-fit: cover;
}
.mycourse .info {
  flex: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.mycourse .top {
  flex: 1;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 40px;
}
.mycourse .title {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.progress {
  display: flex;
  justify-content: center;
  align-items: center;
}
.progress span {
  min-width: 80px;
}
.do-some {
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-wrap {
  padding: 20px 0;
}
.sync-txt {
  font-weight: bold;
}
@media screen and (max-width: 1024px) {
  .mycourse .tab .item {
    flex: 1;
  }
  .mycourse .tcItem {
    flex-direction: column;
    height: auto;
  }
  .mycourse .img {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
    height: 230px;
  }
  .mycourse .tcItem .describe {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
  .mycourse .img {
    height: 420px;
  }
}
</style>