var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "job" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "title-wrap" }, [
            _c(
              "h3",
              {
                staticStyle: {
                  "font-size": "24px",
                  color: "#333",
                  "font-weight": "bold",
                  "line-height": "24px",
                  "margin-right": "20px",
                  "margin-bottom": "0",
                },
              },
              [_vm._v("项目经理（J10784）")]
            ),
            _c(
              "span",
              { staticStyle: { "font-size": "24px", color: "#F34026" } },
              [_vm._v("2-4万")]
            ),
          ]),
          _c("div", [
            _c(
              "span",
              {
                staticStyle: {
                  "font-size": "14px",
                  color: "#666",
                  "line-height": "14px",
                  padding: "20px 0",
                  display: "block",
                },
              },
              [_vm._v("北京-朝阳区 丨 3-5年 丨 本科 丨 招1人 丨 03-24发布")]
            ),
          ]),
          _c("ul", { staticClass: "icons" }, [
            _c("li", [_vm._v("周末双休")]),
            _c("li", [_vm._v("带薪年假")]),
            _c("li", [_vm._v("五险一金")]),
            _c("li", [_vm._v("绩效奖金")]),
            _c("li", [_vm._v("节日福利")]),
            _c("li", [_vm._v("专业培训")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "inner" }, [
          _c("div", { staticClass: "left" }, [
            _c("div", { staticClass: "title-group" }, [
              _c("span", { staticClass: "icon" }),
              _c("span", { staticClass: "txt" }, [_vm._v("职位信息")]),
            ]),
            _c("div", { staticClass: "text-line" }, [
              _c("p", [_vm._v("工作职责：")]),
              _c("p", [
                _vm._v(
                  "1、参与公司项目管理体系的建设、持续改进及推广，不断优化管理流程，提高业务满意度"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "2、监督、执行项目管理相关活动，协调和解决跨部门项目管理相关问题"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "3、参与、指导公司软件项目的日常项目管理等工作（偏教练角色）"
                ),
              ]),
            ]),
            _c("div", { staticClass: "text-line" }, [
              _c("p", [_vm._v("任职资格：")]),
              _c("p", [
                _vm._v(
                  "1、计算机、电子工程、自动控制等相关专业，全日制本科及以上学历；"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "2、具备软件项目管理的一般理论和知识，有至少2年互联网软件项目管理的相关经验；"
                ),
              ]),
              _c("p", [_vm._v("3、具备至少2年敏捷（Agile）项目的经理；")]),
              _c("p", [
                _vm._v(
                  "4、具备敏锐的洞察力，良好的表达、沟通能力以及分析、判断能力；"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "5、有强烈的责任心和事业心，沟通能力强，能快速融入团队，能够良好的协调部门剑协作；"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "6、具有PMP/ACP/Scrum、Kanban等相关资质认证的优先，具有敏捷项目管理经验、敏捷教练经验、阻止过程改进经验者优先。"
                ),
              ]),
            ]),
            _c("div", { staticClass: "title-group" }, [
              _c("span", { staticClass: "icon" }),
              _c("span", { staticClass: "txt" }, [_vm._v("联系方式")]),
            ]),
            _c("div", { staticClass: "text-line" }, [
              _c("div", { staticClass: "icon-wrap" }, [
                _c("img", {
                  staticClass: "comp-icon",
                  attrs: {
                    src: require("../../assets/images/comp1.png"),
                    alt: "",
                  },
                }),
                _c("p", [
                  _vm._v(
                    "上班地址：北京市朝阳区三间房南里4号院DREAM2049国际文创产业园东门B05栋"
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "title-group" }, [
              _c("span", { staticClass: "icon" }),
              _c("span", { staticClass: "txt" }, [_vm._v("公司介绍")]),
            ]),
            _c("div", { staticClass: "text-line" }, [
              _c("p", [
                _vm._v(
                  "好大夫在线创立于2006年，是中国领先的互联网医疗平台之一，致力于成为最值得信赖的医疗平台。经过15年诚信运营，好大夫在线已经在医院/医生信息查询、图文问诊、电话问诊、远程视频门诊、门诊精准预约、诊后疾病管理、家庭医生、疾病知识科普等多个领域取得显著成果，收到医生和患者广泛信赖。"
                ),
              ]),
              _c("p", [
                _vm._v(
                  "好大夫在线拥有数量众多的优质医生群体。截至2021年2月，好大夫在线收录了国内9600家正规医院78万余位医生信息，已累计服务超过6900万用户。用户可以通过好大夫在线APP、PC版网站、手机版网站、微信公众号、微信小程序等多个平台，方便的联系到24万+公立医院的医生，一站式解决线上服务、线下就诊等各种医疗问题。"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "r-top" }, [
              _c("h3", { staticClass: "title" }, [_vm._v("公司信息")]),
              _c("div", { staticClass: "title-pic" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/hdf.png"),
                    alt: "",
                  },
                }),
                _c(
                  "span",
                  { staticStyle: { "font-size": "16px", color: "#000" } },
                  [_vm._v("好大夫在线")]
                ),
              ]),
              _c(
                "div",
                {
                  staticClass: "text-line",
                  staticStyle: { "margin-bottom": "0px", "padding-left": "0" },
                },
                [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      staticClass: "comp-icon",
                      attrs: {
                        src: require("../../assets/images/comp2.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "p",
                      { staticStyle: { "font-size": "14px", color: "#666" } },
                      [_vm._v("外资（欧美）")]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "text-line",
                  staticStyle: { "margin-bottom": "0px", "padding-left": "0" },
                },
                [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      staticClass: "comp-icon",
                      attrs: {
                        src: require("../../assets/images/comp3.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "p",
                      { staticStyle: { "font-size": "14px", color: "#666" } },
                      [_vm._v("500-1000人")]
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "text-line",
                  staticStyle: { "margin-bottom": "0px", "padding-left": "0" },
                },
                [
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("img", {
                      staticClass: "comp-icon",
                      attrs: {
                        src: require("../../assets/images/comp4.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "p",
                      { staticStyle: { "font-size": "14px", color: "#666" } },
                      [_vm._v("互联网/电子商务 医疗/护理/卫生")]
                    ),
                  ]),
                ]
              ),
            ]),
            _c("div", { staticClass: "r-bot" }, [
              _c("h3", { staticClass: "title" }, [_vm._v("相似职位")]),
              _c("ul", { staticClass: "line-item" }, [
                _c("li", [
                  _c("h3", [_vm._v("20089-美术指导（北京）")]),
                  _c("div", { staticClass: "sub-wrap" }, [
                    _c("span", { staticClass: "sub" }, [
                      _vm._v("20089-美术指导（北京）"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "sub", staticStyle: { color: "#F34026" } },
                      [_vm._v("5-12k")]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("h3", [_vm._v("40022-技术总监（深圳）")]),
                  _c("div", { staticClass: "sub-wrap" }, [
                    _c("span", { staticClass: "sub" }, [
                      _vm._v("40022-技术总监（深圳）"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "sub", staticStyle: { color: "#F34026" } },
                      [_vm._v("15-70K")]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("h3", [_vm._v("20212-Java工程师（广东）")]),
                  _c("div", { staticClass: "sub-wrap" }, [
                    _c("span", { staticClass: "sub" }, [
                      _vm._v("20212-Java工程师（广东）"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "sub", staticStyle: { color: "#F34026" } },
                      [_vm._v("9-18K")]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("h3", [_vm._v("20053-项目经理（北京）")]),
                  _c("div", { staticClass: "sub-wrap" }, [
                    _c("span", { staticClass: "sub" }, [
                      _vm._v("20053-项目经理（北京）"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "sub", staticStyle: { color: "#F34026" } },
                      [_vm._v("9-17K")]
                    ),
                  ]),
                ]),
                _c("li", [
                  _c("h3", [_vm._v("30051-采购专员（上海）")]),
                  _c("div", { staticClass: "sub-wrap" }, [
                    _c("span", { staticClass: "sub" }, [
                      _vm._v("30051-采购专员（上海）"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "sub", staticStyle: { color: "#F34026" } },
                      [_vm._v("5-7K")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }