<template>
    <div>

        <div class="inner">
            <div class="top-wrap">
                <div class="banner" :style="{width: $store.state.tenant_idBind == 1202 || $store.state.tenant_idBind == 1226 || $store.state.tenant_idBind == 1299? '1200px': '750px'}">
                    <a-carousel arrows autoplay dotPosition="right">
                        <div
                        slot="prevArrow"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left-circle" />
                        </div>
                        <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                        </div>
                        <div class="carouselImgWrap" v-if="banners.length == 0">
                            <router-link to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="carouselImgWrap" v-for="(item, i) in banners" :key="i">
                            <router-link v-if="banners.length == 0" to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 2" :to="`/course/studydetail?id=${item.link}&course_type=system_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 1" to="" @click.native="goThree(item, i)">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 3" :to="`/course/studydetail?id=${item.link}&course_type=tenant_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                        </div>
                    </a-carousel>
                </div>
                <div class="news" v-if="$store.state.tenant_idBind != 1202 && $store.state.tenant_idBind != 1226 && $store.state.tenant_idBind != 1299">
                    <!-- <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <span>通知公告</span>
                        </div>
                        <span class="more" @click="moreNotice">查看全部&nbsp;></span>
                    </div> -->
                    <ul class="news-wrap" v-if="news.length > 0">
                        <li v-for="(item, i) in news" :key="i">
                            <div class="date">
                                <span class="date-day">{{item.created_at.split('-')[2].split(' ')[0]}}</span>
                                <span class="dates">{{`${item.created_at.split('-')[0]}/${item.created_at.split('-')[1]}`}}</span>
                            </div>
                            <div class="news-item">
                                <p @click="goNotice(item, i)" class="news-title">{{item.title}}</p>
                                <p class="news-info">{{item.infos}}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="empty" style="height: 380px;background: #FAFAFA;" v-if="news.length == 0">
                        <img src="../../assets/images/empty.png" alt="">
                        <span style="color: #666;font-size: 12px">暂无数据</span>
                    </div>
                </div>
            </div>
            <div class="tem-user-wrap" v-if="$store.state.tenant_idBind == 1202 || $store.state.tenant_idBind == 1226 || $store.state.tenant_idBind == 1299">
                <div class="notice" style="flex: 1;height: 140px;">
                    <div class="titleWrap">
                        <p></p>
                        <span class="more" @click="more('news')">更多</span>
                    </div>
                    <ul class="notice-content">
                        <li v-show="news.length == 0">暂无数据</li>
                        <li @click="goNews(item, i)" @mouseover="addColor(item, i)" @mouseout="removeColor(item, i)" :style="index == i?{color: $store.state.themeColor}:{}" v-for="(item, i) in news" :key=i>
                            <div class="notice-item-title">
                                <span class="icon">New</span>
                                <p class="articleT">{{item.title}}</p>
                            </div>
                            <span>{{item.created_at.split(" ")[0]}}</span>
                        </li>

                    </ul>
                </div>
                <div class="flower" style="flex: 1;height: 140px;margin-left: 20px;border-radius: 4px;">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f02.png" alt="">
                        <span>注册登录</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f01.png" alt="">
                        <span>选择课程</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f03.png" alt="">
                        <span>在线学习</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f04.png" alt="">
                        <span>在线考试</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f05.png" alt="">
                        <span>完成学习</span>
                    </div>
                </div>
            </div>

            <div class="live-module" v-if="$store.state.can_live && lives.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>最近直播</span>
                    </div>
                    <span v-if="lives.length >= 2" class="more" @click="more('live')">查看全部&nbsp;></span>
                </div>
                <ul class="live-list" v-if="lives.length > 0">
                    <li class="live-item" @click="go(item, i)" v-for="(item, i) in lives" :key="i">
                        <div class="live-top">
                            <img :src="item.authors[0].avatar" alt="">
                            <p class="title">{{item.title}}</p>
                        </div>
                        <div class="live-bot">
                            <span class="name">{{item.authors[0].name}}</span>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 1" class="live-sign" :style="{background: $store.state.themeColor,color: '#fff'}">
                                <span>正在直播</span>
                                <img src="../../assets/images/going.png" alt="">
                            </div>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 0" class="live-sign">{{item.start_time.split(':')[0]+':'+item.start_time.split(':')[1]}}&nbsp;开始</div>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 2" class="live-sign">已结束</div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="empty" style="height: 170px" v-if="lives.length == 0">
                    <img src="../../assets/images/empty.png" alt="">
                    <span style="color: #666;font-size: 12px">暂无直播</span>
                </div> -->
            </div>

            
           
            
            <div class="empty" style="height: 400px" v-if="categorys.length == 0">
                <img src="../../assets/images/empty.png" alt="">
                <span style="color: #666;font-size: 12px">暂无数据</span>
            </div>


            <div class="union-module" v-if="unions.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>院校联盟</span>
                    </div>
                </div>
                <ul class="union">
                    <li class="union-l" @click="prev()" v-if="unionsTotal_pages > 1">
                        <img src="../../assets/images/union-l.png" alt="">
                    </li>
                    <li class="union-item" v-for="(item, i) in unions" :key="i" @click="linkUrl(item)">
                        <img :src="item.logo" alt="">
                    </li>
                    <li class="union-r" @click="next()" v-if="unionsTotal_pages > 1"><img src="../../assets/images/union-r.png" alt=""></li>
                </ul>
            </div>

        </div>
        <div>
             <div class="list-box">
                 <div class="list-module" v-if="categorys.length > 0">
                <ul>
                    <li class="module-item">
                        <div class="title-bar">
                            <div class="title-wrap">
                                <img src="../../assets/images/couserss.png" alt="">
                                <span>推荐课程</span>
                            </div>
                            <ul class="cates-list">
                                <li class="cates-item" @click="firstScreenings(item, i)" v-for="(item, i) in listssss.course_cates" :key="i">
                                    <span>{{item.name}}</span>
                                    <span class="diagonal-line" v-if="i<listssss.course_cates.length-1">/</span>
                                </li>
                            </ul>
                            <!-- <span v-if="item.course.length >= 6" class="more" @click="more('course', item.id)">查看全部&nbsp;></span> -->
                        </div>
                        <ul class="lesson-list">
                            <li class="lesson-item" @click="go(items, i)" v-for="(items, is) in listssss.courses" :key="is">
                                <img class="img_a" :src="items.picture" alt="">
                                <div class="lesson-msg-wrap">
                                    <p class="lesson-title">{{items.title}}</p>
                                    <p class="lesson-about">
                                        <span>{{items.period}}学时 / {{items.category.name}} </span>
                                        <span>{{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: items.learn_total}}人已学习</span>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
             </div>
            <div class="list-module">
                <div class="classes-wrap" v-if="$store.state.can_class && list.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/classess.png" alt="">
                        <span>培训班</span>
                    </div>
                    <ul class="cates-list">
                        <li class="cates-item" @click="firstScreening(item,i)" v-for="(item, i) in listssss.class_cates" :key="i">
                            <span>{{item.name}}</span>
                            <span class="diagonal-line" v-if="i<listssss.class_cates.length-1">/</span>
                        </li>
                    </ul>
                    <!-- <span v-if="list.length >= 2" class="more" @click="more('classes')">查看全部&nbsp;></span> -->
                </div>
                <ul class="classes-list">
                    <li class="classes-item" v-for="(item, i) in listssss.classes.slice(0, 6)" :key="i" @click="go(item, i)">
                        <div class="img">
                            <img class="img_a" :src="item.picture" alt="">
                            <div v-if="item.learn_status == 1" class="classes-sign-ing"><span style="padding-right: 20px">进行中</span></div>
                            <div v-if="item.learn_status == 2" class="classes-sign-end">已结束</div>
                        </div>
                        <div class="classes-info">
                            <p class="classes-title">{{item.title}}</p>
                            <div class="classes-msg-wrap">
                                <p class="describe" v-if="item.begin_time&&item.end_time">{{$moment(item.begin_time).format("YYYY-MM-DD")+' 至 '+$moment(item.end_time).format("YYYY-MM-DD")}}</p>
                                <!-- <p class="classes-about">{{item.about}}</p> -->
                                <p style="color: #333;margin-bottom: 0">{{item.course_num}}课程<span class="diagonal-line2">/</span>{{item.period}}课时<span class="diagonal-line2">/</span>{{item.join_total}}人参与</p>
                                <!-- {{item.member_limit==0?'公开加入':'学员人数'+item.join_total+'/'+item.member_limit}} -->
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="experts-wrap">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/expertss.png" alt="">
                        <span>专家名师</span>
                    </div>
                </div>
                <ul class="experts-list">
                    <li class="experts-item" v-for="(items, is) in expertsList" :key="is">
                        <img class="img_a" :src="items.picture" alt="">
                        <div class="experts-msg-wrap">
                            <p class="experts-title">{{items.name}}</p>
                            <p class="experts-about">{{items.introduce}}</p>                           
                        </div>

                    </li>
                </ul>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    unions
} from '../../http/api'
import merge from 'webpack-merge'
export default {
    data() {
        return {
            value: 5,
            list: [],
            type: 'notice',
            page: 1,
            page_size: 5,
            unions: [],
            unionsTotal_pages: 0,
            listFull:[],
            expertsList:[],
        }
    },
    props: {
      news: Array,
      banners: Array,
      categorys: Array,
      lives: Array,
    listssss: Object
    },
    created() {
        this.hostname = window.location.hostname
        try {
            if(this.categorys.length != 0) {
                if(this.$store.state.can_class == 1) {
                    this.list = this.categorys[0].course.slice(0,2)
                }
            }
        } catch (error) {

        }
        this.updateUnions()
    },
    mounted() {
        console.log('this.listssss', this.listssss);
        this.expertsList=[{
            name:'韩保江',
            introduce:'中央党校(国家行政学院)经济学教研部主任、教授',
            picture: require('../../assets/expertspicture/pic1.jpg')
        },{
            name:'李志勇',
            introduce:'中共中央党校（国家行政学院）科学社会主义教研部教授',
            picture: require('../../assets/expertspicture/pic2.jpg')
        },{
            name:'邓纯东',
            introduce:'中国社会科学院马克思主义研究院（原）党委书记、院长',
            picture: require('../../assets/expertspicture/pic3.jpg')
        },{
            name:'宋洪远',
            introduce:'农业农村部农村经济研究中心（原）主任',
            picture: require('../../assets/expertspicture/pic4.jpg')
        },{
            name:'郑风田',
            introduce:'中国人民大学农业与农村发展学院副院长 教授',
            picture: require('../../assets/expertspicture/pic5.jpg')
        },{
            name:'李建忠',
            introduce:'中国人事科学研究院副院长',
            picture: require('../../assets/expertspicture/pic6.jpg')
        },{
            name:'冉昊',
            introduce:'中共中央党校（国家行政学院）副教授',
            picture: require('../../assets/expertspicture/pic7.jpg')
        },{
            name:'单志广',
            introduce:'国家信息中心信息化和产业发展部主任',
            picture: require('../../assets/expertspicture/pic8.jpg')
        },{
            name:'孙林',
            introduce:'中共中央党校〈国家行政学院〉党建部副教授',
            picture: require('../../assets/expertspicture/pic9.jpg')
        },{
            name:'朱启臻',
            introduce:'中共中央党校（国家行政学院）应急管理培训中心（中欧应急管理学院）主任（院长）',
            picture: require('../../assets/expertspicture/pic10.jpg')
        },{
            name:'何代欣',
            introduce:'中国社会科学院财经战略研究院财政研究室主任',
            picture: require('../../assets/expertspicture/pic11.jpg')
        },{
            name:'刘爱玲',
            introduce:'中国社会科学院马克思主义研究院副研究员',
            picture: require('../../assets/expertspicture/pic12.jpg') 
        },]
        
    },
    methods: {
        
        firstScreenings(item, i) {
            this.$router.push({
                path:'/course',
                query:merge(this.$route.query,{id: item.id})
            })
        },
        firstScreening(item, i) {
            this.$router.push({
                path:'/course-classes',
                query:merge(this.$route.query,{id: item.id})
            })
        },
        priceShow() {
            console.log('this.$store.state.tenant_idBind',this.$store.state.tenant_idBind) 
            if(this.$store.state.tenant_idBind == 1222) {
                return false
            //} else if(this.$store.state.can_pay && this.$store.state.tenant_idBind != 22) {
            } else if(this.$store.state.can_pay) {
                return true
            }
        },
        linkUrl(item) {
            window.location.href = item.domain
        },
        next() {
            if(this.page < this.unionsTotal_pages) {
                this.page++
                this.updateUnions()
            } else {
                this.page = 1
                this.updateUnions()
            }
        },
        prev() {
            if(this.page > 1) {
                this.page--
                this.updateUnions()
            } else {
                this.page = this.unionsTotal_pages
                this.updateUnions()
            }
        },
        updateUnions() {
            unions({
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                if(res.data.code == 200) {
                    this.unions = res.data.data
                    this.unionsTotal_pages = res.data.ext.total_pages
                }
            })
        },
        goThree(item, i) {
            window.open(item.link)
        },
        liveStatus(start, end) {
            let now = new Date().getTime()
            let s = new Date(start).getTime()
            let e = new Date(end).getTime()
            if(now < s) {
                return 0
            } else if(now > s && now < e) {
                return 1
            } else if(now > e) {
                return 2
            }
        },
        goNotice(item, i) {
          this.$router.push({
            path: '/article_detail',
            query: {
              id: item.id,
              type: this.type
            }
          })
        },
        moreNotice() {
          if(this.type == 'notice') {
            this.$router.push({
                path: '/news'
            })
          }
        },
        goNews(item, i) {
            this.$router.push({
                path: '/article_detail',
                query: {
                id: item.id,
                type: '公告通知'
                }
            })
        },
        go(item, i) {
            if(item.join_type == 'class') {
                if(item.is_access) {
                    this.$router.push({
                    path: '/class/catalog',
                    query: {
                        id: item.id,
                    }
                    })
                } else {
                    this.$message.error('学员已禁用，不能访问')
                }
            } else {
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                    id: item.id,
                    course_type: item.course_type
                    }
                })
            }
        },
        more(type, id) {
            if(type == 'course') {
                this.$router.push({
                    path: '/course',
                    query: {
                        id: id
                    }
                })
            } else if(type == 'live') {
                this.$router.push({
                    path: '/course-live'
                })
            } else if(type == 'classes') {
                this.$router.push({
                    path: '/course-classes'
                })
            } else if(type == 'news') {
                this.$router.push({
                    path: '/news'
                })
            }

        }
    }
}
</script>

<style scoped>
.list-box{
    box-shadow: -100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset,100px 0px 30px 0px rgba(0, 0, 0, 0) inset,0px 0px 30px 0px rgba(0, 0, 0, 0.1) inset;
    padding:10px 0;
}

.list-module{
    width:1200px;
    margin:0 auto;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 415px;
    padding-top: 20px;
    box-sizing: content-box;
    margin-bottom: 20px;
}
.banner {
    width: 740px;
    height: 100%;
    margin-right: 20px;
    border-radius: 5px;
    overflow: hidden;
}
.news {
    flex: 1;
    height: 100%;
}
.diagonal-line{
    padding:0 10px;
    color: #cccccc;
}
.diagonal-line2{
    padding:0 4px;
    color: #333;
}
.title-bar {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}
.title-bar img {
    margin-right: 10px;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wrap span {
    font-size: 24px;
    line-height: 25px;
    color: #1A1A1A;
    font-weight: bold;
}
.title-bar .more {
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    color: #999;
    cursor: pointer;
}
.carouselImgWrap img {
    /* height: 420px; */
    object-fit: cover;
}
.news {
    height: 420px;
    box-sizing: border-box;
    margin-top:7px; 
}
.news-wrap {
    height: 412px;
}
.news .date {
    width: 68px;
    height: 68px;
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 5px;
}
.news .date-day {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    line-height: 36px;
}
.news .dates {
    font-size: 12px;
    line-height: 18px;
    height: 18px;
    color: #000;
}
.news li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    height: 68px;
}
.news li:last-child {
    margin-bottom: 0;
}
.news .news-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* border-bottom: 1px solid #E6E6E6; */
    height: 68px;
    padding: 8px 0 8px 0;
}
.news-item .news-title {
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    line-height: 30px;
    height: 30px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 2px;
    cursor: pointer;
    text-align: left;
}
.news-item .news-title:hover {
    color: #D7221E;
}
.news-item .news-info {
    font-size: 14px;
    color:#767676;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    text-align: left;
}
.flower {
    height: 130px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
}
.flower-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flower-item>span {
    font-size: 14px;
    color: #2A2928;
    font-weight: bold;
    line-height: 14px;
}
.flower-item img{
    width: 54px;
    height: 54px;
    margin-bottom: 10px;
}
.flower .flower-sign {
    width: 17px;
    height: 21px;
}
.classes-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* padding-bottom: 40px; */
}
.classes-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding-right: 15px; */
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
    width: 585px;
    height: 150px;
    /* width: 1220px; */
    /* margin-left: -20px; */
    /* padding: 20px; */
    transition: all .3s;
    margin-right: 30px; 
    margin-bottom: 30px;
    border: 1px solid #EEEEEE;
    background: #FFFFFF;
}
.classes-item:nth-child(2n) {
    margin-right: 0;
}
.classes-item:hover .classes-go {
    background: #0880F1;
}
.classes-item:last-child {
    /* margin-bottom: 0; */
}
.classes-item .img .classes-sign-ing {
    background: url('../../assets/images/classes-sign-ing.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end {
    background: url('../../assets/images/classes-sign-end.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img {
    width: 270px;
    height: 152.54px;
    margin-right: 20px;
    position: relative;
    border-radius: 5px 0px 0px 5px;
    overflow: hidden;
}
.classes-item .img img {
    object-fit: cover;
}
.classes-title {
    font-size: 16px;
    font-weight: bold;
    color: #1A1A1A;
    line-height: 24px;
    height: 50px;
    margin-bottom: 22px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
}
.classes-msg-wrap {
    height: 48px;
    font-size: 12px;
    line-height: 24px;
    color: #1A1A1A;
}

.classes-about {
    /* min-height: 72px; */
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign {
    color: #FF5E57;
}
.classes-price {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info {
    height: 150px;
    padding:15px 15px 15px 0px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.classes-do-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.classes-go {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    overflow: hidden;
    border-radius: 2px;
}

.classes-item:hover .classes-go .classes-go-img {
    background: url('../../assets/images/classes-go-hover.png') no-repeat;
}
.classes-go .classes-go-img {
    background: url('../../assets/images/classes-go.png') no-repeat;
    width: 35px;
    height: 10px;
    transition: all .2s;
}
.module-item {
    width: 100%;
    /* margin-bottom: 40px; */
}
.lesson-item:hover, .classes-item:hover {
    box-shadow: 0 10px 30px rgba(0,0,0,0.2);
}
.cates-list{
    display: flex;
}
.cates-item {
    text-align: right;
    font-size: 14px;
    height: 26px;
    line-height: 26px;
    color:#1A1A1A;
    cursor: pointer;
}

.lesson-list {
    /* box-sizing: content-box; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
.lesson-item {
   
    transition: all .3s;
    width: 277px;
    height: 280px;
    margin-right: 30px;
    background: #FFFFFF;
    text-align: left;
    cursor: pointer;
    overflow: hidden;
    /* padding: 15px; */
    margin-bottom: 28px;
    border-radius: 5px;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
    
}
.lesson-item:nth-child(4n) {
    margin-right: 0;
}
.lesson-item img {
    height: 157.63px;
    object-fit: cover;
    /* margin-bottom: 10px; */
    border-radius: 4px;
}
.lesson-title {
    /* margin-bottom: 25px; */
    font-size: 14px;
    line-height: 24px;
    height: 84.37px;   
    padding-top: 9.37px;
    padding-left: 14.84px;
    padding-right: 14.84px;
    padding-bottom: 25px;
    font-weight: normal;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000000
}
.lesson-about {
    display: flex;
    justify-content:space-between;
    height: 40px;
    border-top: 1px solid #EEEEEE;
    font-size: 12px;
    line-height: 40px;
    color: #767676;
    padding-left: 14.84px;
    padding-right: 14.84px;
}
.lesson-name {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lesson-msg-wrap {
    background: #FFFFFF;
    /* padding-top: 9.37px;
    padding-left: 14.84px;
    padding-right: 14.84px; */
}
.lesson-price-sign {
    color: #FF5E57;
}
.lesson-price {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty img {
    width: 120px;
    height: 74px;
}
.live-module {
    margin-bottom: 40px;
}
.live-list {
    /* height: 170px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.live-item {
    width: 588px;
    height: 100%;
    /* margin-right: 20px; */
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
    margin-bottom: 20px;
}
/* .live-item:last-child {
    margin-right: 0;
} */
.live-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live-top img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot {
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.live-bot .name {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign {
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAECEF;
    color: #666;
}
.live-bot .live-sign img {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
.union {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.union-module {
    margin-bottom: 30px;
}
.union {
    position: relative;
}
.union .union-item {
    height: 90px;
    width: 236px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    padding: 5px;
}
.union .union-item:nth-child(5n) {
    margin-right: 0;
}
.union .union-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.union .union-l, .union .union-r {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.union .union-l {
    position: absolute;
    left: -36px;
}
.union .union-r {
    position: absolute;
    right: -36px;
}
.union .union-l img, .union .union-r img {
    width: 100%;
    height: 100%;
}

.notice {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    border: 1px solid #E9E9E9;
    padding: 5px;
    border-radius: 4px;
  }
.notice .more {
  cursor: pointer;
  color: #888;
  font-size: 12px;
}
.notice .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    padding: 0 10px;
  }
.notice .title {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }
  .notice .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
  }
.notice-content {
    flex: 1;
    height: 256px;
    border-radius: 6px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
  }
  .notice-content::-webkit-scrollbar, .player-list::-webkit-scrollbar {
    width:3px;
  }
  .notice-content::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
      background-color:#aaa;
  }
  .notice .articleT {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 280px;
  }
  .notice-content li {
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }
  .notice-content li:last-child {
    margin-bottom: 0;
  }
  .notice-content span {
    display: block;
    text-align: left;
    line-height: 12px;
    color: #666;
    font-size: 12px;
  }
  .notice-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
  .notice-item-title .icon {
    margin-right: 10px;
    background: #FF8684;
    color: #fff;
    width: 38px;
    border-radius: 4px;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    box-sizing: border-box;
  }
  .tem-user-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  /* .experts-wrap {
    height: 620px;
    background-image: url(../../assets/images/main_bottom.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:bottom;

  } */
  .experts-list {
    /* box-sizing: content-box; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
  .experts-item {
    transition: all .3s;
    width: 175px;
    height: 250px;
    margin-right: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    background: #FFFFFF;
    text-align: left;
    cursor: pointer;    
    overflow: hidden;
    box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);
}
.experts-item:nth-child(6n) {
    margin-right: 0;
}
.experts-item img {
    /* width: 175px; */
    height: 175px;
    object-fit: cover;
    /* margin-bottom: 10px; */
    border-radius: 5px 5px 0px 0px;
}
.experts-title {
    /* margin-bottom: 25px; */
    margin-top: 10px;
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    text-align: center;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #000;
}
.experts-about {
    /* width: 155px; */
    height: 18px;
    padding-left: 15px;
    padding-right: 15px;
    line-height: 18px;
    text-align: center;
    font-size: 12px; 
    color: #767676;
    overflow: hidden;
    white-space:nowrap;
    text-overflow:ellipsis;

}
</style>
