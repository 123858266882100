var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "payres" }, [
    _c("h3", [_vm._v("支付成功")]),
    _c("img", {
      attrs: { src: require("../../assets/images/paystatus.png"), alt: "" },
    }),
    _c(
      "div",
      {},
      [
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.backRecord } },
          [
            _vm._v(
              _vm._s(_vm.course_type == "classroom" ? "返回班级" : "返回课程")
            ),
          ]
        ),
        _c(
          "a-button",
          { attrs: { type: "primary" }, on: { click: _vm.goOrders } },
          [_vm._v("查看订单")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }