var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "lecturer" }, [
    _vm._m(0),
    _vm.authors.length == 0
      ? _c("div", { staticClass: "empty" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/weipingjia.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("暂无数据~")]),
        ])
      : _vm._e(),
    _vm.authors.length > 0
      ? _c(
          "ul",
          _vm._l(_vm.authors, function (item, i) {
            return _c("li", { key: i, staticClass: "teacher-item" }, [
              _c("div", { staticClass: "detail" }, [
                _c("img", { attrs: { src: item.author_image, alt: "" } }),
                _c("div", { staticClass: "text-info" }, [
                  _c("h3", [_vm._v(_vm._s(item.name))]),
                  _c("p", [_vm._v(_vm._s(item.belong_subject))]),
                ]),
              ]),
              _c("p", { staticClass: "infos" }, [_vm._v(_vm._s(item.infos))]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-class-all" }, [
      _c("span", { staticClass: "icon" }),
      _c("h3", [_vm._v("讲师团队")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }