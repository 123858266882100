<template>
    <div>
        <div class="bg">
            <div class="inner">
                <h3 class="bg-title">爱迪产品矩阵</h3>
                <p>北京爱迪科森教育科技股份有限公司致力于网络学习平台的开发和课程资源的整合制作，是集高等教育、干部教育、社会教育、幼儿教育于一体的综合学习平台解决方案提供商。</p>
                <a-button class="btn" @click="open()">业务咨询</a-button>
            </div>
        </div>

        <div class="wrap">
            <div class="menu-inner">
                <h3 class="title">构建教育培训新生态</h3>
                <ul class="menu">
                    <li v-for="(item, i) in menu" 
                    @click="check(item, i)"
                    :key="i" 
                    :class="index == i? 'active-border': ''">
                        {{item.name}}
                    </li>
                </ul>
                <div class="menu-content">
                    <div class="left">
                        <img :src="src" alt="">
                    </div>
                    <div class="right">
                        <h3>{{name}}</h3>
                        <p>{{info}}</p>
                        <a-button @click="more" class="btn">了解更多</a-button>
                    </div>
                </div>
            </div>
        </div>
        <a-modal width="400px"
        :footer="null"
        v-model="visible" title="联系我们" @ok="handleOk">
            <img src="../../assets/images/adCode.png" alt="">
            <p style="text-align: center; font-weight: bold;">相关业务咨询或合作，请添加爱迪科森官方微信公众号</p>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: 'http://adkselearning.zjttv.cn/',
            visible: false,
            index: 0,
            src: require("../../assets/images/m1.png"),
            name: '爱迪云学院',
            info: '爱迪云学院始终致力于打造一个智能高效的在线企培平台，让企业培训更有效、更省心。基于行业属性、企业发展需求提供优质专业课程，并涵盖录播课、直播课、班级计划等多种学习手段。提供成熟的企业培训解决方案，通过全面的产品、安全可靠的服务、持续的技术升级，为客户在线培训项目保驾护航。',
            menu: [
                {
                    id: 1,
                    name: '爱迪云学院',
                    src: require("../../assets/images/m1.png"),
                    info: '爱迪云学院始终致力于打造一个智能高效的在线企培平台，让企业培训更有效、更省心。基于行业属性、企业发展需求提供优质专业课程，并涵盖录播课、直播课、班级计划等多种学习手段。提供成熟的企业培训解决方案，通过全面的产品、安全可靠的服务、持续的技术升级，为客户在线培训项目保驾护航。',
                    url: 'http://adkselearning.zjttv.cn/'
                },
                {
                    id: 2,
                    name: '朗读亭产品',
                    src: require("../../assets/images/m2.png"),
                    info: '书舒朗读亭是一款以朗读体验为基础，集朗读练习、英语口语学习、普通话测评、音频录制、配音、作品分享为一体的智能教育设备，可高效提升朗读者的语文素养与语言表达能力，辅助提高学校教学水平，是连接学生、教师、学校与国家语言发展战略的纽带!',
                    url: 'http://ssldt.bjadks.com/'
                },
                {
                    id: 3,
                    name: '爱迪学堂',
                    src: require("../../assets/images/m3.png"),
                    info: '爱迪科森深耕在线教育十五载，将众多技术积累汇聚于爱迪学堂。爱迪学堂是基于SAAS技术架构的云平台，整合了党政培训、社区教育、专业技术人员继续教育和企业网络大学，将原来的一个个分散的、个例的平台建设和课程运营业务进行统一规划，形成平台+课程+培训+培训支持的云服务。',
                    url: 'http://www.lllnet.cn/'
                },
                {
                    id: 4,
                    name: '网上报告厅',
                    src: require("../../assets/images/m4.png"),
                    info: '《网上报告厅》致力为图书馆提供权威、优质、丰富的资源和全方位的运营服务，汇聚国内外权威学术视频资源，以培养用户扎实的专业素养、良好的心理素养、高尚的道德素养、广播的人文素养、健康的身体素养为目标，帮助用户全面提供其综合素质和专业能力，是国内学术资源行业的视频知识库。',
                    url: 'https://wb.bjadks.com/'
                },
                {
                    id: 5,
                    name: '天天微学习中心',
                    src: require("../../assets/images/m5.png"),
                    info: '《天天微学习中心》根据目前在线教育产品的发展趋势，围绕使用用户群体特点，针对海量资源内容进行深度挖掘和全新科学整合，在资源形式上以“微课”为载体，形式主题突出、短小精悍、资源占用容量小的资源池。从职业技能、专业素养、人格素养、综合素养四个维度为广大用户提供专业全面指导，同时发挥图书馆的专业教学和素质教育辅助的核心职能，实现图书馆的知识传播。',
                    url: 'https://wxxzx.bjadks.com/?t=132153402662918246'
                },
                {
                    id: 6,
                    name: '职业全能培训库',
                    src: require("../../assets/images/m6.png"),
                    info: '《职业全能培训库》以职业规划和职业发展为导向，聚焦新时代大学生就业、创业与继续教育问题，整合国内外优质的职业教育视频资源，借助新一代信息通信技术，全面构建集课程学习、职业咨询、职业测评、学习评估、网上考试、职业速配与系统管理功能为一体的职业培养培训服务体系，为智慧化图书馆提供更高效、便捷、优质的资源建设方案，是国内职业教育培训行业专业的视频知识库。',
                    url: 'https://zyk.bjadks.com/'
                }
            ]
        }
    },
    methods: {
        check(item, i) {
            this.index = i
            this.name = item.name
            this.src = item.src
            this.info = item.info
            this.url = item.url
        },
        open() {
            this.visible = true
        },
        handleOk(e) {
            console.info(e)
            this.visible = false
        },
        more() {
            window.open(this.url)
        }
    }
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 560px;
    background: url('../../assets/images/productMatrixBg.jpg') no-repeat center center;
    background-size: cover;
}
.inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 60px;
}
.inner p {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn {
    widows: 116px;
    height: 46px;
    border-radius: 10px;
    background: #37B979;
    font-size: 15px;
    color: #fff;
    border: none;
}
.menu-inner {
    width: 1200px;
    margin: 0 auto;
    padding-top: 50px;
}
.menu-inner .title {
    font-size: 30px;
    line-height: 30px;
    color: #111D30;
    font-weight: bold;
    margin-bottom: 66px;
}
.menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.active-border {
    border-color: #0066CC!important;
    color: #0066CC!important;
}
.menu li {
    width: 174px;
    height: 50px;
    margin-bottom: 50px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 31px;
    cursor: pointer;
    font-size: 20px;
    color: #999;
    line-height: 48px;
    font-weight: bold;
}
.menu li:nth-child(6n) {
    margin-right: 0;
}
.menu-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.menu-content .left {
    flex: 3;
    box-shadow: 0 0 10px #ddd;
    border-radius: 10px;
    margin-right: 20px;
    overflow: hidden;
}
.menu-content .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 2;
    height: 460px;
    padding-left: 50px;
    background: url('../../assets/images/ppbg.png') no-repeat;
    background-size: 100% 100%;
}
.menu-content .right h3 {
    font-size: 30px;
    font-weight: bold;
    color: #111D30;
    line-height: 30px;
    margin-bottom: 30px;
}
.menu-content .right p {
    font-size: 14px;
    color: #999;
    line-height: 22px;
    text-align: left;
    margin-bottom: 50px;
}
.menu-content .right .btn {
    width: 116px;
    height: 46px;
    color: #fff;
    background: #0066CC;
}
.bg-title {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>