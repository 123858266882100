var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "rssb_main" }, [
    _c("div", { staticClass: "banner", on: { click: _vm.onBanner } }, [
      _c("img", {
        attrs: { src: require("@/assets/images/banner.jpg"), alt: "" },
      }),
    ]),
    _c(
      "div",
      { staticClass: "rssb_list" },
      _vm._l(_vm.carouselList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "rssb_list_item" },
          [
            _c("p", { staticClass: "common_font rssb_date" }, [
              _vm._v(_vm._s(item.date)),
            ]),
            _vm._l(item.data, function (itemA, index) {
              return _c(
                "div",
                { key: index, staticClass: "rssb-list_item_main" },
                [
                  _c("span", { staticClass: "round" }),
                  _c(
                    "div",
                    { staticClass: "rssb_list_item_date common_block" },
                    [
                      _c("span", { staticClass: "common_fonts itemA_data" }, [
                        _vm._v(_vm._s(itemA.time)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "list_container" },
                    _vm._l(itemA.itemList, function (itemB, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "list_item flex" },
                        [
                          _c("img", {
                            staticClass: "head_img",
                            attrs: {
                              src: require("@/assets/images/lizhien.png"),
                              alt: "",
                            },
                          }),
                          _c(
                            "div",
                            {
                              staticClass: "common_blocks",
                              staticStyle: { "text-align": "left" },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "common_font text_title" },
                                [_vm._v(_vm._s(itemB.title))]
                              ),
                              itemB.name
                                ? _c(
                                    "span",
                                    { staticClass: "common_fonts text_name" },
                                    [_vm._v("主讲人：" + _vm._s(itemB.name))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "btn",
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.goIframe(itemA)
                        },
                      },
                    },
                    [_vm._v("进入直播间")]
                  ),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }