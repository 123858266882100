class Player {
  constructor(isLive) {
      this.isLive = isLive
  }
  init(url, cover) {
      return new Aliplayer({
          id: "player-con",
          source: url,
          cover: cover,
          width: "100%",
          height: "500px",
          autoplay: true,
          isLive: this.isLive,
          rePlay: false,
          playsinline: true,
          preload: true,
          showBarTime: 3000,
          controlBarVisibility: "click",
          useH5Prism: true
      })
  }
}

export default Player