<template>
    <div class="have_class_hours">

        <transition name="fades">
            <div v-if="show">
                <div class="title-wrap" :style="{borderColor: $store.state.themeColor}">
                    <div class="title-left">
                        <h3 class="title" :style="{color: $store.state.themeColor}">已获得证书：{{obtain_total}}</h3>
                        <p>当前企业：{{$store.state.currentTenantName}}</p>
                    </div>
                    <a-button @click="back" :style="{background: $store.state.themeColor, color: '#fff', border: 'none', height: '40px'}">返回</a-button>
                </div>
            </div>
        </transition>

        <transition name="fades">
            <div v-if="show && data.length > 0">
                <div>
                    <a-table style="margin-bottom: 20px;" :columns="columns" :data-source="data" size="small" :pagination="false"/>
                    <a-pagination v-model="page" v-if="total > 10" @change="changePage"  size="small" :total="total" />
                </div>
            </div>

            <div class="empty" v-if="show && data.length < 1">
                <img src="../../assets/images/empty.png" alt="">
                <p>暂无数据</p>
            </div>
        </transition>

        <div class="loading" v-if="!show">
            <a-spin/>
        </div>
    </div>
</template>

<script>
import {
    getObtainData
} from '../../http/api'
export default {
    data() {
        return {
            columns: [
                {
                    title: '受训机构',
                    dataIndex: 'name',
                },
                {
                    title: '证书名称',
                    dataIndex: 'cert_name',
                },
                {
                    title: '获得时间',
                    dataIndex: 'time',
                },
                {
                    title: '证书来源',
                    dataIndex: 'resource_info',
                }
            ],
            data: [],
            page: 1,
            page_size: 10,
            total: Number,
            show: false,
            obtain_total: ''
        }
    },
    watch:{
        '$store.state.listenEnterprise'(n, o) {
            this.show = false
            this.page = 1
            this.update()
        }
    },
    mounted() {
        this.update()
    },
    methods: {
        update() {
            getObtainData({
                tenant_id: this.$store.state.tenant_id,
                platform_id: this.$store.state.product_id,
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                this.show = true
                if(res.data.code == 200) {
                    this.data = []
                    this.total = parseInt(res.data.ext.total)
                    this.obtain_total = res.data.ext.obtain_total
                    res.data.data.map((item, i) => {
                        this.data.push({
                            key: i,
                            name: item.tenant.tenant_name,
                            cert_name: item.certificate.certificate_name,
                            time: item.created_at,
                            resource_info: item.resource_info.title
                        })
                    })
                }
            })
        },
        changePage(page) {
            this.page = page
            this.update()
        },
        back() {
            this.$router.push({
                path: '/user/archives'
            })
        }
    }
}
</script>

<style scoped>
.have_class_hours {
    padding: 20px;
    min-height: 500px;
}
.title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}
.title {
    text-align: left;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 10px;
}
.title-left p {
    margin-bottom: 0;
}
.loading {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty {
    min-height: 500px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
}
.empty img {
    width: 121px;
    height: 74px;
}
</style>