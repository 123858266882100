<template>
    <div class="myclass">
        <div class="title-do-wrap">
          <a-input-search placeholder="可根据班级关键词搜索" style="width: 230px; height: 38px; margin-right: 20px;" @search="onSearch" />
          <a-checkbox @change="onChange" :defaultChecked='isCheck'>
            只查看进行中的班级
          </a-checkbox>
        </div>
        <div class="loadding" v-show="!show">
          <a-spin />
        </div>
        <transition name="fades">
        <div v-show="show">
          <div class="empty" v-if="classes.length == 0">
            <img src="../../assets/images/weipingjia.png" alt="">
            <span>暂无班级内容~</span>
          </div>
          <div class="tabContent" v-if="classes.length > 0">
            <ul class="itemWrap">
              <li class="tcItem" v-for="(item, i) in classes" :key="i">
                <div class="img">
                  <img  class="img_a" :src="item.picture" alt="">
                  <span class="ico" :style="{background: item.learn_status? $store.state.themeColor: '#ccc'}">{{item.learn_status_text}}</span>
                </div>
                <div class="info">
                  <div class="top">
                    <h3 class="title" @click="go(item, i)">{{item.title}}</h3>
                    <p class="describe">{{item.info}}</p>
                    <span class="describe">开课时间：{{item.is_forever? '永久有效': item.begin_time+'-'+item.end_time}}</span>
                    <ul class="class-sign">
                        <li>
                            <img src="../../assets/images/detail-icon2.png" alt="">
                            <span>课程：{{item.finish_course_totals}}/{{item.course_totals}}</span>
                        </li>
                        <li v-if="item.exam_totals != 0">
                            <img src="../../assets/images/detail-icon3.png" alt="">
                            <span>考核：{{item.finish_exam_totals}}/{{item.exam_totals}}</span>
                        </li>
                        <li v-if="item.cer_totals != 0">
                            <img src="../../assets/images/detail-icon4.png" alt="">
                            <span>证书：{{item.finish_cer_totals}}/{{item.cer_totals}}</span>
                        </li>
                        <li>
                            <img src="../../assets/images/detail-icon.png" alt="">
                            <span>已获课时：{{item.gain_credit}}/{{item.period}}</span>
                        </li>
                    </ul>
                  </div>
                </div>
                <div class="bot">
                  <a-button @click="go(item, i)" size="large" :style="{width: '140px', background: $store.state.themeColor, color: '#fff', borderColor: '#fff'}">进入班级</a-button>
                </div>
              </li>
            </ul>
            <a-pagination v-if="ext.total_pages > 1" @change="changePage" :defaultCurrent="page" :defaultPageSize="page_size" :total="total" />
          </div>
        </div>
        </transition>
        <a-modal v-model="visible" width="380px" :centered="true" :footer="null" :title="null">
      <div class="txt-wrap">
        <p class="sync-txt">该课程属于 {{tenant_name}} - {{platform_name}}, 是否切换平台？</p>
      </div>
      <div class="do-some">
        <a-button 
          @click="cancel"
          :style="{
            color: $store.state.themeColor+'!important',
            border: '1px solid'+ $store.state.themeColor+'!important',
            marginRight: '20px'
          }">取消</a-button>
        <a-button 
          @click="ok"
          :style="{
            background: $store.state.themeColor,
            color: '#fff!important'
          }">立即切换</a-button>
      </div>
    </a-modal>
    </div>
</template>

<script>
import {
  myClass
} from '../../http/api'
export default {
    data() {
        return {
          is_learned: '',//0
          classes: [],
          show: false,
          ext: '',
          isCheck: false,//true,
          keywords: '',
          visible: false,
          tenant_name: '',
          platform_name: '',
          domain: ''
        }
    },
    watch:{
      '$store.state.listenEnterprise'(n, o) {
        this.classes = []
        this.update()
      }
    },
    mounted() {
      this.update()
    },
    methods: {
      ok() {
        window.location.href = this.domain
      },
      cancel() {
        this.visible = false
      },
      onChange(e) {
        this.show = false
        this.isCheck = e.target.checked
        this.classes = []
        if(this.isCheck) {
          this.is_learned = 0
        } else {
          this.is_learned = ''
        }
        this.update()
      },
      onSearch(val) {
        // console.info(val)
        this.show = false
        this.classes = []
        this.keywords = val
        this.update()
      },
      update() {
        myClass({
          is_learned: this.is_learned,
          keywords: this.keywords,
          platform_id: this.$store.state.product_id,
          tenant_id: this.$store.state.tenant_id
        }).then(res => {
          if(res.data.code == 200) {
            this.show = true
            res.data.data.classes.map((item, i) => {
              this.classes.push(item)
            })
            this.ext = res.data.ext
          }
        })
      },
      go(item, i) {
        if(item.is_local == 0) {
          this.tenant_name = item.tenant.tenant_name
          this.platform_name = item.tenant.platform_name
          this.domain = item.tenant.domain
          this.visible = true
        } else {
          this.$router.push({
            path: '/class/catalog',
            query: {
              id: item.id
            }
          })
        }
      }
    }
}
</script>

<style scoped>

.empty {
  padding: 120px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
button {
  font-size: 14px;
}
.loadding {
  height: 500px;
}
.myclass .tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #0071f5;
  margin-bottom: 0;
}
.myclass .tab .item {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
}
.itemWrap {
  margin-bottom: 20px;
}
.tcItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
}
  .tcItem .describe {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    color: #666;
  }
  .info {
  flex: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.top {
  flex: 1;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  height: 18px;
  cursor: pointer;
      display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.tcItem .describe {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .img {
  width: 320px;
  height: 200px;
  margin-right: 20px;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}
.ico {
  width: 60px;
  height: 28px;
  line-height: 28px;
  color: #fff;
  position: absolute;
  left: 0;
  top: 10px;
  font-size: 12px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.img img {
  object-fit: cover;
}
.class-sign {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.class-sign li {
    margin-right: 20px;
    display: flex;
    align-items: center;
}
.class-sign li img {
    width: 16px;
    height: 14px;
    margin-right: 5px;
}
.class-sign li span {
    color: #000;
    font-size: 12px;
}
.title-do-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.do-some {
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-wrap {
  padding: 20px 0;
}
.sync-txt {
  font-weight: bold;
}
</style>