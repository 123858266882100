<template>
  <div class="home">
        <a-carousel arrows class="carousel" autoplay>
            <div
            slot="prevArrow"
            class="custom-slick-arrow"
            style="left: 10px;zIndex: 1"
            >
            <a-icon type="left-circle" />
            </div>
            <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
            <a-icon type="right-circle" />
            </div>
            <div class="carouselImgWrap" v-for="(item, i) in banners" :key="i">
              <router-link v-if="item.link_type == 2" :to="`/course/studydetail?id=${item.link}&course_type=system_course`">
                <img class="img_a" :src="item.image" alt="">
              </router-link>
              <router-link v-if="item.link_type == 1" to="" @click.native="goThree(item, i)">
                <img class="img_a" :src="item.image" alt="">
              </router-link>
              <router-link v-if="item.link_type == 3" :to="`/course/studydetail?id=${item.link}&course_type=tenant_course`">
                <img class="img_a" :src="item.image" alt="">
              </router-link>
            </div>
            <!-- <div style="color: #fff;font-size: 20px" v-show="banners.length == 0">暂无图片</div> -->
        </a-carousel>
        <div class="content">
            <ul class="flowWrap">
      <li class="item">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/flow-1.png" alt="">
          </div>
          <span class="txt">注册登录</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/flow-2.png" alt="">
          </div>
          <span class="txt">选择课程</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item" v-if="hostname == 'jxjy.zjttv.cn'">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/nflow1.png" alt="">
          </div>
          <span class="txt">在线支付</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/flow-3.png" alt="">
          </div>
          <span class="txt">在线学习</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/flow-4.png" alt="">
          </div>
          <span class="txt">在线考试</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item" v-if="hostname == 'jxjy.zjttv.cn'">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/nflow2.png" alt="">
          </div>
          <span class="txt">在线发证</span>
        </div>
        <div class="line"></div>
      </li>
      <li class="item">
        <div class="in">
          <div class="icon">
            <img src="../../assets/images/flow-5.png" alt="">
          </div>
          <span class="txt">完成学习</span>
        </div>
      </li>
    </ul>
            <div v-if="categorys.length != 0" class="product-list">
              <div class="titleWrap">
              <h3 class="title">{{categorys[0].name}}</h3>
              <span @click="more(1)" style="cursor: pointer">更多</span>
              </div>
              <ul class="product-content">
              <li @click="go(item, i)" v-for="(item, i) in list" :key='i'>
                <div class="hover-item">
                  <div class="front">
                    <div class="imgWrap pr">
                      <div class="class-icon">{{item.learn_status_text}}</div>
                      <img :src="item.picture" alt="">
                      <span v-show="item.type == 'live'" class="icons">直播</span>
                    </div>
                    <div class="msgWrap">
                      <p class="msgTitle">{{item.title}}</p>
                      <div class="price-item">
                        <div v-if="$store.state.can_pay == 1">
                          <span class="price-sign">￥</span>
                          <span class="price">{{item.price>0? item.price: '免费'}}</span>
                        </div>
                        <p class="classTime">{{item.period}}&nbsp;课时</p>
                      </div>

                    </div>
                  </div>
                  <div class="back">
                    <p class="back-title">{{item.title}}</p>
                    <p class="back-info">{{item.about}}</p>
                    <a-button type="default">去学习</a-button>
                  </div>
                </div>

              </li>
              </ul>
          </div>
            <!-- <Epidemic :categorys="categorys"></Epidemic> -->
            <Notice
            title="公告通知"
            width="376"
            height="306"
            :news = "news"
            :type = "'index'"
            ></Notice>


            <div v-if="categorys.length != 0 && hostname == 'jxjy.zjttv.cn'" class="product-list">
              <div class="titleWrap">
              <h3 class="title">{{categorys[1].name}}</h3>
              <span @click="more()" style="cursor: pointer">更多</span>
              </div>
              <ul class="product-content">
                <li @click="go(item, i)" v-for="(item, i) in list2" :key='i'>
                  <div class="hover-item">
                    <div class="front">
                      <div class="imgWrap">
                        <img :src="item.picture" alt="">
                        <span v-show="item.type == 'live'" class="icons">直播</span>
                      </div>
                      <div class="msgWrap">
                        <p class="msgTitle">{{item.title}}</p>
                        <div class="price-item">
                          <div v-if="$store.state.can_pay == 1">
                            <span class="price-sign">￥</span>
                            <span class="price">{{item.price>0? item.price: '免费'}}</span>
                          </div>
                          <p class="classTime">{{item.period}}&nbsp;课时</p>
                        </div>

                      </div>
                    </div>
                    <div class="back">
                      <p class="back-title">{{item.title}}</p>
                      <p class="back-info">{{item.about}}</p>
                      <a-button type="default">去学习</a-button>
                    </div>
                  </div>

                </li>
              </ul>
            </div>
            <div v-if="hostname == 'jxjy.zjttv.cn'">
              <div class="titleWrap">
                <h3 class="title">证书查询</h3>
                <span></span>
              </div>
              <div class="search-module" v-if="showModule">
                
                <div class="search-wrap">
                  <div class="search-item">
                    <label for="">编号</label>
                    <a-input placeholder='请输入证书编号'/>
                  </div>
                  <div class="search-item">
                    <label for="">身份证号</label>
                    <a-input placeholder="请输入身份证号"/>
                  </div>
                </div>
                <a-button @click="searchCert" class="search-btn" :style="{background: $store.state.themeColor,color: '#fff'}">查询</a-button>
              </div>
            </div>
            <div class="listModule">
              <div v-for="(item, i) in listFull" :key="i">
                <div class="titleWrap">
                  <h3 class="titles">{{item.name}}</h3>
                </div>
                <ul>
                  <li @click="go(items, i)" v-for="(items, i) in item.course" :key="i">
                    <div class="imgWrap">
                      <img :src="items.picture" alt="">
                      <span v-show="items.type == 'live'" class="icon">直播</span>
                    </div>
                    <h3 class="title">{{items.title}}</h3>
                    <div class="doWrap">
                      <div v-if="$store.state.can_pay == 1">
                        <span class="price-sign">￥</span>
                        <span class="price">{{items.price > 0? items.price: '免费'}}</span>
                      </div>
                      <p style="color: #888">{{items.period}}课时</p>
                      <div class="starWrap"><span class="starTxt">推荐指数：</span><van-rate size="14px" v-model="value" /></div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
        </div>
  </div>
</template>

<script>
import Notice from '@/components/notice/index.vue'
export default {
    data() {
        return {
          list: [],
          list2: [],
          hostname: '',
          showModule: true,
          value: 5
        }
    },
    props: {
      news: Array,
      banners: Array,
      categorys: Array,
      listFull: Array
    },
    components: {
      Notice
    },
    created() {
      this.hostname = window.location.hostname
      try {
          if(this.categorys.length != 0) {
            this.list = this.categorys[0].course.slice(0,3)
            this.list2 = this.categorys[1].course.slice(0,3)
          }
      } catch (error) {
        
      }
      
    },
    mounted() {

    },
    methods: {
      searchCert() {
        this.$message.error("暂未开放此功能！")
      },
      goThree(item, i) {
        window.open(item.link)
      },
      go(item, i) {
        if(item.join_type == 'class') {
          if(item.is_access) {
            this.$router.push({
              path: '/class/descript',
              query: {
                id: item.id,
              }
            })
          } else {
            this.$message.error('学员已禁用，不能访问')
          }
        } else {
          this.$router.push({
            path: '/course/studydetail',
            query: {
              id: item.id,
              course_type: item.course_type
            }
          })
          
        }
        
      },
      more(type) {
        this.$router.push({
          path: '/course?type='+type
        })
      }
    }
}
</script>

<style scoped>
.content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    background: url('../../assets/images/homeBg.png') no-repeat;
    background-position: 0 380px;
}
.ant-carousel >>> .slick-slide {
    text-align: center;
    /* height: 55vh;
    line-height: 55vh; */
    background: #fff;
    overflow: hidden;
  }

  .ant-carousel >>> .custom-slick-arrow {
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #fff;
    background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
  }
  .ant-carousel >>> .custom-slick-arrow:before {
    display: none;
  }
  .ant-carousel >>> .custom-slick-arrow:hover {
    opacity: 0.5;
  }

  .ant-carousel >>> .slick-slide h3 {
    color: #fff;
  }
  .carouselImgWrap img {
    object-fit: cover;
    max-height: 625px;
  }
  .title {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }
  .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
  }
  .product-list {
    width: 790px;
    padding-bottom: 30px;
  }
  .product-list .product-content {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
  }
  .titleWrap{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  .product-list .imgWrap {
    height: 170px;
    overflow: hidden;
    position: relative;
  }
    .product-list .imgWrap img{
    object-fit: cover;
  }
  .product-list .product-content li {
    width: 245px;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 27px;
  }
  .product-list .product-content li:hover .hover-item {
    /* transform: scale(1.1); */
    transform: rotateY(180deg);
  }
  .hover-item {
    transition: all .5s;
    border-radius: 6px;
    box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
    transform-style: preserve-3d;
    perspective: 1000px;
    height: 256px;
    position: relative;
  }

  .front {
    z-index: 222222;
  }
  .back {
    z-index: 1111111;
    height: 256px;
    background: #0071F5;
    width: 256px;
    transform: rotateY(180deg);
    padding: 40px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .back button {
    width: 86px;
    height: 28px;
    font-size: 14px;
    color: #0071F5;
  }
  .back-title {
    font-size: 20px;
    text-align: left;
    line-height: 20px;
    height: 20px;
    color: #fff;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
  .back-info {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    color: #fff;
    font-size: 12px;
    margin-bottom: 0;
    text-align: left;
  }
  .front, .back {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 6px;
    overflow: hidden;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }
  .msgWrap {
    background: #fff;
    height: 86px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .msgWrap .price-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price-item .price-sign {
    color: #aaa;
    margin-right: 5px;
  }
  .price-item .price {
    height: 18px;
    line-height: 18px;
    color:#D70000;
    font-size:18px;
  }
  .product-list .product-content li:nth-child(3n) {
    margin-right: 0;
  }
  .product-list .msgTitle {
    font-size: 18px;
    min-height: 20px;
    line-height: 20px;
    height: 20px;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    font-weight: bold;
    margin-bottom: 0;
  }
  .product-list .classTime {
    margin-bottom: 0;
    color: #aaa;
    line-height: 12px;
    text-align: left;
    padding-left: 10px;
  }
  .icons {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
  }
  .flowWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 30px 0;
  }
  .flowWrap .icon {
    width: 36px;
    height: 36px;
    margin-bottom: 10px;
  }
  .flowWrap .item {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;
  }
  @keyframes run {
    10%{-webkit-transform:rotate(0deg);}
    20%{-webkit-transform:rotate(10deg);}
    30%{-webkit-transform:rotate(0deg);}
    40%{-webkit-transform:rotate(-10deg);}
    50%{-webkit-transform:rotate(0deg);}
    60%{-webkit-transform:rotate(10deg);}
    70%{-webkit-transform:rotate(0deg);}
    80%{-webkit-transform:rotate(-10deg);}
    90%{-webkit-transform:rotate(0deg);}

  }
  .flowWrap .item:hover {
    animation:run .8s linear;
  }
  .flowWrap .in {
    width: 120px;
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background: #F5F7F9;
  }
  .flowWrap .line {
    width: 50px;
    border-bottom: 2px dashed #ccc;
    margin: 0 10px;
    height: 0;
    list-style: none;
  }
  .search-module {
    width: 376px;
    background: #fff;
    height:256px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-right: 10px;
    padding: 40px 20px;
    box-sizing: border-box;
    border: 1px solid #E9E9E9;
    border-radius: 4px;
  }
  .search-module h3 {
    text-align: left;
  }
  .search-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  .search-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
  }
  .search-item label {
    min-width: 70px;
    text-align: left;
  }
  .search-item input {
    width: 100%;
    height: 40px;
  }
  .search-module .search-btn {
    height: 40px;
    width: 190px;
    border-radius: 20px;
  }
  .pr {
    position: relative;
  }
  .class-icon {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999999999;
    background: #0071F5;
    color: #fff;
    border-top-left-radius: 6px;
    border-bottom-right-radius: 6px;
    padding: 4px 20px;
    font-size: 12px;
  }
  @media screen and (max-width: 1024px) {
    .content {
      width: 100%;
    }
    .ant-carousel >>> .slick-slide {
      height: 210px;
      line-height: 210px;
    }
    .carouselImgWrap {
      height: 210px;
    }
    .product-list {
      width: 100%;
    }
    .product-list .product-content {
      flex-wrap: wrap;
    }
    .product-list .product-content li {
      width: 100%;
      margin-right: 0;
    }
    .flowWrap .line {
      display: none;
    }
    .flowWrap .in {
      width: 68px;
      height: 68px;
    }
    .flowWrap .in .txt {
      font-size: 12px;
    }
    .flowWrap .icon {
      width: 24px;
      height: 24px;
      margin-bottom: 3px;
    }
    .flowWrap {
      justify-content: space-between;
      padding: 25px 0;
    }
  }
@media screen and (min-width:768px) and (max-width:1024px){
  .product-list .imgWrap {
    height: 420px;
  }
}
.listModule {
  width: 100%;
}
.listModule .titleWrap {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.listModule .titleWrap h3 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #000;
  overflow: hidden;
}
.listModule .starWrap {
  display: flex;
  align-items: center;
}
.listModule .starTxt {
  height: 14px;
  line-height: 14px;
  color: #888;
}
.listModule .titles {
  background: url("../../assets/images/titleBg.png") no-repeat;
  min-width: 160px!important;
  margin: 0 auto;
  font-size: 24px!important;
  line-height: 50px;
  height: 50px;
  background-size: 160px auto;
  background-position-y: 100%;
  background-position-x: center;
}
.listModule .doWrap {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
}
.listModule .doWrap p {
  margin-bottom: 0;
}
.listModule ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.listModule .title {
  font-size: 18px;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.listModule ul li {
  background: #fff;
  cursor: pointer;
  width: 360px;
  box-shadow: 5px 5px 30px 0px rgba(30, 126, 205, 0.08);
  border-radius: 4px;
  overflow: hidden;
  margin-right: 60px;
  transition: all .3s;
}
.listModule ul li:hover {
  transform: scale(1.05);
}
.listModule ul li:nth-child(3n) {
  margin-right: 0;
}
.listModule .imgWrap {
  height: 180px;
  margin-bottom: 10px;
  position: relative;
}
.listModule .imgWrap img {
  object-fit: cover;
}
.listModule .more {
  cursor: pointer;
}
.listModule .icon {
    background: rgb(255, 134, 17);
    color: #fff!important;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 8px;
    border-radius: 4px;
}
.listModule .price-sign {
    color: #aaa;
    margin-right: 5px;
}
.listModule .price {
  height: 18px;
  line-height: 18px;
  color:#D70000;
  font-size:18px;
}
@media screen and (max-width: 1024px) {
  .listModule ul li {
    margin-right: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .listModule .imgWrap {
    height: 200px;
  }
}
@media screen and (min-width:768px) and (max-width:1024px){
  .listModule .imgWrap {
    height: 480px;
  }
}
</style>
