<template>
    <div class="archives">
        <div class="title-wrap">
            <div class="title-left">
                <h3 class="title" :style="{borderColor: $store.state.themeColor}">学习档案</h3>
                <p>当前企业：{{$store.state.currentTenantName}}</p>
            </div>
            <a-button @click="goStudyReport" :style="{background: $store.state.themeColor, color: '#fff', border: 'none', height: '40px'}">预览学习报告</a-button>
        </div>
        <div class="loading" v-if="!show">
            <a-spin/>
        </div>
        <transition name="fades">
        <div v-if="show">
            <ul class="data-wrap">
                <li @click="haveClassHours">
                    <span class="num" :style="{color: $store.state.themeColor}">{{info.credit}}</span>
                    <div class="num-msg-wrap">
                        <span class="num-msg">已获课时</span>
                        <img src="../../assets/images/what.png" alt="">
                    </div>
                </li>
                <li @click="signdata">
                    <span class="num" :style="{color: $store.state.themeColor}">{{info.sign_times}}</span>
                    <div class="num-msg-wrap">
                        <span class="num-msg">签到次数</span>
                        <img src="../../assets/images/what.png" alt="">
                    </div>
                </li>
                <li @click="examPassNumber">
                    <span class="num" :style="{color: $store.state.themeColor}">{{info.pass_times}}</span>
                    <div class="num-msg-wrap">
                        <span class="num-msg">已通过考试</span>
                        <img src="../../assets/images/what.png" alt="">
                    </div>
                </li>
                <li @click="obtainedNumber">
                    <span class="num" :style="{color: $store.state.themeColor}">{{info.obtain_total}}</span>
                    <div class="num-msg-wrap">
                        <span class="num-msg">已获得证书</span>
                        <img src="../../assets/images/what.png" alt="">
                    </div>
                    
                </li>
            </ul>

            <div class="progress-wrap">
                <div class="progress-item">
                    <h3 class="sub-t" :style="{borderColor: $store.state.themeColor}">课程</h3>
                    <div class="progress-item-content">
                        <a-progress type="circle" :strokeColor="$store.state.themeColor" :percent="info.course.finish_rate" v-slot:format="percent, successPercent">
                            <div :style="{color: $store.state.themeColor, fontWeight: 'bold', marginBottom: '8px'}"><span style="font-size: 28px">{{percent}}</span><span style="font-size: 14px;">%</span></div>
                            <div style="font-size: 14px; font-weight: bold;">完成率</div>
                        </a-progress>
                        <div class="progress-item-msg">
                            <div>在学课程：{{info.course.learning_total}}</div>
                            <div>已完成课程：{{info.course.finish_total}}</div>
                        </div>
                    </div>
                </div>
                <div class="progress-item">
                    <h3 class="sub-t" :style="{borderColor: $store.state.themeColor}">考试</h3>
                    <div class="progress-item-content">
                        <a-progress type="circle" :strokeColor="$store.state.themeColor" :percent="info.exam.passed_rate" v-slot:format="percent, successPercent">
                            <div :style="{color: $store.state.themeColor, fontWeight: 'bold', marginBottom: '8px'}"><span style="font-size: 28px">{{percent}}</span><span style="font-size: 14px;">%</span></div>
                            <div style="font-size: 14px; font-weight: bold;">完成率</div>
                        </a-progress>
                        <div class="progress-item-msg">
                            <div>已通过：{{info.exam.passed_total}}</div>
                            <div>未通过：{{info.exam.unpassed_total}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </transition>
        

    </div>
</template>

<script>
import {
    getArchives
} from '../../http/api'
export default {
    data() {
        return {
            show: false,
            info: null
        }
    },
    watch:{
      '$store.state.listenEnterprise'(n, o) {
          this.update()
      }
    },
    mounted() {
        this.update()
    },
    methods: {
        goStudyReport() {
          this.$router.push({
              path: '/study_report'
          })  
        },
        haveClassHours() {
            this.$router.push({
                path: '/user/have_class_hours'
            })
        },
        signdata() {
            this.$router.push({
                path: '/user/signdata'
            })
        },
        examPassNumber() {
            this.$router.push({
                path: '/user/exam_pass_number'
            })
        },
        obtainedNumber() {
            this.$router.push({
                path: '/user/obtained_number'
            })
        },
        update() {
            getArchives({
                tenant_id: this.$store.state.tenant_id,
                platform_id: this.$store.state.product_id
            }).then(res => {
                this.show = true
                if(res.data.code == 200) {
                    this.info = res.data.data
                }
            })
        }
    }
}
</script>

<style scoped>
.archives {
    padding: 20px;
    min-height: 500px;
}
.title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.title {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 18px;
    font-size: 16px;
    margin-bottom: 15px;
}
.sub-t {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 8px;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    margin-bottom: 15px;
    margin-bottom: 40px;
}
.data-wrap {
    height: 140px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.data-wrap li {
    flex: 1;
    border-right: 1px solid #ddd;
    cursor: pointer;
}
.data-wrap li span {
    display: block;
}
.data-wrap li .num {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 5px;
    line-height: 32px;
}
.data-wrap li .num-msg-wrap .num-msg {
    font-size: 14px;
    color: #555;
    font-weight: bold;
}
.data-wrap li .num-msg-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 32px;
    height: 32px;
}
.data-wrap li .num-msg-wrap img {
    width: 32px;
    height: 32px;
}
.progress-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}
.progress-wrap .progress-item {
    flex: 1;
}
.progress-wrap .progress-item .progress-item-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 40px;
}
.progress-item-msg {
    font-weight: bold;
    font-size: 14px;
    color: #555;
    text-align: left;
    margin-left: 30px;
}
.progress-item-msg>div {
    padding: 5px 0;
}
.loading {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>