var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "player", staticStyle: { width: "100%" } },
    [
      _vm.mmm ? _c("div", { staticClass: "mmm" }) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _c("transition", { attrs: { name: "fades" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show && _vm.playerHeight,
                expression: "show && playerHeight",
              },
            ],
            staticClass: "playerWrap",
            staticStyle: { height: "100%" },
          },
          [
            _c("div", { staticClass: "n-top" }, [
              _c("div", { staticClass: "header" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/icon-lenove-back.png"),
                  },
                  on: { click: _vm.backWay },
                }),
                _c(
                  "p",
                  { staticClass: "header-back", on: { click: _vm.backWay } },
                  [_vm._v("返回课程详情")]
                ),
              ]),
              _c("p", { staticClass: "course-tit text-line-1" }, [
                _vm._v(_vm._s(_vm.course.title)),
              ]),
            ]),
            _c(
              "div",
              {
                ref: "nplay",
                staticClass: "player-inner",
                style: { height: _vm.playerHeight + "px" },
              },
              [
                _vm.course.type == "live"
                  ? _c("div", { staticClass: "online-icon" }, [
                      _vm._v("在线人数：" + _vm._s(_vm.max_online_num)),
                    ])
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.lessonOne.file_ext == "pdf",
                        expression: "lessonOne.file_ext == 'pdf'",
                      },
                    ],
                    staticClass: "pdf",
                  },
                  [
                    _c(
                      "pdf",
                      {
                        staticStyle: { width: "100%" },
                        attrs: {
                          src: _vm.pdfUrl,
                          page: _vm.pageNum,
                          rotate: _vm.pageRotate,
                        },
                        on: {
                          progress: function ($event) {
                            _vm.loadedRatio = $event
                          },
                          "page-loaded": function ($event) {
                            return _vm.pageLoaded($event)
                          },
                          "num-pages": function ($event) {
                            _vm.pageTotalNum = $event
                          },
                          error: function ($event) {
                            return _vm.pdfError($event)
                          },
                          "link-clicked": function ($event) {
                            _vm.page = $event
                          },
                        },
                      },
                      [_vm._v(">")]
                    ),
                  ],
                  1
                ),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.$store.state.playerVisible &&
                        _vm.lessonOne.file_ext != "pdf",
                      expression:
                        "$store.state.playerVisible && lessonOne.file_ext != 'pdf'",
                    },
                  ],
                  staticClass: "prism-player",
                  attrs: { id: "player-con" },
                }),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.lessonOne.file_ext == "mp3",
                        expression: "lessonOne.file_ext == 'mp3'",
                      },
                    ],
                    staticClass: "cover-mp3",
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.lessonOne.picture, alt: "" },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.course.type == "live" &&
                          _vm.lessonOne.live_status == "living",
                        expression:
                          "course.type == 'live' && lessonOne.live_status == 'living'",
                      },
                    ],
                    staticClass: "player-custom-controller",
                  },
                  [
                    _c("img", {
                      staticClass: "player-icon-controller",
                      attrs: {
                        src: !_vm.playIconFlag
                          ? require("../../../assets/images/play-icon-controller.png")
                          : require("../../../assets/images/pause-icon-controller.png"),
                        title: !_vm.playIconFlag ? "播放" : "暂停",
                      },
                      on: { click: _vm.toggle },
                    }),
                    _c("img", {
                      staticClass: "player-icon-controller",
                      staticStyle: { width: "22px", height: "22px" },
                      attrs: {
                        src: require("../../../assets/images/refresh-icon-controller.png"),
                        title: "刷新",
                      },
                      on: { click: _vm.refreshPlayer },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          !_vm.$store.state.playerVisible &&
                          _vm.lessonOne.file_ext != "pdf",
                        expression:
                          "!$store.state.playerVisible && lessonOne.file_ext != 'pdf'",
                      },
                    ],
                    staticClass: "errTips",
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.errTipsTxt))]),
                    _vm.$store.state.open_trial == 0
                      ? _c(
                          "a-button",
                          {
                            attrs: { size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.retry()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.errBtnTxt))]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.abnormal,
                        expression: "abnormal",
                      },
                    ],
                    staticClass: "player-mask",
                    style: {
                      height: _vm.videoHeight ? _vm.videoHeight + "px" : "100%",
                    },
                  },
                  [
                    _c("div", { staticClass: "title-mask" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../../assets/images/warn_mask.png"),
                          alt: "",
                        },
                      }),
                      _c("h3", [_vm._v("学习进度异常！")]),
                    ]),
                    _c("p", [_vm._v("1、是否存在拖动进度条的行为")]),
                    _c("p", [_vm._v("2、网络不稳定，导致记录错误")]),
                    _c(
                      "a-button",
                      {
                        style: {
                          width: "100px",
                          border: "none",
                          marginTop: "20px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.refreshPlayer()
                          },
                        },
                      },
                      [_vm._v("刷新重试")]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "player-bot-do" }),
              ]
            ),
            _vm.course.type == "normal"
              ? _c(
                  "div",
                  {
                    staticClass: "player-normal-menu",
                    style: {
                      width: _vm.sidebarWidth + "px",
                      height: _vm.playerHeight + "px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.packFlag,
                            expression: "!packFlag",
                          },
                        ],
                        staticClass: "player-menu-title-wrap",
                      },
                      [
                        _c("h3", { staticClass: "player-menu-title" }, [
                          _vm._v("课程目录"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.packFlag,
                            expression: "!packFlag",
                          },
                        ],
                        staticClass: "player-normal-item-scroll",
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "player-normal-item-wrap" },
                          _vm._l(_vm.chapters, function (item, i) {
                            return _c(
                              "li",
                              { key: i, staticClass: "player-normal-item" },
                              [
                                _vm.course.is_chapter
                                  ? _c("h3", { staticClass: "chapter" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ])
                                  : _vm._e(),
                                _vm._l(item.lessons, function (items, is) {
                                  return _c(
                                    "div",
                                    {
                                      key: is,
                                      staticClass: "item-wrap-flex",
                                      style: {
                                        background:
                                          _vm.checkId == items.id
                                            ? "#0063B1"
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.play(item, items, is)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "n-course-tit text-line-1",
                                          style: {
                                            color:
                                              _vm.checkId == items.id
                                                ? "#fff"
                                                : "#434347",
                                          },
                                        },
                                        [_vm._v(_vm._s(items.title))]
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "n-course-time",
                                          style: {
                                            color:
                                              _vm.checkId == items.id
                                                ? "#fff"
                                                : "#A1A1A3",
                                          },
                                        },
                                        [_vm._v(_vm._s(items.length_format))]
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.course.type == "live"
              ? _c(
                  "div",
                  {
                    staticClass: "playerModules",
                    style: { width: _vm.sidebarWidth + "px" },
                  },
                  [
                    !_vm.packFlag
                      ? _c(
                          "ul",
                          { staticClass: "tab" },
                          _vm._l(_vm.tab, function (item, i) {
                            return _c(
                              "li",
                              {
                                key: i,
                                style:
                                  _vm.index == i
                                    ? {
                                        background: _vm.$store.state.themeColor,
                                      }
                                    : {},
                                on: {
                                  click: function ($event) {
                                    return _vm.tabMethod(item, i)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "player-content" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.index == 0,
                              expression: "index == 0",
                            },
                          ],
                          staticClass: "player-list",
                          style: { height: _vm.replayWrapHeight + "px" },
                        },
                        _vm._l(_vm.replay_videos, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "player-item",
                              style:
                                _vm.listIndex == item.video_id
                                  ? {
                                      color: _vm.$store.state.themeColor,
                                      border: `1px solid ${_vm.$store.state.themeColor}`,
                                    }
                                  : {},
                              on: {
                                click: function ($event) {
                                  return _vm.checkList(item, i)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "player-item-wrap" }, [
                                _c("div", { staticClass: "playback" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "playback-icon",
                                      style:
                                        _vm.listIndex == item.video_id
                                          ? {
                                              borderColor:
                                                _vm.$store.state.themeColor,
                                            }
                                          : {},
                                    },
                                    [_vm._v("回放")]
                                  ),
                                  _c("p", { staticClass: "title" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]),
                                _c("div", [_vm._v(_vm._s(item.start_time))]),
                              ]),
                              _c("div", [_vm._v(_vm._s(item.percent) + "%")]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.index == 1,
                              expression: "index == 1",
                            },
                          ],
                          staticClass: "talkingRoom",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "txtWrap" },
                            _vm._l(_vm.talkingList, function (item, i) {
                              return _c("div", { key: i }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666",
                                      "font-size": "12px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.created_at))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "talkingItem",
                                    style:
                                      item.user_id == _vm.from_client_id
                                        ? { flexDirection: "row-reverse" }
                                        : {},
                                  },
                                  [
                                    _c("div", { staticClass: "pho" }, [
                                      _c("img", {
                                        attrs: { src: item.avatar, alt: "" },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "talkingInfo",
                                        style:
                                          item.user_id == _vm.from_client_id
                                            ? { alignItems: "flex-end" }
                                            : {},
                                      },
                                      [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(_vm._s(item.username)),
                                        ]),
                                        _c("p", { staticClass: "txt" }, [
                                          _vm._v(_vm._s(item.content)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "sendWrap" },
                            [
                              _c("a-input", {
                                staticClass: "sendTxt",
                                attrs: { placeholder: "请输入消息..." },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.sendMsg()
                                  },
                                },
                                model: {
                                  value: _vm.sendVal,
                                  callback: function ($$v) {
                                    _vm.sendVal = $$v
                                  },
                                  expression: "sendVal",
                                },
                              }),
                              _c(
                                "a-button",
                                {
                                  staticClass: "sendBtn",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendMsg()
                                    },
                                  },
                                },
                                [_vm._v("发言")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sidebar-btn",
                          staticStyle: { left: "-18px" },
                          on: {
                            click: function ($event) {
                              return _vm.packUp()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.packFlag
                                ? require("../../../assets/images/leftsidebar.png")
                                : require("../../../assets/images/rightsidebar.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "bot-div",
                      staticStyle: { height: "80px", background: "#151515" },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
      _vm._m(0),
      _c(
        "a-modal",
        {
          attrs: {
            title: "友情提示",
            footer: null,
            bodyStyle: { textAlign: "center", padding: "30px" },
            width: "700px",
          },
          model: {
            value: _vm.visibleThree,
            callback: function ($$v) {
              _vm.visibleThree = $$v
            },
            expression: "visibleThree",
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                staticClass: "img_a",
                attrs: { src: _vm.$store.state.logo, alt: "" },
              }),
            ]),
            _c("p", { staticClass: "notice" }, [
              _vm._v(
                "学员你好！" +
                  _vm._s(_vm.$store.state.website_name) +
                  "实行严格的学习审查制度，将实时监测学员账户的学习过程及学习行为。若发现账户存在异常，我们将对该账户及相关异常数据进行记录存档，并反馈给相关部门，由此带来的处理后果由学员自行承担。" +
                  _vm._s(_vm.$store.state.website_name) +
                  "提醒学员务必诚信学习，感谢支持！"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "checkWrap" },
            [
              _c("a-checkbox", { on: { change: _vm.onChange } }, [
                _vm._v("不再提示"),
              ]),
            ],
            1
          ),
          _c(
            "a-button",
            { staticClass: "okBtn", on: { click: _vm.handleOK3 } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            closable: false,
            maskClosable: false,
            title: "提示",
            footer: null,
            bodyStyle: { textAlign: "center" },
            width: "300px",
          },
          model: {
            value: _vm.visibleGo,
            callback: function ($$v) {
              _vm.visibleGo = $$v
            },
            expression: "visibleGo",
          },
        },
        [
          _c("p", [_vm._v("点击确定，继续学习")]),
          _c(
            "a-button",
            { staticClass: "okBtn", on: { click: _vm.handleOKGo } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _vm.visibleTest
        ? _c(
            "a-modal",
            {
              attrs: {
                bodyStyle: { padding: 0 },
                maskClosable: false,
                title: "随堂小测",
                footer: null,
                width: "500px",
              },
              model: {
                value: _vm.visibleTest,
                callback: function ($$v) {
                  _vm.visibleTest = $$v
                },
                expression: "visibleTest",
              },
            },
            [
              _c("div", { staticClass: "test-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.answerObjLength) + "/")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.questionArr.length))]),
              ]),
              _c(
                "div",
                { staticClass: "classes-test" },
                _vm._l(_vm.questionArr, function (item, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c("h3", [_vm._v(_vm._s(item.question.stem))]),
                      _vm.is_again == 1
                        ? _c("a-radio-group", {
                            attrs: {
                              name: item.question.id.toString(),
                              options: item.question.metas.choices,
                            },
                            on: { change: _vm.onChangeRadio },
                          })
                        : _vm._e(),
                      _vm._l(item.question.metas.choices, function (items, is) {
                        return _vm.is_again == 0
                          ? _c("div", { key: is, staticClass: "answer-item" }, [
                              _c("span", [_vm._v(_vm._s(items.label))]),
                              item.question.answer[0] == is
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../../assets/images/r.png"),
                                      alt: "",
                                    },
                                  })
                                : item.question.answer[0] == is &&
                                  item.choices.choice_status == "right"
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../../assets/images/r.png"),
                                      alt: "",
                                    },
                                  })
                                : item.choices.choice_answer[0] == is &&
                                  item.choices.choice_status == "wrong"
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../../assets/images/w.png"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "exam-btn-wrap" },
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.commitExam },
                    },
                    [_vm._v(_vm._s(_vm.is_again == 1 ? "提交" : "再测一次"))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "我要提问",
            footer: null,
            width: "500px",
          },
          model: {
            value: _vm.visibleQues,
            callback: function ($$v) {
              _vm.visibleQues = $$v
            },
            expression: "visibleQues",
          },
        },
        [
          _c("div", { staticClass: "qes-talk-wrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.lessonOne.title))]),
            _c(
              "div",
              { ref: "qestalkroom", staticClass: "qes-talk-room" },
              _vm._l(_vm.questionList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "qes-talk-item",
                    style: {
                      flexDirection: item.is_teacher ? "" : "row-reverse",
                    },
                  },
                  [
                    _c("div", { staticClass: "qes-people-wrap" }, [
                      _c("img", {
                        staticClass: "qes-talk-avatar",
                        attrs: { src: item.user.avatar, alt: "" },
                      }),
                      _c("span", { staticClass: "qes-talk-name" }, [
                        _vm._v(_vm._s(item.is_teacher ? "老师" : "我")),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "qes-talk-info",
                        style: { background: item.is_teacher ? "#f0f6fc" : "" },
                      },
                      [
                        _c(
                          "p",
                          {
                            style: { color: item.is_teacher ? "#0071F5" : "" },
                          },
                          [_vm._v(_vm._s(item.content))]
                        ),
                        _c("span", [_vm._v(_vm._s(item.created_time))]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _c("p", { staticClass: "refresh", on: { click: _vm.refresh } }, [
              _vm._v("刷新"),
            ]),
            _c(
              "div",
              { staticClass: "qes-do-wrap" },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.askContent,
                      expression: "askContent",
                    },
                  ],
                  staticClass: "qes-txt",
                  attrs: { placeholder: "在这里输入想问老师的问题吧～" },
                  domProps: { value: _vm.askContent },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.askContent = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitQestion },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "学习前的准备工作",
            footer: "",
            maskClosable: false,
            centered: true,
          },
          on: { cancel: _vm.photoCancel },
          model: {
            value: _vm.photoVisible,
            callback: function ($$v) {
              _vm.photoVisible = $$v
            },
            expression: "photoVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "people-module" },
            [
              _vm.open_check_photo == 1
                ? _c(
                    "div",
                    {
                      staticClass: "item-bg",
                      style: {
                        background:
                          _vm.is_id_photo == 1 ? "#fff1f0" : "#f6ffed",
                      },
                    },
                    [
                      _c("div", { staticClass: "people-title" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.is_id_photo == 1
                                ? require("../../../assets/images/er.png")
                                : require("../../../assets/images/suc.png"),
                            alt: "",
                          },
                        }),
                        _c("h3", [_vm._v("证件照上传")]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.is_id_photo == 1
                              ? "课程已配置需证件照的证书，请前往个人中心上传证件照"
                              : "已上传"
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.$store.state.open_check_realname == 1
                ? _c(
                    "div",
                    {
                      staticClass: "item-bg",
                      style: {
                        background:
                          _vm.is_certification_idcard == 1
                            ? "#fff1f0"
                            : "#f6ffed",
                      },
                    },
                    [
                      _c("div", { staticClass: "people-title" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.is_certification_idcard == 1
                                ? require("../../../assets/images/er.png")
                                : require("../../../assets/images/suc.png"),
                            alt: "",
                          },
                        }),
                        _c("h3", [_vm._v("实名认证")]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.is_certification_idcard == 1
                              ? "课程已开通实名认证，请前往个人中心进行实名认证"
                              : "已上传"
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  style: { border: "none" },
                  attrs: { size: "large" },
                  on: { click: _vm.goUser },
                },
                [_vm._v("前往补全信息")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "320px",
            title: null,
            footer: null,
            centered: true,
            maskClosable: false,
            closable: false,
          },
          model: {
            value: _vm.$store.state.tryVisible,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state, "tryVisible", $$v)
            },
            expression: "$store.state.tryVisible",
          },
        },
        [
          _c("div", { staticClass: "try-content" }, [
            !_vm.$store.state.token && _vm.lessonOne.file_ext != "pdf"
              ? _c("p", [_vm._v("试看已结束，请登录后继续观看！")])
              : _vm._e(),
            _vm.$store.state.token &&
            _vm.course.order_status == 0 &&
            _vm.lessonOne.file_ext != "pdf"
              ? _c("p", [_vm._v("试看已结束，请购买后继续观看！")])
              : _vm._e(),
            _vm.lessonOne.file_ext == "pdf" && !_vm.$store.state.token
              ? _c("p", [_vm._v("文档不支持试看，请登录后观看")])
              : _vm._e(),
            _vm.lessonOne.file_ext == "pdf" &&
            _vm.$store.state.token &&
            _vm.course.order_status == 0
              ? _c("p", [_vm._v("文档不支持试看，请购买后学习！")])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "try-btn-wrap" },
              [
                _c(
                  "a-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.tryCancel()
                      },
                    },
                  },
                  [_vm._v("退出")]
                ),
                !_vm.$store.state.token
                  ? _c(
                      "a-button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.tryOk()
                          },
                        },
                      },
                      [_vm._v("确定")]
                    )
                  : _vm._e(),
                _vm.$store.state.token && _vm.course.order_status == 0
                  ? _c(
                      "a-button",
                      {
                        style: { background: "rgb(255, 134, 17)" },
                        on: {
                          click: function ($event) {
                            return _vm.tryBuy()
                          },
                        },
                      },
                      [_vm._v("立即购买")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "300px",
            title: null,
            footer: null,
            closable: false,
            centered: true,
            maskClosable: false,
          },
          model: {
            value: _vm.examVisible,
            callback: function ($$v) {
              _vm.examVisible = $$v
            },
            expression: "examVisible",
          },
        },
        [
          _c("div", { staticClass: "exam-content" }, [
            _c("p", [
              _vm._v("恭喜！学习已完成！该课程已设置考试环节，点击前往考试"),
            ]),
            _c(
              "div",
              { staticClass: "exam-do-wrap" },
              [
                _c("a-button", { on: { click: _vm.waiting } }, [
                  _vm._v("再等等"),
                ]),
                _c(
                  "a-button",
                  {
                    style: { border: "none", marginLeft: "20px" },
                    on: { click: _vm.doExam },
                  },
                  [_vm._v("去考试")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("remote-css", {
        attrs: {
          href: "https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css",
        },
      }),
      _c("remote-js", {
        attrs: {
          src: "https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "n-play-foot" }, [
      _c("img", {
        attrs: { src: require("../../../assets/images/icon-course-foot.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }