<template>
    <div class="error">
        <div class="img" style="margin-bottom: 30px">
            <img src="../../assets/images/error.png" alt="">
        </div>
        <div style="margin-bottom: 20px">域名访问错误...！请确定域名是否正确！</div>
        <a-button :style="{background: $store.state.themeColor,color:'#fff'}" @click="goIndex()">返回首页</a-button>
    </div>
</template>

<script>
export default {
    methods: {
        goIndex() {
            this.$router.push({
                path: '/',
                query: {
                    ref: 404
                }
            })
        }
    }
}
</script>

<style scoped>
    .error {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        color: #ddd;
    }
    .img {
        width: 50px;
        height: 50px;
    }
</style>