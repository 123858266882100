<template>
    <div class="class-wrap">
        <div class="all-bg" :style="$store.state.template == 1?{backgroundImage: 'url('+classes.picture+')',backgroundRepeat: 'no-repeat',backgroundSize: 'cover'}: {}">
            <div style="background: #fff;opacity: 0.9;">
                <div class="inner">
                    <div class="info">
                        <div class="info-img">
                            <img class="info-img" :src="classes.picture" alt="">
                        </div>
                        <div class="info-txt">
                            <h3 class="title">{{classes.title}}</h3>
                            <p class="about">{{classes.about}}</p>
                            <p class="class-time">开课时间：{{classTime}}</p>
                            <ul class="class-sign">
                                <li>
                                    <img src="../../assets/images/detail-icon.png" alt="">
                                    <span>课时数  {{classes.period}}</span>
                                </li>
                                <li>
                                    <img src="../../assets/images/detail-icon2.png" alt="">
                                    <span>课程数  {{classes.course_num}}</span>
                                </li>
                                <li v-if="classes.has_test">
                                    <img src="../../assets/images/detail-icon3.png" alt="">
                                    <span>需要考核</span>
                                </li>
                                <li v-if="classes.has_certificate">
                                    <img src="../../assets/images/detail-icon4.png" alt="">
                                    <span>颁发证书</span>
                                </li>
                            </ul>
                            <div class="price-wrap" v-if="$store.state.can_pay && classes.order_status == 0">
                                <span>价格：</span>
                                <span>{{classes.price > 0? '￥'+classes.price: '免费'}}</span>
                            </div>
                            <div class="info-do">
                                
                                <el-button :disabled="!classes.buyable" v-if="$store.state.can_pay && classes.order_status == 0" class="join-btn" :style="{background: !classes.buyable?'#ccc':$store.state.themeColor}" @click="buy">立即购买</el-button>
                            
                                <span>{{classes.learn_total}}名学员已开始学习</span>
                               <span v-if="classes" style="padding-left:20px;">{{!classes.personal_access?'非公开':'公开'}} <span v-if="classes.member_limit">|</span> {{classes.member_limit==0?'':'学员人数'+classes.join_total+'/'+classes.member_limit}}</span>
                                
                            </div>
                            <div v-if="$store.state.open_share == 1" style="width: 100%;display: flex;flex-direction: column;align-items: flex-end;padding-top: 20px;">
                                <Share :imgData="share_img"></Share>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="inner">
            

            <div class="classes-content">
                <ul class="side-bar">
                    <li :class="item.id == select_id?'ac': ''" v-for="(item, i) in sideBar" :key="i" @click="checkTab(item, i)">
                        <img v-if="item.id != select_id" class="cicon" :src="require('../../assets/images/'+item.src+'.png')" alt="">
                        <img v-if="item.id == select_id" class="cicon" :src="require('../../assets/images/'+item.selectSrc+'.png')" alt="">
                        <span :style="{color: item.id == select_id? '#0071F5': ''}">{{item.val}}</span>
                    </li>
                </ul>
                
                <router-view :classes="classes" :wares="wares" :authors="authors" class="class-view" />
            </div>
        </div>
        <a-modal v-model="visible" :closable="false" title="温馨提示">
            <span style="min-height: 100px;display: inline-block;"><a-icon type="exclamation-circle" style="color:orange;padding-right:14px;"/>{{model_text}}</span>
            <template slot="footer">
                <a-button key="back" @click="onSubmit">
                确认
                </a-button>
            
            </template>
        </a-modal>
    </div>
</template>

<script>
    import {
        classDetail
    } from '../../http/api'
    import Share from '@/components/share/index.vue'
    export default {
        name: "index.vue",
        data() {
            return {
                model_text:'学员人数已达上限',
                visible:false,
                classTime: '',
                class_id: '',
                classes: {
                    join_total:'',
                    member_limit:''
                },
                prev_course: '',
                authors: [],
                sideBar: [
                    {
                        id: 1,
                        val: '班级介绍',
                        src: 'cicon',
                        selectSrc: 'cicons',
                        path: '/class/descript'
                    },
                    {
                        id: 2,
                        val: '课程目录',
                        src: 'cicon1',
                        selectSrc: 'cicon1s',
                        path: '/class/catalog'
                    },
                    {
                        id: 7,
                        val: '在线考试',
                        src: 'cicon6',
                        selectSrc: 'cicon6s',
                        path: '/class/exam'
                    },
                    {
                        id: 3,
                        val: '互动问答',
                        src: 'cicon2',
                        selectSrc: 'cicon2s',
                        path: '/class/linkup'
                    },
                    {
                        id: 4,
                        val: '相关证书',
                        src: 'cicon3',
                        selectSrc: 'cicon3s',
                        path: '/class/classcert'
                    },
                    {
                        id: 5,
                        val: '讲师团队',
                        src: 'cicon4',
                        selectSrc: 'cicon4s',
                        path: '/class/lecturer'
                    },
                    {
                        id: 6,
                        val: '学习资料',
                        src: 'cicon5',
                        selectSrc: 'cicon5s',
                        path: '/class/studydata'
                    }
                ],
                select_id: 1,
                wares: [],
                share_img: String
            }
        },
        components: {
            Share
        },
        watch: {
            '$route.path'(n, o) {
                this.sideBar.map((item, i) => {
                    if(item.path == n) {
                        this.select_id = item.id
                    }
                })
            }
        },
       
        mounted() {
            if(this.$store.state.hostname == 'lyedu.zjttv.cn') {
                this.sideBar = [
                    {
                        id: 1,
                        val: '班级介绍',
                        src: 'cicon',
                        selectSrc: 'cicons',
                        path: '/class/descript'
                    },
                    {
                        id: 2,
                        val: '课程目录',
                        src: 'cicon1',
                        selectSrc: 'cicon1s',
                        path: '/class/catalog'
                    },
                    {
                        id: 5,
                        val: '讲师团队',
                        src: 'cicon4',
                        selectSrc: 'cicon4s',
                        path: '/class/lecturer'
                    }
                ]
            }
            this.class_id = this.$route.query.id
            this.sideBar.map((item, i) => {
                if(item.path == this.$route.path) {
                    this.select_id = item.id
                }
            })
            let that = this;
            classDetail({
                id: this.class_id
            }).then(res => {
                if(res.data.code == 200) {
                    console.log('111111111111')
                    let data = res.data.data.class;
                    this.classes = res.data.data.class
                    this.prev_course = res.data.prev_course
                    this.wares = res.data.data.wares
                    this.authors = res.data.data.authors
                    this.share_img = res.data.data.share_img
                    this.$store.commit('ISCLASSCERTIFICATE',res.data.data.class.is_class_certificate)
                    console.log('data.is_member_limit',data.is_member_limit)
                    if(data.is_member_limit){
                        that.visible = true;
                    }

                    if(this.classes.is_forever) {
                        this.classTime = '永久'
                    } else {
                        this.classTime = this.classes.begin_time.split(':')[0]+':'+this.classes.begin_time.split(':')[1]+' 至 '+this.classes.end_time.split(':')[0]+':'+this.classes.end_time.split(':')[1]
                    }
                }else{
                    that.visible = true;
                    that.model_text = res.data.message;
                }
            })
        },
        // mounted() {

        // },
        methods: {
            onSubmit(){
                this.$router.go(-1);
            },
            checkTab(item, i) {
                this.$router.push({
                    path: item.path,
                    query: {
                        id: this.class_id
                    }
                })
            },
            buy() {
                if(this.classes.buyable || this.classes.is_member_limit == 0) {
                    this.$router.push({
                        path: '/pay',
                        query: {
                            course_id: this.classes.course_id,
                            course_type: this.classes.course_type
                        }
                    })
                } else {
                    if(this.classes.buyable == 0) {
                        this.$message.error("该班级已禁止购买！")
                    } else if(this.classes.is_member_limit) {
                        this.$message.error("该课程暂不支持购买！")
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .class-wrap {
        padding-bottom: 20px;
    }
    .info {
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        margin-bottom: 30px;
    }
    .inner {
        width: 1200px;
        margin: 0 auto;
    }
    .info-txt {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .info-txt .title {
        text-align: left;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 12px;
        line-height: 28px;
    }
    .info-txt .about {
        color: #666;
        font-size: 14px;
        text-align: left;
        margin-bottom: 15px;
        min-height: 44px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
    .info-txt .class-time {
        text-align: left;
        color: #666;
        font-size: 12px;
        margin-bottom: 10px;
    }
    .info-txt .price-wrap {
        min-height: 50px;
        background: #E1E8EB;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin-bottom: 10px;
    }
    .info-txt .price-wrap span:first-child {
        font-size: 14px;
        color: #666;
    }
    .info-txt .price-wrap span:last-child {
        font-size: 20px;
        color: #FC583D;
    }
    .info-txt .info-do {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
    }
    .info-txt .info-do span{
        font-size: 12px;
        color: #666;
    }
    .info-txt .info-do .join-btn {
        width: 120px;
        height: 40px;
        color: #fff;
        margin-right: 20px;
    }
    .info-img {
        width: 470px;
        height: 100%;
        margin-right: 20px;
    }
    .info-img img {
        object-fit: cover;
    }
    .classes-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .classes-content .side-bar {
        width: 200px;
        min-width: 200px;
        border: 1px solid #EBEFF2;
        padding-top: 20px;
        border-radius: 4px;
        margin-right: 20px;
    }
    .classes-content .side-bar li {
        height: 34px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .ac {
        background-image: linear-gradient(to right, #fff , #cde4ff);
    }
   .classes-content .side-bar li .cicon {
       width: 20px;
       height: 20px;
       margin-right: 10px;
   }
   .classes-content .side-bar li span {
       font-size: 14px;
       line-height: 14px;
   }
    .classes-content .class-view {
        flex: 1;
        box-sizing: border-box;
        border-radius: 4px;
    }
    .class-sign {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;
    }
    .class-sign li {
        margin-right: 30px;
        display: flex;
        align-items: center;
    }
    .class-sign li img {
        width: 16px;
        height: 14px;
        margin-right: 5px;
    }
    .class-sign li span {
        color: #666;
        font-size: 12px;
    }
</style>