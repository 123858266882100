<template>
  <div class="home" :class="{Box:$store.state.is_global_gray}">
    <div class="loadding" v-show="!show">
      <a-spin />
    </div>
    <transition name="fades">
      <div v-if="show && $store.state.tenant_idBind!='2865'&&$store.state.tenant_idBind!='3462'">
        <!-- 3462 -->
        <div v-if="$store.state.template == 1">
          <HomeOne v-if="show" :news="news" :banners="banners" :categorys="categorys" :listFull="listFull"></HomeOne>
        </div>
        <div v-if="$store.state.template == 2">
          <HomeTwo v-if="show" :news="news" :banners="banners" :categorys="categorys" :lives="lives" :listFull="listFull"></HomeTwo>
        </div>
        <div v-if="$store.state.template == 3">
          <HomeThr v-if="show" :news="news" :banners="banners" :categorys="categorys" :lives="lives" :listFull="listFull" :top_cates="top_cates"></HomeThr>
        </div>
      </div>
      <div v-if="show && $store.state.tenant_idBind=='3462'">
        <!-- 3462 -->
        <HomeFour v-if="show" :news="news" :banners="banners" :categorys="categorys" :lives="lives" :listFull="listFull" :listssss="listssss"></HomeFour>
      </div>
      <div v-if="show && $store.state.tenant_idBind=='2865'">
         <HomeRssb/>
      </div>
    </transition>
  </div>
</template>

<script>
import HomeOne from '@/components/home_template/one.vue'
import HomeTwo from '@/components/home_template/two.vue'
import HomeThr from '@/components/home_template/thr.vue'
import HomeFour from '@/components/home_template/four.vue'
import HomeRssb from '@/components/home_template/rssb.vue'
import {
  sitList,
  cateMore
} from '../../http/api'
import coo from '../../common/cookie.js'
export default {
    data() {
        return {
          news: [],
          banners: [],
          categorys: [],
          lives: [],
          listFull: [],
          top_cates: [],
          show: false,
          total_pages: 0,
          listssss:{}
        
        }
    },
    
    components: {
      HomeOne,
      HomeTwo,
      HomeThr,
      HomeRssb,
      HomeFour
    },
    created() {
      console.log('this.listssss', this.listssss);
      let page
      if(this.$store.state.template == 1) {
        page = 3
      } else if (this.$store.state.template == 2) {
        page = 4
      } else if (this.$store.state.template == 3) {
        if(this.$store.state.hostname == this.$store.state.demonstrationHostname) {
          page = 3
        } else {
          page = 4
        }
      } else if (this.$store.state.template == 4) {
        
        const path = coo.getLinkCookie()
        console.error(path)
        if (path) {
          this.$router.push({
          path: path
        })
        } else {
          this.$router.push({
            path: '/course'
          })
        }
        return
      }
      sitList({
        page_size: 5//page
      }).then(res => {
          this.news = res.data.data.notices
          this.banners = res.data.data.banners
          this.categorys = res.data.data.categorys
          this.lives = res.data.data.lives
          this.top_cates = res.data.data.top_cates
          this.total_pages = res.data.ext.total_pages
          this.show = true
          this.listssss = res.data.data.dangjian
          this.listFull = this.categorys.filter((val, i) => {
            if(this.$store.state.template == 1) {
              return i != 0
            } else if(this.$store.state.template == 2 || this.$store.state.template == 3) {
              if(this.$store.state.can_class == 1) {
                return i != 0
              } else {
                return i != -1
              }
            }
          })
      })
    },
    mounted() {
      let page = 2
      let flag = true
      window.onscroll = () => {
        let seeH = document.body.offsetHeight
        let docH = document.body.scrollHeight
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        if(this.$route.path == '/') {
          if(this.$store.state.template == 1) {
            if(scrollTop > 180) {
              this.$store.commit("ISINDEX", false)
            } else {
              this.$store.commit("ISINDEX", true)
            }
          } else if(this.$store.state.template == 2) {
            if(scrollTop > 120) {
              this.$store.commit("ISINDEX", false)
            } else {
              this.$store.commit("ISINDEX", true)
            }
          }

          if(docH - (seeH + scrollTop) < 800) {
            if(flag && this.total_pages > 1) {
              flag = false
              cateMore({
                page: page
              }).then(res => {
                if(res.data.code == 200) {
                  res.data.data.map((item, i) => {
                    if(item.course.length > 0) {
                      this.listFull.push(item)
                    }
                  })
                  if(page < res.data.ext.total_pages) {
                    flag = true
                    page++
                  }
                }
              })
            }
          } 
        }
      }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>
