var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "inner" }, [
      _c("div", { staticClass: "top-wrap" }, [
        _c(
          "div",
          {
            staticClass: "banner",
            style: {
              width:
                _vm.$store.state.tenant_idBind == 1202 ||
                _vm.$store.state.tenant_idBind == 1226 ||
                _vm.$store.state.tenant_idBind == 1299
                  ? "1200px"
                  : "750px",
            },
          },
          [
            _c(
              "a-carousel",
              { attrs: { arrows: "", autoplay: "", dotPosition: "right" } },
              [
                _c(
                  "div",
                  {
                    staticClass: "custom-slick-arrow",
                    staticStyle: { left: "10px", zIndex: "1" },
                    attrs: { slot: "prevArrow" },
                    slot: "prevArrow",
                  },
                  [_c("a-icon", { attrs: { type: "left-circle" } })],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "custom-slick-arrow",
                    staticStyle: { right: "10px" },
                    attrs: { slot: "nextArrow" },
                    slot: "nextArrow",
                  },
                  [_c("a-icon", { attrs: { type: "right-circle" } })],
                  1
                ),
                _vm.banners.length == 0
                  ? _c(
                      "div",
                      { staticClass: "carouselImgWrap" },
                      [
                        _c("router-link", { attrs: { to: "" } }, [
                          _c("img", {
                            staticClass: "img_a",
                            attrs: {
                              src: "https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg",
                              alt: "",
                            },
                          }),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._l(_vm.banners, function (item, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "carouselImgWrap" },
                    [
                      _vm.banners.length == 0
                        ? _c("router-link", { attrs: { to: "" } }, [
                            _c("img", {
                              staticClass: "img_a",
                              attrs: {
                                src: "https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg",
                                alt: "",
                              },
                            }),
                          ])
                        : _vm._e(),
                      item.link_type == 2
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: `/course/studydetail?id=${item.link}&course_type=system_course`,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "img_a",
                                attrs: { src: item.image, alt: "" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      item.link_type == 1
                        ? _c(
                            "router-link",
                            {
                              attrs: { to: "" },
                              nativeOn: {
                                click: function ($event) {
                                  return _vm.goThree(item, i)
                                },
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "img_a",
                                attrs: { src: item.image, alt: "" },
                              }),
                            ]
                          )
                        : _vm._e(),
                      item.link_type == 3
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: `/course/studydetail?id=${item.link}&course_type=tenant_course`,
                              },
                            },
                            [
                              _c("img", {
                                staticClass: "img_a",
                                attrs: { src: item.image, alt: "" },
                              }),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ],
          1
        ),
        _vm.$store.state.tenant_idBind != 1202 &&
        _vm.$store.state.tenant_idBind != 1226 &&
        _vm.$store.state.tenant_idBind != 1299
          ? _c("div", { staticClass: "news" }, [
              _c("div", { staticClass: "title-bar" }, [
                _vm._m(0),
                _c(
                  "span",
                  { staticClass: "more", on: { click: _vm.moreNotice } },
                  [_vm._v("查看全部 >")]
                ),
              ]),
              _vm.news.length > 0
                ? _c(
                    "ul",
                    { staticClass: "news-wrap" },
                    _vm._l(_vm.news, function (item, i) {
                      return _c("li", { key: i }, [
                        _c("div", { staticClass: "date" }, [
                          _c("span", { staticClass: "date-day" }, [
                            _vm._v(
                              _vm._s(
                                item.created_at.split("-")[2].split(" ")[0]
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "dates" }, [
                            _vm._v(
                              _vm._s(
                                `${item.created_at.split("-")[0]}/${
                                  item.created_at.split("-")[1]
                                }`
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "news-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "news-title",
                              on: {
                                click: function ($event) {
                                  return _vm.goNotice(item, i)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c("p", { staticClass: "news-info" }, [
                            _vm._v(_vm._s(item.infos)),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm.news.length == 0
                ? _c(
                    "div",
                    {
                      staticClass: "empty",
                      staticStyle: { height: "380px", background: "#FAFAFA" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/empty.png"),
                          alt: "",
                        },
                      }),
                      _c(
                        "span",
                        { staticStyle: { color: "#666", "font-size": "12px" } },
                        [_vm._v("暂无数据")]
                      ),
                    ]
                  )
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _vm.$store.state.tenant_idBind != 989 &&
      _vm.$store.state.tenant_idBind != 1202 &&
      _vm.$store.state.tenant_idBind != 1226 &&
      _vm.$store.state.tenant_idBind != 1299 &&
      _vm.$store.state.tenant_idBind != 1222
        ? _c("div", { staticClass: "flower" }, [
            _vm._m(1),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(2),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(3),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(4),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(5),
          ])
        : _vm._e(),
      _vm.$store.state.tenant_idBind == 1222
        ? _c("div", { staticClass: "flower" }, [
            _vm._m(6),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(7),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(8),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(9),
            _c("img", {
              staticClass: "flower-sign",
              attrs: {
                src: require("../../assets/images/flower-sign.png"),
                alt: "",
              },
            }),
            _vm._m(10),
          ])
        : _vm._e(),
      _vm.$store.state.tenant_idBind == 1202 ||
      _vm.$store.state.tenant_idBind == 1226 ||
      _vm.$store.state.tenant_idBind == 1299
        ? _c("div", { staticClass: "tem-user-wrap" }, [
            _c(
              "div",
              {
                staticClass: "notice",
                staticStyle: { flex: "1", height: "140px" },
              },
              [
                _c("div", { staticClass: "titleWrap" }, [
                  _c("p"),
                  _c(
                    "span",
                    {
                      staticClass: "more",
                      on: {
                        click: function ($event) {
                          return _vm.more("news")
                        },
                      },
                    },
                    [_vm._v("更多")]
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "notice-content" },
                  [
                    _c(
                      "li",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.news.length == 0,
                            expression: "news.length == 0",
                          },
                        ],
                      },
                      [_vm._v("暂无数据")]
                    ),
                    _vm._l(_vm.news, function (item, i) {
                      return _c(
                        "li",
                        {
                          key: i,
                          style:
                            _vm.index == i
                              ? { color: _vm.$store.state.themeColor }
                              : {},
                          on: {
                            click: function ($event) {
                              return _vm.goNews(item, i)
                            },
                            mouseover: function ($event) {
                              return _vm.addColor(item, i)
                            },
                            mouseout: function ($event) {
                              return _vm.removeColor(item, i)
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "notice-item-title" }, [
                            _c("span", { staticClass: "icon" }, [
                              _vm._v("New"),
                            ]),
                            _c("p", { staticClass: "articleT" }, [
                              _vm._v(_vm._s(item.title)),
                            ]),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(item.created_at.split(" ")[0])),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]
            ),
            _vm._m(11),
          ])
        : _vm._e(),
      _vm.$store.state.can_live && _vm.lives.length > 0
        ? _c("div", { staticClass: "live-module" }, [
            _c("div", { staticClass: "title-bar" }, [
              _vm._m(12),
              _vm.lives.length >= 2
                ? _c(
                    "span",
                    {
                      staticClass: "more",
                      on: {
                        click: function ($event) {
                          return _vm.more("live")
                        },
                      },
                    },
                    [_vm._v("查看全部 >")]
                  )
                : _vm._e(),
            ]),
            _vm.lives.length > 0
              ? _c(
                  "ul",
                  { staticClass: "live-list" },
                  _vm._l(_vm.lives, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        staticClass: "live-item",
                        on: {
                          click: function ($event) {
                            return _vm.go(item, i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "live-top" }, [
                          _c("img", {
                            attrs: { src: item.authors[0].avatar, alt: "" },
                          }),
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                        _c("div", { staticClass: "live-bot" }, [
                          _c("span", { staticClass: "name" }, [
                            _vm._v(_vm._s(item.authors[0].name)),
                          ]),
                          _vm.liveStatus(item.start_time, item.end_time) == 1
                            ? _c(
                                "div",
                                {
                                  staticClass: "live-sign",
                                  style: {
                                    background: _vm.$store.state.themeColor,
                                    color: "#fff",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("正在直播")]),
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/going.png"),
                                      alt: "",
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm.liveStatus(item.start_time, item.end_time) == 0
                            ? _c("div", { staticClass: "live-sign" }, [
                                _vm._v(
                                  _vm._s(
                                    item.start_time.split(":")[0] +
                                      ":" +
                                      item.start_time.split(":")[1]
                                  ) + " 开始"
                                ),
                              ])
                            : _vm._e(),
                          _vm.liveStatus(item.start_time, item.end_time) == 2
                            ? _c("div", { staticClass: "live-sign" }, [
                                _vm._v("已结束"),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.$store.state.can_class && _vm.list.length > 0
        ? _c("div", { staticClass: "classes-wrap" }, [
            _c("div", { staticClass: "title-bar" }, [
              _c("div", { staticClass: "title-wrap" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/title-icon.png"),
                    alt: "",
                  },
                }),
                _c("span", [_vm._v(_vm._s(_vm.categorys[0].name))]),
              ]),
              _vm.list.length >= 2
                ? _c(
                    "span",
                    {
                      staticClass: "more",
                      on: {
                        click: function ($event) {
                          return _vm.more("classes")
                        },
                      },
                    },
                    [_vm._v("查看全部 >")]
                  )
                : _vm._e(),
            ]),
            _c(
              "ul",
              { staticClass: "classes-list" },
              _vm._l(_vm.list, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    staticClass: "classes-item",
                    on: {
                      click: function ($event) {
                        return _vm.go(item, i)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "img" }, [
                      _c("img", {
                        staticClass: "img_a",
                        attrs: { src: item.picture, alt: "" },
                      }),
                      item.learn_status == 1
                        ? _c("div", { staticClass: "classes-sign-ing" }, [
                            _c(
                              "span",
                              { staticStyle: { "padding-right": "20px" } },
                              [_vm._v("进行中")]
                            ),
                          ])
                        : _vm._e(),
                      item.learn_status == 2
                        ? _c("div", { staticClass: "classes-sign-end" }, [
                            _vm._v("已结束"),
                          ])
                        : _vm._e(),
                    ]),
                    _c("div", { staticClass: "classes-info" }, [
                      _c("p", { staticClass: "classes-title" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                      item.begin_time
                        ? _c("span", { staticClass: "describe" }, [
                            _vm._v(
                              "开课时间：" +
                                _vm._s(item.begin_time + " 至 " + item.end_time)
                            ),
                          ])
                        : _vm._e(),
                      _c("p", { staticClass: "classes-about" }, [
                        _vm._v(_vm._s(item.about)),
                      ]),
                      _c(
                        "p",
                        {
                          staticStyle: { color: "#333", "margin-bottom": "0" },
                        },
                        [
                          _vm._v(
                            _vm._s(item.course_num) +
                              "门课 | " +
                              _vm._s(item.period) +
                              "课时 | " +
                              _vm._s(item.learn_total) +
                              "人已学 | " +
                              _vm._s(
                                !item.personal_access ? "非公开" : "公开"
                              ) +
                              " "
                          ),
                          item.member_limit
                            ? _c("span", [_vm._v("|")])
                            : _vm._e(),
                          _vm._v(
                            " " +
                              _vm._s(
                                item.member_limit == 0
                                  ? ""
                                  : "学员人数" +
                                      item.join_total +
                                      "/" +
                                      item.member_limit
                              )
                          ),
                        ]
                      ),
                      _c("div", { staticClass: "classes-do-wrap" }, [
                        item.order_status == 0 &&
                        _vm.$store.state.can_pay &&
                        _vm.$store.state.tenant_idBind != 22
                          ? _c("div", [
                              item.price && item.price > 0
                                ? _c("span", { staticClass: "classes-sign" }, [
                                    _vm._v("￥"),
                                  ])
                                : _vm._e(),
                              _c("span", { staticClass: "classes-price" }, [
                                _vm._v(
                                  _vm._s(item.price > 0 ? item.price : "免费")
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        item.order_status != 0 ? _c("div") : _vm._e(),
                        _vm._m(13, true),
                      ]),
                    ]),
                  ]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.categorys.length > 0
        ? _c("div", { staticClass: "list-module" }, [
            _c(
              "ul",
              _vm._l(_vm.listFull, function (item, i) {
                return _c("li", { key: i, staticClass: "module-item" }, [
                  _c("div", { staticClass: "title-bar" }, [
                    _c("div", { staticClass: "title-wrap" }, [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/title-icon.png"),
                          alt: "",
                        },
                      }),
                      _c("span", [_vm._v(_vm._s(item.name))]),
                    ]),
                    item.course.length >= 3
                      ? _c(
                          "span",
                          {
                            staticClass: "more",
                            on: {
                              click: function ($event) {
                                return _vm.more("course", item.id)
                              },
                            },
                          },
                          [_vm._v("查看全部 >")]
                        )
                      : _vm._e(),
                  ]),
                  _c(
                    "ul",
                    { staticClass: "lesson-list" },
                    _vm._l(item.course, function (items, is) {
                      return _c(
                        "li",
                        {
                          key: is,
                          staticClass: "lesson-item",
                          on: {
                            click: function ($event) {
                              return _vm.go(items, i)
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "img_a",
                            attrs: { src: items.picture, alt: "" },
                          }),
                          _c("div", { staticClass: "lesson-msg-wrap" }, [
                            _c("p", { staticClass: "lesson-title" }, [
                              _vm._v(_vm._s(items.title)),
                            ]),
                            _c("p", { staticClass: "lesson-about" }, [
                              _vm._v(
                                _vm._s(items.period) +
                                  "课时 | " +
                                  _vm._s(items.peroid_format) +
                                  "分钟 | " +
                                  _vm._s(
                                    _vm.$store.state.hostname ==
                                      "bjadks.zjttv.cn"
                                      ? Math.floor(
                                          Math.random() * (500 - 100)
                                        ) + 100
                                      : items.learn_total
                                  ) +
                                  "人已学"
                              ),
                            ]),
                            items.authors && items.authors.length > 0
                              ? _c(
                                  "p",
                                  { staticClass: "lesson-name" },
                                  _vm._l(
                                    items.authors.slice(0, 3),
                                    function (itemss, iss) {
                                      return _c(
                                        "span",
                                        {
                                          key: iss,
                                          attrs: { a: items.length },
                                        },
                                        [
                                          _vm._v(_vm._s(itemss.name)),
                                          items.authors.length > 3
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(iss == 2 ? "等" : "、")
                                                ),
                                              ])
                                            : _vm._e(),
                                          items.authors.length > 1 &&
                                          items.authors.length < 3
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(iss != 1 ? "、" : "")
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                )
                              : _c("p", { staticClass: "lesson-name" }),
                            _c("div", { staticClass: "lesson-price-info" }, [
                              _vm.priceShow()
                                ? _c("div", [
                                    items.price > 0
                                      ? _c(
                                          "span",
                                          { staticClass: "lesson-price-sign" },
                                          [_vm._v("￥")]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "span",
                                      { staticClass: "lesson-price" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            items.price > 0
                                              ? items.price
                                              : "免费"
                                          )
                                        ),
                                      ]
                                    ),
                                  ])
                                : _vm._e(),
                              _c(
                                "div",
                                [
                                  _c("van-rate", {
                                    attrs: { size: "14px" },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              }),
              0
            ),
          ])
        : _vm._e(),
      _vm.categorys.length == 0
        ? _c(
            "div",
            { staticClass: "empty", staticStyle: { height: "400px" } },
            [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/empty.png"),
                  alt: "",
                },
              }),
              _c(
                "span",
                { staticStyle: { color: "#666", "font-size": "12px" } },
                [_vm._v("暂无数据")]
              ),
            ]
          )
        : _vm._e(),
      _vm.unions.length > 0
        ? _c("div", { staticClass: "union-module" }, [
            _vm._m(14),
            _c(
              "ul",
              { staticClass: "union" },
              [
                _vm.unionsTotal_pages > 1
                  ? _c(
                      "li",
                      {
                        staticClass: "union-l",
                        on: {
                          click: function ($event) {
                            return _vm.prev()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/union-l.png"),
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
                _vm._l(_vm.unions, function (item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      staticClass: "union-item",
                      on: {
                        click: function ($event) {
                          return _vm.linkUrl(item)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: item.logo, alt: "" } })]
                  )
                }),
                _vm.unionsTotal_pages > 1
                  ? _c(
                      "li",
                      {
                        staticClass: "union-r",
                        on: {
                          click: function ($event) {
                            return _vm.next()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/union-r.png"),
                            alt: "",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrap" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/title-icon.png"), alt: "" },
      }),
      _c("span", [_vm._v("通知公告")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f02.png"), alt: "" },
      }),
      _c("span", [_vm._v("注册登录")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f01.png"), alt: "" },
      }),
      _c("span", [_vm._v("选择课程")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f03.png"), alt: "" },
      }),
      _c("span", [_vm._v("在线学习")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f04.png"), alt: "" },
      }),
      _c("span", [_vm._v("在线考试")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f05.png"), alt: "" },
      }),
      _c("span", [_vm._v("完成学习")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f02.png"), alt: "" },
      }),
      _c("span", [_vm._v("注册登录")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f01.png"), alt: "" },
      }),
      _c("span", [_vm._v("选择课程")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f06.png"), alt: "" },
      }),
      _c("span", [_vm._v("支付费用")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f03.png"), alt: "" },
      }),
      _c("span", [_vm._v("在线学习")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower-item" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/tem-two-f05.png"), alt: "" },
      }),
      _c("span", [_vm._v("完成学习")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "flower",
        staticStyle: {
          flex: "1",
          height: "140px",
          "margin-left": "20px",
          "border-radius": "4px",
        },
      },
      [
        _c("div", { staticClass: "flower-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/tem-two-f02.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("注册登录")]),
        ]),
        _c("img", {
          staticClass: "flower-sign",
          attrs: {
            src: require("../../assets/images/flower-sign.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "flower-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/tem-two-f01.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("选择课程")]),
        ]),
        _c("img", {
          staticClass: "flower-sign",
          attrs: {
            src: require("../../assets/images/flower-sign.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "flower-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/tem-two-f03.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("在线学习")]),
        ]),
        _c("img", {
          staticClass: "flower-sign",
          attrs: {
            src: require("../../assets/images/flower-sign.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "flower-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/tem-two-f04.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("在线考试")]),
        ]),
        _c("img", {
          staticClass: "flower-sign",
          attrs: {
            src: require("../../assets/images/flower-sign.png"),
            alt: "",
          },
        }),
        _c("div", { staticClass: "flower-item" }, [
          _c("img", {
            attrs: {
              src: require("../../assets/images/tem-two-f05.png"),
              alt: "",
            },
          }),
          _c("span", [_vm._v("完成学习")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrap" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/title-icon.png"), alt: "" },
      }),
      _c("span", [_vm._v("最近直播")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classes-go" }, [
      _c("div", { staticClass: "classes-go-img" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-bar" }, [
      _c("div", { staticClass: "title-wrap" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/title-icon.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("院校联盟")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }