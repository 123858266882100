var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "linkup" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "select-wrap" },
        [
          _c(
            "a-select",
            {
              staticStyle: { width: "160px", "margin-right": "10px" },
              attrs: { "default-value": "0" },
              on: { change: _vm.changeCourse },
            },
            [
              _c("a-select-option", { attrs: { value: "0" } }, [
                _vm._v("全部课程"),
              ]),
              _vm._l(_vm.courseArr, function (item, i) {
                return _c(
                  "a-select-option",
                  {
                    key: i,
                    attrs: { value: item.id + "-" + item.course_type },
                  },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
            ],
            2
          ),
          _c(
            "a-select",
            {
              staticStyle: { width: "160px" },
              attrs: { "default-value": "0" },
              on: { change: _vm.changeLesson },
              model: {
                value: _vm.secondInit,
                callback: function ($$v) {
                  _vm.secondInit = $$v
                },
                expression: "secondInit",
              },
            },
            [
              _c("a-select-option", { attrs: { value: "0" } }, [
                _vm._v("全部课时"),
              ]),
              _vm._l(_vm.lessonArr, function (item, i) {
                return _c(
                  "a-select-option",
                  { key: i, attrs: { value: item.id } },
                  [_vm._v(_vm._s(item.title))]
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
      _vm.show && _vm.linkArr.length == 0
        ? _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/weipingjia.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("暂无互动~")]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticStyle: { height: "300px", "line-height": "300px" },
        },
        [_c("a-spin")],
        1
      ),
      _vm.linkArr.length > 0
        ? _c(
            "ul",
            { staticStyle: { "margin-bottom": "20px" } },
            _vm._l(_vm.linkArr, function (item, i) {
              return _c(
                "li",
                { key: i, staticClass: "link-item" },
                [
                  _c("div", { staticClass: "link-user" }, [
                    _c("img", { attrs: { src: item.user.avatar, alt: "" } }),
                    _c("div", [
                      _c("h3", { staticClass: "user" }, [
                        _vm._v(_vm._s(item.user.realname)),
                      ]),
                      _c("p", { staticClass: "user-info" }, [
                        _vm._v("来自课程 " + _vm._s(item.title)),
                      ]),
                    ]),
                  ]),
                  _c("p", { staticClass: "infos" }, [
                    _vm._v(_vm._s(item.content)),
                  ]),
                  _c("div", { staticClass: "sign" }, [
                    _c("span", { staticClass: "sign-see" }, [
                      _vm._v(_vm._s(item.hit_num) + "人已查看"),
                    ]),
                    item.post_num > 0
                      ? _c(
                          "span",
                          {
                            staticClass: "sign-do",
                            on: {
                              click: function ($event) {
                                return _vm.seeDetail(item)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.post_num) + "条记录，点击查看")]
                        )
                      : _vm._e(),
                  ]),
                  item.id == _vm.show_id
                    ? _c("detail", {
                        attrs: {
                          thread_id: _vm.thread_id,
                          lesson_id: _vm.lesson_id,
                          course_id: _vm.course_id,
                          course_type: _vm.course_type,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _vm.ext.total_pages > 1
        ? _c("a-pagination", {
            attrs: { "default-current": _vm.page, total: _vm.ext.total_count },
            on: { change: _vm.changePage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-class-all" }, [
      _c("span", { staticClass: "icon" }),
      _c("h3", [_vm._v("互动问答")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }