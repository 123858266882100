<template>
    <div class="player" style="width: 100%">
      <div class="mmm" v-if="mmm"></div>
      <div class="loadding" v-show="!show">
          <a-spin />
      </div>
      <transition name="fades">
      <div v-show="show" class="playerWrap" style="height: 100%">
        <div class="player-inner">
          <div class="online-icon" v-if="course.type == 'live'">在线人数：{{max_online_num}}</div>
          <div class="player-inner-title">
            <div style="display:flex;align-items: center;"> 
                <div class="player-back" @click="backWay()">
                  <img src="../../assets/images/back.png" alt="">
                  </div>
                <p>{{course.title}}</p>
            </div>
            <div v-if="userName&&userMobile" class="user_info">
              学员信息：{{userName}} {{userMobile}}
            </div>
          </div>
          <div class="pdf" v-show="lessonOne.file_ext == 'pdf'">
              <pdf
              style="width: 100%;"
              :src="pdfUrl"
              :page="pageNum"
              :rotate="pageRotate"
              @progress="loadedRatio = $event"
              @page-loaded="pageLoaded($event)"
              @num-pages="pageTotalNum=$event"
              @error="pdfError($event)"
              @link-clicked="page = $event">></pdf>
          </div>
          <div class="prism-player" id="player-con" v-show="$store.state.playerVisible && lessonOne.file_ext != 'pdf'"></div>
          <div class="cover-mp3" v-show="lessonOne.file_ext == 'mp3'">
              <img :src="lessonOne.picture" alt="">
          </div>
          <div class="player-custom-controller" v-show="course.type == 'live' && lessonOne.live_status == 'living'">
              <img class="player-icon-controller"
              @click="toggle" :src="!playIconFlag? require('../../assets/images/play-icon-controller.png'): require('../../assets/images/pause-icon-controller.png')" :title="!playIconFlag? '播放': '暂停'">
              <img class="player-icon-controller"
              @click="refreshPlayer"
              style="width: 22px;height: 22px;" src="../../assets/images/refresh-icon-controller.png" title="刷新">
          </div>
          <div class="errTips" v-show="!$store.state.playerVisible && lessonOne.file_ext != 'pdf'">
              <span>{{errTipsTxt}}</span>
              <a-button v-if="$store.state.open_trial == 0" @click="retry()" size="small" :style="{background: $store.state.themeColor, color: '#fff'}">{{errBtnTxt}}</a-button>
          </div>

          <div class="player-mask" :style="{height: videoHeight? videoHeight+'px': '100%'}" v-show="abnormal&&$store.state.is_cancel_progress==0">
              <div class="title-mask">
                <img src="../../assets/images/warn_mask.png" alt="">
                <h3>学习进度异常！</h3>
              </div>
              <p>错误码：{{lessonOne.course_id}} | {{lessonOne.id}} | {{videoName}}</p>
              <p>1、是否存在拖动进度条的行为</p>
              <p>2、网络不稳定，导致记录错误</p>
              <a-button
                :style="{
                  width: '100px',
                  color: '#fff',
                  border: 'none',
                  marginTop: '20px',
                  background: $store.state.themeColor
                }"
                @click="refreshPlayer()">刷新重试</a-button>
          </div>
          <!-- 新增错误提示框 -->
           <div class="player-mask" :style="{height: videoHeight? videoHeight+'px': '100%'}" v-if="errorPrompt">
              <div class="title-mask">
                <img src="../../assets/images/warn_mask.png" alt="">
                <h3>服务器开小差！</h3>
              </div>
              <p>错误码：{{lessonOne.course_id}} | {{lessonOne.id}} | {{videoName}}</p>
              <p>1、需要检查当前网络环境是否通畅</p>
              <p>2、可尝试使用手机热点网络检查是否是当前无线网络环境问题</p>
              <a-button
                :style="{
                  width: '100px',
                  color: '#fff',
                  border: 'none',
                  marginTop: '20px',
                  background: $store.state.themeColor
                }"
                @click="refreshPlayer()">刷新重试</a-button>
          </div>
          <!-- 播放器底部工具栏 -->
          <div class="player-bot-do">

            <!-- 视频进度状态栏 -->
            <div class="signal" v-if="statusVisible&&!play_expired">
                <div class="signalColor" :style="{background: sigColor}"></div>
                <span v-if="signalFlag == 1" style="color: #52c41a">正常记录学习进度</span>
                <span v-else-if="signalFlag == 0" style="color: #c00">学习进度异常</span>
                <span v-else-if="signalFlag == 2" style="color: gold">正在回看</span>
                <span v-else-if="signalFlag == 3" style="color: #eee">视频暂停</span>
                <span v-else-if="signalFlag == 4" style="color: #c00">班级已结束，不记录学习数据</span>
                <span v-else-if="signalFlag == 5" style="color: #c00">课程已结束，不记录学习数据</span>
                <!-- <span v-else-if="signalFlag == 6" style="color: #52c41a">正在试看</span> -->
            </div>
            
             <!-- 视频进度状态栏 -->
            <div class="signal" v-if="play_expired == 1||play_expired == 2">
                <div class="signalColor" style="background:#c00"></div>
                <span v-if="play_expired == 1" style="color: #c00">班级已结束，不记录学习数据</span>
                <span v-else-if="play_expired == 2" style="color: #c00">课程已结束，不记录学习数据</span>
            </div>
            <div  class="signal" v-if="!statusVisible">
                <div class="signalColor" :style="{background: sigColor}"></div>
                <span style="color: #52c41a">该视频可试看{{$store.state.trial_length}}分钟</span>

              </div>
            <!-- <div class="signal" v-if="!statusVisible">
              <span style="color: #52c41a">该视频可试看{{$store.state.trial_length}}分钟</span>
            </div> -->

            <div class="tools" v-if="lessonOne.file_ext == 'pdf'">
              <a-button style="margin-right: 15px;" @click.stop="prePage"> 上一页</a-button>
              <a-button @click.stop="nextPage"> 下一页</a-button>
              <span style="color:#ffffff;margin-left:10px">{{pageNum}}/{{pageTotalNum}} </span>
            </div>

            <!-- 课堂小功能模块 -->
            <div class="player-do-btn" v-show="signalVisible">
              <!-- <a-button v-if="statusVisible" @click="openVisibleTest" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">随堂小测</a-button> -->
              <a-button v-if="statusVisible" @click="openVisibleQues" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">我要提问</a-button>
              <!-- <a-button @click="openVisibleCourse" type="primary" :style="{background: $store.state.themeColor,borderColor: $store.state.themeColor}">课程介绍</a-button> -->
            </div>

          </div>
          <div class="air-msg"  :style="{left: airPositionLeft+'px'}" v-if="$store.state.is_cancel_progress==0">
            <img src="../../assets/images/warn_radius.png" alt="">
            <p>请不要拖动进度条，否则会停止记录学习进度</p>
          </div>
          <div class="air-msg" :style="{width: '170px', right: airPositionRight+'px'}">
            <img src="../../assets/images/r.png" alt="">
            <p>课时已学习完成</p>
          </div>
        </div>

        <div class="player-normal-menu" v-if="course.type == 'normal'" :style="{width: sidebarWidth+'px'}">
          <div class="progress-wrap" v-show="!packFlag">
            <div class="progress-title">
              <span>当前课程进度</span>
              <span>{{course_member.complete_percent}}%</span>
            </div>
            <a-progress :percent="course_member.complete_percent" strokeColor="#6AC148" :showInfo="false"/>
          </div>
          <div class="player-menu-title-wrap" v-show="!packFlag">
              <h3 class="player-menu-title">课程目录</h3>
          </div>
          <div class="player-normal-item-scroll" v-show="!packFlag">
            <ul class="player-normal-item-wrap">
              <li class="player-normal-item" v-for="(item, i) in chapters" :key="i">
                <h3 class="chapter" v-if="course.is_chapter">{{item.title}}</h3>
                <div class="item-wrap-flex" v-for="(items, is) in item.lessons" :key="is" @click="plays(item, items, is)">
                  <div class="item-title-wrap-flex">
                      <div class="item-title-wrap">
                        <img :src="checkId == items.id? require('../../assets/images/play.png'): require('../../assets/images/pause.png')" alt="">
                        <p :style="{color: checkId == items.id? '#0071F5': ''}">{{items.title}}</p>
                      </div>
                      <div class="sub-wrap">
                        <p class="icon" v-if="course.course_type == 'tenant_course'">{{iconFilter(items.file_ext).name}}</p>
                        <p class="course-time">需用时{{items.length_format}}</p>
                        <p v-if="items.file_ext && items.file_ext == 'pdf'" class="course-time">已学{{items.watch_time_format}}</p>
                        <p v-if="items.file_ext == 'pdf'" class="course-time">{{items.learn_page+'/'+items.pages}}</p>
                      </div>
                  </div>
                  <a-progress v-if="items.learn_percent > 0 && items.learn_percent < 100" type="circle" :percent="parseInt(items.learn_percent)" :width="30" strokeColor="#1D75FA" />
                  <img style="width:28px;height:28px;" v-if="items.learn_percent == 100" src="../../assets/images/refundIcon.png" alt="">
                </div>
              </li>
            </ul>
          </div>
          <div class="sidebar-btn" @click="packUp()">
            <img :src="packFlag? require('../../assets/images/leftsidebar.png'): require('../../assets/images/rightsidebar.png')" alt="">
          </div>
        </div>
        <div class="playerModules" v-if="course.type == 'live'" :style="{width: sidebarWidth+'px'}">
            <ul class="tab" v-if="!packFlag">
                <li @click="tabMethod(item, i)" :style="index == i?{background: $store.state.themeColor,color: '#fff'}:{}" v-for="(item, i) in tab" :key="i">{{item.name}}</li>
            </ul>
            <div class="player-content">
                <div class="player-list" v-show="index == 0" :style="{height: replayWrapHeight+'px'}">
                    <div @click="checkList(item, i)" class="player-item" v-for="(item, i) in replay_videos" :key="i" :style="listIndex == item.video_id? {color: $store.state.themeColor, border: `1px solid ${$store.state.themeColor}`}: {}">
                        <div class="player-item-wrap">
                          <div class="playback">
                              <span class="playback-icon" :style="listIndex == item.video_id? {borderColor: $store.state.themeColor}: {}">回放</span>
                              <p class="title">{{item.name}}</p>
                          </div>
                          <div>{{item.start_time}}</div>
                        </div>
                        <div>{{item.percent}}%</div>
                    </div>
                </div>
                <div class="talkingRoom" v-show="index == 1">
                    <div class="txtWrap">
                        <div v-for="(item, i) in talkingList" :key="i">
                            <div style="color:#666;font-size: 12px">{{item.created_at}}</div>
                            <div class="talkingItem" :style="item.user_id == from_client_id? {flexDirection: 'row-reverse'}:{}">
                                <div class="pho">
                                    <img :src="item.avatar" alt="">
                                </div>
                                <div class="talkingInfo" :style="item.user_id == from_client_id? {alignItems: 'flex-end'}: {}">
                                    <span class="name">{{item.username}}</span>
                                    <p class="txt">{{item.content}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="sendWrap">
                        <a-input @keyup.enter="sendMsg()" v-model="sendVal" class="sendTxt" placeholder="请输入消息..." />
                        <a-button @click="sendMsg()" class="sendBtn" :style="{background: $store.state.themeColor}">发言</a-button>
                    </div>
                </div>
                <div class="sidebar-btn" style="left: -18px" @click="packUp()">
                  <img :src="packFlag? require('../../assets/images/leftsidebar.png'): require('../../assets/images/rightsidebar.png')" alt="">
                </div>
            </div>
          <div class="bot-div" style="height: 80px;background: #151515;"></div>

        </div>
      </div>
      </transition>
      <!-- <a-modal title="提示" :footer="null" v-model="visible" :bodyStyle="{textAlign:'center'}" width='300px'>
      <p>该课时已学习完成</p>
      <div style="margin-bottom: 30px;">
        <span style="color: red;">（{{timeout}}）</span>
        <span>秒后自动播放下一课</span>
      </div>
          <a-button class="okBtn" @click="handleOK" :style="{background: $store.state.themeColor, color: '#fff'}">确定</a-button>
      </a-modal> -->
      <a-modal title="友情提示" :footer="null" v-model="visibleThree" :bodyStyle="{textAlign:'center',padding: '30px'}" width='700px'>
          <div>
              <div class="logo">
                  <img class="img_a" :src="$store.state.logo" alt="">
              </div>
              <p class="notice">学员你好！{{$store.state.website_name}}实行严格的学习审查制度，将实时监测学员账户的学习过程及学习行为。若发现账户存在异常，我们将对该账户及相关异常数据进行记录存档，并反馈给相关部门，由此带来的处理后果由学员自行承担。{{$store.state.website_name}}提醒学员务必诚信学习，感谢支持！</p>
          </div>
          <div class="checkWrap">
              <a-checkbox @change="onChange">不再提示</a-checkbox>
          </div>
          <a-button class="okBtn" @click="handleOKs" :style="{background: $store.state.themeColor, color: '#fff'}">确定</a-button>
      </a-modal>

      <!-- 弹框学习监听 -->
      <!-- <a-modal :closable="false" :maskClosable='false' title="提示" :footer="null" v-model="visibleGo" :bodyStyle="{textAlign:'center'}" width='300px'>
      <p>点击确定，继续学习</p>
          <a-button class="okBtn" @click="handleOKGo" :style="{background: $store.state.themeColor, color: '#fff'}">确定</a-button>
      </a-modal> -->
      
      <a-modal v-if="visibleTest" v-model="visibleTest" :bodyStyle="{padding: 0}" :maskClosable='false' title="随堂小测" :footer="null" width='500px'>
        <div class="test-title">
          <span>{{answerObjLength}}/</span>
          <span>&nbsp;{{questionArr.length}}</span>
        </div>
        <div class="classes-test">
          <div v-for="(item, i) in questionArr" :key="i">
            <h3>{{item.question.stem}}</h3>
            <a-radio-group v-if="is_again == 1" :name="item.question.id.toString()" :options="item.question.metas.choices" @change="onChangeRadio"/>

            <div class="answer-item" v-for="(items, is) in item.question.metas.choices" :key="is" v-if="is_again == 0">
              <span>{{items.label}}</span>
              <img class="qesIcon" v-if="item.question.answer[0] == is" src="../../assets/images/r.png" alt="">
              <img class="qesIcon" v-else-if="item.question.answer[0] == is && item.choices.choice_status == 'right'" src="../../assets/images/r.png" alt="">
              <img class="qesIcon" v-else-if="item.choices.choice_answer[0] == is && item.choices.choice_status == 'wrong'" src="../../assets/images/w.png" alt="">
            </div>
          </div>

        </div>
        <div class="exam-btn-wrap">
          <a-button @click="commitExam" :style="{background: $store.state.themeColor}" type="primary">{{is_again == 1?'提交':'再测一次'}}</a-button>
        </div>
      </a-modal>
      <a-modal v-model="visibleQues" :maskClosable='false' title="我要提问" :footer="null" width='500px'>
        <div class="qes-talk-wrap">
          <h3>{{lessonOne.title}}</h3>
          <div class="qes-talk-room" ref="qestalkroom">
            <div class="qes-talk-item" v-for="(item, i) in questionList" :key="i" :style="{flexDirection: item.is_teacher?'':'row-reverse'}">
              <div class="qes-people-wrap">
                  <img class="qes-talk-avatar" :src="item.user.avatar" alt="">
                  <span class="qes-talk-name">{{item.is_teacher?'老师': '我'}}</span>
              </div>
              <div class="qes-talk-info" :style="{background: item.is_teacher?'#f0f6fc': ''}">
                <p :style="{color: item.is_teacher?'#0071F5': ''}">{{item.content}}</p>
                <span>{{item.created_time}}</span>
              </div>
            </div>
          </div>
          <p class="refresh" @click="refresh">刷新</p>
          <div class="qes-do-wrap">
            <textarea class="qes-txt" v-model="askContent" placeholder="在这里输入想问老师的问题吧～"/>
            <a-button type="primary" @click="submitQestion">确定</a-button>
          </div>
        </div>

      </a-modal>
      <a-modal v-model="visibleCourse" :maskClosable='false' title="课程介绍" :footer="null" width='500px'>
        <p style="min-height: 150px;">{{course.infos}}</p>
        <div class="btn-wrap">
          <a-button @click="courseOk" :style="{background: $store.state.themeColor}" type="primary">确定</a-button>
        </div>
      </a-modal>
      <a-modal
      v-model="photoVisible" @cancel="photoCancel" title="学习前的准备工作" footer="" :maskClosable="false" :centered="true">
        <div class="people-module">
          <div class="item-bg" v-if="open_check_photo == 1"
          :style="{background: is_id_photo == 1? '#fff1f0': '#f6ffed'}">
            <div class="people-title">
              <img :src="is_id_photo == 1?require('../../assets/images/er.png'): require('../../assets/images/suc.png')" alt="">
              <h3>证件照上传</h3>
            </div>
            <p>{{is_id_photo == 1? '课程已配置需证件照的证书，请前往个人中心上传证件照': '已上传'}}</p>
          </div>
          <div class="item-bg" v-if="$store.state.open_check_realname == 1"
          :style="{background: is_certification_idcard == 1? '#fff1f0': '#f6ffed'}">
            <div class="people-title">
              <img :src="is_certification_idcard == 1?require('../../assets/images/er.png'): require('../../assets/images/suc.png')" alt="">
              <h3>实名认证</h3>
            </div>
            <p>{{is_certification_idcard == 1? '课程已开通实名认证，请前往个人中心进行实名认证': '已上传'}}</p>
          </div>
          <a-button @click="goUser" size="large" :style="{background: $store.state.themeColor, color: '#fff', border: 'none'}">前往补全信息</a-button>
        </div>
      </a-modal>
      <a-modal v-model="$store.state.tryVisible" width="320px" :title="null" :footer="null" :centered="true" :maskClosable='false' :closable="false">
        <div class="try-content">
          <p v-if="!$store.state.token && lessonOne.file_ext != 'pdf'">试看已结束，请登录后继续观看！</p>
          <p v-if="$store.state.token && course.order_status == 0 && lessonOne.file_ext != 'pdf'">试看已结束，请购买后继续观看！</p>
          <p v-if="lessonOne.file_ext == 'pdf' && !$store.state.token">文档不支持试看，请登录后观看</p>
          <p v-if="lessonOne.file_ext == 'pdf' && $store.state.token && course.order_status == 0">文档不支持试看，请购买后学习！</p>
          <div class="try-btn-wrap">
            <a-button @click="tryCancel()">退出</a-button>
            <a-button v-if="!$store.state.token" @click="tryOk()" :style="{background: $store.state.themeColor, color: '#fff', border: 'none'}">确定</a-button>
            <a-button v-if="$store.state.token && course.order_status == 0" @click="tryBuy()" :style="{background: 'rgb(255, 134, 17)', color: '#fff', border: 'none'}">立即购买</a-button>
          </div>
        </div>
        </a-modal>
      <a-modal width="300px" v-model="examVisible" :title="null" :footer="null" :closable="false" :centered="true" :maskClosable="false">
        <div class="exam-content">
          <p>恭喜！学习已完成！该课程已设置考试环节，点击前往考试</p>
          <div class="exam-do-wrap">
            <a-button @click="waiting">再等等</a-button>
            <a-button @click="doExam" :style="{background: $store.state.themeColor,color: '#fff',border: 'none', marginLeft: '20px'}">去考试</a-button>
          </div>
        </div>
      </a-modal>
      
      <a-modal :closable="false"  :maskClosable='false' title="提示" :footer="null" v-model="is_play_expired" :bodyStyle="{textAlign:'center',}"  width='400px'>
      <p style='font-size:16px;'>{{play_expired_text}}</p>
          <a-button class="okBtn" @click="playExpired" :style="{background: $store.state.themeColor, color: '#fff',}" style="margin-top:60px;">确定</a-button>
      </a-modal>
       <a-modal :closable="false" :keyboard="false"  :maskClosable='false' title="极速验证" :footer="null" v-model="visibleGo" :bodyStyle="{textAlign:'center',}"  width='400px'>
        <slider v-if="visibleGo" ref="slider" @onVerifyFailed="onVerifyFailed" @handleOKGo="handleOKGo"></slider>
        <p style='font-size:16px;'>{{play_expired_text}}</p>
        <!-- <div class="code_img">
           <div>验证码：</div>
           <el-input v-model="VCode" placeholder="验证码" class="code_input"></el-input>
            <div @click="refreshCode()" class="code" style="cursor:pointer;" title="点击切换验证码">
              <s-identify :identifyCode="identifyCode"></s-identify>
            </div>
            <span class="code_img_text" @click="refreshCode()">换一张</span>
        </div>
        <p v-if="codeError" style="color:red;padding:10px 0;">请输入正确的验证码！</p>
        <a-button class="okBtn" @click="handleOKGo" :style="{background: $store.state.themeColor, color: '#fff'}" style="margin-top:60px;">确定</a-button> -->
      </a-modal>
      <!-- 人机验证失败提示框 -->
      <a-modal :closable="false"  :maskClosable='false' :title="'警告！您已累计'+failTimes+'次验证失败'" :footer="null" v-model="verifyFailed" :bodyStyle="{textAlign:'center',}"  width='400px'>
        <p style='prompt-wrap_title'></p>
        <div class="prompt-wrap_content">
          <p class="prompt-wrap_text">平台相应相关培训政策要求</p>
          <p class="prompt-wrap_text prompt-wrap_redtext">累计达到{{fallback_tolerance_times}}次回退当前视频学习进度</p>
          <p class="prompt-wrap_text">请务必保证培训真实性</p>
        </div>
            <a-button class="okBtn" @click="onVerifty" :style="{background: $store.state.themeColor, color: '#fff',}" style="margin-top:60px;">{{this.failTimes>=this.fallback_tolerance_times?'刷新页面':'继续验证'}}</a-button>
      </a-modal>
      <remote-css href="https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css"></remote-css>
      <remote-js src="https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js"></remote-js>
    </div>
</template>

<script>
import Player from '../../common/player'
import pdf from 'vue-pdf'
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import hexMD5 from 'md5'
import sIdentify from "@/components/common/sIdentify.vue";
import slider from '@/components/slider/indexs.vue'
import {
    playerDetail,
    coursePush,
    askList,
    ask,
    quizList,
    quizCommit,
    quickVerify,
    author,
    userInfo,
    courseEvent,
    getCurrentTime
} from '../../http/api'
const category = navigator.userAgent;
let objKeySort = (obj) => {//排序的函数
  console.log('obj',obj)
  var newkey = Object.keys(obj).sort();
　　//先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newObj = {};//创建一个新的对象，用于存放排好序的键值对
  for (var i = 0; i < newkey.length; i++) {//遍历newkey数组
      newObj[newkey[i]] = obj[newkey[i]];//向新创建的对象中按照排好的顺序依次增加键值对
  }
  return newObj;//返回排好序的新对象
}
export default {
    inject:['reload'],
    data() {
        return {
            is_play_expired:false,
            play_expired_text:'',
            play_expired:0,//班级、课程是否已过期
            videoName:'',//视频名称
            errorPrompt:false,//错误提示框
            errTipsTxt: '',
            token: '',
            platform_id: 6,
            lessonId: '',
            show: false,
            ws: {},
            course: {},
            course_member: {},
            chapters: [],
            exams: [],
            learn: {},
            lessonOne: {},
            replay_videos: [],
            player: null,
            sendVal: "",
            index: 1,
            listIndex: 0,
            tab: [
                {
                    name: '视频列表'
                },
                {
                    name: '聊天室'
                }
            ],
            from_client_id: '',
            talkingList: [],
            currentTime: '',
            timeInterval: '',
            title: "",
            visible: false,
            endFlag: true,
            visibleThree: false,
            signalFlag: -1,
            sigColor: '#151515',
            signalVisible: false,
            hostname: "",
            timeFlag: true,
            alertFlag: true,
            timeFlag2: true,
            alertFlag2: true,
            timeFlag3: true,
            alertFlag3: true,
            timeFlag4: true,
            alertFlag4: true,
            timeFlag5: true,
            alertFlag5: true,
            alertTime: '',
            visibleGo: false,
            pre_play_time: 1,
            errBtnTxt: '点击重试',
            visibleTest: false,
            visibleQues: false,
            visibleCourse: false,
            askContent: '',
            questionList: [],
            page: 1,
            page_size: 10,
            pageFlag: true,
            scrollFlag: false,
            checkId: 0,
            questionArr: [],
            answerObj: {},
            answerObjLength: 0,
            is_again: 0,
            timeArr: [],
            timeout: 5,
            liveInterval: '',
            replay_id: '',
            max_online_num: 0,
            course_lesson_id_param: '',
            photoVisible: false,
            mmm: false,
            playIconFlag: false,
            once: true,
            replayWrapHeight: 0,
            timeoutInterval: '',
            isPause: false,
            sidebarWidth: 380,
            packFlag: false,
            airPositionLeft: -340,
            airPositionRight: -170,
            videoHeight: 0,
            abnormal: false,
            fullScreenStatus: false,
            airFlag: true,
            // ↓↓↓↓-------pdf参数
            pdfUrl: "",
            pageNum: 1,
            pageTotalNum: 1,
            pageRotate: 0,
            // 加载进度
            loadedRatio: 0,
            curPageNum: 0,
            pdfCurrentTime: '',
            is_certification_idcard: null,  // 是否上传实人认证
            is_id_photo: null,               // 是否上传证件照
            open_check_photo: null,
            statusVisible: null,
            tryTimer: null,
            examVisible: false,
            isPlay:false,
            messageFlag:false,
            identifyCode: "",
            identifyCodes: ['0','1','2','3','4','5','6','7','8','9','a','b','c','d'],
            VCode:'',//图形验证码
            codeError:'',

            //事件监听记录
            eventType:true,
            eventId:'',
            userName:'',
            userMobile:'',
            
            playingTime:0,//记录推送累计格子
            pushEndTime:'',
            pushStartTime:'',
            isClearInterval:false,//是否清计时器
            timeNum:0,
            oldStartTime:0,//上一次的push开始时间
            oldEndTime:0,//上一次的push结束时间
            current_play_time:0,
            verify:0,//人机验证是否成功 1成功 2失败
            failTimes:0,//人机验证失败次数
            verifyFailed:false,//人机验证失败提示框
            verifyText:'继续验证',
            fallback_tolerance_times:'',//人机验证累计总次数
            learn_fallback:0,//是否清空进度
            pauseStartTime:0,//记录暂停开始时间
            pauseEndTime:0,//暂停结束时间
            startPlayTime:0,//记录暂停开始时间
            pauseTime:0,//暂停时间
            isPausePush:true,//人脸识别、人机验证时暂停不推送
            is_retry:0,//人机验证、人脸验证补推通知
            course_lesson_id:'',//push参数
            lesson_finish_percent:0,//当前视频观看进度
            retryTime:0,
            remain_lesson_length:0,//剩余推送时长
            query_classId:null,
            currentTimes:0,//服务器时间
            switchLesson:false,//是否是切换视频操作
            is_least_learn:0,//当前视频是否学完
            video_length:null,
            isShowMessage:true
        }
    },
    watch:{
      // '$route.query.lessonId':{
      //     handler(newVal,oldVal){
      //       console.log('newvalnewval',newVal)
      //           //判断newVal有没有值监听路由变化
      //     },
      //   deep: true
      // }
    },
    components: {
        pdf,
        'remote-js': {
            render(createElement) {
                return createElement('script', { attrs: { type: 'text/javascript', src: this.src }})
            },
            props: {
                src: { type: String, required: true }
            }
        },
        'remote-css': {
            render(createElement) {
                return createElement('link', { attrs: { rel: 'stylesheet', href: this.href }})
            },
            props: {
                href: { type: String, required: true }
            }
        },
        sIdentify,
        slider
    },
    created() {
        this.hostname = window.location.hostname
        this.$store.commit("TRYSHOW", false)
        this.$store.commit("SHOWLOGIN", false)

    },
    mounted() {
      let faceVisible = this.$store.state.faceVisible
      if(faceVisible){
         this.$store.commit("FACEVISIBLE", false)
      }
       this.refreshCode();
      if( this.$store.state.jianGuan){
          this.getUserInfo();
        }
        // this.getUserInfo();
        if(this.userAgent(navigator.userAgent)) {
          if(!localStorage.checked || localStorage.checked == 0) {
              this.visibleThree = true
          } else if(localStorage.checked && localStorage.checked == 1) {
              this.visibleThree = false
          }
        }

        this.title = this.$route.query.title
        this.lessonId = this.$route.query.lessonId
        this.index = 0
        let face_back = this.$route.query.face_back;
        let href = window.location.href;
        console.log('href',href,'face_back',face_back)
        let that =this;
        //人脸识别成功后的推送(修改face_back值，但不刷新页面)
     
        console.log('this.$route.query',this.$route.query)
          let class_id = this.$route.query.class_id;
          this.query_classId = Array.isArray(class_id)?class_id[0]:class_id?class_id.split(',')[0]:''
          console.log('clsssid_arr',this.query_classId);
         setTimeout(() => {
            that.updatePlayer(that.$route.query.id, that.lessonId, that.$route.query.course_type, '');
         }, 1000);
      
           if(/&face_back/.test(href)&&face_back==1){
             this.is_retry = 1;
             this.course_lesson_id = `${this.$route.query.id}_${this.lessonId}_${this.$route.query.course_type.split('_')[0]}_${this.query_classId}_class`
            // that.newCoursePush(30);
             let time = localStorage.getItem('faceTime');
             setTimeout(() => {
                that.getCurrentTimes(time||30);
                window.history.replaceState({},'',href.replace(/&face_back=\w/,'&face_back=0'))
             }, 1000);
            }else{
                // that.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, '')

            }
            window.addEventListener("popstate", function(e) {
              localStorage.removeItem("firstPlayer")
            }, false)
    },
    watch: {
        'course.type'(n) {
            if(n == 'live') {
                this.talking()
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.timeInterval)
        this.player = null
    },
    methods: {
    // beforeunload(e) {
    //   let course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type?this.course.course_type.split('_')[0]:this.$route.query.course_type.split('_')[0]}_${this.query_classId}_class`;
    //   localStorage.setItem('playingTime',this.playingTime%30);
    //   localStorage.setItem('course_lesson_id',this.course_lesson_id);
    //   localStorage.setItem('course_lesson_id_param',course_lesson_id_param);
    // },
    // async beforeunloadHandler(e) {
    //    let playingTime = localStorage.getItem('playingTime');
    //    let course_lesson_id_param = localStorage.getItem('course_lesson_id_param')
    //    var params = {
    //         course_lesson_id:course_lesson_id_param,
    //         play_time: Number(playingTime),
    //         is_finish: 0,
    //         timestamp:parseInt(new Date().getTime()/1000),
    //         is_pause:1,
    //         is_retry:this.is_retry//人机验证、人脸验证补推通知
            
    //     };
    //     let data = {
    //       client_id: 'U2FsdGVkX186GEUZRDKw7N+cbGhKTAS4RSL8th0C7uwUfY5WaRGJlg==',
    //       client_secret: 'U2FsdGVkX1+grygpUVr8IQ8b0OJksuAXCYsGIv6UpZhj/rdsrMRCM0a9A5e2ZA3k',
    //       hostname: this.$store.state.hostname,
    //     }
    //     //https://aidedutest.api.adksedu.com/front.v1/course/push
    //    await fetch('http://dev.api.qypx.11taotao.com/front.v1/site/authorized', {
    //         method: 'POST',
    //         body:JSON.stringify(data),
    //         headers: {'Content-Type': 'application/json;charset=UTF-8','Accept': 'application/json,text/plain,*/*'},
    //         // keepalive: true
    //     }).then(res=>{
    //         console.log('res?????',res)
    //         localStorage.removeItem('playingTime');
    //         localStorage.removeItem('course_lesson_id');
    //         localStorage.removeItem('course_lesson_id_param');
    //         return res.json();

    //     });
       
    //   },
    //获取服务器时间
    getCurrentTimes(time,is_pause,id){
      let that = this;
      getCurrentTime().then(res=>{
         that.currentTimes = res.data.data.current_time;
         that.newCoursePush(time,is_pause,id)
      })
    },
      submitForm() {
          if(this.$refs['slider']) {
              // 未通过验证时，提示错误信息并返回
              if (!this.$refs['slider'].confirmSuccess) {
                  console.log(this.$refs['slider'].confirmSuccess)
                  this.$message.error("请拖动滑块验证");
                  return
              }else{
                  this.$message.success("验证成功，可提交");
                  console.log(this.$refs['slider'].confirmSuccess)
          
              }
          }
      },
        // 生成随机数
      //记录开始、结束、暂停
      courseEvent(lessonId,course_type,course_id){
        let data = {
          id : this.eventId,
          course_id:course_id?course_id:this.course.id,
          lesson_id: lessonId,
          course_type: course_type,
          type:this.eventType?'start':'stop'
        };
        courseEvent(data).then(res=>{
          if(res.data.code==200){
             console.log('res.data.data.id',res.data.data.id)
            if(this.eventType){
              this.eventId = res.data.data.id;
              console.log('res.data.data.id',res.data.data.id)
            }
          }
        })
        console.log('this.eventId',this.eventId)
      },
      // 生成随机数
      randomNum(min, max) {
        max = max + 1
        return Math.floor(Math.random() * (max - min) + min);
      },
      // 更新验证码
      refreshCode() {
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
        console.log('当前验证码:',this.identifyCode);
      },
      // 随机生成验证码字符串
      makeCode(data, len) {
        console.log('data, len:', data, len)
        for (let i = 0; i < len; i++) {
          this.identifyCode += this.identifyCodes[this.randomNum(0, this.identifyCodes.length-1)]
        }
      },
        getRequest(str) {
            var url = str ? str : decodeURI(window.location.search); //获取url中"?"符后的字串
            var theRequest = new Object();
            if (url.indexOf('?') != -1) {
                url = url.substr(1);
            }
            if (url) {
                var strs = url.split('&');
                for (var i = 0; i < strs.length; i++) {
                    var srtArry = strs[i].split('=');
                    var y = srtArry.shift();
                    theRequest[y] = decodeURIComponent(srtArry.join('='));           
                }
            }
            return theRequest;

        },
      getUserInfo(){
          userInfo().then(res => {
            this.$store.commit("PHONENUMBER", res.data.data.mobile)
          }).catch(err => {

          })
      },
      playExpired(){
        this.is_play_expired=false;
        this.player.play();
         //记录视频开始
        // this.eventType=true;
        console.log('this.eventType111')
        // this.courseEvent( this.lessonOne.id,  this.course.course_type, this.course.id);
    },
      tryBuy() {
        if(this.$store.state.token) {
          this.$router.push({
              path: '/pay',
              query: {
                course_id: this.course.id,
                course_type: this.course.course_type
              }
          })
        } else {
            this.$store.commit("SHOWLOGIN", true)
            this.$store.commit("TRYSHOW", false)
        }
      },
      tryOk() {
        this.$store.commit("SHOWLOGIN", true)
        this.$store.commit("TRYSHOW", false)
      },
      tryCancel() {
        this.$store.commit("TRYSHOW", false)
        this.$router.push({
          path: '/course/studydetail',
          query: this.$route.query
        })
      },
      waiting() {
        this.examVisible = false
      },
      doExam() {
        this.$route.query['index'] = 1
        this.$router.push({
          path: '/course/studydetail',
          query: this.$route.query
        })
      },
      goUser() {
        this.$router.push('/user/setuser')
      },
			prePage() {     // 上一页函数
				var page = this.pageNum
				page = page > 1 ? page - 1 : this.pageTotalNum
				this.pageNum = page
        document.querySelector('.pdf').scrollTo(0, 0)
        this.pdfCousePush();
			},
			nextPage() {    // 下一页函数
				var page = this.pageNum
				page = page < this.pageTotalNum ? page + 1 : 1
				this.pageNum = page;
        console.log('this.page;',this.pageNum)
        document.querySelector('.pdf').scrollTo(0, 0);
        this.pdfCousePush();
      },
      pdfCousePush(){
        if(this.$store.state.is_cancel_progress==1){
            return false;
          }
          console.log('coursePush1')
         coursePush({
              course_lesson_id: `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.pageNum}_${this.query_classId}_class`,
              play_time:1200,
              is_finish: 0,
              timestamp:parseInt(new Date().getTime()/1000)
          }).then(res => {
              if(res.data.code == 200) {
                // this.signalFlag = 1
                this.sigColor = "#52c41a"
                this.max_online_num = res.data.data.max_online_num
              } else {
                
              }

          }).catch(err => {
            console.log(err)
          })
      },
			pageLoaded(e) {   // 页面加载回调函数，其中e为当前页数
				this.curPageNum = e
      },
      
			pdfError(error) {   // 其他的一些回调函数
				console.error(error)
			},
      iconFilter(param) {
        if(param == 'mp4') {
          return {
            name: '视频',
            className: 'mp4-color icon'
          }
        } else if(param == 'mp3') {
          return {
            name: '音频',
            className: 'mp3-color icon'
          }
        } else if(param == 'pdf') {
          return {
            name: '文档',
            className: 'doc-color icon'
          }
        }
      },
      toggle() {
        if(!this.playIconFlag) {
          this.player.play()
          this.playIconFlag = true
           //记录视频开始
          // this.eventType=true
          // console.log('this.eventType222')
          // this.courseEvent( this.lessonOne.id,  this.course.course_type, this.course.id);

        } else {
          this.player.pause()
          this.playIconFlag = false
          // this.eventType=false
          // this.courseEvent( this.lessonOne.id,  this.course.course_type, this.course.id);
        }
      },
      // 强制退出方法
      signOut() {
        this.$store.commit("DELETETOKEN")
        this.btnLoading = false
        this.$router.push({
          path: '/'
        })
        localStorage.removeItem('state')
      },
      onChangeRadio(e) {
        this.answerObj[e.target.name] = e.target.value
        this.answerObjLength = Object.keys(this.answerObj).length
      },
      commitExam() {
        if(this.is_again == 0) {
        this.answerObj = {}
        this.quizListUpdate(1)
        } else {
          quizCommit({
            course_id: this.$route.query.id,
            course_type: this.$route.query.course_type,
            lesson_id: this.lessonOne.id,
            quiz: this.answerObj
          }).then(res => {
            if(res.data.code == 200) {
              this.$message.success(res.data.message)
                this.quizListUpdate(0)
            } else {
              this.$message.error(res.data.message)
            }
          })
        }

      },
      refresh() {
        this.page = 1
        this.scrollFlag = false
        this.questionList = []
        this.questionUpdate()
      },
      questionUpdate() {
        if(this.pageFlag) {
          this.pageFlag = false
          askList({
            course_id: this.$route.query.id,
            course_type: this.$route.query.course_type,
            lesson_id: this.lessonOne.id,
            page: this.page,
            page_size: this.page_size
          }).then(res => {
            if(res.data.code == 200) {
              this.pageFlag = true
              this.page++
              res.data.data.map((item, i) => {
                this.questionList.push(item)
              })
              let wrap = document.querySelector(".qes-talk-room")
              this.$nextTick(() => {
                if(!this.scrollFlag) {
                  wrap.scrollTop = wrap.scrollHeight
                }
                this.$refs.qestalkroom.addEventListener('scroll', () => {
                  if(wrap.scrollTop == 0 && this.page <= res.data.ext.total_pages) {
                    this.scrollFlag = true
                    wrap.scrollTop = 200
                    this.questionUpdate()
                  }
                }, false)
              })
            }
          })
        }

      },
      openVisibleQues() {
        this.visibleQues = true
        this.page = 1
        this.scrollFlag = false
        this.questionList = []
        this.questionUpdate()
        let wrap = document.querySelector(".qes-talk-room")
        this.$nextTick(() => {
          wrap.scrollTop = wrap.scrollHeight
        })

      },
      submitQestion() {
        ask({
          course_id: this.$route.query.id,
          course_type: this.$route.query.course_type,
          lesson_id: this.lessonOne.id,
          content: this.askContent,
        }).then(res => {
          this.pageFlag = true
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            this.askContent = ''
            let obj = {
              content: res.data.data.content,
              created_time: res.data.data.created_time,
              user: {
                avatar: res.data.data.user.avatar
              },
              is_teacher: res.data.data.is_teacher
            }
            this.questionList.push(obj)
            let wrap = document.querySelector(".qes-talk-room")
            this.$nextTick(() => {
              wrap.scrollTop = wrap.scrollHeight
            })
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      // 温馨提示
      onChange(e) {
          if(e.target.checked) {
              localStorage.checked = 1
          } else {
              localStorage.checked = 0
          }
      },
      handleOK(e) {
          clearInterval(this.timeoutInterval)
          this.visible = false
          this.timeout = 5
          if(this.lessonOne.file_ext == 'pdf') {
            if(this.lessonOne.next_lesson.lesson_id != 0) {
              this.$router.replace({
                query: {
                  id: this.$route.query.id,
                  course_type: this.$route.query.course_type,
                  lessonId: this.lessonOne.next_lesson.lesson_id,
                  class_id:this.query_classId
                }
              })
              this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
            }
          } else {
            if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {  // 回放
              if(this.replayOne.next_replay_id != 0) {
                this.$router.replace({
                    query: {
                      id: this.$route.query.id,
                      course_type: this.$route.query.course_type,
                      lessonId: this.lessonOne.next_lesson.lesson_id,
                      class_id:this.query_classId
                    }
                })
                this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, this.replayOne.next_replay_id)
              }
            } else {  //  点播
              if(this.lessonOne.next_lesson.lesson_id != 0) {
                this.$router.replace({
                  query: {
                    id: this.$route.query.id,
                    course_type: this.$route.query.course_type,
                    lessonId: this.lessonOne.next_lesson.lesson_id,
                    class_id:this.query_classId
                  }
                })
                this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
              }
            }
          }
      },
      handleOKs(e) {
          this.visibleThree = false
      },
       //人机验证失败提交事件
      onVerifty(){
          this.verifyFailed = false
          if(this.learn_fallback&&this.failTimes>=this.fallback_tolerance_times){
           this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, '')
          }else{
            this.visibleGo = true
          }
      },
      //滑动验证失败回调
      onVerifyFailed(){
        console.log('this.visibleGo', this.visibleGo)
        
         this.verify = 2;
        
           let currentTime
            if(this.lessonOne.file_ext == 'pdf') {
              currentTime = this.pdfCurrentTime? this.pdfCurrentTime: this.learn.watch_time
            } else {
              currentTime = this.current_play_time//this.player.getCurrentTime() + this.learn.learn_time
            }
            quickVerify({
              course_id: this.course.id,
              lesson_id: this.lessonOne.id,
              play_time: currentTime,
              verify:this.verify,
              course_type: this.course.course_type,
              class_id:this.query_classId
            }).then(res => {
              if(res.data.code == 200) {
                 this.failTimes = res.data.data.fail_times;
                 this.fallback_tolerance_times = res.data.data.fallback_tolerance_times;
                 this.learn_fallback = res.data.data.learn_fallback;
                 
                  if(this.learn_fallback){
                      this.verifyFailed = true;
                      this.visibleGo = false;
                  }
              }})
      },
      handleOKGo(e) {
        // if(this.VCode.toLocaleLowerCase()==this.identifyCode){
           this.codeError = false;
           this.verify = 1;
           let currentTime
            if(this.lessonOne.file_ext == 'pdf') {
              currentTime = this.pdfCurrentTime? this.pdfCurrentTime: this.learn.watch_time
            } else {
              currentTime = this.current_play_time//this.player.getCurrentTime() + this.learn.learn_time
            }
            quickVerify({
              course_id: this.course.id,
              lesson_id: this.lessonOne.id,
              play_time: currentTime,
              verify:this.verify,
              course_type: this.course.course_type,
              class_id:this.query_classId
            }).then(res => {
              if(res.data.code == 200) {
                clearInterval(this.timeInterval);
                this.isPausePush = true
                this.visibleGo = false;
                // this.isPause = false;
                this.failTimes = res.data.data.fail_times;
                this.fallback_tolerance_times = res.data.data.fallback_tolerance_times;
                this.learn_fallback = res.data.data.learn_fallback;
                // this.pushStartTime = this.oldStartTime
                this.is_retry = 1
                this.getCurrentTimes(this.retryTime?this.retryTime:this.playingTime%30);
                this.player.play()
                // if(this.lessonOne.file_ext != 'pdf') {
                //     //记录视频开始
                //   //   console.log('this.eventType333')
                //   // this.eventType=true
                //   // this.courseEvent( this.lessonOne.id,  this.course.course_type, this.course.id);
                //   this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, '')
                //   // this.player.play()
                // } else {
                //   this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, '')
                // }
              } else {
                this.visibleGo = false;
                this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, '')
                this.$message.error(res.data.message)
              }
            })
        // }else{
        //   this.codeError = true;
        // }
          // this.refreshCode();
          // this.VCode = ''

      },
      // 聊天室初始化
      talking() {
          if(process.env.NODE_ENV == 'release' || process.env.NODE_ENV == 'development') {
              this.ws = new WebSocket("wss://chattest.adksedu.com/wss")  // 测试
          } else if(process.env.NODE_ENV == 'preview') {
              this.ws = new WebSocket("wss://chattest1.adksedu.com:7273")
          } else if(process.env.NODE_ENV == 'master') {
              this.ws = new WebSocket("wss://chat.adksedu.com/wss") // 生产
          }
          this.ws.onopen = (evt) => {
              this.token = this.$store.state.token
              this.ws.send(
                  '{"type":"login","platform_id":"' + this.platform_id + '","course_id":' + this.$route.query.id + ',"lesson_id":' + this.$route.query.lessonId + '}'
              )
          }

          this.ws.onmessage = (evt) => {
              let res = JSON.parse(evt.data)
              if(res.type == 'ping') {
                  this.ws.send(
                      '{"type": "pong"}'
                  )

              } else if(res.type == 'login') {
                  this.from_client_id = res.client_id
              } else if(res.type == 'say') {
                  let obj = {
                      username: res.from_client_name,
                      user_id: res.from_client_id,
                      avatar: res.avatar,
                      content: res.content,
                      created_at: res.time
                  }
                  this.talkingList.push(obj)
                  setTimeout(() => {
                      this.setScroll()
                  },100)

              }
          }
          this.ws.onerror = (e) => {
            console.info(e)
          }
          this.ws.onclose = (evt) => {}
      },
      // 切换点播列表
      plays(item, items, is) {

        let that = this;
          if(this.isPlay){
            return false;
          }
           if(this.eventId){
              //记录视频暂停
              console.log('切换视频暂停进来了！！！！！！')
              this.eventType=false
              // this.courseEvent( this.lessonId,  this.course.course_type,  this.course.id)
            }
            // 切换视频做推送
            console.log('1236',this.pauseTime,this.isPausePush,this.playingTime%30)
            if(this.pauseTime&&this.isPausePush&&this.playingTime%30){ 
                  this.switchLesson = true;
                  this.getCurrentTimes(this.playingTime%30,1,items.id);
                  this.playingTime = 0;
                  this.startPlayTime=0
                  this.pauseTime=0
            }else{
              this.updatePlayer(this.course.id, items.id, this.course.course_type, '')
            }
          this.isPlay=true;
          setTimeout(() => {
            that.isPlay=false
          }, 2000);
          this.lessonId = items.id
          this.$router.replace({
            query: {
              id: this.$route.query.id,
              course_type: this.$route.query.course_type,
              lessonId: items.id,
              class_id:this.query_classId
            }
          })
          
          this.checkId = items.id
          this.signalVisible = false
          this.endFlag = true
      },
      retry() {
          if(this.course.order_status == 1) {
            window.location.reload()
          } else if(this.course.order_status == 0) {
            this.$router.push({
              path: '/pay',
              query: {
                course_id: this.course.id,
                course_type: this.course.course_type
              }
            })
          } else {
            window.location.reload()
          }

      },
      // 浏览器限制
      userAgent(str) {
        if(str.indexOf('Chrome') != -1) {
          return true
        } else if(str.indexOf('Firefox') != -1) {
          return true
        } else if(str.indexOf('Safari') != -1) {
          return true
        } else {  // other
          return false
        }
      },
      refreshPlayer() {
        if(this.course.type == 'live' && this.lessonOne.live_status == 'done') { // 回放
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, this.replayOne.video_id)
        } else {  // 点播
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, '')
        }
      },
      //获取字符串str，根据某字符cha，检索出第几个的下标
      find(str,cha,num){
      var x=str.indexOf(cha);
      for(var i=0;i<num;i++)
      {
        x=str.indexOf(cha,x+1);
      }
      return x;
      },
      // update
      updatePlayer(id, lessonId, course_type, replay_id){
         let that = this;
         let class_id = this.query_classId
         console.log('class_id123123',)
          this.airPositionLeft = -340
          this.airPositionRight = -170
          try {
              this.player.dispose()
              clearInterval(this.timeInterval)
              clearInterval(this.airTimer)
          } catch (error) {
          }
          playerDetail({
              id: id,
              lesson_id: lessonId,
              course_type: course_type,
              replay_id: replay_id,
              class_id: Array.isArray(class_id)?class_id[0]:class_id&&class_id!='undefined'?class_id:''
          }).then(res => {
            if(res.data.code == 200) {
                this.playingTime = 0;
                this.timeNum = 0;
                this.isPause = false
                this.isClearInterval = true;//清除计时器
                this.is_least_learn = 0;//当前课程是否已经学完0没学完1已学完
                if(res.data.data.course_member.course_is_finished == 1) {
                  this.examVisible = true
                }
                try {
                  if(this.$store.state.jianGuan){
                    let nlbps = document.createElement('script');
                    nlbps.src = 'https://bps.nengliba.com/api/event/NLBpsWebSdk?appId=ede3f320-058d-fe29-354f-e8ba1edb5756';
                    let s = document.getElementsByTagName('script')[0];
                    s.parentNode.insertBefore(nlbps, s);
                    nlbps.onload=function(){
                      //生产环境取消debug
                      // window['_NLBps'].debug = true;
                        console.log('window',window['_NLBps'])
                        window['_NLBps'].trackEvent('learn_page', { 
                          // 学员平台登录账号
                          userId: that.$store.state.userName,
                          // 学员名称
                          userName: that.$store.state.realName,
                          // 学员电话
                          phoneNumber: that.$store.state.phoneNumber,
                          // 学员所学课程
                          courseName: res.data.data.course.title,
                          // 学员所学在班级编号
                          classNo: that.query_classId
                      })
                      }
                    }
                } catch(error){ 
                console.info(error)
                  } 
                let data =  res.data.data;           
                console.log('data.course.id',data.course.id)
                let url = window.location.pathname+window.location.search
                let newUrl = url.split('&lessonId=')[0]
                url = newUrl + '&lessonId='+res.data.data.lessonOne.id
                sessionStorage.faceCourseUrl = url
                this.abnormal = false
                if(!res.data.data.learn && res.data.data.course.type != 'live' && this.$store.state.open_trial == 0) {
                    clearInterval(this.timeInterval)
                    this.endFlag = false
                    this.signalFlag = 0
                    this.sigColor = "#c00"
                    this.player.pause()
                    this.$message.error('课程中【新增视频】或【替换视频】导致无法正常学习，请联系管理员处理');
                }
                this.course = res.data.data.course
                this.max_online_num = res.data.data.lessonOne.max_online_num
                this.course_member = res.data.data.course_member
                this.chapters = res.data.data.chapters
                this.exams = res.data.data.exams
                this.lessonOne = res.data.data.lessonOne
                this.lessonId = this.lessonOne.id
                this.replay_videos = res.data.data.replay_videos
                this.show = true
                this.learn = res.data.data.learn?res.data.data.learn:{status:''}
                this.airFlag = true
                this.is_certification_idcard = res.data.data.is_certification_idcard
                this.open_check_photo = res.data.data.open_check_photo
                this.is_id_photo = res.data.data.is_id_photo
                this.userName = res.data.ext.realname;
                this.userMobile = res.data.ext.mobile
                 let video = res.data.data.lessonOne.videos;
                 let learn_status =res.data.data.learn?res.data.data.learn.status:''
                // let num= this.find(video,'/',3);
                // let videos=video.substring(num+1);
                //     videos=videos.substring(0,videos.indexOf('.'));
                // this.videoName = videos;
                let times = Number(res.data.data.lessonOne.length)-res.data.data.lessonOne.watch_time;
                if(times>0&&times<=1){
                  this.getCurrentTimes(1)
                }
                  if(res.data.ext.day_limit_time&&this.learn.status == 'learning'){
                    var myDate = new Date().getTime(); //实例一个时间对象； 
                    var datetime = new Date();
                    var year = datetime.getFullYear();
                    var month = datetime.getMonth() + 1 < 10 ?"0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
                    var date = datetime.getDate() < 10 ?"0"+ datetime.getDate() : datetime.getDate();
                    let day_limit_time_begin = res.data.ext.day_limit_time_begin;
                    let begin_date = year+'-'+month+'-'+date+' '+day_limit_time_begin;
                    let day_limit_time_end = res.data.ext.day_limit_time_end;
                    let end_date = year+'-'+month+'-'+date+' '+day_limit_time_end;
                    let beginDatas = new Date(begin_date).getTime();
                    let endDatas = new Date(end_date).getTime();
                if(myDate<beginDatas||myDate>endDatas){
                    let that = this;//'今日已到达学习时长限制！休息一下，是为了更好的开始！'
                    let msg = '请在每日：'+day_limit_time_begin+ ' 至 ' +day_limit_time_end+'进行学习';
                    this.$messageBox.confirm(msg, '温馨提示', {
                    confirmButtonText: '确定',
                      showCancelButton:false,
                      showClose:false,
                      closeOnClickModal:false,
                      closeOnPressEscape:false
                    }).then(async () => {
                        // that.$router.go(-1)
                      })
                       this.player.stop();
                    clearInterval(this.timeInterval)
                }
              }
                if(this.course.order_status == 1) {
                  this.statusVisible = true
                } else if(this.course.order_status == 0) {
                  this.statusVisible = false
                }
                if(this.lessonOne.file_ext == 'pdf') {
                  // pdf试看拦截 => 不支持试看
                  if(this.$store.state.open_trial == 1) {
                    if(!this.$store.state.token) {
                      this.$store.commit("TRYSHOW", true)
                      console.log('11')
                      return false
                    } else {
                      if(this.course.order_status == 0) {
                        this.$store.commit("TRYSHOW", true)
                         console.log('12')
                        return false
                      }
                    }
                  }
                  if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                    this.photoVisible = true
                  } else {
                    this.pageNum = 1
                    if(res.data.data.is_face == 1) {
                      if(this.$store.state.listen_user_study_face == 1) {
                        let sessionObj = JSON.parse(sessionStorage.session)
                        let session = {
                          certification: sessionObj.certification,
                          token: sessionObj.token,
                          type: 2 // 监听
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit('FACEPLAYER',true)
                        this.$store.commit("SETTOKEN", sessionObj.token)
                        this.$store.commit("COURSEINFO", {
                          course_id: this.course.id,
                          course_type: this.course.course_type,
                          lesson_id: this.lessonId
                        })
                        this.$store.commit("PLAYTIME", this.learn.watch_time)
                        this.$store.commit('FACEVISIBLE', true)
                      } else if(this.$store.state.listen_user_study == 1) {
                        this.visibleGo = true
                      }
                    }
                    if(this.learn.status == 'learning') {
                      this.signalFlag = 1
                      this.sigColor = "#52c41a"
                    } else {
                      this.signalFlag = 2
                      this.sigColor = 'gold'
                    }
                    let h = document.body.offsetHeight - 66 -80
                    document.querySelector(".pdf").style.height = h+'px'
                    this.pdfUrl = pdf.createLoadingTask({url: this.lessonOne.videos, CMapReaderFactory})

                    this.signalVisible = true
                    let currentTime = new Date().getTime() / 1000 - this.learn.watch_time  //当前时间开始计时
                    console.info(currentTime)
                    clearInterval(this.timeInterval)
                    if ((this.$store.state.open_valid_length == 1 && res.data.data.is_face == 0 && this.signalFlag == 2) || (this.signalFlag == 1 && res.data.data.is_face == 0 && this.$store.state.open_valid_length == 0)) {
                      if(this.$store.state.is_cancel_progress==1){
                         console.log('13')
                        return false;
                      }
                    
                      // this.timeInterval = setInterval(() => {
                      //   // if(this.course.type == 'normal' && this.learn.status == 'learning') {
                      //   if(this.course.type == 'normal') {
                      //     let pastTime = new Date().getTime() / 1000 + - currentTime
                      //     this.pdfCurrentTime = pastTime
                      //     let isFinishType
                      //     if(pastTime < parseFloat(this.lessonOne.length)) {
                      //       isFinishType = 0
                      //     } else {
                      //       isFinishType = 1
                      //     }
                      //     this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.curPageNum}_${this.query_classId}_class`
                      //      console.log('coursePush2')
                      //     let data = {
                      //         course_lesson_id: this.course_lesson_id_param,
                      //         play_time: this.signalFlag == 1 ? pastTime : "",
                      //         is_finish: isFinishType,
                      //         timestamp:parseInt(new Date().getTime()/1000),
                      //         salt:'EdE89aevzfEuD14G'
                      //     };
                      //     let keySortStr =objKeySort(data);
                      //     let dataStr = '';
                      //     for(let key in keySortStr){
                      //       dataStr+=key+'='+keySortStr[key]+(key!='timestamp'?'&':'')
                      //     }
                      //     console.log('dataStr',dataStr)
                      //     // datas.signature = hexMD5(dataStr)
                      //     coursePush({
                      //         course_lesson_id: this.course_lesson_id_param,
                      //         play_time: this.signalFlag == 1 ? pastTime : "",
                      //         is_finish: isFinishType,
                      //         timestamp:parseInt(new Date().getTime()/1000),
                      //         signature : hexMD5(dataStr)
                      //     }).then(res => {
                      //         if(res.data.code == 200) {
                      //           this.lesson_finish_percent = res.data.data.lesson_finish_percent
                      //           this.chapters.map((item, i) => {
                      //               item.lessons.map((items, is) => {
                      //                 if(items.id == this.lessonOne.id) {
                      //                   items.learn_percent = res.data.data.lesson_finish_percent
                      //                   this.course_member.complete_percent = res.data.data.course_finish_percent
                      //                 }
                      //               })
                      //             })
                      //           if(res.data.data.course_is_finished == 1) {
                      //             this.examVisible = true
                      //           }
                      //           if(res.data.data.is_least_learn == 1) {
                      //             this.airPositionRight = 0
                      //             clearInterval(this.timeInterval)
                      //             // pdf查看进度完成，继续播放下一课件
                      //             if(this.lessonOne.next_lesson.lesson_id != 0) {
                      //               this.visible = true
                      //               this.timeoutInterval = setInterval(() => {
                      //                 if(this.timeout > 1) {
                      //                   this.timeout --
                      //                 } else {
                      //                   this.visible = false
                      //                   this.timeout = 5
                      //                   this.$router.replace({
                      //                     query: {
                      //                       id: this.$route.query.id,
                      //                       course_type: this.$route.query.course_type,
                      //                       lessonId: this.lessonOne.next_lesson.lesson_id,
                      //                       class_id:this.query_classId
                      //                     }
                      //                   })
                      //                   this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '')
                      //                   clearInterval(this.timeoutInterval)
                      //                 }
                      //               }, 1000)
                      //             }
                      //           }
                      //         } else if(res.data.code == 111) {
                      //           clearInterval(this.timeInterval)
                      //           if(res.data.data.listen_user_study_face == 1) {
                      //             let sessionObj = JSON.parse(sessionStorage.session)
                      //             let session = {
                      //               realname: sessionObj.realname,
                      //               idcard: sessionObj.idcard,
                      //               certification: sessionObj.certification,
                      //               token: sessionObj.token,
                      //               type: 2 // 监听
                      //             }
                      //             let sessionStr = JSON.stringify(session)

                      //             this.$store.commit('FACEPLAYER',true)
                      //             sessionStorage.setItem('session', sessionStr)
                      //             this.$store.commit("SETTOKEN", sessionObj.token)
                      //             this.$store.commit("PLAYTIME", this.pdfCurrentTime)
                      //             this.$store.commit('FACEVISIBLE', true)
                                 
                      //           } else if(this.$store.state.listen_user_study == 1) {
                      //             this.visibleGo = true
                      //           }
                      //         } else {
                      //           this.signalFlag = 0
                      //           this.sigColor = "#c00"
                      //           clearInterval(this.timeInterval)
                      //           this.endFlag = false
                      //           this.abnormal = true
                      //         }
                      //     }).catch(err => {
                      //       this.signalFlag = 0
                      //       this.sigColor = "#c00"
                      //       this.errorPrompt = true;
                      //       clearInterval(this.timeInterval)
                      //       this.endFlag = false
                      //     })
                      //   }
                      // }, 10000)
                    }
                  }
                }
                if(res.data.data.course.type == 'normal') {
                    this.checkId = this.lessonOne.id
                    if(this.$store.state.open_trial == 0) {  // 不支持试看
                      if(res.data.data.course.order_status == 0) {
                        this.$store.commit("PLAYERVISIBLE", false)
                        this.errTipsTxt = "请购买后再进行学习"
                        this.errBtnTxt = "立即购买"
                         console.log('13')
                        return false
                      } else if(res.data.data.course.order_status == 2) {
                        this.errTipsTxt = "课程正在退款中...不能观看！"
                        this.$store.commit("PLAYERVISIBLE", false)
                      }
                      if(res.data.data.lessonOne.videos == "") {
                          this.$store.commit("PLAYERVISIBLE", false)
                          this.errTipsTxt = "该课程已下架..."
                           console.log('14')
                          return false
                      }
                      if(res.data.data.learn) {
                        if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                          this.photoVisible = true
                        } else {
                          let player = new Player()   // 普通视频
                          this.player = player.init(this.lessonOne.videos,learn_status)
                        }
                      } else {
                        setTimeout(() => {
                          this.$store.commit("PLAYERVISIBLE", false)
                          this.errTipsTxt = "课程数据初始化中...请稍后再试"
                        }, 1000)
                      }
                    } else if(this.$store.state.open_trial == 1) {  
                        this.signalFlag=6

                       //  可以试看 => 生成播放器
                      if(this.$store.state.token) {
                        if(this.course.order_status == 1) {
                          if(this.is_certification_idcard == 1 || this.is_id_photo == 1) {
                            this.photoVisible = true
                          } else {
                            let player = new Player()   // 普通视频
                            this.player = player.init(this.lessonOne.videos,learn_status)
                          }
                        } else if(this.course.order_status == 0) {
                            let player = new Player()   // 普通视频
                            this.player = player.init(this.lessonOne.videos,learn_status)
                        }
                      } else {
                        let player = new Player()   // 普通视频
                        this.player = player.init(this.lessonOne.videos,'tryWatch')
                      }
                    }
                } else if (res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                    this.replayOne = res.data.data.replayOne
                    this.listIndex = this.replayOne.video_id
                    this.$store.commit("PLAYERVISIBLE", true)
                    this.tab = [
                        {
                            name: '视频列表'
                        },
                        {
                            name: '聊天室'
                        }
                    ]
                    this.talkingList = res.data.data.chats
                    setTimeout(() => {
                        this.setScroll()
                    },100)
                    if(res.data.data.learn) {
                      let player = new Player()   // 直播回放
                      this.player = player.init(res.data.data.replayOne.source,learn_status)
                    } else {
                      setTimeout(() => {
                        this.$store.commit("PLAYERVISIBLE", false)
                        this.errTipsTxt = "回放数据初始化中...请稍后再试"
                      }, 1000)
                    }

                    this.index = 0
                    this.$nextTick(() => {
                      this.replayWrapHeight = document.querySelector('.player-content').offsetHeight - 10
                    })
                } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'living') {
                    this.$store.commit("PLAYERVISIBLE", true)
                    this.tab = [
                        {
                            name: '聊天室'
                        }
                    ]
                    let player = new Player(true)   // 直播
                    this.player = player.init(res.data.data.lessonOne.streaming_url_artc+'&aliyun_uuid='+this.$store.state.uuid,learn_status)
                    this.index = 1
                } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'default') {
                    this.$store.commit("PLAYERVISIBLE", false)
                    this.errTipsTxt = "直播未开始！"
                }
              
                if(this.lessonOne.file_ext != 'pdf') {
                  this.player.on('ready',() => {
                    if(res.data.data.is_face == 1) {
                      if(this.$store.state.listen_user_study_face == 1) {
                        let sessionObj = JSON.parse(sessionStorage.session)
                        let session = {
                          certification: sessionObj.certification,
                          token: sessionObj.token,
                          type: 2 // 监听
                        }
                        let sessionStr = JSON.stringify(session)
                        sessionStorage.setItem('session', sessionStr)
                        this.$store.commit('FACEPLAYER',true)
                        this.$store.commit("SETTOKEN", sessionObj.token)
                        this.$store.commit("PLAYTIME", this.learn.watch_time)
                        this.$store.commit("COURSEINFO", {
                          course_id: this.course.id,
                          course_type: this.course.course_type,
                          lesson_id: this.lessonId
                        })
                        this.$store.commit('FACEVISIBLE', true,)
                        //记录视频暂停
                        // this.eventType=false
                        // this.courseEvent( lessonId, course_type, data.course.id)
                        this.player.pause()
                      } else if(this.$store.state.listen_user_study == 1) {
                        this.visibleGo = true
                      }
                    }
                    this.$store.commit("PLAYERVISIBLE", true)

                    let videoElement = document.querySelector("video")

                    if(sessionStorage.vol) {
                      videoElement.volume = sessionStorage.vol
                    } else {
                      sessionStorage.vol = videoElement.volume
                    }
                    videoElement.addEventListener("volumechange", function() {
                      console.info(videoElement.volume+'change')
                      sessionStorage.vol = videoElement.volume
                    })
                    if(this.hostname != 'desikai.bjadks.com') {
                      if(!this.userAgent(navigator.userAgent)) {
                        localStorage.removeItem("firstPlayer")
                        this.mmm = true
                        
                        
                         //记录视频暂停
                        // this.eventType=false
                        // this.courseEvent( lessonId, course_type, data.course.id)
                        let prompt = confirm("为保证上课体验，本站仅支持使用以下浏览器学习：谷歌Chrome, 火狐Firefox, 微软Edge，点击“确定”跳转下载浏览器页面。")
                        if(prompt || !prompt) {
                          this.$router.back(-1)
                        }
                      }
                    }
                  })
                 
                  this.player.on('pause', () => {
                    clearInterval(this.timeInterval);
                    console.log('视频暂停..............222222,计时器已清')
                        // let leastTime = this.playingTime!=30&&this.playingTime%30<=3&&this.remain_lesson_length>2;
                        //记录视频暂停--暂停推送--push合并
                        this.pauseEndTime = new Date().getTime()/1000;
                        //人脸、人机验证时暂停不推送
                        console.log('this.pauseTime',this.pauseTime,'this.isPausePush',this.isPausePush,'this.playingTime',this.playingTime%30)
                        if(this.pauseTime&&this.isPausePush){ 
                            console.log('是这里！！！！！！！！')
                            this.getCurrentTimes(this.playingTime>=30&&this.playingTime%30==0?30:this.playingTime%30,1);
                            this.playingTime = 0;
                            this.startPlayTime=0
                            this.pauseTime=0
                        }
                        this.eventType=false
                        // this.courseEvent( lessonId, course_type, data.course.id)
                        this.playIconFlag = false
                        if(this.signalFlag == 0) {  // 当出现异常时，暂停来源为false 避免刷新重试时不进入seek
                        this.isPause = false
                        } else {
                        this.isPause = true
                        }
                        // clearInterval(this.timeInterval)
                        if(this.signalFlag != 0) {
                        this.signalFlag = 3
                        this.sigColor = "#eee"
                        }
                  })
                  this.player.on('play', () => {
                      //记录视频开始
                      this.eventType=true
                      // this.courseEvent( data.lessonOne.id, course_type, data.course.id)

                      this.videoHeight = document.querySelector(".prism-player").offsetHeight
                      this.playIconFlag = true
                     
                      // 试看x分钟计时器
                      if(this.course.order_status == 0) {
                        clearInterval(this.tryTimer)
                        this.tryTimer = setInterval(() => {
                          if(this.player.getCurrentTime() > this.$store.state.trial_length*60) {
                            this.player.pause()
                                //记录视频暂停
                            // this.eventType=false
                            // this.courseEvent( lessonId, course_type, data.course.id)
                            clearInterval(this.tryTimer)
                            this.$store.commit("TRYSHOW", true)
                          }
                        }, 1000)
                      }

                      if(this.course.type != 'live') {
                          this.signalVisible = true
                      } else if(this.course.type == 'live' && this.lessonOne.live_status == 'living') {
                          clearInterval(this.liveInterval)
                          if(this.$store.state.is_cancel_progress==1){
                             console.log('15')
                            return false;
                          }
                          this.liveInterval = setInterval(() => {
                              let data = {
                              course_lesson_id: `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.query_classId}_class`,
                              play_time: this.player.getCurrentTime() + this.learn.learn_time,
                              is_finish: 0,
                              timestamp:parseInt(new Date().getTime()/1000),
                              salt:'EdE89aevzfEuD14G'
                            };
                            let keySortStr =objKeySort(data);
                            let dataStr = '';
                            for(let key in keySortStr){
                              dataStr+=key+'='+keySortStr[key]+(key!='timestamp'?'&':'')
                            }
                             console.log('coursePush3')
                            coursePush({
                                course_lesson_id: `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.query_classId}_class`,
                                play_time: this.player.getCurrentTime() + this.learn.learn_time,
                                is_finish: 0,
                                timestamp:parseInt(new Date().getTime()/1000),
                                signature : hexMD5(dataStr)
                            }).then(res => {
                                if(res.data.code == 200) {
                                  this.signalFlag = 1
                                  this.sigColor = "#52c41a"
                                  this.max_online_num = res.data.data.max_online_num
                                } else {
                                  this.signalFlag = 0
                                  this.sigColor = "#c00"
                                  this.abnormal = true
                                  this.player.pause()
                                  //记录视频暂停
                                  // this.eventType=false
                                  // this.courseEvent( lessonId, course_type, data.course.id)
                                  clearInterval(this.liveInterval)
                                  this.player.fullscreenService.cancelFullScreen()
                                }

                            }).catch(err => {
                              clearInterval(this.liveInterval)
                            })
                          }, 10000)
                      }
                      console.log('res.data.data.lessonOne.live_status',res.data.data.lessonOne.live_status)
                      console.log('res.data.data.course.type',res.data.data.course.type)
                      // 点播和直播回放
                      if(res.data.data.course.type == 'normal' || res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                        console.log('进来了123')
                          if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {  // 回放
                            if(this.replayOne.percent == 100) {
                              this.signalFlag = 2
                              this.sigColor = 'gold'
                            } else {
                              this.signalFlag = 1
                              this.sigColor = "#52c41a"
                            }
                            if(this.replayOne.percent == 0 && this.airFlag) {  // 第一次播放气泡提示
                              this.airPositionLeft = 0
                              this.airFlag = false   // 只弹起一次，避免暂停后继续播放再次弹出
                              this.airTimer = setTimeout(() => {
                                this.airPositionLeft = -340
                              }, 5000)
                            }
                            this.replay_id = this.replayOne.video_id
                            console.log('seeeeek2',this.replayOne.percent,this.replayOne.percent,this.isPause);
                            console.log('this.fullScreenStatus',this.fullScreenStatus)
                            if(this.replayOne.percent != 10 && this.replayOne.percent != 100 && !this.isPause) {   // isPause是否暂停后播放
                              if(!this.fullScreenStatus) {
                                this.player.seek(this.replayOne.watch_time)
                              }
                            } else {
                              //记录视频开始
                              console.log('this.eventType444')
                              // this.eventType=true
                              // this.courseEvent( lessonId, course_type, data.course.id)
                              console.log('play1')
                              this.player.play()
                            }
                          } else {  //  点播
                          
                            if(this.lessonOne.learn_percent == 100) {
                              this.signalFlag = 2
                              this.sigColor = 'gold'
                            } else {
                              this.signalFlag = 1
                              this.sigColor = "#52c41a"
                            }
                            if(this.lessonOne.learn_percent == 0 && this.airFlag && this.$store.state.open_trial == 0) {  // 第一次播放气泡提示
                              this.airPositionLeft = 0
                              this.airFlag = false   // 只弹起一次，避免暂停后继续播放再次弹出
                              this.airTimer = setTimeout(() => {
                                this.airPositionLeft = -340
                              }, 5000)
                            }
                            console.log('seeeeek1',this.lessonOne.learn_percent,this.isPause);
                            console.log('this.fullScreenStatus',this.fullScreenStatus)
                            if(this.lessonOne.learn_percent != 0 && this.lessonOne.learn_percent != 100 && !this.isPause) {
                              console.info(this.fullScreenStatus)
                              if(!this.fullScreenStatus) {
                                console.log('seeeeek')
                                this.player.seek(this.learn.watch_time)
                              }
                            } else {
                              console.log('play2')
                              this.player.play()
                            }
                          }
                         
                          let that = this;
                          //this.$store.state.open_valid_length == 1 &&
                          if (( this.course.order_status == 1 && this.signalFlag == 2) || (this.signalFlag == 1 && this.course.order_status == 1)) {
                        
                              clearInterval(this.timeInterval);
                              // this.isClearInterval = false;
                              console.log('清了')
                              this.timeInterval = setInterval(() => {
                          
                                let time = that.dateFormat();
                                let oldT = that.pushEndTime?new Date(that.pushEndTime).getTime():0;
                                let curT = new Date().getTime()/1000;
                                console.log('上一次结束时间',parseInt(oldT),'---',parseInt(curT))

                                if(that.playingTime!=0&&that.playingTime%30==0){//每次推送的间隔要大于30s
                                if(res.data.data.course.type == 'normal') {
                                // if(res.data.data.course.type == 'normal' && this.learn.status == 'learning') {
                                  this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.query_classId}_class`
                                } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                                  this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.replay_id}_${this.query_classId}_class`
                                }
                                console.log('结束时间123123123',time)
                                console.log('that.playingTime',that.playingTime)
                                   that.oldStartTime = that.pushStartTime,
                                   that.startPlayTime = 0 
                                   that.pauseTime = 0
                                   // that.timeNum = 0;
                                  //  that.newCoursePush(that.playingTime==30?30:that.playingTime%30);
                                   that.getCurrentTimes(30) 
                                   that.pushEndTime = parseInt(curT)// time

                            } 
                              if(that.startPlayTime==0){
                                  that.pushStartTime =parseInt(curT)
                              }
                              if(that.pauseTime==0){
                                  that.pauseStartTime =parseInt(curT)
                              }
                              if(that.player.getStatus()=='playing'){
                                    that.playingTime = that.playingTime+1
                                    that.pauseTime = that.pauseTime+1
                                    that.startPlayTime = that.startPlayTime+1
                                  console.log('计时器',that.timeNum+=1,'playingTime',that.playingTime)

                              }
                              if(that.player.getStatus()=='ready'){
                                console.log('ready')
                                  // this.$router.go(0)
                                  // that.reload();
                                  that.player.pause()
                                  that.player.play()
                                  // that.updatePlayer(that.$route.query.id, that.lessonId, that.$route.query.course_type, '')
                              }
                            },1000)
                          //  }
                          }
                      }
                  })
                  this.player.on('ended', () => {
                      console.log('播放结束..............11111111')
                     let remain_lesson_length = this.remain_lesson_length
                     let time = that.dateFormat();
                     let playingTime = 0;
                        console.log('结束时间',time)
                        // that.pushEndTime = parseInt(new Date().getTime()/1000)//time
                      // setTimeout(() => {
                      //     console.log('进来了，补推？？？？？',remain_lesson_length)
                      //     if(remain_lesson_length>0&&remain_lesson_length<10){
                      //       that.newCoursePush(remain_lesson_length,1);
                      //     }
                      // }, 1500);
                      clearInterval(this.timeInterval)
                       if(this.playingTime%30>0){
                          playingTime = this.playingTime%30
                        }else{
                          playingTime =30;
                        }
                        console.log('播完了',this.$store.state.open_valid_length,res.data.data.course.type,this.learn.status)

                      if (res.data.data.course.type == 'normal' && this.learn.status == 'finished') {
                        // this.$store.state.open_valid_length==1 
                        this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.query_classId}_class`
                        if(this.$store.state.is_cancel_progress==1){
                          console.log('is_cancel_progress',this.$store.state.is_cancel_progress)
                          return false;
                        }
                         console.log('coursePush5')
                         let play_time = parseInt(this.lessonOne.length)-parseInt(this.current_play_time);
                          let datas  ={
                             course_lesson_id: this.course_lesson_id_param,
                              play_time:this.playingTime%30>0?this.playingTime:0,//playingTime, //(this.player.getCurrentTime() % 10).toFixed(2),
                              is_finish: 1,
                              timestamp:parseInt(new Date().getTime()/1000),
                              // push_end_time:that.pushEndTime,
                              // push_start_time:that.pushStartTime,
                              salt:'EdE89aevzfEuD14G'
                          };
                            let keySortStr =objKeySort(datas);
                            let dataStr = '';
                            for(let key in keySortStr){
                              dataStr+=key+'='+keySortStr[key]+(key!='timestamp'?'&':'')
                            }
                            
                            // if(this.lesson_finish_percent!=100){
                            //   console.log('进来了。。。。。。。。。。结束了。。。。',this.lesson_finish_percent)
                            //    coursePush({
                            //       course_lesson_id: this.course_lesson_id_param,
                            //       play_time:play_time>0?play_time:0,//parseInt(this.lessonOne.length)-parseInt(this.current_play_time),//playingTime
                            //       is_finish: 1,
                            //       signature:hexMD5(dataStr),
                            //       push_end_time:that.pushEndTime,
                            //       push_start_time:that.pushStartTime,
                            //       timestamp:parseInt(new Date().getTime()/1000)
                            //   }).then(res => {

                            //   }).catch(err => {

                            //   })
                            // }
                      }
                      if(res.data.data.course.type == 'normal' && this.learn.status == 'learning' && this.endFlag || res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done' && this.endFlag)  {
                        if(res.data.data.course.type == 'normal' && this.learn.status == 'learning') {
                          this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.query_classId}_class`
                        } else if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done') {
                          this.course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type.split('_')[0]}_${this.replay_id}_${this.query_classId}_class`
                        }
                        if(res.data.data.course.type == 'live' && res.data.data.lessonOne.live_status == 'done' && this.replayOne.percent == 100) {  //回放学完100%

                        } else if(res.data.data.course.type == 'normal' && this.learn.status == 'learning' && this.learn_percent == 100) {  // 点播学完100%

                        } else {
                          if(this.$store.state.is_cancel_progress==1){
                             console.log('16')
                            return false;
                          }
                                                   
                           console.log('coursePush6')
                            let datas  ={
                              course_lesson_id: this.course_lesson_id_param,
                              play_time:parseInt(this.lessonOne.length)-parseInt(this.current_play_time),//playingTime
                              is_finish: 1,
                              salt:'EdE89aevzfEuD14G',
                              timestamp:parseInt(new Date().getTime()/1000),
                              // push_end_time:that.pushEndTime,
                              // push_start_time:that.pushStartTime
                          };
                             
                            let keySortStr =objKeySort(datas);
                            let dataStr = '';
                            for(let key in keySortStr){
                              dataStr+=key+'='+keySortStr[key]+(key!='timestamp'?'&':'')
                            }
                        //      if(this.lesson_finish_percent!=100){
                        //   coursePush({
                        //       course_lesson_id: this.course_lesson_id_param,
                        //       play_time:parseInt(this.lessonOne.length)-parseInt(this.current_play_time),//playingTime
                        //       is_finish: 1,
                        //       signature:hexMD5(dataStr),
                        //       timestamp:parseInt(new Date().getTime()/1000),
                        //       push_end_time:that.pushEndTime,
                        //       push_start_time:that.pushStartTime
                        //   }).then(res => {
                        //       if(res.data.code == 200) {
                        //         this.lesson_finish_percent = res.data.data.lesson_finish_percent
                        //         if(res.data.data.is_least_learn == 1) {
                        //           this.airPositionRight = 0
                        //           clearInterval(this.timeInterval)
                        //           if(res.data.data.course_is_finished == 1) {
                        //             this.examVisible = true
                        //           }
                        //         }
                        //         this.chapters.map((item, i) => {
                        //           item.lessons.map((items, is) => {
                        //             if(items.id == this.lessonOne.id) {
                        //               items.learn_percent = res.data.data.lesson_finish_percent
                        //               this.course_member.complete_percent = res.data.data.course_finish_percent
                        //             }
                        //           })
                        //         })
                        //       }
                        //   }).catch(err => {

                        //   })
                        //  }
                        }
                      }
                      if(this.hostname != "iteq.zckt.tv") {
                        this.visible = true
                        this.timeoutInterval = setInterval(() => {
                          if(this.timeout > 1) {
                            this.timeout --
                          } else {
                            this.visible = false
                            this.timeout = 5
                            if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {  // 回放
                              if(this.replayOne.next_replay_id != 0) {
                                this.$router.replace({
                                  query: {
                                    id: this.$route.query.id,
                                    course_type: this.$route.query.course_type,
                                    lessonId: this.lessonOne.next_lesson.lesson_id,
                                    class_id:this.query_classId
                                  }
                                })
                                this.updatePlayer(this.course.id, this.lessonOne.id, this.course.course_type, this.replayOne.next_replay_id)
                              }
                            } else {  //  点播
                              console.info('进入点播')
                              if(this.lessonOne.next_lesson.lesson_id != 0) {
                               if(this.is_least_learn==0){
                                //   let video_length = Number(res.data.data.length)
                                //   let current_play_time =  Number(res.data.data.current_play_time)
                                  // 解决%99看不完的问题：当视频推送时间剩余20秒时，播放器seek到最后25秒，留五秒预值
                                  this.player.play();
                                  setTimeout(() => {
                                    console.log('走补推逻辑了？？？？')
                                   that.player.seek(this.current_play_time-15)
                                  }, 500);
                               }else{
                                     this.$router.replace({
                                        query: {
                                            id: this.$route.query.id,
                                            course_type: this.$route.query.course_type,
                                            lessonId: this.lessonOne.next_lesson.lesson_id,
                                            class_id:this.query_classId
                                        }
                                        })
                                        
                                        this.updatePlayer(this.course.id, this.lessonOne.next_lesson.lesson_id, this.course.course_type, '');
                                        this.$router.go(0);
                               }
                              
                              }
                            }
                            clearInterval(this.timeoutInterval)
                          }
                        }, 1000)
                      }
                  })
                  this.player.on('startSeek', (data) => {
                      this.currentTime = this.player.getCurrentTime()
                  })
                  this.player.on('requestFullScreen', () => {
                    console.info('进入全屏')
                    this.fullScreenStatus = true
                  })
                  this.player.on('cancelFullScreen', () => {
                    console.info('退出全屏')
                    this.fullScreenStatus = false
                  })
                  this.player.on('completeSeek', (data) => {
                    console.info('data.paramData',data.paramData)
                    if(this.course.order_status == 1) {
                      // 往后拖动不再推进度
                      if(data.paramData > this.currentTime && this.signalFlag != 2&&this.$store.state.is_cancel_progress==0) {
                          this.player.fullscreenService.cancelFullScreen()
                          clearInterval(this.timeInterval)
                          this.endFlag = false
                          this.signalFlag = 0
                          this.sigColor = "#c00"
                          this.abnormal = true
                          this.player.pause()
                      }
                    } else if(this.course.order_status == 0) {
                      if(data.paramData > this.$store.state.trial_length * 60) {
                        this.player.pause()
                        this.$store.commit("TRYSHOW", true)
                      }
                    }
                      

                  })
                  this.player.on('error', error => {
                      this.$store.commit("PLAYERVISIBLE", false)
                      this.errTipsTxt = "视频中断，请检查网络！错误码: "+res.data.data.lessonOne.course_id+' | '+res.data.data.lessonOne.id+' | '+this.videoName
                  })
                }
              console.log('this.play_expired',res.data.data.play_expired);
              let play_expired = res.data.data.play_expired;
              this.play_expired = play_expired;
              let that = this;
              if(play_expired){
                setTimeout(() => {
                 that.player.pause();
                  
                }, 500);
                this.is_play_expired=true;
              }
              if(play_expired==1){
                this.play_expired_text='班级已结束，不记录学习数据！请知悉'
              }else if(play_expired==2){
                this.play_expired_text='课程已结束，不记录学习数据！请知悉'
              }
              
              // setTimeout(() => {
              //   that.player.play();
              // }, 1500);
             console.log('play了', that.player)

              

            }
          }).catch(err => {
              this.show = true
          })
          
      },
      photoHandleOk() {
        this.photoVisible = false
        this.$router.push('/user/setuser')
      },
      photoCancel() {
        this.photoVisible = false
        this.$router.go(-1)
      },
      // 切换回放列表
      checkList(item, i) {
          this.listIndex = item.video_id
          this.replay_id = item.video_id
          this.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, item.video_id)
      },
      tabMethod(item, i) {
          if(this.course.type == 'live' && this.lessonOne.live_status == 'done') {
            this.index = i
          }
      },
      // 发送聊天消息
      sendMsg() {
          if(this.$store.state.token) {
              if(this.sendVal != "") {
                  if(this.lessonOne.live_status == 'done') {
                      this.$message.error("直播已结束，不能发言")
                  } else {
                      this.ws.send(
                          '{"type": "say","to_client_id": "all","content":"'+this.sendVal+'", "access_token": "'+this.token.replace('Bearer ', "")+'"}'
                      )
                      this.sendVal = ""
                  }

              } else {
                  this.$message.error("不能发送空消息！")
              }
          } else {
              this.$store.commit("SHOWLOGIN", true)
          }
      },
      // 聊天室设置滚动条到最底部
      setScroll() {
          let d = document.querySelector(".txtWrap")
          d.scrollTop = d.scrollHeight - d.clientHeight
      },
      backWay() {
        let urlObj =this.getRequest();
        localStorage.removeItem("firstPlayer")
        let params = window.location.search.split('&lessonId=')[0]
        console.log('paramsparams',params)
        this.$router.push({
          path: '/course/studydetail?id='+urlObj.id+'&course_type='+urlObj.course_type+'&class_id='+urlObj.class_id
        })
        if(this.messageFlag){
          this.messageFlag = false
          this.$messageBox.close();
        }
      },
      openVisibleTest() {
        this.quizListUpdate(0)
      },
      quizListUpdate(n) {
        quizList({
          course_id: this.$route.query.id,
          course_type: this.$route.query.course_type,
          lesson_id: this.lessonOne.id,
          is_again: n
        }).then(res => {

          if(res.data.code == 200) {
            this.questionArr = res.data.data.data
            this.is_again = res.data.data.is_again
            this.visibleTest = true
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      openVisibleCourse() {
        this.visibleCourse = true
      },
      courseOk() {
        this.visibleCourse = false
      },
      packUp() {
        if(!this.packFlag) {
          if(this.course.type == 'live') {
            this.sidebarWidth = 0
          } else {
            this.sidebarWidth = 18
          }
          this.packFlag = true
        } else {
          this.sidebarWidth = 380
          this.packFlag = false
        }
      },
      async newCoursePush(time,is_pause,lessonId){
            console.log('time',time,is_pause)
            // if(this.$store.state.tenant_id!='2527'&&this.$store.state.tenant_id!='1481'&&this.$store.state.tenant_id!='1766'){
            //     let pushing = localStorage.getItem('pushing');
            //     console.log('pushing',pushing) 
            //     if(pushing==='0'){
            //       setTimeout(() => {
            //         localStorage.removeItem('pushing')
            //       }, 10000);
            //       return false;
            //     }
            //     localStorage.setItem('pushing','0');
            //     if(!time){
            //       return false;
            //     }
            // }
            let oldT = this.pushEndTime?new Date(this.pushEndTime).getTime():0;
            let curT = new Date().getTime()/1000;
            console.log('this.pushEndTime',this.pushEndTime)
            console.log('this.query_classId',this.query_classId)
            console.log('oldT---curT',oldT,curT)
            // 限制同时间推送逻辑
            // if(parseInt(curT)==parseInt(oldT)){
            //   return false;
            // }
            let course_lesson_id_param = `${this.course.id}_${this.lessonOne.id}_${this.course.course_type?this.course.course_type.split('_')[0]:this.$route.query.course_type.split('_')[0]}_${this.query_classId}_class`
            console.log('this.course_lesson_id',this.course_lesson_id)
            console.log('course_lesson_id_param',course_lesson_id_param)
            let that =this;
            this.pushEndTime = parseInt(new Date().getTime()/1000)
            var params = {
                    course_lesson_id:this.is_retry&&this.verify!=1?this.course_lesson_id:course_lesson_id_param,
                    play_time: time,
                    is_finish: 0,
                    timestamp:this.currentTimes,//parseInt(new Date().getTime()/1000),
                    is_pause:is_pause?is_pause:0,
                    is_retry:this.is_retry//人机验证、人脸验证补推通知
                    
                }
            if (this.signalFlag == 2) {
            params = {
                course_lesson_id: this.is_retry?this.course_lesson_id:course_lesson_id_param,
                is_finish: 0,
                play_time: time,
                timestamp:this.currentTimes,//parseInt(new Date().getTime()/1000),
                is_pause:is_pause?is_pause:0,
                is_retry:this.is_retry//人机验证、人脸验证补推通知
            }
            }
                let datas  =JSON.parse(JSON.stringify(params));
                datas.salt='EdE89aevzfEuD14G'
                console.log('coursePush4',that.$store.state)
                if(that.$store.state.is_cancel_progress==1){
                    return false;
                }
                let keySortStr =objKeySort(datas);
                let dataStr = '';
                for(let key in keySortStr){
                    dataStr+=key+'='+keySortStr[key]+(key!='timestamp'?'&':'')
                }
                params.signature = hexMD5(dataStr)
                let res =  await coursePush(params);//.then(res => {
                console.log('resresres',res)
                localStorage.setItem('pushing',1);
              
                        this.oldEndTime = that.pushEndTime
                        if(localStorage.getItem('isback')==1){
                           localStorage.removeItem('isback')
                          return false;
                        }
                        if(this.switchLesson){
                          this.updatePlayer(this.course.id, lessonId, this.course.course_type, '')
                          this.switchLesson = false;
                          return false;
                        }
                        if(res.data.code!=200){
                          this.player.pause()
                        
                        }
                        if(res.data.code == 200) {
                          this.verify=0;
                          this.lesson_finish_percent = res.data.data.lesson_finish_percent

                          if(this.is_retry  == 1){
                            this.is_retry = 0;
                            this.$router.go(0)
                            // debugger
                            // that.updatePlayer(this.$route.query.id, this.lessonId, this.$route.query.course_type, '')
                          }
                        //    that.pushStartTime = that.pushEndTime
                        // if(this.player.getCurrentTime() > this.learn.watch_time) {
                        //     this.signalFlag = 1
                        //     this.sigColor = "#52c41a"
                        // }
                        this.pre_play_time = res.data.data.pre_play_time
                        this.current_play_time = Number(res.data.data.current_play_time)
                        this.learn.watch_time = res.data.data.pre_play_time
                        this.remain_lesson_length = res.data.data.remain_lesson_length
                        this.video_length = Number(res.data.data.length)
                         if(res.data.data.is_least_learn == 1&&res.data.data.course_is_finished==1) {
                            clearInterval(this.timeInterval);
                          this.$messageBox.confirm('课程已完成，还需完成并通过考试',{
                                  confirmButtonText: "去考试",
                            }).then(_ => {
                                    if(that.$store.state.token) {
                                        that.$router.push({
                                            path:'/class/exam',
                                            query:{
                                                id:that.$route.query.class_id
                                            }
                                        })
                                    }else{
                                        that.$store.commit("SHOWLOGIN", true)
                                    }
                            }).catch(_ => {
                                that.$router.push({
                                    path: '/class/catalog',
                                    query: {
                                        id:that.$route.query.class_id?that.$route.query.class_id:'',
                                    }
                                })
                            });
                         }
                        if(res.data.data.is_least_learn == 1) {
                            this.is_least_learn = res.data.data.is_least_learn 

                            this.airPositionRight = 0
                            clearInterval(this.timeInterval);
                            
                        }
                        this.chapters.map((item, i) => {
                            item.lessons.map((items, is) => {
                            if(items.id == this.lessonOne.id) {
                                items.learn_percent = res.data.data.lesson_finish_percent
                                this.course_member.complete_percent = res.data.data.course_finish_percent
                            }
                            })
                        })
                        } else if(res.data.code == 111) {
                        this.retryTime = time
                        this.isPausePush = false//人脸识别、人机验证时暂停不推送
                        clearInterval(this.timeInterval)
                        this.current_play_time = res.data.data.current_play_time
                        if(res.data.data.listen_user_study_face == 1) {
                            // this.visibleGo = true
                            localStorage.setItem('faceTime',time)
                            let sessionObj = JSON.parse(sessionStorage.session)
                            let session = {
                            realname: sessionObj.realname,
                            idcard: sessionObj.idcard,
                            certification: sessionObj.certification,
                            token: sessionObj.token,
                            type: 2, // 监听
                            class_id:this.query_classId
                            }
                            let sessionStr = JSON.stringify(session)
                            sessionStorage.setItem('session', sessionStr)
                            this.$store.commit('FACEPLAYER',true)
                            this.$store.commit("SETTOKEN", sessionObj.token)
                            this.$store.commit("PLAYTIME", this.player.getCurrentTime())
                            this.$store.commit('FACEVISIBLE', true)
                             console.log('this.$store.state.faceVisible',this.$store.state.faceVisible)
                        } else if(this.$store.state.listen_user_study == 1) {
                            this.visibleGo = true

                        }
                        
                        
                        } 
                        else if(res.data.code == 113) {
                        let that = this;//'今日已到达学习时长限制！休息一下，是为了更好的开始！'
                        this.messageFlag = true;
                        this.$messageBox.confirm(res.data.message, '温馨提示', {
                        confirmButtonText: '确定',
                            showCancelButton:false,
                            showClose:false,
                            closeOnClickModal:false,
                            closeOnPressEscape:false
                        }).then(async () => {
                            that.$router.go(-1)
                            })
                        this.player.pause()
                        }else if(res.data.code == 114||res.data.code == 115) {
                        let that = this;//'今日已到达学习时长限制！休息一下，是为了更好的开始！'
                        this.messageFlag = true;
                        this.$messageBox.confirm(res.data.message, '温馨提示', {
                        confirmButtonText: '确定',
                            showCancelButton:false,
                            showClose:false,
                            closeOnClickModal:false,
                            closeOnPressEscape:false
                        }).then(async () => {
                            that.$router.go(-1)
                            })
                        this.player.pause()
                        }else if(res.data.code == 116) {
                        this.player.pause();
                        if(this.isShowMessage){

                            this.$messageBox.confirm('请完成前置课程学习和考试，才能学习该课程！','提示',{
                                      confirmButtonText: "确认",
                                      showCancelButton:false,
                                      showClose:false
                                }).then(_ => {
                                      that.$router.push({
                                        path: '/class/catalog',
                                        query: {
                                            id:that.$route.query.class_id?that.$route.query.class_id:'',
                                        }
                                })
                            })
                         }
                         this.isShowMessage=false;
                        }
                        else {
                        this.player.fullscreenService.cancelFullScreen()
                        this.signalFlag = 0
                        this.sigColor = "#c00"
                        this.abnormal = true
                        this.player.pause()
                        clearInterval(this.timeInterval)
                        this.endFlag = false
                        }
                    //})
    },
    dateFormat(){
        let dt = new Date();
        let year = dt.getFullYear();
        let month = (dt.getMonth() + 1).toString().padStart(2,'0');
        let date = dt.getDate().toString().padStart(2,'0');
        let hour = dt.getHours().toString().padStart(2,'0');
        let minute = dt.getMinutes().toString().padStart(2,'0');
        let second = dt.getSeconds().toString().padStart(2,'0');
        return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
    },
   
    },
    destroyed() {
      
    //  window.removeEventListener('unload', e => this.beforeunloadHandler(e))
      // this.$store.commit("FACEVISIBLE", false);
      // this.newCoursePush(this.playingTime%30,1,this.pauseStartTime,parseInt(this.pauseEndTime));
      // this.playingTime = 0;
      // this.startPlayTime=0
      // this.pauseTime=0
    },
    beforeDestroy() {
      localStorage.setItem('isback',1)
      // setTimeout(() => {
      //     this.$store.commit("FACEVISIBLE", false);
      // },500);
      clearInterval(this.timeInterval);
      this.timeInterval=null;
      console.log('离开页面了11111')
    },
    
   
}
</script>

<style scoped>

.player-mask {
  position: absolute;
  left: 0;
  top: 66px;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  z-index: 99999999999999999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-mask {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.title-mask img{
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.title-mask h3 {
  color: #fff;
  font-size: 22px;
  margin-bottom: 0;
}
.player-mask p {
  color: #fff;
}
.air-msg {
  width: 340px;
  background: #fff;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  bottom: 140px;
  padding: 5px 10px;
  transition: all .5s;
}
.air-msg img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.air-msg p {
  font-size: 24rpx;
  color: #888;
  margin-bottom: 0;
}
.player-item-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.player-custom-controller {
  position: absolute;
  left: 75px;
  bottom: 80px;
  z-index: 9999999999999999;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.player-icon-controller {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 5px;
}
.player {
    width: 1200px;
    margin: 0 auto;
    padding: 0!important;
}
.player .ant-breadcrumb{
    text-align: left;
    margin: 10px 0;
    width: 450px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.player .errTips {
    width: 100%;
    height: 100%;
    background: #333;
    color: #fff;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.player .errTips span {
  margin-bottom: 10px;
}
.player .errTips button {
  border: none;
}
.player .playerWrap {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.online-icon {
  background: rgba(0, 0, 0, .5);
  filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7F000000,endcolorstr=#7F000000);
  color: #fff;
  font-size: 12px;
  position: absolute;
  left: 10px;
  top: 76px;
  padding: 5px 8px;
  border-radius: 4px;
  z-index: 99999999999999;
}
.player-inner {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.prism-player {
  flex: 1;
}
.player-normal-menu {
  background: #212121;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  box-sizing: border-box;
  position: relative;
  transition: all .5s;
}
.progress-wrap {
  padding: 30px 20px;
}
.progress-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  font-size: 16px;
  margin-bottom: 20px;
}
.player-menu-title-wrap {
  height: 20px;
  padding-left: 20px;
  text-align: left;
  margin-bottom: 15px;
}
.player-menu-title {
  width: auto;
  color: #ccc;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  position: relative;
  font-size: 14px;
}
.player-normal-item {
  padding-bottom: 10px;
}
.player-normal-item-scroll {
  flex: 1;
  display: flex;
  height: 0;
}
.player-normal-item-wrap {
  padding: 0 10px;
  flex: 1;
  overflow-y: auto;
}
.player-normal-item .chapter {
  color: #999;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  text-align: left;
  margin-bottom: 15px;
}
.item-wrap-flex {
  padding: 0 10px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.item-wrap-flex .course-time {
  text-align: left;
  padding-left: 10px;
  color: #666;
  font-size: 12px;
  height: 12px;
  margin-bottom: 0;
  line-height: 12px;
}

.item-title-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.item-title-wrap img {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}
.item-title-wrap p {
  color: #999;
  font-size: 14px;
  margin: 0;
  line-height: 22px;
  height: 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 260px;
}
.player-menu-title::before {
  content: '';
  width: 4px;
  border-radius: 2px;
  left: -10px;
  top: 4px;
  height: 12px;
  background: #3091FD;
  display: block;
  position: absolute;
}
.player-inner-title {
  background: #151515;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 9999999999999999;
}
.user_info{
  font-size: 18px;
  color:#ffffff;
  padding-right: 20px;
}
.player-back {
  cursor: pointer;
  width: 100px;
  background: #212121;
  height: 66px;
  line-height: 66px;
}
.player-back img {
  width: 32px;
  height: 32px;
}
.player-inner-title p {
  font-size: 18px;
  color: #ffffff;
  margin: 0 0 0 20px;
}
.player-bot-do {
  background: #151515;
  height: 80px;
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  position: relative;
}
.player-do-btn button {
  background: #151515!important;
  border: 1px solid #fff!important;
  margin-left: 20px;
  height: 36px;
  width: 98px;
}
.player-do-btn button:hover {
  background: #0071F5!important;
  border: none!important;
}
.player .playerModules {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9;
    position: relative;
    transition: all .5s;
}
.player .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    border-bottom: 1px solid #eee;
    background: #fff;
}
.player .tab li {
    flex: 1;
    height: 40px;
    line-height: 40px;
    cursor: pointer;
    transition: all .2s;
}
.player .player-content {
    flex: 1;
    background: #e9ebf5;
    padding-bottom: 10px;
}
.checkWrap {
    margin-bottom: 10px;
}
.player .player-list{
    /* height: 100%; */
    padding:8px;
    overflow: auto;
}
.player .player-item {
    background: #fff;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    padding: 0 10px;
    cursor: pointer;
    margin-bottom: 10px;
}
.player .playback {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.player .playback .playback-icon {
    display: block;
    width: 46px;
    height: 24px;
    line-height: 24px;
    border-radius: 4px;
    border: 1px solid #aaa;
    margin-right: 10px;
    font-size: 12px;
}
.player .playback .title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    line-height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.classes-test::-webkit-scrollbar, .player-normal-item-wrap::-webkit-scrollbar, .qes-talk-room::-webkit-scrollbar, .txtWrap::-webkit-scrollbar, .player-list::-webkit-scrollbar {
    width:3px;
}
.classes-test::-webkit-scrollbar-thumb, .player-normal-menu::-webkit-scrollbar-thumb, .qes-talk-room::-webkit-scrollbar-thumb, .txtWrap::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
    background-color:#aaa;
}
.player .talkingItem {
    display: flex;
    justify-content: flex-start;
}
.player .talkingInfo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.player .talkingRoom {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.player .talkingRoom .pho {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    overflow: hidden;
}
.player .talkingInfo .name {
    font-weight: bold;
    line-height: 14px;
    padding: 18px 10px;
    display: block;
}
.player .talkingInfo .txt {
    background: #fff;
    border-radius: 6px;
    text-align: left;
    padding: 10px 15px;
    margin-bottom: 0;
}
.player .sendWrap {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    position: absolute;
    left: 0;
    bottom: 0;
}
.player .txtWrap {
    flex: 1;
    overflow: auto;
    padding: 8px;
    max-height: 80vh;
}
.player .txtWrap>div {
  padding-bottom: 40px;
}
.player .sendBtn {
    margin-left: 8px;
    height: 40px;
    color: #fff;
    width: 80px;
}
.player .sendTxt {
    height: 40px;
    flex: 1;
}
.logo {
    width: 140px;
    height: 50px;
    margin: 0 auto 30px;
}
.notice {
    font-size: 16px;
    line-height: 28px;
    text-align: left;
    text-indent: 2em;
    color: #333;
}
.answer-item {
  background: #Fff;
  padding:15px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}
.player .top {
    height: 68px;
    background: rgb(251, 191, 48);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    font-size: 18px;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.player .top p {
    color: rgb(167, 118, 0);
    margin-bottom: 0;
    margin-right: 30px;
}
.player .top a {
    color: #fff;
}
.player .noMsgBtn {
    width: 100px;
    height: 32px;
    background: #fff;
    color: rgb(167, 118, 0)!important;
    border-radius: 16px;
    font-size: 16px;
    cursor: pointer;
    border: 0;
}
.signal {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 9999;
    min-width: 350px;
}
.signalColor {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    margin-right: 10px;
}
.btn-wrap {
  text-align: center;
}
.qes-talk-wrap {
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.qes-talk-room {
  width: 100%;
  flex: 1;
  overflow-y: auto;
}
.qes-do-wrap {
  border-radius: 6px;
  width: 440px;
  height: 110px;
  border: 1px solid #E1E8EB;
}
.qes-talk-avatar {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  margin-bottom: 5px;
}
.qes-talk-info {
  margin-top: 17px;
  background: #FAFAFA;
  padding: 10px;
  border-radius: 6px;
  font-size: 12px;
  flex: 1;
}
.qes-talk-info p {
  color: #666;
}
.qes-talk-info span {
  color: #999;
}
.qes-people-wrap {
  min-width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.qes-talk-name {
  font-size: 12px;
}
.qes-talk-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}
.qes-do-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding:10px;
}
.qes-do-wrap .qes-txt {
  flex: 1;
  height: 100%;
  outline: none;
  border: none;
  resize: none;
}
.qes-do-wrap button {
  width: 88px;
  height: 36px;
}
.test-title {
  background: #FAFAFA;
  padding: 20px 20px 0;
}
.qesIcon {
  width: 22px;
  height: 22px;
  margin-left: 10px;
}
.test-title>span {
  color: #1D75FA;
}
.test-title>span:first-child {
  font-size: 22px;
}
.classes-test {
  height: 480px;
  overflow-y: auto;
  background: #FAFAFA;
  padding:20px;
}
.classes-test>h3 {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
}
.refresh {
  font-size: 16px;
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}
.exam-btn-wrap {
  text-align: center;
  padding: 25px 0;
}
.sidebar-btn {
  width: 18px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  background: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: -30px;
}
.sidebar-btn img {
  width: 16px;
  height: 16px;
}
@media screen and (max-width: 1024px) {
  .player-inner-title {
    height: 50px;
  }
  .player-back {
    width: 50px;
  }
  .player-inner {
    height: 370px;
  }
  .signal {
    font-size: 12px;
  }
  .playerWrap {
    flex-direction: column;
    background: #333;
  }
  .player-do-btn button {
    width: 78px;
    height: 28px;
    font-size: 12px;
    margin-left: 10px;
  }
  .player-inner {
    margin-bottom: 20px;
  }
  .player-normal-menu {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
  .qes-talk-wrap {
    height: 400px;
  }
  .qes-do-wrap {
    width: 100%;
  }
  .bot-div {
    display: none;
  }
  .player .txtWrap {
    max-height: 30vh;
  }
  .player .playerModules {
    width: 100%;
  }
  .player-bot-do {
    min-height: 60px!important;
    height: 60px!important;
  }
}
.mmm {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, .5);
  z-index: 999999999999;
}
.pdf {
  width: 100%;
  overflow: auto;
}
.tools {
  margin: 0 auto;
}
.tools button {
  height: 30px;
  background: rgb(0, 113, 245);
  color: #fff;
  border: 0;
}
.page {
  padding-top: 5px;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}
.pdf::-webkit-scrollbar {
  width: 3px;
}
.pdf::-webkit-scrollbar-thumb {
  background: #aaa;
}
.sub-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.icon {
  font-size: 12px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: content-box;
  color: #888;
  border: 1px solid #888;
  margin-bottom: 0;
}
.cover-mp3 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.cover-mp3 img {
  object-fit: cover;
}
.people-module {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.people-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}
.item-bg {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}
.item-bg p {
  margin-bottom: 0;
}
.people-title h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.people-title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.try-content {
  padding: 20px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.try-btn-wrap {
  padding-top: 10px;
}
.try-btn-wrap button {
  margin: 0 10px;
}
.exam-content {
  padding: 20px 20px 0 20px;
}
.exam-do-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

.code{
  position: relative;
  top: 3px;
}
.code_img{
  display: flex;
  align-items: center;
}
.code_img_text{
  margin-left: 20px;
  color: blue;
  text-decoration:underline;
  cursor: pointer;
}
.code_input{
  width: 100px;
  margin-right: 10px;
}
.prompt-wrap_content{
  margin:0 auto;
  width:270px;
}
.prompt-wrap_redtext{
  color:red
}
</style>
