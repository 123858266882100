<template>
    <div class="notice" :style="{'width':width+'px','height':height+'px'}">
          <div class="titleWrap">
            <p class="title">{{title}}</p>
            <span v-if="type == 'index'" class="more" @click="more()">更多</span>
          </div>
          <ul class="notice-content">
            <li v-show="news.length == 0">暂无数据</li>
            <li @click="go(item, i)" @mouseover="addColor(item, i)" @mouseout="removeColor(item, i)" :style="index == i?{color: $store.state.themeColor}:{}" v-for="(item, i) in news" :key=i>
              <div v-if="type == 'index'" class="notice-item-title">
                <span class="icon">NEW</span>
                <p class="articleT">{{item.title}}</p>
              </div>
              <p  v-if="type != 'index'" class="articleT">{{item.title}}</p>
              <span>{{item.created_at.split(" ")[0]}}</span>
            </li>

          </ul>
        </div>
</template>

<script>
export default {
    data() {
        return {
            index: Number
        }
    },
    props: {
        title: String,
        width: String,
        height: String,
        news: Array,
        type: String

    },
    methods: {
        go(item, i) {
          this.$router.push({
            path: '/article_detail',
            query: {
              id: item.id,
              type: this.type
            }
          })
        },
        more() {
          if(this.type == 'index') {
            this.$router.push({
                path: '/news'
            })
          }
        },
        addColor(item, i) {
            this.index = i
        },
        removeColor(item, i) {
          this.index = Number
        }
    }
}
</script>

<style scoped>
.notice {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
.notice .more {
  cursor: pointer;
}
.notice .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    margin-bottom: 10px;
  }
.notice .title {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }
  .notice .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
  }
.notice-content {
    flex: 1;
    height: 256px;
    border: 1px solid #E9E9E9;
    border-radius: 6px;
    padding: 30px 20px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
  }
  .notice-content::-webkit-scrollbar, .player-list::-webkit-scrollbar {
    width:3px;
  }
  .notice-content::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
      background-color:#aaa;
  }
  .notice .articleT {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 280px;
  }
  .notice-content li {
    cursor: pointer;
    margin-bottom: 25px;
  }
  .notice-content li:last-child {
    margin-bottom: 0;
  }
  .notice-content span {
    display: block;
    text-align: left;
    line-height: 12px;
    color: #666;
  }
  .notice-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  .notice-item-title .icon {
    margin-right: 10px;
    background: #FF8684;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    font-size: 14px;
    height: 20px;
    box-sizing: border-box;
  }
  @media screen and (max-width: 1024px) {
    .notice {
      width: 100%!important;
    }
  }
</style>
