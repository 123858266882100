<template>
    <div class="modal" v-if="$store.state.open_share == 1">
            <a-button @mouseover="show" @mouseout="hide" class="btn-wrap">
                <img :src="src" alt="">
                <span>分享</span>
            </a-button>
        <div class="code-img" v-show="visible">
            <img :src="imgData" alt="">
            <p style="margin-bottom: 0;text-align: center;padding-top: 10px">微信扫一扫</p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
            src: require('../../assets/images/share_icons.png')
        }
    },
    props: {
        imgData: {
            type:String,
            default:''
        }
    },
    methods: {
        show() {
            this.visible = true
            setTimeout(() => {
                this.src = require('../../assets/images/share_icons_ac.png')
            }, 200)
            
        },
        hide() {
            this.visible = false
            setTimeout(() => {
                this.src = require('../../assets/images/share_icons.png')
            }, 200)
            
        },
    }
}
</script>

<style scoped>
.modal {
    width: 190px;
    z-index: 99999999;
    display: flex;
    justify-content: flex-end;
    position: relative;
}
.code-img {
    padding: 10px;
    border-radius: 6px;
    background: #fff;
    width: 190px;
    border: 1px solid #ddd;
    position: absolute;
    right: 0;
    top: 42px;
}
.btn-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-wrap img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
</style>