import { render, staticRenderFns } from "./rssb.vue?vue&type=template&id=11617e16&scoped=true"
import script from "./rssb.vue?vue&type=script&lang=js"
export * from "./rssb.vue?vue&type=script&lang=js"
import style0 from "./rssb.vue?vue&type=style&index=0&id=11617e16&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11617e16",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/www/saas-adyxy-qypx-pc/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('11617e16')) {
      api.createRecord('11617e16', component.options)
    } else {
      api.reload('11617e16', component.options)
    }
    module.hot.accept("./rssb.vue?vue&type=template&id=11617e16&scoped=true", function () {
      api.rerender('11617e16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/home_template/rssb.vue"
export default component.exports