var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "link-detail" }, [
    _c(
      "ul",
      { staticClass: "detail-wrap" },
      [
        _vm._l(_vm.data, function (item, i) {
          return _c(
            "li",
            {
              key: i,
              staticClass: "detail-item",
              style: { flexDirection: item.is_teacher ? "row-reverse" : "" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "user",
                  style: item.is_teacher
                    ? { marginLeft: "10px" }
                    : { marginRight: "10px" },
                },
                [
                  _c("img", { attrs: { src: item.user.avatar, alt: "" } }),
                  _c("span", [
                    _vm._v(
                      _vm._s(item.is_teacher ? "老师" : item.user.realname)
                    ),
                  ]),
                ]
              ),
              _c("div", { staticClass: "content" }, [
                _c("p", [_vm._v(_vm._s(item.content))]),
                _c("span", [_vm._v(_vm._s(item.created_time))]),
              ]),
            ]
          )
        }),
        _vm.ext && _vm.ext.total_pages
          ? _c("li", { staticClass: "more", on: { click: _vm.more } }, [
              _vm._v("点击加载更多..."),
            ])
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }