var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg" }, [
        _c(
          "div",
          { staticClass: "inner" },
          [
            _c("h3", { staticClass: "bg-title" }, [_vm._v("爱迪云企校联盟")]),
            _c("p", [
              _vm._v(
                "爱迪云通过提供平台+课程+服务的一站式服务、持续稳定的技术升级、24小时相应的专业运营支持，已吸众多企业和学校入驻，共建爱迪云生态。"
              ),
            ]),
            _c(
              "a-button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.open()
                  },
                },
              },
              [_vm._v("业务咨询")]
            ),
          ],
          1
        ),
      ]),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _c(
        "a-modal",
        {
          attrs: { width: "400px", footer: null, title: "联系我们" },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../../assets/images/adCode.png"), alt: "" },
          }),
          _c(
            "p",
            { staticStyle: { "text-align": "center", "font-weight": "bold" } },
            [_vm._v("相关业务咨询或合作，请添加爱迪科森官方微信公众号")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thr1x" }, [
      _c("h3", { staticClass: "title1x" }, [_vm._v("为什么选择爱迪云学院")]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/alliance1.png"),
              alt: "",
            },
          }),
          _c("h3", [_vm._v("成熟的解决方案")]),
          _c("p", [
            _vm._v(
              "成熟的解决方案：通过全面的产品、安全可靠的服务、持续的技术升级，为客户在线培训项目保驾护航"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/alliance2.png"),
              alt: "",
            },
          }),
          _c("h3", [_vm._v("全面的产品服务")]),
          _c("p", [
            _vm._v(
              "全面的产品与服务：产品功能覆盖在线职业教育全程，一站式服务，以用户为中心，以客户成功为导向"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/alliance3.png"),
              alt: "",
            },
          }),
          _c("h3", [_vm._v("持续的技术升级")]),
          _c("p", [
            _vm._v(
              "持续的技术升级：平台功能定期更新，无需额外成本即可享受产品持续优化迭代"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/alliance4.png"),
              alt: "",
            },
          }),
          _c("h3", [_vm._v("更稳定更安全")]),
          _c("p", [
            _vm._v("更稳定更安全：专业技术运维团队，24小时监控平台稳定性"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "service" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("服务规模")]),
      _c("ul", [
        _c("li", [
          _c("div", [
            _c("span", { staticClass: "nStyle" }, [_vm._v("302")]),
            _c("span", { staticClass: "sStyle" }, [_vm._v("家")]),
          ]),
          _c(
            "span",
            { staticClass: "sStyle", staticStyle: { "font-size": "14px" } },
            [_vm._v("已入驻高校")]
          ),
        ]),
        _c("li", [
          _c("div", [
            _c("span", { staticClass: "nStyle" }, [_vm._v("67000")]),
            _c("span", { staticClass: "sStyle" }, [_vm._v("名")]),
          ]),
          _c(
            "span",
            { staticClass: "sStyle", staticStyle: { "font-size": "14px" } },
            [_vm._v("已注册学员")]
          ),
        ]),
        _c("li", [
          _c("div", [
            _c("span", { staticClass: "nStyle" }, [_vm._v("132")]),
            _c("span", { staticClass: "sStyle" }, [_vm._v("家")]),
          ]),
          _c(
            "span",
            { staticClass: "sStyle", staticStyle: { "font-size": "14px" } },
            [_vm._v("合作企业")]
          ),
        ]),
        _c("li", [
          _c("div", [
            _c("span", { staticClass: "nStyle" }, [_vm._v("400")]),
            _c("span", { staticClass: "sStyle" }, [_vm._v("家")]),
          ]),
          _c(
            "span",
            { staticClass: "sStyle", staticStyle: { "font-size": "14px" } },
            [_vm._v("职业证书")]
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fou1x" }, [
      _c("h3", { staticClass: "title1x" }, [_vm._v("他们都在使用爱迪云")]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school1.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school2.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school3.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school4.png"), alt: "" },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school5.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school6.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school7.png"), alt: "" },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school8.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school9.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school10.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school11.png"),
              alt: "",
            },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school12.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school13.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school14.png"),
              alt: "",
            },
          }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }