<template>
    <div class="classes">
        <div class="classes-bg" :class="{'newbg':$store.state.tenant_idBind==3462}">
            <div class="inner">
                <div class="top">
                    <div class="title-wrap">
                        <img src="../../assets/images/live-icon.png" alt="">
                        <h3>直播课</h3>
                    </div>
                    <!-- <img style="width: 350px;" src="../../assets/images/bg_txt.png" alt=""> -->
                    <div class="search">
                        <a-input v-model="keywords" @keyup.enter="search" class="search-msg" placeholder="搜索感兴趣的内容"/>
                        <a-button @click="search" class="search-btn"></a-button>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="bot">
            <div class="inner">
                <ul class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
                    <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :style="index == i?{borderColor: $store.state.themeColor,fontWeight: 'bold', color: $store.state.themeColor}:{}">{{item.name}}</li>
                </ul>
            </div>
            
            <div v-if="showMore" @click="showMoreMenu" class="menu-more">
                <img v-if="moreMenuFlag" src="../../assets/images/more_up.png" alt="">
                <img v-if="!moreMenuFlag" src="../../assets/images/more_down.png" alt="">
            </div>
        </div> -->
    
        <div class="classes-content">
            <ul class="second-menus" v-if="second.length > 0 && second.length > 0">
                <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
            </ul>
            <ul class="third-menus" v-if="secondId != 0">
                <li @click="thirdScreening(item, i)" v-for="(item, i) in third" :key="i" :class="third_cate_id == item.id? 'ac': ''">{{item.name}}</li>
            </ul>
            <div class="loadding" v-show="!show">
                <a-spin />
            </div>

<transition name="fades">
            <div class="live-module-near" v-if="show">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>近期直播</span>
                    </div>
                </div>
                
                <!-- <div v-if="show"> -->
                    <a-timeline>
                        <a-timeline-item v-for="(item, i) in ingList" :key="i" @click="go(item, i)">
                            <div class="live-near-item">
                                <div class="time-title">
                                    <span>{{item.start_time.split(' ')[0].split('-')[2] == day? '今天': item.start_time.split(' ')[0]}}</span>
                                    <span>{{item.start_time.split(' ')[1].split(':')[0]+':'+item.start_time.split(' ')[1].split(':')[1]+'-'+item.end_time.split(' ')[1].split(':')[0]+':'+item.end_time.split(' ')[1].split(':')[1]}}</span>
                                </div>
                                <img :src="item.author[0].avatar" alt="">
                                <div class="text-wrap">
                                    <p class="title">{{item.title}}</p>
                                    <p class="sub-title">{{item.author[0].name}}</p>
                                </div>
                                <div class="status" v-if="liveStatus(item.start_time, item.end_time)" :style="{background: $store.state.themeColor,color: '#fff'}">
                                    <span>正在直播</span>
                                    <img src="../../assets/images/going.png" alt="">
                                </div>
                                <div class="status" v-if="!liveStatus(item.start_time, item.end_time)">未开始</div>
                            </div>
                        </a-timeline-item>
                    </a-timeline>
                    <div class="empty" style="height: 400px" v-if="ingList.length == 0">
                        <img src="../../assets/images/empty.png" alt="">
                        <span style="color: #666;font-size: 14px">暂无直播</span>
                    </div>
                <!-- </div> -->
                
                
            </div>
            </transition>
            
            <transition name="fades">
                <div class="live-module" v-show="show && endList.length > 0">
                    <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <span>直播回放</span>
                        </div>
                    </div>
                    
                    <ul class="live-list" v-if="endList.length > 0">
                        <li class="live-item" @click="go(item, i)" v-for="(item, i) in endList" :key="i">
                            <div class="live-top">
                                <img :src="item.author[0].avatar" alt="">
                                <p class="title">{{item.title}}</p>
                            </div>
                            <div class="live-bot">
                                <span class="name">{{item.author[0].name}}</span>
                                <div class="live-sign" style="background: #EAECEF; color: #666">
                                    <span>已结束</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                    
                    
                </div>
                </transition>
            
            <a-pagination v-if="endList.length > 0" @change="changePage" v-model="page" :total="totalPage"/>
        </div>
        
        
    </div>
</template>
<script>
import {
  first,
  second
} from '../../http/api'
export default {
    data() {
        return {
            value: 5,
            page: 1,
            page_size: 10,
            totalPage: 0,
            keywords: '',
            first: [],
            second: [],
            third: [],
            index: 0,
            zero_cate_id: 0,
            firstId: 0,
            secondId: 0,
            third_cate_id: 0,
            ingList: [],
            endList: [],
            show: false,
            day: '',
            showMore: false,
            moreMenuFlag: false
        }
    },
    mounted() {
        this.day = new Date().getDate()
        first({
            zero_cate_id: this.zero_cate_id
        }).then(res => {
        if(res.data.code == 200) {
          this.first = res.data.data
          this.$nextTick(() => {
            let h = document.querySelector('.first-menus').scrollHeight
            if(h > 60) {
              this.showMore = true
            }
          })
        }
      })
      this.update(this.page, 0)
      this.update(this.page, 1)
    },
    methods: {
        liveStatus(start, end) {
            let now = new Date().getTime()
            let s = new Date(start).getTime()
            let e = new Date(end).getTime()
            if(now > s && now < e) {
                return true
            } else {
                return false
            }
        },
        showMoreMenu() {
            if(!this.moreMenuFlag) {
                this.moreMenuFlag = true
            } else {
                this.moreMenuFlag = false
            }
        },
        firstScreening(item, i) {
            this.index = i
            this.firstId = item.id
            this.page = 1
            this.secondId = 0
            this.keywords = ''
            if(item.id != 0) {
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                this.second = res.data.data
                }
            })
            } else {
            this.second = []
            }
            this.secondScreening({id: this.secondId})
        },
        secondScreening(item, i) {
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId,
                second_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                    this.third = res.data.data
                }
            })
            this.secondId = item.id
            this.third_cate_id = 0
            this.page = 1
            this.keywords = ''
            this.update(this.page, 0)
            this.update(this.page, 1)
        },
        thirdScreening(item, i) {
            this.third_cate_id = item.id
            this.page = 1
            this.keywords = ''
            this.update(this.page, 0)
            this.update(this.page, 1)
        },
        update(page, done) {
            this.show = false
            second({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId,
            second_cate_id: this.secondId,
            third_cate_id: this.third_cate_id,
            keywords: this.keywords,
            page: page,
            page_size: this.page_size,
            type: 2,  // 直播
            is_done: done // 进行中
            }).then(res => {
            this.show = true
            if(res.data.code == 200) {
                if(done == 0) {
                    this.ingList = res.data.data.courses
                } else if(done == 1) {
                    this.endList = res.data.data.courses
                    this.totalPage = res.data.data.ext.total
                }
                

                
            }
            }).catch(err => {
            this.show = true
            this.$message.error(err.response.data.message)
            })
        },
        changePage(page, pageSize) {
            this.update(page, 1)
        },
        search() {
            this.page = 1
            this.update(this.page, 0)
            this.update(this.page, 1)
        },
        go(item, i) {
            this.$router.push({
                path: '/course/studydetail',
                query: {
                id: item.id,
                course_type: item.course_type
                }
            })
        },
    }
}
</script>
<style scoped>
.classes-bg {
    background: url('../../assets/images/class-bg.png') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
}
.title-wrap img {
    width: 54px;
    height: 54px;
    margin-right: 15px;
}
.title-wrap h3 {
    font-size: 30px;
    margin-bottom: 0;
    background: linear-gradient(to right, #DCFBFE, #6EA2DF);
    -webkit-background-clip: text;
    color: transparent;
}
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
}
.search-msg {
    width: 400px;
    height: 50px;
    border-radius: 0;
}
.search-btn {
    width: 50px;
    height: 50px;
    background: url('../../assets/images/search.png') no-repeat;
    border: none;
    border-radius: 0;
}
.classes-content {
    padding: 30px 0 40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}
.bot {
    box-shadow: 0 3px 5px #ccc;
}
.bot .first-menus {
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    padding-top: 18px;
}
.bot .first-menus li {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-right: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
}
.second-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.second-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.third-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    margin-bottom: 20px;
}
.third-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.loadding {
    flex: 1;
}
.ac-bg {
    color: #0880F1!important;
    background: rgba(194,224,252, .5)!important;
    border-radius: 4px;
    font-weight: bold;
}
.ac {
    color: #0880F1!important;
    font-weight: bold;
}
.no {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.no img {
    width: 100px;
    height: 100px;
}
.no p {
    color: #bbb;
}
.live-module {
    margin-bottom: 40px;
}
.live-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.live-item {
    width: 590px;
    height: 100%;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
}
.live-item:nth-child(2n) {
    margin-right: 0;
}
.live-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live-top img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot {
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.live-bot .name {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign {
    width: 140px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty img {
    width: 120px;
    height: 74px;
}
.title-bar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.title-bar img {
    width: 6px;
    height: 20px;
    margin-right: 5px;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wrap span {
    font-size: 20px;
    line-height: 20px;
    color: #333;
    font-weight: bold;
}
.live-module-near {
    margin-bottom: 40px;
}
.live-near-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
}
.live-near-item .time-title {
    width: 110px;
    margin-right: 40px;
}
.live-near-item .time-title span {
    display: block;
    text-align: left;
}
.live-near-item .time-title span:first-child {
    font-size: 18px;
    font-weight: bold;
    color: #2A2A2A;
}
.live-near-item .time-title span:last-child {
    font-size: 16px;
    color: #2A2A2A;
}
.live-near-item img {
    width: 160px;
    height: 160px;
    border-radius: 80px;
    object-fit: cover;
    margin-right: 35px;
}
.live-near-item .text-wrap {
    flex: 1;
    text-align: left;
}
.live-near-item .text-wrap .title {
    font-size: 22px;
    color: #070707;
    font-weight: bold;
    line-height: 30px;
}
.live-near-item .text-wrap .sub-title {
    color: #160F0F;
    font-size: 18px;
    margin-bottom: 0;
}
.live-near-item .status {
    width: 140px;
    height: 34px;
    border-radius: 17px;
    line-height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAECEF;
    color: #666;
    font-size: 16px;
}
.live-near-item .status img {
    width: 16px;
    height: 16px;
    margin-left: 6px;
    margin-right: 0;
    border-radius: 0;
}
.menu-more {
    color: #666;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-more img {
    width: 28px;
    height: 26px;
    padding: 5px;
    cursor: pointer;
}
</style>