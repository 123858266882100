var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mycourse" },
    [
      _c(
        "ul",
        {
          staticClass: "tab",
          style: { borderColor: _vm.$store.state.themeColor },
        },
        _vm._l(_vm.tab, function (item, i) {
          return _c(
            "li",
            {
              key: i,
              staticClass: "item",
              style:
                i == _vm.index
                  ? { background: _vm.$store.state.themeColor, color: "#fff" }
                  : {},
              on: {
                click: function ($event) {
                  return _vm.tabs(item, i)
                },
              },
            },
            [_vm._v(_vm._s(item.name))]
          )
        }),
        0
      ),
      _vm.list.length == 0
        ? _c("div", { staticClass: "empty" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/weipingjia.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("暂无课程内容~")]),
          ])
        : _vm._e(),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "tabContent" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.show,
                      expression: "!show",
                    },
                  ],
                  staticClass: "loadding",
                },
                [_c("a-spin")],
                1
              ),
              _c("transition", { attrs: { name: "fades" } }, [
                _vm.show
                  ? _c(
                      "ul",
                      { staticClass: "itemWrap" },
                      _vm._l(_vm.list, function (item, i) {
                        return _c("li", { key: i, staticClass: "tcItem" }, [
                          _c("div", { staticClass: "img" }, [
                            _c("img", {
                              staticClass: "img_a",
                              attrs: { src: item.picture, alt: "" },
                            }),
                            item.is_show == 2
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "status-icon status-delete-icon",
                                  },
                                  [_vm._v("已删除")]
                                )
                              : _vm._e(),
                            item.is_show == 0
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "status-icon status-shelves-down-icon",
                                  },
                                  [_vm._v("已下架")]
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "info" }, [
                            _c("div", { staticClass: "top" }, [
                              _c(
                                "h3",
                                {
                                  staticClass: "title",
                                  on: {
                                    click: function ($event) {
                                      return _vm.go(item, i)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(item.title))]
                              ),
                              _c("p", { staticClass: "describe" }, [
                                _vm._v(_vm._s(item.infos)),
                              ]),
                              _c("span", [
                                _vm._v("总课时：" + _vm._s(item.period)),
                              ]),
                              _c(
                                "div",
                                { staticClass: "progress" },
                                [
                                  _c("span", [_vm._v("学习进度：")]),
                                  _c("a-progress", {
                                    attrs: {
                                      percent: parseInt(
                                        item.finish_course_percent
                                      ),
                                      strokeColor: _vm.strokeColor,
                                      status: "active",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "bot" },
                            [
                              item.type != "live"
                                ? _c(
                                    "a-button",
                                    {
                                      style: {
                                        width: "120px",
                                        background: _vm.studyBtnState(
                                          item.learn_status
                                        ).bgColor,
                                        color: _vm.studyBtnState(
                                          item.learn_status
                                        ).color,
                                        borderColor: _vm.studyBtnState(
                                          item.learn_status
                                        ).bdColor,
                                      },
                                      attrs: { size: "large" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.go(item, i, 0)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.studyBtnState(item.learn_status)
                                            .msg
                                        )
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              item.type == "live"
                                ? _c(
                                    "a-button",
                                    {
                                      style: {
                                        width: "120px",
                                        background: "rgb(255, 134, 17)",
                                        color: "#fff",
                                        borderColor: "rgb(255, 134, 17)",
                                      },
                                      attrs: { size: "large" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.go(item, i, 0)
                                        },
                                      },
                                    },
                                    [_vm._v("查看课程")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
              _c("a-pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
                attrs: {
                  defaultCurrent: _vm.page,
                  defaultPageSize: _vm.page_size,
                  total: _vm.total,
                },
                on: { change: _vm.changePage },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: { width: "380px", centered: true, footer: null, title: null },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "txt-wrap" }, [
            _c("p", { staticClass: "sync-txt" }, [
              _vm._v(
                "该课程属于 " +
                  _vm._s(_vm.tenant_name) +
                  " - " +
                  _vm._s(_vm.platform_name) +
                  ", 是否切换平台？"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "do-some" },
            [
              _c(
                "a-button",
                {
                  style: {
                    color: _vm.$store.state.themeColor + "!important",
                    border:
                      "1px solid" + _vm.$store.state.themeColor + "!important",
                    marginRight: "20px",
                  },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "a-button",
                {
                  style: {
                    background: _vm.$store.state.themeColor,
                    color: "#fff!important",
                  },
                  on: { click: _vm.ok },
                },
                [_vm._v("立即切换")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }