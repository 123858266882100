<template>
    <div class="orders">
        <h3 class="title" :style="{borderColor: $store.state.themeColor}">订单列表</h3>
        <div class="search-wrap">
          <div class="search">
              <a-input v-model="keywords" type="text" placeholder="订单号搜索" />
              <a-button @click="query" type="primary">订单查询</a-button>
          </div>
          <a-button @click="showMenu" style="{background: '#D7E9FE!important'}" type="primary">{{menuTxt}}</a-button>
        </div>

        <div class="conditions" v-show="menuShow">
            <div class="select-wrap">
                <label for="">支付方式</label>
                <a-select default-value="" style="width: 120px" @change="checkPayment">
                    <a-select-option value="">请选择</a-select-option>
                    <a-select-option value="wechat">微信支付</a-select-option>
                </a-select>
            </div>
            <div class="select-wrap">
                <label for="">订单状态</label>
                <a-select default-value="" :value="oerderStatusSelect" style="width: 120px" @change="checkStatus">
                    <a-select-option value="">请选择</a-select-option>
                    <a-select-option value="finished">已完成</a-select-option>
                    <a-select-option value="closed">已取消</a-select-option>
                    <a-select-option value="created">待支付</a-select-option>
                </a-select>
            </div>
            <div class="select-wrap">
                <label for="">下单时间</label>
                <a-range-picker @change="onChangeDate" :placeholder="['开始日期', '结束日期']"/>
            </div>
        </div>
        <div class="tabs-bar">
            <ul class="tabs">
              <li
                  @click="tab(item, index)"
                  v-for="(item, index) in tabs"
                  :key="index"
                  :style="{color:index == tabIndex?$store.state.themeColor:''}"
              >{{item.tabName}}({{item.tabNumber}})</li>
            </ul>
            <!-- <a-checkbox :defaultChecked="true" @change="hideOrders">隐藏免费订单</a-checkbox> -->
            <el-checkbox :disabled="disabled" v-model="allOrderShow" @change="hideOrders">隐藏免费订单</el-checkbox>

        </div>
        

        <div class="">
          <div class="loadding" v-show="!show">
              <a-spin />
          </div>
          <transition name="fades">
            <div class="empty" v-if="show && list.length == 0">
              <img src="../../assets/images/weipingjia.png" alt="">
              <span>暂无订单信息</span>
            </div>
            <div v-if="show && list.length > 0">
              <ul class="order">
                  <li v-for="(item, index) in list" :key="index">
                      <div class="order-top">
                        <div class="order-number">
                          <span>订单号：{{item.origin_sn}}</span>
                          <span>{{item.created_time}}</span>
                        </div>
                          <div class="order-time" v-if="item.order_status == 'created'">
                            <span>剩余支付时间：</span>
                            <span>{{item.remain_time}}</span>
                          </div>
                      </div>
                      <div class="content-wrap">
                        <div class="tit">
                            <span>订单信息</span>
                            <span>订单价格</span>
                            <span>订单类型</span>
                            <span>交易状态</span>
                            <span>支付方式</span>
                            <span>操作</span>
                        </div>
                        <div class="order-content">
                            <div class="content" v-for="(items, indexs) in item.items" :key="indexs">
                                <div class="info-wrap">
                                    <div class="img-wrap">
                                      <img :src="items.course.picture" alt="">
                                      <span v-if="items.course.is_show == 2" class="status-icon status-delete-icon">已删除</span>
                                      <span v-if="items.course.is_show == 0" class="status-icon status-shelves-down-icon">已下架</span>
                                    </div>
                                    <p class="order-title">{{items.course.title}}</p>
                                </div>
                                <div class="pay-msg">
                                    <span class="red">{{item.pay_amount>0? '￥'+item.pay_amount: '免费'}}</span>
                                </div>
                                <div>
                                  <span>{{items.target_type == 'classroom'? '班级': '课程'}}</span>
                                </div>
                                <div>
                                    <span>{{orderStatus(item.order_status)}}</span>
                                </div>
                                <div>
                                    <span>{{payMentTxt(item.payment)}}</span>
                                </div>
                                <div class="do">
                                    <a-button @click="pay(item.origin_sn, item.payment)" v-if="item.order_status == 'created'" type="primary">立即支付</a-button>
                                    <a-button @click="orderCancel(item.origin_sn, 1)" v-if="item.order_status == 'created'"
                                    type="primary"
                                    style="color:#0071F5;background:#fff">取消订单</a-button>
                                    <a-button
                                    v-if="item.invoice_status == 1 && $store.state.open_wechat_pay==0"
                                    type="default"
                                    style="color: #0071F5!important;borderColor:#0071F5!important"
                                    @click="takeInvoice(item.origin_sn, item.invoice_status)"
                                    >开发票</a-button>
                                    <span @click="go(item.origin_sn)" style="color: #0071F5;cursor: pointer;margin-bottom:10px;">订单详情</span>
                                    <span @click="seeInvoice(item.origin_sn, item.invoice_status)" v-if="item.invoice_status == 3" style="color: #0071F5;cursor: pointer;">查看发票</span>
                                </div>
                            </div>
                        </div>
                      </div>
                  </li>
              </ul>
              <a-pagination v-model="page" :total="total" show-less-items @change="changePage"/>
            </div>
          </transition>
        </div>
        
        <a-modal :maskClosable='false' v-model="visible" width="700px" footer="" title="立即付款" @cancel="cancel">
          <div class="txt-info">
            <div>
                <p class="order-info-title">订单号{{order.sn}}</p>
                <div class="order-info">
                  <p>下单时间：{{order.created_time}}</p>
                  <p></p>
                </div>
                <div class="">
                  <p>应付金额：￥{{order.pay_amount}}</p>
                  <p></p>
                </div>
                <div class="">
                  <p>支付方式：{{payType == 'wechat'? '微信支付': '支付宝支付'}}</p>
                </div>
            </div>
            <div>剩余支付时间：<span style="color: #c00;font-size: 20px;">{{showTimeInfo}}</span></div>
          </div>
          <div class="pay-info">
              <div>
                <div class="code-wrap">
                  <img class="wx_pay_qrcode" :src="wx_pay_qrcode" alt="">
                </div>
              </div>
              <div class="">
                <img v-if="payType == 'wechat'" class="pay-icon" src="../../assets/images/payicon.png" alt="">
                <img v-if="payType == 'adapay'" style="width: 64px;height: 64px;" src="../../assets/images/payiconali.png" alt="">
                <p>{{payType == 'wechat'? '微信': '支付宝'}}</p>
                <p>请使用{{payType == 'wechat'? '微信': '支付宝'}}扫描左侧二维码以完成支付</p>
                <p class="red">￥{{order.pay_amount}}</p>
              </div>
            </div>
        </a-modal>
        <a-modal v-if="$store.state.open_wechat_pay==0" width='500px' v-model="visible2" footer="" title="开发票" :destroyOnClose="destroyOnClose">
        <!-- <a-modal width='500px' v-model="visible2" footer="" title="开发票" :destroyOnClose="destroyOnClose"> -->
          <div class="modal-top" v-if="invoice_status == 1">
            <div class="input-item">
              <div class="">
                <label for="">发票类型</label>
                <a-radio-group>
                  <a-radio>
                    电子普通发票
                  </a-radio>
                </a-radio-group>
              </div>

            </div>
            <div class="input-item">
              <div class="">
                <label for="">发票抬头</label>
                <a-radio-group v-model="value" @change="onChange">
                  <a-radio :value="0">
                    个人
                  </a-radio>
                  <a-radio :value="1">
                    单位
                  </a-radio>
                </a-radio-group>
              </div>

            </div>

            <div class="input-item" v-if="value == 0">
              <div class="">
                <label for="">发票内容</label>
                <a-input v-model="buyer_name" placeholder="请输入个人或您的姓名" />
              </div>

            </div>

            <div class="input-item" v-if="value == 1">
              <div class="">
                <label for="">企业名称</label>
                <a-input v-model="buyer_name" placeholder="请输入企业名称" />
              </div>
              <div class="">
                <label for="">企业税号</label>
                <a-input v-model="tax_num" placeholder="请输入企业税号" />
              </div>

            </div>
            <div class="input-item">
              <div class="">
                <label for="">手机号</label>
                <a-input v-model="mobile" placeholder="请输入手机号" />
              </div>
            </div>
            <div class="input-item">
              <div class="">
                <label for="">收票邮箱</label>
                <a-input v-model="email" placeholder="请输入接收发票的邮箱" />
              </div>

            </div>
          </div>
          <div class="modal-bot" v-if="invoice_status == 1">
            <p class="invoice-title">开具发票规则</p>
            <div class="content">
              <p>1. 根据国家税务规定，不能向个人开具增值税专用发票</p>
              <p>2. 发票金额不含优惠券支付部分</p>
              <p>3. 不同开票主体的发票，需要分别提交开票申请</p>
              <p>4. 电子普通发票是税局认可的有效付款凭证，其法律效力、基本用途及使用规定同纸质发票</p>
              <p>5. 请您按照税法规定使用发票</p>
              <p>6. 有任何疑问，请您及时咨询我们的客服</p>
            </div>
          </div>

          <div class="invoice-btn" v-if="invoice_status == 1">
            <a-button @click="startInvoice" type="primary">立即开票</a-button>
          </div>


          <div class="see-invoice" v-if="invoice_status == 3">
            <div class="">
              <label for="">发票类型</label>
              <p>电子普通发票</p>
            </div>
            <div class="">
              <label for="">发票抬头</label>
              <p>{{invoice.title}}</p>
            </div>
            <div class="">
              <label for="">收票邮箱</label>
              <p>{{invoice.email}}</p>
            </div>
          </div>

          <div class="invoice-img" v-if="invoice_status == 3">
            <div class="invoice-img-title">
              <span style="font-weight: bold;">发票</span>
              <a :href="invoice.img" download target="_blank" style="color:#0071F5;cursor:pointer;">下载发票</a>
            </div>
            <img :src="invoice.img" alt="">
          </div>
          <div class="invoice-btn" v-if="invoice_status == 3">
            <a-button @click="invoiceOk" type="primary">确定</a-button>
          </div>
        </a-modal>
    </div>
</template>

<script>
import {
  myOrders,
  goPay,
  getPrepare,
  cancelOrder,
  issue,
  getInvoice,
  second
} from '../../http/api'
export default {
    data() {
        return {
            tabs: '',
            tabIndex: 0,
            page: 1,
            page_size: 10,
            list: [],
            flag: true,
            keywords: '',
            status: '',
            payment: '',
            created_time: '',
            total: 0,
            menuShow: false,
            menuTxt: '+展开筛选',
            visible: false,
            order: '',
            timer: '',
            wx_pay_qrcode: '',
            visible2: false,
            value: 0,
            mobile: '',
            email: '',
            buyer_name: '',
            tax_num: '',
            invoice: '',
            sn: '',
            show: false,
            invoiceMessageFlag: true,
            oerderStatusSelect: '',
            invoiceType: 0,
            msgFlag: true,
            showTimeInfo: '',
            destroyOnClose: true,
            payType: '',
            is_free: 0,
            orderTimer: '',
            invoice_status: '',
            allOrderShow:true,//免费订单是否显示
            disabled:false//禁止频繁点击
        }
    },
    watch:{
      '$store.state.listenEnterprise'(n, o) {
        this.list = []
        this.update()
      }
    },
    created() {
      
    },
    mounted() {
      this.update()
    },
    destroyed() {
      clearInterval(this.orderTimer)
    },
    methods: {
      payMentTxt(param) {
        if(param == 'wechat' || param == 'mini_wechat') {
          return '微信'
        } else if(param == 'adapay') {
          return '支付宝'
        } else {
          return '免费'
        }
      },
      hideOrders(e) {
        // if(e.target.checked) {
        //   this.is_free = 0
        // } else {
        //   this.is_free = 1
        // }
        this.disabled=true;
        this.list = []
        this.update()
      },
      seeInvoice(sn, invoice_status) {
        this.invoice_status = invoice_status
        if(invoice_status == 2) {
          if(this.msgFlag) {
            this.msgFlag = false
            this.$message.error("正在开票中...请稍后！", () => {
              this.msgFlag = true
            })
          }
        } else {
          getInvoice({
            order_sn: sn
          }).then(res => {
            if(res.data.code == 200) {
              this.invoice = res.data.data
              this.visible2 = true
            }
          })
        }
        
      },
      invoiceOk() {
        this.visible2 = false
      },
      startInvoice() {
        let regex = /^[a-zA-Z\u4e00-\u9fa5]{1,20}$/
        // regex = /^([a-zA-Z ]+|[\u4e00-\u9fa5]+)$/
        // console.info(regex.test(this.buyer_name))
      
        if(this.invoiceType == 0) {
          if(!this.buyer_name) {
            this.$message.error("请输入发票内容");
            // if(this.msgFlag) {
            //   this.msgFlag = false
            //   this.$message.error("请输入发票内容", () => {
            //     console.log('请输入发票内容')
            //     this.msgFlag = true
            //   })
            // }
            return false
          } else if(!regex.test(this.buyer_name)) {
            this.$message.error("发票内容请输入中文或英文");
            // if(this.msgFlag) {
            //   this.msgFlag = false
            //   this.$message.error("发票内容请输入中文或英文", () => {
            //     this.msgFlag = true
            //   })
            // }
            return false
          }
        }
        if(this.invoiceType == 1) {
          if(!this.buyer_name) {
            this.$message.error("请输入企业名称")
            // if(this.msgFlag) {
            //   this.msgFlag = false
            //   this.$message.error("请输入企业名称", () => {
            //     this.msgFlag = true
            //   })
            // }
            return false
          } else if(!regex.test(this.buyer_name)) {
              this.$message.error("企业名称请输入中文或英文");
            // if(this.msgFlag) {
            //   this.msgFlag = false
            //   this.$message.error("企业名称请输入中文或英文", () => {
            //     this.msgFlag = true
            //   })
            // }
            return false
          }
          if(!this.tax_num) {
            this.$message.error("请输入企业税号");
            // if(this.msgFlag) {
            //   this.msgFlag = false
            //   this.$message.error("请输入企业税号", () => {
            //     this.msgFlag = true
            //   })
            // }
            return false
          }
        }
        if(!this.mobile) {
            this.$message.error("请输入您的手机号");
          // if(this.msgFlag) {
          //     this.msgFlag = false
          //     this.$message.error("请输入您的手机号", () => {
          //       this.msgFlag = true
          //     })
          //   }
          return false
        }
        if(!this.email) {
          this.$message.error("请输入您的收票邮箱",)
          // if(this.msgFlag) {
          //     this.msgFlag = false
          //     this.$message.error("请输入您的收票邮箱", () => {
          //       this.msgFlag = true
          //     })
          //   }
          return false
        }

        if(this.invoiceMessageFlag) {
          this.invoiceMessageFlag = false
          issue({
            order_sn: this.sn,
            mobile: this.mobile,
            email: this.email,
            invoice_type: this.value,
            buyer_name: this.buyer_name,
            tax_num: this.tax_num
          }).then(res => {
            if(res.data.code == 200) {
                this.visible2 = false
                this.$message.success(res.data.message)
                this.invoiceMessageFlag = true
                this.show = false
                this.list = []
                this.page = 1
                this.update()
            } else {
              this.$message.error(res.data.message, () => {
                this.invoiceMessageFlag = true
              })
            }
          })
        }

      },
      takeInvoice(sn, invoice_status) {
        this.invoice_status = invoice_status
        this.visible2 = true
        this.sn = sn
      },
      onChange(e) {
        this.invoiceType = e.target.value
        this.buyer_name = ''
        this.tax_num = ''
        this.mobile = ''
        this.email = ''
      },
      orderCancel(sn, msg) {
        cancelOrder({
          order_sn: sn
        }).then(res => {
          if(res.data.code == 200) {
            this.list = []
            this.page = 1
            this.update()
            if(msg) {
              this.$message.success(res.data.message)
            }
          } else {
            this.list = []
            this.page = 1
            this.update()
            if(msg) {
              this.$message.error(res.data.message)
            }
          }
        })
      },
      cancel() {
        clearInterval(this.timer)
      },
      pay(sn, payment) {
        let obj
        if(payment == 'wechat') {
          obj = {
            order_sn: sn,
            go_payment: 'wechat'
          }
        } else {
          obj = {
            order_sn: sn
          }
        }
        goPay(obj).then(res => {
          if(res.data.code == 200) {
            this.showTimeInfo = '--:--'
            this.visible = true
            this.order = res.data.data.order
            this.wx_pay_qrcode = res.data.data.wx_pay_qrcode
            this.payType = res.data.data.order.payment
            this.timer = setInterval(() => {
              let minutes = res.data.data.order.remain_time.split(':')[0] == 30? 29: res.data.data.order.remain_time.split(':')[0]
              let seconds = res.data.data.order.remain_time.split(':')[1] == 0? 59: res.data.data.order.remain_time.split(':')[1]
              // if(seconds > 0) {
                seconds--
                let formatS = seconds < 10? '0'+seconds: seconds
                res.data.data.order.remain_time = minutes+':'+formatS
                this.showTimeInfo = minutes+':'+formatS
                if(seconds == 0) {
                  setTimeout(() => {
                    seconds = 59
                    let formatS = seconds < 10? '0'+seconds: seconds
                    res.data.data.order.remain_time = minutes+':'+formatS
                    this.showTimeInfo = minutes+':'+formatS
                    if(minutes > 0) {
                      minutes--
                      let formatM = minutes < 10? '0'+minutes: minutes
                      res.data.data.order.remain_time = formatM+':'+seconds
                      this.showTimeInfo = formatM+':'+seconds
                    } else if(minutes == 0) {
                      this.showTimeInfo = '00:00'
                      clearInterval(this.timer)
                      this.visible = false
                    }
                  }, 1000)
                }
              // }
              getPrepare({
                order_sn: this.order.sn
              }).then(res => {
                if(res.data.code == 200) {
                  clearInterval(this.timer)
                  setTimeout(() => {
                    this.visible = false
                    this.list = []
                    this.update()
                  }, 1500)
                }
              })
            }, 1000)
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      showMenu() {
        this.menuShow = !this.menuShow
        if(this.menuShow) {
          this.menuTxt = '-收起筛选'
        } else {
          this.menuTxt = '+展开筛选'
        }
      },
      changePage(page,pageSize) {
        this.page = page
        this.list = []
        this.update()
      },
      onChangeDate(date, dateString) {
        this.created_time = `${dateString[0]}~${dateString[1]}`
      },
      checkPayment(val) {
        this.payment = val
      },
      checkStatus(val) {
        this.status = val
        this.oerderStatusSelect = val
      },
      orderStatus(key) {
        if(key == 'closed') {
          return '已取消'
        } else if(key == 'created') {
          return '待支付'
        } else if(key == 'paid') {
          return '已支付'
        } else if(key == 'refunded') {
          return '已退款'
        } else if(key == 'finished') {
          return '已完成'
        } else if(key == 'refunding') {
          return '退款中'
        }
      },
      query() {
        this.list = []
        this.page = 1
        this.show = false
        this.update()
      },
      update() {
        clearInterval(this.orderTimer)
        
        this.flag = false
        myOrders({
          status: this.status,
          page: this.page,
          page_size: this.page_size,
          keywords: this.keywords,
          payment: this.payment,
          created_time: this.created_time,
          is_free: this.allOrderShow?0:1,
          platform_id: this.$store.state.product_id,
          tenant_id: this.$store.state.tenant_id
        }).then(res => {
          this.show = true
          this.flag = true
          if(res.data.code == 200) {
            this.tabs = [
              {
                  tabName: '全部订单',
                  status: '',
                  tabNumber: res.data.ext.all_total
              },
              {
                  tabName: '已完成',
                  status: 'finished',
                  tabNumber: res.data.ext.finished_total
              },
              {
                  tabName: '已取消',
                  status: 'closed',
                  tabNumber: res.data.ext.closed_total
              },
              {
                  tabName: '待支付',
                  status: 'created',
                  tabNumber: res.data.ext.created_total
              },
            ]
            this.total = res.data.ext.total
            res.data.data.map((item, index) => {
              this.list.push(item)
            })
            this.disabled = false;
            this.orderTimer = setInterval(() => {
              console.info(1)
              this.list.map((item, i) => {
                if(item.order_status == 'created') {
                  let minutes = item.remain_time.split(':')[0]
                  let seconds = item.remain_time.split(':')[1]
                  if(seconds > 0) {
                    seconds--
                    let formatS = seconds < 10? '0'+seconds: seconds
                    item.remain_time = minutes+':'+formatS
                    if(seconds == 0) {
                      setTimeout(() => {
                        seconds = 59
                        if(minutes > 0) {
                          minutes--
                          let formatM = minutes < 10? '0'+minutes: minutes
                          item.remain_time = formatM+':'+seconds
                        }
                      }, 1000)
                    }
                  }
                  if(minutes == 0 && seconds == 0 && item.order_status == 'created') {
                    clearInterval(this.orderTimer)
                    this.orderCancel(item.origin_sn, 0)
                  }
                }
              })
            }, 1000)
          }
        })
      },
      tab(item, index) {
        if(this.flag) {
          this.tabIndex = index
          this.status = item.status
          this.list = []
          this.page = 1
          this.show = false
          this.update()
          this.oerderStatusSelect = ''
        }
      },
      go(sn) {
          this.$router.push({
              path: '/user/orderdetail',
              query: {
                sn: sn
              }
          })
      }
    }
}
</script>

<style scoped>
.empty {
  padding: 50px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
.orders {
    padding: 20px;
}
.title {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
    margin-bottom: 30px;
}
.search {
    width: 360px!important;
    width: auto;
    display: flex;
    justify-content: left;
    align-items: center;
    border: 1px solid #E1E8EB;
    height: 36px;
    margin-right: 15px;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}
.search-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.search input {
    flex: 1;
    height: 34px;
    border: none;
}
.search button {
  width: 98px;
  height: 34px;
  font-size: 12px;
  background: #0071F5;
}
.tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #0071F5;
    margin-bottom: 20px;
    flex: 1;
    margin-right: 20px;
}
.tabs li {
    margin-right: 30px;
    line-height: 44px;
    height: 44px;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    color: #333;
}
.order-top {
    padding: 0 20px;
    height: 44px;
    background: #F3F6F7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555;
}
.order-top .order-number span {
  margin-right: 30px;
  font-size: 12px;
}
.order-top .order-time span {
  font-size: 14px;
  color: #FC583D;
}
.order-top .order-time span:last-child {
  font-weight: bold;
}
.content-wrap {
  border: 1px solid #eee;
  margin-bottom: 20px;
}
.order-content {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.tit {
    width: 100%;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #666;
    height: 44px;
    padding: 0 15px;
}
.tit>span {
    flex: 1;
}
.tit>span:first-child {
    min-width: 380px;
    text-align: left;
}
.order-content .content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    padding: 0 15px;
}
.order-content .content>div {
    flex: 1;
    border-right: 1px solid #EBEFF2;
    min-height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.order-content .content>div:first-child {
    min-width: 380px;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.order-content .content>div:last-child {
  border-right: none;
}
.order-content .info-wrap img {
  width: 142px;
  height: 84px;
  object-fit: cover;
}
.order-content .info-wrap {
  display: flex;
}
.order-content .info-wrap .img-wrap {
  overflow: hidden;
  border-radius: 4px;
  margin-right: 10px;
  position: relative;
}
.content .order-title {
    font-size: 12px;
    color: #333;
    text-align: left;
    margin: 0;
}
.content .pay-msg>div {
    margin-bottom: 10px;
}
.red {
    color: #FC583D;
}
.conditions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.conditions>div {
    margin-right: 20px;
}
.conditions>div>label {
    margin-right: 10px;
}
.do {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.do button {
    margin-bottom: 10px;
    width: 88px;
}
.order li {
  border-radius: 6px;
  overflow: hidden;
}
.ant-select {
  width: 160px!important;
}
.select-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.select-wrap label {
  width: 50px;
  font-size: 12px;
}
.ac {
  color: #FC583D;
}
.order-info {
  color: #666;
}
.wx_pay_qrcode {
  width: 168px;
  height: 168px;
}
.order-info-title {
  font-weight: bold;
  color: #333;
  font-size: 14px;
}
.pay-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.pay-info>div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.modal-top {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.modal-top .input-item {
  margin-bottom: 20px;
}
.modal-top .input-item>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.modal-top .input-item>div>label {
  min-width: 50px;
  font-size: 12px;
  margin-right: 20px;
  font-weight: bold;
}
.modal-bot {
  margin-bottom: 30px;
}
.modal-bot .invoice-title{
  font-size: 12px;
  font-weight: bold;
}
.modal-bot .content {
  height: 220px;
  overflow-y: auto;
}
.modal-bot .content::-webkit-scrollbar, .player-list::-webkit-scrollbar {
  width:3px;
}
.modal-bot .content::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
    background-color:#aaa;
}
.invoice-btn {
  text-align: center;
}
.see-invoice {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
}
.see-invoice>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.see-invoice>div>label {
  font-weight: bold;
}
.see-invoice>div>p {
  margin: 0 0 0 20px;
}
.invoice-img-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.invoice-img {
  margin-bottom: 30px;
}
.invoice-img img {
  width: 100%;
  height: 298px;
}
.code-wrap {
  width: 170px;
  height: 170px;
  background: url('../../assets/images/codewrap.png') no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pay-icon {
  width: 123px;
  height: 31px;
  margin-bottom: 10px;
}
.txt-info {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.loadding {
  height: 500px;
}
.tabs-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
