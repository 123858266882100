<template>
    <div>
        <div class="job">
            <div class="inner">
                <div class="title-wrap">
                    <h3 style="font-size: 24px;color:#333;font-weight: bold;line-height: 24px;margin-right: 20px;margin-bottom: 0;">项目经理（J10784）</h3>
                    <span style="font-size: 24px;color: #F34026;">2-4万</span>
                </div>
                <div>
                    <span style="font-size: 14px;color: #666;line-height: 14px;padding: 20px 0;display: block">北京-朝阳区 丨 3-5年 丨 本科 丨 招1人 丨 03-24发布</span>
                </div>
                <ul class="icons">
                    <li>周末双休</li>
                    <li>带薪年假</li>
                    <li>五险一金</li>
                    <li>绩效奖金</li>
                    <li>节日福利</li>
                    <li>专业培训</li>
                </ul>
            </div>
        </div>
        <div class="content">
            <div class="inner">
                <div class="left">
                    <div class="title-group">
                        <span class="icon"></span>
                        <span class="txt">职位信息</span>
                    </div>
                    <div class="text-line">
                        <p>工作职责：</p>
                        <p>1、参与公司项目管理体系的建设、持续改进及推广，不断优化管理流程，提高业务满意度</p>
                        <p>2、监督、执行项目管理相关活动，协调和解决跨部门项目管理相关问题</p>
                        <p>3、参与、指导公司软件项目的日常项目管理等工作（偏教练角色）</p>
                    </div>
                    <div class="text-line">
                        <p>任职资格：</p>
                        <p>1、计算机、电子工程、自动控制等相关专业，全日制本科及以上学历；</p>
                        <p>2、具备软件项目管理的一般理论和知识，有至少2年互联网软件项目管理的相关经验；</p>
                        <p>3、具备至少2年敏捷（Agile）项目的经理；</p>
                        <p>4、具备敏锐的洞察力，良好的表达、沟通能力以及分析、判断能力；</p>
                        <p>5、有强烈的责任心和事业心，沟通能力强，能快速融入团队，能够良好的协调部门剑协作；</p>
                        <p>6、具有PMP/ACP/Scrum、Kanban等相关资质认证的优先，具有敏捷项目管理经验、敏捷教练经验、阻止过程改进经验者优先。</p>
                    </div>
                    <div class="title-group">
                        <span class="icon"></span>
                        <span class="txt">联系方式</span>
                    </div>
                    <div class="text-line">
                        <div class="icon-wrap">
                            <img class="comp-icon" src="../../assets/images/comp1.png" alt="">
                            <p>上班地址：北京市朝阳区三间房南里4号院DREAM2049国际文创产业园东门B05栋</p>
                        </div>
                    </div>
                    <div class="title-group">
                        <span class="icon"></span>
                        <span class="txt">公司介绍</span>
                    </div>
                    <div class="text-line">
                        <p>好大夫在线创立于2006年，是中国领先的互联网医疗平台之一，致力于成为最值得信赖的医疗平台。经过15年诚信运营，好大夫在线已经在医院/医生信息查询、图文问诊、电话问诊、远程视频门诊、门诊精准预约、诊后疾病管理、家庭医生、疾病知识科普等多个领域取得显著成果，收到医生和患者广泛信赖。</p>
                        <p>好大夫在线拥有数量众多的优质医生群体。截至2021年2月，好大夫在线收录了国内9600家正规医院78万余位医生信息，已累计服务超过6900万用户。用户可以通过好大夫在线APP、PC版网站、手机版网站、微信公众号、微信小程序等多个平台，方便的联系到24万+公立医院的医生，一站式解决线上服务、线下就诊等各种医疗问题。</p>
                    </div>
                </div>

                <div class="right">
                    <div class="r-top">
                        <h3 class="title">公司信息</h3>
                        <div class="title-pic">
                            <img src="../../assets/images/hdf.png" alt="">
                            <span style="font-size: 16px;color:#000">好大夫在线</span>
                        </div>
                        <div class="text-line" style="margin-bottom: 0px;padding-left: 0">
                            <div class="icon-wrap">
                                <img class="comp-icon" src="../../assets/images/comp2.png" alt="">
                                <p style="font-size: 14px;color: #666;">外资（欧美）</p>
                            </div>
                        </div>
                        <div class="text-line" style="margin-bottom: 0px;padding-left: 0">
                            <div class="icon-wrap">
                                <img class="comp-icon" src="../../assets/images/comp3.png" alt="">
                                <p style="font-size: 14px;color: #666;">500-1000人</p>
                            </div>
                        </div>
                        <div class="text-line" style="margin-bottom: 0px;padding-left: 0">
                            <div class="icon-wrap">
                                <img class="comp-icon" src="../../assets/images/comp4.png" alt="">
                                <p style="font-size: 14px;color: #666;">互联网/电子商务&nbsp;医疗/护理/卫生</p>
                            </div>
                        </div>
                    </div>
                    <div class="r-bot">
                        <h3 class="title">相似职位</h3>
                        <ul class="line-item">
                            <li>
                                <h3>20089-美术指导（北京）</h3>
                                <div class="sub-wrap">
                                    <span class="sub">20089-美术指导（北京）</span>
                                    <span class="sub" style="color: #F34026;">5-12k</span>
                                </div>
                            </li>
                            <li>
                                <h3>40022-技术总监（深圳）</h3>
                                <div class="sub-wrap">
                                    <span class="sub">40022-技术总监（深圳）</span>
                                    <span class="sub" style="color: #F34026;">15-70K</span>
                                </div>
                            </li>
                            <li>
                                <h3>20212-Java工程师（广东）</h3>
                                <div class="sub-wrap">
                                    <span class="sub">20212-Java工程师（广东）</span>
                                    <span class="sub" style="color: #F34026;">9-18K</span>
                                </div>
                            </li>
                            <li>
                                <h3>20053-项目经理（北京）</h3>
                                <div class="sub-wrap">
                                    <span class="sub">20053-项目经理（北京）</span>
                                    <span class="sub" style="color: #F34026;">9-17K</span>
                                </div>
                            </li>
                            <li>
                                <h3>30051-采购专员（上海）</h3>
                                <div class="sub-wrap">
                                    <span class="sub">30051-采购专员（上海）</span>
                                    <span class="sub" style="color: #F34026;">5-7K</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.job {
    height: 224px;
    background: #F6F8FB;
}
.job .inner {
    padding: 50px 0;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}
.icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.icons li {
    background: #E5E9F0;
    width: 86px;
    height: 36px;
    font-size: 14px;
    color: #666;
    text-align: center;
    line-height: 36px;
    margin-right: 10px;
    border-radius: 6px;
}
.content .inner {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.title-group {
    height: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 28px;
}
.title-group .icon {
    width: 6px;
    height: 18px;
    display: block;
    background: #0066CC;
    margin-right: 10px;
}
.title-group .txt {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
}
.text-line {
    font-size: 14px;
    color: #333;
    text-align: left;
    margin-bottom: 44px;
    padding-left: 10px;
}
.text-line p {
    margin-bottom: 0;
    font-size: 14px;
    color: #333;
    line-height: 30px;
}
.left {
    width: 840px;
}
.right {
    width: 320px;
}
.r-top {
    height: 270px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    margin-bottom: 27px;
    padding: 25px;
}
.r-bot {
    height: 490px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    padding: 25px;
}
.r-top .title, .r-bot .title {
    font-size: 20px;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 30px;
    text-align: left;
}
.comp-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}
.icon-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-pic {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
}
.title-pic img {
    width: 60px;
    height: 60px;
    margin-right: 16px;
}
.line-item li {
    padding: 24px 0;
    border-bottom: 1px solid #D0D7E1;
    cursor: pointer;
}
.line-item li:first-child {
    padding-top: 0;
}
.line-item li:last-child {
    border-bottom: none;
}
.line-item h3{
    font-size: 14px;
    color: #000;
    margin-bottom: 12px;
    line-height: 14px;
    text-align: left;
}
.line-item .sub {
    font-size: 12px;
    line-height: 12px;
    color: #999;
}
.line-item .sub-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>