<template>
  <div class="listModule">
    <transition name="fades">
      <div v-show="show">
        <div class="content" v-if="list.length != 0">
          <ul class="list">
            <li @click="go(item, i)" v-for="(item, i) in list" :key="i">
              <img :src="item.cover" alt="">
              <div style="flex: 1;">
                <p class="title">{{item.title}}</p>
                <p class="describe">{{item.infos}}</p>
                <span class="date">{{item.created_at.split(" ")[0]}}</span>
              </div>
              
            </li>
          </ul>
          <a-pagination v-if="total > 10" @change="changePage" v-model="page" :defaultPageSize="pageSize" :total="total" />
        </div>
        <div class="no" v-else>
          <img src="../../assets/images/no.png" alt="">
          <p>暂无数据</p>
        </div>
    </div>
    </transition>

    <div class="skeleton" v-show="!show">
      <a-skeleton active  v-for="(item, i) in 4" :key="i"/>
    </div>
    
    
  </div>
</template>

<script>
import store from '../../store/index.js'
import {
  newsList
} from '../../http/api'
export default {
    data() {
        return {
          page: 1,
          pageSize: 10,
          list: [],
          total: 0,
          show: false
        }
    },
    props: {
        type: String,
        cate: Number
    },
    created() {
      this.update(this.page)
    },
    mounted() {

    },
    methods: {
      update(page) {
        newsList({
          category_id: this.cate,
          page: page,
          page_size: this.pageSize
        }).then(res => {
          this.list = res.data.data.news
          this.total = res.data.data.total
          this.show = true
        }).catch(err => {
            
        })
      },
      changePage(page, pageSize) {
        this.update(page)
      },
      go(item, i) {
        this.$router.push({
          path: '/article_detail',
          query: {
            id: item.id,
            type: this.type
          }
        })
      }
    }
}
</script>

<style scoped>
.listModule {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.listModule .list {
  width: 1200px;
  margin: 0 auto 20px;
}
.listModule .title {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  text-align: left;
}
.listModule .describe {
  text-align: left;
  color: #666;
}
.listModule .date {
  display: block;
  text-align: left;
  color: #666;
}
.listModule .list li {
  cursor: pointer;
  padding: 20px;
  border-bottom: 1px solid #eee;
  border-radius: 4px;
  height: 165px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.listModule .list li:hover {
  background: #f6f6f6;
}
.listModule .list li img {
  width: 220px;
  margin-right: 20px;
  object-fit: cover;
  border-radius: 6px;
}
.skeleton {
  width: 1200px;
  margin: 0 auto;
}
.no {
  padding-top: 120px;
}
.no img {
  width: 100px;
  height: 100px;
}
.no p {
  color: #bbb;
}
@media screen and (max-width: 1024px) {
  .listModule .list {
    width: 100%;
  }
  .listModule .describe {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  .listModule{
    padding-top: 0;
  }
}
</style>