<template>
    <div class="have_class_hours">

        <transition name="fades">
            <div v-if="show">
                <div class="title-wrap" :style="{borderColor: $store.state.themeColor}">
                    <div class="title-left">
                        <div class="select-title-wrap">
                            <h3 class="title" :style="{color: $store.state.themeColor}">已通过考试：{{passed_total}}</h3>
                            <a-select default-value="显示所有数据" style="width: 180px" @change="handleChange">
                                <a-select-option value="">
                                    显示所有数据
                                </a-select-option>
                                <a-select-option value="passed">
                                    仅显示已通过的考试
                                </a-select-option>
                                <a-select-option value="unpassed">
                                    仅显示未通过的考试
                                </a-select-option>
                            </a-select>
                        </div>
                        <p>当前企业：{{$store.state.currentTenantName}}</p>
                    </div>
                    <a-button @click="back" :style="{background: $store.state.themeColor, color: '#fff', border: 'none', height: '40px'}">返回</a-button>
                </div>
            </div>
        </transition>
        
        <transition name="fades">
            <div v-if="show && data.length > 0">
                <div>
                    <a-table style="margin-bottom: 20px;" :columns="columns" :data-source="data" size="small" :pagination="false"/>
                    <a-pagination v-model="page" v-if="total > 10" @change="changePage" size="small" :total="total" />
                </div>
            </div>

            <div class="empty" v-if="show && data.length < 1">
                <img src="../../assets/images/empty.png" alt="">
                <p>暂无数据</p>
            </div>
        </transition>

        <div class="loading" v-if="!show">
            <a-spin/>
        </div>

    </div>
</template>

<script>
import {
    getExamData
} from '../../http/api'
export default {
    data() {
        return {
            columns: [
                {
                    title: '受训机构',
                    dataIndex: 'name',
                },
                {
                    title: '考试名称',
                    dataIndex: 'exam_name',
                },
                {
                    title: '考试时间',
                    dataIndex: 'time',
                },
                {
                    title: '考试结果',
                    dataIndex: 'exam_result',
                }
            ],
            data: [],
            page: 1,
            page_size: 10,
            total: Number,
            show: false,
            status: '',
            passed_total: ''
        }
    },
    watch:{
        '$store.state.listenEnterprise'(n, o) {
            this.show = false
            this.page = 1
            this.update()
        }
    },
    mounted() {
        this.update()
    },
    methods: {
        update() {
            getExamData({
                tenant_id: this.$store.state.tenant_id,
                platform_id: this.$store.state.product_id,
                page: this.page,
                page_size: this.page_size,
                passed_status: this.status
            }).then(res => {
                this.show = true
                if(res.data.code == 200) {
                    this.data = []
                    this.total = parseInt(res.data.ext.total)
                    this.passed_total = res.data.ext.passed_total
                    res.data.data.map((item, i) => {
                        this.data.push({
                            key: i,
                            name: item.tenant.tenant_name,
                            exam_name: item.testpaper.name,
                            time: item.updated_at,
                            exam_result: item.passed_status == 'passed'? '通过': '未通过'
                        })
                    })
                }
            })
        },
        changePage(page) {
            this.page = page
            this.update()
        },
        back() {
            this.$router.push({
                path: '/user/archives'
            })
        },
        handleChange(value) {
            this.status = value
            this.page = 1
            this.update()
        },
    }
}
</script>

<style scoped>
.have_class_hours {
    padding: 20px;
    min-height: 500px;
}
.title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    padding-bottom: 20px;
}
.title {
    text-align: left;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
    margin-right: 20px;
    margin-bottom: 0;
}
.title-left p {
    margin-bottom: 0;
    text-align: left;
}
.select-title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.loading {
    min-height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.empty {
    min-height: 500px;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
}
.empty img {
    width: 121px;
    height: 74px;
}
</style>