var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.$store.state.template == 3 &&
    _vm.$store.state.hostname != _vm.$store.state.demonstrationHostname
      ? _c(
          "div",
          { staticClass: "inner" },
          [
            _c("div", { staticClass: "top-wrap" }, [
              _c(
                "div",
                { staticClass: "banner" },
                [
                  _c(
                    "a-carousel",
                    {
                      attrs: { arrows: "", autoplay: "", dotPosition: "right" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "custom-slick-arrow",
                          staticStyle: { left: "10px", zIndex: "1" },
                          attrs: { slot: "prevArrow" },
                          slot: "prevArrow",
                        },
                        [_c("a-icon", { attrs: { type: "left-circle" } })],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "custom-slick-arrow",
                          staticStyle: { right: "10px" },
                          attrs: { slot: "nextArrow" },
                          slot: "nextArrow",
                        },
                        [_c("a-icon", { attrs: { type: "right-circle" } })],
                        1
                      ),
                      _vm.banners.length == 0
                        ? _c(
                            "div",
                            { staticClass: "carouselImgWrap" },
                            [
                              _c("router-link", { attrs: { to: "" } }, [
                                _c("img", {
                                  staticClass: "img_a",
                                  attrs: {
                                    src: "https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg",
                                    alt: "",
                                  },
                                }),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._l(_vm.banners, function (item, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "carouselImgWrap" },
                          [
                            _vm.banners.length == 0
                              ? _c("router-link", { attrs: { to: "" } }, [
                                  _c("img", {
                                    staticClass: "img_a",
                                    attrs: {
                                      src: "https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg",
                                      alt: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            item.link_type == 2
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: `/course/studydetail?id=${item.link}&course_type=system_course`,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img_a",
                                      attrs: { src: item.image, alt: "" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            item.link_type == 1
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: { to: "" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.goThree(item, i)
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img_a",
                                      attrs: { src: item.image, alt: "" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                            item.link_type == 3
                              ? _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to: `/course/studydetail?id=${item.link}&course_type=tenant_course`,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "img_a",
                                      attrs: { src: item.image, alt: "" },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "news" }, [
                _c("div", { staticClass: "title-bar" }, [
                  _c("div", { staticClass: "title-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/title-icon.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "ul",
                      { staticClass: "news-menus" },
                      _vm._l(_vm.top_cates, function (item, i) {
                        return _c(
                          "li",
                          {
                            staticClass: "menus-li-1x",
                            style: {
                              borderColor:
                                _vm.menu_li_id == item.id
                                  ? _vm.$store.state.themeColor
                                  : "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.checkNews(item, i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "more", on: { click: _vm.moreNotice } },
                    [_vm._v("查看全部 >")]
                  ),
                ]),
                _vm.newsList.length > 0
                  ? _c(
                      "ul",
                      { staticClass: "news-wrap" },
                      _vm._l(_vm.newsList, function (item, i) {
                        return _c("li", { key: i }, [
                          _c("div", { staticClass: "date" }, [
                            _c("span", { staticClass: "date-day" }, [
                              _vm._v(
                                _vm._s(
                                  item.created_at.split("-")[2].split(" ")[0]
                                )
                              ),
                            ]),
                            _c("span", { staticClass: "dates" }, [
                              _vm._v(
                                _vm._s(
                                  `${item.created_at.split("-")[0]}/${
                                    item.created_at.split("-")[1]
                                  }`
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "news-item" }, [
                            _c(
                              "p",
                              {
                                staticClass: "news-title",
                                on: {
                                  click: function ($event) {
                                    return _vm.goNotice(item, i)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                            _c("p", { staticClass: "news-info" }, [
                              _vm._v(_vm._s(item.infos)),
                            ]),
                          ]),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _vm.newsList.length == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "empty",
                        staticStyle: { height: "380px", background: "#FAFAFA" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/empty.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: { color: "#666", "font-size": "12px" },
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _vm._m(0),
            _c("div", { staticClass: "content-wrap" }, [
              _c("div", { staticClass: "content-left" }, [
                _vm.$store.state.can_live && _vm.lives.length > 0
                  ? _c("div", { staticClass: "live-module" }, [
                      _c("div", { staticClass: "title-bar" }, [
                        _vm._m(1),
                        _vm.lives.length >= 2
                          ? _c(
                              "span",
                              {
                                staticClass: "more",
                                on: {
                                  click: function ($event) {
                                    return _vm.more("live")
                                  },
                                },
                              },
                              [_vm._v("查看全部 >")]
                            )
                          : _vm._e(),
                      ]),
                      _vm.lives.length > 0
                        ? _c(
                            "ul",
                            { staticClass: "live-list" },
                            _vm._l(_vm.lives, function (item, i) {
                              return _c(
                                "li",
                                {
                                  key: i,
                                  staticClass: "live-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.go(item, i)
                                    },
                                  },
                                },
                                [
                                  _c("div", { staticClass: "live-top" }, [
                                    _c("img", {
                                      attrs: {
                                        src: item.authors[0].avatar,
                                        alt: "",
                                      },
                                    }),
                                    _c("p", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "live-bot" }, [
                                    _c("span", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.authors[0].name)),
                                    ]),
                                    _vm.liveStatus(
                                      item.start_time,
                                      item.end_time
                                    ) == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "live-sign",
                                            style: {
                                              background:
                                                _vm.$store.state.themeColor,
                                              color: "#fff",
                                            },
                                          },
                                          [
                                            _c("span", [_vm._v("正在直播")]),
                                            _c("img", {
                                              attrs: {
                                                src: require("../../assets/images/going.png"),
                                                alt: "",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.liveStatus(
                                      item.start_time,
                                      item.end_time
                                    ) == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "live-sign" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.start_time.split(":")[0] +
                                                  ":" +
                                                  item.start_time.split(":")[1]
                                              ) + " 开始"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.liveStatus(
                                      item.start_time,
                                      item.end_time
                                    ) == 2
                                      ? _c(
                                          "div",
                                          { staticClass: "live-sign" },
                                          [_vm._v("已结束")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.$store.state.can_class && _vm.list.length > 0
                  ? _c("div", { staticClass: "classes-wrap" }, [
                      _c("div", { staticClass: "title-bar" }, [
                        _c("div", { staticClass: "title-wrap" }, [
                          _c("img", {
                            attrs: {
                              src: require("../../assets/images/title-icon.png"),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v(_vm._s(_vm.categorys[0].name))]),
                        ]),
                        _vm.list.length >= 2
                          ? _c(
                              "span",
                              {
                                staticClass: "more",
                                on: {
                                  click: function ($event) {
                                    return _vm.more("classes")
                                  },
                                },
                              },
                              [_vm._v("查看全部 >")]
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "ul",
                        { staticClass: "classes-list" },
                        _vm._l(_vm.list, function (item, i) {
                          return _c(
                            "li",
                            {
                              key: i,
                              staticClass: "classes-item",
                              on: {
                                click: function ($event) {
                                  return _vm.go(item, i)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img" }, [
                                _c("img", {
                                  staticClass: "img_a",
                                  attrs: { src: item.picture, alt: "" },
                                }),
                                item.learn_status == 1
                                  ? _c(
                                      "div",
                                      { staticClass: "classes-sign-ing" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-right": "20px",
                                            },
                                          },
                                          [_vm._v("进行中")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                item.learn_status == 2
                                  ? _c(
                                      "div",
                                      { staticClass: "classes-sign-end" },
                                      [_vm._v("已结束")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c("div", { staticClass: "classes-info" }, [
                                _c("p", { staticClass: "classes-title" }, [
                                  _vm._v(_vm._s(item.title)),
                                ]),
                                _c("p", { staticClass: "classes-about" }, [
                                  _vm._v(_vm._s(item.about)),
                                ]),
                                _c(
                                  "p",
                                  {
                                    staticStyle: {
                                      color: "#333",
                                      "margin-bottom": "0",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(item.lesson_num) +
                                        "门课 | " +
                                        _vm._s(item.period) +
                                        "课时 | " +
                                        _vm._s(item.learn_total) +
                                        "人已学"
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "classes-do-wrap" }, [
                                  item.order_status == 0 &&
                                  _vm.$store.state.can_pay
                                    ? _c("div", [
                                        item.price && item.price > 0
                                          ? _c(
                                              "span",
                                              { staticClass: "classes-sign" },
                                              [_vm._v("￥")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          { staticClass: "classes-price" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                item.price > 0
                                                  ? item.price
                                                  : "免费"
                                              )
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  item.order_status != 0 ? _c("div") : _vm._e(),
                                  _vm._m(2, true),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.categorys.length > 0
                  ? _c("div", { staticClass: "list-module" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.listFull, function (item, i) {
                          return _c(
                            "li",
                            { key: i, staticClass: "module-item" },
                            [
                              _c("div", { staticClass: "title-bar" }, [
                                _c("div", { staticClass: "title-wrap" }, [
                                  _c("img", {
                                    attrs: {
                                      src: require("../../assets/images/title-icon.png"),
                                      alt: "",
                                    },
                                  }),
                                  _c("span", [_vm._v(_vm._s(item.name))]),
                                ]),
                                item.course.length >= 3
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "more",
                                        on: {
                                          click: function ($event) {
                                            return _vm.more("course", item.id)
                                          },
                                        },
                                      },
                                      [_vm._v("查看全部 >")]
                                    )
                                  : _vm._e(),
                              ]),
                              _c(
                                "ul",
                                { staticClass: "lesson-list" },
                                _vm._l(item.course, function (items, is) {
                                  return _c(
                                    "li",
                                    {
                                      key: is,
                                      staticClass: "lesson-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.go(items, i)
                                        },
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "img_a",
                                        attrs: { src: items.picture, alt: "" },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "lesson-msg-wrap" },
                                        [
                                          _c(
                                            "p",
                                            { staticClass: "lesson-title" },
                                            [_vm._v(_vm._s(items.title))]
                                          ),
                                          items.type == "link"
                                            ? _c("p", [
                                                _vm._v(
                                                  "课程来源：" +
                                                    _vm._s(items.link_source)
                                                ),
                                              ])
                                            : _vm._e(),
                                          items.type != "link"
                                            ? _c("div", [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass: "lesson-about",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(items.period) +
                                                        "课时 | " +
                                                        _vm._s(
                                                          items.peroid_format
                                                        ) +
                                                        "分钟 | " +
                                                        _vm._s(
                                                          items.learn_total
                                                        ) +
                                                        "人已学"
                                                    ),
                                                  ]
                                                ),
                                                items.authors &&
                                                items.authors.length > 0
                                                  ? _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "lesson-name",
                                                      },
                                                      _vm._l(
                                                        items.authors.slice(
                                                          0,
                                                          3
                                                        ),
                                                        function (itemss, iss) {
                                                          return _c(
                                                            "span",
                                                            {
                                                              key: iss,
                                                              attrs: {
                                                                a: items.length,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  itemss.name
                                                                )
                                                              ),
                                                              items.authors
                                                                .length > 3
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        iss == 2
                                                                          ? "等"
                                                                          : "、"
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                              items.authors
                                                                .length > 1 &&
                                                              items.authors
                                                                .length < 3
                                                                ? _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        iss != 1
                                                                          ? "、"
                                                                          : ""
                                                                      )
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _c("p", {
                                                      staticClass:
                                                        "lesson-name",
                                                    }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "lesson-price-info",
                                                  },
                                                  [
                                                    _vm.$store.state.can_pay
                                                      ? _c("div", [
                                                          items.price > 0
                                                            ? _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "lesson-price-sign",
                                                                },
                                                                [_vm._v("￥")]
                                                              )
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "lesson-price",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  items.price >
                                                                    0
                                                                    ? items.price
                                                                    : "免费"
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("van-rate", {
                                                          attrs: {
                                                            size: "14px",
                                                          },
                                                          model: {
                                                            value: _vm.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.value = $$v
                                                            },
                                                            expression: "value",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
                _vm.categorys.length == 0
                  ? _c(
                      "div",
                      {
                        staticClass: "empty",
                        staticStyle: { height: "400px" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/empty.png"),
                            alt: "",
                          },
                        }),
                        _c(
                          "span",
                          {
                            staticStyle: { color: "#666", "font-size": "12px" },
                          },
                          [_vm._v("暂无数据")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "content-right" }, [
                _c(
                  "div",
                  { staticClass: "right-module", on: { click: _vm.addCert } },
                  [
                    _c("img", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        src: require("../../assets/images/addAcount.jpg"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c("div", { staticClass: "title-bar" }, [
                  _c("div", { staticClass: "title-wrap" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/title-icon.png"),
                        alt: "",
                      },
                    }),
                    _c(
                      "p",
                      {
                        staticStyle: {
                          "font-size": "20px",
                          "line-height": "22px",
                          "margin-bottom": "0",
                        },
                      },
                      [_vm._v("合作院校 " + _vm._s(_vm.schoolTotal) + " 家")]
                    ),
                  ]),
                  _c(
                    "span",
                    { staticClass: "more", on: { click: _vm.moreSchool } },
                    [_vm._v("查看全部 >")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "right-module",
                    staticStyle: { "min-height": "300px" },
                  },
                  [
                    _c(
                      "ul",
                      { staticClass: "coll-tab" },
                      _vm._l(_vm.collegeData, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            class: _vm.tabIndex == i ? "coll-tab-ac" : " ",
                            on: {
                              click: function ($event) {
                                return _vm.checkSchoolTab(item, i)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                    _c(
                      "ul",
                      { staticClass: "school-list-wrap" },
                      _vm._l(_vm.schoolList, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.linkUrl(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name))]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "a-modal",
              {
                attrs: {
                  title: null,
                  id: "school-cert-in",
                  footer: null,
                  width: "700px",
                  destroyOnClose: true,
                },
                model: {
                  value: _vm.visible,
                  callback: function ($$v) {
                    _vm.visible = $$v
                  },
                  expression: "visible",
                },
              },
              [
                _c(
                  "h3",
                  {
                    staticStyle: {
                      "font-size": "24px",
                      "line-height": "26px",
                      "text-align": "center",
                      "font-weight": "bold",
                      "border-bottom": "1px solid #ddd",
                      padding: "0 0 15px",
                      "margin-top": "-10px",
                    },
                  },
                  [_vm._v("证书申请信息")]
                ),
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-bottom": "40px",
                    },
                  },
                  [
                    _vm._v(
                      "请准确填写相关信息，以确保我们能尽快准确的为您提供服务"
                    ),
                  ]
                ),
                _c(
                  "a-form",
                  {
                    attrs: {
                      form: _vm.form,
                      "label-col": { span: 5 },
                      "wrapper-col": { span: 12 },
                    },
                    on: { submit: _vm.handleSubmit },
                  },
                  [
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { "margin-left": "-69px" },
                        attrs: { label: "院校名称" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "name",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入院校名称",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['name', { rules: [{ required: true, message: '请输入院校名称' }] }]",
                            },
                          ],
                          attrs: { maxLength: "50" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { "margin-left": "-69px" },
                        attrs: { label: "院校所在地" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "address",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入院校所在地",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['address', { rules: [{ required: true, message: '请输入院校所在地' }] }]",
                            },
                          ],
                          attrs: { maxLength: "50" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { "margin-left": "-69px" },
                        attrs: { label: "院校类型" },
                      },
                      [
                        _c(
                          "a-select",
                          {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "type",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "请选择院校类型",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                        'type',\n                        { rules: [{ required: true, message: '请选择院校类型' }] },\n                        ]",
                              },
                            ],
                            attrs: {
                              size: "large",
                              placeholder: "请选择院校类型",
                            },
                            on: { change: _vm.handleSelectChange },
                          },
                          [
                            _c(
                              "a-select-option",
                              { attrs: { value: "中职院校" } },
                              [_vm._v(" 中职院校 ")]
                            ),
                            _c(
                              "a-select-option",
                              { attrs: { value: "高职院校" } },
                              [_vm._v(" 高职院校 ")]
                            ),
                            _c(
                              "a-select-option",
                              { attrs: { value: "本科院校" } },
                              [_vm._v(" 本科院校 ")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { "margin-left": "-69px" },
                        attrs: { label: "联系人姓名" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "contacter",
                                {
                                  rules: [
                                    {
                                      required: true,
                                      message: "请输入联系人姓名",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['contacter', { rules: [{ required: true, message: '请输入联系人姓名' }] }]",
                            },
                          ],
                          attrs: { maxLength: "20" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        staticStyle: { "margin-left": "-69px" },
                        attrs: { label: "手机号" },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "mobile",
                                {
                                  rules: [
                                    { required: true, message: "请输入手机号" },
                                    {
                                      pattern: new RegExp(
                                        /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
                                      ),
                                      message: "手机号格式错误！",
                                    },
                                  ],
                                },
                              ],
                              expression:
                                "['mobile', { rules: [{ required: true, message: '请输入手机号'}, {pattern: new RegExp(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\\d{8}$/), message: '手机号格式错误！'}] }]",
                            },
                          ],
                          attrs: { maxLength: "11" },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      [
                        _c(
                          "a-button",
                          {
                            style: {
                              background: _vm.$store.state.themeColor,
                              height: "40px",
                              width: "100%",
                              marginTop: "20px",
                            },
                            attrs: { type: "primary", "html-type": "submit" },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "a-modal",
              {
                attrs: { title: "证书申请信息", footer: null },
                model: {
                  value: _vm.visibleAgain,
                  callback: function ($$v) {
                    _vm.visibleAgain = $$v
                  },
                  expression: "visibleAgain",
                },
              },
              [
                _c("p", { staticStyle: { "text-align": "center" } }, [
                  _vm._v(_vm._s(_vm.againMsg)),
                ]),
                _c(
                  "div",
                  { staticClass: "close-btn" },
                  [
                    _c(
                      "a-button",
                      {
                        style: {
                          background: _vm.$store.state.themeColor,
                          color: "#fff",
                          border: "none",
                        },
                        on: { click: _vm.close },
                      },
                      [_vm._v("关闭")]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
    _vm.$store.state.template == 3 &&
    _vm.$store.state.hostname == _vm.$store.state.demonstrationHostname
      ? _c("div", [
          _c("div", { staticClass: "notice-1x" }, [
            _c("div", { staticClass: "notice-1x-inner" }, [
              _vm._m(3),
              _vm.news.length > 0
                ? _c(
                    "ul",
                    { staticClass: "news-wrap1x" },
                    _vm._l(_vm.news, function (item, i) {
                      return _c("li", { key: i }, [
                        _c("div", { staticClass: "date" }, [
                          _c("span", { staticClass: "date-day" }, [
                            _vm._v(
                              _vm._s(
                                item.created_at.split("-")[2].split(" ")[0]
                              )
                            ),
                          ]),
                          _c("span", { staticClass: "dates" }, [
                            _vm._v(
                              _vm._s(
                                `${item.created_at.split("-")[0]}/${
                                  item.created_at.split("-")[1]
                                }`
                              )
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "news-item" }, [
                          _c(
                            "p",
                            {
                              staticClass: "news-title",
                              on: {
                                click: function ($event) {
                                  return _vm.goNotice(item, i)
                                },
                              },
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                          _c("p", { staticClass: "news-info" }, [
                            _vm._v(_vm._s(item.infos)),
                          ]),
                        ]),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
            ]),
          ]),
          _vm._m(4),
          _c("div", { staticClass: "two1x" }, [
            _c("h3", { staticClass: "title1x" }, [
              _vm._v("爱迪课堂，满足岗位进阶和终身学习的需要"),
            ]),
            _c("p", { staticClass: "subt1x" }, [
              _vm._v("开放注册、自主学习、学情跟踪、学时记录"),
            ]),
            _c(
              "ul",
              { staticClass: "two1x-menu" },
              _vm._l(_vm.first, function (item, i) {
                return _c(
                  "li",
                  {
                    class: i == _vm.index ? "two1x-menu-ac" : "",
                    on: {
                      click: function ($event) {
                        return _vm.checkMenu(item, i)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
            _c(
              "ul",
              { staticClass: "two1x-list" },
              _vm._l(_vm.list1x, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    on: {
                      click: function ($event) {
                        return _vm.goCourse(item, i)
                      },
                    },
                  },
                  [
                    _c("img", { attrs: { src: item.picture, alt: "" } }),
                    _c(
                      "h3",
                      {
                        staticStyle: {
                          "line-height": "20px",
                          "font-weight": "bold",
                          display: "-webkit-box",
                          "-webkit-box-orient": "vertical",
                          "-webkit-line-clamp": "1",
                          overflow: "hidden",
                          "text-align": "left",
                          padding: "0 20px",
                          "margin-bottom": "12px",
                          "font-size": "18px",
                        },
                      },
                      [_vm._v(_vm._s(item.title))]
                    ),
                    item.link == "link"
                      ? _c(
                          "p",
                          {
                            staticClass: "thr-sub-title",
                            staticStyle: { "padding-bottom": "20px" },
                          },
                          [
                            _vm._v(
                              " 课程来源：" + _vm._s(item.link_source) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    item.link != "link"
                      ? _c("div", [
                          _c("p", { staticClass: "thr-sub-title" }, [
                            _vm._v(_vm._s(item.infos)),
                          ]),
                          _vm._m(5, true),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._m(6),
          _vm._m(7),
          _c("div", { staticClass: "fiv1x" }, [
            _c("h3", { staticClass: "title1x" }, [
              _vm._v("就业创业，架构桥梁，企业择优"),
            ]),
            _c("p", { staticClass: "subt1x" }, [
              _vm._v("牵手企业，定向输送复合型人才"),
            ]),
            _c(
              "ul",
              { staticClass: "fiv1x-menu" },
              _vm._l(_vm.menu, function (item, i) {
                return _c(
                  "li",
                  {
                    class: i == _vm.index2 ? "fiv1x-menu-ac" : "",
                    on: {
                      click: function ($event) {
                        return _vm.checkMenu2(item, i)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
            _c(
              "ul",
              { staticClass: "fiv1xlist" },
              _vm._l(_vm.data, function (item, i) {
                return _c(
                  "li",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.goJob()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            width: "65%",
                            "text-align": "left",
                            "font-size": "16px",
                            "font-weight": "bold",
                            display: "-webkit-box",
                            "-webkit-box-orient": "vertical",
                            "-webkit-line-clamp": "1",
                            overflow: "hidden",
                          },
                        },
                        [_vm._v(_vm._s(item.title))]
                      ),
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              color: "#F34026",
                            },
                          },
                          [_vm._v(_vm._s(item.red))]
                        ),
                        _c("span", {
                          staticStyle: { "font-size": "12px", color: "#333" },
                        }),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _vm._v(
                        _vm._s(item.jobarea) + " 丨 " + _vm._s(item.joblimit)
                      ),
                    ]),
                    _c("div", {
                      staticStyle: {
                        width: "100%",
                        "border-bottom": "1px solid #E5E5E5",
                      },
                    }),
                    _c("div", { staticClass: "bot" }, [
                      _c(
                        "span",
                        { staticStyle: { "font-size": "12px", color: "#333" } },
                        [_vm._v(_vm._s(item.company))]
                      ),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "12px", color: "#999" } },
                        [_vm._v("五险一金丨周末双休")]
                      ),
                    ]),
                  ]
                )
              }),
              0
            ),
          ]),
          _vm._m(8),
          _c("div", { staticClass: "sev1x" }, [
            _c("div", { staticClass: "tab" }, [
              _c(
                "h3",
                {
                  staticClass: "title1x",
                  staticStyle: { "text-align": "left" },
                },
                [_vm._v("移动端学习（即将推出）")]
              ),
              _c(
                "p",
                {
                  staticClass: "subt1x",
                  staticStyle: {
                    "text-align": "left",
                    "margin-bottom": "60px",
                    color: "#818892",
                    "line-height": "28px",
                  },
                },
                [_vm._v("多场景助学，聚焦个性化学习赋能，开启全新移动学习体验")]
              ),
              _c(
                "ul",
                { staticClass: "menu" },
                _vm._l(_vm.tabMenu, function (item, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function ($event) {
                          return _vm.checkTab(item, i)
                        },
                      },
                    },
                    [
                      _c("img", {
                        style: {
                          background: _vm.indexTab == i ? "#0066CC" : "#fff",
                        },
                        attrs: {
                          src: _vm.indexTab == i ? item.acsrc : item.src,
                          alt: "",
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            color: "#555",
                            "font-weight": "bold",
                            "line-height": "16px",
                          },
                        },
                        [_vm._v(_vm._s(item.name))]
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _c("div", { staticClass: "tabImg" }, [
              _c("img", {
                staticClass: "bg",
                attrs: {
                  src: require("../../assets/images/m-iphone.png"),
                  alt: "",
                },
              }),
              _c("div", {
                staticClass: "img-item",
                style: { backgroundImage: "url(" + _vm.checkedImg + ")" },
              }),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flower" }, [
      _c("div", { staticClass: "flower-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/tem-two-f02.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("注册登录")]),
      ]),
      _c("img", {
        staticClass: "flower-sign",
        attrs: { src: require("../../assets/images/flower-sign.png"), alt: "" },
      }),
      _c("div", { staticClass: "flower-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/tem-two-f01.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("选择课程")]),
      ]),
      _c("img", {
        staticClass: "flower-sign",
        attrs: { src: require("../../assets/images/flower-sign.png"), alt: "" },
      }),
      _c("div", { staticClass: "flower-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/tem-two-f03.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("在线学习")]),
      ]),
      _c("img", {
        staticClass: "flower-sign",
        attrs: { src: require("../../assets/images/flower-sign.png"), alt: "" },
      }),
      _c("div", { staticClass: "flower-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/tem-two-f04.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("在线考试")]),
      ]),
      _c("img", {
        staticClass: "flower-sign",
        attrs: { src: require("../../assets/images/flower-sign.png"), alt: "" },
      }),
      _c("div", { staticClass: "flower-item" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/tem-two-f05.png"),
            alt: "",
          },
        }),
        _c("span", [_vm._v("录入证书")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-wrap" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/title-icon.png"), alt: "" },
      }),
      _c("span", [_vm._v("最近直播")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "classes-go" }, [
      _c("div", { staticClass: "classes-go-img" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "notice-1x-icon" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/1xnotice.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "one1x" }, [
      _c("h3", { staticClass: "title1x" }, [
        _vm._v("国家认证1+X证书，就业无忧"),
      ]),
      _c("p", { staticClass: "subt1x" }, [
        _vm._v("反映职业活动和个人职业生涯发展所需要的综合能力"),
      ]),
      _c("div", { staticClass: "lv one1xitem" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate1.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate1.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate1.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "la one1xitem" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate2.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate2.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate2.png"),
            alt: "",
          },
        }),
      ]),
      _c("div", { staticClass: "hu one1xitem" }, [
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate3.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate3.png"),
            alt: "",
          },
        }),
        _c("img", {
          attrs: {
            src: require("../../assets/images/certificate3.png"),
            alt: "",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "two1x-price" }, [
      _c("span", { staticStyle: { "font-size": "14px", color: "#999" } }, [
        _vm._v("1人已学"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "thr1x" }, [
      _c("h3", { staticClass: "title1x", staticStyle: { color: "#0C3950" } }, [
        _vm._v("学分银行，国家级能力评定标准"),
      ]),
      _c("p", { staticClass: "subt1x", staticStyle: { color: "#5388A4" } }, [
        _vm._v("一人一账号，一号伴终身"),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/bank1x1.png"), alt: "" },
          }),
          _c("h3", [_vm._v("学分银行账户")]),
          _c("p", [
            _vm._v(
              "学习成果信息、学员信息、业务信息等。个人学习账户是学分银行为办理学习成果认定其中主要记录用户的基本信息、学习成果信息、业务办理"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/bank1x2.png"), alt: "" },
          }),
          _c("h3", [_vm._v("学习成果登记")]),
          _c("p", [
            _vm._v(
              "学习成果登记是将学习成果关联学分银行账户的过程。无论是机构还是个人均可将各自拥有的学习成果进行登记，分别存入机构账户和个人学习"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/bank1x3.png"), alt: "" },
          }),
          _c("h3", [_vm._v("学习成果认定")]),
          _c("p", [
            _vm._v(
              "书所体现的学习成果按照统一的学时学分规则认定其具有学分银行学分的过程。学习成果认定是实现学习成果积累和转换的基础别存入机构账户"
            ),
          ]),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/bank1x4.png"), alt: "" },
          }),
          _c("h3", [_vm._v("学习成果存储")]),
          _c("p", [
            _vm._v(
              "学习成果存储是将被认定的机构和个人学习成果进行标注和分类码放，同时，分别存入机构账户和个人学习账户的过程。"
            ),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "fou1x" }, [
      _c("h3", { staticClass: "title1x" }, [
        _vm._v("校企联盟，优质共享，互认互通"),
      ]),
      _c("p", { staticClass: "subt1x" }, [
        _vm._v("推动开放与共享，创建人才培养新生态"),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school1.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school2.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school3.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school4.png"), alt: "" },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school5.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school6.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school7.png"), alt: "" },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school8.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: { src: require("../../assets/images/school9.png"), alt: "" },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school10.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school11.png"),
              alt: "",
            },
          }),
        ]),
      ]),
      _c("ul", [
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school12.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school13.png"),
              alt: "",
            },
          }),
        ]),
        _c("li", [
          _c("img", {
            attrs: {
              src: require("../../assets/images/school14.png"),
              alt: "",
            },
          }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "six1x" }, [
      _c("h3", { staticClass: "title1x", staticStyle: { color: "#fff" } }, [
        _vm._v("学产品矩阵，构建教育培训新生态"),
      ]),
      _c("p", { staticClass: "subt1x", staticStyle: { color: "#fff" } }, [
        _vm._v("总有一块适合你提升的地方"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }