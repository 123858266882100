<template>
    <div class="classcert">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>相关证书</h3>
        </div>
        <ul class="cert-list" v-if="show && cert.length > 0">
            <li class="cert-item" v-for="(item, i) in cert" :key="i">
                <img class="img_a" :src="item.template_background" alt="">
                <p style="color:green;font-weight: bold;line-height: 20px;height: 20px;">{{!item.get_directly || item.identity_photo == 1? '获得条件': ''}}</p>
                <div class="conditions-wrap">
                    <div v-if="!item.get_directly">
                        <div class="conditions" v-if="item.course_finished">
                            <span>学完课程</span>
                            <span :style="{color: item.is_course_finished?'#1D75FA': ''}">{{item.is_course_finished? '已完成': '未完成'}}</span>
                        </div>
                        <div class="conditions" v-if="item.pass_exam">
                            <span>考试通过</span>
                            <span :style="{color: item.is_pass_exam?'#1D75FA': ''}">{{item.is_pass_exam? '已完成': '未完成'}}</span>
                        </div>
                    </div>
                    <!-- <div class="conditions">
                        <span>报名课程</span>
                        <span :style="{color: item.get_directly?'#1D75FA': ''}">{{item.get_directly? '已完成': '未完成'}}</span>
                    </div> -->
                    <div class="conditions" v-if="item.identity_photo == 1">
                        <span>证件照</span>
                        <span style="color: #1D75FA;" v-if="item.is_identity_photo">已上传</span>
                        <span @click="upAvatar" class="up-btn" v-if="!item.is_identity_photo">去上传</span>
                    </div>
                </div>
                <a-button v-if="!item.is_get_cert">未获得</a-button>
                <a class="see-btn" v-if="item.is_get_cert" target="_blank" :href="item.template_background" download>查看证书</a>
            </li>
        </ul>
        <div class="empty" v-if="show && !cert.length">
            <img src="../../assets/images/weipingjia.png" alt="">
            <span>暂无证书~</span>
        </div>
        <div style="height: 300px;line-height: 300px;" v-show="!show">
            <a-spin />
        </div>
    </div>
</template>

<script>
import {
    classCert
} from '../../http/api'
export default {
    data() {
        return {
            class_id: '',
            cert: [],
            show: false
        }
    },
    created() {
        this.class_id = this.$route.query.id
        classCert({
            id: this.class_id
        }).then(res => {
            if(res.data.code == 200) {
                this.cert = res.data.data
                this.show = true
            }
        })
    },
    methods: {
        upAvatar() {
            this.$router.push('/user/setuser')
        }
    }
}
</script>

<style scoped>
    .cert-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }
    .cert-item {
        width: 310px;
        margin-right: 25px;
        border: 1px solid #eee;
        box-sizing: border-box;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-radius: 4px;
        min-height: 420px;
    }
    .cert-item:nth-child(3n) {
        margin-right: 0;
    }
    .conditions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
    }
    .conditions span:last-child {
        color: #666;
    }
    .conditions-wrap {
        margin-bottom: 20px;
        min-height: 93px;
    }
    .up-btn {
        font-size: 14px;
        background: #1D75FA;
        border-radius: 4px;
        color: #fff!important;
        cursor: pointer;
        padding: 4px;
        line-height: 14px;
    }
    .see-btn {
        display: inline-block;
        width: 74px;
        height: 32px;
        background: #1D75FA;
        line-height: 32px;
        color: #fff;
        border-radius: 4px;
    }
    .empty {
        padding: 50px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
</style>