<template>
    <div>
        <div class="bg">
            <div class="inner">
                <h3 class="bg-title">爱迪云企校联盟</h3>
                <p>爱迪云通过提供平台+课程+服务的一站式服务、持续稳定的技术升级、24小时相应的专业运营支持，已吸众多企业和学校入驻，共建爱迪云生态。</p>
                <a-button class="btn" @click="open()">业务咨询</a-button>
            </div>
        </div>
        <div class="thr1x">
            <h3 class="title1x">为什么选择爱迪云学院</h3>
            <ul>
                <li>
                    <img src="../../assets/images/alliance1.png" alt="">
                    <h3>成熟的解决方案</h3>
                    <p>成熟的解决方案：通过全面的产品、安全可靠的服务、持续的技术升级，为客户在线培训项目保驾护航</p>
                </li>
                <li>
                    <img src="../../assets/images/alliance2.png" alt="">
                    <h3>全面的产品服务</h3>
                    <p>全面的产品与服务：产品功能覆盖在线职业教育全程，一站式服务，以用户为中心，以客户成功为导向</p>
                </li>
                <li>
                    <img src="../../assets/images/alliance3.png" alt="">
                    <h3>持续的技术升级</h3>
                    <p>持续的技术升级：平台功能定期更新，无需额外成本即可享受产品持续优化迭代</p>
                </li>
                <li>
                    <img src="../../assets/images/alliance4.png" alt="">
                    <h3>更稳定更安全</h3>
                    <p>更稳定更安全：专业技术运维团队，24小时监控平台稳定性</p>
                </li>
            </ul>
        </div>

        <div class="service">
            <h3 class="title">服务规模</h3>
            <ul>
                <li>
                    <div>
                        <span class="nStyle">302</span>
                        <span class="sStyle">家</span>
                    </div>
                    <span style="font-size: 14px" class="sStyle">已入驻高校</span>
                </li>
                <li>
                    <div>
                        <span class="nStyle">67000</span>
                        <span class="sStyle">名</span>
                    </div>
                    <span style="font-size: 14px" class="sStyle">已注册学员</span>
                </li>
                <li>
                    <div>
                        <span class="nStyle">132</span>
                        <span class="sStyle">家</span>
                    </div>
                    <span style="font-size: 14px" class="sStyle">合作企业</span>
                </li>
                <li>
                    <div>
                        <span class="nStyle">400</span>
                        <span class="sStyle">家</span>
                    </div>
                    <span style="font-size: 14px" class="sStyle">职业证书</span>
                </li>
            </ul>
        </div>

        <div class="fou1x">
            <h3 class="title1x">他们都在使用爱迪云</h3>
            <ul>
                <li>
                    <img src="../../assets/images/school1.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school2.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school3.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school4.png" alt="">
                </li>
            </ul>
            <ul>
                <li>
                    <img src="../../assets/images/school5.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school6.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school7.png" alt="">
                </li>
            </ul>
            <ul>
                <li>
                    <img src="../../assets/images/school8.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school9.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school10.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school11.png" alt="">
                </li>
            </ul>
            <ul>
                <li>
                    <img src="../../assets/images/school12.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school13.png" alt="">
                </li>
                <li>
                    <img src="../../assets/images/school14.png" alt="">
                </li>
            </ul>
        </div>

        <a-modal width="400px"
        :footer="null"
        v-model="visible" title="联系我们" @ok="handleOk">
            <img src="../../assets/images/adCode.png" alt="">
            <p style="text-align: center; font-weight: bold;">相关业务咨询或合作，请添加爱迪科森官方微信公众号</p>
        </a-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        open() {
            this.visible = true
        },
        handleOk(e) {
            // console.info(e)
            this.visible = false
        }
    }
}
</script>

<style scoped>
.bg {
    width: 100%;
    height: 560px;
    background: url('../../assets/images/allianceBg.jpg') no-repeat center center;
    background-size: cover;
}
.inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 60px;
}
.inner p {
    width: 490px;
    color: #fff;
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 70px;
}
.btn {
    widows: 126px;
    height: 42px;
    border-radius: 10px;
    background: #0066CC;
    font-size: 15px;
    color: #fff;
    border: none;
}
.thr1x {
    height: 500px;
    padding-top: 50px;
}
.thr1x ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.thr1x ul li {
    width: 288px;
    height: 310px;
    background: #fff;
    border-radius: 10px;
    margin: 0 12px;
    padding: 40px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all .3s;
    cursor: pointer;
    box-shadow: 0 0 10px #eee;
}.thr1x ul li:hover {
    transform: scale(1.05);
}
.thr1x ul li img {
    width: 60px;
    height: 60px;
    margin-bottom: 26px;
}
.thr1x ul li h3 {
    color: #111D30;
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 18px;
    font-weight: bold;
}
.thr1x ul li p {
    color: #666;
    text-align: left;
    font-size: 12px;
    line-height: 24px;
}
.title1x {
    color: #111D30;
    font-size: 30px;
    line-height: 30px;
    font-weight: bold;
    margin-bottom: 45px;
}
.service {
    padding-top: 50px;
    height: 304px;
    background: url('../../assets/images/serBg.png') no-repeat;
}
.service .title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 60px;
    color: #fff;
}
.service ul {
    width: 1200px;
    margin: 0 auto;
    padding: 0 170px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.service ul li{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.service ul li>div {
    margin-bottom: 14px;
}
.nStyle {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
    line-height: 40px;
}
.sStyle {
    font-size: 20px;
    color: #fff;
    line-height: 20px;
}
.fou1x {
    padding-top: 50px;
    background: #f6f8fb;
    height: 600px;
}
.fou1x ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.fou1x ul li {
    margin: 0 10px;
}
.fou1x ul li img {
    width: auto;
    height: auto;
}
.bg-title {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
</style>