var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "exam" },
    [
      _vm._m(0),
      _c("div", { staticClass: "exam" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.exams.length == 0,
                expression: "exams.length == 0",
              },
            ],
            staticStyle: { padding: "20px", "min-height": "400px" },
          },
          [_vm._m(1)]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.exams.length > 0,
                expression: "exams.length > 0",
              },
            ],
            staticClass: "exams",
          },
          [
            _c(
              "ul",
              _vm._l(_vm.exams, function (item, i) {
                return _c(
                  "li",
                  { key: i },
                  [
                    _c("div", { staticClass: "left" }, [
                      _c("h3", [_vm._v(_vm._s(item.name))]),
                      _c("p", [_vm._v(_vm._s(item.description))]),
                      _c("p", { staticStyle: { "margin-bottom": "0" } }, [
                        _c("span", [
                          _vm._v("题目数量 " + _vm._s(item.item_count)),
                        ]),
                        _c("span", [_vm._v("|")]),
                        _c("span", [
                          _vm._v(
                            "考试时间 " +
                              _vm._s(
                                !item.limit_time_format
                                  ? "不限制"
                                  : item.limit_time_format + "分钟"
                              )
                          ),
                        ]),
                        _c("span", [_vm._v("|")]),
                        _c("span", [_vm._v("总分 " + _vm._s(item.score))]),
                        _c("span", [_vm._v("|")]),
                        _c("span", [
                          _vm._v("及格分 " + _vm._s(item.passed_score)),
                        ]),
                        _c("span", [_vm._v("|")]),
                        item.retake == 1
                          ? _c("span", [
                              _vm._v(
                                "允许重考 " + _vm._s(item.do_times) + " 次"
                              ),
                            ])
                          : _vm._e(),
                        item.retake == 0
                          ? _c("span", [_vm._v("不允许重考")])
                          : _vm._e(),
                        item.retake == 2
                          ? _c("span", [_vm._v("不限制重考次数")])
                          : _vm._e(),
                      ]),
                    ]),
                    _c(
                      "a-button",
                      {
                        style: {
                          background:
                            item.exam_type == 0 || item.exam_type == 4
                              ? "#ccc"
                              : _vm.$store.state.themeColor,
                          color: "#fff",
                          width: "100px",
                        },
                        attrs: { size: "large" },
                        on: {
                          click: function ($event) {
                            return _vm.goExam(item, i)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.btnTxt(item).txt))]
                    ),
                    item.passed_status != ""
                      ? _c("img", {
                          staticClass: "exam-icon",
                          staticStyle: {
                            position: "absolute",
                            top: "45px",
                            right: "150px",
                          },
                          attrs: {
                            src:
                              item.passed_status == "passed"
                                ? require("../../assets/images/examYes.png")
                                : require("../../assets/images/examNo.png"),
                            alt: "",
                          },
                        })
                      : _vm._e(),
                    item.is_access_exam == 1
                      ? _c(
                          "span",
                          {
                            staticClass: "pass_icon",
                            style: { background: _vm.$store.state.themeColor },
                          },
                          [_vm._v("随到随考")]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            bodyStyle: { padding: 0 },
            title: _vm.examTitle,
            footer: null,
            width: "520px",
          },
          model: {
            value: _vm.examVisible,
            callback: function ($$v) {
              _vm.examVisible = $$v
            },
            expression: "examVisible",
          },
        },
        [
          _vm.exam_type == 2 || _vm.exam_type == 3
            ? _c(
                "div",
                { staticClass: "exam-item-wrap" },
                [
                  _vm._l(_vm.examData, function (item, i) {
                    return _c(
                      "div",
                      { key: i, staticClass: "exam-item" },
                      [
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-bottom": "10px",
                                color: "#666",
                                "font-size": "16px",
                                "font-weight": "bold",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    i == 0 ? "历史最高分：" : "上次考试成绩："
                                  )
                                ),
                              ]),
                              item.passed_status != ""
                                ? _c("span", [
                                    _vm._v(_vm._s(item.score) + "分"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                          item.passed_status != ""
                            ? _c(
                                "div",
                                {
                                  staticStyle: {
                                    color: "#888",
                                    "font-size": "12px",
                                  },
                                },
                                [_vm._v("考试时间：" + _vm._s(item.end_time))]
                              )
                            : _vm._e(),
                        ]),
                        item.passed_status != ""
                          ? _c("img", {
                              staticClass: "exam-icon",
                              attrs: {
                                src:
                                  item.passed_status == "passed"
                                    ? require("../../assets/images/examYes.png")
                                    : require("../../assets/images/examNo.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                        _c(
                          "a-button",
                          {
                            style: {
                              width: "90px",
                              background: _vm.$store.state.themeColor,
                              color: "#fff",
                              border: "none",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.seeExam(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ],
                      1
                    )
                  }),
                  _vm.ext.is_retake == 1
                    ? _c(
                        "div",
                        { staticClass: "exam-btn-wrap" },
                        [
                          _c(
                            "a-button",
                            {
                              style: {
                                width: "160px",
                                height: "44px",
                                background: _vm.class_isFinish
                                  ? "#ccc"
                                  : _vm.$store.state.themeColor,
                                color: "#fff",
                                border: "none",
                              },
                              attrs: { disabled: _vm.class_isFinish == 1 },
                              on: { click: _vm.start },
                            },
                            [_vm._v("开始考试")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.exam_type == 1
            ? _c("div", { staticClass: "exam-start-wrap" }, [
                _c("div", { staticClass: "exam-top" }, [
                  _c("img", {
                    staticClass: "warn-icon",
                    attrs: {
                      src: require("../../assets/images/warn.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [
                    _vm._v(
                      "温馨提示：作答过程中，请严格遵守考试制度，一切作弊行为会被记录在考试记录中，请大家认真对待"
                    ),
                  ]),
                ]),
                _vm.limit_time_format
                  ? _c("div", { staticClass: "start-txt" }, [
                      _vm._v("本次考试作答时间为 "),
                      _c("span", { staticStyle: { color: "#c00" } }, [
                        _vm._v(_vm._s(_vm.limit_time_format)),
                      ]),
                      _vm._v(" 分钟。"),
                    ])
                  : _vm._e(),
                !_vm.limit_time_format
                  ? _c("p", { staticClass: "start-txt" }, [
                      _vm._v("本次考试不限时长。"),
                    ])
                  : _vm._e(),
                _c("p", { staticClass: "start-txt" }, [
                  _vm._v(
                    "请各位学员尽量保持网络环境稳定，网络通畅，以便更好的作答。"
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "start-do-wrap" },
                  [
                    _c(
                      "a-button",
                      {
                        staticStyle: { "font-size": "14px", width: "90px" },
                        attrs: { size: "large" },
                        on: { click: _vm.startCancel },
                      },
                      [_vm._v("取消")]
                    ),
                    _c(
                      "a-button",
                      {
                        style: {
                          background: _vm.class_isFinish
                            ? "#ccc"
                            : _vm.$store.state.themeColor,
                          color: "#fff",
                          marginLeft: "30px",
                          fontSize: "14px",
                          width: "90px",

                          border: "none",
                        },
                        attrs: {
                          size: "large",
                          disabled: _vm.class_isFinish == 1,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.start()
                          },
                        },
                      },
                      [_vm._v("开始考试")]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-class-all" }, [
      _c("span", { staticClass: "icon" }),
      _c("h3", [_vm._v("在线考试")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty" }, [
      _c("img", {
        attrs: { src: require("../../assets/images/weipingjia.png"), alt: "" },
      }),
      _c("span", [_vm._v("暂未设置考试~")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }