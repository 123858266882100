<template>
    <div class="examination" ref="ImageContent" >
      
        <div class="subject-top inner" >
          <div class="top-title">
            <h3>{{info.name}}</h3>
            <p>{{info.description}}</p>
            <div class="info_text"><span>考生姓名: {{realname}}</span><span>身份证号: {{idcard}}</span><span>考试时间: {{endTime}}</span></div>
            <span class="texts">考试企业: {{$store.state.tenant_name}}</span>
          </div>
          <div class="top-time" v-if="is_finish_exam == 0">
            <h3>剩余考试时间</h3>
            <span v-if="info.is_limited == 1">{{minute}} : {{second}}</span>
            <span v-if="info.is_limited == 0">不限制</span>
          </div>
          <div style="
          position: relative;
          left: 52px;
          bottom: 13px;
              ">
            <a-button
                v-if="is_finish_exam != 0"
                @click=" exportPdf('#pdf')" 
                size="large" 
                :style="{
                background: $store.state.themeColor,
                color: '#fff',
                border: 'none',
                width: '80px',
                marginTop: '30px',
                fontSize:'14px'
                }">导出试卷</a-button>
          </div>
          <div class="top-score" v-if="is_finish_exam == 1">
             
            <img style="position: absolute;top: 5px;left: -90px; width: 72px; height: 63px;" crossOrigin="anonymous" v-if="info.passed_status != ''" class="exam-icon" :src="info.passed_status == 'passed'? require('../../assets/images/examYes.png')+'?any_string_is_ok': require('../../assets/images/examNo.png?any_string_is_ok')+'?any_string_is_ok'" alt="">
            <h3>本次考试成绩</h3>
            <span>{{test_result.score}}</span>
          </div>
        </div>
        <div class="subject-content inner">
            <div class="subject" ref="subjects" :class="{ 'subject_active': subjectActive }">
                <div class="subject-child" :class="{ 'subject-child_active': subjectActive }">
                  <div class="question" v-for="(val, key, i) in exams" :key="i">
                    <!-- titleNumberMapping(i+1)+'，'+ -->
                    <h3 v-if="val.length > 0">{{titleMapping(key)+'（共'+ val.length +' 道）'}}</h3>
                    <div class="qes-item" :id="anchorMapping(key)+(is+1)" v-for="(item, is) in val" :key="is">
                      <p class="qes-title">{{is+1+'，'+ escape2Html(item.question.stem)}}</p>
                      <div class="answers-wrap">
                        <div class="answers">
                          <p
                          @click="chooseAnswer(item, items, key, iss)"
                          :class="{selector: key == 'single_choice' || key == 'determine'? item.check_question_id == item.id + '_' + item.question_id + '_' + iss: key == 'multy_choice'? items.is_choiced: ''}"
                          v-for="(items, iss) in item.question.choices"
                          :key="iss"
                          >{{items.item}}</p>
                        </div>
                        <p class="score-item">本题：{{item.score}}分</p>
                      </div>
                      <div class="answer-content" v-if="is_finish_exam == 1 && info.show_answer == 1">
                        <div style="margin-right: 30px;">
                          <span style="color: #555; font-weight: bold;">正确答案：</span>
                          <span style="color: #52c41a; font-weight: bold;">{{iChoiced(item.question.answer)}}</span>
                        </div>
                        <div>
                          <span style="color: #555; font-weight: bold;">你的答案：</span>
                          <span :style="{color: item.item_passed_status == 'right'? '#52c41a': '#c00', fontWeight: 'bold'}">{{resChecked(item.question.choices)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="subject-number inner">
                <p class="number-title">作答情况</p>
                <div class="unit-item">
                    <h3 v-if="exams.single_choice.length > 0">单选题</h3>
                    <div class="unit" v-if="is_finish_exam == 0">
                        <a :class="{selector: item.check_question_id}" v-for="(item, i) in exams.single_choice" :key="i" :href="'#s'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 0">
                        <a :class="{selector: item.check_question_id}" v-for="(item, i) in exams.single_choice" :key="i" :href="'#s'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 1">
                        <a :class="item.item_passed_status == 'right'? 'selector-suc': 'selector-err'" v-for="(item, i) in exams.single_choice" :key="i" :href="'#s'+(i+1)">{{i+1}}</a>
                    </div>
                </div>
                <div class="unit-item">
                    <h3 v-if="exams.multy_choice.length > 0">多选题</h3>
                    <div class="unit" v-if="is_finish_exam == 0">
                        <a :class="{selector: multy_is_choice(item.question.choices)}" v-for="(item, i) in exams.multy_choice" :key="i" :href="'#m'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 0">
                        <a :class="{selector: multy_is_choice(item.question.choices)}" v-for="(item, i) in exams.multy_choice" :key="i" :href="'#m'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 1">
                        <a :class="item.item_passed_status == 'right'? 'selector-suc': 'selector-err'" v-for="(item, i) in exams.multy_choice" :key="i" :href="'#m'+(i+1)">{{i+1}}</a>
                    </div>
                </div>
                <div class="unit-item">
                    <h3 v-if="exams.determine.length > 0">判断题</h3>
                    <div class="unit" v-if="is_finish_exam == 0">
                        <a :class="{selector: item.check_question_id}" v-for="(item, i) in exams.determine" :key="i" :href="'#j'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 0">
                        <a :class="{selector: item.check_question_id}" v-for="(item, i) in exams.determine" :key="i" :href="'#j'+(i+1)">{{i+1}}</a>
                    </div>
                    <div class="unit" v-if="is_finish_exam == 1 && info.show_answer == 1">
                        <a :class="item.item_passed_status == 'right'? 'selector-suc': 'selector-err'" v-for="(item, i) in exams.determine" :key="i" :href="'#j'+(i+1)">{{i+1}}</a>
                    </div>
                </div>
                <a-button
                  v-if="is_finish_exam == 0"
                  @click="submitExam" 
                  size="large" 
                  :style="{
                  background: $store.state.themeColor,
                  color: '#fff',
                  border: 'none',
                  width: '120px',
                  marginTop: '30px'
                  }">提交试卷</a-button>
                  <a-button
                  v-if="is_finish_exam == 1"
                  @click="examBack" 
                  size="large" 
                  :style="{
                  background: $store.state.themeColor,
                  color: '#fff',
                  border: 'none',
                  width: '120px',
                  marginTop: '30px'
                  }">返回</a-button>
               
                 
            </div>
        </div>
        
        <a-modal v-model="visible" :title="null" :closable="closable" :footer="null" :maskClosable="false">
          <div class="module-content">
            <div class="msg-wrap" v-if="!timeOver">
              <h3 v-if="answerNum == 0">确定要提交试卷吗？</h3>
              <h3 v-if="answerNum > 0">还有 {{answerNum}} 道题目没有作答，确定提交试卷吗？</h3>
            </div>

            <div class="msg-wrap" v-if="timeOver">
              <h3>考试时间已到，点击【确定】提交试卷并查看成绩</h3>
            </div>
            

              <div v-if="!timeOver">
              <a-button 
              :style="{
                width: '120px'
              }" 
              size="large"
              @click="cancelSubmit">继续做题</a-button>
              <a-button 
              :style="{
                background: $store.state.themeColor,
                color: '#fff',
                border: 'none',
                width: '120px',
                marginLeft: '30px'
              }" 
              size="large"
              @click="okSubmit">提交试卷</a-button>
            </div>
              
            <a-button 
            v-if="timeOver"
            @click="okSubmit"
            :style="{
              background: $store.state.themeColor,
              color: '#fff',
              border: 'none',
              width: '120px',
            }" size="large">确定</a-button>
          </div>
        </a-modal>

      <!-- 限制提交试卷弹框 -->
        <a-modal v-model="notAllow" :title="modelText" :closable="closable" :footer="null" :maskClosable="false">
          <div class="module-content">
            <div class="msg-wrap" v-if="!timeOver||classIsFinish">
              <!-- <p >平台相应相关培训政策要求</p> -->
              <p style="color:red;margin-top:-20px;margin-bottom:10px;">{{notAllowText}}</p>
              <p>请务必保证学习真实性</p>
            </div>

            <div v-if="!timeOver&&!classIsFinish">
              <a-button 
              :style="{
                background: $store.state.themeColor,
                color: '#fff',
                border: 'none',
                width: '120px',
              }" 
              size="large"
              @click="cancelSubmit">继续做题</a-button>
            </div>
            <a-button 
                v-if="classIsFinish"
                @click="goBack"
                :style="{
                  background: $store.state.themeColor,
                  color: '#fff',
                  border: 'none',
                  width: '120px',
                }" size="large">取消
              </a-button>
          
          </div>
        </a-modal>
    </div>
</template>

<script>
import {
    getExams,
    commitResult,
    examReport,
    getScore,
    userInfo
} from '../../http/api'
// import {getPdf} from '../../common/pdf';
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
export default {
  data() {
    return {
      timer: null,
      minute: null,
      second: null,
      info: {},
      exams: [],
      visible: false,
      choicesData: {},
      answerData: {},
      is_finish_exam: null,
      test_result: null,
      answerNum: null,
      timeOver: false,
      closable: true,
      endTime:'',//考试时间
      idcard:'',//身份证号
      realname:'',//姓名
      subjectActive:true,
      notAllowText:'',//限制提前交卷文案
      notAllow:false,//限制提前交卷弹框
      classIsFinish:false,//班级过期--返回按钮
      modelText:'无法提前交卷'
    }
  },
  mounted() {
   
    if(this.$route.query.result_id) {
      sessionStorage.result_id = this.$route.query.result_id
    }
    if(this.$route.query.type){
      this.subjectActive=false;
    }
    this.getScores();
    this.getUserInfo();
    getExams({
        id: this.$route.query.id,
        test_id: this.$route.query.test_id,
        course_type: this.$route.query.course_type,
        result_id: sessionStorage.result_id
    }).then(res => {
      if(res.data.code == 200) {
        this.info = res.data.data.exam
        this.exams = res.data.data.items
        this.is_finish_exam = res.data.data.is_finish_exam
        this.test_result = res.data.data.test_result
        this.minute = this.test_result.dead_line_minute_format
        this.second = this.test_result.dead_line_second_format
        if(this.second == 0 && this.minute == 0) {
          this.timeOver = true
          this.visible = true
          this.closable = false
          clearInterval(this.timer)
        }
        this.exams.single_choice.map((item, i) => {
          item.question.choices.map((items, is) => {
            if(items.is_choiced) {
              item.check_question_id = item.id + '_' + item.question_id + '_' + is

              this.answerData[item.id+'_'+item.question_id] = []
              this.answerData[item.id+'_'+item.question_id].push(is)
            }
          })
        })
        this.exams.multy_choice.map((item, i) => {
          item.question.choices.map((items, is) => {
            if(items.is_choiced) {
              if(!this.answerData[item.id+'_'+item.question_id]) {
                this.answerData[item.id+'_'+item.question_id] = []
                this.answerData[item.id+'_'+item.question_id].push(is)
                this.answerData[item.id+'_'+item.question_id] = this.answerData[item.id+'_'+item.question_id].sort()
              } else {
                this.answerData[item.id+'_'+item.question_id].push(is)
                this.answerData[item.id+'_'+item.question_id] = this.answerData[item.id+'_'+item.question_id].sort()
              }
            }
          })
        })
        this.exams.determine.map((item, i) => {
          item.question.choices.map((items, is) => {
            if(items.is_choiced) {
              item.check_question_id = item.id + '_' + item.question_id + '_' + is
              this.answerData[item.id+'_'+item.question_id] = []
              this.answerData[item.id+'_'+item.question_id].push(is)
            }
          })
        })
      }
    })
    this.countDown()

    // 防作弊
    if(document.hidden !== undefined) {
      document.addEventListener('visibilitychange', () => {
        console.info(document.hidden)
      })
    }
  },
  methods: {
    goBack(){
      this.notAllow = false;
      
    },
    getPdf () {

       const _this = this;

       var element = this.$refs.ImageContent; // 这个dom元素是要导出pdf的div容器
        html2canvas(element, {


          dpi: 172,//导出pdf清晰度
          backgroundColor: "#fff",
          allowTaint: true,
	        useCORS: true,
          width: window.screen.availWidth,
          height:150+this.$refs.subjects.offsetHeight,
          windowWidth: document.body.scrollWidth,
          windowHeight: document.body.scrollHeight,
          x:0,
          y:window.pageYOffset,
          scrollY: 0,
          scrollX: 0, // 支持跨域打印图片

        }).then(function (canvas) {
        let dataURL = canvas.toDataURL("image/png");

        // this.imgUrl = dataURL;
         console.log('canvas',dataURL)
          var contentWidth = canvas.width;

          var contentHeight = canvas.height;

          //一页pdf显示html页面生成的canvas高度;

          var pageHeight = (contentWidth / 592.28) * 841.89;

          //未生成pdf的html页面高度

          var leftHeight = contentHeight;

          //页面偏移

          var position = 0;

          //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高

          var imgWidth = 595.28;

          var imgHeight = (592.28 / contentWidth) * contentHeight;

          var pageData = canvas.toDataURL('image/jpeg', 1.0);

          var pdf = new jsPDF('', 'pt', 'a4');

          //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)

          //当内容未超过pdf一页显示的范围，无需分页

          if (leftHeight < pageHeight) {

            pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);

          } else {

            while (leftHeight > 0) {

              pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);

              leftHeight -= pageHeight;

              position -= 841.89;

              //避免添加空白页

              if (leftHeight > 0) {

                pdf.addPage();

              }

            }

          }

          pdf.save(_this.currentMenu);

          _this.isPdf = true;

        });
    },

    exportPdf() {
      this.isPdf = false;

      this.getPdf()

    },
    async getUserInfo(){
        let that = this;
        await userInfo().then(res => {
          let data = res.data.data;
          this.idcard = data.idcard;
          this.realname = data.realname;
            }).catch(err => {
                that.isToken = false;
                that.$message.error('学员账号登录状态已失效，请登录后重试');
        })
    },
    getScores(){
         getScore({
            result_id:sessionStorage.result_id //this.$route.query.result_id
        }).then(res => {
            if(res.data.code == 200) {
              this.endTime = res.data.data.result.end_time;
            }
            
        }).catch(err => {
          
        })
    },
    escape2Html(str) {
      var arrEntities={'lt':'<','gt':'>','nbsp':' ','amp':'&','quot':'"'}
      return str.replace(/&(lt|gt|nbsp|amp|quot);/ig,function(all,t){return arrEntities[t];})
    },
    examBack() {
      if(this.$route.query.course_type == 'classroom') {
        this.$router.push({
          path: '/class/exam',
          query: {
            id: this.$route.query.id
          }
        })
      } else {
        this.$route.query['index'] = 1
        this.$router.push({
          path: '/course/studydetail',
          query: this.$route.query
        })
      }
      
    },
    submitExam() {
      this.answerNum = (this.exams.single_choice.length + this.exams.multy_choice.length + this.exams.determine.length) - Object.keys(this.answerData).length
      this.visible = true
    },
    okSubmit() {
      commitResult({
        exam_id: this.$route.query.test_id,
        exam: this.answerData
      }).then(res => {
        if(res.data.code == 200) {
          this.$message.success(res.data.message)
          this.visible = false
          sessionStorage.result_id = res.data.data.result_id
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        }else if(res.data.code==201){
          this.notAllow = true;
          this.visible = false;
            this.notAllowText = res.data.message
        } else {
          this.notAllowText = res.data.message
          this.classIsFinish = true
          this.visible = false;
           this.notAllow = true;
           this.modelText = '提示'
          // this.$message.error(res.data.message)
        }
      })
    },
    cancelSubmit() {
      this.visible = false;
      this.notAllow = false;
    },
    removeElement(arr, item) {  
      return arr.filter(function(i){  
          return i!=item
      })
    },
    chooseAnswer(item, items, key, iss) {
      if(this.is_finish_exam == 0) {
        if(key == 'single_choice' || key == 'determine') {
          item.check_question_id = item.id + '_' + item.question_id + '_' + iss
          this.answerData[item.id+'_'+item.question_id] = []
          this.answerData[item.id+'_'+item.question_id].push(iss)
        } else if(key == 'multy_choice') {
          if(items.is_choiced) {
            items.is_choiced = false
            this.answerData[item.id+'_'+item.question_id] = this.removeElement(this.answerData[item.id+'_'+item.question_id], iss)
            if(this.answerData[item.id+'_'+item.question_id].length == 0) {
              delete this.answerData[item.id+'_'+item.question_id]
            }
          } else {
            items.is_choiced = true
            if(!this.answerData[item.id+'_'+item.question_id]) {
              this.answerData[item.id+'_'+item.question_id] = []
              this.answerData[item.id+'_'+item.question_id].push(iss)
              this.answerData[item.id+'_'+item.question_id] = this.answerData[item.id+'_'+item.question_id].sort()
            } else {
              this.answerData[item.id+'_'+item.question_id].push(iss)
              this.answerData[item.id+'_'+item.question_id] = this.answerData[item.id+'_'+item.question_id].sort()
            }
          }
          this.multy_is_choice(item.question.choices)
        }
        this.choicesData = {}
        this.choicesData[item.id+'_'+item.question_id] = this.answerData[item.id+'_'+item.question_id]
        if(!this.choicesData[item.id+'_'+item.question_id]) {
          this.choicesData[item.id+'_'+item.question_id] = []
        }
        
        examReport({
          course_id: this.$route.query.id,
          course_type: this.$route.query.course_type,
          test_id: this.$route.query.test_id,
          choice: this.choicesData
        }).then(res => {
          console.info('上报成功')
        })
      }
    },
    countDown() {
      this.timer = setInterval(() => {
        if(this.second > 0) {
          this.second--
          if(this.second < 10) {
            this.second = '0' + this.second
          }
        } else {
          this.second = 59
          if(this.minute > 0) {
            this.minute--
            if(this.minute < 10) {
              this.minute = '0' + this.minute
            }
          }
        }
        if(this.second == 0 && this.minute == 0) {
          this.timeOver = true
          this.visible = true
          this.closable = false
          clearInterval(this.timer)
        }
      }, 1000)
    },
    // 映射
    multy_is_choice(arr) {
      let flag = false
      arr.map((item, i) => {
        if(item.is_choiced) {
          flag = true
        }
      })
      return flag
    },
    // 映射选项
    iChoiced(param) {
      let str = ''
      param.map((item, i) => {
        if(item == 0) {
          str += 'A'
        }
        if(item == 1) {
          str += 'B'
        }
        if(item == 2) {
          str += 'C'
        }
        if(item == 3) {
          str += 'D'
        }
        if(item == 4) {
          str += 'E'
        }
        if(item == 5) {
          str += 'F'
        }
        if(item == 6) {
          str += 'G'
        }
        if(item == 7) {
          str += 'H'
        }
      })
      return str
    },
    resChecked(param) {
      let str = ''
      param.map((item, i) => {
        if(item.is_choiced) {
          if(i == 0) {
            str += 'A'
          }
          if(i == 1) {
            str += 'B'
          }
          if(i == 2) {
            str += 'C'
          }
          if(i == 3) {
            str += 'D'
          }
          if(i == 4) {
            str += 'E'
          }
          if(i == 5) {
            str += 'F'
          }
          if(i == 6) {
            str += 'G'
          }
          if(i == 7) {
            str += 'H'
          }
        }
      })
      return str
    },
    titleNumberMapping(n) {
      if(n == 1) {
        return '一'
      } else if(n == 2) {
        return '二'
      } else if(n == 3) {
        return '三'
      }
    },
    titleMapping(str) {
      let id = this.$store.state.tenant_idBind;
      if(str == 'single_choice') {
        return '单选题'
      } else if(str == 'multy_choice') {
        return '多选题'
      } else if(str == 'determine') {
        return '判断题'
      }
    },
    anchorMapping(str) {
      if(str == 'single_choice') {
        return 's'
      } else if(str == 'multy_choice') {
        return 'm'
      } else if(str == 'determine') {
        return 'j'
      }
    }
  }
}
</script>

<style scoped >

.examination {
    display: flex;
    flex-direction: column;
    background: #fff;
}
.inner {
    width: 1200px;
    margin: 0 auto;
    background: #f9f9f9;
}
.subject-top {
    height: 150px;
    border: 1px solid #ddd;
    border-bottom: none;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px 0 20px;
    position: relative;
}
.top-title {
  text-align: left;
}
.top-title h3 {
  color: #333;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 10px;
}
.top-title p {
  margin-bottom: 0;
  font-size: 14px;
  color: #888;
}
.top-time h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555;
}
.top-time span {
  display: block;
  font-size: 22px;
  color: #c00;
  line-height: 28px;
}
.top-score {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-score h3 {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
  margin-right: 10px;
  color: #555;
}
.top-score span {
  font-size: 48px;
  color: #c00;
  display: block;
}
.subject-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px 0;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid #ddd;
}
.subject {
    flex: 1;
    height: 100%;
    display: flex;
    padding-left: 20px;
}
.subject_active{
    overflow: auto;

}
.subject::-webkit-scrollbar {
  width: 0;
}
.subject::-webkit-scrollbar-thumb {
  background: #ccc;
}
.subject-child_active{
    height: 0;
}
.subject-child {
    width: 100%;
}
.subject-number {
    width: 315px;
    border-left: 1px solid #ddd;
    box-sizing: content-box;
    padding-left: 10px;
    height: 100%;
}
.subject-number .number-title {
    text-align: left;
    font-size: 16px;
    margin-bottom: 20px;
}
.unit-item {
    margin-bottom: 30px;
}
.unit-item h3 {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}
.unit-item .unit {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.unit-item .unit a {
    width: 35px;
    height: 28px;
    border: 1px solid #777;
    border-radius: 2px;
    margin: 0 10px 10px 0;
    color: #555;
    line-height: 28px;
    text-align: center;
}
.selector {
  background: rgb(8, 128, 241)!important;
  color: #fff!important;
  /* border: none!important; */
}
.selector-suc {
  background: #52c41a;
  color: #fff!important;
  /* border: none!important; */
}
.selector-err {
  background: #c00;
  color: #fff!important;
  /* border: none!important; */
}
.errColor {
  background: #ff4d4f;
  color: #fff!important;
  border: none!important;
}
.sucColor {
  background: #52c41a;
  color: #fff!important;
  border: none!important;
}
.question h3 {
  font-size: 16px;
  font-weight: bold;
  color: #555;
  margin-bottom: 20px;
  text-align: left;
}
.qes-item {
  padding-left: 30px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ddd;
}
.qes-item .score-item {
  color: #888;
}
.qes-title {
  font-size: 14px;
  color: #555;
  font-weight: bold;
  margin-bottom: 10px;
  text-align: left;
}
.answers p {
  font-size: 14px;
  color: #555;
  padding: 8px;
  margin: 5px 0;
  text-align: left;
  cursor: pointer;
  max-width: 400px;
  border-radius: 6px;
  line-height: 18px;
  min-width: 120px;
  transition: all .2s;
}
.answers p:hover {
  background: #ddd;
}
.answers-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-right: 20px;
  padding-bottom: 20px;
}
.module-content {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.module-content h3 {
  color: #555;
  font-weight: bold;
}
.module-content .msg-wrap {
  margin-bottom: 30px;
}
.module-content .msg-wrap p{
  font-size: 18px;
  line-height: 28px;
  text-align: center;

}
.answer-content {
  margin-right: 20px;
  background: #eee;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  padding-left: 10px;
}
.info_text{
  padding-top: 10px;
}
.info_text span{
   font-size: 12px;
   padding-right: 30px;
   color: #888;
   overflow:hidden;
  white-space:nowrap;
  text-overflow:ellipsis;
  display: inline-block;
}
.texts{
   font-size: 12px;
   color: #888;
}
</style>
