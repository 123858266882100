let skinLayouts=[
    {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
     {
       name: "H5Loading", align: "cc"
     },
     {name: "errorDisplay", align: "tlabs", x: 0, y: 0},
     {name: "infoDisplay"},
     {name:"tooltip", align:"blabs",x: 0, y: 56},
     {name: "thumbnail"},
     {
       name: "controlBar", align: "blabs", x: 0, y: 0,
       children: [
         {name: "progress", align: "blabs", x: 0, y: 44},
         {name: "playButton", align: "tl", x: 15, y: 12},
         {name: "timeDisplay", align: "tl", x: 10, y: 7},
         {name: "fullScreenButton", align: "tr", x: 10, y: 12},
         {name:"subtitle", align:"tr",x:15, y:12},
         {name:"setting", align:"tr",x:15, y:12},
         {name: "volume", align: "tr", x: 5, y: 10}
       ]
     }
   ]
let skinLayout=[                   
    {name: "bigPlayButton", align: "blabs", x: 30, y: 80},
    {
      name: "H5Loading", align: "cc"
    },
    {
      name: "controlBar", align: "blabs", x: 0, y: 0,
      children: [
        //{name: "progress", align: "tlabs", x: 0, y: 0},
        {name: "playButton", align: "tl", x: 15, y: 26},
        {name: "timeDisplay", align: "tl", x: 10, y: 24},
        {name: "fullScreenButton", align: "tr", x: 20, y: 25},
        {name: "volume", align: "tr", x: 20, y: 25},
      ]
    }
  ];
class Player {
    constructor(isLive) {
        this.isLive = isLive
    }
    init(url, isSkinLayout,cover) {
      console.log('---isSkinLayout--',isSkinLayout)
        return new Aliplayer({
            id: "player-con",
            source: url,
            cover: cover,
            width: "100%",
            height: "500px",
            autoplay: true,
            isLive: this.isLive,
            rePlay: false,
            playsinline: true,
            preload: true,
            controlBarVisibility: "always",
            useH5Prism: true,
            skinLayout: isSkinLayout=='finished'||isSkinLayout=='tryWatch'?skinLayouts:skinLayout
        })
    }
}

export default Player