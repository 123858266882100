<template>
    <div class="lecturer">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>讲师团队</h3>
        </div>
        <div class="empty" v-if="authors.length == 0">
            <img src="../../assets/images/weipingjia.png" alt="">
            <span>暂无数据~</span>
        </div>
        <ul v-if="authors.length > 0">
            <li class="teacher-item" v-for="(item, i) in authors" :key="i">
                <div class="detail">
                    <img :src="item.author_image" alt="">
                    <div class="text-info">
                        <h3>{{item.name}}</h3>
                        <p>{{item.belong_subject}}</p>
                    </div>
                </div>
                <p class="infos">{{item.infos}}</p>
            </li>
        </ul>
    </div>
</template>

<script>
import {
    classExam
} from '../../http/api'
export default {
    props: {
        authors: Array
    },
    data() {
        return {
            class_id: ''
        }
    },
    created() {
        this.class_id = this.$route.query.id
    }
}
</script>

<style scoped>
.teacher-item {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
}
.teacher-item img {
    width: 70px;
    height: 70px;
    border-radius: 35px;
    margin-right: 15px;
}
.teacher-item .detail {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.teacher-item .text-info {
    flex: 1;
}
.teacher-item .text-info h3 {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}
.teacher-item .text-info p {
    font-size: 14px;
    color: #666;
    text-align: left;
}
.teacher-item .infos {
    width: 50%;
    text-align: left;
    font-size: 14px;
}
.empty {
  padding: 50px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
</style>