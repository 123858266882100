var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "face" }, [
    _vm.support
      ? _c(
          "div",
          [
            _c("div", { staticClass: "face-wrap" }, [
              _c("video", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.show,
                    expression: "!show",
                  },
                ],
                attrs: {
                  id: "myVideo",
                  preload: "",
                  autoplay: "",
                  loop: "",
                  muted: "",
                  playsinline: "",
                  "webkit-playsinline": "true",
                },
                domProps: { muted: true },
              }),
              _c("canvas", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.show,
                    expression: "show",
                  },
                ],
                attrs: { id: "canvas", width: "220", height: "220" },
              }),
            ]),
            _c("div", { staticClass: "face-txt" }, [
              _c(
                "p",
                {
                  staticClass: "status-txt",
                  style: { background: _vm.active },
                },
                [_vm._v(_vm._s(_vm.statusTxt))]
              ),
              _c("p", [_vm._v("请确保摄像头光线充足，并保持人脸在圆圈中。")]),
            ]),
            _c(
              "a-spin",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { tip: "正在检测..." },
              },
              [
                _c("a-icon", {
                  staticStyle: { "font-size": "24px" },
                  attrs: { slot: "indicator", type: "loading", spin: "" },
                  slot: "indicator",
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.again,
                    expression: "again",
                  },
                ],
              },
              [
                _c(
                  "a-button",
                  {
                    style: {
                      background: _vm.$store.state.themeColor,
                      fontSize: "14px",
                    },
                    attrs: { block: "", size: "large", type: "primary" },
                    on: { click: _vm.openCamera },
                  },
                  [_vm._v("重新认证")]
                ),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
    !_vm.support
      ? _c(
          "div",
          [
            _c("div", { staticClass: "face-wrap" }, [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isTake,
                    expression: "!isTake",
                  },
                ],
                staticClass: "photo-img",
                staticStyle: { background: "#333" },
              }),
              _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isTake,
                    expression: "isTake",
                  },
                ],
                staticClass: "photo-img",
                attrs: { src: _vm.url, id: "photo-img", alt: "" },
              }),
            ]),
            _c(
              "a-spin",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.loading,
                    expression: "loading",
                  },
                ],
                attrs: { tip: "正在检测..." },
              },
              [
                _c("a-icon", {
                  staticStyle: { "font-size": "24px" },
                  attrs: { slot: "indicator", type: "loading", spin: "" },
                  slot: "indicator",
                }),
              ],
              1
            ),
            _c("div", { staticClass: "face-txt" }, [
              _c(
                "p",
                {
                  staticClass: "status-txt",
                  style: { background: _vm.active },
                },
                [_vm._v(_vm._s(_vm.statusTxt))]
              ),
              _c("p", [_vm._v("IOS14以下版本请自行拍照上传图片进行验证。")]),
            ]),
            _c("div", [
              _c("label", { attrs: { id: "file-btn", for: "myfile" } }, [
                _vm._v("拍照"),
              ]),
              _c("input", {
                attrs: {
                  id: "myfile",
                  type: "file",
                  name: "file",
                  accept: "image/*",
                  capture: "camera",
                },
                on: { change: _vm.change },
              }),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }