var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "bg" }, [
        _c(
          "div",
          { staticClass: "inner" },
          [
            _c("h3", { staticClass: "bg-title" }, [_vm._v("爱迪产品矩阵")]),
            _c("p", [
              _vm._v(
                "北京爱迪科森教育科技股份有限公司致力于网络学习平台的开发和课程资源的整合制作，是集高等教育、干部教育、社会教育、幼儿教育于一体的综合学习平台解决方案提供商。"
              ),
            ]),
            _c(
              "a-button",
              {
                staticClass: "btn",
                on: {
                  click: function ($event) {
                    return _vm.open()
                  },
                },
              },
              [_vm._v("业务咨询")]
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "menu-inner" }, [
          _c("h3", { staticClass: "title" }, [_vm._v("构建教育培训新生态")]),
          _c(
            "ul",
            { staticClass: "menu" },
            _vm._l(_vm.menu, function (item, i) {
              return _c(
                "li",
                {
                  key: i,
                  class: _vm.index == i ? "active-border" : "",
                  on: {
                    click: function ($event) {
                      return _vm.check(item, i)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(item.name) + " ")]
              )
            }),
            0
          ),
          _c("div", { staticClass: "menu-content" }, [
            _c("div", { staticClass: "left" }, [
              _c("img", { attrs: { src: _vm.src, alt: "" } }),
            ]),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.name))]),
                _c("p", [_vm._v(_vm._s(_vm.info))]),
                _c(
                  "a-button",
                  { staticClass: "btn", on: { click: _vm.more } },
                  [_vm._v("了解更多")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "a-modal",
        {
          attrs: { width: "400px", footer: null, title: "联系我们" },
          on: { ok: _vm.handleOk },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("img", {
            attrs: { src: require("../../assets/images/adCode.png"), alt: "" },
          }),
          _c(
            "p",
            { staticStyle: { "text-align": "center", "font-weight": "bold" } },
            [_vm._v("相关业务咨询或合作，请添加爱迪科森官方微信公众号")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }