<template>
    <div class="guide">
        <img class="img_a" v-if="$store.state.template != 3" :src="$store.state.wechat_share_img" alt="">
        <img class="img_a" v-if="$store.state.template == 3 || $store.state.hostname == $store.state.demonstrationHostname" src="../../assets/images/xmobilebg.png" alt="">
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    created() {

    },
    beforeDestroy() {
        if(navigator.userAgent.indexOf('Android') != -1 || navigator.userAgent.indexOf('iPhone') != -1) {
            this.$router.push({
                path: '/guidepage'
            })
        }
    }
}
</script>

<style scoped>
.guide {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.guide img {
    object-fit: cover;
}
</style>