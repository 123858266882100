<template>
    <div>
        <div class="xcert">
            <div class="bg">
                <div class="inner">
                    <h3 class="bg-title">课证融通</h3>
                    <p style="color: #fff;">保障毕业证书与职业技能等级证书之间的有机衔接，提升高素质技术技能型人才的培养质量</p>
                </div>
            </div>
            <div class="top-bar">
                <div class="form-item">
                    <label for="">分类</label>
                    <a-select default-value="请选择" style="width: 120px" @change="handleChange">
                        <a-select-option value="全部">
                            全部
                        </a-select-option>
                    </a-select>
                </div>
                <div class="form-item">
                    <label for="">学院</label>
                    <a-select default-value="请选择" style="width: 120px" @change="handleChange2">
                        <a-select-option value="全部">
                            全部
                        </a-select-option>
                    </a-select>
                </div>
                <div class="form-item">
                    <label for="">内容搜索</label>
                    <a-input placeholder="请输入关键词" />
                </div>
                <div class="form-item">
                    <a-button class="btns">查询</a-button>
                </div>
            </div>
            <h3 class="title">本校已开展项目</h3>
            <div class="cert-content">
                <div class="cert-item" v-for="(item, i) in list" :key="i" @click="go(item, i)">
                    <div class="top" :style="{background: 'url('+item.bg+') no-repeat'}">
                        <h3>{{item.title}}</h3>
                        <div class="btn-wrap">
                            <a-button class="btn">线上</a-button>
                            <a-button class="btn">线下</a-button>
                            <a-button class="btn">积分转换</a-button>
                        </div>
                        <div class="cert-img">
                            <img :src="item.src" alt="">
                            <img class="mask" :src="require('../../assets/images/certMask.png')" alt="">
                        </div>
                    </div>
                    <div class="bot">
                        <p>证书等级：<span>{{item.sub1}}</span></p>
                        <p>职业技能：<span>{{item.sub2}}</span></p>
                        <p>就业方向：<span>{{item.sub3}}</span></p>
                        <p>培训组织：<span>{{item.sub4}}</span></p>
                        <img class="cert-icon" :src="require('../../assets/images/cert-r-icon.png')" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [
                {
                    id: 1,
                    src: require("../../assets/images/cert1x.jpg"),
                    title: '区块链数据治理与维护职业等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '网络部署、智能合约开发、应用开发及测试等',
                    sub3: '运维工程师、开发工程师、测试工程师等',
                    sub4: '西安纸贵互联网科枝有限公司',
                    url: 'http://qkl.zjttv.cn/',
                    bg: require('../../assets/images/cbg1.jpg')
                },
                {
                    id: 2,
                    src: require("../../assets/images/cert2x.jpg"),
                    title: '数字创意建模职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '三维模型制作、贴图制作、渲染等',
                    sub3: '数字媒体、影视 、室内设计等',
                    sub4: '浙江中科视传科技有限公司',
                    url: 'http://szcy.zjttv.cn/',
                    bg: require('../../assets/images/cbg2.jpg')
                },
                {
                    id: 3,
                    src: require("../../assets/images/cert3x.jpg"),
                    title: '数据中心IT系统运维管理',
                    sub1: '初级、中级、高级',
                    sub2: '基础网络环境建设、云平台环境部署、数据中心安全域管理等',
                    sub3: '数据中心 IT 系统运维管理、数据仓库管理、大数据平台运维等',
                    sub4: '国富瑞数据系统有限公司',
                    url: 'http://sjzx.zjttv.cn/',
                    bg: require('../../assets/images/cbg3.jpg')
                },
                {
                    id: 4,
                    src: require("../../assets/images/cert4x.jpg"),
                    title: '商务流程自动化机器人应用',
                    sub1: '初级、中级、高级',
                    sub2: '业务数据批量处理、人力资源管理流程、商务流程自动化机器人的高级应用和开发能力等',
                    sub3: '企业财务共享中心、自动化项目开发专员、自动化方案架构师等',
                    sub4: '成都康德世纪教育科技集团有限公司',
                    url: 'http://zdh.zjttv.cn/',
                    bg: require('../../assets/images/cbg4.jpg')
                },
                {
                    id: 5,
                    src: require("../../assets/images/cert5x.jpg"),
                    title: '大数据应用部署与调优',
                    sub1: '初级、中级、高级',
                    sub2: '独立完成应用开发环境配置与部署、掌握SQL语法和Python编程语言等',
                    sub3: '运维工程师、测试工程师、培训讲师等',
                    sub4: '南京云创大数据科技股份有限公司',
                    url: 'http://dsj.zjttv.cn/',
                    bg: require('../../assets/images/cbg5.jpg')
                },
                {
                    id: 6,
                    src: require("../../assets/images/cert6x.jpg"),
                    title: '器乐艺术指导',
                    sub1: '初级、中级、高级',
                    sub2: '具备器乐艺术作品模仿及编创能力、具备能够独立分析及编创器乐艺术作品的能力等',
                    sub3: '音乐老师、幼儿教师、制作总监等',
                    sub4: '新华国采教育网络科技有限责任公司',
                    url: 'http://yszd.zjttv.cn/',
                    bg: require('../../assets/images/cbg6.jpg')
                },
                {
                    id: 7,
                    src: require("../../assets/images/cert7x.png"),
                    title: '5G移动网络运维',
                    sub1: '初级、中级、高级',
                    sub2: '预算编制、工程制图、覆盖规划等',
                    sub3: '站点工程、通信设备安装与调测、通信网络维护及优化等工作岗位',
                    sub4: '北京华晟经世信息技术股份有限公司',
                    url: 'https://5gyw.zjttv.cn/',
                    bg: require('../../assets/images/cbg1.jpg')
                },
                {
                    id: 8,
                    src: require("../../assets/images/cert6x.jpg"),
                    title: '光宽带网络建设',
                    sub1: '初级、中级、高级',
                    sub2: '网络规划设计、项目文件审核、特殊场景网络分析与处理等',
                    sub3: '光宽带网络设计、工程实施、项目维护等工作岗位',
                    sub4: '北京华晟经世信息技术股份有限公司',
                    url: 'https://kdjs.zjttv.cn/',
                    bg: require('../../assets/images/cbg2.jpg')
                },
                {
                    id: 9,
                    src: require("../../assets/images/cert9x.png"),
                    title: '特种机器人操作与运维',
                    sub1: '初级、中级、高级',
                    sub2: '应用企业的操作技师、特种机器人原理图绘制、机器人参数设置',
                    sub3: '机器人操作员、特种设备维护工程师、特种设备研发工程师',
                    sub4: '徐州鑫科机器人有限公司',
                    url: 'https://jqrcz.zjttv.cn/',
                    bg: require('../../assets/images/cbg3.jpg')
                },
                {
                    id: 10,
                    src: require("../../assets/images/cert6x.jpg"),
                    title: '工业互联网',
                    sub1: '初级、中级、高级',
                    sub2: '平台应用系统集成企业、数据采集设备部署和连接、工业数据采集设备安装',
                    sub3: '工业现场数据上云实施与运维、云平台算法建模应用、工业数据边缘处理应用、',
                    sub4: '江苏徐工信息集团',
                    url: 'https://gyhlw.zjttv.cn/',
                    bg: require('../../assets/images/cbg4.jpg')
                },
                {
                    id: 11,
                    src: require("../../assets/images/cert11x.png"),
                    title: '金融智能投顾职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '结合人工智能、大数据等技术的智能投顾平台，制定出更加符合各方需求的科学理财规划和资产配置方案',
                    sub3: '金融服务专业领域的资产配置、理财顾问、金融顾问等岗位',
                    sub4: '金智东博（北京）教育科技股份有限公司',
                    url: 'https://jrtg.zjttv.cn/',
                    bg: require('../../assets/images/cbg1.jpg')
                },
                {
                    id: 12,
                    src: require("../../assets/images/cert12x.png"),
                    title: '网络直播运营职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '内容运营、直播运营、流量运营、账号（IP）运营四个维度的职业技能',
                    sub3: '直播执行、直播效果负责人、项目高级经理等岗位',
                    sub4: '中广协广告信息文化传播有限责任公司',
                    url: 'https://jrtg.zjttv.cn/',
                    bg: require('../../assets/images/cbg2.jpg')
                },
                {
                    id: 13,
                    src: require("../../assets/images/cert13x.png"),
                    title: '老年照护职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '面向老年服务与管理、老年保健与管理、护理（老年护理）专业',
                    sub3: '对应养老护理岗位，包括养老护理员、养老护理主管、养老护理部主任等',
                    sub4: '北京中福长者文化科技有限公司',
                    url: 'https://lnzh.zjttv.cn/',
                    bg: require('../../assets/images/cbg3.jpg')
                },
                {
                    id: 14,
                    src: require("../../assets/images/cert14x.png"),
                    title: '无人机驾驶职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '无人机的检查与维护、根据飞行任务需要装配无人机整体系统以及任务载荷，并完成系统整体调试，设计作业方案及应急处置预案',
                    sub3: '操控与运行管理岗位、无人机的操控、任务管理与指挥以及机型试飞岗位',
                    sub4: '北京优云智翔航空科技有限公司',
                    url: 'https://wrjjs.zjttv.cn/',
                    bg: require('../../assets/images/cbg4.jpg')
                },
                {
                    id: 15,
                    src: require("../../assets/images/cert15x.png"),
                    title: '无人机检测与维护职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '后安全监控与检测工作、无人机机载电子设备进行拆装和维护维修以及飞行/载荷控制线路施工、对无人机装载、运输以及任务设备进行保养',
                    sub3: '特殊环境（如地质灾害现场、火情现场等）等多种运行场景下的无人机系统检测、调试、维护、维修工作、从事保障运行与任务执行所需的装配调整及航前航后检查工作',
                    sub4: '北京优云智翔航空科技有限公司',
                    url: 'https://wrjjw.zjttv.cn/',
                    bg: require('../../assets/images/cbg5.jpg')
                },
                {
                    id: 16,
                    src: require("../../assets/images/cert16x.png"),
                    title: '跨境电商B2B数据运营职业技能等级证书',
                    sub1: '初级、中级、高级',
                    sub2: '跨境电商全网营销、电商平台店铺运营、跨境交易履约、海外社交媒体营销等工作',
                    sub3: '跨境电子商务应用和服务企业，跨境电商平台运营、跨境电商全网营销和跨境电商销售等岗位',
                    sub4: '阿里巴巴（中国）教育科技有限公司',
                    url: 'https://kjdsB2B_sjyy.zjttv.cn/',
                    bg: require('../../assets/images/cbg6.jpg')
                },
            ]
        }
    },
    methods: {
        go(item, i) {
            window.open(item.url)
        },
        handleChange() {},
        handleChange2() {}
    }
}
</script>

<style scoped>
.top-bar {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #D0D7E1;
    height: 92px;
    width: 1200px;
    margin: 0 auto;
    background: #fff;
}
.form-item {
    display: flex;
    align-items: center;
    margin-right: 50px;
}
.form-item label {
    min-width: 60px;
    font-size: 12px;
    color: #333;
}
.btns {
    background: #0066CC;
    color: #fff;
    border-radius: 8px;
    border: none;
}
.xcert {
    background: #F6F8FB;
    height: 100%;
}
.bg {
    height: 150px;
    margin-bottom: 30px;
    background: url('../../assets/images/bg-title-1x.png') no-repeat;
    background-size: cover;
}
.bg-title {
    font-size: 38px;
    font-weight: bold;
    background-image: -webkit-linear-gradient(top, #fff, #eee, #ccc);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
    margin-bottom: 10px;
}
.title {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    text-align: left;
    width: 1200px;
    margin: 0 auto;
    background: #fff;
    padding: 30px 20px;
}
.inner {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
}
.cert-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 1200px;
    margin: 0 auto 20px;
    background: #fff;
    padding: 0 20px;
}
.cert-item {
    width: 570px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    border: 1px solid #eee;
    transition: all .2s;
}
.cert-item:hover {
    transform: translateY(-5px);
}
.cert-item:nth-child(2n) {
    margin-right: 0;
}
.top {
    width: 100%;
    height: 130px;
    background-size: cover!important;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    padding: 0 20px;
    position: relative;
}
.top h3 {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 60%;
    text-align: left;
}
.btn {
    height: 20px;
    line-height: 20px;
    border-radius: 6px;
    color: #eee;
    border: 1px solid #eee;
    background: none;
    padding: 0 10px;
    margin-right: 10px;
    font-size: 12px;
    line-height: 18px;
}
.cert-img {
    width: 180px;
    height: 130px;
    border-radius: 5px;
    background: #fff;
    padding: 5px;
    box-sizing: content-box;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 9999;
    box-shadow: 0px 5px 10px 0px rgba(7, 0, 2, 0.2);
}
.mask {
    position: absolute;
    right: 0;
    top: 0;
    width: 90px;
    height: 140px;
}
.bot {
    height: 125px;
    background: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}
.bot p {
    margin-bottom: 0;
    line-height: 16px;
    font-size: 12px;
    color: #999;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.bot p span {
    color: #000;
}
.cert-icon {
    background: #0066CC;
    width: 30px;
    border-radius: 15px;
    height: 30px;
    position: absolute;
    bottom: 20px;
    right: 20px;
}
</style>