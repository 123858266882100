<template>
    <div class="face">
        <div v-if="support">
            <div class="face-wrap">
                <video v-show="!show" id="myVideo" preload autoplay loop muted playsinline webkit-playsinline="true"></video>
                <canvas v-show="show" id="canvas" width="220" height="220" ></canvas>
            </div>
            <div class="face-txt">
                <p class="status-txt" :style="{background: active}">{{statusTxt}}</p>
                <p>请确保摄像头光线充足，并保持人脸在圆圈中。</p>
            </div>
            <a-spin tip="正在检测..." v-show="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
            </a-spin>

            <div v-show="again">
                <a-button block size="large" @click="openCamera" type="primary" :style="{background: $store.state.themeColor, fontSize: '14px'}">重新认证</a-button>
            </div>
        </div>

        <div v-if="!support">
            <div class="face-wrap">
                <div style="background: #333;" v-show="!isTake" class="photo-img"></div>
                <img v-show="isTake" :src="url" class="photo-img" id="photo-img" alt="">
            </div>
            <a-spin tip="正在检测..." v-show="loading">
                <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
            </a-spin>
            <div class="face-txt">
                <p class="status-txt" :style="{background: active}">{{statusTxt}}</p>
                <p>IOS14以下版本请自行拍照上传图片进行验证。</p>
            </div>
            
            <div>
                <label id="file-btn" for="myfile">拍照</label>
                <input id="myfile" type="file" name="file" accept="image/*" capture="camera" @change="change">
            </div>
        </div>
            
        
    </div>
</template>

<script>
import '@/assets/js/tracking-min'
import '@/assets/js/face-min'
import axios from 'axios'
export default {
    data() {
        return {
            support: false,
            isTake: false,
            mediaStreamTrack: null,
            trackerTask: '',
            url: '',
            imgData: '',
            show: '',
            statusTxt: '',
            number: '',
            timer: '',
            active: '',
            faceType: '',
            token: '',
            accessToken: '',
            loading: false,
            again: false,
            mobile: '',
            idcard: '',
            realname: '',
            union_id: '',
            open_id: '',
            sms_code: '',
            password: '',
            bind: '',
            department_id: '',
            certification: '',
            res: '',
            id: '',
            course_type: '',
            lessonId: '',
            playTime: '',
            return_url: ''
        }
    },
    mounted() {
        let param = window.location.search
        // console.info(param)
        this.faceType = decodeURIComponent(param.split('&')[0].split('=')[1])
        if(this.faceType == 0) {
            // 注册
            this.mobile = param.split('&')[1].split('=')[1]
            this.idcard = param.split('&')[2].split('=')[1]
            this.realname = decodeURIComponent(param.split('&')[3].split('=')[1])
            this.union_id = param.split('&')[4].split('=')[1]
            this.open_id = param.split('&')[5].split('=')[1]
            this.sms_code = param.split('&')[6].split('=')[1]
            this.password = param.split('&')[7].split('=')[1]
            this.bind = param.split('&')[8].split('=')[1]
            this.department_id = param.split('&')[9].split('=')[1]
            this.accessToken = decodeURIComponent(param.split('&')[10].split('=')[1])
        } else if(this.faceType == 1) {
            // 登录
            this.token = decodeURIComponent(param.split('&')[1].split('=')[1])
            this.accessToken = decodeURIComponent(param.split('&')[2].split('=')[1])
            this.realname = decodeURIComponent(param.split('&')[3].split('=')[1])
            this.idcard = param.split('&')[4].split('=')[1]
            this.certification = param.split('&')[5].split('=')[1]
        } else if(this.faceType == 2) {
            this.token = decodeURIComponent(param.split('&')[1].split('=')[1])
            this.accessToken = decodeURIComponent(param.split('&')[2].split('=')[1])
            this.realname = decodeURIComponent(param.split('&')[3].split('=')[1])
            this.idcard = param.split('&')[4].split('=')[1]
            this.certification = param.split('&')[5].split('=')[1]
            this.id = param.split('&')[6].split('=')[1]
            this.course_type = param.split('&')[7].split('=')[1]
            this.lessonId = param.split('&')[8].split('=')[1]
            this.playTime = param.split('&')[9].split('=')[1]
        }
        
        param = null
        
        if (navigator.mediaDevices.getUserMedia || navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia) {
            this.support = true
            this.getUserMedia({ video: true }) // 调用用户媒体设备，访问摄像头、录音
        } else {
            console.log("你的浏览器不支持访问用户媒体设备")
            this.support = false
        }
    },
    methods: {
        getUserMedia(constrains) {
            let that = this
            if (navigator.mediaDevices.getUserMedia) {
                // 最新标准API
                navigator.mediaDevices.getUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
            } else if (navigator.webkitGetUserMedia) {
                // webkit内核浏览器
                navigator.webkitGetUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
            } else if (navigator.mozGetUserMedia) {
                // Firefox浏览器
                navigator.mozGetUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
            } else if (navigator.getUserMedia) {
                // 旧版API
                navigator.getUserMedia(constrains).then(stream => { that.success(stream); }).catch(err => { that.error(err); });
            }
        },
        // 成功的回调函数
        success(stream) {
            console.log("已点击允许,开启成功")
            this.openCamera()
        },
        // 异常的回调函数
        error(error) {
            console.log("访问用户媒体设备失败：", error.name, error.message)
            this.support = false
        },
        change(e) {
            let file = document.querySelector("#myfile").files[0]
            this.url = URL.createObjectURL(file)
            var reader = new FileReader()
            reader.onload = (e) => {
                let image = new Image()
                image.src = e.target.result
                image.onload = () => {
                    let canvas = document.createElement('canvas')
                    let ctx = canvas.getContext('2d')
                    let img = document.querySelector("#photo-img")
                    canvas.width = img.width
                    canvas.height = img.height
                    ctx.drawImage(img, 0, 0, 220, 220)
                    this.imgData = canvas.toDataURL('image/jpeg', 0.2)
                    this.isTake = true
                    this.loading = true
                    this.active = '#fff'
                    this.statusTxt = ''
                    this.takePhoto()
                }
            }
            reader.readAsDataURL(file)
        },
        openCamera() {
            let flag = true
            let constraints = {
                video: {
                    width: 220,
                    height: 220,
                    facingMode: "user"
                }
            }
            let video = document.getElementById('myVideo')
            let promise = navigator.mediaDevices.getUserMedia(constraints)
            promise.then((mediaStream) => {
                this.mediaStreamTrack = typeof mediaStream.stop === 'function' ? mediaStream : mediaStream.getTracks()[0]
                video.srcObject = mediaStream
                video.play()
            })

            var objects = new tracking.ObjectTracker(['face'])
            objects.setInitialScale(4)
            objects.setStepSize(2)
            objects.setEdgesDensity(0.1)

            

            setTimeout(() => {
                this.trackerTask = tracking.track('#myVideo', objects)
            }, 2000)
            

            this.show = false
            this.again = false
            this.number = 5
            this.statusTxt = '请保持人脸在圆圈中...' + '（' + this.number + 's' + '）'
            this.active = 'rgb(8, 128, 241)'
            this.timer = setInterval(() => {
                this.number--
                this.statusTxt = '请保持人脸在圆圈中...' + '（' + this.number + 's' + '）'
                if(this.number == 0) {
                    this.statusTxt = '请保持人脸在圆圈中...'
                    objects.on('track', (event) => {
                        if (event.data.length === 0) {
                            // 未识别到人脸
                        } else {
                            // 识别到人脸
                            if(flag) {
                                flag = false
                                this.show = true
                                this.loading = true
                                this.takePhoto()
                            }
                        }
                    })
                    clearInterval(this.timer)
                }
            }, 1000)
        },
        takePhoto() {
            if(this.support) {
                let video = document.getElementById('myVideo')
                let canvas = document.getElementById('canvas')
                let ctx = canvas.getContext('2d')
                ctx.drawImage(video, 0, 0, 220, 220)
                this.imgData = document.getElementById('canvas').toDataURL()
            }
            let baseUrl = process.env.VUE_APP_BASEURL
            this.return_url = '/course/player?id='+this.id+'&course_type='+this.course_type+'&lessonId='+this.lessonId
            axios({
                headers: {
                    'content-type': 'application/json',
                    'Authorization': this.token,
                    'access-token': this.accessToken
                },
                url: baseUrl+'/front.v1/user/veri-face',
                method: 'post',
                data: {
                    face: this.imgData,
                    client_type: 1,
                    verify_type: this.faceType,
                    real_name: this.realname,
                    id_card: this.idcard,
                    play_time: this.playTime,
                    return_url: this.return_url
                }
            }).then(res => {
                // console.info(res)
                this.res = res
                this.loading = false
                if(res.data.code == 200) {
                    this.statusTxt = '人脸对比成功'
                    this.active = '#52c41a'
                    this.loading = false
                    if(this.support) {
                        this.trackerTask.stop()
                        this.mediaStreamTrack.stop()
                    }
                    if(this.faceType == 1) {
                        setTimeout(() => {
                            wx.miniProgram.postMessage({ data: {
                                status: res.data.data.Passed
                            } })
                            wx.miniProgram.reLaunch({
                                url: '/pages/my/my'
                            })
                    }, 1200)
                    } else if(this.faceType == 0) {
                        if(this.bind == 'true') {
                            axios({
                                headers: {
                                    'content-type': 'application/json',
                                    'Authorization': this.token,
                                    'access-token': this.accessToken
                                },
                                method: 'post',
                                url: baseUrl+'/front.v1/mini-wechat/reg-bind',
                                data: {
                                    mobile: this.mobile,
                                    idcard: this.idcard,
                                    realname: this.realname,
                                    union_id: this.union_id,
                                    open_id: this.open_id,
                                    sms_code: this.sms_code,
                                    password: this.password,
                                    department_id: this.department_id
                                }
                            }).then(res => {
                                if(res.data.code == 200) {
                                    wx.miniProgram.postMessage({ data: {
                                        status: this.res.data.data.Passed,
                                        token: res.data.data.token
                                    } })
                                    wx.miniProgram.reLaunch({
                                        url: '/pages/my/my'
                                    })
                                }
                            })
                        } else {
                            axios({
                                headers: {
                                    'content-type': 'application/json',
                                    'Authorization': this.token,
                                    'access-token': this.accessToken
                                },
                                url: baseUrl+ '/front.v1/site/regist',
                                method: 'post',
                                data: {
                                    realname: this.realname,
                                    idcard: this.idcard,
                                    mobile: this.mobile,
                                    sms_code: this.sms_code,
                                    password: this.password,
                                    department_id: this.department_id
                                }
                            }).then(res => {
                                if(res.data.code == 200) {
                                    wx.miniProgram.postMessage({ data: {
                                        status: this.res.data.data.Passed,
                                        token: res.data.data.token
                                    } })
                                    wx.miniProgram.reLaunch({
                                        url: '/pages/my/my'
                                    })
                                }
                            })
                        }
                    } else if(this.faceType == 2) {
                        wx.miniProgram.postMessage({ data: {
                            status: res.data.data.Passed
                        } })
                        wx.miniProgram.navigateBack()
                    }
                } else {
                    alert(JSON.stringify(res.data))
                    this.statusTxt = '人脸对比失败，请重试'
                    this.active = '#ff4d4f'
                    this.loading = false
                    if(this.support) {
                        this.trackerTask.stop()
                        this.mediaStreamTrack.stop()
                        this.again = true
                    }
                }
            }).catch(err => {
                console.info(err)
                this.loading = false
                this.statusTxt = '人脸对比失败，请重试'
                this.active = '#ff4d4f'
                if(this.support) {
                    this.trackerTask.stop()
                    this.mediaStreamTrack.stop()
                    this.again = true
                }
                
            })
        },
    },
    destroyed() {
        if(this.support) {
            this.trackerTask.stop()
            this.mediaStreamTrack.stop()
        }
    }
}
</script>

<style scoped>
#myVideo, #canvas {
    border-radius: 50%;
}
.face-wrap {
    height: 220px;
    margin-bottom: 20px;
    padding-top: 50px;
    box-sizing: content-box;
}
.face-txt {
    color: #bbb;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 20px;
}
.status-txt {
    text-align: center;
    display: inline-block;
    font-size: 14px;
    line-height: 14px;
    padding: 8px 20px;
    color: #fff;
    border-radius: 4px;
}
.photo-img {
    width: 220px;
    height: 220px;
    border-radius: 110px;
    margin: 0 auto;
    object-fit: cover;
}
#myfile {
    display: none;
}
#file-btn {
    background: rgb(8, 128, 241);
    font-size: 14px;    
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border-radius: 4px;
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    -webkit-box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    display: block;
    line-height: 40px;
}
</style>