var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home", class: { Box: _vm.$store.state.is_global_gray } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _c("transition", { attrs: { name: "fades" } }, [
        _vm.show &&
        _vm.$store.state.tenant_idBind != "2865" &&
        _vm.$store.state.tenant_idBind != "3462"
          ? _c("div", [
              _vm.$store.state.template == 1
                ? _c(
                    "div",
                    [
                      _vm.show
                        ? _c("HomeOne", {
                            attrs: {
                              news: _vm.news,
                              banners: _vm.banners,
                              categorys: _vm.categorys,
                              listFull: _vm.listFull,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.state.template == 2
                ? _c(
                    "div",
                    [
                      _vm.show
                        ? _c("HomeTwo", {
                            attrs: {
                              news: _vm.news,
                              banners: _vm.banners,
                              categorys: _vm.categorys,
                              lives: _vm.lives,
                              listFull: _vm.listFull,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.$store.state.template == 3
                ? _c(
                    "div",
                    [
                      _vm.show
                        ? _c("HomeThr", {
                            attrs: {
                              news: _vm.news,
                              banners: _vm.banners,
                              categorys: _vm.categorys,
                              lives: _vm.lives,
                              listFull: _vm.listFull,
                              top_cates: _vm.top_cates,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.show && _vm.$store.state.tenant_idBind == "3462"
          ? _c(
              "div",
              [
                _vm.show
                  ? _c("HomeFour", {
                      attrs: {
                        news: _vm.news,
                        banners: _vm.banners,
                        categorys: _vm.categorys,
                        lives: _vm.lives,
                        listFull: _vm.listFull,
                        listssss: _vm.listssss,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.show && _vm.$store.state.tenant_idBind == "2865"
          ? _c("div", [_c("HomeRssb")], 1)
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }