<template>
    <div>

        <div class="inner">
            <div class="top-wrap">
                <div class="banner" :style="{width: $store.state.tenant_idBind == 1202 || $store.state.tenant_idBind == 1226 || $store.state.tenant_idBind == 1299? '1200px': '750px'}">
                    <a-carousel arrows autoplay dotPosition="right">
                        <div
                        slot="prevArrow"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left-circle" />
                        </div>
                        <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                        </div>
                        <div class="carouselImgWrap" v-if="banners.length == 0">
                            <router-link to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="carouselImgWrap" v-for="(item, i) in banners" :key="i">
                            <router-link v-if="banners.length == 0" to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 2" :to="`/course/studydetail?id=${item.link}&course_type=system_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 1" to="" @click.native="goThree(item, i)">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 3" :to="`/course/studydetail?id=${item.link}&course_type=tenant_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                        </div>
                    </a-carousel>
                </div>
                <div class="news" v-if="$store.state.tenant_idBind != 1202 && $store.state.tenant_idBind != 1226 && $store.state.tenant_idBind != 1299">
                    <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <span>通知公告</span>
                        </div>
                        <span class="more" @click="moreNotice">查看全部&nbsp;></span>
                    </div>
                    <ul class="news-wrap" v-if="news.length > 0">
                        <li v-for="(item, i) in news" :key="i">
                            <div class="date">
                                <span class="date-day">{{item.created_at.split('-')[2].split(' ')[0]}}</span>
                                <span class="dates">{{`${item.created_at.split('-')[0]}/${item.created_at.split('-')[1]}`}}</span>
                            </div>
                            <div class="news-item">
                                <p @click="goNotice(item, i)" class="news-title">{{item.title}}</p>
                                <p class="news-info">{{item.infos}}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="empty" style="height: 380px;background: #FAFAFA;" v-if="news.length == 0">
                        <img src="../../assets/images/empty.png" alt="">
                        <span style="color: #666;font-size: 12px">暂无数据</span>
                    </div>
                </div>
            </div>
            <div class="flower" v-if="$store.state.tenant_idBind != 989&&$store.state.tenant_idBind != 1202 && $store.state.tenant_idBind != 1226 && $store.state.tenant_idBind != 1299 && $store.state.tenant_idBind != 1222">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f02.png" alt="">
                    <span>注册登录</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f01.png" alt="">
                    <span>选择课程</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f03.png" alt="">
                    <span>在线学习</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f04.png" alt="">
                    <span>在线考试</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f05.png" alt="">
                    <span>完成学习</span>
                </div>
            </div>
            <div class="flower" v-if="$store.state.tenant_idBind == 1222">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f02.png" alt="">
                    <span>注册登录</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f01.png" alt="">
                    <span>选择课程</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f06.png" alt="">
                    <span>支付费用</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f03.png" alt="">
                    <span>在线学习</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f05.png" alt="">
                    <span>完成学习</span>
                </div>
            </div>

            <div class="tem-user-wrap" v-if="$store.state.tenant_idBind == 1202 || $store.state.tenant_idBind == 1226 || $store.state.tenant_idBind == 1299">
                <div class="notice" style="flex: 1;height: 140px;">
                    <div class="titleWrap">
                        <p></p>
                        <span class="more" @click="more('news')">更多</span>
                    </div>
                    <ul class="notice-content">
                        <li v-show="news.length == 0">暂无数据</li>
                        <li @click="goNews(item, i)" @mouseover="addColor(item, i)" @mouseout="removeColor(item, i)" :style="index == i?{color: $store.state.themeColor}:{}" v-for="(item, i) in news" :key=i>
                            <div class="notice-item-title">
                                <span class="icon">New</span>
                                <p class="articleT">{{item.title}}</p>
                            </div>
                            <span>{{item.created_at.split(" ")[0]}}</span>
                        </li>

                    </ul>
                </div>
                <div class="flower" style="flex: 1;height: 140px;margin-left: 20px;border-radius: 4px;">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f02.png" alt="">
                        <span>注册登录</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f01.png" alt="">
                        <span>选择课程</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f03.png" alt="">
                        <span>在线学习</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f04.png" alt="">
                        <span>在线考试</span>
                    </div>
                    <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                    <div class="flower-item">
                        <img src="../../assets/images/tem-two-f05.png" alt="">
                        <span>完成学习</span>
                    </div>
                </div>
            </div>

            <div class="live-module" v-if="$store.state.can_live && lives.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>最近直播</span>
                    </div>
                    <span v-if="lives.length >= 2" class="more" @click="more('live')">查看全部&nbsp;></span>
                </div>
                <ul class="live-list" v-if="lives.length > 0">
                    <li class="live-item" @click="go(item, i)" v-for="(item, i) in lives" :key="i">
                        <div class="live-top">
                            <img :src="item.authors[0].avatar" alt="">
                            <p class="title">{{item.title}}</p>
                        </div>
                        <div class="live-bot">
                            <span class="name">{{item.authors[0].name}}</span>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 1" class="live-sign" :style="{background: $store.state.themeColor,color: '#fff'}">
                                <span>正在直播</span>
                                <img src="../../assets/images/going.png" alt="">
                            </div>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 0" class="live-sign">{{item.start_time.split(':')[0]+':'+item.start_time.split(':')[1]}}&nbsp;开始</div>
                            <div v-if="liveStatus(item.start_time, item.end_time) == 2" class="live-sign">已结束</div>
                        </div>
                    </li>
                </ul>
                <!-- <div class="empty" style="height: 170px" v-if="lives.length == 0">
                    <img src="../../assets/images/empty.png" alt="">
                    <span style="color: #666;font-size: 12px">暂无直播</span>
                </div> -->
            </div>

            <div class="classes-wrap" v-if="$store.state.can_class && list.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>{{categorys[0].name}}</span>
                    </div>
                    <span v-if="list.length >= 2" class="more" @click="more('classes')">查看全部&nbsp;></span>
                </div>
                <ul class="classes-list">
                    <li class="classes-item" v-for="(item, i) in list" :key="i" @click="go(item, i)">
                        <div class="img">
                            <img class="img_a" :src="item.picture" alt="">
                            <div v-if="item.learn_status == 1" class="classes-sign-ing"><span style="padding-right: 20px">进行中</span></div>
                            <div v-if="item.learn_status == 2" class="classes-sign-end">已结束</div>
                        </div>
                        <div class="classes-info">
                            <p class="classes-title">{{item.title}}</p>
                             <span class="describe" v-if="item.begin_time">开课时间：{{item.begin_time+' 至 '+item.end_time}}</span>
                            <p class="classes-about">{{item.about}}</p>
                            <p style="color: #333;margin-bottom: 0">{{item.course_num}}门课  |  {{item.period}}课时  |  {{item.learn_total}}人已学  | {{!item.personal_access?'非公开':'公开'}} <span v-if="item.member_limit">|</span> {{item.member_limit==0?'':'学员人数'+item.join_total+'/'+item.member_limit}}</p>
                            <div class="classes-do-wrap">
                                <div v-if="item.order_status == 0 && $store.state.can_pay && $store.state.tenant_idBind != 22">
                                    <span v-if="item.price && item.price > 0" class="classes-sign">￥</span>
                                    <span class="classes-price">{{item.price > 0? item.price: '免费'}}</span>
                                </div>
                                <div v-if="item.order_status != 0"></div>
                                <div class="classes-go">
                                    <div class="classes-go-img"></div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="list-module" v-if="categorys.length > 0">
                <ul>
                    <li class="module-item" v-for="(item, i) in listFull" :key="i">
                        <div class="title-bar">
                            <div class="title-wrap">
                                <img src="../../assets/images/title-icon.png" alt="">
                                <span>{{item.name}}</span>
                            </div>
                            <span v-if="item.course.length >= 3" class="more" @click="more('course', item.id)">查看全部&nbsp;></span>
                        </div>
                        <ul class="lesson-list">
                            <li class="lesson-item" @click="go(items, i)" v-for="(items, is) in item.course" :key="is">
                                <img class="img_a" :src="items.picture" alt="">
                                <div class="lesson-msg-wrap">
                                    <p class="lesson-title">{{items.title}}</p>
                                    <p class="lesson-about">{{items.period}}课时 | {{items.peroid_format}}分钟 | {{$store.state.hostname == 'bjadks.zjttv.cn'? Math.floor(Math.random() * (500 - 100)) + 100: items.learn_total}}人已学</p>
                                    <p class="lesson-name" v-if="items.authors && items.authors.length > 0">
                                        <span v-for="(itemss, iss) in items.authors.slice(0, 3)" :key="iss" :a="items.length">{{itemss.name}}<span v-if="items.authors.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="items.authors.length>1 && items.authors.length<3">{{iss != 1?'、': ''}}</span></span>
                                    </p>
                                    <p class="lesson-name" v-else></p>
                                    <div class="lesson-price-info">
                                        <div v-if="priceShow()">
                                            <span v-if="items.price > 0" class="lesson-price-sign">￥</span>
                                            <span class="lesson-price">{{items.price > 0? items.price: '免费'}}</span>
                                        </div>
                                        <div>
                                            <van-rate size="14px" v-model="value" />
                                        </div>
                                    </div>
                                </div>

                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="empty" style="height: 400px" v-if="categorys.length == 0">
                <img src="../../assets/images/empty.png" alt="">
                <span style="color: #666;font-size: 12px">暂无数据</span>
            </div>


            <div class="union-module" v-if="unions.length > 0">
                <div class="title-bar">
                    <div class="title-wrap">
                        <img src="../../assets/images/title-icon.png" alt="">
                        <span>院校联盟</span>
                    </div>
                </div>
                <ul class="union">
                    <li class="union-l" @click="prev()" v-if="unionsTotal_pages > 1">
                        <img src="../../assets/images/union-l.png" alt="">
                    </li>
                    <li class="union-item" v-for="(item, i) in unions" :key="i" @click="linkUrl(item)">
                        <img :src="item.logo" alt="">
                    </li>
                    <li class="union-r" @click="next()" v-if="unionsTotal_pages > 1"><img src="../../assets/images/union-r.png" alt=""></li>
                </ul>
            </div>

        </div>

    </div>
</template>

<script>
import {
    unions
} from '../../http/api'
export default {
    data() {
        return {
            value: 5,
            list: [],
            type: 'notice',
            page: 1,
            page_size: 5,
            unions: [],
            unionsTotal_pages: 0
        }
    },
    props: {
      news: Array,
      banners: Array,
      categorys: Array,
      lives: Array,
      listFull: Array
    },
    created() {
        this.hostname = window.location.hostname
        try {
            if(this.categorys.length != 0) {
                if(this.$store.state.can_class == 1) {
                    this.list = this.categorys[0].course.slice(0,2)
                }
            }
        } catch (error) {

        }
        this.updateUnions()
    },
    mounted() {

    },
    methods: {
        priceShow() {
            console.log('this.$store.state.tenant_idBind',this.$store.state.tenant_idBind) 
            if(this.$store.state.tenant_idBind == 1222) {
                return false
            //} else if(this.$store.state.can_pay && this.$store.state.tenant_idBind != 22) {
            } else if(this.$store.state.can_pay) {
                return true
            }
        },
        linkUrl(item) {
            window.location.href = item.domain
        },
        next() {
            if(this.page < this.unionsTotal_pages) {
                this.page++
                this.updateUnions()
            } else {
                this.page = 1
                this.updateUnions()
            }
        },
        prev() {
            if(this.page > 1) {
                this.page--
                this.updateUnions()
            } else {
                this.page = this.unionsTotal_pages
                this.updateUnions()
            }
        },
        updateUnions() {
            unions({
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                if(res.data.code == 200) {
                    this.unions = res.data.data
                    this.unionsTotal_pages = res.data.ext.total_pages
                }
            })
        },
        goThree(item, i) {
            window.open(item.link)
        },
        liveStatus(start, end) {
            let now = new Date().getTime()
            let s = new Date(start).getTime()
            let e = new Date(end).getTime()
            if(now < s) {
                return 0
            } else if(now > s && now < e) {
                return 1
            } else if(now > e) {
                return 2
            }
        },
        goNotice(item, i) {
          this.$router.push({
            path: '/article_detail',
            query: {
              id: item.id,
              type: this.type
            }
          })
        },
        moreNotice() {
          if(this.type == 'notice') {
            this.$router.push({
                path: '/news'
            })
          }
        },
        goNews(item, i) {
            this.$router.push({
                path: '/article_detail',
                query: {
                id: item.id,
                type: '公告通知'
                }
            })
        },
        go(item, i) {
            if(item.join_type == 'class') {
                if(item.is_access) {
                    this.$router.push({
                    path: '/class/catalog',
                    query: {
                        id: item.id,
                    }
                    })
                } else {
                    this.$message.error('学员已禁用，不能访问')
                }
            } else {
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                    id: item.id,
                    course_type: item.course_type
                    }
                })
            }
        },
        more(type, id) {
            if(type == 'course') {
                this.$router.push({
                    path: '/course',
                    query: {
                        id: id
                    }
                })
            } else if(type == 'live') {
                this.$router.push({
                    path: '/course-live'
                })
            } else if(type == 'classes') {
                this.$router.push({
                    path: '/course-classes'
                })
            } else if(type == 'news') {
                this.$router.push({
                    path: '/news'
                })
            }

        }
    }
}
</script>

<style scoped>
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 420px;
    padding-top: 25px;
    box-sizing: content-box;
    margin-bottom: 25px;
}
.banner {
    width: 750px;
    height: 100%;
    margin-right: 20px;
    border-radius: 2px;
    overflow: hidden;
}
.news {
    flex: 1;
    height: 100%;
}
.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.title-bar img {
    width: 6px;
    height: 22px;
    margin-right: 5px;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wrap span {
    font-size: 22px;
    line-height: 22px;
    color: #333;
    font-weight: bold;
}
.title-bar .more {
    font-size: 14px;
    line-height: 22px;
    color: #999;
    cursor: pointer;
}
.carouselImgWrap img {
    height: 420px;
    object-fit: cover;
}
.news {
    height: 420px;
    box-sizing: border-box;
}
.news-wrap {
    height: 380px;
}
.news .date {
    width: 80px;
    height: 80px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.news .date-day {
    font-size: 36px;
    color: #1F1E1C;
    font-weight: bold;
    line-height: 40px;
}
.news .dates {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.news li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.news li:last-child {
    margin-bottom: 0;
}
.news .news-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #E6E6E6;
    height: 80px;
}
.news-item .news-title {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
}
.news-item .news-title:hover {
    color: #0880F1;
}
.news-item .news-info {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    text-align: left;
}
.flower {
    height: 130px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
}
.flower-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flower-item>span {
    font-size: 14px;
    color: #2A2928;
    font-weight: bold;
    line-height: 14px;
}
.flower-item img{
    width: 54px;
    height: 54px;
    margin-bottom: 10px;
}
.flower .flower-sign {
    width: 17px;
    height: 21px;
}
.classes-list {
    padding-bottom: 40px;
}
.classes-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    width: 1220px;
    margin-left: -20px;
    padding: 20px;
    transition: all .3s;
}
.classes-item:hover .classes-go {
    background: #0880F1;
}
.classes-item:last-child {
    margin-bottom: 0;
}
.classes-item .img .classes-sign-ing {
    background: url('../../assets/images/classes-sign-ing.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end {
    background: url('../../assets/images/classes-sign-end.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img {
    width: 430px;
    height: 240px;
    margin-right: 20px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}
.classes-item .img img {
    object-fit: cover;
}
.classes-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
    height: 30px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px;
}
.classes-about {
    min-height: 72px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign {
    color: #FF5E57;
}
.classes-price {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info {
    height: 240px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.classes-do-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.classes-go {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    overflow: hidden;
    border-radius: 2px;
}

.classes-item:hover .classes-go .classes-go-img {
    background: url('../../assets/images/classes-go-hover.png') no-repeat;
}
.classes-go .classes-go-img {
    background: url('../../assets/images/classes-go.png') no-repeat;
    width: 35px;
    height: 10px;
    transition: all .2s;
}
.module-item {
    margin-bottom: 40px;
}
.lesson-item:hover, .classes-item:hover {
    box-shadow: 0 0 8px #ccc;
}
.lesson-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.lesson-item {
    transition: all .3s;
    width: 386px;
    height: 356px;
    margin-right: 21px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    padding: 15px;
}
.lesson-item:nth-child(3n) {
    margin-right: 0;
}
.lesson-item img {
    height: 190px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-about {
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 14px;
    color: #666;
}
.lesson-name {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lesson-msg-wrap {
    background: #FAFAFA;
}
.lesson-price-sign {
    color: #FF5E57;
}
.lesson-price {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty img {
    width: 120px;
    height: 74px;
}
.live-module {
    margin-bottom: 40px;
}
.live-list {
    /* height: 170px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.live-item {
    width: 588px;
    height: 100%;
    /* margin-right: 20px; */
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
    margin-bottom: 20px;
}
/* .live-item:last-child {
    margin-right: 0;
} */
.live-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live-top img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot {
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.live-bot .name {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign {
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAECEF;
    color: #666;
}
.live-bot .live-sign img {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
.union {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.union-module {
    margin-bottom: 30px;
}
.union {
    position: relative;
}
.union .union-item {
    height: 90px;
    width: 236px;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    margin-right: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    padding: 5px;
}
.union .union-item:nth-child(5n) {
    margin-right: 0;
}
.union .union-item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.union .union-l, .union .union-r {
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.union .union-l {
    position: absolute;
    left: -36px;
}
.union .union-r {
    position: absolute;
    right: -36px;
}
.union .union-l img, .union .union-r img {
    width: 100%;
    height: 100%;
}

.notice {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    border: 1px solid #E9E9E9;
    padding: 5px;
    border-radius: 4px;
  }
.notice .more {
  cursor: pointer;
  color: #888;
  font-size: 12px;
}
.notice .titleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    padding: 0 10px;
  }
.notice .title {
    position: relative;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 0;
  }
  .notice .title::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    display: inline-block;
    width: 18px;
    height: 2px;
    background-color: #11A0F8;
  }
.notice-content {
    flex: 1;
    height: 256px;
    border-radius: 6px;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background: #fff;
  }
  .notice-content::-webkit-scrollbar, .player-list::-webkit-scrollbar {
    width:3px;
  }
  .notice-content::-webkit-scrollbar-thumb, .player-list::-webkit-scrollbar-thumb{
      background-color:#aaa;
  }
  .notice .articleT {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: left;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 280px;
  }
  .notice-content li {
    cursor: pointer;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
  }
  .notice-content li:last-child {
    margin-bottom: 0;
  }
  .notice-content span {
    display: block;
    text-align: left;
    line-height: 12px;
    color: #666;
    font-size: 12px;
  }
  .notice-item-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px;
  }
  .notice-item-title .icon {
    margin-right: 10px;
    background: #FF8684;
    color: #fff;
    width: 38px;
    border-radius: 4px;
    font-size: 14px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    box-sizing: border-box;
  }
  .tem-user-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
  }
</style>
