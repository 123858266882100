<template>
    <div class="bank-bg">
        <div class="bg"></div>
        <div class="loading" v-if="show">
            <a-spin />
        </div>
        <transition name="fades">
        <div class="inner" v-if="!show">
            <div class="credit-wrap">
                <div class="credit-item">
                    <img class="code" src="../../assets/images/lvcode.png" alt="">
                    <div class="card-wrap">
                        <img class="bank-card" src="../../assets/images/bankcard.png" alt="">
                        <div class="info-wrap">
                            <img class="photo" :src="userInfo.avatar" alt="">
                            <div>
                                <div class="name-wrap">
                                    <span>{{userInfo.realname}}</span>
                                    <img src="../../assets/images/mancard.png" alt="">
                                </div>
                                <div class="phone-msg">
                                    <span style="margin-right: 30px;">手机号{{userInfo.mobile}}</span>
                                    <span>身份证{{userInfo.idcard.replace(/^(.{4})(?:\d+)(.{4})$/,"$1******$2")}}</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <ul class="score-wrap">
                        <li>
                            <span class="msg-ac" style="font-size: 40px;">{{userInfo.finish_credit_times}}</span>
                            <span class="score">总时长（分钟）</span>
                        </li>
                        <li>
                            <span class="msg-ac">{{userInfo.finish_course_total}}</span>
                            <span class="score">已培训项目（个）</span>
                        </li>
                        <li>
                            <span class="msg-ac">{{userInfo.finish_credit}}</span>
                            <span class="score">已完成课时（45分钟/课时）</span>
                        </li>
                        <li>
                            <span class="msg-ac">{{userInfo.obtain_total}}</span>
                            <span class="score">已获得证书</span>
                        </li>
                    </ul>
                </div>
                <div class="credit-item-r">
                    <h3 class="title-card">服务</h3>
                    <ul class="ser-wrap">
                        <li>
                            <img src="../../assets/images/card1.png" alt="">
                            <span>学分转换</span>
                        </li>
                        <li>
                            <img src="../../assets/images/card2.png" alt="">
                            <span>证书查询</span>
                        </li>
                        <li @click="goNews()">
                            <img src="../../assets/images/card3.png" alt="">
                            <span>最新政策</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="content">
                <ul class="menu">
                    <li 
                    v-for="(item, i) in tab" 
                    :key="i"
                    :class="index == i? 'tab-ac': ''"
                    @click="checkTab(item, i)">{{item.name}}</li>
                </ul>

                <div class="table" v-show="id == 1">
                    <div class="tab-wrap">
                        <div class="tab-item">
                            <span class="tit">受训机构</span>
                            <a-select default-value="请选择" style="width: 157px; height: 38px;" @change="handleChange">
                                <a-select-option :value="item.tenant_id" v-for="(item, i) in tenant" :key="i">
                                    {{item.tenant_name}}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="tab-item">
                            <span class="tit">培训课程名称</span>
                            <a-input v-model="val" style="width: 157px; height: 38px;" placeholder="请输入关键词" />
                        </div>
                        <div class="tab-item">
                            <span class="tit">学习时间</span>
                            <a-range-picker :locale="locale" @change="onChangeDate" />
                        </div>
                        <a-button class="check-btn" @click="check()">筛选</a-button>
                    </div>
                    <ul class="tab-list">
                        <li style="background: #F6F8FB; color: #000;">
                            <span style="flex: 2">受训机构</span>
                            <span style="flex: 3;">培训课程名称</span>
                            <span style="flex: 2">学习开始时间</span>
                            <span style="flex: 2">学习结束时间</span>
                            <span>已获学时数</span>
                            <span>考试状态</span>
                            <span>考试成绩</span>
                            <span>证书</span>
                        </li>
                        <li :style="{background: i%2 != 0? '#FAFBFD': ''}" class="tab-item-li" v-for="(item, i) in list" :key="i">
                            <span style="flex: 2">{{item.tenant.name}}</span>
                            <span class="tab-title">{{item.title}}</span>
                            <span style="flex: 2">{{item.start_learn_date}}</span>
                            <span style="flex: 2">{{item.finish_learn_date}}</span>
                            <span>{{item.gain_credit}}</span>
                            <span>{{item.is_examed == 0? '未考试': '已考试'}}</span>
                            <span>{{item.score}}</span>
                            <span>{{item.cert.length}}</span>
                        </li>
                    </ul>
                    <div class="page-bot">
                        <span style="font-size: 14px; color: #999;">当前显示{{list.length}}条</span>
                        <a-pagination show-quick-jumper :default-current="ext.page" :total="ext.total" :current="page" :pageSize='page_size' @change="onChange" />
                    </div>
                </div>
                <div class="cert-wrap" v-show="id == 2">
                    <h3 style="font-size: 20px;color: #000;font-weight: bold;text-align: left;">已获得证书</h3>
                    <ul class="cert-list">
                        <li v-for="(item, i) in certs" @click="certSee(item)">
                            <img style="width: 278px;height:197px;" :src="item.oss_certificate_photo" alt="">
                            <span style="line-height: 12px;color: #666; font-size: 12px;">{{item.created_at}}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </transition>
    </div>
</template>

<script>
import {
  userInfo,
  learnRecords,
  joinTenant,
  myCerts
} from '../../http/api'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
export default {
    data() {
        return {
            locale,
            userInfo: '',
            id: 1,
            index: 0,
            tab: [
                {
                    id: 1,
                    name: '学习记录'
                },
                {
                    id: 2,
                    name: '证书'
                }
            ],
            page: 1,
            page_size: 20,
            list: [],
            tenant: [
                {
                    tenant_name: '全部',
                    tenant_id: 0
                }
            ],
            certs: [],
            tenant_id: '',
            val: '',
            learn_time: '',
            ext: '',
            show: true
        }
    },
    mounted() {
        userInfo().then(res => {
          this.userInfo = res.data.data
          this.$store.commit("SETAVATAR", res.data.data.avatar)
          this.$store.commit("SETREALNAME", res.data.data.realname)
          this.show = false
        }).catch(err => {

        })
        joinTenant().then(res => {
            if(res.data.code == 200) {
                res.data.data.map((item, i) => {
                    this.tenant.push(item)
                })
            }
        })

        myCerts({
            page_size: 50
        }).then(res => {
            if(res.data.code == 200) {
                this.certs = res.data.data
            }
        })
        this.update()
    },
    methods: {
        certSee(item) {
            window.open(item.oss_certificate_photo)
        },
        goNews() {
          this.$router.push({
              path: '/news',
              query: {
                  id: 8,
                  title: '公告通知'
              }
          })  
        },
        update() {
            learnRecords({
                tenant_id: this.tenant_id,
                keywords: this.val,
                learn_time: this.learn_time,
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                if(res.data.code == 200) {
                    this.ext = res.data.data.ext
                    res.data.data.courses.map((item, i) => {
                        this.list.push(item)
                    })
                }
            })
        },
        checkTab(item, i) {
            this.index = i
            this.id = item.id
        },
        handleChange(value) {
            // console.info(value)
            this.tenant_id = value
        },
        onChange(pageNumber) {
            this.list = []
            this.page = pageNumber
            this.update()
        },
        onChangeDate(date, dateString) {
            this.learn_time = dateString[0]+'~'+dateString[1]
        },
        check() {
            this.list = []
            this.page = 1
            this.update()
        }
    }
}
</script>

<style scoped>
.bank-bg {
    background: #F6F8FB;
}
.bg {
    width: 100%;
    height: 150px;
    background: url('../../assets/images/cbankBg.png') no-repeat;
    margin-bottom: 20px;
}
.inner {
    width: 1220px;
    margin: 0 auto 20px;
}
.credit-wrap {
    height: 270px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 44px;
}
.credit-item {
    height: 270px;
    width: 700px;
    background: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 6px;
    box-shadow: 0 0 3px #ddd;
}
.credit-item-r {
    height: 270px;
    width: 500px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 0 3px #ddd;
}
.credit-item .code {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 0;
    right: 0;
}
.bank-card {
    width: 206px;
    height: 107px;
}
.card-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.info-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.photo {
    width: 76px;
    height: 76px;
    border-radius: 38px;
    margin-right: 15px;
}
.name-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
}
.name-wrap span {
    font-size: 20px;
    color: #000;
    font-weight: bold;
    line-height: 20px;
}
.name-wrap img {
    width: 26px;
    height: 26px;
}
.phone-msg {
    font-size: 12px;
    color: #999;
    height: 30px;
    background: #F6F8FB;
    display: block;
    line-height: 30px;
    border-radius: 4px;
    padding: 0 10px;
}
.score {
    font-size: 12px;
    color: #999;
    line-height: 12px;
}
.msg-ac {
    color: #0066CC;
    font-size: 30px;
    font-weight: bold;
}
.score-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.score-wrap li {
    height: 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    border-right: 1px solid #D0D7E1;
    padding-bottom: 20px;
}
.score-wrap li:last-child {
    border-right: none;
}
.title-card {
    font-size: 24px;
    color: #333;
    text-align: left;
    position: absolute;
    left: 20px;
    top: 10px;
}
.ser-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}
.ser-wrap li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 45px;
    cursor: pointer;
}
.ser-wrap li img {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
}
.ser-wrap li span {
    font-size: 14px;
    color: #333;
    line-height: 14px;
}
.content {
    background: #fff;
    border-radius: 10px;
}
.content .menu {
    height: 50px;
    border-bottom: 2px solid #0066CC;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.content .menu li:first-child {
    border-top-left-radius: 10px;
}
.content .menu li {
    width: 116px;
    background: #fff;
    color: #333;
    font-size: 16px;
    height: 100%;
    line-height: 50px;
    border-right: 1px solid #D0D7E1;
    cursor: pointer;
}
.table {
    padding: 0 25px 25px;
}
.tab-ac {
    color: #fff!important;
    background: #0066CC!important;
}
.tab-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 35px;
}
.tab-item .tit {
    text-align: left;
    margin-right: 15px;
    display: block;
}
.tab-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 25px;
}
.check-btn {
    width: 74px;
    height: 36px;
    background: #0066CC;
    color: #fff;
    border-radius: 10px;
    border: none;
}
.tab-list {
    margin-bottom: 25px;
}
.tab-list li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
}
.tab-list li span {
    flex: 1;
}
.tab-list .tab-item-li {
    color: #666;
}
.tab-list .tab-item-li span {
    flex: 1;
}
.tab-title {
    flex: 3!important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.page-bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cert-wrap {
    padding: 0 25px 25px;
}
.cert-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.cert-list li {
    width: 272px;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    margin-bottom: 30px;
}
.cert-list li img {
    margin-bottom: 14px;
}
.cert-list li:last-child {
    margin-right: 0;
}
.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}
</style>