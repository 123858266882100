var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "player", staticStyle: { width: "100%" } },
    [
      _vm.mmm ? _c("div", { staticClass: "mmm" }) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _c("transition", { attrs: { name: "fades" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "playerWrap",
            staticStyle: { height: "100%" },
          },
          [
            _c("div", { staticClass: "player-inner" }, [
              _vm.course.type == "live"
                ? _c("div", { staticClass: "online-icon" }, [
                    _vm._v("在线人数：" + _vm._s(_vm.max_online_num)),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "player-inner-title" }, [
                _c(
                  "div",
                  { staticStyle: { display: "flex", "align-items": "center" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "player-back",
                        on: {
                          click: function ($event) {
                            return _vm.backWay()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/back.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                    _c("p", [_vm._v(_vm._s(_vm.course.title))]),
                  ]
                ),
                _vm.userName && _vm.userMobile
                  ? _c("div", { staticClass: "user_info" }, [
                      _vm._v(
                        " 学员信息：" +
                          _vm._s(_vm.userName) +
                          " " +
                          _vm._s(_vm.userMobile) +
                          " "
                      ),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lessonOne.file_ext == "pdf",
                      expression: "lessonOne.file_ext == 'pdf'",
                    },
                  ],
                  staticClass: "pdf",
                },
                [
                  _c(
                    "pdf",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        src: _vm.pdfUrl,
                        page: _vm.pageNum,
                        rotate: _vm.pageRotate,
                      },
                      on: {
                        progress: function ($event) {
                          _vm.loadedRatio = $event
                        },
                        "page-loaded": function ($event) {
                          return _vm.pageLoaded($event)
                        },
                        "num-pages": function ($event) {
                          _vm.pageTotalNum = $event
                        },
                        error: function ($event) {
                          return _vm.pdfError($event)
                        },
                        "link-clicked": function ($event) {
                          _vm.page = $event
                        },
                      },
                    },
                    [_vm._v(">")]
                  ),
                ],
                1
              ),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.$store.state.playerVisible &&
                      _vm.lessonOne.file_ext != "pdf",
                    expression:
                      "$store.state.playerVisible && lessonOne.file_ext != 'pdf'",
                  },
                ],
                staticClass: "prism-player",
                attrs: { id: "player-con" },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.lessonOne.file_ext == "mp3",
                      expression: "lessonOne.file_ext == 'mp3'",
                    },
                  ],
                  staticClass: "cover-mp3",
                },
                [_c("img", { attrs: { src: _vm.lessonOne.picture, alt: "" } })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.course.type == "live" &&
                        _vm.lessonOne.live_status == "living",
                      expression:
                        "course.type == 'live' && lessonOne.live_status == 'living'",
                    },
                  ],
                  staticClass: "player-custom-controller",
                },
                [
                  _c("img", {
                    staticClass: "player-icon-controller",
                    attrs: {
                      src: !_vm.playIconFlag
                        ? require("../../assets/images/play-icon-controller.png")
                        : require("../../assets/images/pause-icon-controller.png"),
                      title: !_vm.playIconFlag ? "播放" : "暂停",
                    },
                    on: { click: _vm.toggle },
                  }),
                  _c("img", {
                    staticClass: "player-icon-controller",
                    staticStyle: { width: "22px", height: "22px" },
                    attrs: {
                      src: require("../../assets/images/refresh-icon-controller.png"),
                      title: "刷新",
                    },
                    on: { click: _vm.refreshPlayer },
                  }),
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        !_vm.$store.state.playerVisible &&
                        _vm.lessonOne.file_ext != "pdf",
                      expression:
                        "!$store.state.playerVisible && lessonOne.file_ext != 'pdf'",
                    },
                  ],
                  staticClass: "errTips",
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.errTipsTxt))]),
                  _vm.$store.state.open_trial == 0
                    ? _c(
                        "a-button",
                        {
                          style: {
                            background: _vm.$store.state.themeColor,
                            color: "#fff",
                          },
                          attrs: { size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.retry()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.errBtnTxt))]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.abnormal &&
                        _vm.$store.state.is_cancel_progress == 0,
                      expression:
                        "abnormal&&$store.state.is_cancel_progress==0",
                    },
                  ],
                  staticClass: "player-mask",
                  style: {
                    height: _vm.videoHeight ? _vm.videoHeight + "px" : "100%",
                  },
                },
                [
                  _c("div", { staticClass: "title-mask" }, [
                    _c("img", {
                      attrs: {
                        src: require("../../assets/images/warn_mask.png"),
                        alt: "",
                      },
                    }),
                    _c("h3", [_vm._v("学习进度异常！")]),
                  ]),
                  _c("p", [
                    _vm._v(
                      "错误码：" +
                        _vm._s(_vm.lessonOne.course_id) +
                        " | " +
                        _vm._s(_vm.lessonOne.id) +
                        " | " +
                        _vm._s(_vm.videoName)
                    ),
                  ]),
                  _c("p", [_vm._v("1、是否存在拖动进度条的行为")]),
                  _c("p", [_vm._v("2、网络不稳定，导致记录错误")]),
                  _c(
                    "a-button",
                    {
                      style: {
                        width: "100px",
                        color: "#fff",
                        border: "none",
                        marginTop: "20px",
                        background: _vm.$store.state.themeColor,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.refreshPlayer()
                        },
                      },
                    },
                    [_vm._v("刷新重试")]
                  ),
                ],
                1
              ),
              _vm.errorPrompt
                ? _c(
                    "div",
                    {
                      staticClass: "player-mask",
                      style: {
                        height: _vm.videoHeight
                          ? _vm.videoHeight + "px"
                          : "100%",
                      },
                    },
                    [
                      _c("div", { staticClass: "title-mask" }, [
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/warn_mask.png"),
                            alt: "",
                          },
                        }),
                        _c("h3", [_vm._v("服务器开小差！")]),
                      ]),
                      _c("p", [
                        _vm._v(
                          "错误码：" +
                            _vm._s(_vm.lessonOne.course_id) +
                            " | " +
                            _vm._s(_vm.lessonOne.id) +
                            " | " +
                            _vm._s(_vm.videoName)
                        ),
                      ]),
                      _c("p", [_vm._v("1、需要检查当前网络环境是否通畅")]),
                      _c("p", [
                        _vm._v(
                          "2、可尝试使用手机热点网络检查是否是当前无线网络环境问题"
                        ),
                      ]),
                      _c(
                        "a-button",
                        {
                          style: {
                            width: "100px",
                            color: "#fff",
                            border: "none",
                            marginTop: "20px",
                            background: _vm.$store.state.themeColor,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.refreshPlayer()
                            },
                          },
                        },
                        [_vm._v("刷新重试")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "player-bot-do" }, [
                _vm.statusVisible && !_vm.play_expired
                  ? _c("div", { staticClass: "signal" }, [
                      _c("div", {
                        staticClass: "signalColor",
                        style: { background: _vm.sigColor },
                      }),
                      _vm.signalFlag == 1
                        ? _c("span", { staticStyle: { color: "#52c41a" } }, [
                            _vm._v("正常记录学习进度"),
                          ])
                        : _vm.signalFlag == 0
                        ? _c("span", { staticStyle: { color: "#c00" } }, [
                            _vm._v("学习进度异常"),
                          ])
                        : _vm.signalFlag == 2
                        ? _c("span", { staticStyle: { color: "gold" } }, [
                            _vm._v("正在回看"),
                          ])
                        : _vm.signalFlag == 3
                        ? _c("span", { staticStyle: { color: "#eee" } }, [
                            _vm._v("视频暂停"),
                          ])
                        : _vm.signalFlag == 4
                        ? _c("span", { staticStyle: { color: "#c00" } }, [
                            _vm._v("班级已结束，不记录学习数据"),
                          ])
                        : _vm.signalFlag == 5
                        ? _c("span", { staticStyle: { color: "#c00" } }, [
                            _vm._v("课程已结束，不记录学习数据"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm.play_expired == 1 || _vm.play_expired == 2
                  ? _c("div", { staticClass: "signal" }, [
                      _c("div", {
                        staticClass: "signalColor",
                        staticStyle: { background: "#c00" },
                      }),
                      _vm.play_expired == 1
                        ? _c("span", { staticStyle: { color: "#c00" } }, [
                            _vm._v("班级已结束，不记录学习数据"),
                          ])
                        : _vm.play_expired == 2
                        ? _c("span", { staticStyle: { color: "#c00" } }, [
                            _vm._v("课程已结束，不记录学习数据"),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                !_vm.statusVisible
                  ? _c("div", { staticClass: "signal" }, [
                      _c("div", {
                        staticClass: "signalColor",
                        style: { background: _vm.sigColor },
                      }),
                      _c("span", { staticStyle: { color: "#52c41a" } }, [
                        _vm._v(
                          "该视频可试看" +
                            _vm._s(_vm.$store.state.trial_length) +
                            "分钟"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.lessonOne.file_ext == "pdf"
                  ? _c(
                      "div",
                      { staticClass: "tools" },
                      [
                        _c(
                          "a-button",
                          {
                            staticStyle: { "margin-right": "15px" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.prePage.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" 上一页")]
                        ),
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.nextPage.apply(null, arguments)
                              },
                            },
                          },
                          [_vm._v(" 下一页")]
                        ),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#ffffff",
                              "margin-left": "10px",
                            },
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.pageNum) +
                                "/" +
                                _vm._s(_vm.pageTotalNum) +
                                " "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.signalVisible,
                        expression: "signalVisible",
                      },
                    ],
                    staticClass: "player-do-btn",
                  },
                  [
                    _vm.statusVisible
                      ? _c(
                          "a-button",
                          {
                            style: {
                              background: _vm.$store.state.themeColor,
                              borderColor: _vm.$store.state.themeColor,
                            },
                            attrs: { type: "primary" },
                            on: { click: _vm.openVisibleQues },
                          },
                          [_vm._v("我要提问")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.$store.state.is_cancel_progress == 0
                ? _c(
                    "div",
                    {
                      staticClass: "air-msg",
                      style: { left: _vm.airPositionLeft + "px" },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("../../assets/images/warn_radius.png"),
                          alt: "",
                        },
                      }),
                      _c("p", [
                        _vm._v("请不要拖动进度条，否则会停止记录学习进度"),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "air-msg",
                  style: { width: "170px", right: _vm.airPositionRight + "px" },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/r.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("课时已学习完成")]),
                ]
              ),
            ]),
            _vm.course.type == "normal"
              ? _c(
                  "div",
                  {
                    staticClass: "player-normal-menu",
                    style: { width: _vm.sidebarWidth + "px" },
                  },
                  [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.packFlag,
                            expression: "!packFlag",
                          },
                        ],
                        staticClass: "progress-wrap",
                      },
                      [
                        _c("div", { staticClass: "progress-title" }, [
                          _c("span", [_vm._v("当前课程进度")]),
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.course_member.complete_percent) + "%"
                            ),
                          ]),
                        ]),
                        _c("a-progress", {
                          attrs: {
                            percent: _vm.course_member.complete_percent,
                            strokeColor: "#6AC148",
                            showInfo: false,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.packFlag,
                            expression: "!packFlag",
                          },
                        ],
                        staticClass: "player-menu-title-wrap",
                      },
                      [
                        _c("h3", { staticClass: "player-menu-title" }, [
                          _vm._v("课程目录"),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.packFlag,
                            expression: "!packFlag",
                          },
                        ],
                        staticClass: "player-normal-item-scroll",
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "player-normal-item-wrap" },
                          _vm._l(_vm.chapters, function (item, i) {
                            return _c(
                              "li",
                              { key: i, staticClass: "player-normal-item" },
                              [
                                _vm.course.is_chapter
                                  ? _c("h3", { staticClass: "chapter" }, [
                                      _vm._v(_vm._s(item.title)),
                                    ])
                                  : _vm._e(),
                                _vm._l(item.lessons, function (items, is) {
                                  return _c(
                                    "div",
                                    {
                                      key: is,
                                      staticClass: "item-wrap-flex",
                                      on: {
                                        click: function ($event) {
                                          return _vm.plays(item, items, is)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "item-title-wrap-flex" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "item-title-wrap" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src:
                                                    _vm.checkId == items.id
                                                      ? require("../../assets/images/play.png")
                                                      : require("../../assets/images/pause.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _c(
                                                "p",
                                                {
                                                  style: {
                                                    color:
                                                      _vm.checkId == items.id
                                                        ? "#0071F5"
                                                        : "",
                                                  },
                                                },
                                                [_vm._v(_vm._s(items.title))]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "sub-wrap" },
                                            [
                                              _vm.course.course_type ==
                                              "tenant_course"
                                                ? _c(
                                                    "p",
                                                    { staticClass: "icon" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.iconFilter(
                                                            items.file_ext
                                                          ).name
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "p",
                                                { staticClass: "course-time" },
                                                [
                                                  _vm._v(
                                                    "需用时" +
                                                      _vm._s(
                                                        items.length_format
                                                      )
                                                  ),
                                                ]
                                              ),
                                              items.file_ext &&
                                              items.file_ext == "pdf"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "course-time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "已学" +
                                                          _vm._s(
                                                            items.watch_time_format
                                                          )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              items.file_ext == "pdf"
                                                ? _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "course-time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          items.learn_page +
                                                            "/" +
                                                            items.pages
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      ),
                                      items.learn_percent > 0 &&
                                      items.learn_percent < 100
                                        ? _c("a-progress", {
                                            attrs: {
                                              type: "circle",
                                              percent: parseInt(
                                                items.learn_percent
                                              ),
                                              width: 30,
                                              strokeColor: "#1D75FA",
                                            },
                                          })
                                        : _vm._e(),
                                      items.learn_percent == 100
                                        ? _c("img", {
                                            staticStyle: {
                                              width: "28px",
                                              height: "28px",
                                            },
                                            attrs: {
                                              src: require("../../assets/images/refundIcon.png"),
                                              alt: "",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                              ],
                              2
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "sidebar-btn",
                        on: {
                          click: function ($event) {
                            return _vm.packUp()
                          },
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: _vm.packFlag
                              ? require("../../assets/images/leftsidebar.png")
                              : require("../../assets/images/rightsidebar.png"),
                            alt: "",
                          },
                        }),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.course.type == "live"
              ? _c(
                  "div",
                  {
                    staticClass: "playerModules",
                    style: { width: _vm.sidebarWidth + "px" },
                  },
                  [
                    !_vm.packFlag
                      ? _c(
                          "ul",
                          { staticClass: "tab" },
                          _vm._l(_vm.tab, function (item, i) {
                            return _c(
                              "li",
                              {
                                key: i,
                                style:
                                  _vm.index == i
                                    ? {
                                        background: _vm.$store.state.themeColor,
                                        color: "#fff",
                                      }
                                    : {},
                                on: {
                                  click: function ($event) {
                                    return _vm.tabMethod(item, i)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "player-content" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.index == 0,
                              expression: "index == 0",
                            },
                          ],
                          staticClass: "player-list",
                          style: { height: _vm.replayWrapHeight + "px" },
                        },
                        _vm._l(_vm.replay_videos, function (item, i) {
                          return _c(
                            "div",
                            {
                              key: i,
                              staticClass: "player-item",
                              style:
                                _vm.listIndex == item.video_id
                                  ? {
                                      color: _vm.$store.state.themeColor,
                                      border: `1px solid ${_vm.$store.state.themeColor}`,
                                    }
                                  : {},
                              on: {
                                click: function ($event) {
                                  return _vm.checkList(item, i)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "player-item-wrap" }, [
                                _c("div", { staticClass: "playback" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "playback-icon",
                                      style:
                                        _vm.listIndex == item.video_id
                                          ? {
                                              borderColor:
                                                _vm.$store.state.themeColor,
                                            }
                                          : {},
                                    },
                                    [_vm._v("回放")]
                                  ),
                                  _c("p", { staticClass: "title" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                ]),
                                _c("div", [_vm._v(_vm._s(item.start_time))]),
                              ]),
                              _c("div", [_vm._v(_vm._s(item.percent) + "%")]),
                            ]
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.index == 1,
                              expression: "index == 1",
                            },
                          ],
                          staticClass: "talkingRoom",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "txtWrap" },
                            _vm._l(_vm.talkingList, function (item, i) {
                              return _c("div", { key: i }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#666",
                                      "font-size": "12px",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.created_at))]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "talkingItem",
                                    style:
                                      item.user_id == _vm.from_client_id
                                        ? { flexDirection: "row-reverse" }
                                        : {},
                                  },
                                  [
                                    _c("div", { staticClass: "pho" }, [
                                      _c("img", {
                                        attrs: { src: item.avatar, alt: "" },
                                      }),
                                    ]),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "talkingInfo",
                                        style:
                                          item.user_id == _vm.from_client_id
                                            ? { alignItems: "flex-end" }
                                            : {},
                                      },
                                      [
                                        _c("span", { staticClass: "name" }, [
                                          _vm._v(_vm._s(item.username)),
                                        ]),
                                        _c("p", { staticClass: "txt" }, [
                                          _vm._v(_vm._s(item.content)),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            }),
                            0
                          ),
                          _c(
                            "div",
                            { staticClass: "sendWrap" },
                            [
                              _c("a-input", {
                                staticClass: "sendTxt",
                                attrs: { placeholder: "请输入消息..." },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.sendMsg()
                                  },
                                },
                                model: {
                                  value: _vm.sendVal,
                                  callback: function ($$v) {
                                    _vm.sendVal = $$v
                                  },
                                  expression: "sendVal",
                                },
                              }),
                              _c(
                                "a-button",
                                {
                                  staticClass: "sendBtn",
                                  style: {
                                    background: _vm.$store.state.themeColor,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendMsg()
                                    },
                                  },
                                },
                                [_vm._v("发言")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sidebar-btn",
                          staticStyle: { left: "-18px" },
                          on: {
                            click: function ($event) {
                              return _vm.packUp()
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: _vm.packFlag
                                ? require("../../assets/images/leftsidebar.png")
                                : require("../../assets/images/rightsidebar.png"),
                              alt: "",
                            },
                          }),
                        ]
                      ),
                    ]),
                    _c("div", {
                      staticClass: "bot-div",
                      staticStyle: { height: "80px", background: "#151515" },
                    }),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: "友情提示",
            footer: null,
            bodyStyle: { textAlign: "center", padding: "30px" },
            width: "700px",
          },
          model: {
            value: _vm.visibleThree,
            callback: function ($$v) {
              _vm.visibleThree = $$v
            },
            expression: "visibleThree",
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "logo" }, [
              _c("img", {
                staticClass: "img_a",
                attrs: { src: _vm.$store.state.logo, alt: "" },
              }),
            ]),
            _c("p", { staticClass: "notice" }, [
              _vm._v(
                "学员你好！" +
                  _vm._s(_vm.$store.state.website_name) +
                  "实行严格的学习审查制度，将实时监测学员账户的学习过程及学习行为。若发现账户存在异常，我们将对该账户及相关异常数据进行记录存档，并反馈给相关部门，由此带来的处理后果由学员自行承担。" +
                  _vm._s(_vm.$store.state.website_name) +
                  "提醒学员务必诚信学习，感谢支持！"
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "checkWrap" },
            [
              _c("a-checkbox", { on: { change: _vm.onChange } }, [
                _vm._v("不再提示"),
              ]),
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "okBtn",
              style: { background: _vm.$store.state.themeColor, color: "#fff" },
              on: { click: _vm.handleOKs },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _vm.visibleTest
        ? _c(
            "a-modal",
            {
              attrs: {
                bodyStyle: { padding: 0 },
                maskClosable: false,
                title: "随堂小测",
                footer: null,
                width: "500px",
              },
              model: {
                value: _vm.visibleTest,
                callback: function ($$v) {
                  _vm.visibleTest = $$v
                },
                expression: "visibleTest",
              },
            },
            [
              _c("div", { staticClass: "test-title" }, [
                _c("span", [_vm._v(_vm._s(_vm.answerObjLength) + "/")]),
                _c("span", [_vm._v(" " + _vm._s(_vm.questionArr.length))]),
              ]),
              _c(
                "div",
                { staticClass: "classes-test" },
                _vm._l(_vm.questionArr, function (item, i) {
                  return _c(
                    "div",
                    { key: i },
                    [
                      _c("h3", [_vm._v(_vm._s(item.question.stem))]),
                      _vm.is_again == 1
                        ? _c("a-radio-group", {
                            attrs: {
                              name: item.question.id.toString(),
                              options: item.question.metas.choices,
                            },
                            on: { change: _vm.onChangeRadio },
                          })
                        : _vm._e(),
                      _vm._l(item.question.metas.choices, function (items, is) {
                        return _vm.is_again == 0
                          ? _c("div", { key: is, staticClass: "answer-item" }, [
                              _c("span", [_vm._v(_vm._s(items.label))]),
                              item.question.answer[0] == is
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../assets/images/r.png"),
                                      alt: "",
                                    },
                                  })
                                : item.question.answer[0] == is &&
                                  item.choices.choice_status == "right"
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../assets/images/r.png"),
                                      alt: "",
                                    },
                                  })
                                : item.choices.choice_answer[0] == is &&
                                  item.choices.choice_status == "wrong"
                                ? _c("img", {
                                    staticClass: "qesIcon",
                                    attrs: {
                                      src: require("../../assets/images/w.png"),
                                      alt: "",
                                    },
                                  })
                                : _vm._e(),
                            ])
                          : _vm._e()
                      }),
                    ],
                    2
                  )
                }),
                0
              ),
              _c(
                "div",
                { staticClass: "exam-btn-wrap" },
                [
                  _c(
                    "a-button",
                    {
                      style: { background: _vm.$store.state.themeColor },
                      attrs: { type: "primary" },
                      on: { click: _vm.commitExam },
                    },
                    [_vm._v(_vm._s(_vm.is_again == 1 ? "提交" : "再测一次"))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "我要提问",
            footer: null,
            width: "500px",
          },
          model: {
            value: _vm.visibleQues,
            callback: function ($$v) {
              _vm.visibleQues = $$v
            },
            expression: "visibleQues",
          },
        },
        [
          _c("div", { staticClass: "qes-talk-wrap" }, [
            _c("h3", [_vm._v(_vm._s(_vm.lessonOne.title))]),
            _c(
              "div",
              { ref: "qestalkroom", staticClass: "qes-talk-room" },
              _vm._l(_vm.questionList, function (item, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "qes-talk-item",
                    style: {
                      flexDirection: item.is_teacher ? "" : "row-reverse",
                    },
                  },
                  [
                    _c("div", { staticClass: "qes-people-wrap" }, [
                      _c("img", {
                        staticClass: "qes-talk-avatar",
                        attrs: { src: item.user.avatar, alt: "" },
                      }),
                      _c("span", { staticClass: "qes-talk-name" }, [
                        _vm._v(_vm._s(item.is_teacher ? "老师" : "我")),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "qes-talk-info",
                        style: { background: item.is_teacher ? "#f0f6fc" : "" },
                      },
                      [
                        _c(
                          "p",
                          {
                            style: { color: item.is_teacher ? "#0071F5" : "" },
                          },
                          [_vm._v(_vm._s(item.content))]
                        ),
                        _c("span", [_vm._v(_vm._s(item.created_time))]),
                      ]
                    ),
                  ]
                )
              }),
              0
            ),
            _c("p", { staticClass: "refresh", on: { click: _vm.refresh } }, [
              _vm._v("刷新"),
            ]),
            _c(
              "div",
              { staticClass: "qes-do-wrap" },
              [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.askContent,
                      expression: "askContent",
                    },
                  ],
                  staticClass: "qes-txt",
                  attrs: { placeholder: "在这里输入想问老师的问题吧～" },
                  domProps: { value: _vm.askContent },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.askContent = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitQestion },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            title: "课程介绍",
            footer: null,
            width: "500px",
          },
          model: {
            value: _vm.visibleCourse,
            callback: function ($$v) {
              _vm.visibleCourse = $$v
            },
            expression: "visibleCourse",
          },
        },
        [
          _c("p", { staticStyle: { "min-height": "150px" } }, [
            _vm._v(_vm._s(_vm.course.infos)),
          ]),
          _c(
            "div",
            { staticClass: "btn-wrap" },
            [
              _c(
                "a-button",
                {
                  style: { background: _vm.$store.state.themeColor },
                  attrs: { type: "primary" },
                  on: { click: _vm.courseOk },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: "学习前的准备工作",
            footer: "",
            maskClosable: false,
            centered: true,
          },
          on: { cancel: _vm.photoCancel },
          model: {
            value: _vm.photoVisible,
            callback: function ($$v) {
              _vm.photoVisible = $$v
            },
            expression: "photoVisible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "people-module" },
            [
              _vm.open_check_photo == 1
                ? _c(
                    "div",
                    {
                      staticClass: "item-bg",
                      style: {
                        background:
                          _vm.is_id_photo == 1 ? "#fff1f0" : "#f6ffed",
                      },
                    },
                    [
                      _c("div", { staticClass: "people-title" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.is_id_photo == 1
                                ? require("../../assets/images/er.png")
                                : require("../../assets/images/suc.png"),
                            alt: "",
                          },
                        }),
                        _c("h3", [_vm._v("证件照上传")]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.is_id_photo == 1
                              ? "课程已配置需证件照的证书，请前往个人中心上传证件照"
                              : "已上传"
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.$store.state.open_check_realname == 1
                ? _c(
                    "div",
                    {
                      staticClass: "item-bg",
                      style: {
                        background:
                          _vm.is_certification_idcard == 1
                            ? "#fff1f0"
                            : "#f6ffed",
                      },
                    },
                    [
                      _c("div", { staticClass: "people-title" }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.is_certification_idcard == 1
                                ? require("../../assets/images/er.png")
                                : require("../../assets/images/suc.png"),
                            alt: "",
                          },
                        }),
                        _c("h3", [_vm._v("实名认证")]),
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.is_certification_idcard == 1
                              ? "课程已开通实名认证，请前往个人中心进行实名认证"
                              : "已上传"
                          )
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  style: {
                    background: _vm.$store.state.themeColor,
                    color: "#fff",
                    border: "none",
                  },
                  attrs: { size: "large" },
                  on: { click: _vm.goUser },
                },
                [_vm._v("前往补全信息")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "320px",
            title: null,
            footer: null,
            centered: true,
            maskClosable: false,
            closable: false,
          },
          model: {
            value: _vm.$store.state.tryVisible,
            callback: function ($$v) {
              _vm.$set(_vm.$store.state, "tryVisible", $$v)
            },
            expression: "$store.state.tryVisible",
          },
        },
        [
          _c("div", { staticClass: "try-content" }, [
            !_vm.$store.state.token && _vm.lessonOne.file_ext != "pdf"
              ? _c("p", [_vm._v("试看已结束，请登录后继续观看！")])
              : _vm._e(),
            _vm.$store.state.token &&
            _vm.course.order_status == 0 &&
            _vm.lessonOne.file_ext != "pdf"
              ? _c("p", [_vm._v("试看已结束，请购买后继续观看！")])
              : _vm._e(),
            _vm.lessonOne.file_ext == "pdf" && !_vm.$store.state.token
              ? _c("p", [_vm._v("文档不支持试看，请登录后观看")])
              : _vm._e(),
            _vm.lessonOne.file_ext == "pdf" &&
            _vm.$store.state.token &&
            _vm.course.order_status == 0
              ? _c("p", [_vm._v("文档不支持试看，请购买后学习！")])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "try-btn-wrap" },
              [
                _c(
                  "a-button",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.tryCancel()
                      },
                    },
                  },
                  [_vm._v("退出")]
                ),
                !_vm.$store.state.token
                  ? _c(
                      "a-button",
                      {
                        style: {
                          background: _vm.$store.state.themeColor,
                          color: "#fff",
                          border: "none",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tryOk()
                          },
                        },
                      },
                      [_vm._v("确定")]
                    )
                  : _vm._e(),
                _vm.$store.state.token && _vm.course.order_status == 0
                  ? _c(
                      "a-button",
                      {
                        style: {
                          background: "rgb(255, 134, 17)",
                          color: "#fff",
                          border: "none",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.tryBuy()
                          },
                        },
                      },
                      [_vm._v("立即购买")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            width: "300px",
            title: null,
            footer: null,
            closable: false,
            centered: true,
            maskClosable: false,
          },
          model: {
            value: _vm.examVisible,
            callback: function ($$v) {
              _vm.examVisible = $$v
            },
            expression: "examVisible",
          },
        },
        [
          _c("div", { staticClass: "exam-content" }, [
            _c("p", [
              _vm._v("恭喜！学习已完成！该课程已设置考试环节，点击前往考试"),
            ]),
            _c(
              "div",
              { staticClass: "exam-do-wrap" },
              [
                _c("a-button", { on: { click: _vm.waiting } }, [
                  _vm._v("再等等"),
                ]),
                _c(
                  "a-button",
                  {
                    style: {
                      background: _vm.$store.state.themeColor,
                      color: "#fff",
                      border: "none",
                      marginLeft: "20px",
                    },
                    on: { click: _vm.doExam },
                  },
                  [_vm._v("去考试")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            closable: false,
            maskClosable: false,
            title: "提示",
            footer: null,
            bodyStyle: { textAlign: "center" },
            width: "400px",
          },
          model: {
            value: _vm.is_play_expired,
            callback: function ($$v) {
              _vm.is_play_expired = $$v
            },
            expression: "is_play_expired",
          },
        },
        [
          _c("p", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(_vm._s(_vm.play_expired_text)),
          ]),
          _c(
            "a-button",
            {
              staticClass: "okBtn",
              staticStyle: { "margin-top": "60px" },
              style: { background: _vm.$store.state.themeColor, color: "#fff" },
              on: { click: _vm.playExpired },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            closable: false,
            keyboard: false,
            maskClosable: false,
            title: "极速验证",
            footer: null,
            bodyStyle: { textAlign: "center" },
            width: "400px",
          },
          model: {
            value: _vm.visibleGo,
            callback: function ($$v) {
              _vm.visibleGo = $$v
            },
            expression: "visibleGo",
          },
        },
        [
          _vm.visibleGo
            ? _c("slider", {
                ref: "slider",
                on: {
                  onVerifyFailed: _vm.onVerifyFailed,
                  handleOKGo: _vm.handleOKGo,
                },
              })
            : _vm._e(),
          _c("p", { staticStyle: { "font-size": "16px" } }, [
            _vm._v(_vm._s(_vm.play_expired_text)),
          ]),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            closable: false,
            maskClosable: false,
            title: "警告！您已累计" + _vm.failTimes + "次验证失败",
            footer: null,
            bodyStyle: { textAlign: "center" },
            width: "400px",
          },
          model: {
            value: _vm.verifyFailed,
            callback: function ($$v) {
              _vm.verifyFailed = $$v
            },
            expression: "verifyFailed",
          },
        },
        [
          _c("p", { staticStyle: {} }),
          _c("div", { staticClass: "prompt-wrap_content" }, [
            _c("p", { staticClass: "prompt-wrap_text" }, [
              _vm._v("平台相应相关培训政策要求"),
            ]),
            _c("p", { staticClass: "prompt-wrap_text prompt-wrap_redtext" }, [
              _vm._v(
                "累计达到" +
                  _vm._s(_vm.fallback_tolerance_times) +
                  "次回退当前视频学习进度"
              ),
            ]),
            _c("p", { staticClass: "prompt-wrap_text" }, [
              _vm._v("请务必保证培训真实性"),
            ]),
          ]),
          _c(
            "a-button",
            {
              staticClass: "okBtn",
              staticStyle: { "margin-top": "60px" },
              style: { background: _vm.$store.state.themeColor, color: "#fff" },
              on: { click: _vm.onVerifty },
            },
            [
              _vm._v(
                _vm._s(
                  this.failTimes >= this.fallback_tolerance_times
                    ? "刷新页面"
                    : "继续验证"
                )
              ),
            ]
          ),
        ],
        1
      ),
      _c("remote-css", {
        attrs: {
          href: "https://g.alicdn.com/de/prismplayer/2.9.3/skins/default/aliplayer-min.css",
        },
      }),
      _c("remote-js", {
        attrs: {
          src: "https://g.alicdn.com/de/prismplayer/2.9.3/aliplayer-min.js",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }