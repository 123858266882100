<template>
    <div class="detailWrap">
        <ul class="tab" :style="{borderColor: $store.state.themeColor}">
        <li @click="tabs(item, i)"
        class="item" :style="i == index?{background: $store.state.themeColor, color:'#fff'}:{}"
        v-for="(item, i) in tab" :key="i"
        >{{item.name}}</li>
        </ul>
        <div class="tabWrap">
            <div class="tabContent">
                <div class="details" v-show="showIndex == 0">
                    <div class="catalog">
                        <!-- <h3 class="title">课程简介</h3>
                        <p class="txt">{{course.infos}}</p> -->
                        <h3 class="title">课程目录</h3>
                        <ul class="directory-wrap">
                          <li class="directory-item" v-for="(item, i) in lessons" :key="i">
                            <div class="directory-title-wrap" v-if="course.is_chapter">
                              <div class="directory-icon">
                                <img src="../../assets/images/big.png" alt="">
                                <img src="../../assets/images/sm.png" alt="">
                              </div>
                              <h3 class="directory-title">{{item.title}}</h3>
                            </div>
                            <div class="directory-second-item" :class="{'directory-second-item-a': course.type == 'normal'}" @click="go(item, items, i)" v-for="(items, index) in item.lessons" :key="index">
                                <div class="directory-second-left">
                                  <div class="directory-second-title">
                                    <div v-if="course.course_type == 'tenant_course'" :class="iconFilter(items.file_ext).className">{{items.type=='live'?'直播':iconFilter(items.file_ext).name}}</div>
                                    <img src="../../assets/images/three.png" alt="">
                                    <h3 class="directory-title">{{items.title}}</h3>
                                  </div>
                                  <!-- <p class="directory-second-subT">{{items.summary? items.summary: '暂无介绍'}}</p> -->
                                </div>
                                <div class="directory-second-right" :style="course.type == 'normal'? {flexDirection: 'column'}: {}">

                                  <span style="margin-right: 10px">{{items.start_time}}</span>
                                  <span style="margin-right: 10px">{{items.length_format}}</span>
                                  <a-button v-if="course.type == 'live'" @click="go(item, items, i)" :style="{background: liveBtnStatus(items.live_status).bg, color: liveBtnStatus(items.live_status).color}" size="large">{{items.live_status_text}}</a-button>
                                  <a-button v-if="course.type == 'normal'" @click.stop="go(item, items, i)" :style="{ background: studyBtnState(items.learn_status).bgColor, color: studyBtnState(items.learn_status).color, borderColor: studyBtnState(items.learn_status).bdColor}" size="large">{{studyBtnState(items.learn_status).msg}}</a-button>
                                </div>
                              </div>
                          </li>
                        </ul>
                    </div>
                </div>
                <div class="exam" v-if="showIndex == 1">
                    <div v-show="exams.length == 0" style="padding:20px;min-height:400px"><div class="empty">
                          <img src="../../assets/images/weipingjia.png" alt="">
                          <span>暂未设置考试~</span>
                        </div>
                    </div>
                    <div class="exams" v-show="exams.length > 0">
                      <h3 class="title">在线考试</h3>
                        <ul>
                            <li v-for="(item, i) in exams" :key="i">
                                <div class="left">
                                    <h3>{{item.name}}</h3>
                                    <p>{{item.description}}</p>
                                    <p style="margin-bottom: 0;">
                                      <span>题目数量 {{item.item_count}}</span>
                                      <span>|</span>
                                      <span>考试时间 {{!item.limit_time_format? '不限制': item.limit_time_format+'分钟'}}</span>
                                      <span>|</span>
                                      <span>总分 {{item.score}}</span>
                                      <span>|</span>
                                      <span>及格分 {{item.passed_score}}</span>
                                      <span>|</span>
                                      <span v-if="item.retake == 1">允许重考 {{item.do_times}} 次</span>
                                      <span v-if="item.retake == 0">不允许重考</span>
                                      <span v-if="item.retake == 2">不限制重考次数</span>
                                      <!-- <span class="btn-txt-info" v-if="item.retake == 1 && item.passed_status != 'passed'">（剩余考试次数 {{item.remain_do_times}}）</span> -->
                                    </p>
                                </div>
                                <a-button 
                                  @click="goExam(item, i)" 
                                  :style="{background: item.exam_type == 0? '#ccc': $store.state.themeColor, color: '#fff', width: '100px'}"
                                  size="large">{{btnTxt(item).txt}}</a-button>
                                <img style="position: absolute;top: 45px;right: 130px;" v-if="item.passed_status!='none'&&item.passed_status" class="exam-icon" :src="item.passed_status == 'passed'? require('../../assets/images/examYes.png'): require('../../assets/images/examNo.png')" alt="">
                                <span v-if="item.is_access_exam == 1" class="pass_icon" :style="{background: $store.state.themeColor}">随到随考</span>
                            </li>
                        </ul>
                    </div>
                </div>
                
                <div class="certificate" v-if="showIndex == 2">
                    <h3 class="title">课程证书：</h3>
                    <div v-if="!$store.state.token">
                      <p v-if="course.learn_status != 2" class="beautifull">学员可登录后查看是否已获得证书</p>
                      <div class="certificate-wrap" v-for="(item, index) in course.cert" :key="index">
                            <div style="position: relative;">
                              <img :src="item.cert" alt="">
                              <div class="black" v-if="course.learn_status != 2"></div>
                            </div>
                        </div>
                    </div>
                    <div v-if="course.cert_type == 1 && $store.state.token">
                        <div class="certificate-wrap" v-for="(item, index) in course.cert" :key="index">
                            <p v-if="course.learn_status != 2" class="beautifull">您还没有完成课程学习，无法领取证书。</p>
                            <p v-if="course.learn_status == 2" class="beautifull">恭喜您！顺利结业，已获得证书。</p>
                            <div style="position: relative;">
                              <img :src="item.cert" alt="">
                              <div class="black" v-if="course.learn_status != 2"></div>
                            </div>

                            <a v-if="course.learn_status == 2" class="down" target="_blank" :href="item.cert" download>预览下载</a>
                        </div>
                    </div>
                    <div v-if="course.cert_type == 2 && $store.state.token">

                        <div class="certificate-wrap" v-for="(item, index) in course.cert" :key="index">
                        <div>
                            <div v-if="!item.passed_status || item.passed_status == 'none'">
                                <p class="beautifull">您还没有通过考试，无法领取证书。</p>
                                <img :src="item.cert" alt="">
                            </div>
                        </div>
                        <div>
                            <div v-if="item.passed_status == 'unpassed'">
                                <p class="beautifull">您还没有通过考试，无法领取证书。</p>
                                <img :src="item.cert" alt="">
                            </div>
                        </div>
                        <div v-if="item.passed_status == 'passed'">
                            <p class="beautifull">您通过了{{item.title}}考试，请查看证书。</p>
                            <img :src="item.cert" alt="">
                            <a class="down" target="_blank" :href="item.cert" download>预览下载</a>
                        </div>
                    </div>
                    </div>
                </div>

                <div class="data-download"  v-if="showIndex == 3">
                  <h3 class="title">本课程提供的学习资料如下：</h3>
                  <div class="empty" v-if="course_wares.length == 0">
                    <img src="../../assets/images/weipingjia.png" alt="">
                    <span>暂无资料提供下载~</span>
                  </div>
                  <ul>
                    <li class="download-item" v-for="(item, i) in course_wares" :key="i">
                      <div class="download-item-left">
                        <img :src="require('../../assets/images/'+item.file_uri.split('.')[3]+'.png')" alt="">
                        <span>{{item.title}}</span>
                      </div>

                      <div class="download-item-right">
                        <span>{{item.download_times}}&nbsp;人已下载</span>
                        <div @click="downloadMethod(item)">
                          <a :href="item.file_uri" download>点击下载</a>
                        </div>

                      </div>
                    </li>
                  </ul>
                </div>
            </div>
            <div class="side-bar-wrap">
              <div class="star-wrap" v-if="course.type == 'normal'">
                <h3 class="title">课程评价</h3>
                <div class="star-module" v-show="course.learn_status == 2">
                  <p class="star-txt">感谢您的评价，您的评价对我们提供更好的服务有很大帮助！</p>
                  <a-rate @change="changeStar" :disabled='isgrade' v-model="value" :tooltips="desc" />
                  <p v-if="rate_percent > 0" class="star-info">{{rate_percent}}%的学员跟您打的分数一样！</p>
                </div>
                <div class="empty" v-show="course.learn_status != 2">
                  <img src="../../assets/images/weipingjia.png" alt="">
                  <span>您还未学习完该课程，无法进行评价，赶紧去学习吧！</span>
                </div>
              </div>

              <div class="teacher-wrap" v-if="course.author.length > 0">
                <div class="teacher-title-wrap">
                  <div>
                    <h3 class="title">讲师简介</h3>
                    <div class="title-info">
                      <span>共</span>
                      <span style="color: #3091FD;">&nbsp;{{course.author.length}}&nbsp;</span>
                      <span>位讲师</span>
                    </div>
                  </div>

                  <span @click="more" class="more">更多</span>
                </div>
                <div class="teacher-content">
                  <img class="teacher-avatar" :src="course.author[0].avatar" alt="">
                  <div class="teacher-info">
                    <span style="font-weight: bold;">{{course.author[0].name}}</span>
                    <!-- <span>{{course.author[0].position? course.author[0].position: '暂无简介'}}</span> -->
                  </div>
                </div>
                <!-- <p class="teacher-info-content">{{course.author[0].infos? course.author[0].infos: '暂无介绍'}}</p> -->

              </div>

              <div class="recommended-wrap" v-if="relate_courses">
                <h3 class="title">相关推荐</h3>
                <div class="empty" v-if="relate_courses.length == 0">
                  <img src="../../assets/images/weipingjia.png" alt="">
                  <span>暂无推荐课程</span>
                </div>
                <ul>
                  <li class="course-items" v-for="(item, i) in relate_courses" :key="i" @click="checkCourse(item)">
                    <img :src="item.picture" alt="">
                    <div class="course-info">
                      <span>{{item.title}}</span>
                      <span>{{item.period}}学时</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="qes-wrap">
                <h3 class="title">常见问题</h3>
                <ul v-if="news.length > 0">
                  <li @click="goDetail(item)" v-for="(item, i) in news" :key="i" class="news-item">{{item.title}}</li>
                </ul>
                <div class="empty" v-if="news.length == 0">
                  <img src="../../assets/images/weipingjia.png" alt="">
                  <span>暂无内容~</span>
                </div>
              </div>
            </div>
        </div>

        <a-modal :bodyStyle="{padding: 0}" v-model="examVisible" :title="examTitle" :footer="null" width='520px'>
          <!-- 查看成绩 -->
          <div v-if="exam_type == 2 || exam_type == 3" class="exam-item-wrap">
            <div class="exam-item" v-for="(item, i) in examData" :key="i">
                <div>
                  <div style="margin-bottom: 10px;color: #666;font-size: 16px; font-weight: bold;">
                    <span>{{i== 0? '历史最高分：': '上次考试成绩：'}}</span>
                    <span v-if="item.passed_status != ''">{{item.score}}分</span>
                  </div>
                  <div v-if="item.passed_status != ''" style="color: #888;font-size: 12px;">考试时间：{{item.end_time}}</div>
                </div>
                <img v-if="item.passed_status != ''" class="exam-icon" :src="item.passed_status == 'passed'? require('../../assets/images/examYes.png'): require('../../assets/images/examNo.png')" alt="">
                <a-button v-if="i != 0" @click="seeExam(item)" :style="{width: '90px',background: $store.state.themeColor, color: '#fff', border: 'none'}">查看</a-button>
                <div v-if="i == 0" style="width: 90px"></div>
            </div>
            <div class="exam-btn-wrap" v-if="ext.is_retake == 1">
              <a-button @click="start" :style="{width: '160px', height: '44px', background: $store.state.themeColor, color: '#fff', border: 'none'}">开始考试</a-button>
            </div>
          </div>

          <!-- 开始考试 -->
          <div class="exam-start-wrap" v-if="exam_type == 1">
            <div class="exam-top">
              <img class="warn-icon" src="../../assets/images/warn.png" alt="">
              <span>温馨提示：作答过程中，请严格遵守考试制度，一切作弊行为会被记录在考试记录中，请大家认真对待</span>
            </div>
            <div v-if="limit_time_format" class="start-txt">本次考试作答时间为 <span style="color: #c00;">{{limit_time_format}}</span> 分钟。</div>
            <p v-if="!limit_time_format" class="start-txt">本次考试不限时长。</p>
            <p class="start-txt">请各位学员尽量保持网络环境稳定，网络通畅，以便更好的作答。</p>
            <div class="start-do-wrap">
              <a-button @click="startCancel" size="large" style="font-size: 14px;width: 90px;">取消</a-button>
              <a-button @click="start()" size="large" :style="{
              background: $store.state.themeColor,
              color: '#fff',
              marginLeft: '30px',
              fontSize: '14px',
              width: '90px',
              border: 'none'}">开始考试</a-button>
            </div>
          </div>
        </a-modal>

        <a-modal :bodyStyle="{padding: 0}" v-model="visibleTeacher" title="讲师介绍" :footer="null" width='500px'>
          <ul class="teacher-scroll">
            <li class="teacher-items" v-for="(item, index) in authors" :key="index">
              <div class="">
                <div class="teacher-infos">
                    <img :src="item.author_image" alt="">
                    <div class="">
                      <span>{{item.name}}</span>
                      <span>{{item.position}}</span>
                    </div>
                </div>
                <a-rate @change="changeStar2(item)" :disabled='item.rate == 0?false:true' :value="item.rate" v-model="item.rate" :tooltips="desc" />

              </div>
              <p>{{item.infos}}</p>
            </li>
          </ul>
          <div style="text-align: center;height: 70px;display: flex;alignItems: center;justify-content: center;">
            <a-button @click="teacherOk" :style="{background: $store.state.themeColor}" type="primary">关闭</a-button>
          </div>

        </a-modal>
    </div>
</template>

<script>
import Notice from '../notice/index.vue'
import {
  download,
  grade,
  authors,
  testResultList,
  classDetail,
  checkLogin
} from '../../http/api'
export default {
    data() {
        return {
            visibleTeacher: false,
            index: 0,
            showIndex: 0,
            tab: [
                {
                  name: '课程详情',
                  index: 0
                },
                {
                  name: '在线考试',
                  index: 1
                },
                {
                  name: '资料下载',
                  index: 3
                }
            ],
            value: 0,
            desc: ['1分', '2分', '3分', '4分', '5分'],
            isgrade: false,
            rate_percent: 0,
            authors: [],
            examVisible: false,
            examTitle: null,
            exam_type: null,
            examData: [],
            limit_time_format: '',
            test_id: '',
            ext: '',
            classData:{}
        }
    },
    props: {
        course: Object,
        lessons: Array,
        exams: Array,
        relate_courses: Array,
        course_wares: Array,
        news: Array,
        rate: Object
    },
    components: {
        Notice
    },
    created() {
        if(this.rate) {
          this.rate_percent = this.rate.rate_pecernt
          this.value = this.rate.rating
          if(this.value) {
            this.isgrade = true
          }
        }


        if(this.$route.query.index) {
            this.index = this.$route.query.index
            this.showIndex = this.$route.query.index
        } else {
            this.index = 0
        }

        if(this.course.type == 'live') {
            this.tab = [
                {
                    name: '课程详情',
                    index: 0
                }
            ]
        }
        if(this.course.cert_type > 0 && this.$store.state.can_certificate == 1) {
            this.tab = [
                {
                    name: '课程详情',
                    index: 0
                },
                {
                    name: '在线考试',
                    index: 1
                },
                {
                    name: '证书',
                    index: 2
                },
                {
                    name: '资料下载',
                    index: 3
                }
            ]
        }
    },
    watch: {
        'course'(n) {
            if(n.type == 'live') {
                this.tab = [
                    {
                        name: '课程详情',
                        index: 0
                    }
                ]
            }
            if(n.cert_type > 0 && this.$store.state.can_certificate == 1) {
                this.tab = [
                    {
                        name: '课程详情',
                        index: 0
                    },
                    {
                        name: '在线考试',
                        index: 1
                    },
                    {
                        name: '证书',
                        index: 2
                    },
                    {
                        name: '资料下载',
                        index: 3
                    }
                ]
            }
        }

    },
    mounted() {
      // this.getClassDetail();
      if(this.$route.query.class_id){
        this.tab = [
            {
              name: '课程详情',
              index: 0
            }
        ]
      }
    },
    methods: {
      //检测用户登录状态
       checkLogin(items){
          checkLogin({
          }).then(res => {
              if(res.data.code == 200) {
                 this.$router.push({
                    path: '/rssbIframe',
                    query: {
                        src:items.pc_frame_link
                        
                    }
                })
              }
          })
      },
      getClassDetail(){
          classDetail({
                id: this.$route.query.class_id
            }).then(res => {
                if(res.data.code == 200) {
                    let data = res.data.data.class;
                    this.classData = data;
                }
            })
      },
      btnTxt(item) {
        if(item.exam_type == 0) {
          return {
            txt: '未学完课程'
          }
        } else if(item.exam_type == 1) {
          return {
            txt: '开始考试'
          }
        } else if(item.exam_type == 3) {
          return {
            txt: '查看成绩'
          }
        }
      },
      goDetail(item) {
        this.$router.push({
          path: '/article_detail',
          query: {
            id: item.id,
            type: 'qes'
          }
        })
      },
      teacherOk() {
        this.visibleTeacher = false
      },
      more() {
        this.visibleTeacher = true
        authors({
          course_id: this.course.id,
          course_type: this.course.course_type,
        }).then(res => {
          if(res.data.code == 200) {
            this.authors = res.data.data
          }
        })
      },
      downloadMethod(item) {
        download({
          course_id: this.course.id,
          course_type: this.course.course_type,
          ware_id: item.id
        }).then(res => {

        })
      },
      checkCourse(item) {
        this.$router.push({
          path: '/course/studydetail',
          query: {
            id: item.id,
            course_type: item.course_type
          }
        })
      },
      changeStar(val) {
        this.doGrade(0, val)
      },
      changeStar2(item) {
        this.doGrade(item.id, item.rate)
      },
      doGrade(type, val) {
        grade({
          course_id: this.course.id,
          course_type: this.course.course_type,
          author_id: type,
          rate: val
        }).then(res => {
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            this.rate_percent = res.data.data.rate_percent
            this.isgrade = true
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      up() {
          window.location.reload()
      },
      seeExam(item) {
        this.$router.push({
          path: '/examination',
          query: {
            id: this.course.id,
            course_type: this.course.course_type,
            test_id: item.test_id,
            result_id: item.id? item.id: ''
          }
        })
      },
      start() {
        this.$router.push({
          path: '/examination',
          query: {
            id: this.course.id,
            course_type: this.course.course_type,
            test_id: this.test_id
          }
        })
      },
      startCancel() {
        this.examVisible = false
      },
      goExam(item, i) {
          sessionStorage.result_id = ''
          this.exam_type = item.exam_type
          if(item.exam_type == 0) {
            this.examTitle = '未学完课程'
          } else if(item.exam_type == 1) {
            this.examTitle = '开始考试'
            this.test_id = item.test_id
          } else if(item.exam_type == 2) {
            this.examTitle = '开始补考'
          } else if(item.exam_type == 3) {
            this.examTitle = '查看成绩'
          }
          if(this.$store.state.token) {
            if(item.exam_type == 3) {
              testResultList({
                test_id: item.id
              }).then(res => {
                if(res.data.code == 200) {
                  this.examVisible = true
                  this.examData = res.data.data
                  this.ext = res.data.ext
                  this.test_id = res.data.ext.test_id
                }
              })
            } else if(item.exam_type == 1) {
              this.limit_time_format = item.limit_time_format
              this.test_id = item.id
              this.examVisible = true
            }
          } else {
              this.$store.commit("SHOWLOGIN", true)
          }
      },
      tabs(item, i) {
          this.index = i
          this.showIndex = item.index
      },
      go(item, items, i) {
         console.log('this.is_class_certificate',this.$store.state.is_class_certificate)
         console.log('this.classData',this.classData);
         if(this.course.type == 'live') {
             return this.checkLogin(items);
          }
         if(this.$store.state.is_class_certificate===0){
              let sessionObj = JSON.parse(sessionStorage.session)
              let session = {
                certification: sessionObj.certification,
                token: sessionObj.token,
                type: 4, //班级人脸
                class_id:this.$route.query.class_id,
                callback:'/course/player?id='+this.course.id+'&course_type='+this.course.course_type+'&lessonId='+items.id+'&title='+this.$route.query.title
              }
              let sessionStr = JSON.stringify(session)
              sessionStorage.setItem('session', sessionStr)
              console.log('session.token123123123123',session.token)
              this.$store.commit("SETTOKEN", sessionObj.token)
              this.$store.commit('FACEVISIBLE', true)
         }else{
           
            if(items.type == 'link' && items.course_type == 'system_course') {
                if(this.$store.state.token) {
                    window.open(items.link_address)
                } else {
                    this.$store.commit("SHOWLOGIN", true)
                }
            } else {
              if(this.$store.state.open_trial == 0) {  // 不能试看
              if(this.$store.state.token) {
                this.$router.push({
                    path: '/course/player',
                    query: {
                        id: this.course.id,
                        course_type: this.course.course_type,
                        class_id: this.$route.query.class_id,
                        lessonId: items.id,
                        title: this.$route.query.title
                    }
                })
              } else {
                  this.$store.commit("SHOWLOGIN", true)
              }
            } else if(this.$store.state.open_trial == 1) {
              this.$router.push({
                  path: '/course/player',
                  query: {
                      id: this.course.id,
                      course_type: this.course.course_type,
                      class_id: this.$route.query.class_id,
                      lessonId: items.id,
                      title: this.$route.query.title
                  }
              })
            }
            }
            
          
         }
          
      },
      studyBtnState(state) {
        if(this.$store.state.open_trial == 1) {
          if(this.$store.state.token) {
            if(this.course.order_status == 1) {
              if(state == 0) {
                return {
                  msg: '开始学习',
                  bgColor: this.$store.state.themeColor,
                  color: '#fff',
                  bdColor: '#fff'
                }
              }else if(state == 1) {
                return {
                  msg: '继续学习',
                  bgColor: '#ff8611',
                  color: '#fff',
                  bdColor: '#fff'
                }
              } else if(state == 2) {
                return {
                  msg: '已完成',
                  bgColor: '#fff',
                  color: this.$store.state.themeColor,
                  bdColor: this.$store.state.themeColor
                }
              }
            } else {
              return {
                msg: '免费试看',
                bgColor: this.$store.state.themeColor,
                color: '#fff',
                bdColor: '#fff'
              }
            }
          } else {
            return {
              msg: '免费试看',
              bgColor: this.$store.state.themeColor,
              color: '#fff',
              bdColor: '#fff'
            }
          }
        } else if(this.$store.state.open_trial == 0) {
          if(state == 0) {
            return {
              msg: '开始学习',
              bgColor: this.$store.state.themeColor,
              color: '#fff',
              bdColor: '#fff'
            }
          }else if(state == 1) {
            return {
              msg: '继续学习',
              bgColor: '#ff8611',
              color: '#fff',
              bdColor: '#fff'
            }
          } else if(state == 2) {
            return {
              msg: '已完成',
              bgColor: '#fff',
              color: this.$store.state.themeColor,
              bdColor: this.$store.state.themeColor
            }
          }
        }
      },
      liveBtnStatus(state) {
        if(state == 'default') {
          return {
            bg: '#ddd',
            color: '#999'
          }
        } else if(state == 'living') {
          return {
            bg: this.$store.state.themeColor,
            color: '#fff'
          }
        } else if(state == 'done') {
          return {
            bg: 'none',
            color: this.$store.state.themeColor
          }
        }
      },
      iconFilter(param) {
        if(param == 'mp4') {
          return {
            name: '视频',
            className: 'mp4-color icon'
          }
        } else if(param == 'mp3') {
          return {
            name: '音频',
            className: 'mp3-color icon'
          }
        } else if(param == 'pdf') {
          return {
            name: '文档',
            className: 'doc-color icon'
          }
        }
      }
    }
}
</script>

<style scoped>
.exam-icon {
  width: 72px;
  height: 63px;
}
.exam-item-wrap {
  padding: 0 30px;
}
.exam-btn-wrap {
  padding: 20px 0;
  text-align: center;
}
.exam-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  min-height: 104px;
}
.detailWrap .tabWrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.detailWrap {
    margin-bottom: 30px;
}
.detailWrap .tab {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}
.detailWrap .tab .item {
  cursor: pointer;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-right: 1px solid #eee;
  transition: all .2s;
}
.detailWrap .tabContent {
    border: 1px solid #eee;
    margin-right: 20px;
    padding: 0 30px 20px;
    width: 830px;
}
.detailWrap .tabContent .catalog {
    width: 100%;
}
.detailWrap .details {
    display: flex;
    justify-content: space-between;
}
.directory-title-wrap {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.directory-icon img:first-child {
  width: 10px;
  height: 10px;
}
.directory-icon img:last-child {
  width: 16px;
  height: 16px;
  margin-left: -7px;
}
.directory-title {
  font-size: 14px;
  color: #333;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
.directory-second-item {
  cursor: pointer;
  margin-left: 15px;
  border-bottom: 1px dashed #A6B7BF;
  display: flex;
  justify-content: center;
  /* margin-bottom: 20px; */
  align-items: center;
  height: 50px;
}
/* .directory-second-item-a:hover .directory-second-right button{
  display: block!important;
} */
/* .directory-second-item-a:hover .directory-second-right span {
  display: none!important;
} */
.directory-second-left {
  flex: 1;
}
.directory-second-right {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center;
  align-items: center; */
}
.directory-second-right button {
  width: 80px;
  height: 30px;
  font-size: 12px;
  border-radius: 15px;
  padding: 0;
  outline: none;
  border: none;
  /* display: none; */
  box-sizing: border-box;
}
.directory-second-right span {
  color: #666;
  font-size: 12px;
  height: 24px;
  line-height: 24px;
}
.directory-second-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* margin-bottom: 10px; */
}
.directory-second-title img {
  width: 16px;
  height: 16px;
}
.directory-second-subT {
  font-size: 12px;
  color: #666;
  padding-left: 20px;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.detailWrap .title {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    line-height: 20px;
    padding: 20px 0;
    text-align: left;
    margin-bottom: 0;
    position: relative;
}
.detailWrap .title::before {
  content: '';
  width: 4px;
  height: 12px;
  border-radius: 2px;
  background: #3091FD;
  position: absolute;
  left: -10px;
  top: 24px;
}
.detailWrap .txt {
    text-align: left;
    text-indent: 2em;
    margin-bottom: 20px;
    color: #333;
    font-size: 12px;
    line-height: 20px;
}
.exams ul li{
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    height: 160px;
}
.exams ul li h3 {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}
.exams ul li .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exams ul li .left p {
  margin-bottom: 0;
  font-size: 12px;
  color: #888;
  text-align: left;
  margin-bottom: 15px;
}
.exams ul li .left p span {
  padding-right: 10px;
}
.exams ul li button {
  border: none;
  font-size: 14px;
}
.exams ul li .pass_icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  height: 24px;
  width: 80px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
}
.certificate {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.certificate-wrap {
    width: 500px;
    margin-bottom: 30px;
    border-radius: 4px;
    overflow: hidden;
}
.down {
    cursor: pointer;
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-right: 1px solid #eee;
    background: rgb(0, 113, 245);
    color: rgb(255, 255, 255);
    display: block;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 4px;
}
.beautifull {
    font-weight: bold;
    font-size: 16px;
    padding: 10px 0;
    color: #333;
}
.side-bar-wrap {
  flex: 1;
}
.star-wrap {
  height: 230px;
  border: 1px solid #EBEFF2;
  padding: 0 20px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.star-wrap .star-module {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.star-wrap .star-txt {
  font-size: 12px;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}
.star-wrap .star-info {
  font-size: 12px;
  text-align: left;
  color: #999;
  padding-top: 20px;
}
.empty {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
.more {
  font-size: 12px;
  color: #666;
  cursor: pointer;
}
.teacher-wrap {
  /* height: 260px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.teacher-title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.teacher-title-wrap>div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.teacher-title-wrap .title-info {
  margin-left: 10px;
  color: #666;
  font-size: 12px;
  width: 120px;
}
.teacher-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
}
.teacher-avatar {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 15px;
}
.teacher-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.teacher-info span:first-child {
  font-size: 14px;
  color: #333;
}
.teacher-info span:last-child {
  font-size: 12px;
  color: #666;
}
.teacher-info-content {
  font-size: 12px;
  color: #666;
  text-align: left;
  line-height: 22px;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.recommended-wrap {
  /* height: 410px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.recommended-wrap .course-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 74px;
  margin-bottom: 10px;
  cursor: pointer;
}
.recommended-wrap .course-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}
.recommended-wrap .course-items img{
  margin-right: 15px;
  width: 132px;
  height: 74px;
  border-radius: 4px;
  object-fit: cover;
}
.recommended-wrap .course-info span:first-child {
  font-size: 14px;
  color: #333;
  text-align: left;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.recommended-wrap .course-info span:last-child {
  font-size: 12px;
  color: #666;
}
.qes-wrap {
  /* height: 355px; */
  border: 1px solid #EBEFF2;
  padding: 0 20px 10px;
  margin-bottom: 20px;
  border-radius: 2px;
}
.qes-wrap .news-item {
  font-size: 12px;
  color: #333;
  text-align: left;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.qes-wrap .news-item:hover {
  color: #3091FD;
  cursor: pointer;
}
.download-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dashed #A6B7BF;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.download-item img {
  width: 16px;
  height: 16px;
  margin-right: 10px;
}
.download-item-left, .download-item-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.download-item-left span {
  font-size: 14px;
  color: #333;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.download-item-right span {
  font-size: 12px;
  color: #666;
  margin-right: 20px;
}
.download-item-right a {
  width: 80px;
  display: block;
  height: 24px;
  line-height: 24px;
  background: #0071F5;
  color: #fff;
  font-size: 12px;
  padding: 0;
  border-radius: 12px;
  border: none;
  background-image: url('../../assets/images/download.png');
  padding-left: 18px;
  background-repeat: no-repeat;
  background-position: 5px center;
  background-size: 16px 16px;
}
.teacher-infos {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.teacher-infos>img {
  width: 56px;
  height: 56px;
  border-radius: 28px;
  margin-right: 15px;
}
.teacher-infos>div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.teacher-infos>div>span:first-child {
  font-size: 14px;
  color: #333;
  font-weight: bold;
}
.teacher-infos>div>span:last-child {
  font-size: 12px;
  color: #666;
}
.teacher-scroll {
  overflow-y: auto;
  height: 480px;
  background: #FAFAFA;
  padding:20px;
}
.teacher-scroll::-webkit-scrollbar {
  width:3px;
}
.teacher-scroll::-webkit-scrollbar-thumb {
  background-color:#aaa;
}
.teacher-items {
  margin-bottom: 15px;
  background: #fff;
  padding: 15px;
  border-radius: 5px;
}
.teacher-items>div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.teacher-items>p {
  font-size: 12px;
  color: #333;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.black {
  width: 100%;
  height: 360px;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1024px) {
    .detailWrap .tabWrap {
        flex-direction: column;
    }
    .detailWrap .tabContent {
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;
    }
    .detailWrap .tab .item {
        flex: 1;
    }
    .side-bar-wrap {
      width: 100%;
    }
}
.icon {
  font-size: 12px;
  padding: 0 6px;
  border-radius: 4px;
  line-height: 18px;
  height: 18px;
  box-sizing: content-box;
}
.mp4-color {
  color: rgb(8, 128, 241);
  border: 1px solid rgb(8, 128, 241);
}
.mp3-color {
  color: #52c41a;
  border: 1px solid #52c41a;
}
.doc-color {
  color: #faad14;
  border: 1px solid #faad14;
}
.btn-txt-info {
  font-size: 12px;
  color: #c00;
  line-height: 28px;
  padding: 0!important;
  display: inline-block;
}
.exam-start-wrap {
  padding: 20px;
}
.exam-top {
  background: #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}
.warn-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.warn-icon span {
  color: #333;
  font-size: 14px;
}
.start-txt {
  font-size: 15px;
  color: #333;
  line-height: 1;
  margin-bottom: 15px;
}
.start-do-wrap {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
