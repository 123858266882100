var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.$store.state.open_share == 1
    ? _c(
        "div",
        { staticClass: "modal" },
        [
          _c(
            "a-button",
            {
              staticClass: "btn-wrap",
              on: { mouseover: _vm.show, mouseout: _vm.hide },
            },
            [
              _c("img", { attrs: { src: _vm.src, alt: "" } }),
              _c("span", [_vm._v("分享")]),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              staticClass: "code-img",
            },
            [
              _c("img", { attrs: { src: _vm.imgData, alt: "" } }),
              _c(
                "p",
                {
                  staticStyle: {
                    "margin-bottom": "0",
                    "text-align": "center",
                    "padding-top": "10px",
                  },
                },
                [_vm._v("微信扫一扫")]
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }