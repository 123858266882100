<template>
    <div class="detail">
        <a-breadcrumb class="inner" style="padding:15px 0" v-if="$store.state.template == 1">
            <a-breadcrumb-item><span @click="back()">{{navMsg}}</span></a-breadcrumb-item>
            <a-breadcrumb-item>{{onMsg}}</a-breadcrumb-item>
        </a-breadcrumb>

        <div class="title-bg" :class="{'newbg':$store.state.tenant_idBind==3462}" v-if="$store.state.template == 2 || $store.state.template == 3">
            <div class="title-inner">
                <img src="../../assets/images/notice-icon.png" alt="">
                <h3>{{titleMsg()}}</h3>
            </div>
        </div>
        <div class="news-tab-wrap" v-if="$store.state.template == 2 || $store.state.template == 3">
            <ul class="news-tab">
                <li :style="index == item.id || $route.query.type == item.path.split('/')[1]? {color: $store.state.themeColor, borderColor: $store.state.themeColor}: {}" 
                v-for="(item, i) in menus" 
                :key="i"
                @click="checkTab(item, i)">{{item.name}}</li>
            </ul>
        </div>

        <div class="inner">
            <div class="title-wrap-modal">
                <h3 class="title" :style="{color: $store.state.themeColor}">{{detail.title}}</h3>
                <div v-if="$store.state.open_share == 1" style="width: 100%;display: flex;flex-direction: column;align-items: flex-end;padding-top: 20px;">
                    <Share :imgData="share_img"></Share>
                </div>
            </div>
            
            <p class="time">{{time}}</p>
            <div class="body"></div>
        </div>
    </div>
    
</template>

<script>
import {
    newsDetail,
    newsCate
} from '../../http/api'
import Share from '@/components/share/index.vue'
export default {
    data() {
        return {
            detail: '',
            navMsg: '',
            onMsg: '',
            time: '',
            index: 1,
          menus: [
            {
              id: 1,
              name: '公告通知',
              path: '/news'
            },
            {
              id: 2,
              name: '使用帮助',
              path: '/help'
            },
            {
              id: 3,
              name: '常见问题',
              path: '/qes'
            }
          ],
            share_img: String
        }
    },
    components: {
        Share
    },
    created() {
        if(this.$store.state.template == 3) {
            newsCate().then(res => {
                this.menus = res.data.data
            })
        } else {
            this.menus.map((item, i) => {
                if(item.path == '/help') {
                this.$store.state.cates.map((items, is) => {
                    this.menus.splice(i+1, 0, items)
                })
                }
            })
        }
    },
    mounted() {
        if(this.$route.query.type == 'notice' ||this.$route.query.type == 'index') {
            this.navMsg = '公告通知'
            this.onMsg = '公告详情'
        } else if(this.$route.query.type == 'help') {
            this.navMsg = '使用帮助'
            this.onMsg = '学习详情'
        }
        if(this.$route.query.type == 'help') {
            this.index = 2
        } else if(this.$route.query.type == 'notice') {
            this.index = 1
        } else if(this.$route.query.type == 'qes') {
            this.index = 3
        } else {
            this.index = this.$route.query.menuId
        }
        newsDetail({
            id: this.$route.query.id
        }).then(res => {
            if(res.data.code == 200) {
                this.detail = res.data.data.new
                this.time = res.data.data.new.created_at.split(" ")[0]
                this.share_img = res.data.data.share_img
                document.querySelector('.body').innerHTML = this.detail.body
            } else {
                this.$message.error(res.data.message)
            }
        }).catch(err => {
          
        })
    },
    methods: {
        titleMsg() {
        if(this.$route.query.type == 'notice') {
          return '公告通知'
        } else if(this.$route.query.type == 'help') {
          return '使用帮助'
        } else if(this.$route.query.type == 'qes') {
            return "常见问题"
        } else {
          return this.$route.query.type
        }
      },
        back() {
            this.$router.go(-1)
        },
        checkTab(item, i) {
            this.index = item.id
            if(this.$store.state.template == 3) {
                this.$router.push({
                    path: item.path,
                    query: {
                    id: item.id,
                    title: item.name
                    }
                })
            } else {
                if(item.path == '/wildcarda' || item.path == '/wildcardb' || item.path == '/wildcardc') {
                    this.$router.push({
                        path: item.path,
                        query: {
                        id: item.meta.id,
                        title: item.meta.title
                        }
                    })
                } else {
                    this.$router.push({
                        path: item.path
                    })
                }
            }
        }
    }
}
</script>
<style>
    .body img{
         width:100%;
         height:100%;
     }
</style>
<style scoped>
    
    .inner {
        width: 1200px;
        margin: 0 auto;
    }
    .detail {
        padding-bottom: 20px;
        text-align: left;
        display: block;
        padding:0 0 20px
    }
    .detail .title {
        font-weight: bold;
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 0;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 1200px;
    }
    .detail .time {
        color: #999;
        margin-bottom: 20px;
        text-align: left;
    }
    .detail .body {
        padding: 10px;
        border-radius: 4px;
        overflow: hidden;
        min-height: 600px;
        text-align: left;
    }
    @media screen and (max-width: 1024px) {
        .detail {
            width: 100%;
        }
    }
    .news-tab-wrap {
  height: 60px;
  box-shadow: 0 3px 5px #ccc;
  margin-bottom: 20px;
}
.news-tab {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.news-tab li {
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #fff;
}

.title-bg {
    height: 130px;
    background: url('../../assets/images/title-bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
}
.title-inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-inner img {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3 {
  font-size: 30px;
  margin-bottom: 0;
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}
.title-wrap-modal {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>