<template>
    <div class="study-detail">
        <div class="loadding" v-show="!show">
            <a-spin />
        </div>
        <transition name="fades">
         <div v-if="show">
         <a-breadcrumb v-if="$store.state.template == 1">
            <a-breadcrumb-item><router-link to="/course">学习广场</router-link></a-breadcrumb-item>
            <a-breadcrumb-item>{{course.title}}</a-breadcrumb-item>
        </a-breadcrumb>
        <div style="height: 30px" v-if="$store.state.template == 2 || $store.state.template == 3"></div>

        <div class="classesWrap">
            <div class="img">
                <img class="img_a" :src="course.picture" alt="">
            </div>
            <div class="info">
                <h3 class="title">{{course.title}}</h3>
                <p class="txt">{{course.infos}}</p>
                <div class="price-info" v-if="$store.state.can_pay == 1 && course.order_status == 0">
                  <span>价格</span>
                  <span class="red">￥{{course.price>0?course.price:'免费'}}</span>
                </div>
                <div class="go-btn-wrap">
                    <a-button v-if="course.type != 'live' && course.order_status == 1" @click="go(course,studyBtnState(course.learn_status))" :style="{background: studyBtnState(course.learn_status).bgColor, color: studyBtnState(course.learn_status).color, borderColor: studyBtnState(course.learn_status).bdColor}" size="large">{{studyBtnState(course.learn_status).msg}}</a-button>
                    <a-button v-if="$store.state.can_pay == 1 && course.order_status == 0 && course.price > 0" @click="buy" size="large" :style="{background: '#FF8611', color: '#fff',border: 'none'}">立即购买</a-button>
                    <a-button v-if="tryBtnStatus()" @click="go(course)" :style="{background: $store.state.themeColor, color: '#fff', borderColor: 'none', marginLeft: '10px'}" size="large">免费试看</a-button>
                </div>
                <p v-if="course.learn_status == 1" style="padding: 10px 0;">已学：{{course.gain_credit}}课时</p>
                <!-- <span style="padding-top: 20px;">
                    <a-rate @change="changeStar" v-model="value" :tooltips="desc" />
                    <span class="ant-rate-text">{{ desc[value - 1] }}</span>
                </span> -->
                <div v-if="$store.state.open_share == 1" style="width: 100%;display: flex;flex-direction: column;align-items: flex-end;padding-top: 20px;">
                    <Share :imgData="share_img"></Share>
                </div>
            </div>
        </div>
        <Common v-if="show" :exams="exams" :course="course" :lessons="lessons" :news="news" :relate_courses="relate_courses" :course_wares="course_wares" :rate="rate"></Common>
         </div>
        </transition>
    
    </div>
</template>

<script>
import Common from '@/components/common/index.vue'
import Share from '@/components/share/index.vue'
import {
    courseDetail,
    classDetail
} from '../../http/api'
export default {
    data() {
        return {
            show: false,
            course: {},
            news: [],
            lessons: [],
            exams: [],
            // value: 5,
            // desc: ['1分', '2分', '3分', '4分', '5分'],
            hostname: '',
            starMap: {},
            relate_courses: [],
            course_wares: [],
            rate: {},
            lessonId: '',
            share_img: String,
            classData:{}
        }
    },
    components: {
        Common,
        Share
    },
    created() {
        this.hostname = window.location.hostname
        this.update()
    },
    mounted() {
        console.log('this.$store.state.is_class_certificate',this.$store.state.is_class_certificate)
       
        localStorage.detaiPath = this.$route.fullPath;
        let that = this;
        if(this.$store.state.token){
             setTimeout(() => {
                that.getClassDetail();
            }, 500);
        }   
       
    },
    watch: {
      '$route'(to, from) {
        // console.info(to)
        if(to.path == '/course/studydetail') {
          this.show = false
          this.update()
        }
      }
    },
    methods: {
        getClassDetail(){
            console.log('33333333333333')
            let stateClassid = Array.isArray(this.$store.state.class_id)?this.$store.state.class_id[0]:this.$store.state.class_id
            let queryClass_id = Array.isArray(this.$route.query.class_id)?this.$route.query.class_id[0]:this.$route.query.class_id
            classDetail({
                id:stateClassid?stateClassid:queryClass_id
            }).then(res => {
                if(res.data.code == 200) {
                    let data = res.data.data.class;
                    this.classData = data;
                    this.$store.commit('ISCLASSCERTIFICATE',res.data.data.class.is_class_certificate)
                }
            })
        },
        // changeStar(val) {
        //     if(localStorage.map) {
        //         let map = JSON.parse(localStorage.map)
        //         map[this.course.id]= val
        //         localStorage.map = JSON.stringify(map)
        //     } else {
        //         this.starMap[this.course.id] = val
        //         localStorage.map = JSON.stringify(this.starMap)
        //     }
        // },
        tryBtnStatus() {
            if(this.$store.state.open_trial == 1) {
                if(this.$store.state.token) {
                    if(this.course.order_status == 0) {
                        return true
                    } else if(this.course.order_status == 1) {
                        return false
                    }
                } else {
                    if(this.course.order_status == 0) {
                        return true
                    } else if(this.course.order_status == 1) {
                        return false
                    }
                }
            } else if(this.$store.state.open_trial == 0) {
                return false
            }
        },
        buy() {
            if(this.$store.state.token) {
                this.$router.push({
                    path: '/pay',
                    query: {
                      course_id: this.course.id,
                      course_type: this.course.course_type
                    }
                })
            } else {
                this.$store.commit("SHOWLOGIN", true)
            }

        },
        update() {
            courseDetail({
                id: this.$route.query.id,
                course_type: this.$route.query.course_type,
                class_id: this.$route.query.class_id
            }).then(res => {
                this.show = true
                if(res.data.code == 200) {
                    this.course = res.data.data.course
                    this.news = res.data.data.news
                    this.lessons = res.data.data.chapters
                    this.exams = res.data.data.exams
                    this.relate_courses = res.data.data.relate_courses
                    this.course_wares = res.data.data.course_wares
                    this.share_img = res.data.data.course.share_img
                    if(res.data.data.pre_lesson.lesson_id && res.data.data.pre_lesson.lesson_id != 0) {
                        this.lessonId = res.data.data.pre_lesson.lesson_id
                    }
                    if(res.data.data.rate) {
                      this.rate = res.data.data.rate
                    }
                } else if(res.data.code == 104) {
                    this.$message.error(res.data.message)
                } else {
                    this.$message.error(res.data.message)
                }
                if(localStorage.map) {
                  let map = JSON.parse(localStorage.map)
                  for(let key in map) {
                      if(key == this.course.id) {
                          this.value = map[key]
                      }
                  }
                }


            }).catch(err => {
                this.show = true
                this.$message.error(err.response.data.message)
            })
        },
        go(course,status) {
            console.log('status',status)
            if(status.msg=='已完成'&&this.course.class_result_status!=2){
                 this.$messageBox.confirm('课程已完成，还需完成并通过考试',{
                      confirmButtonText: "去考试",
                 }).then(_ => {
                        if(this.$store.state.token) {
                            this.$router.push({
                                path:'/class/exam',
                                query:{
                                    id:this.$route.query.class_id
                                }
                            })
                        }else{
                            this.$store.commit("SHOWLOGIN", true)
                        }
                }).catch(_ => {
                      if(this.$store.state.token) {
                        this.$router.push({
                            path: '/course/player',
                            query: {
                                id: course.id,
                                course_type: course.course_type,
                                title: this.title,
                                class_id: this.$route.query.class_id,
                                lessonId: this.lessonId
                            }
                        })
                    } else {
                        this.$store.commit("SHOWLOGIN", true)
                    }
                });
              
            }else{
                 if(this.$store.state.is_class_certificate===0){
              let sessionObj = JSON.parse(sessionStorage.session)
              let session = {
                certification: sessionObj.certification,
                token: sessionObj.token,
                type: 4, //班级人脸
                class_id:this.$route.query.class_id,
                callback:'/course/player?id='+course.id+'&course_type='+course.course_type+'&lessonId='+this.lessonId+'&title='+this.title
              }
              let sessionStr = JSON.stringify(session)
              sessionStorage.setItem('session', sessionStr)
              console.log('sessionObj.token000000000',sessionObj.token) 
              this.$store.commit("SETTOKEN", sessionObj.token)
              this.$store.commit('FACEVISIBLE', true)
         }else{
            if(course.type == 'link' && course.course_type == 'system_course') {
                if(this.$store.state.token) {
                    window.open(course.link_address)
                } else {
                    this.$store.commit("SHOWLOGIN", true)
                }
            } else {
                if(this.$store.state.open_trial == 0) {  // 不能试看
                if(this.$store.state.token) {
                    this.$router.push({
                        path: '/course/player',
                        query: {
                            id: course.id,
                            course_type: course.course_type,
                            title: this.title,
                            class_id: this.$route.query.class_id,
                            lessonId: this.lessonId
                        }
                    })
                } else {
                    this.$store.commit("SHOWLOGIN", true)
                }
            } else if(this.$store.state.open_trial == 1) {   // 可以试看
            
                this.$router.push({
                    path: '/course/player',
                    query: {
                        id: course.id,
                        course_type: course.course_type,
                        title: this.title,
                        class_id: this.$route.query.class_id,
                        lessonId: this.lessonId
                    }
                })
            }
            }
         }
            }
           
        },
        studyBtnState(state) {
        if(state == 0) {
          return {
            msg: '开始学习',
            bgColor: this.$store.state.themeColor,
            color: '#fff',
            bdColor: '#fff'
          }
        }else if(state == 1) {
          return {
            msg: '继续学习',
            bgColor: '#ff8611',
            color: '#fff',
            bdColor: '#fff'
          }
        } else if(state == 2) {
          return {
            msg: '已完成',
            bgColor: '#fff',
            color: this.$store.state.themeColor,
            bdColor: this.$store.state.themeColor
          }
        }
      }

    }
}
</script>

<style scoped>
button:hover {
  color: #fff;
}
.study-detail {
    width: 1200px;
    margin: 0 auto;
}
.study-detail .ant-breadcrumb{
    text-align: left;
    margin: 10px 0;
    width: 200px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.study-detail .classesWrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}
.study-detail .img {
    width: 560px;
    height: 315px;
    margin-right: 30px;
    border-radius: 4px;
    overflow: hidden;
}
.study-detail .img img {
    object-fit: cover;
}
.study-detail .info {
    height: 315px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}
.study-detail .title {
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    min-height: 24px;
}
.study-detail .txt {
    text-align: left;
    margin-bottom: 30px;
    color: #666;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    min-height: 80px;
    line-height: 20px;
}
.price-info {
  height: 52px;
  background: #E1E8EB;
  line-height: 52px;
  width: 100%;
  margin-bottom: 20px;
  text-align: left;
  padding: 0 10px;
  color: #666;
  border-radius: 4px;
}
.red {
  color: #FC583D;
  font-weight: bold;
  margin-left: 10px;
}
@media screen and (max-width: 1024px) {
    .study-detail {
        width: 100%;
    }
    .study-detail .classesWrap {
        flex-direction: column;
        height: auto;
    }
    .study-detail .img {
        width: 100%;
        height: 240px;
        margin-right: 0;
        padding-top: 20px;
    }
    .study-detail .info {
        height: auto;
    }
}
@media screen and (min-width:768px) and (max-width:1024px){
    .study-detail .img {
        height: 480px;
    }
}
</style>
