<template>
  <div class="setuser">
    <h3 class="title" :style="{borderColor: $store.state.themeColor}">基本信息</h3>
    <div class="content">
      <div class="con-top">
      <ul class="form">
        <li class="item">
          <label for=""><span class="red">*</span>姓名</label>
          <a-input v-model="realname" placeholder="请输入姓名" />
        </li>
        <li class="item">
            <label for=""><span class="red">*</span>性别</label>
          <a-radio-group v-model="sex" @change="onChangeSex">
            <a-radio :value="0">
              男
            </a-radio>
            <a-radio :value="1">
              女
            </a-radio>
          </a-radio-group>
        </li>
        <li class="item" v-if="hostname != 'iteq.zckt.tv'">
          <label for=""><span class="red">*</span>身份证号</label>
          <a-input v-model="idcard" placeholder="请输入身份证号" />
        </li>
        <li class="item" v-if="hostname != 'iteq.zckt.tv'">
          <label for=""><span class="red">*</span>用户名</label>
          <a-input v-model="student_num" placeholder="请输入用户名" />
        </li>
        <li class="item" v-if="hostname != 'iteq.zckt.tv'">
          <label for=""><span class="red">*</span>手机号</label>
          <a-input readonly="true" style="background: #eee;border: none;" v-model="mobile" placeholder="请输入手机号" />
        </li>
        <li class="item">
          <label for="">邮箱</label>
          <a-input v-model="email" placeholder="请输入邮箱" />
        </li>
        <li class="item" v-if="$store.state.can_department == 1">
          <label for=""><span v-if="$store.state.register_department == 2" class="red">*</span>所属部门</label>
          <div class="select-wrap">
            <a-select
            v-if="floor_F_show"
              size="large"
              show-search
              :defaultValue="defaultMsgF"
              option-filter-prop="children"
              style="width: 230px;height: 40px;font-size: 14px;margin-right: 10px;"
              :filter-option="filterOption"
              @change="handleChangeDepartment"
            >
              <a-select-option v-for="(item, i) in departmentFirst" :key="i" :value="item.id+'-'+1" :options="item">
                {{item.name}}
              </a-select-option>
            </a-select>
            <a-select
              v-if="floor_S_show"
              size="large"
              show-search
              :defaultValue="defaultMsgS"
              option-filter-prop="children"
              style="width: 230px;height: 40px;font-size: 14px;margin-right: 10px;"
              :filter-option="filterOption"
              @change="handleChangeDepartment"
            >
              <a-select-option v-for="(item, i) in departmentSecond" :key="i" :value="item.id+'-'+2" :options="item">
                {{item.name}}
              </a-select-option>
            </a-select>
            <a-select
              v-if="floor_T_show"
              size="large"
              show-search
              :defaultValue="defaultMsgT"
              option-filter-prop="children"
              style="width: 230px;height: 40px;font-size: 14px;"
              :filter-option="filterOption"
              @change="handleChangeDepartment"
            >
              <a-select-option v-for="(item, i) in departmentThird" :key="i" :value="item.id+'-'+3" :options="item">
                {{item.name}}
              </a-select-option>
            </a-select>
          </div>
        </li>
        <li class="item">
          <label>
            <div class="wechat" for="">
              <img src="../../assets/images/wechat.png" alt="">
            </div>
          </label>
          <div class="bindBtn" :style="{color: $store.state.themeColor}">
            <span @click="bindWechat()" v-if="is_bind_weixin == 0">绑定微信</span>
            <span @click="unbind()" v-if="is_bind_weixin == 1">解绑微信</span>
            <span @click="bindWechat()" v-if="is_bind_weixin == 1">换绑微信</span>
          </div>
        </li>
      </ul>
      <ul class="form" style="width: 500px;">
        <li class="item" style="margin-bottom: 0">
          <label for="">证件照</label>
          <label for="ups" class="photo-up-wrap" @mouseover="showChange" @mouseout="outChange">
            <span v-if="!imageData">+</span>
            <img v-if="imageData" :src="imageData" alt="">
            <span class="hoverBg" v-show="changeVisible">修改证件照</span>
          </label>
          <input id="ups" type="file" @change="upImgCert" ref="inputerCert" title="" style="display: none">
        </li>
        <li class="item" style="margin-bottom: 0;">
          <label for="" style="color: #aaa"></label>
          <p class="annotation">证件照要求：尺寸 413像素*295像素   格式：仅支持png   大小：不超过100K</p>

        </li>
        <li class="item" style="height: 30px;">
          <label for="" style="color: #aaa"></label>
          <p class="annotation" style="font-weight: bold; color: #666;">二寸照将用于证书展示，请认真上传该照片</p>
        </li>

      </ul>
      </div>
      <div class="do">
        <a-button @click="submitMethod" :disabled="one && two && three?false:true" :style="{background:one && two && three?$store.state.themeColor:'#ccc'}">保存</a-button>
        <a-button @click="reset()" class="resetBtn">重置</a-button>
      </div>
    </div>
    <h3 class="title" v-if="$store.state.open_check_realname == 1" :style="{borderColor: $store.state.themeColor}">安全信息</h3>
    <div class="idcard-content" v-if="$store.state.open_check_realname == 1">
      <span style="font-size: 14px;font-weight: bold;margin-right: 15px;">实名认证</span>
      <div class="idcard-status" v-if="data.certification_idcard == 1">
        <span style="color: rgb(93, 204, 98);">已认证</span>
        <img src="../../assets/images/suc.png" alt="">
      </div>
      <div class="idcard-status" v-if="data.certification_idcard == 0">
        <span style="color: rgb(250, 37, 44);">未认证</span>
        <img src="../../assets/images/er.png" alt="">
      </div>
      <a-button @click="openIdModule" :style="{background: $store.state.themeColor}">{{data.certification_idcard == 0? '点击认证': '点击查看'}}</a-button>
    </div>

    <a-modal
    v-model="visibleIdcard"
    title="实名认证"
    :footer="null"
    width="700px">
    <div>
      <div class="top-msg">
        <img src="../../assets/images/warn.png" alt="">
        <div>
          <p>应学习要求，学员需进行实名认证。请按照要求上传身份证正反面图片</p>
          <p style="margin-bottom: 0;">身份证上传要求：光线充足，图片无任何遮挡；上传图片大小不超过1.5M</p>
        </div>
      </div>
      <div class="item">
        <div class="card-wrap">
            <label for="positive" class="positive">
              <div class="card-bg" v-if="!positiveData">
                <img src="../../assets/images/positive.png" alt="">
                <span>点击上传身份证人像面</span>
              </div>
              <img class="card-data" :src="positiveData" alt="" v-if="positiveData">
            </label>
            <label for="other-side" class="other-side">
              <div class="card-bg" v-if="!otherSideData">
                <img src="../../assets/images/other-side.png" alt="">
                <span>点击上传身份证国徽面</span>
              </div>
              <img class="card-data" :src="otherSideData" alt="" v-if="otherSideData">
            </label>
          <input @change="positive" type="file" id="positive" ref="positive" style="display: none;">
          <input @change="otherSide" type="file" id="other-side" ref="other" style="display: none;">
        </div>
      </div>
      <div class="card-do">
        <a-button @click="cancel" style="color: #999!important;border: 1px solid #ccc;">取消</a-button>
        <a-button @click="authentication" :disabled="!flag" :style="{background: flag? $store.state.themeColor: '#ddd'}">{{data.id_card_front && data.id_card_back? '重新认证': '提交认证'}}</a-button>
      </div>
    </div>
    </a-modal>

    <a-modal
    v-model="visibleCert"
    title="证件照裁剪"
    :footer="null"
    width="550px"
    :maskClosable="false"
    :bodyStyle="{
      width: '550px',
      height: '550px',
      display: 'flex',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
      }">
      <div style="width: 500px; height: 500px; margin-bottom: 20px">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixedNumber="option.fixedNumber"
          :fixed="option.fixed"
          :autoCrop="true"
        ></vueCropper>
      </div>
      <div>
        <div style="display: flex;">
          <a-button size="large" @click="save()" :style="{background: $store.state.themeColor, marginRight: '20px', color: '#fff!important'}">保存</a-button>
          <label for="ups" style="padding: 0 15px; border-radius: 6px; height: 40px; background: #eee; display: block; cursor: pointer; line-height: 40px; text-align: center;">重新上传</label>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  upUserInfo,
  unBind,
  getWechat,
  departmentList,
  upIdcard,
  setIdcard
} from '../../http/api'
import { VueCropper }  from 'vue-cropper'

export default {
    data() {
        return {
          changeVisible: false,
          visibleCert: false,
          realname: '',
          idcard: '',
          mobile: '',
          email: '',
          one: false,
          two: false,
          three: false,
          four: false,
          is_bind_weixin: "",
          hostname: "",
          loading: false,
          imageData: '',
          floor: 0,
          has_more: 0,
          departmentFirst: [],
          departmentSecond: [],
          departmentThird: [],
          department_id: '',
          floor_F_show: false,
          floor_S_show: false,
          floor_T_show: false,
          department: '',
          defaultMsgF: '搜索并选择部门信息',
          defaultMsgS: '搜索并选择部门信息',
          defaultMsgT: '搜索并选择部门信息',
          option: {
            img: '',
            size: 1,
            outputType: 'jpeg',
            fixed: true,
            autoCropWidth: 295,
            autoCropHeight: 413,
            fixedNumber: [0.71, 1]
          },
          sex: 0,
          upCertFlag: false,
          positiveData: '',
          otherSideData: '',
          visibleIdcard: false,
          doUpFront: false,
          doUpBack: false,
          flag: false,
          student_num: ''
        }
    },
    props: {
      data: Object
    },
    components: {
      VueCropper
    },
    watch: {
      'realname'(n, o) {
        if(n) {
          this.one = true
        } else {
          this.one = false
        }
      },
      'idcard'(n, o) {
        if(n) {
          this.two = true
        } else {
          this.two = false
        }
      },
      'mobile'(n, o) {
        if(n) {
          this.three = true
        } else {
          this.three = false
        }
      },
      'email'(n, o) {
        if(n) {
          this.four = true
        } else {
          this.four = false
        }
      },
    },
    created() {
      this.hostname = window.location.hostname
    },
    mounted() {
      console.info(this.data.department)
      departmentList().then(res => {
        if(res.data.code == 200) {
          this.departmentFirst = res.data.data
          this.departmentFirst.map((item, i) => {
            if(item.id == this.data.department[0].id) {
              if(item.has_more) {
                departmentList({
                  parent_id: item.id
                }).then(res => {
                  if(res.data.code == 200) {
                    this.departmentSecond = res.data.data
                    this.departmentSecond.map((items, i) => {
                      if(items.id == this.data.department[1].id) {
                        if(items.has_more) {
                          departmentList({
                            parent_id: items.id
                          }).then(res => {
                            if(res.data.code == 200) {
                              this.departmentThird = res.data.data
                            }
                          })
                        }
                      }
                    })
                  }
                })
              }
            }
          })
        }
      })
      this.realname = this.data.realname
      this.sex = this.data.gender
      this.idcard = this.data.idcard
      this.mobile = this.data.mobile
      this.email = this.data.email
      this.is_bind_weixin = this.data.is_bind_weixin
      this.imageData = this.data.id_photo
      this.positiveData = this.data.id_card_front
      this.otherSideData = this.data.id_card_back
      this.student_num = this.data.username

      if(this.data.department.length == 0 || this.data.department.length == 1 || this.data.department.length == 2 || this.data.department.length == 3) {
        this.floor_F_show = true
        this.defaultMsgF = this.data.department[0].name
      }
      if(this.data.department.length == 2 || this.data.department.length == 3) {
        this.floor_S_show = true
        this.defaultMsgS = this.data.department[1].name
      }
      if(this.data.department.length == 3) {
        this.floor_T_show = true
        this.defaultMsgT = this.data.department[2].name
        this.department_id = this.data.department[2].id
      }
      if(this.data.department.length == 2) {
        this.department_id = this.data.department[1].id
      }
      if(this.data.department.length == 1) {
        this.department_id = this.data.department[0].id
      }
    },
    methods: {
      cancel() {
        this.visibleIdcard = false
      },
      openIdModule() {
        if(this.data.id_card_front && this.data.id_card_back) {  // 第二次重新认证
          this.positiveData = this.data.id_card_front
          this.otherSideData = this.data.id_card_back
        }

        this.visibleIdcard = true
      },
      onChangeSex(e) {
        this.sex = e.target.value
      },
      handleChangeDepartment(value, option) {
        let id = value.split('-')[0]
        this.floor = value.split('-')[1]
        this.has_more = option.data.attrs.options.has_more
        console.info(value)
        console.info(option)
        departmentList({
          parent_id: id
        }).then(res => {
          if(res.data.code == 200) {
            if(this.floor == 1) {
              if(res.data.data.length > 0) {
                this.floor_S_show = false
                this.floor_T_show = false
                setTimeout(() => {
                  this.departmentSecond = res.data.data
                  this.defaultMsgS = res.data.data[0].name
                  this.department_id = res.data.data[0].id
                  this.floor_S_show = true
                  if(res.data.data[0].has_more) {
                    departmentList({
                      parent_id: res.data.data[0].id
                    }).then(res => {
                      if(res.data.code == 200) {
                         if(res.data.data.length > 0) {
                          this.departmentThird = res.data.data
                          this.defaultMsgT = res.data.data[0].name
                          this.department_id = res.data.data[0].id
                          this.floor_T_show = true
                        } else {
                          this.department_id = id
                        }
                      }
                    })
                  }
                }, 200)
              } else {
                this.floor_S_show = false
                this.floor_T_show = false
                this.department_id = id
              }
            } else if(this.floor == 2) {
              if(res.data.data.length > 0) {
                this.floor_T_show = false
                setTimeout(() => {
                  this.defaultMsgT = res.data.data
                  this.defaultMsgT = res.data.data[0].name
                  this.department_id = res.data.data[0].id
                  this.floor_T_show = true
                }, 200)
              } else {
                this.floor_T_show = false
                this.department_id = id
              }
            } else if(this.floor == 3) {
              this.department_id = id
            }
          }
        })
      },
      filterOption(input, option) {
        return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
        );
      },
      bindWechat() {
        getWechat({
          authclient: 'weixin',
          jump: window.location.hostname
        }).then(res => {
          if(res.data.code == 200) {
            window.location.href = res.data.url
          }
        })
      },
      unbind() {
        unBind().then(res => {
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            window.location.reload()
          } else {
            this.$message.error(res.data.message)
          }
        })
      },
      showChange() {
        if(this.imageData) {
          this.changeVisible = true
        }
      },
      outChange() {
        this.changeVisible = false
      },
      upImgCert(e) {
        let inputDOM = this.$refs.inputerCert
        let file = inputDOM.files
        console.info(file[0])
        let reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.onload = () => {
          this.option.img = reader.result
          e.target.value = ''
          this.visibleCert = true
        }
      },
      positive(e) {
        let inputDOM = this.$refs.positive
        let file = inputDOM.files
        console.info(file[0])
        let reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.onload = () => {
          this.positiveData = reader.result
          e.target.value = ''
          upIdcard({
            file: this.positiveData,
            side: 0
          }).then(res => {
            if(res.data.code == 200) {
              this.$message.success(res.data.message)
              this.positiveData = res.data.data.url
              this.doUpFront = true
              if(this.data.id_card_front && this.data.id_card_back) {  // 第二次重新认证
                if(this.doUpFront || this.doUpBack) {
                  this.flag = true
                } else {
                  this.flag = false
                }
              } else {  // 第一次认证
                if(this.doUpFront && this.doUpBack) {
                  this.flag = true
                } else {
                  this.flag = false
                }
              }
            } else {
              this.$message.error(res.data.message)
              this.positiveData = ''
              this.flag = false
            }
          }).catch(err => {
            this.positiveData = ''
          })
        }
      },
      otherSide(e) {
        let inputDOM = this.$refs.other
        let file = inputDOM.files
        console.info(file[0])
        let reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.onload = () => {
          this.otherSideData = reader.result
          e.target.value = ''
          upIdcard({
            file: this.otherSideData,
            side: 1
          }).then(res => {
            if(res.data.code == 200) {
              this.$message.success(res.data.message)
              this.otherSideData = res.data.data.url
              this.doUpBack = true
              if(this.data.id_card_front && this.data.id_card_back) {  // 第二次重新认证
                if(this.doUpFront || this.doUpBack) {
                  this.flag = true
                } else {
                  this.flag = false
                }
              } else {  // 第一次认证
                if(this.doUpFront && this.doUpBack) {
                  this.flag = true
                } else {
                  this.flag = false
                }
              }
            } else {
              this.$message.error(res.data.message)
              this.otherSideData = ''
              this.flag = false
            }
          }).catch(err => {
            this.otherSideData = ''
          })
        }
      },
      authentication() {
        if(this.flag) {
          if(this.positiveData && this.otherSideData) {
            setIdcard({
              id_card_front: this.positiveData,
              id_card_back: this.otherSideData
            }).then(res => {
              if(res.data.code == 200) {
                this.$message.success(res.data.message)
                this.data.certification_idcard = 1
                this.visibleIdcard = false
                this.data.id_card_front = res.data.data.id_card_front
                this.data.id_card_back = res.data.data.id_card_back
                this.positiveData = res.data.data.id_card_front
                this.otherSideData = res.data.data.id_card_back
                this.realname = res.data.data.realname
                this.idcard = res.data.data.idcard
                this.sex = res.data.data.gender
                this.$store.commit("SETREALNAME", res.data.data.realname)
              }
            })
          } else {
            this.$message.error('请上传证件照信息')
          }
        } else {
          this.$message.error('请修改证件照信息')
        }
      },
      save() {
        this.$refs.cropper.getCropData((data) => {
          this.visibleCert = false
          this.imageData = data
          this.upCertFlag = true
        })
      },
      submitMethod() {
        let reg = /^[\u4e00-\u9fa5]{0,}$/
        if(this.realname == "") {
          this.$message.error('请输入姓名')
        } else if(!reg.test(this.realname)) {
          this.$message.error('真实姓名请输入中文')
        } else if(this.idcard == "") {
          this.$message.error('请输入身份证号')
        } else if(this.student_num == "") {
          this.$message.error('请输入用户名')
        } else if(this.mobile == "") {
          this.$message.error('请输入手机号')
        } else {
          upUserInfo({
            realname: this.realname,
            gender: this.sex,
            idcard: this.idcard,
            mobile: this.mobile,
            email: this.email,
            id_photo: this.upCertFlag? this.imageData: '',
            department_id: this.department_id,
            username: this.student_num
          }).then(res => {
            if(res.data.code == 200) {
              this.data.gender = this.sex
              this.data.realname = this.realname
              this.data.idcard = this.idcard
              this.$message.success(res.data.message)
              this.$store.commit("SETREALNAME", this.realname)
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {

        })
        }

      },
      reset() {
        this.realname = ''
        this.idcard = ''
        this.email = ''
        this.imageData = ''
      }
    }
}
</script>

<style scoped>
/*
认证模块
*/
.idcard-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.idcard-status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;
}
.idcard-status img {
  width: 20px;
  height: 20px;
}
.idcard-status span {
  font-size: 14px;
  margin-right: 5px;
}


button {
  color: #fff!important;
  border: none;
}
.resetBtn {
  background: #fff;
  color: #333!important;
  border: 1px solid #bbb;
}
.resetBtn:hover {
  color: #333;
}
  .setuser {
    padding: 20px;
    border: 1px solid #eee;
    min-height: 500px;
  }
  .bindBtn span {
    cursor: pointer;
    margin-right: 20px;
  }
  .setuser .content {
    padding: 20px;
    margin-bottom: 30px;
    border-bottom: 1px solid #eee;
  }
  .content .form {
    width: 320px;
  }
  .setuser .title {
    border-left: 4px solid #fff;
    text-align: left;
    padding-left: 10px;
    font-weight: bold;
    line-height: 20px;
    font-size: 16px;
  }
  .setuser label {
    min-width: 90px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 4px;
    overflow: hidden;
  }
  .setuser .item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
  }
  .setuser .item input {
    width: 240px;
    text-align: left;
    height: 40px;
  }
  .setuser .do {
    margin-bottom: 20px;
  }
  .setuser .do button:first-child {
    margin-right: 20px;
  }
  .wechat {
    width: 40px;
    height: 40px;
    margin: 0 auto;
  }
  .annotation {
    color: #aaa;
    white-space: nowrap;
    margin: 0;
  }
  .select-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 1024px) {
    .setuser {
      width: 100%;
      min-height: 400px;
    }
    .setuser .content {
      width: auto;
      margin: 0;
    }
    .setuser .title {
      font-size: 16px;
      margin-bottom: 20px;
    }
  }
  .photo-up-wrap {
    width: 142px!important;
    height: 200px!important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #eee;
    font-size: 50px;
    color: #999;
    cursor: pointer;
    position: relative;
  }
  .hoverBg {
    color: #fff;
    font-size: 14px;
    height: 30px;
    width: 100%;
    line-height: 30px;
    background: rgba(0, 0, 0, .7);
    position: absolute;
    left: 0;
    bottom: 0;
  }
  .con-top {
    display: flex;
    justify-content: space-around;
  }
  .positive, .other-side {
    border: 1px dashed #ccc;
    border-radius: 6px;
    width: 315px;
    height: 190px!important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
   .positive:hover, .other-side:hover {
     border-color: rgb(8, 128, 241);
   }
  .card-bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .card-bg img {
    width: 74px;
    height: 48px;
    margin-bottom: 10px;
  }
   .positive span, .other-side span {
     display: block;
     font-size: 14px;
     line-height: 16px;
     color: rgb(8, 128, 241);
   }
  .card-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .card-data {
    width: 100%;
    height: 100%;
  }
  .top-msg {
    background: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 10px;
    margin-bottom: 30px;
  }
  .top-msg img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .top-msg p {
    font-size: 14px;
    color: #666;
  }
  .card-do {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card-do button {
    width: 130px;
    height: 40px;
    margin: 0 10px;
  }
</style>
