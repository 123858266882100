var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "detail" },
    [
      _vm.$store.state.template == 1
        ? _c(
            "a-breadcrumb",
            { staticClass: "inner", staticStyle: { padding: "15px 0" } },
            [
              _c("a-breadcrumb-item", [
                _c(
                  "span",
                  {
                    on: {
                      click: function ($event) {
                        return _vm.back()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.navMsg))]
                ),
              ]),
              _c("a-breadcrumb-item", [_vm._v(_vm._s(_vm.onMsg))]),
            ],
            1
          )
        : _vm._e(),
      _vm.$store.state.template == 2 || _vm.$store.state.template == 3
        ? _c(
            "div",
            {
              staticClass: "title-bg",
              class: { newbg: _vm.$store.state.tenant_idBind == 3462 },
            },
            [
              _c("div", { staticClass: "title-inner" }, [
                _c("img", {
                  attrs: {
                    src: require("../../assets/images/notice-icon.png"),
                    alt: "",
                  },
                }),
                _c("h3", [_vm._v(_vm._s(_vm.titleMsg()))]),
              ]),
            ]
          )
        : _vm._e(),
      _vm.$store.state.template == 2 || _vm.$store.state.template == 3
        ? _c("div", { staticClass: "news-tab-wrap" }, [
            _c(
              "ul",
              { staticClass: "news-tab" },
              _vm._l(_vm.menus, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    style:
                      _vm.index == item.id ||
                      _vm.$route.query.type == item.path.split("/")[1]
                        ? {
                            color: _vm.$store.state.themeColor,
                            borderColor: _vm.$store.state.themeColor,
                          }
                        : {},
                    on: {
                      click: function ($event) {
                        return _vm.checkTab(item, i)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
          ])
        : _vm._e(),
      _c("div", { staticClass: "inner" }, [
        _c("div", { staticClass: "title-wrap-modal" }, [
          _c(
            "h3",
            {
              staticClass: "title",
              style: { color: _vm.$store.state.themeColor },
            },
            [_vm._v(_vm._s(_vm.detail.title))]
          ),
          _vm.$store.state.open_share == 1
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "flex-end",
                    "padding-top": "20px",
                  },
                },
                [_c("Share", { attrs: { imgData: _vm.share_img } })],
                1
              )
            : _vm._e(),
        ]),
        _c("p", { staticClass: "time" }, [_vm._v(_vm._s(_vm.time))]),
        _c("div", { staticClass: "body" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }