<template>
    <div class="study_report inner" v-if="show">
        <a-button :loading="loading" @click="down" class="down-btn">点击下载PDF学习报告</a-button>
        <div class="top">
            <h3>学习档案</h3>
            <div>
                <img src="../../assets/images/report_logo.png" alt="">
            </div>
        </div>
        <div class="content" :style="{width: isDone? '1060px': '1260px', top: isDone? '150px': '0'}">
            <div class="people-info">
                <div class="avatar-wrap" :style="{paddingLeft: isDone?'0': '100px'}">
                    <img v-if="isDone" class="avatar" :src="user.avatar" alt="">
                    <div class="name">
                        <h3>{{user.realname}}</h3>
                        <span>{{user.regist_time+'-'+user.report_time}}</span>
                    </div>
                </div>
                <div class="study-learn-wrap">
                    <span class="learn-txt">总计获得学时</span>
                    <span class="learn-number">{{user.period_totals}}</span>
                </div>
            </div>

            <ul class="lattice">
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">完成课程</span>
                        <span class="lattice-number">{{user.finish_course_total}}</span>
                    </div>
                </li>
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">已通过考试</span>
                        <span class="lattice-number">{{user.passed_exam_totals}}</span>
                    </div>
                </li>
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">获得证书</span>
                        <span class="lattice-number">{{user.obtain_totals}}</span>
                    </div>
                </li>
            </ul>

            <ul class="lattice" style="margin-bottom: 30px;">
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">参加直播</span>
                        <span class="lattice-number">{{user.attend_live_totals}}</span>
                    </div>
                </li>
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">签到</span>
                        <span class="lattice-number">{{user.sign_totals}}</span>
                    </div>
                </li>
                <li>
                    <div class="study-learn-wrap">
                        <span class="learn-txt">班级</span>
                        <span class="lattice-number">{{user.class_totals}}</span>
                    </div>
                </li>
            </ul>

            <div class="item" v-if="learns.length > 0">
                <div :style="{marginBottom: isDone? '0': '-16px'}" class="item-bar"></div>
                <h3>我的课程</h3>
            </div>
            <table v-if="learns.length > 0">
                <thead>
                    <tr>
                        <th style="width: 35%;">课程</th>
                        <th style="width: 35%;">学习时间</th>
                        <th style="width: 30%;">完成度</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in learns" :style="{background: i%2!=0? '#eee': '#fff'}">
                        <td>{{item.title}}</td>
                        <td>{{item.start_learn_date+'-'+item.last_learn_date}}</td>
                        <td>
                            <div class="progress-wrap">
                                <div class="bar" :style="{width: item.finish_percent * 2+'px'}"></div>
                                <span>{{item.finish_percent}}%</span>    
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="item" v-if="exams.length > 0">
                <div :style="{marginBottom: isDone? '0': '-16px'}" class="item-bar"></div>
                <h3>考试</h3>
            </div>
            <table v-if="exams.length > 0">
                <thead>
                    <tr>
                        <th style="width: 35%;">课程</th>
                        <th style="width: 35%;">学习时间</th>
                        <th style="width: 30%;">结果</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in exams" :key="i" :style="{background: i%2!=0? '#eee': '#fff'}">
                        <td>{{item.testpaper.name}}</td>
                        <td>{{item.begin_time+'-'+item.end_time}}</td>
                        <td>
                            <div :style="{color: item.passed_status == 'unpassed'? '#c00': '#52c41a'}" class="status">{{item.passed_status == 'unpassed'? '未通过': '通过'}}</div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="item" v-if="obtains.length > 0">
                <div :style="{marginBottom: isDone? '0': '-16px'}" class="item-bar"></div>
                <h3>证书</h3>
            </div>
            <table v-if="obtains.length > 0">
                <thead>
                    <tr>
                        <th style="width: 35%;">证书</th>
                        <th style="width: 35%;">获得时间</th>
                        <th style="width: 30%;">证书来源</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in obtains" :style="{background: i%2!=0? '#eee': '#fff'}">
                        <td>{{item.certificate.certificate_name}}</td>
                        <td>{{item.created_at}}</td>
                        <td>{{item.resource_info.title}}</td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
import html2canvas from 'html2canvas'
import {
    getPdf,
    genPdf
} from '../../http/api'
export default {
    data() {
        return {
            user: null,
            learns: null,
            exams: null,
            obtains: null,
            show: false,
            objData: {},
            isDone: false,
            loading: false,
            n: null,
            parseIntNumber: null,
        }
    },
    mounted() {
        getPdf().then(res => {
            if(res.data.code == 200) {
                this.user = res.data.data.user
                this.learns = res.data.data.learns
                this.exams = res.data.data.exams
                this.obtains = res.data.data.obtains
                this.show = true
                this.$nextTick(() => {
                    let scrollHeight = document.querySelector('.content').scrollHeight  // 文档全文高度
                    this.n = scrollHeight / 1700
                    this.parseIntNumber = parseInt(this.n)
                    if(this.n > this.parseIntNumber) {
                        this.parseIntNumber++
                    }
                    let y = 1700
                    for(let i=0; i<this.parseIntNumber; i++) {
                        html2canvas(document.querySelector('.content'),
                            {
                                height: 1700,
                                y: y * i,
                                useCORS: true,
                                allowTaint: true
                            }
                        ).then((canvas) => {
                            this.objData[i] = canvas.toDataURL()
                        })
                    }
                    this.isDone = true  // 控制css
                })
            }
        })
    },
    methods: {
        down() {
            this.loading = true
            setTimeout(() => {
                genPdf({
                    img: this.objData
                }).then(res => {
                    this.loading = false
                    if(res.data.code == 200) {
                        window.open(res.data.data.url)
                    }
                })
            }, 5000)
        }
    }
}
</script>

<style scoped>
.study_report {
    background: url('../../assets/images/report_bg.png') no-repeat;
    background-size: 100% 443px;
    position: relative;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top {
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 70px;
}
.top h3 {
    font-size: 30px;
    font-weight: bold;
    border-right: 1px solid #fff;
    color: #fff;
    padding-right: 30px;
    margin: 0 30px 0 0;
}
.top img {
    width: 183px;
    height: 60px;
}
.content {
    position: absolute;
    left: 70px;
    top: 150px;
    width: 1060px;
    background: #fff;
    padding: 40px 30px;
    border: 1px solid #ddd;
    border-radius: 6px;
}
.people-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
    border-bottom: 1px solid #eee;
}
.people-info .avatar {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    margin-right: 20px;
}
.people-info .avatar-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.people-info .name h3 {
    margin-bottom: 10px;
    font-size: 20px;
    text-align: left;
}
.people-info .name span {
    color: #666;
}
.study-learn-wrap {
    padding-right: 50px;
}
.study-learn-wrap span {
    display: block;
    text-align: left;
}
.study-learn-wrap .learn-txt {
    color: #666;
}
.study-learn-wrap .learn-number {
    font-size: 44px;
    font-weight: bold;
    color: #2c75bb;
}
table {
    width: 100%;
    border-collapse:collapse;
    border-spacing:1;
    border-spacing:0;
    margin-bottom: 50px;
}
thead tr {
    background: #ddd;
    height: 40px;
}thead tr th {
    text-align: left;
    padding-left: 10px;
}
tbody td {
    text-align: left;
    padding-left: 10px;
    height: 40px;
}
.status {
    text-align: left;
}
.suc {
    color: #52c41a;
}
.err {
    color: #c00;
}
.progress-wrap {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: left;
}
.bar {
    background: #52c41a;
    height: 20px;
    margin-right: 10px;
}
.title {
    /* border-left: 4px solid rgb(8, 128, 241); */
    text-align: left;
    /* padding-left: 10px; */
    font-weight: bold;
    line-height: 18px;
    font-size: 16px;
    height: 18px;
    margin-bottom: 20px;
}
.lattice {
    display: flex;
}
.lattice li {
    flex: 1;
    height: 120px;
    border-right: 1px solid #eee;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-left: 20px;
}
.lattice li:first-child {
    border-left: 1px solid #eee;
}
.lattice-number {
    font-size: 30px;
    font-weight: bold;
    color: #333;
}
.down-btn {
    color: #555;
    font-size: 12px;
    border-bottom: 1px solid #fff;
    cursor: pointer;
    position: absolute!important;
    right: 70px;
    top: 20px;
}
.item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 20px;
    margin-bottom: 20px;
}
.item .item-bar {
    width: 4px;
    height: 18px;
    border-left: 4px solid rgb(8, 128, 241);
    margin-bottom: -16px;
}
.item h3 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    color: 333;
}
</style>