var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _c("transition", { attrs: { name: "fades" } }, [
        _vm.show
          ? _c("div", [
              _c("div", { staticClass: "header" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/images/icon-lenove-back.png"),
                  },
                  on: { click: _vm.back },
                }),
                _c(
                  "p",
                  { staticClass: "header-back", on: { click: _vm.back } },
                  [_vm._v("返回课程中心")]
                ),
              ]),
              _c("div", { staticClass: "info" }, [
                _c("div", {
                  staticClass: "img",
                  style: { backgroundImage: "url(" + _vm.course.picture + ")" },
                }),
                _c("div", { staticClass: "text-wrap" }, [
                  _c("p", { staticClass: "tit" }, [
                    _vm._v(_vm._s(_vm.course.title)),
                  ]),
                  _c("p", { staticClass: "des" }, [
                    _vm._v(_vm._s(_vm.course.infos)),
                  ]),
                  _c(
                    "p",
                    {
                      staticClass: "button",
                      on: {
                        click: function ($event) {
                          return _vm.goPlay(_vm.course)
                        },
                      },
                    },
                    [_vm._v("开始学习")]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "detail" }, [
                _c("div", { staticClass: "l" }, [
                  _c("div", { staticClass: "course-list" }, [
                    _c("div", { staticClass: "top" }, [
                      _c("p", { staticClass: "line" }),
                      _c("p", { staticClass: "t" }, [_vm._v("课程目录")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "course-list-items" },
                      _vm._l(_vm.lessons, function (c, i) {
                        return _c(
                          "div",
                          { key: i, staticClass: "item" },
                          [
                            _vm.course.is_chapter
                              ? _c("div", { staticClass: "chapter-title" }, [
                                  _c("p", { staticClass: "tit text-line-1" }, [
                                    _vm._v(_vm._s(c.title)),
                                  ]),
                                ])
                              : _vm._e(),
                            _vm._l(c.lessons, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "chapter-item",
                                  on: {
                                    click: function ($event) {
                                      return _vm.go(c, item, i)
                                    },
                                  },
                                },
                                [
                                  _c("p", { staticClass: "t text-line-1" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                  _c("p", { staticClass: "time" }, [
                                    _vm._v(
                                      "需用时" + _vm._s(item.length_format)
                                    ),
                                  ]),
                                ]
                              )
                            }),
                          ],
                          2
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "r" }, [
                  _vm.course.author && _vm.course.author.length
                    ? _c("div", { staticClass: "lec" }, [
                        _c("div", { staticClass: "top" }, [
                          _c("p", { staticClass: "line" }),
                          _c("p", { staticClass: "t" }, [_vm._v("讲师简介")]),
                        ]),
                        _c("div", { staticClass: "body" }, [
                          _c("div", { staticClass: "lec-info" }, [
                            _c("img", {
                              attrs: { src: _vm.course.author[0].avatar },
                            }),
                            _c("div", { staticClass: "lec-name" }, [
                              _c("p", { staticClass: "name" }, [
                                _vm._v(_vm._s(_vm.course.author[0].name)),
                              ]),
                              _c("p", { staticClass: "job" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.course.author[0].position
                                        ? _vm.course.author[0].position
                                        : "暂无简介"
                                    ) +
                                    " "
                                ),
                              ]),
                            ]),
                          ]),
                          _c("div", { staticClass: "des" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.course.author[0].infos
                                    ? _vm.course.author[0].infos
                                    : "暂无介绍"
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.relate_courses.length
                    ? _c("div", { staticClass: "rec" }, [
                        _c("div", { staticClass: "top" }, [
                          _c("p", { staticClass: "line" }),
                          _c("p", { staticClass: "t" }, [_vm._v("相关推荐")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "body" },
                          _vm._l(_vm.relate_courses, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.id,
                                staticClass: "course-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.checkCourse(item)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "img",
                                  style: {
                                    backgroundImage:
                                      "url(" + item.picture + ")",
                                  },
                                }),
                                _c("div", { staticClass: "counrse-info" }, [
                                  _c("p", { staticClass: "t text-line-2" }, [
                                    _vm._v(_vm._s(item.title)),
                                  ]),
                                  _c("p", { staticClass: "count" }, [
                                    _vm._v(_vm._s(item.period) + "课时"),
                                  ]),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }