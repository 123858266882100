export default {
    tabs: [
        {
            id: 1,
            name: '互联网/计算机/电子',
            list: [
                {
                    name: '算法工程师-决策优化',
                    addres: '上海',
                    company: '上汽人工智能'
                },
                {
                    name: 'IT类-IT',
                    addres: '上海',
                    company: '上海家化'
                },
                {
                    name: '商务BD',
                    addres: '上海',
                    company: '汇学保'
                },
                {
                    name: '管培生（仓储方向）',
                    addres: '东莞',
                    company: '嘉鸿网络科技'
                },
                {
                    name: '商务BD',
                    addres: '上海',
                    company: '北方自动控制技术研究所'
                },
                {
                    name: '管培生（仓储方向）',
                    addres: '东莞',
                    company: '汇学保'
                },
                {
                    name: '车辆工程工程师',
                    addres: '太原',
                    company: '北方自动控制技术研究所'
                },
                {
                    name: '运营助理',
                    addres: '杭州',
                    company: '好伙伴'
                }
            ]
        },
        {
            id: 2,
            name: '汽车/机械类',
            list: [
                {
                    name: 'IT类',
                    addres: '宜昌',
                    company: '广汽乘用车宜昌分公司'
                },
                {
                    name: '机械设计工程师',
                    addres: '上海',
                    company: '上海荷迪思'
                },
                {
                    name: '技术研发',
                    addres: '上海',
                    company: '蜂花'
                },
                {
                    name: '硬件研发工程师',
                    addres: '上海',
                    company: '哲飞科技'
                },
                {
                    name: '汽车售后服务顾问',
                    addres: '杭州',
                    company: '蜂花'
                },
                {
                    name: '硬件研发工程师',
                    addres: '上海',
                    company: '上海荷迪思'
                },
                {
                    name: '汽车售后服务顾问',
                    addres: '杭州',
                    company: '杭州爱卡奇瑞'
                },
                {
                    name: '电气工程师',
                    addres: '苏州',
                    company: '罗博特科智能科技'
                }
            ]
        },
        {
            id: 3,
            name: '营销/销售类',
            list: [
                {
                    name: '市场管培生-品类管理',
                    addres: '广州',
                    company: '中建三局'
                },
                {
                    name: '运营管培生',
                    addres: '苏州',
                    company: '丝路互联'
                },
                {
                    name: '瑞那品牌销售经理',
                    addres: '襄阳',
                    company: '烟台张裕酿酒'
                },
                {
                    name: '生成助理经理',
                    addres: '广州',
                    company: '百事（中国）'
                },
                {
                    name: '销售代表',
                    addres: '四川省',
                    company: '四川全通'
                },
                {
                    name: '生成助理经理',
                    addres: '广州',
                    company: '丝路互联'
                },
                {
                    name: '销售代表',
                    addres: '四川省',
                    company: '中建三局'
                },
                {
                    name: 'Product Merchandising Internship',
                    addres: '上海',
                    company: '耐克'
                }
            ]
        },
    ]
}