<template>
  <div class="page">
    <div class="loadding" v-show="!show">
      <a-spin />
    </div>
    <transition name="fades">
      <div v-if="show">
        <div class="header">
        <img src="../../../assets/images/icon-lenove-back.png" @click="back" />
        <p class="header-back" @click="back">返回课程中心</p>
      </div>

      <div class="info">
        <div
          class="img"
          :style="{ backgroundImage: 'url(' + course.picture + ')' }"
        ></div>
        <div class="text-wrap">
          <p class="tit">{{ course.title }}</p>
          <p class="des">{{ course.infos }}</p>
          <p class="button" @click="goPlay(course)">开始学习</p>
        </div>
      </div>

      <div class="detail">
        <div class="l">
          <!-- <div class="course-des">
            <div class="top">
              <p class="line"></p>
              <p class="t">课程简介</p>
            </div>
            <div class="desc">{{ course.infos }}</div>
          </div> -->

          <div class="course-list">
            <div class="top">
              <p class="line"></p>
              <p class="t">课程目录</p>
            </div>
            <div class="course-list-items">
              <div v-for="(c, i) in lessons" :key="i" class="item">
                <div class="chapter-title" v-if="course.is_chapter">
                  <p class="tit text-line-1">{{ c.title }}</p>
                </div>

                <div
                  class="chapter-item"
                  v-for="(item, index) in c.lessons"
                  :key="index"
                  @click="go(c, item, i)"
                >
                  <p class="t text-line-1">{{ item.title }}</p>
                  <p class="time">需用时{{ item.length_format }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="r">
          <div v-if="course.author && course.author.length" class="lec">
            <div class="top">
              <p class="line"></p>
              <p class="t">讲师简介</p>
            </div>

            <div class="body">
              <div class="lec-info">
                <img :src="course.author[0].avatar" />
                <div class="lec-name">
                  <p class="name">{{ course.author[0].name }}</p>
                  <p class="job">
                    {{
                      course.author[0].position
                        ? course.author[0].position
                        : "暂无简介"
                    }}
                  </p>
                </div>
              </div>
              <div class="des">
                {{
                  course.author[0].infos ? course.author[0].infos : "暂无介绍"
                }}
              </div>
            </div>
          </div>

          <div v-if="relate_courses.length" class="rec">
            <div class="top">
              <p class="line"></p>
              <p class="t">相关推荐</p>
            </div>
            <div class="body">
              <div
                v-for="item in relate_courses"
                :key="item.id"
                class="course-item"
                @click="checkCourse(item)"
              >
                <div
                  class="img"
                  :style="{ backgroundImage: 'url(' + item.picture + ')' }"
                ></div>
                <div class="counrse-info">
                  <p class="t text-line-2">{{ item.title }}</p>
                  <p class="count">{{ item.period }}课时</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { courseDetail } from "../../../http/api";
import coo from '../../../common/cookie.js'
export default {
  name: "lenovelcourse",
  data() {
    return {
      show: false,
      course: {},
      news: [],
      lessons: [],
      exams: [],
      hostname: "",
      starMap: {},
      relate_courses: [],
      course_wares: [],
      rate: {},
      lessonId: "",
    };
  },
  mounted() {
    this.request();
    coo.setLinkCookie(this.$router.history.current.fullPath)
  },
  watch: {
    $route(to, from) {
      if (to.path == "/lenovelcourse") {
        this.show = false;
        this.request();
      }
    },
  },
  methods: {
    request() {
      courseDetail({
        id: this.$route.query.id,
        course_type: this.$route.query.course_type,
        class_id: this.$route.query.class_id,
      })
        .then((res) => {
          if (res.data.code == 200) {
            this.show = true;
            this.course = res.data.data.course;
            this.news = res.data.data.news;
            this.lessons = res.data.data.chapters;
            this.exams = res.data.data.exams;
            this.relate_courses = res.data.data.relate_courses;
            this.course_wares = res.data.data.course_wares;
            if (
              res.data.data.pre_lesson.lesson_id &&
              res.data.data.pre_lesson.lesson_id != 0
            ) {
              this.lessonId = res.data.data.pre_lesson.lesson_id;
            }
            if (res.data.data.rate) {
              this.rate = res.data.data.rate;
            }
          } else if (res.data.code == 104) {
            this.$message.error(res.data.message);
          } else {
            this.$message.error(res.data.message);
          }
          if (localStorage.map) {
            let map = JSON.parse(localStorage.map);
            for (let key in map) {
              if (key == this.course.id) {
                this.value = map[key];
              }
            }
          }
        })
        .catch((err) => {
          this.show = true;
          this.$message.error(err.response.data.message);
        });
    },
    goPlay(course) {
      if (course.type == "link" && course.course_type == "system_course") {
        if (this.$store.state.token) {
          window.open(course.link_address);
        } else {
          this.$store.commit("SHOWLOGIN", true);
        }
      } else {
        if (this.$store.state.open_trial == 0) {
          // 不能试看
          if (this.$store.state.token) {
            this.$router.push({
              path: "/lenovo/player",
              query: {
                id: course.id,
                course_type: course.course_type,
                title: this.title,
                class_id: this.$route.query.class_id,
                lessonId: this.lessonId,
              },
            });
          } else {
            this.$store.commit("SHOWLOGIN", true);
          }
        } else if (this.$store.state.open_trial == 1) {
          // 可以试看
          this.$router.push({
            path: "/lenovo/player",
            query: {
              id: course.id,
              course_type: course.course_type,
              title: this.title,
              class_id: this.$route.query.class_id,
              lessonId: this.lessonId,
            },
          });
        }
      }
    },
    go(item, items, i) {
      if (this.course.type == "live") {
        this.$router.push({
          path: "/lenovo/player",
          query: {
            id: this.course.id,
            course_type: this.course.course_type,
            class_id: this.$route.query.class_id,
            lessonId: items.id,
            title: this.$route.query.title,
          },
        });
      } else {
        if (items.type == "link" && items.course_type == "system_course") {
          if (this.$store.state.token) {
            window.open(items.link_address);
          } else {
            this.$store.commit("SHOWLOGIN", true);
          }
        } else {
          if (this.$store.state.open_trial == 0) {
            // 不能试看
            if (this.$store.state.token) {
              this.$router.push({
                path: "/lenovo/player",
                query: {
                  id: this.course.id,
                  course_type: this.course.course_type,
                  class_id: this.$route.query.class_id,
                  lessonId: items.id,
                  title: this.$route.query.title,
                },
              });
            } else {
              this.$store.commit("SHOWLOGIN", true);
            }
          } else if (this.$store.state.open_trial == 1) {
            this.$router.push({
              path: "/lenovo/player",
              query: {
                id: this.course.id,
                course_type: this.course.course_type,
                class_id: this.$route.query.class_id,
                lessonId: items.id,
                title: this.$route.query.title,
              },
            });
          }
        }
      }
    },
    back() {
      this.$router.push({
        path: "/course",
      });
    },
    studyBtnState(state) {
      debugger;
      if (state == 0) {
        return {
          msg: "开始学习",
          //   bgColor: this.$store.state.themeColor,
          bgColor: "#0063b1",
          color: "#fff",
          bdColor: "#fff",
        };
      } else if (state == 1) {
        return {
          msg: "继续学习",
          bgColor: "#ff8611",
          color: "#fff",
          bdColor: "#fff",
        };
      } else if (state == 2) {
        return {
          msg: "已完成",
          bgColor: "#fff",
          color: this.$store.state.themeColor,
          bdColor: this.$store.state.themeColor,
        };
      }
    },
    tryBtnStatus() {
      if (this.$store.state.open_trial == 1) {
        if (this.$store.state.token) {
          if (this.course.order_status == 0) {
            return true;
          } else if (this.course.order_status == 1) {
            return false;
          }
        } else {
          if (this.course.order_status == 0) {
            return true;
          } else if (this.course.order_status == 1) {
            return false;
          }
        }
      } else if (this.$store.state.open_trial == 0) {
        return false;
      }
    },
    buy() {
      if (this.$store.state.token) {
        this.$router.push({
          path: "/pay",
          query: {
            course_id: this.course.id,
            course_type: this.course.course_type,
          },
        });
      } else {
        this.$store.commit("SHOWLOGIN", true);
      }
    },
    checkCourse(item) {
      this.$router.push({
        path: "/lenovelcourse",
        query: {
          id: item.id,
          course_type: item.course_type,
        },
      });
    },
  },
};
</script>

<style>
.page {
  width: 100%;
  background: #fff;
  padding-bottom: 30px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 30px;
  width: 100%;
  height: 66px;
  border-bottom: 1px solid #f3f3f3;
}

.header img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  cursor: pointer;
}

.header p {
  font-size: 14px;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.info {
  padding: 32px 24px;
  display: flex;
}

.info .img {
  width: 370px;
  height: 208px;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.info .text-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.info .text-wrap .tit {
  height: 26px;
  font-size: 20px;
  color: #2c2b31;
  font-weight: bold;
  line-height: 26px;
  overflow: hidden;
  text-align: left;
}

.info .text-wrap .des {
  min-height: 115px;
  width: 100%;
  font-size: 14px;
  color: #727275;
  line-height: 25px;
  margin-top: 10px;
  text-align: left;
}

.info .text-wrap .button {
  width: 155px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  background-color: #0063b1;
  margin-top: 25px;
  cursor: pointer;
}

.info .text-wrap .go-btn-wrap {
  text-align: left;
  margin-top: 25px;
}

.detail {
  width: 100%;
  display: flex;
  padding: 0 20px;
}

.detail .l {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.detail .r {
  flex-shrink: 0;
  width: 370px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.detail .r .lec,
.detail .r .rec {
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  margin-bottom: 20px;
}

.detail .top {
  height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
}

.detail .top .line {
  background-color: #0063b1;
  width: 3px;
  height: 16px;
}
.detail .top .t {
  margin-left: 8px;
  color: #2c2b31;
  font-size: 18px;
}

.detail .l .course-des {
  border-top: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.detail .l .course-des .desc {
  padding: 0 28px 30px 36px;
  font-size: 14px;
  line-height: 25px;
  color: #434347;
  text-align: left;
  text-indent: 2em;
}

.detail .l .course-list {
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.detail .l .course-list .course-list-items {
  padding: 0 20px;
}

.detail .l .course-list .course-list-items .chapter-title {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  overflow: hidden;
  margin-bottom: 10px;
  /* background-color: #f3f3f3; */
}

.detail .l .course-list .course-list-items .chapter-title p {
  color: #434347;
  font-weight: bold;
  font-size: 16px;
}

.detail .l .course-list .course-list-items .chapter-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 15px;
  cursor: pointer;
}

.detail .l .course-list .course-list-items .chapter-item .t {
  font-size: 14px;
  font-weight: bold;
  flex-shrink: 0;
  color: #434347;
}

.detail .l .course-list .course-list-items .chapter-item .time {
  font-size: 12px;
  color: #a1a1a3;
}

.detail .r .lec .body {
  padding: 0 36px;
}

.detail .r .lec .body .lec-info {
  height: 50px;
  display: flex;
  align-items: center;
}

.detail .r .lec .body .lec-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.detail .r .lec .body .lec-info .lec-name {
  display: flex;
  height: 50px;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;
}

.detail .r .lec .body .lec-info .lec-name .name {
  height: 26px;
  text-align: left;
  font-weight: bold;
  font-size: 16px;
}

.detail .r .lec .body .lec-info .lec-name .job {
  height: 20px;
  text-align: left;
  font-size: 12px;
  color: #999;
}

.detail .r .lec .body .des {
  font-size: 14px;
  line-height: 26px;
  text-align: left;
  margin: 10px 0;
}

.detail .r .rec .body {
  padding: 0 36px 30px;
}

.detail .r .rec .body .course-item {
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
  height: 74px;
  cursor: pointer;
}

.detail .r .rec .body .course-item .img {
  width: 132px;
  height: 74px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  overflow: hidden;
  flex-shrink: 0;
}

.detail .r .rec .body .course-item .counrse-info {
  flex: 1;
  height: 100%;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.detail .r .rec .body .course-item .counrse-info .t {
  font-size: 14px;
  color: #2c2b31;
}

.detail .r .rec .body .course-item .counrse-info .count {
  font-size: 12px;
  color: #a1a1a3;
}

p {
  margin: 0;
  text-align: left;
}

.text-line-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-line-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.unfold {
  display: block;
  z-index: 11;
  float: right;
  width: 40px;
  height: 21px;
}

.unfold p {
  margin: 0;
  line-height: 21px;
  color: #0063b1;
}
</style>