<template>
    <div class="pay">
      <div class="pay-inner">

        <div class="title">确认订单</div>
        <div class="course-info">
            <div class="info-l">
              <p class="info-t">课程信息</p>
              <div class="product">
                <img :src="course.picture" alt="">
                <div class="p-info">
                  <span>{{course.title}}</span>
                  <span style="color: #666;">{{course.period}}学时</span>
                </div>
              </div>
            </div>
            <div class="info-r">
              <p class="info-t">应付金额</p>
              <div class="price-info">
                <span style="color:#FC583D">￥</span>
                <span class="red">{{course.price}}</span>
              </div>
            </div>
        </div>
        <div class="way">
            <p class="info-t">支付方式</p>
            <ul class="pay-way">
                <li @click="checkPay(item, i)" :class="index == i? 'pay-color': ''" v-for="(item, i) in payMenus" :key="i">
                  <img :src="item.src" alt="">
                  <span>{{item.name}}</span>
                </li>
            </ul>
        </div>

        <div class="bot-wrap">
            <div>
                <span>实付金额：</span>
                <div class="">
                  <span style="color: #FC583D">￥</span>
                  <span class="red">{{course.price}}</span>
                </div>
            </div>
            <p>请在30分钟内支付完成，如未完成此订单将自动关闭，需重新购买</p>
            <a-button class="pay-btn" @click="showModal" size="large" :style="{background: '#FC583D', color: '#fff',border: 'none'}">立即支付</a-button>
        </div>
        <a-modal :maskClosable='false' v-model="visible" width="700px" footer="" title="立即付款" @cancel="cancel">
          <div class="txt-info">
            <div>
              <p class="order-info-title">订单号{{order.sn}}</p>
              <div class="order-info">
                <p>下单时间：{{order.created_time}}</p>
                <p></p>
              </div>
              <div class="">
                <p>应付金额：￥{{course.price}}</p>
                <p></p>
              </div>
              <div class="">
                <p>支付方式：{{payType == 'wechat'? '微信支付': '支付宝支付'}}</p>
              </div>
            </div>
            <div>剩余支付时间：<span style="color: #c00;font-size: 20px;">{{showTimeInfo}}</span></div>
          </div>
          <div class="pay-info">
            <div>
              <div class="code-wrap">
                <img class="wx_pay_qrcode" :src="wx_pay_qrcode" alt="">
              </div>
            </div>
            <div class="">
              <img v-if="payType == 'wechat'" class="pay-icon" src="../../assets/images/payicon.png" alt="">
              <img v-if="payType == 'adapay'" style="width: 64px;height: 64px;" src="../../assets/images/payiconali.png" alt="">
              <p>{{payType == 'wechat'? '微信支付': '支付宝支付'}}</p>
              <p>请使用{{payType == 'wechat'? '微信': '支付宝'}}扫描左侧二维码以完成支付</p>
              <p class="red">￥{{course.price}}</p>
            </div>
          </div>
        </a-modal>
        </div>
    </div>
</template>

<script>
import {
  orderInfo,
  order,
  getPrepare,
  cancelOrder
} from '../../http/api'
export default {
    data() {
    return {
      payMenus: [
        {
          src: require('../../assets/images/wechatpay.png'),
          name: '微信支付',
          payType: 'wechat'
        },
        {
          src: require('../../assets/images/alipay.png'),
          name: '支付宝支付',
          payType: 'adapay'
        }
      ],
      payType: 'wechat',
      index: 0,
      course_id: '',
      course_type: '',
      course: '',
      order: '',
      wx_pay_qrcode: '',
      visible: false,
      timer: '',
      showTimeInfo: ''
    }
  },
  mounted() {
    this.course_id = this.$route.query.course_id
    this.course_type = this.$route.query.course_type
    if (this.$store.state.open_wechat_pay) {
      this.payMenus = [
        {
          src: require('../../assets/images/wechatpay.png'),
          name: '微信支付',
          payType: 'wechat'
        }
      ]
    }
    orderInfo({
      course_id: this.course_id,
      course_type: this.course_type
    }).then(res => {
      if(res.data.code == 200) {
        this.course = res.data.data
      }
    })
  },
  methods: {
    orderCancel(sn) {
      cancelOrder({
        order_sn: sn
      }).then(res => {
        if(res.data.code == 200) {
          this.$message.success(res.data.message)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    checkPay(item, i) {
      this.index = i
      this.payType = item.payType
    },
    showModal() {
      order({
        course_id: this.course_id,
        course_type: this.course_type,
        payment: this.payType
      }).then(res => {
        if(res.data.code == 200) {
            this.order = res.data.data.order
            this.wx_pay_qrcode = res.data.data.wx_pay_qrcode
            this.showTimeInfo = '--:--'
            this.visible = true
            this.timer = setInterval(() => {
              let minutes = res.data.data.order.remain_time.split(':')[0] == 30? 29: res.data.data.order.remain_time.split(':')[0]
              let seconds = res.data.data.order.remain_time.split(':')[1] == 0? 59: res.data.data.order.remain_time.split(':')[1]
              // if(seconds > 0) {
                seconds--
                let formatS = seconds < 10? '0'+seconds: seconds
                res.data.data.order.remain_time = minutes+':'+formatS
                this.showTimeInfo = minutes+':'+formatS
                // console.info(this.showTimeInfo)
                if(seconds == 0) {
                  setTimeout(() => {
                    seconds = 59
                    let formatS = seconds < 10? '0'+seconds: seconds
                    res.data.data.order.remain_time = minutes+':'+formatS
                    this.showTimeInfo = minutes+':'+formatS
                    if(minutes > 0) {
                      minutes--
                      let formatM = minutes < 10? '0'+minutes: minutes
                      res.data.data.order.remain_time = formatM+':'+seconds
                      this.showTimeInfo = formatM+':'+seconds
                      // console.info(1)
                    } else if(minutes == 0) {
                      this.showTimeInfo = '00:00'
                      clearInterval(this.timer)
                      this.visible = false
                      this.orderCancel(res.data.data.order.sn)
                      // console.info(2)
                    }
                  }, 1000)
                }
              // }
              getPrepare({
                order_sn: this.order.sn
              }).then(res => {
                if(res.data.code == 200) {
                  clearInterval(this.timer)
                  this.$router.push({
                    path: '/payresult',
                    query: {
                      course_id: this.course_id,
                      course_type: this.course_type,
                      target_type: res.data.data.target_type
                    }
                  })
                }
              })
            },1000)
        } else if(res.data.code == 105) {
          this.errorMessage = res.data.message
          this.showConfirm()
        } else {
          this.$message.error(res.data.message)
        }

      })
    },
    cancel() {
      clearInterval(this.timer)
      this.$router.push({
        path: '/user/orders'
      })
    },
    showConfirm() {
      this.$confirm({
        title: this.errorMessage,
        cancelText: '取消',
        okText: '确定',
        onOk: () => {
          this.$router.push({
            path: '/user/orders'
          })
        },
        onCancel() {

        },
      })
    },
  }
}
</script>

<style scoped>
p {
  text-align: left;
}
.pay {
    margin: 0 auto;
    width: 980px;
}
.pay-inner {
  padding-top: 20px;
}
.title {
    font-size: 20px;
    text-align: center;
    height: 100px;
    line-height: 100px;
    background: blue;
    color: #fff;
    margin-bottom: 25px;
    border-radius: 4px;
    background: url("../../assets/images/ordertop.png");
}
.course-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border: 1px solid #EBEFF2;
  border-top: 2px solid #3091FD;
  margin-bottom: 25px;
}
.course-info .info-l {
  width: 655px;
}
.course-info .info-r {
  flex: 1;
}
.info-t {
  margin-bottom: 15px;
  font-size: 12px;
  color: #333;
  text-align: left;
}
.txt-info {
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.product {
  height: 90px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid #EBEFF2;
  margin-right: 20px;
}

.price-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.product img {
  width: 150px;
  border-radius: 6px;
  height: 90px;
  margin-right: 10px;
  object-fit: cover;
}
.way {
    text-align: left;
    margin-bottom: 150px;
}
.pay-way {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.pay-way li {
    width: 200px;
    height: 70px;
    cursor: pointer;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 6px;
    transition: .2s all;
}
.pay-color {
  border-color: #0880F1!important;
  transform: scale(1.05);
}
.pay-way li img {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}
.bot-wrap {
    text-align: right;
}
.bot-wrap>div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}
.bot-wrap p {
  text-align: right;
  color: #FC583D;
  margin-bottom: 20px;
}
.pay-btn {
  margin-bottom: 50px;
}
.red {
  color: #FC583D;
  font-size: 28px;
}
.ac {
  color: #FC583D;
}
.order-info {
  color: #666;
}
.wx_pay_qrcode {
  width: 168px;
  height: 168px;
}
.order-info-title {
  font-weight: bold;
  color: #333;
  font-size: 14px;
}
.pay-info {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}
.p-info {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
.pay-info>div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.pay-info>div p:last-child {
  margin-bottom: 0;
}
.pay-icon {
  width: 123px;
  height: 31px;
  margin-bottom: 10px;
}
.code-wrap {
  width: 170px;
  height: 170px;
  background: url('../../assets/images/codewrap.png') no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 1024px) {
.pay {
    width: 100%;
}
}

</style>
