<template>
    <div>
        <div class="job">
            <div class="inner">
                <h3 class="title">最新网申职位</h3>
                <ul class="menu">
                    <li 
                    v-for="(item, i) in tab" 
                    :key="i"
                    :class="index == i? 'tab-ac': ''"
                    @click="checkTab(item, i)">{{item.name}}</li>
                </ul>
                <ul class="top-content">
                    <li v-for="(item, i) in checkList" @click="go(item, i)">
                        <div class="top">
                            <h3 class="t">{{item.name}}</h3>
                            <span class="st">{{item.addres}}</span>
                        </div>
                        <div class="bot">
                            <!-- <img src="" alt=""> -->
                            <span class="sst">{{item.company}}</span>
                        </div>
                    </li>
                </ul>
                <h3 class="title">更多热招职位</h3>
                <div class="more-content">
                    <div class="search">
                        <div class="one">
                            <span>北京</span>
                            <img class="icon" src="../../assets/images/more_down.png" alt="">
                        </div>
                        <input type="text" placeholder="搜索职位、公司">
                        <a-button class="btn">搜索</a-button>
                    </div>
                    <div class="menus-list">
                        <span style="color: #999;">公司地点：</span>
                        <ul>
                            <li
                            @click="tabO(item, i)"
                            :class="fIndex == i? 'ac': ''" v-for="(item, i) in fMenu" :key="i">{{item.name}}</li>
                        </ul>
                    </div>
                    <div class="menus-list">
                        <span style="color: #999;">行业类型：</span>
                        <ul>
                            <li 
                            @click="tabT(item, i)"
                            :class="sIndex == i? 'ac': ''" v-for="(item, i) in sMenu" :key="i">{{item.name}}</li>
                        </ul>
                    </div>
                </div>

                <div class="job-list-wrap">
                    <ul class="job-list">
                        <li v-for="(item, i) in data.data[0].data" @click="go(item, i)">
                            <div class="left">
                                <h3>{{item.title}}</h3>
                                <div class="r" style="margin-bottom: 22px;">
                                    <div>
                                        <span class="pri">{{item.red}}</span>
                                        <span class="su">/月</span>
                                    </div>
                                    <span class="su">{{item.joblimit}}</span>
                                </div>
                                <div class="f">
                                    <span>{{item.falselink}}</span>
                                    <span>{{item.tags}}</span>
                                    <span>{{item.tags}}</span>
                                </div>
                            </div>
                            <div class="right">
                                <div class="d">
                                    <h3>{{item.company}}</h3>
                                    <span class="su">{{item.scale}}</span>
                                    <span class="suu">{{item.description}}</span>
                                </div>
                                <!-- <img src="" alt=""> -->
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tab from '../../assets/data/type'
import data from '../../assets/data/data'
export default {
    data() {
        return {
            data: '',
            tab: '',
            checkList: tab.tabs[0].list,
            index: 0,
            fIndex: 0,
            fMenu: [
                {
                    id: 0,
                    name: '全国'
                },
                {
                    id: 1,
                    name: '北京'
                },
                {
                    id: 2,
                    name: '上海'
                },
                {
                    id: 3,
                    name: '广州'
                },
                {
                    id: 4,
                    name: '深圳'
                },
                {
                    id: 5,
                    name: '杭州'
                },
                {
                    id: 6,
                    name: '天津'
                },
                {
                    id: 7,
                    name: '西安'
                },
                {
                    id: 8,
                    name: '苏州'
                },
                {
                    id: 9,
                    name: '武汉'
                },
                {
                    id: 10,
                    name: '全部城市'
                }
            ],
            sIndex: 0,
            sMenu: [
                {
                    id: 0,
                    name: '不限'
                },
                {
                    id: 1,
                    name: '电子商务'
                },
                {
                    id: 2,
                    name: '游戏'
                },
                {
                    id: 3,
                    name: '媒体'
                },
                {
                    id: 4,
                    name: '广告营销'
                },
                {
                    id: 5,
                    name: '数据服务'
                },
                {
                    id: 6,
                    name: '医疗健康'
                },
                {
                    id: 7,
                    name: '生活服务'
                },
                {
                    id: 8,
                    name: '旅游'
                }
                ,
                {
                    id: 9,
                    name: '在线教育'
                }
                ,
                {
                    id: 10,
                    name: '更多行业'
                }
            ]
        }
    },
    created() {
        this.tab = tab.tabs
        this.data = data
    },
    methods: {
        checkTab(item, i) {
            this.index = i
            this.checkList = item.list
        },
        tabO(item, i) {
            this.fIndex = i
        },
        tabT(item, i) {
            this.sIndex = i
        },
        go(item, i) {
            this.$router.push({
                path: '/job'
            })
        }
    }
}
</script>

<style scoped>
.job {
    height: 100%;
    background: #F6F8FB;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.title {
    font-size: 24px;
    color: #333;
    text-align: left;
    line-height: 24px;
    margin-bottom: 20px;
    padding-top: 40px;
}
.menu {
    height: 50px;
    border-bottom: 2px solid #0066CC;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.menu li:first-child {
    border-top-left-radius: 10px;
}
.menu li {
    width: 148px;
    background: #fff;
    color: #333;
    font-size: 15px;
    height: 100%;
    line-height: 50px;
    border-right: 1px solid #D0D7E1;
    cursor: pointer;
    font-weight: bold;
}
.tab-ac {
    color: #fff!important;
    background: #0066CC!important;
}
.top-content {
    background: #fff;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 30px 30px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.top-content li {
    width: 270px;
    height: 120px;
    margin-right: 20px;
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
}
.top-content li:nth-child(4n) {
    margin-right: 0;
}
.top-content li img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}
.top-content li .t {
    color: #000;
    font-size: 16px;
    line-height: 18px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    max-width: 190px;
}
.top-content li .st {
    color: #999;
    line-height: 12px;
    font-size: 12px;
}
.top-content li .sst {
    color: #333;
    font-size: 14px;
    line-height: 16px;
}
.top-content li .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.top-content li .bot {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.more-content {
    height: 180px;
    border-radius: 10px;
    background: #fff;
    padding: 30px 25px;
    margin-bottom: 20px;
}
.search {
    width: 960px;
    height: 50px;
    border: 1px solid #D0D7E1;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    border-right: none;
    margin-bottom: 20px;
}
.search .icon {
    width: 16px;
    height: 16px;
}
.search .one {
    width: 113px;
    border-right: 1px solid #D0D7E1;
}
.search input {
    border: none;
    height: 50px;
    flex: 1;
    padding-left: 10px;
}
.search .btn {
    height: 50px;
    width: 134px;
    background: #0066CC;
    color: #fff;
}
.menus-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    padding-left: 30px;
}
.menus-list span {
    font-size: 12px;
    color: #999;
    line-height: 12px;
}
.menus-list li {
    font-size: 12px;
    color: #666;
    margin-right: 20px;
    cursor: pointer;
    line-height: 12px;
}
.menus-list ul {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.ac {
    color: #0066CC!important;
}
.job-list-wrap {
    border-radius: 10px;
    background: #fff;
    padding: 30px 25px;
    margin-bottom: 20px;
}
.job-list {
    border: 1px solid #D0D7E1;
    border-radius: 10px;
    padding: 0 25px;
}
.job-list li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0;
    border-bottom: 1px solid #D0D7E1;
    cursor: pointer;
}
.job-list li:last-child {
    border-bottom: 0;
}
.job-list .left h3 {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    margin-bottom: 15px;
    text-align: left;
}
.job-list .left .pri {
    font-size: 18px;
    line-height: 18px;
    color: #F34026;
    display: inline-block;
    text-align: left;
}
.job-list .left .su {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    display: inline-block;
}
.job-list .left .r {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.job-list .left .f {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.job-list .left .f span{
    font-size: 12px;
    line-height: 12px;
    color: #999;
    display: inline-block;
    width: 76px;
    height: 30px;
    background: #F6F8FB;
    line-height: 30px;
    margin-right: 10px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.job-list .left .f span:last-child {
    margin-right: 0;
}
.job-list .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 550px;
}
.job-list .right img {
    width: 80px;
    height: 80px;
    margin-left: 20px;
}
.job-list .right .d {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.job-list .right h3 {
    font-size: 16px;
    line-height: 16px;
    color: #000;
    margin-bottom: 15px;
    text-align: left;
    margin-bottom: 20px;
}
.job-list .right .su {
    font-size: 12px;
    line-height: 12px;
    color: #666;
    display: inline-block;
    margin-bottom: 20px;
}
.job-list .right .suu {
    font-size: 12px;
    color: #999;
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
</style>