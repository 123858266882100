<template lang="html">
  <div class="payres">
    <h3>支付成功</h3>
    <img src="../../assets/images/paystatus.png" alt="">
    <div class="">
      <a-button @click="backRecord" type="primary">{{course_type == 'classroom'? '返回班级': '返回课程'}}</a-button>
      <a-button @click="goOrders" type="primary">查看订单</a-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      course_id: '',
      course_type: '',
    }
  },
  mounted() {
    this.course_id = this.$route.query.course_id
    this.course_type = this.$route.query.course_type
  },
  methods: {
    backRecord() {
      if(this.course_type == 'classroom') {
        this.$router.push({
          path: '/class/descript',
          query: {
            id: this.course_id
          }
        })
      } else {
        this.$router.push({
          path: '/course/studydetail',
          query: {
            id: this.$route.query.course_id,
            course_type: this.$route.query.course_type
          }
        })
      }
      
    },
    goOrders() {
      this.$router.push({
        path: '/user/orders'
      })
    }
  }
}
</script>

<style lang="css" scoped>
  .payres img {
    width: 117px;
    height: 123px;
    margin-bottom: 30px;
  }
  .payres h3 {
    padding-top: 80px;
    margin-bottom: 60px;
    font-weight: bold;
    font-size: 20px;
  }
  .payres button {
    margin:0 10px;
    width: 98px!important;
    height: 36px;
  }
  .payres button:last-child {
    color: #0071F5;
    background: #fff;
  }
</style>
