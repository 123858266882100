var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "job" }, [
      _c("div", { staticClass: "inner" }, [
        _c("h3", { staticClass: "title" }, [_vm._v("最新网申职位")]),
        _c(
          "ul",
          { staticClass: "menu" },
          _vm._l(_vm.tab, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                class: _vm.index == i ? "tab-ac" : "",
                on: {
                  click: function ($event) {
                    return _vm.checkTab(item, i)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          0
        ),
        _c(
          "ul",
          { staticClass: "top-content" },
          _vm._l(_vm.checkList, function (item, i) {
            return _c(
              "li",
              {
                on: {
                  click: function ($event) {
                    return _vm.go(item, i)
                  },
                },
              },
              [
                _c("div", { staticClass: "top" }, [
                  _c("h3", { staticClass: "t" }, [_vm._v(_vm._s(item.name))]),
                  _c("span", { staticClass: "st" }, [
                    _vm._v(_vm._s(item.addres)),
                  ]),
                ]),
                _c("div", { staticClass: "bot" }, [
                  _c("span", { staticClass: "sst" }, [
                    _vm._v(_vm._s(item.company)),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
        _c("h3", { staticClass: "title" }, [_vm._v("更多热招职位")]),
        _c("div", { staticClass: "more-content" }, [
          _c(
            "div",
            { staticClass: "search" },
            [
              _vm._m(0),
              _c("input", {
                attrs: { type: "text", placeholder: "搜索职位、公司" },
              }),
              _c("a-button", { staticClass: "btn" }, [_vm._v("搜索")]),
            ],
            1
          ),
          _c("div", { staticClass: "menus-list" }, [
            _c("span", { staticStyle: { color: "#999" } }, [
              _vm._v("公司地点："),
            ]),
            _c(
              "ul",
              _vm._l(_vm.fMenu, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    class: _vm.fIndex == i ? "ac" : "",
                    on: {
                      click: function ($event) {
                        return _vm.tabO(item, i)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "menus-list" }, [
            _c("span", { staticStyle: { color: "#999" } }, [
              _vm._v("行业类型："),
            ]),
            _c(
              "ul",
              _vm._l(_vm.sMenu, function (item, i) {
                return _c(
                  "li",
                  {
                    key: i,
                    class: _vm.sIndex == i ? "ac" : "",
                    on: {
                      click: function ($event) {
                        return _vm.tabT(item, i)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "job-list-wrap" }, [
          _c(
            "ul",
            { staticClass: "job-list" },
            _vm._l(_vm.data.data[0].data, function (item, i) {
              return _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.go(item, i)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "left" }, [
                    _c("h3", [_vm._v(_vm._s(item.title))]),
                    _c(
                      "div",
                      {
                        staticClass: "r",
                        staticStyle: { "margin-bottom": "22px" },
                      },
                      [
                        _c("div", [
                          _c("span", { staticClass: "pri" }, [
                            _vm._v(_vm._s(item.red)),
                          ]),
                          _c("span", { staticClass: "su" }, [_vm._v("/月")]),
                        ]),
                        _c("span", { staticClass: "su" }, [
                          _vm._v(_vm._s(item.joblimit)),
                        ]),
                      ]
                    ),
                    _c("div", { staticClass: "f" }, [
                      _c("span", [_vm._v(_vm._s(item.falselink))]),
                      _c("span", [_vm._v(_vm._s(item.tags))]),
                      _c("span", [_vm._v(_vm._s(item.tags))]),
                    ]),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c("div", { staticClass: "d" }, [
                      _c("h3", [_vm._v(_vm._s(item.company))]),
                      _c("span", { staticClass: "su" }, [
                        _vm._v(_vm._s(item.scale)),
                      ]),
                      _c("span", { staticClass: "suu" }, [
                        _vm._v(_vm._s(item.description)),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "one" }, [
      _c("span", [_vm._v("北京")]),
      _c("img", {
        staticClass: "icon",
        attrs: { src: require("../../assets/images/more_down.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }