var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "guide" }, [
    _vm.$store.state.template != 3
      ? _c("img", {
          staticClass: "img_a",
          attrs: { src: _vm.$store.state.wechat_share_img, alt: "" },
        })
      : _vm._e(),
    _vm.$store.state.template == 3 ||
    _vm.$store.state.hostname == _vm.$store.state.demonstrationHostname
      ? _c("img", {
          staticClass: "img_a",
          attrs: { src: require("../../assets/images/xmobilebg.png"), alt: "" },
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }