<template>
    <div class="linkup">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>互动问答</h3>
        </div>
        <div class="select-wrap">
            <a-select default-value="0" style="width: 160px;margin-right: 10px;" @change="changeCourse">
                <a-select-option value="0">全部课程</a-select-option>
                <a-select-option v-for="(item, i) in courseArr" :key="i" :value="item.id+'-'+item.course_type">{{item.title}}</a-select-option>
            </a-select>
            <a-select default-value="0" v-model="secondInit" style="width: 160px" @change="changeLesson">
                <a-select-option value="0">全部课时</a-select-option>
                <a-select-option v-for="(item, i) in lessonArr" :key="i" :value="item.id">{{item.title}}</a-select-option>
            </a-select>
        </div>
        <div class="empty" v-if="show && linkArr.length == 0">
            <img src="../../assets/images/weipingjia.png" alt="">
            <span>暂无互动~</span>
        </div>
        <div style="height: 300px;line-height: 300px;" v-show="!show">
            <a-spin />
        </div>
        <ul v-if="linkArr.length > 0" style="margin-bottom: 20px;">
            <li class="link-item" v-for="(item, i) in linkArr" :key="i">
                <div class="link-user">
                    <img :src="item.user.avatar" alt="">
                    <div>
                        <h3 class="user">{{item.user.realname}}</h3>
                        <p class="user-info">来自课程 {{item.title}}</p>
                    </div>
                </div>
                <p class="infos">{{item.content}}</p>
                <div class="sign">
                    <span class="sign-see">{{item.hit_num}}人已查看</span>
                    <span class="sign-do" v-if="item.post_num > 0" @click="seeDetail(item)">{{item.post_num}}条记录，点击查看</span>
                </div>
                <detail v-if="item.id == show_id" :thread_id="thread_id" :lesson_id="lesson_id" :course_id="course_id" :course_type="course_type"></detail>
            </li>
        </ul>
        <a-pagination v-if="ext.total_pages > 1" :default-current="page" :total="ext.total_count" @change="changePage"/>
    </div>
</template>

<script>
import {
    getCourseType,
    getThread
} from '../../http/api'
import detail from '../linkdetail/index'
export default {
    data() {
        return {
            class_id: '',
            thread_id: '',
            course_id: '',
            course_type: '',
            lesson_id: '',
            courseArr: [],
            lessonArr: [],
            page: 1,
            page_size: 10,
            linkArr: [],
            ext: '',
            show_id: '',
            show: false,
            secondInit: '全部课时'
        }
    },
    components: {
        detail
    },
    created() {
        this.class_id = this.$route.query.id
        getCourseType({
            id: this.class_id,
            course_id: this.course_id,
            course_type: this.course_type
        }).then(res => {
            if(res.data.code == 200) {
                res.data.data.map((item, i) => {
                    this.courseArr.push(item)
                })               
            }
        })
        this.update()
    },
    methods: {
        changeCourse(value) {
            this.secondInit = '全部课时'
            this.lesson_id = ''
            this.show = false
            this.course_id = value.split('-')[0]
            this.course_type = value.split('-')[1]
            this.linkArr = []
            this.lessonArr = []
            getCourseType({
                id: this.class_id,
                course_id: this.course_id,
                course_type: this.course_type
            }).then(res => {
                if(res.data.code == 200) {
                    res.data.data.map((item, i) => {
                        this.lessonArr.push(item)
                    })
                    this.update()    
                }
            })
        },
        changeLesson(value) {
            this.show = false
            this.lesson_id = value
            this.linkArr = []
            this.update()
        },
        update() {
            getThread({
                id: this.class_id,
                course_id: this.course_id,
                course_type: this.course_type,
                lesson_id: this.lesson_id,
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                this.show = true
                if(res.data.code == 200) {
                    res.data.data.map((item, i) => {
                        this.linkArr.push(item)
                    })
                    this.ext = res.data.ext
                }
            })
        },
        changePage(page) {
            this.show = false
            this.page = page
            this.linkArr = []
            this.update()
        },
        seeDetail(item) {
            this.thread_id = item.id
            this.lesson_id = item.lesson_id
            this.course_id = item.course_id
            this.course_type = item.course_type
            this.show_id = item.id
        }
    }
}
</script>

<style scoped>
.select-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.link-item {
    border-bottom: 1px solid #eee;
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.link-item img {
    width: 66px;
    height: 66px;
    border-radius: 33px;
    object-fit: cover;
    margin-right: 15px;
    border: 1px solid #eee;
    box-sizing: border-box;
}
.link-user {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
}
.user {
    font-weight: bold;
    text-align: left;
}
.user-info {
    font-size: 14px;
    color: #666;
}
.infos {
    color: #666;
    font-size: 14px;
}
.sign {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.sign span {
    display: block;
}
.sign .sign-see {
    color: #666;
    font-size: 12px;
    margin-right: 30px;
}
.sign .sign-do {
    color: #fff;
    background: rgb(0, 113, 245);
    padding:4px 8px;
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
}
.empty {
  padding: 50px 0;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
</style>