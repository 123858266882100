var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "have_class_hours" },
    [
      _c("transition", { attrs: { name: "fades" } }, [
        _vm.show
          ? _c("div", [
              _c(
                "div",
                {
                  staticClass: "title-wrap",
                  style: { borderColor: _vm.$store.state.themeColor },
                },
                [
                  _c("div", { staticClass: "title-left" }, [
                    _c(
                      "h3",
                      {
                        staticClass: "title",
                        style: { color: _vm.$store.state.themeColor },
                      },
                      [_vm._v("签到次数：" + _vm._s(_vm.sign_times))]
                    ),
                    _c("p", [
                      _vm._v(
                        "当前企业：" +
                          _vm._s(_vm.$store.state.currentTenantName)
                      ),
                    ]),
                  ]),
                  _c(
                    "a-button",
                    {
                      style: {
                        background: _vm.$store.state.themeColor,
                        color: "#fff",
                        border: "none",
                        height: "40px",
                      },
                      on: { click: _vm.back },
                    },
                    [_vm._v("返回")]
                  ),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "fades" } }, [
        _vm.show && _vm.data.length > 0
          ? _c("div", [
              _c(
                "div",
                [
                  _c("a-table", {
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      columns: _vm.columns,
                      "data-source": _vm.data,
                      size: "small",
                      pagination: false,
                    },
                  }),
                  _vm.total > 10
                    ? _c("a-pagination", {
                        attrs: { size: "small", total: _vm.total },
                        on: { change: _vm.changePage },
                        model: {
                          value: _vm.page,
                          callback: function ($$v) {
                            _vm.page = $$v
                          },
                          expression: "page",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm.show && _vm.data.length < 1
          ? _c("div", { staticClass: "empty" }, [
              _c("img", {
                attrs: {
                  src: require("../../assets/images/empty.png"),
                  alt: "",
                },
              }),
              _c("p", [_vm._v("暂无数据")]),
            ])
          : _vm._e(),
      ]),
      !_vm.show
        ? _c("div", { staticClass: "loading" }, [_c("a-spin")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }