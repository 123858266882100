var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "orders" },
    [
      _c(
        "h3",
        {
          staticClass: "title",
          style: { borderColor: _vm.$store.state.themeColor },
        },
        [_vm._v("订单列表")]
      ),
      _c(
        "div",
        { staticClass: "search-wrap" },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("a-input", {
                attrs: { type: "text", placeholder: "订单号搜索" },
                model: {
                  value: _vm.keywords,
                  callback: function ($$v) {
                    _vm.keywords = $$v
                  },
                  expression: "keywords",
                },
              }),
              _c(
                "a-button",
                { attrs: { type: "primary" }, on: { click: _vm.query } },
                [_vm._v("订单查询")]
              ),
            ],
            1
          ),
          _c(
            "a-button",
            {
              staticStyle: { "{background": "'#D7E9FE!important'}" },
              attrs: { type: "primary" },
              on: { click: _vm.showMenu },
            },
            [_vm._v(_vm._s(_vm.menuTxt))]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.menuShow,
              expression: "menuShow",
            },
          ],
          staticClass: "conditions",
        },
        [
          _c(
            "div",
            { staticClass: "select-wrap" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("支付方式")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { "default-value": "" },
                  on: { change: _vm.checkPayment },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择"),
                  ]),
                  _c("a-select-option", { attrs: { value: "wechat" } }, [
                    _vm._v("微信支付"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "select-wrap" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("订单状态")]),
              _c(
                "a-select",
                {
                  staticStyle: { width: "120px" },
                  attrs: { "default-value": "", value: _vm.oerderStatusSelect },
                  on: { change: _vm.checkStatus },
                },
                [
                  _c("a-select-option", { attrs: { value: "" } }, [
                    _vm._v("请选择"),
                  ]),
                  _c("a-select-option", { attrs: { value: "finished" } }, [
                    _vm._v("已完成"),
                  ]),
                  _c("a-select-option", { attrs: { value: "closed" } }, [
                    _vm._v("已取消"),
                  ]),
                  _c("a-select-option", { attrs: { value: "created" } }, [
                    _vm._v("待支付"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "select-wrap" },
            [
              _c("label", { attrs: { for: "" } }, [_vm._v("下单时间")]),
              _c("a-range-picker", {
                attrs: { placeholder: ["开始日期", "结束日期"] },
                on: { change: _vm.onChangeDate },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "tabs-bar" },
        [
          _c(
            "ul",
            { staticClass: "tabs" },
            _vm._l(_vm.tabs, function (item, index) {
              return _c(
                "li",
                {
                  key: index,
                  style: {
                    color:
                      index == _vm.tabIndex ? _vm.$store.state.themeColor : "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.tab(item, index)
                    },
                  },
                },
                [
                  _vm._v(
                    _vm._s(item.tabName) + "(" + _vm._s(item.tabNumber) + ")"
                  ),
                ]
              )
            }),
            0
          ),
          _c(
            "el-checkbox",
            {
              attrs: { disabled: _vm.disabled },
              on: { change: _vm.hideOrders },
              model: {
                value: _vm.allOrderShow,
                callback: function ($$v) {
                  _vm.allOrderShow = $$v
                },
                expression: "allOrderShow",
              },
            },
            [_vm._v("隐藏免费订单")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {},
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.show,
                  expression: "!show",
                },
              ],
              staticClass: "loadding",
            },
            [_c("a-spin")],
            1
          ),
          _c("transition", { attrs: { name: "fades" } }, [
            _vm.show && _vm.list.length == 0
              ? _c("div", { staticClass: "empty" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/weipingjia.png"),
                      alt: "",
                    },
                  }),
                  _c("span", [_vm._v("暂无订单信息")]),
                ])
              : _vm._e(),
            _vm.show && _vm.list.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "ul",
                      { staticClass: "order" },
                      _vm._l(_vm.list, function (item, index) {
                        return _c("li", { key: index }, [
                          _c("div", { staticClass: "order-top" }, [
                            _c("div", { staticClass: "order-number" }, [
                              _c("span", [
                                _vm._v("订单号：" + _vm._s(item.origin_sn)),
                              ]),
                              _c("span", [_vm._v(_vm._s(item.created_time))]),
                            ]),
                            item.order_status == "created"
                              ? _c("div", { staticClass: "order-time" }, [
                                  _c("span", [_vm._v("剩余支付时间：")]),
                                  _c("span", [
                                    _vm._v(_vm._s(item.remain_time)),
                                  ]),
                                ])
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "content-wrap" }, [
                            _c("div", { staticClass: "tit" }, [
                              _c("span", [_vm._v("订单信息")]),
                              _c("span", [_vm._v("订单价格")]),
                              _c("span", [_vm._v("订单类型")]),
                              _c("span", [_vm._v("交易状态")]),
                              _c("span", [_vm._v("支付方式")]),
                              _c("span", [_vm._v("操作")]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "order-content" },
                              _vm._l(item.items, function (items, indexs) {
                                return _c(
                                  "div",
                                  { key: indexs, staticClass: "content" },
                                  [
                                    _c("div", { staticClass: "info-wrap" }, [
                                      _c("div", { staticClass: "img-wrap" }, [
                                        _c("img", {
                                          attrs: {
                                            src: items.course.picture,
                                            alt: "",
                                          },
                                        }),
                                        items.course.is_show == 2
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "status-icon status-delete-icon",
                                              },
                                              [_vm._v("已删除")]
                                            )
                                          : _vm._e(),
                                        items.course.is_show == 0
                                          ? _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "status-icon status-shelves-down-icon",
                                              },
                                              [_vm._v("已下架")]
                                            )
                                          : _vm._e(),
                                      ]),
                                      _c("p", { staticClass: "order-title" }, [
                                        _vm._v(_vm._s(items.course.title)),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "pay-msg" }, [
                                      _c("span", { staticClass: "red" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.pay_amount > 0
                                              ? "￥" + item.pay_amount
                                              : "免费"
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            items.target_type == "classroom"
                                              ? "班级"
                                              : "课程"
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.orderStatus(item.order_status)
                                          )
                                        ),
                                      ]),
                                    ]),
                                    _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.payMentTxt(item.payment))
                                        ),
                                      ]),
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "do" },
                                      [
                                        item.order_status == "created"
                                          ? _c(
                                              "a-button",
                                              {
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.pay(
                                                      item.origin_sn,
                                                      item.payment
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("立即支付")]
                                            )
                                          : _vm._e(),
                                        item.order_status == "created"
                                          ? _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  color: "#0071F5",
                                                  background: "#fff",
                                                },
                                                attrs: { type: "primary" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.orderCancel(
                                                      item.origin_sn,
                                                      1
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("取消订单")]
                                            )
                                          : _vm._e(),
                                        item.invoice_status == 1 &&
                                        _vm.$store.state.open_wechat_pay == 0
                                          ? _c(
                                              "a-button",
                                              {
                                                staticStyle: {
                                                  color: "#0071F5!important",
                                                  borderColor:
                                                    "#0071F5!important",
                                                },
                                                attrs: { type: "default" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.takeInvoice(
                                                      item.origin_sn,
                                                      item.invoice_status
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("开发票")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              color: "#0071F5",
                                              cursor: "pointer",
                                              "margin-bottom": "10px",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.go(item.origin_sn)
                                              },
                                            },
                                          },
                                          [_vm._v("订单详情")]
                                        ),
                                        item.invoice_status == 3
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  color: "#0071F5",
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.seeInvoice(
                                                      item.origin_sn,
                                                      item.invoice_status
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看发票")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                    _c("a-pagination", {
                      attrs: { total: _vm.total, "show-less-items": "" },
                      on: { change: _vm.changePage },
                      model: {
                        value: _vm.page,
                        callback: function ($$v) {
                          _vm.page = $$v
                        },
                        expression: "page",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            width: "700px",
            footer: "",
            title: "立即付款",
          },
          on: { cancel: _vm.cancel },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c("div", { staticClass: "txt-info" }, [
            _c("div", [
              _c("p", { staticClass: "order-info-title" }, [
                _vm._v("订单号" + _vm._s(_vm.order.sn)),
              ]),
              _c("div", { staticClass: "order-info" }, [
                _c("p", [
                  _vm._v("下单时间：" + _vm._s(_vm.order.created_time)),
                ]),
                _c("p"),
              ]),
              _c("div", {}, [
                _c("p", [
                  _vm._v("应付金额：￥" + _vm._s(_vm.order.pay_amount)),
                ]),
                _c("p"),
              ]),
              _c("div", {}, [
                _c("p", [
                  _vm._v(
                    "支付方式：" +
                      _vm._s(
                        _vm.payType == "wechat" ? "微信支付" : "支付宝支付"
                      )
                  ),
                ]),
              ]),
            ]),
            _c("div", [
              _vm._v("剩余支付时间："),
              _c(
                "span",
                { staticStyle: { color: "#c00", "font-size": "20px" } },
                [_vm._v(_vm._s(_vm.showTimeInfo))]
              ),
            ]),
          ]),
          _c("div", { staticClass: "pay-info" }, [
            _c("div", [
              _c("div", { staticClass: "code-wrap" }, [
                _c("img", {
                  staticClass: "wx_pay_qrcode",
                  attrs: { src: _vm.wx_pay_qrcode, alt: "" },
                }),
              ]),
            ]),
            _c("div", {}, [
              _vm.payType == "wechat"
                ? _c("img", {
                    staticClass: "pay-icon",
                    attrs: {
                      src: require("../../assets/images/payicon.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              _vm.payType == "adapay"
                ? _c("img", {
                    staticStyle: { width: "64px", height: "64px" },
                    attrs: {
                      src: require("../../assets/images/payiconali.png"),
                      alt: "",
                    },
                  })
                : _vm._e(),
              _c("p", [
                _vm._v(_vm._s(_vm.payType == "wechat" ? "微信" : "支付宝")),
              ]),
              _c("p", [
                _vm._v(
                  "请使用" +
                    _vm._s(_vm.payType == "wechat" ? "微信" : "支付宝") +
                    "扫描左侧二维码以完成支付"
                ),
              ]),
              _c("p", { staticClass: "red" }, [
                _vm._v("￥" + _vm._s(_vm.order.pay_amount)),
              ]),
            ]),
          ]),
        ]
      ),
      _vm.$store.state.open_wechat_pay == 0
        ? _c(
            "a-modal",
            {
              attrs: {
                width: "500px",
                footer: "",
                title: "开发票",
                destroyOnClose: _vm.destroyOnClose,
              },
              model: {
                value: _vm.visible2,
                callback: function ($$v) {
                  _vm.visible2 = $$v
                },
                expression: "visible2",
              },
            },
            [
              _vm.invoice_status == 1
                ? _c("div", { staticClass: "modal-top" }, [
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("发票类型"),
                          ]),
                          _c(
                            "a-radio-group",
                            [_c("a-radio", [_vm._v(" 电子普通发票 ")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("发票抬头"),
                          ]),
                          _c(
                            "a-radio-group",
                            {
                              on: { change: _vm.onChange },
                              model: {
                                value: _vm.value,
                                callback: function ($$v) {
                                  _vm.value = $$v
                                },
                                expression: "value",
                              },
                            },
                            [
                              _c("a-radio", { attrs: { value: 0 } }, [
                                _vm._v(" 个人 "),
                              ]),
                              _c("a-radio", { attrs: { value: 1 } }, [
                                _vm._v(" 单位 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm.value == 0
                      ? _c("div", { staticClass: "input-item" }, [
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("发票内容"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入个人或您的姓名" },
                                model: {
                                  value: _vm.buyer_name,
                                  callback: function ($$v) {
                                    _vm.buyer_name = $$v
                                  },
                                  expression: "buyer_name",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.value == 1
                      ? _c("div", { staticClass: "input-item" }, [
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("企业名称"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入企业名称" },
                                model: {
                                  value: _vm.buyer_name,
                                  callback: function ($$v) {
                                    _vm.buyer_name = $$v
                                  },
                                  expression: "buyer_name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {},
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("企业税号"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入企业税号" },
                                model: {
                                  value: _vm.tax_num,
                                  callback: function ($$v) {
                                    _vm.tax_num = $$v
                                  },
                                  expression: "tax_num",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("手机号"),
                          ]),
                          _c("a-input", {
                            attrs: { placeholder: "请输入手机号" },
                            model: {
                              value: _vm.mobile,
                              callback: function ($$v) {
                                _vm.mobile = $$v
                              },
                              expression: "mobile",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "input-item" }, [
                      _c(
                        "div",
                        {},
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("收票邮箱"),
                          ]),
                          _c("a-input", {
                            attrs: { placeholder: "请输入接收发票的邮箱" },
                            model: {
                              value: _vm.email,
                              callback: function ($$v) {
                                _vm.email = $$v
                              },
                              expression: "email",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm.invoice_status == 1
                ? _c("div", { staticClass: "modal-bot" }, [
                    _c("p", { staticClass: "invoice-title" }, [
                      _vm._v("开具发票规则"),
                    ]),
                    _c("div", { staticClass: "content" }, [
                      _c("p", [
                        _vm._v(
                          "1. 根据国家税务规定，不能向个人开具增值税专用发票"
                        ),
                      ]),
                      _c("p", [_vm._v("2. 发票金额不含优惠券支付部分")]),
                      _c("p", [
                        _vm._v("3. 不同开票主体的发票，需要分别提交开票申请"),
                      ]),
                      _c("p", [
                        _vm._v(
                          "4. 电子普通发票是税局认可的有效付款凭证，其法律效力、基本用途及使用规定同纸质发票"
                        ),
                      ]),
                      _c("p", [_vm._v("5. 请您按照税法规定使用发票")]),
                      _c("p", [
                        _vm._v("6. 有任何疑问，请您及时咨询我们的客服"),
                      ]),
                    ]),
                  ])
                : _vm._e(),
              _vm.invoice_status == 1
                ? _c(
                    "div",
                    { staticClass: "invoice-btn" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.startInvoice },
                        },
                        [_vm._v("立即开票")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.invoice_status == 3
                ? _c("div", { staticClass: "see-invoice" }, [
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("发票类型")]),
                      _c("p", [_vm._v("电子普通发票")]),
                    ]),
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("发票抬头")]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.title))]),
                    ]),
                    _c("div", {}, [
                      _c("label", { attrs: { for: "" } }, [_vm._v("收票邮箱")]),
                      _c("p", [_vm._v(_vm._s(_vm.invoice.email))]),
                    ]),
                  ])
                : _vm._e(),
              _vm.invoice_status == 3
                ? _c("div", { staticClass: "invoice-img" }, [
                    _c("div", { staticClass: "invoice-img-title" }, [
                      _c("span", { staticStyle: { "font-weight": "bold" } }, [
                        _vm._v("发票"),
                      ]),
                      _c(
                        "a",
                        {
                          staticStyle: { color: "#0071F5", cursor: "pointer" },
                          attrs: {
                            href: _vm.invoice.img,
                            download: "",
                            target: "_blank",
                          },
                        },
                        [_vm._v("下载发票")]
                      ),
                    ]),
                    _c("img", { attrs: { src: _vm.invoice.img, alt: "" } }),
                  ])
                : _vm._e(),
              _vm.invoice_status == 3
                ? _c(
                    "div",
                    { staticClass: "invoice-btn" },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.invoiceOk },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }