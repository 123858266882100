var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "error" },
    [
      _vm._m(0),
      _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
        _vm._v("域名访问错误...！请确定域名是否正确！"),
      ]),
      _c(
        "a-button",
        {
          style: { background: _vm.$store.state.themeColor, color: "#fff" },
          on: {
            click: function ($event) {
              return _vm.goIndex()
            },
          },
        },
        [_vm._v("返回首页")]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "img", staticStyle: { "margin-bottom": "30px" } },
      [
        _c("img", {
          attrs: { src: require("../../assets/images/error.png"), alt: "" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }