var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "a-carousel",
        { staticClass: "carousel", attrs: { arrows: "", autoplay: "" } },
        [
          _c(
            "div",
            {
              staticClass: "custom-slick-arrow",
              staticStyle: { left: "10px", zIndex: "1" },
              attrs: { slot: "prevArrow" },
              slot: "prevArrow",
            },
            [_c("a-icon", { attrs: { type: "left-circle" } })],
            1
          ),
          _c(
            "div",
            {
              staticClass: "custom-slick-arrow",
              staticStyle: { right: "10px" },
              attrs: { slot: "nextArrow" },
              slot: "nextArrow",
            },
            [_c("a-icon", { attrs: { type: "right-circle" } })],
            1
          ),
          _vm._l(_vm.banners, function (item, i) {
            return _c(
              "div",
              { key: i, staticClass: "carouselImgWrap" },
              [
                item.link_type == 2
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: `/course/studydetail?id=${item.link}&course_type=system_course`,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img_a",
                          attrs: { src: item.image, alt: "" },
                        }),
                      ]
                    )
                  : _vm._e(),
                item.link_type == 1
                  ? _c(
                      "router-link",
                      {
                        attrs: { to: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.goThree(item, i)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img_a",
                          attrs: { src: item.image, alt: "" },
                        }),
                      ]
                    )
                  : _vm._e(),
                item.link_type == 3
                  ? _c(
                      "router-link",
                      {
                        attrs: {
                          to: `/course/studydetail?id=${item.link}&course_type=tenant_course`,
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "img_a",
                          attrs: { src: item.image, alt: "" },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "content" },
        [
          _c("ul", { staticClass: "flowWrap" }, [
            _vm._m(0),
            _vm._m(1),
            _vm.hostname == "jxjy.zjttv.cn"
              ? _c("li", { staticClass: "item" }, [
                  _vm._m(2),
                  _c("div", { staticClass: "line" }),
                ])
              : _vm._e(),
            _vm._m(3),
            _vm._m(4),
            _vm.hostname == "jxjy.zjttv.cn"
              ? _c("li", { staticClass: "item" }, [
                  _vm._m(5),
                  _c("div", { staticClass: "line" }),
                ])
              : _vm._e(),
            _vm._m(6),
          ]),
          _vm.categorys.length != 0
            ? _c("div", { staticClass: "product-list" }, [
                _c("div", { staticClass: "titleWrap" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.categorys[0].name)),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.more(1)
                        },
                      },
                    },
                    [_vm._v("更多")]
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "product-content" },
                  _vm._l(_vm.list, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        on: {
                          click: function ($event) {
                            return _vm.go(item, i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "hover-item" }, [
                          _c("div", { staticClass: "front" }, [
                            _c("div", { staticClass: "imgWrap pr" }, [
                              _c("div", { staticClass: "class-icon" }, [
                                _vm._v(_vm._s(item.learn_status_text)),
                              ]),
                              _c("img", {
                                attrs: { src: item.picture, alt: "" },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.type == "live",
                                      expression: "item.type == 'live'",
                                    },
                                  ],
                                  staticClass: "icons",
                                },
                                [_vm._v("直播")]
                              ),
                            ]),
                            _c("div", { staticClass: "msgWrap" }, [
                              _c("p", { staticClass: "msgTitle" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("div", { staticClass: "price-item" }, [
                                _vm.$store.state.can_pay == 1
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "price-sign" },
                                        [_vm._v("￥")]
                                      ),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.price > 0 ? item.price : "免费"
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("p", { staticClass: "classTime" }, [
                                  _vm._v(_vm._s(item.period) + " 课时"),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "back" },
                            [
                              _c("p", { staticClass: "back-title" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("p", { staticClass: "back-info" }, [
                                _vm._v(_vm._s(item.about)),
                              ]),
                              _c("a-button", { attrs: { type: "default" } }, [
                                _vm._v("去学习"),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _c("Notice", {
            attrs: {
              title: "公告通知",
              width: "376",
              height: "306",
              news: _vm.news,
              type: "index",
            },
          }),
          _vm.categorys.length != 0 && _vm.hostname == "jxjy.zjttv.cn"
            ? _c("div", { staticClass: "product-list" }, [
                _c("div", { staticClass: "titleWrap" }, [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.categorys[1].name)),
                  ]),
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          return _vm.more()
                        },
                      },
                    },
                    [_vm._v("更多")]
                  ),
                ]),
                _c(
                  "ul",
                  { staticClass: "product-content" },
                  _vm._l(_vm.list2, function (item, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        on: {
                          click: function ($event) {
                            return _vm.go(item, i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "hover-item" }, [
                          _c("div", { staticClass: "front" }, [
                            _c("div", { staticClass: "imgWrap" }, [
                              _c("img", {
                                attrs: { src: item.picture, alt: "" },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: item.type == "live",
                                      expression: "item.type == 'live'",
                                    },
                                  ],
                                  staticClass: "icons",
                                },
                                [_vm._v("直播")]
                              ),
                            ]),
                            _c("div", { staticClass: "msgWrap" }, [
                              _c("p", { staticClass: "msgTitle" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("div", { staticClass: "price-item" }, [
                                _vm.$store.state.can_pay == 1
                                  ? _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "price-sign" },
                                        [_vm._v("￥")]
                                      ),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          _vm._s(
                                            item.price > 0 ? item.price : "免费"
                                          )
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _c("p", { staticClass: "classTime" }, [
                                  _vm._v(_vm._s(item.period) + " 课时"),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "back" },
                            [
                              _c("p", { staticClass: "back-title" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("p", { staticClass: "back-info" }, [
                                _vm._v(_vm._s(item.about)),
                              ]),
                              _c("a-button", { attrs: { type: "default" } }, [
                                _vm._v("去学习"),
                              ]),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm.hostname == "jxjy.zjttv.cn"
            ? _c("div", [
                _vm._m(7),
                _vm.showModule
                  ? _c(
                      "div",
                      { staticClass: "search-module" },
                      [
                        _c("div", { staticClass: "search-wrap" }, [
                          _c(
                            "div",
                            { staticClass: "search-item" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("编号"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入证书编号" },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "search-item" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("身份证号"),
                              ]),
                              _c("a-input", {
                                attrs: { placeholder: "请输入身份证号" },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "a-button",
                          {
                            staticClass: "search-btn",
                            style: {
                              background: _vm.$store.state.themeColor,
                              color: "#fff",
                            },
                            on: { click: _vm.searchCert },
                          },
                          [_vm._v("查询")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "listModule" },
            _vm._l(_vm.listFull, function (item, i) {
              return _c("div", { key: i }, [
                _c("div", { staticClass: "titleWrap" }, [
                  _c("h3", { staticClass: "titles" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                ]),
                _c(
                  "ul",
                  _vm._l(item.course, function (items, i) {
                    return _c(
                      "li",
                      {
                        key: i,
                        on: {
                          click: function ($event) {
                            return _vm.go(items, i)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "imgWrap" }, [
                          _c("img", { attrs: { src: items.picture, alt: "" } }),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: items.type == "live",
                                  expression: "items.type == 'live'",
                                },
                              ],
                              staticClass: "icon",
                            },
                            [_vm._v("直播")]
                          ),
                        ]),
                        _c("h3", { staticClass: "title" }, [
                          _vm._v(_vm._s(items.title)),
                        ]),
                        _c("div", { staticClass: "doWrap" }, [
                          _vm.$store.state.can_pay == 1
                            ? _c("div", [
                                _c("span", { staticClass: "price-sign" }, [
                                  _vm._v("￥"),
                                ]),
                                _c("span", { staticClass: "price" }, [
                                  _vm._v(
                                    _vm._s(
                                      items.price > 0 ? items.price : "免费"
                                    )
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          _c("p", { staticStyle: { color: "#888" } }, [
                            _vm._v(_vm._s(items.period) + "课时"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "starWrap" },
                            [
                              _c("span", { staticClass: "starTxt" }, [
                                _vm._v("推荐指数："),
                              ]),
                              _c("van-rate", {
                                attrs: { size: "14px" },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("div", { staticClass: "in" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: require("../../assets/images/flow-1.png"), alt: "" },
          }),
        ]),
        _c("span", { staticClass: "txt" }, [_vm._v("注册登录")]),
      ]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("div", { staticClass: "in" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: require("../../assets/images/flow-2.png"), alt: "" },
          }),
        ]),
        _c("span", { staticClass: "txt" }, [_vm._v("选择课程")]),
      ]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "in" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", {
          attrs: { src: require("../../assets/images/nflow1.png"), alt: "" },
        }),
      ]),
      _c("span", { staticClass: "txt" }, [_vm._v("在线支付")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("div", { staticClass: "in" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: require("../../assets/images/flow-3.png"), alt: "" },
          }),
        ]),
        _c("span", { staticClass: "txt" }, [_vm._v("在线学习")]),
      ]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("div", { staticClass: "in" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: require("../../assets/images/flow-4.png"), alt: "" },
          }),
        ]),
        _c("span", { staticClass: "txt" }, [_vm._v("在线考试")]),
      ]),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "in" }, [
      _c("div", { staticClass: "icon" }, [
        _c("img", {
          attrs: { src: require("../../assets/images/nflow2.png"), alt: "" },
        }),
      ]),
      _c("span", { staticClass: "txt" }, [_vm._v("在线发证")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "item" }, [
      _c("div", { staticClass: "in" }, [
        _c("div", { staticClass: "icon" }, [
          _c("img", {
            attrs: { src: require("../../assets/images/flow-5.png"), alt: "" },
          }),
        ]),
        _c("span", { staticClass: "txt" }, [_vm._v("完成学习")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "titleWrap" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("证书查询")]),
      _c("span"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }