<template>
    <div>
        <div class="title-bg" :class="{'newbg':$store.state.tenant_idBind==3462}">
            <div class="title-inner">
                <div class="title-wrap">
                    <img src="../../assets/images/notice-icon.png" alt="">
                    <h3>合作院校</h3>
                </div>
                <img @click="addCert" style="cursor: pointer;width: 300px;height: 99px;margin-right: 20px;" src="../../assets/images/addh.png" alt="">
            </div>
        </div>

        <!-- <div class="mid">
            <div class="inner">
                <img @click="addCert" style="cursor: pointer;width: 300px;height: 100px;margin-right: 20px;" src="../../assets/images/addh.png" alt="">
                <span style="margin-right: 40px;">享受试点申请，学科共建，全流程无忧服务</span>
                <span>客服电话：15910630984</span>
            </div>
        </div> -->

        <div class="school-content" v-if="data.length > 0">
            <div class="item" v-if="data[0] && data[0].colleges.length > 0">
                <p :style="{background: $store.state.themeColor}">{{data[0].name}}</p>
                <ul>
                    <li @click="linkUrl(item)" v-for="(item, i) in data[0].colleges" :key="i">&nbsp;{{item.name}}</li>
                </ul>
            </div>
            <div class="item" v-if="data[1] && data[1].colleges.length > 0">
                <p :style="{background: $store.state.themeColor}">{{data[1].name}}</p>
                <ul>
                    <li @click="linkUrl(item)" v-for="(item, i) in data[1].colleges" :key="i">&nbsp;{{item.name}}</li>
                </ul>
            </div>
            <div class="item" v-if="data[2] && data[2].colleges.length > 0">
                <p :style="{background: $store.state.themeColor}">{{data[2].name}}</p>
                <ul>
                    <li @click="linkUrl(item)" v-for="(item, i) in data[2].colleges" :key="i">&nbsp;{{item.name}}</li>
                </ul>
            </div>
        </div>
        <div class="empty" style="height: 400px" v-if="data.length == 0">
            <img src="../../assets/images/empty.png" alt="">
            <span style="color: #666;font-size: 12px">暂无合作院校</span>
        </div>

        <a-modal v-model="visible" :title="null" id="school-cert-in" :footer="null" width="700px" :destroyOnClose="true">
                <h3 style="font-size: 24px;line-height: 26px;text-align: center;font-weight: bold;border-bottom: 1px solid #ddd;padding: 0 0 15px;margin-top: -10px;">证书申请信息</h3>
                <p style="text-align: center; margin-bottom: 40px;">请准确填写相关信息，以确保我们能尽快准确的为您提供服务</p>
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item label="院校名称" style="margin-left: -69px">
                        <a-input
                            maxLength='50'
                            v-decorator="['name', { rules: [{ required: true, message: '请输入院校名称' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="院校所在地" style="margin-left: -69px">
                        <a-input
                            maxLength='50'
                            v-decorator="['address', { rules: [{ required: true, message: '请输入院校所在地' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="院校类型" style="margin-left: -69px">
                        <a-select
                            size="large"
                            v-decorator="[
                            'type',
                            { rules: [{ required: true, message: '请选择院校类型' }] },
                            ]"
                            placeholder="请选择院校类型"
                            @change="handleSelectChange"
                        >
                            <a-select-option value="中职院校">
                            中职院校
                            </a-select-option>
                            <a-select-option value="高职院校">
                            高职院校
                            </a-select-option>
                            <a-select-option value="本科院校">
                            本科院校
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="联系人姓名" style="margin-left: -69px">
                        <a-input
                            maxLength='20'
                            v-decorator="['contacter', { rules: [{ required: true, message: '请输入联系人姓名' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="手机号" style="margin-left: -69px">
                        <a-input
                            maxLength='11'
                            v-decorator="['mobile', { rules: [{ required: true, message: '请输入手机号'}, {pattern: new RegExp(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/), message: '手机号格式错误！'}] }]"
                        />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" :style="{background: $store.state.themeColor,height: '40px',width: '100%', marginTop: '20px'}">提交</a-button>
                    </a-form-item>
                </a-form>
            </a-modal>
            <a-modal v-model="visibleAgain" title="证书申请信息" :footer="null">
                <p style="text-align: center;">{{againMsg}}</p>
                <div class="close-btn">
                    <a-button @click="close" :style="{background: $store.state.themeColor,color: '#fff', border: 'none'}">关闭</a-button>
                </div>
            </a-modal>
        
    </div>
</template>

<script>
import {
  collegeList,
  collegeApply
} from '../../http/api'
export default {
    data() {
        return {
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            data: '',
            visible: false,
            visibleAgain: false,
            isAdd: false,
            againMsg: ''
        }
    },
    mounted() {
        collegeList().then(res => {
            if(res.data.code == 200) {
                this.data = res.data.data
            }
        })
    },
    methods: {
        linkUrl(item) {
            window.open(item.link)
        },
        close() {
            if(this.isAdd) {
                this.visibleAgain = false
                this.visible = false
            } else {
                this.visibleAgain = false
            }
        },
        addCert() {
            this.visible = true
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if(!err) {
                    collegeApply(values).then(res => {
                        if(res.data.code == 200) {
                            this.visibleAgain = true
                            this.isAdd = true
                            this.againMsg = res.data.message
                        } else {
                            this.visibleAgain = true
                            this.isAdd = false
                            this.againMsg = res.data.message
                        }
                    })
                }
                
            });
        },
        handleSelectChange(value) {
            console.log(value);
        },
    }
}
</script>

<style scoped>
.title-bg {
    height: 130px;
    background: url('../../assets/images/title-bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
}
.title-inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-inner img {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3 {
  font-size: 30px;
  margin-bottom: 0;
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}
.school-content {
    width: 1200px;
    margin: 0 auto 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 500px;
}
.item {
    width: 350px;
    box-shadow: 0 0 5px #ddd;
    min-height: 500px;
    border-radius: 6px;
}
.item li {
    text-align: left;
    font-size: 16px;
    line-height: 18px;
    padding: 10px 20px;
    cursor: pointer;
    transition: all .2s;
}
.item li::before{
    content: "★"; 
    color: rgb(8, 128, 241);
}
.item li:hover {
    color: rgb(8, 128, 241);
    font-weight: bold;
}
.item p {
    width: 100%;
    height: 50px;
    color: #fff;
    font-size: 18px;
    line-height: 50px;
    border-radius: 6px;
    margin: 0 auto 20px;
}
.mid {
    height: 120px;
    box-shadow: 0 0 5px #ddd;
    margin-bottom: 30px;
}
.inner {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
#school-cert-in .ant-form-item {
    display: flex;
    justify-content: center;
    width: 650px;
}
#school-cert-in .ant-input {
    height: 40px;
}
.close-btn {
    text-align: center;
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty img {
    width: 120px;
    height: 74px;
}
</style>