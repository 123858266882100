<template>
    <div class="rssb_main">
        <div class="banner" @click="onBanner">
            <img src="@/assets/images/banner.jpg" alt="">
        </div>
        <div class="rssb_list">
            <div class="rssb_list_item" v-for="(item,index) in carouselList" :key="index">
              <p class="common_font rssb_date">{{item.date}}</p>
               <div v-for="(itemA,index) in item.data" :key="index" class="rssb-list_item_main">
                    <span class="round "></span>
                    <div class="rssb_list_item_date common_block">
                        <span class="common_fonts itemA_data ">{{itemA.time}}</span>
                    </div>
                   <div class="list_container">
                    <div v-for="(itemB,index) in itemA.itemList" :key="index" class="list_item flex">
                        <img class="head_img " src="@/assets/images/lizhien.png" alt="">
                        <div class="common_blocks" style="text-align: left;">
                            <span class="common_font text_title">{{itemB.title}}</span>
                            <span class="common_fonts text_name " v-if="itemB.name">主讲人：{{itemB.name}}</span>
                        </div>                        
                    </div>
                   </div>
                   <el-button class="btn" type="primary" plain  @click="goIframe(itemA)">进入直播间</el-button>
                </div>
            </div>           
        </div>

    </div>
</template>

<script>
import {joinLive} from '@/http/api'

    export default {
         data(){
             return{
                 carouselList:[
                    {
                        date:'2022年8月15日',
                        data:[
                            {
                            time:'09:00-12:00',
                            itemList:[
                                {
                                    title:'领导致辞开班仪式',
                                    name:'',

                                },
                                {
                                    title:'李卫院士致辞',
                                    name:'李卫',

                                },
                                {
                                    title:'稀土磁性材料科技创新',
                                    name:'方以坤',

                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/3ed235a4c7e24c6f9eb9a8b2e718fa23'
                        },{
                            time:'14:30-17:30',
                            itemList:[
                                {
                                    title:'大力提升知识产权运用能力，激励和保障高水平创新与高质量发展——关于知识产权强国建设与十四五规划若干问题的思考',
                                    name:'韩秀成',                                    
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/442110b3031f47a98e52681109782113'
                        },
                        ]
                    },
                    {
                        date:'2022年8月16日',
                        data:[{
                            time:'09:00-12:00',
                            itemList:[
                                {
                                    title:'从事科技成果转移转化服务应具备的能力与素质',
                                    name:'郝强',
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/7264818198a74be18b8fc858a2690655' 
                        },{
                            time:'14:30-17:30',
                            itemList:[
                                {
                                    title:'科技成果转化方式与途径',
                                    name:'吴寿仁',
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/ffb4c06406424b009cc98c8ef410e444'
                        },
                        ]
                    },
                    {
                        date:'2022年8月17日',
                        data:[{
                            time:'09:00-12:00',
                            itemList:[
                                {
                                    title:'稀土磁制冷技术和永磁磁路设计应用',
                                    name:'黄焦宏',                                     
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/1a1a93da84554b24a9be1cebec930ed2'
                        },{
                            time:'14:30-17:30',
                            itemList:[
                                {
                                    title:'资本视角下的科技成果转化',
                                    name:'李建军',                                    
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/634619ab3870470caa1e22c382f13f33'
                        },
                        ]
                    },
                    {
                        date:'2022年8月18日',
                        data:[{
                            time:'09:00-12:00',
                            itemList:[
                                {
                                    title:'构建科技创新全链条服务生态体系',
                                    name:'孙鹏',                                
                                },
                                {
                                    title:'高价值专利挖掘与培育',
                                    name:'李斌',                                
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/177a11c9e6b749cfb2067afdcc115b81'
                        },{
                            time:'14:30-17:30',
                            itemList:[
                                {
                                    title:'交流讨论',
                                    name:'',                                     
                                },
                            ],
                            src:'https://play.yunxi.tv/livestream/09b36678774342d486ba5ba085142c5f'
                        },
                        ]
                    },
                 ]
             }
         },
         methods:{
             onBanner(){
                 window.location.href='https://0f8dd640932a6989.share.mingdao.net/form/bcc2ba0902a24ab192a7e64a1fb44724'
             },
             goIframe(item){
                 this.ajax_joinLive(item);
                 
             },
             ajax_joinLive(item){
                 let data = {
                     title:item.time
                 };
                 joinLive(data).then(res=>{
                     console.log('resss',res)
                    if(res.data.code==200){
                        this.$router.push({
                            path:'/rssbIframe',
                            query:{
                                src:item.src
                            }
                        })
                    }
                 })
             }
         }
    }
</script>

<style scoped>
div{
    line-height: 1.2;
}
p span {
    padding: 0px;
    margin: 0px;
}
.el-button{
    width: 160px;
    height: 30px;
}


.flex {
    display: flex;
}
.flex_column {
    flex-direction: column;
}
.rssb_main{
    width: 1200px;
    margin: 30px auto;
    box-sizing: border-box;

}
.banner img{
    width:100%;
    height: 400px;
    display: inline-block;
    cursor: pointer;
}
.rssb_list{
    padding-top: 20px;
}
.rssb_list_item{
    width: 100%;
    position: relative;
}

.absolute {
    position: absolute;
}
.rssb-list_item_main {
    position: relative;
    display: flex;
    padding: 30px;
    border: 1px solid rgba(225,229,237,0.7500);
    border-radius: 10px;
    margin-bottom: 10px;

}

.rssb_list_item :last-child {
    margin-bottom: 5px;
}
.rssb_list_item_date{
  text-align: center;
  /* padding-left: 60px; */
  /* width: 124px; */
  /* height: 16px; */
  line-height: 16px;
  
}
.common_block span{
   display: block;
}

/* .common_blocks span{
   display: inline-block;
} */
.round{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #24B324;
    border-radius: 50%;
    /* margin-left: 20px; */
    margin-top: 16px;
}
.common_font{
    font-size: 18px;
    font-weight: 400;
    color: #000000;
    /* line-height: 24px; */
}
.common_fonts{
    font-weight: 400;
    color: #000000;
    font-size: 18px;
}
.rssb_date {
    font-weight: 400;
    color: #000000;
    font-size: 16px; 
    /* margin-left: 20px; */
    margin-top: 22px;
    margin-bottom: 22px;
}
.itemA_data {
    font-weight: bold;
    height: 16px;
    font-size: 18px;
    display: block;
    margin-top: 13px;
    margin-left:20px;
    color: #24B324;
}
.head_img{
    width:50px;
    height:50px;
    margin-right: 20px;
    margin-left: 76px;
    border-radius: 50%;
    display: inline-block;
    overflow: hidden;
    /* margin-top: -4px; */
}

.text_title{
    display: block;
    width: 594px;
    /* height: 67px; */
    font-family: FZLTCHK--GBK1-0;
    font-weight: bold;
    margin-top: 4px;
    /* display: inline-block; */
}

.text_name {
    font-size: 14px;
    margin-top:8px;
    display: inline-block;
}
.btn{
    width: 120px;
    height: 30px;
    background: rgba(0,39,118,0);
    border: 1px solid rgba(0,0,0,0.7500);
    border-radius: 15px;
    padding: 0;
    color: #fff;
    position: absolute;
    right:30px;
    top:35px;
    background: #002776;
    font-size: 16px;
    font-weight: bold;
    }

.el-button--primary.is-plain:focus, .el-button--primary.is-plain:hover{
    background: #0040bf;
}  
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
     background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
     background-color: #d3dce6;
  }
    ::v-deep.el-carousel__arrow i {
        font-size: 29px !important;
    }
    .list_item{
      position: relative;
      margin-bottom: 25px;
  }
  .list_container .list_item:last-child {
    margin-bottom: 0px;
  }
</style>