<template>
    <div>
        <!-- 1+x普通版首页 -->
        <div class="inner" v-if="$store.state.template == 3 && $store.state.hostname != $store.state.demonstrationHostname">
            <div class="top-wrap">
                <div class="banner">
                    <a-carousel arrows autoplay dotPosition="right">
                        <div
                        slot="prevArrow"
                        class="custom-slick-arrow"
                        style="left: 10px;zIndex: 1"
                        >
                        <a-icon type="left-circle" />
                        </div>
                        <div slot="nextArrow"  class="custom-slick-arrow" style="right: 10px">
                        <a-icon type="right-circle" />
                        </div>
                        <div class="carouselImgWrap" v-if="banners.length == 0">
                            <router-link to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                        </div>
                        <div class="carouselImgWrap" v-for="(item, i) in banners" :key="i">
                            <router-link v-if="banners.length == 0" to="">
                                <img class="img_a" src="https://bjadks-assets.oss-cn-beijing.aliyuncs.com/assets/eduvue/images/default_bg.jpg" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 2" :to="`/course/studydetail?id=${item.link}&course_type=system_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 1" to="" @click.native="goThree(item, i)">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                            <router-link v-if="item.link_type == 3" :to="`/course/studydetail?id=${item.link}&course_type=tenant_course`">
                                <img class="img_a" :src="item.image" alt="">
                            </router-link>
                        </div>
                    </a-carousel>
                </div>
                <div class="news">
                    <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <ul class="news-menus">
                                <li class="menus-li-1x" :style="{borderColor: menu_li_id == item.id? $store.state.themeColor: ''}" v-for="(item, i) in top_cates" @click="checkNews(item, i)">{{item.name}}</li>
                            </ul>
                        </div>
                        <span class="more" @click="moreNotice">查看全部&nbsp;></span>
                    </div>
                    <ul class="news-wrap" v-if="newsList.length > 0">
                        <li v-for="(item, i) in newsList" :key="i">
                            <div class="date">
                                <span class="date-day">{{item.created_at.split('-')[2].split(' ')[0]}}</span>
                                <span class="dates">{{`${item.created_at.split('-')[0]}/${item.created_at.split('-')[1]}`}}</span>
                            </div>
                            <div class="news-item">
                                <p @click="goNotice(item, i)" class="news-title">{{item.title}}</p>
                                <p class="news-info">{{item.infos}}</p>
                            </div>
                        </li>
                    </ul>
                    <div class="empty" style="height: 380px;background: #FAFAFA;" v-if="newsList.length == 0">
                        <img src="../../assets/images/empty.png" alt="">
                        <span style="color: #666;font-size: 12px">暂无数据</span>
                    </div>
                </div>
            </div>
            <div class="flower">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f02.png" alt="">
                    <span>注册登录</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f01.png" alt="">
                    <span>选择课程</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f03.png" alt="">
                    <span>在线学习</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f04.png" alt="">
                    <span>在线考试</span>
                </div>
                <img class="flower-sign" src="../../assets/images/flower-sign.png" alt="">
                <div class="flower-item">
                    <img src="../../assets/images/tem-two-f05.png" alt="">
                    <span>录入证书</span>
                </div>
            </div>
            <div class="content-wrap">
                <div class="content-left">
                    <div class="live-module" v-if="$store.state.can_live && lives.length > 0">
                        <div class="title-bar">
                            <div class="title-wrap">
                                <img src="../../assets/images/title-icon.png" alt="">
                                <span>最近直播</span>
                            </div>
                            <span v-if="lives.length >= 2" class="more" @click="more('live')">查看全部&nbsp;></span>
                        </div>
                        <ul class="live-list" v-if="lives.length > 0">
                            <li class="live-item" @click="go(item, i)" v-for="(item, i) in lives" :key="i">
                                <div class="live-top">
                                    <img :src="item.authors[0].avatar" alt="">
                                    <p class="title">{{item.title}}</p>
                                </div>
                                <div class="live-bot">
                                    <span class="name">{{item.authors[0].name}}</span>
                                    <div v-if="liveStatus(item.start_time, item.end_time) == 1" class="live-sign" :style="{background: $store.state.themeColor,color: '#fff'}">
                                        <span>正在直播</span>
                                        <img src="../../assets/images/going.png" alt="">
                                    </div>
                                    <div v-if="liveStatus(item.start_time, item.end_time) == 0" class="live-sign">{{item.start_time.split(':')[0]+':'+item.start_time.split(':')[1]}}&nbsp;开始</div>
                                    <div v-if="liveStatus(item.start_time, item.end_time) == 2" class="live-sign">已结束</div>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="classes-wrap" v-if="$store.state.can_class && list.length > 0">
                        <div class="title-bar">
                            <div class="title-wrap">
                                <img src="../../assets/images/title-icon.png" alt="">
                                <span>{{categorys[0].name}}</span>
                            </div>
                            <span v-if="list.length >= 2" class="more" @click="more('classes')">查看全部&nbsp;></span>
                        </div>
                        <ul class="classes-list">
                            <li class="classes-item" v-for="(item, i) in list" :key="i" @click="go(item, i)">
                                <div class="img">
                                    <img class="img_a" :src="item.picture" alt="">
                                    <div v-if="item.learn_status == 1" class="classes-sign-ing"><span style="padding-right: 20px">进行中</span></div>
                                    <div v-if="item.learn_status == 2" class="classes-sign-end">已结束</div>
                                </div>
                                <div class="classes-info">
                                    <p class="classes-title">{{item.title}}</p>
                                    <p class="classes-about">{{item.about}}</p>
                                    <p style="color: #333;margin-bottom: 0">{{item.lesson_num}}门课  |  {{item.period}}课时  |  {{item.learn_total}}人已学</p>
                                    <div class="classes-do-wrap">
                                        <div v-if="item.order_status == 0 && $store.state.can_pay">
                                            <span v-if="item.price && item.price > 0" class="classes-sign">￥</span>
                                            <span class="classes-price">{{item.price > 0? item.price: '免费'}}</span>
                                        </div>
                                        <div v-if="item.order_status != 0"></div>
                                        <div class="classes-go">
                                            <div class="classes-go-img"></div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="list-module" v-if="categorys.length > 0">
                        <ul>
                            <li class="module-item" v-for="(item, i) in listFull" :key="i">
                                <div class="title-bar">
                                    <div class="title-wrap">
                                        <img src="../../assets/images/title-icon.png" alt="">
                                        <span>{{item.name}}</span>
                                    </div>
                                    <span v-if="item.course.length >= 3" class="more" @click="more('course', item.id)">查看全部&nbsp;></span>
                                </div>
                                <ul class="lesson-list">
                                    <li class="lesson-item" @click="go(items, i)" v-for="(items, is) in item.course" :key="is">
                                        <img class="img_a" :src="items.picture" alt="">
                                        <div class="lesson-msg-wrap">
                                            <p class="lesson-title">{{items.title}}</p>
                                            <p v-if="items.type == 'link'">课程来源：{{items.link_source}}</p>
                                            <div v-if="items.type != 'link'">
                                                <p class="lesson-about">{{items.period}}课时 | {{items.peroid_format}}分钟 | {{items.learn_total}}人已学</p>
                                                <p class="lesson-name" v-if="items.authors && items.authors.length > 0">
                                                    <span v-for="(itemss, iss) in items.authors.slice(0, 3)" :key="iss" :a="items.length">{{itemss.name}}<span v-if="items.authors.length > 3">{{iss == 2? '等': '、'}}</span><span v-if="items.authors.length>1 && items.authors.length<3">{{iss != 1?'、': ''}}</span></span>
                                                </p>
                                                <p class="lesson-name" v-else></p>
                                                <div class="lesson-price-info">
                                                    <div v-if="$store.state.can_pay">
                                                        <span v-if="items.price > 0" class="lesson-price-sign">￥</span>
                                                        <span class="lesson-price">{{items.price > 0? items.price: '免费'}}</span>
                                                    </div>
                                                    <div>
                                                        <van-rate size="14px" v-model="value" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="empty" style="height: 400px" v-if="categorys.length == 0">
                        <img src="../../assets/images/empty.png" alt="">
                        <span style="color: #666;font-size: 12px">暂无数据</span>
                    </div>
                </div>
                <div class="content-right">
                    <div class="right-module" @click="addCert">
                        <img style="cursor: pointer;" src="../../assets/images/addAcount.jpg" alt="">
                    </div>
                    <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <p style="font-size: 20px;line-height: 22px;margin-bottom: 0;">合作院校&nbsp;{{schoolTotal}}&nbsp;家</p>
                        </div>
                        <span class="more" @click="moreSchool">查看全部&nbsp;></span>
                    </div>
                    <div class="right-module" style="min-height: 300px">
                        <ul class="coll-tab">
                            <li @click="checkSchoolTab(item, i)" :class="tabIndex == i? 'coll-tab-ac': ' '" v-for="(item, i) in collegeData" :key="i">{{item.name}}</li>
                        </ul>
                        <ul class="school-list-wrap">
                            <li @click="linkUrl(item)" v-for="(item, i) in schoolList" :key="i">&nbsp;{{item.name}}</li>
                        </ul>
                    </div>
                    <!-- <div class="title-bar">
                        <div class="title-wrap">
                            <img src="../../assets/images/title-icon.png" alt="">
                            <p style="font-size: 20px;line-height: 22px;margin-bottom: 0;">院校评价</p>
                        </div>
                    </div>
                    <div class="right-module">
                        <ul class="comments">
                            <li v-for="item in 3">
                                <div class="info">
                                    <img src="" alt="">
                                    <span style="font-weight: bold;">李校长</span>
                                </div>
                                <p class="timer">2021-05-18</p>
                                <p class="txt">X证书真是帮了我们不少大忙！爱迪科森专业，高校有能力为1+X教育尽一份大力</p>
                            </li>
                        </ul>
                    </div> -->

                </div>
            </div>
            <a-modal v-model="visible" :title="null" id="school-cert-in" :footer="null" width="700px" :destroyOnClose="true">
                <h3 style="font-size: 24px;line-height: 26px;text-align: center;font-weight: bold;border-bottom: 1px solid #ddd;padding: 0 0 15px;margin-top: -10px;">证书申请信息</h3>
                <p style="text-align: center; margin-bottom: 40px;">请准确填写相关信息，以确保我们能尽快准确的为您提供服务</p>
                <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
                    <a-form-item label="院校名称" style="margin-left: -69px">
                        <a-input
                            maxLength='50'
                            v-decorator="['name', { rules: [{ required: true, message: '请输入院校名称' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="院校所在地" style="margin-left: -69px">
                        <a-input
                            maxLength='50'
                            v-decorator="['address', { rules: [{ required: true, message: '请输入院校所在地' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="院校类型" style="margin-left: -69px">
                        <a-select
                            size="large"
                            v-decorator="[
                            'type',
                            { rules: [{ required: true, message: '请选择院校类型' }] },
                            ]"
                            placeholder="请选择院校类型"
                            @change="handleSelectChange"
                        >
                            <a-select-option value="中职院校">
                            中职院校
                            </a-select-option>
                            <a-select-option value="高职院校">
                            高职院校
                            </a-select-option>
                            <a-select-option value="本科院校">
                            本科院校
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="联系人姓名" style="margin-left: -69px">
                        <a-input
                            maxLength='20'
                            v-decorator="['contacter', { rules: [{ required: true, message: '请输入联系人姓名' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="手机号" style="margin-left: -69px">
                        <a-input
                            maxLength='11'
                            v-decorator="['mobile', { rules: [{ required: true, message: '请输入手机号'}, {pattern: new RegExp(/^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/), message: '手机号格式错误！'}] }]"
                        />
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit" :style="{background: $store.state.themeColor,height: '40px',width: '100%', marginTop: '20px'}">提交</a-button>
                    </a-form-item>
                </a-form>
            </a-modal>
            <a-modal v-model="visibleAgain" title="证书申请信息" :footer="null">
                <p style="text-align: center;">{{againMsg}}</p>
                <div class="close-btn">
                    <a-button @click="close" :style="{background: $store.state.themeColor,color: '#fff', border: 'none'}">关闭</a-button>
                </div>
            </a-modal>
        </div>



        <!-- 1+x演示版首页 -->
        <div v-if="$store.state.template == 3 && $store.state.hostname == $store.state.demonstrationHostname">
            <div class="notice-1x">
                <div class="notice-1x-inner">
                    <div class="notice-1x-icon">
                        <img src="../../assets/images/1xnotice.png" alt="">
                    </div>
                    <ul class="news-wrap1x" v-if="news.length > 0">
                        <li v-for="(item, i) in news" :key="i">
                            <div class="date">
                                <span class="date-day">{{item.created_at.split('-')[2].split(' ')[0]}}</span>
                                <span class="dates">{{`${item.created_at.split('-')[0]}/${item.created_at.split('-')[1]}`}}</span>
                            </div>
                            <div class="news-item">
                                <p @click="goNotice(item, i)" class="news-title">{{item.title}}</p>
                                <p class="news-info">{{item.infos}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="one1x">
                <h3  class="title1x">国家认证1+X证书，就业无忧</h3>
                <p class="subt1x">反映职业活动和个人职业生涯发展所需要的综合能力</p>
                <div class="lv one1xitem">
                    <img src="../../assets/images/certificate1.png" alt="">
                    <img src="../../assets/images/certificate1.png" alt="">
                    <img src="../../assets/images/certificate1.png" alt="">
                </div>
                <div class="la one1xitem">
                    <img src="../../assets/images/certificate2.png" alt="">
                    <img src="../../assets/images/certificate2.png" alt="">
                    <img src="../../assets/images/certificate2.png" alt="">
                </div>
                <div class="hu one1xitem">
                    <img src="../../assets/images/certificate3.png" alt="">
                    <img src="../../assets/images/certificate3.png" alt="">
                    <img src="../../assets/images/certificate3.png" alt="">
                </div>
            </div>
            <div class="two1x">
                <h3 class="title1x">爱迪课堂，满足岗位进阶和终身学习的需要</h3>
                <p class="subt1x">开放注册、自主学习、学情跟踪、学时记录</p>
                <ul class="two1x-menu">
                    <li v-for="(item, i) in first"
                    @click="checkMenu(item, i)"
                    :class="i == index? 'two1x-menu-ac': ''">{{item.name}}</li>
                </ul>
                <ul class="two1x-list">
                    <li @click="goCourse(item, i)" v-for="(item, i) in list1x" :key="i">
                        <img :src="item.picture" alt="">
                        <h3 style="
                        line-height: 20px;
                        font-weight: bold;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                        overflow: hidden;
                        text-align: left;
                        padding: 0 20px;
                        margin-bottom: 12px;
                        font-size: 18px;">{{item.title}}</h3>
                        <p class="thr-sub-title" style="padding-bottom: 20px;" v-if="item.link == 'link'">
                            课程来源：{{item.link_source}}
                        </p>
                        <div v-if="item.link != 'link'">
                            <p class="thr-sub-title">{{item.infos}}</p>
                            <div class="two1x-price">
                                <span style="
                                font-size: 14px;
                                color:#999;
                                ">1人已学</span>
                                <!-- <span style="font-weight: bold;font-size: 20px; color: #F34026;">免费</span> -->
                            </div>
                        </div>

                    </li>
                </ul>
            </div>

            <div class="thr1x">
                <h3 style="color: #0C3950;" class="title1x">学分银行，国家级能力评定标准</h3>
                <p style="color: #5388A4;" class="subt1x">一人一账号，一号伴终身</p>
                <ul>
                    <li>
                        <img src="../../assets/images/bank1x1.png" alt="">
                        <h3>学分银行账户</h3>
                        <p>学习成果信息、学员信息、业务信息等。个人学习账户是学分银行为办理学习成果认定其中主要记录用户的基本信息、学习成果信息、业务办理</p>
                    </li>
                    <li>
                        <img src="../../assets/images/bank1x2.png" alt="">
                        <h3>学习成果登记</h3>
                        <p>学习成果登记是将学习成果关联学分银行账户的过程。无论是机构还是个人均可将各自拥有的学习成果进行登记，分别存入机构账户和个人学习</p>
                    </li>
                    <li>
                        <img src="../../assets/images/bank1x3.png" alt="">
                        <h3>学习成果认定</h3>
                        <p>书所体现的学习成果按照统一的学时学分规则认定其具有学分银行学分的过程。学习成果认定是实现学习成果积累和转换的基础别存入机构账户</p>
                    </li>
                    <li>
                        <img src="../../assets/images/bank1x4.png" alt="">
                        <h3>学习成果存储</h3>
                        <p>学习成果存储是将被认定的机构和个人学习成果进行标注和分类码放，同时，分别存入机构账户和个人学习账户的过程。</p>
                    </li>
                </ul>
            </div>

            <div class="fou1x">
                <h3 class="title1x">校企联盟，优质共享，互认互通</h3>
                <p class="subt1x">推动开放与共享，创建人才培养新生态</p>
                <ul>
                    <li>
                        <img src="../../assets/images/school1.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school2.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school3.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school4.png" alt="">
                    </li>
                </ul>
                <ul>
                    <li>
                        <img src="../../assets/images/school5.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school6.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school7.png" alt="">
                    </li>
                </ul>
                <ul>
                    <li>
                        <img src="../../assets/images/school8.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school9.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school10.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school11.png" alt="">
                    </li>
                </ul>
                <ul>
                    <li>
                        <img src="../../assets/images/school12.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school13.png" alt="">
                    </li>
                    <li>
                        <img src="../../assets/images/school14.png" alt="">
                    </li>
                </ul>
            </div>

            <div class="fiv1x">
                <h3 class="title1x">就业创业，架构桥梁，企业择优</h3>
                <p class="subt1x">牵手企业，定向输送复合型人才</p>
                <ul class="fiv1x-menu">
                    <li v-for="(item, i) in menu"
                    @click="checkMenu2(item, i)"
                    :class="i == index2? 'fiv1x-menu-ac': ''">{{item.name}}</li>
                </ul>
                <ul class="fiv1xlist">
                    <li v-for="(item, i) in data"
                    @click="goJob()">
                        <div class="title">
                            <span style="width:65%; text-align: left; font-size: 16px; font-weight: bold;display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;">{{item.title}}</span>
                            <div>
                                <span style="font-size: 16px; color: #F34026;">{{item.red}}</span>
                                <span style="font-size: 12px; color: #333;"></span>
                            </div>
                        </div>
                        <div class="info">{{item.jobarea}} 丨 {{item.joblimit}}</div>
                        <div style="width: 100%; border-bottom: 1px solid #E5E5E5;"></div>
                        <div class="bot">
                            <span style="font-size: 12px; color: #333;">{{item.company}}</span>
                            <span style="font-size: 12px; color: #999;">五险一金丨周末双休</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="six1x">
                <h3 style="color: #fff;" class="title1x">学产品矩阵，构建教育培训新生态</h3>
                <p style="color: #fff;" class="subt1x">总有一块适合你提升的地方</p>
            </div>

            <div class="sev1x">
                <div class="tab">
                    <h3 class="title1x" style="text-align: left;">移动端学习（即将推出）</h3>
                    <p class="subt1x" style="text-align: left; margin-bottom: 60px; color: #818892;line-height: 28px;">多场景助学，聚焦个性化学习赋能，开启全新移动学习体验</p>
                    <ul class="menu">
                        <li v-for="(item, i) in tabMenu" :key="i" @click="checkTab(item, i)">
                            <img :style="{background: indexTab == i? '#0066CC': '#fff'}" :src="indexTab == i? item.acsrc: item.src" alt="">
                            <span style="font-size: 16px; color: #555;font-weight: bold; line-height: 16px;">{{item.name}}</span>
                        </li>
                    </ul>
                </div>

                <div class="tabImg">
                    <img class="bg" src="../../assets/images/m-iphone.png" alt="">
                    <div class="img-item"
                     :style="{backgroundImage: 'url('+checkedImg+')'}"></div>
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import {
  first,
  second,
  newsList,
  collegeApply,
  collegeList,
  newsCate
} from '../../http/api'

import data from '../../assets/data/data'
export default {
    data() {
        return {
            formLayout: 'horizontal',
            form: this.$form.createForm(this, { name: 'coordinated' }),
            value: 5,
            list: [],
            type: '',
            hostname: '1+x',
            first: '',
            index: 0,
            index2: 0,
            zero_cate_id: 0,
            firstId: '',
            list1x: [],
            data: '',
            menu: '',
            indexTab: 0,
            checkedImg: require("../../assets/images/ph1.png"),
            tabMenu: [
                {
                    name: '课程中心',
                    src: require("../../assets/images/icon1x1.png"),
                    acsrc: require("../../assets/images/icon1x1s.png"),
                    checkedImg: require("../../assets/images/ph1.png")
                },
                {
                    name: '在线学习',
                    src: require("../../assets/images/icon1x2.png"),
                    acsrc: require("../../assets/images/icon1x2s.png"),
                    checkedImg: require("../../assets/images/ph2.png")
                },
                {
                    name: '在线考核',
                    src: require("../../assets/images/icon1x3.png"),
                    acsrc: require("../../assets/images/icon1x3s.png"),
                    checkedImg: require("../../assets/images/ph3.png")
                },
                {
                    name: '培训计划',
                    src: require("../../assets/images/icon1x4.png"),
                    acsrc: require("../../assets/images/icon1x4s.png"),
                    checkedImg: require("../../assets/images/ph4.png")
                },
                {
                    name: '学情报告',
                    src: require("../../assets/images/icon1x5.png"),
                    acsrc: require("../../assets/images/icon1x5s.png"),
                    checkedImg: require("../../assets/images/ph5.png")
                },
            ],
            newsList: [],
            visible: false,
            newsPage: 1,
            newsPageSize: 4,
            visibleAgain: false,
            againMsg: '',
            isAdd: false,
            collegeData: [],
            tabIndex: 0,
            schoolList: [],
            schoolTotal: '',
            menu_li_id: ''
        }
    },
    props: {
      news: Array,
      banners: Array,
      categorys: Array,
      lives: Array,
      listFull: Array,
      top_cates: Array
    },
    created() {
        this.hostname = window.location.hostname
        this.top_cates.splice(1, 0, {
            id:0,
            name:"|",
            path:"/x",
            tenant_id:0
        })
        this.menu = data.menu
        this.data = data.data[0].data
        try {
            if(this.categorys.length != 0) {
                if(this.$store.state.can_class == 1) {
                    this.list = this.categorys[0].course.slice(0,2)
                }
            }
        } catch (error) {

        }
    },
    mounted() {
        collegeList({
            page_size: 10
        }).then(res => {
            this.collegeData = res.data.data
            this.schoolTotal = res.data.ext.total
            this.schoolList = res.data.data[0].colleges
        })
        this.checkNews({id: this.top_cates[0].id})
        first({
            zero_cate_id: this.zero_cate_id
        }).then(res => {
            if(res.data.code == 200) {
                this.first = res.data.data.slice(1)
                this.first.push({
                    name: '查看更多',
                    id: -1
                })
                this.firstId = this.first[0].id
                this.update()
            }
        })

        clearInterval(timer)
        var timer = setInterval(() => {
            if(this.indexTab == 4) {
                this.indexTab = 0
            } else {
                this.indexTab = ++this.indexTab
            }

            this.checkedImg = this.tabMenu[this.indexTab].checkedImg

        }, 2000)
    },
    methods: {
        linkUrl(item) {
            window.open(item.link)
        },
        moreSchool() {
            this.$router.push({
                path: '/school'
            })
        },
        checkSchoolTab(item, i) {
            this.tabIndex = i
            this.schoolList = item.colleges
        },
        close() {
            if(this.isAdd) {
                this.visibleAgain = false
                this.visible = false
            } else {
                this.visibleAgain = false
            }
        },
        addCert() {
            this.visible = true
        },
        checkNews(item, i) {
            this.menu_li_id = item.id
            newsList({
            category_id: item.id,
            page: this.newsPage,
            page_size: this.newsPageSize
            }).then(res => {
                if(res.data.code == 200) {
                    this.newsList = res.data.data.news
                }
            })
        },
        handleSubmit(e) {
            e.preventDefault();
            this.form.validateFields((err, values) => {
                if(!err) {
                    collegeApply(values).then(res => {
                        if(res.data.code == 200) {
                            this.visibleAgain = true
                            this.isAdd = true
                            this.againMsg = res.data.message
                        } else {
                            this.visibleAgain = true
                            this.isAdd = false
                            this.againMsg = res.data.message
                        }
                    })
                }

            });
        },
        handleSelectChange(value) {
            console.log(value);
        },
        goJob() {
            this.$router.push({
                path: '/job'
            })
        },
        checkTab(item, i) {
            this.indexTab = i
            this.checkedImg = item.checkedImg
        },
        checkMenu(item, i) {
            this.index = i
            this.firstId = item.id
            this.update()
        },
        checkMenu2(item, i) {
            this.index2 = i
            this.data = data.data[i].data
        },
        update() {
            this.show = false
            second({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId,
            page_size: 8,
            type: 1
            }).then(res => {
            if(res.data.code == 200) {
                this.list1x = res.data.data.courses
            }
            }).catch(err => {
                this.$message.error(err.response.data.message)
            })
        },
        goThree(item, i) {
            window.open(item.link)
        },
        liveStatus(start, end) {
            let now = new Date().getTime()
            let s = new Date(start).getTime()
            let e = new Date(end).getTime()
            if(now < s) {
                return 0
            } else if(now > s && now < e) {
                return 1
            } else if(now > e) {
                return 2
            }
        },
        goNotice(item, i) {
          this.$router.push({
            path: '/article_detail',
            query: {
              id: item.id,
              type: this.type,
              menuId: this.menu_li_id
            }
          })
        },
        moreNotice() {
            newsCate().then(res => {
                if(res.data.code == 200) {
                    this.$router.push({
                        path: '/cert',
                        query: {
                            id: res.data.data[0].id,
                            title: res.data.data[0].name
                        }
                    })
                }
            })

        },
        go(item, i) {
            if(item.join_type == 'class') {
                if(item.is_access) {
                    this.$router.push({
                    path: '/class/catalog',
                    query: {
                        id: item.id,
                    }
                    })
                } else {
                    this.$message.error('学员已禁用，不能访问')
                }
            } else {
                if(item.type == 'link') {
                    window.open(item.link_address)
                } else {
                    this.$router.push({
                        path: '/course/studydetail',
                        query: {
                        id: item.id,
                        course_type: item.course_type
                        }
                    })
                }
            }
        },
        more(type, id) {
            if(type == 'course') {
                this.$router.push({
                    path: '/course',
                    query: {
                        id: id
                    }
                })
            } else if(type == 'live') {
                this.$router.push({
                    path: '/course-live'
                })
            } else if(type == 'classes') {
                this.$router.push({
                    path: '/course-classes'
                })
            }
        },
        goCourse(item, i) {
            if(item.type == 'link') {
                window.open(item.link_address)
            } else {
                this.$router.push({
                    path: '/course/studydetail',
                    query: {
                        id: item.id,
                        course_type: item.course_type
                    }
                })
            }
        },
    }
}
</script>

<style scoped>
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 420px;
    padding-top: 25px;
    box-sizing: content-box;
    margin-bottom: 25px;
}
.banner {
    width: 750px;
    height: 100%;
    margin-right: 20px;
    border-radius: 2px;
    overflow: hidden;
}
.news {
    flex: 1;
    height: 100%;
}
.title-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
}
.title-bar img {
    width: 6px;
    height: 22px;
    margin-right: 5px;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wrap span {
    font-size: 22px;
    line-height: 22px;
    color: #333;
    font-weight: bold;
}
.title-bar .more {
    font-size: 14px;
    line-height: 22px;
    color: #999;
    cursor: pointer;
}
.carouselImgWrap img {
    height: 420px;
    object-fit: cover;
}
.news {
    height: 420px;
    box-sizing: border-box;
}
.news-wrap {
    height: 380px;
}
.news .date {
    width: 80px;
    height: 80px;
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.news .date-day {
    font-size: 36px;
    color: #1F1E1C;
    font-weight: bold;
    line-height: 40px;
}
.news .dates {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.news li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.news li:last-child {
    margin-bottom: 0;
}
.news .news-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-bottom: 1px solid #E6E6E6;
    height: 80px;
}
.news-item .news-title {
    width: 340px;
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.news-item .news-title:hover {
    color: #0880F1;
}
.news-item .news-info {
    width: 340px;
    font-size: 14px;
    color: #666;
    line-height: 20px;
    height: 20px;
    margin-bottom: 0;
    text-align: left;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.flower {
    height: 130px;
    border: 1px solid #E6E6E6;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 40px;
}
.flower-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.flower-item>span {
    font-size: 14px;
    color: #2A2928;
    font-weight: bold;
    line-height: 14px;
}
.flower-item img{
    width: 54px;
    height: 54px;
    margin-bottom: 10px;
}
.flower .flower-sign {
    width: 17px;
    height: 21px;
}
.classes-list {
    padding-bottom: 40px;
}
.classes-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    margin-left: -20px;
    padding: 20px;
    transition: all .3s;
    border: 1px solid #eee;
}
.classes-item:hover .classes-go {
    background: #0880F1;
}
.classes-item:last-child {
    margin-bottom: 0;
}
.classes-item .img .classes-sign-ing {
    background: url('../../assets/images/classes-sign-ing.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end {
    background: url('../../assets/images/classes-sign-end.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img {
    width: 350px;
    height: 200px;
    margin-right: 20px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}
.classes-item .img img {
    object-fit: cover;
}
.classes-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
    height: 30px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px;
}
.classes-about {
    min-height: 72px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign {
    color: #FF5E57;
}
.classes-price {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info {
    height: 200px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.classes-do-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.classes-go {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .2s;
    overflow: hidden;
    border-radius: 2px;
}

.classes-item:hover .classes-go .classes-go-img {
    background: url('../../assets/images/classes-go-hover.png') no-repeat;
}
.classes-go .classes-go-img {
    background: url('../../assets/images/classes-go.png') no-repeat;
    width: 35px;
    height: 10px;
    transition: all .2s;
}
.module-item {
    margin-bottom: 40px;
}
.lesson-item:hover, .classes-item:hover {
    box-shadow: 0 0 8px #ccc;
}
.lesson-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.lesson-item {
    transition: all .3s;
    width: 270px;
    height: 330px;
    margin-right: 20px;
    background: #FAFAFA;
    text-align: left;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    padding: 15px;
}
.lesson-item:nth-child(3n) {
    margin-right: 0;
}
.lesson-item img {
    height: 160px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 4px;
}
.lesson-title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
    height: 24px;
    font-weight: bold;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-about {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 14px;
    color: #666;
}
.lesson-name {
    min-height: 18px;
    margin-bottom: 12px;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}
.lesson-name span {
    height: 18px;
    line-height: 18px;
}
.lesson-price-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.lesson-msg-wrap {
    background: #FAFAFA;
}
.lesson-price-sign {
    color: #FF5E57;
}
.lesson-price {
    color: #FF5E57;
    font-size: 22px;
    line-height: 22px;
    font-weight: bold;
}
.empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.empty img {
    width: 120px;
    height: 74px;
}
.live-module {
    margin-bottom: 40px;
}
.live-list {
    /* height: 170px; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.live-item {
    width: 590px;
    height: 100%;
    margin-right: 20px;
    padding: 20px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FAFAFA;
    cursor: pointer;
}
.live-item:last-child {
    margin-right: 0;
}
.live-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.live-top img {
    width: 80px;
    height: 80px;
    border-radius: 40px;
    margin-right: 30px;
}
.live-top .title {
    flex: 1;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 30px;
    height: 60px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 0;
}
.live-bot {
    height: 34px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.live-bot .name {
    width: 80px;
    font-size: 16px;
    font-weight: bold;
    margin-right: 30px;
}
.live-bot .live-sign {
    padding: 0 20px;
    height: 32px;
    font-size: 16px;
    border-radius: 16px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #EAECEF;
    color: #666;
}
.live-bot .live-sign img {
    width: 18px;
    height: 18px;
    margin-left: 6px;
}
/*
    1+x css
 */
.notice-1x {
    box-shadow: 0 5px 5px #eee;
    margin-bottom: 60px;
}
.notice-1x-inner {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notice-1x-icon {
    width: 60px;
    height: 60px;
    margin-right: 15px;
}
.news-wrap1x {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
.news-wrap1x .date {
    width: 68px;
    height: 68px;
    background: #F6F8FB;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    border-radius: 4px;
}
.news-wrap1x .date-day {
    font-size: 36px;
    color: #1F1E1C;
    font-weight: bold;
    line-height: 40px;
}
.news-wrap1x .dates {
    font-size: 14px;
    line-height: 20px;
    height: 20px;
}
.news-wrap1x li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-left: 10px;
}
.news-wrap1x li:last-child {
    margin-bottom: 0;
}
.news-wrap1x .news-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 68px;
}
.news-wrap1x .news-item .news-title {
    color: #333;
    font-size: 18px;
    font-weight: bold;
    line-height: 40px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    cursor: pointer;
    text-align: left;
}
.news-wrap1x .news-item .news-title:hover {
    color: #0880F1;
}
.news-wrap1x .news-item .news-info {
    font-size: 14px;
    color: #888;
    line-height: 20px;
    height: 20px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin-bottom: 0;
    text-align: left;
}
.one1x {
 width: 1200px;
 margin: 0 auto 56px;
}
.one1x .lv {
    width: 100%;
    height: 210px;
    background: url('../../assets/images/lv.png');
    margin-bottom: 20px;
    background-size: cover;
}
.one1x .la {
    width: 100%;
    height: 210px;
    background: url('../../assets/images/la.png');
    margin-bottom: 20px;
    background-size: cover;
}
.one1x .hu {
    width: 100%;
    height: 210px;
    background: url('../../assets/images/hu.png');
    background-size: cover;
}
.one1x .lv, .one1x .la, .one1x .hu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
}
.one1x .lv img, .one1x .la img, .one1x .hu img {
    width: 224px;
    height: 166px;
    margin-right: 25px;
}
.one1x .one1xitem {
    cursor: pointer;
    transition: all .3s;
}
.one1x .one1xitem:hover {
    transform: translateX(-15px);
    box-shadow: 10px 0 10px #bbb;
}
.two1x {
    background: #f6f8fb;
    padding: 60px 0 56px;
}
.two1x-menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
}
.two1x-menu li {
    width: 110px;
    height: 36px;
    border-radius: 10px;
    line-height: 36px;
    background: #E5E9F0;
    color: #555;
    margin: 0 8px;
    cursor: pointer;
}
.two1x-menu-ac {
    background: #0066cc!important;
    color: #fff!important;
}
.two1x-list {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.two1x-list li {
    width: 288px;
    margin-right: 16px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 22px;
    cursor: pointer;
    transition: all .3s;
}
.two1x-list li:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 5px #ddd;
}
.two1x-list li:nth-child(4n) {
    margin-right: 0;
}
.two1x-list li img {
    width: 288px;
    height: 154px;
    object-fit: cover;
    margin-bottom: 22px;
}
.two1x-list .two1x-price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}
.thr1x {
    background: url('../../assets/images/bankbg.png');
    background-size: cover;
    height: 586px;
    padding-top: 60px;
}
.thr1x ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.thr1x ul li {
    width: 288px;
    height: 322px;
    background: #fff;
    border-radius: 10px;
    margin: 0 12px;
    padding: 40px 30px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all .3s;
    cursor: pointer;
}.thr1x ul li:hover {
    transform: scale(1.05);
}
.thr1x ul li img {
    width: 76px;
    height: 76px;
    margin-bottom: 10px;
}
.thr1x ul li h3 {
    color: #111D30;
    font-size: 18px;
    margin-bottom: 18px;
    line-height: 18px;
    font-weight: bold;
}
.thr1x ul li p {
    color: #666;
    text-align: left;
    font-size: 12px;
}
.title1x {
    font-size: 30px;line-height: 30px;font-weight: bold;
}
.subt1x {
    font-size: 16px; line-height: 22px; margin-bottom: 40px
}
.fou1x {
    padding: 60px 0;
    background: #f6f8fb;
}
.fou1x ul {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.fou1x ul li {
    margin: 0 10px;
}
.fou1x ul li img {
    width: auto;
    height: auto;
}
.fiv1x {
    background: #f6f8fb;
    padding-bottom: 60px;
}
.fiv1x-menu {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 24px;
}
.fiv1x-menu li {
    width: 110px;
    height: 36px;
    border-radius: 10px;
    line-height: 36px;
    background: #E5E9F0;
    color: #555;
    margin: 0 8px;
    cursor: pointer;
}
.fiv1x-menu-ac {
    background: #0066cc!important;
    color: #fff!important;
}
.fiv1xlist {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.fiv1xlist li{
    width: 380px;
    background: #fff;
    border-radius: 10px;
    height: 126px;
    margin-bottom: 20px;
    margin-right: 20px;
    padding: 10px 18px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
}
.fiv1xlist li:hover {
    transform: translateY(-10px);
    box-shadow: 0 5px 5px #ddd;
}
.fiv1xlist li:nth-child(3n) {
    margin-right: 0;
}
.fiv1xlist li .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20px;
}
.fiv1xlist li .info {
    width: 100%;
    text-align: left;
    font-size: 12px;
    color: #999;
}
.fiv1xlist li .bot {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 14px;
}
.six1x {
    background: url('../../assets/images/six1x.png') no-repeat center center;
    background-size: cover;
    height: 700px;
    padding-top: 60px;
}
.sev1x {
    width: 1200px;
    height: 730px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sev1x .tab {
    width: 380px;
    margin-right: 90px;
}
.sev1x .tabImg {
    width: 450px;
    height: 800px;
    margin-bottom: -150px;
    z-index: 9999;
    padding-left: 30px;
    position: relative;
    overflow: hidden;
}
.sev1x .tabImg .img-item {
    position: absolute;
    left: 96px;
    top: 65px;
    width: 291px;
    height: 675px;
    z-index: -999;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transition:0.5s linear;
}
.sev1x .tab .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.sev1x .tab .menu li {
    width: 80px;
    height: 80px;
    margin-right: 70px;
    margin-bottom: 70px;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    cursor: pointer;
}
.sev1x .tab .menu li img {
    margin-bottom: 10px;
    border-radius: 10px;
    transition: all .2s;
}
.sev1x .tab .menu li:nth-child(3n) {
    margin-right: 0;
}
.news-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.news-menus li {
    font-size: 20px;
    line-height: 22px;
    margin-right: 10px;
    margin-bottom: 0;
    cursor: pointer;
    font-weight: bold;
}
.content-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.content-left {
    width: 850px;
}
.content-right {
    width: 330px;
}
.right-module {
    border: 1px solid #eee;
    padding: 15px;
    margin-bottom: 30px;
}
#school-cert-in .ant-form-item {
    display: flex;
    justify-content: center;
    width: 650px;
}
#school-cert-in .ant-input {
    height: 40px;
}
.thr-sub-title {
    line-height: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-align: left;
    padding: 0 20px;
    font-size: 14px;
    margin-bottom: 0;
    color: #999;
}
.close-btn {
    text-align: center;
}
.coll-tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.coll-tab li {
    flex: 1;
    cursor: pointer;
    height: 40px;
    line-height: 40px;
    border-bottom: 2px solid #fff;
    transition: all .2s;
}
.coll-tab-ac {
    border-color: rgb(8, 128, 241)!important;
    background: rgb(8, 128, 241)!important;
    color: #fff;
    border-radius: 4px;
}
.school-list-wrap li {
    display: block;
    text-align: left;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    padding: 18px 20px;
    font-weight: bold;
    color: #555;
    cursor: pointer;
    line-height: 16px;
    height: 32px;
}
.school-list-wrap li::before{
    content: "★";
    color: rgb(8, 128, 241);
}
.school-list-wrap li:hover {
    color: rgb(8, 128, 241)!important;
}
.comments .info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
}
.comments .info img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
}
.comments .timer {
    color: #888;
    font-size: 14px;
    text-align: left;
    margin-bottom: 10px;
}
.comments .txt {
    text-align: left;
}
.menus-li-1x {
    border-bottom: 3px solid #fff;
}
</style>
