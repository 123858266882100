<template>
    <div class="exam">
        <div class="title-class-all">
            <span class="icon"></span>
            <h3>在线考试</h3>
        </div>
        <div class="exam">
            <div v-show="exams.length == 0" style="padding:20px;min-height:400px"><div class="empty">
                    <img src="../../assets/images/weipingjia.png" alt="">
                    <span>暂未设置考试~</span>
                </div>
            </div>
            <div class="exams" v-show="exams.length > 0">
                <ul>
                    <li v-for="(item, i) in exams" :key="i">
                        <div class="left">
                            <h3>{{item.name}}</h3>
                            <p>{{item.description}}</p>
                            <p style="margin-bottom: 0;">
                                <span>题目数量 {{item.item_count}}</span>
                                <span>|</span>
                                <span>考试时间 {{!item.limit_time_format? '不限制': item.limit_time_format+'分钟'}}</span>
                                <span>|</span>
                                <span>总分 {{item.score}}</span>
                                <span>|</span>
                                <span>及格分 {{item.passed_score}}</span>
                                <span>|</span>
                                <span v-if="item.retake == 1">允许重考 {{item.do_times}} 次</span>
                                <span v-if="item.retake == 0">不允许重考</span>
                                <span v-if="item.retake == 2">不限制重考次数</span>
                                <!-- <span class="btn-txt-info" v-if="item.retake == 1 && item.passed_status != 'passed'">（剩余考试次数 {{item.remain_do_times}}）</span> -->
                            </p>
                        </div>
                        <a-button 
                            
                            @click="goExam(item, i)" 
                            :style="{background: item.exam_type == 0 || item.exam_type == 4? '#ccc': $store.state.themeColor, color: '#fff', width: '100px'}"
                            size="large">{{btnTxt(item).txt}}</a-button>
                        <img style="position: absolute;top: 45px;right: 150px;" v-if="item.passed_status != ''" class="exam-icon" :src="item.passed_status == 'passed'? require('../../assets/images/examYes.png'): require('../../assets/images/examNo.png')" alt="">
                        <span v-if="item.is_access_exam == 1" class="pass_icon" :style="{background: $store.state.themeColor}">随到随考</span>
                    </li>
                </ul>
            </div>
        </div>
        <a-modal :bodyStyle="{padding: 0}" v-model="examVisible" :title="examTitle" :footer="null" width='520px'>
          <!-- 查看成绩 -->
          <div v-if="exam_type == 2 || exam_type == 3" class="exam-item-wrap">
            <div class="exam-item" v-for="(item, i) in examData" :key="i">
                <div>
                  <div style="margin-bottom: 10px;color: #666;font-size: 16px; font-weight: bold;">
                    <span>{{i== 0? '历史最高分：': '上次考试成绩：'}}</span>
                    <span v-if="item.passed_status != ''">{{item.score}}分</span>
                  </div>
                  <div v-if="item.passed_status != ''" style="color: #888;font-size: 12px;">考试时间：{{item.end_time}}</div>
                </div>
                <img v-if="item.passed_status != ''" class="exam-icon" :src="item.passed_status == 'passed'? require('../../assets/images/examYes.png'): require('../../assets/images/examNo.png')" alt="">
                <!-- v-if="i != 0" -->
                <a-button  @click="seeExam(item)" :style="{width: '90px',background: $store.state.themeColor, color: '#fff', border: 'none'}">查看</a-button>
                <!-- <div v-if="i == 0" style="width: 90px"></div> -->
            </div>
            <div class="exam-btn-wrap" v-if="ext.is_retake == 1">
              <a-button @click="start" :disabled="class_isFinish==1" :style="{width: '160px', height: '44px',background: class_isFinish?'#ccc':$store.state.themeColor, color: '#fff', border: 'none'}">开始考试</a-button>
            </div>
          </div>

          <!-- 开始考试 -->
          <div class="exam-start-wrap" v-if="exam_type == 1">
            <div class="exam-top">
              <img class="warn-icon" src="../../assets/images/warn.png" alt="">
              <span>温馨提示：作答过程中，请严格遵守考试制度，一切作弊行为会被记录在考试记录中，请大家认真对待</span>
            </div>
            <div v-if="limit_time_format" class="start-txt">本次考试作答时间为 <span style="color: #c00;">{{limit_time_format}}</span> 分钟。</div>
            <p v-if="!limit_time_format" class="start-txt">本次考试不限时长。</p>
            <p class="start-txt">请各位学员尽量保持网络环境稳定，网络通畅，以便更好的作答。</p>
            <div class="start-do-wrap">
              <a-button @click="startCancel" size="large" style="font-size: 14px;width: 90px;">取消</a-button>
              <a-button @click="start()" size="large" 
              :disabled="class_isFinish==1"
              :style="{
              background:class_isFinish?'#ccc':$store.state.themeColor,
              color: '#fff',
              marginLeft: '30px',
              fontSize: '14px',
              width: '90px',
              
              border: 'none'}">开始考试</a-button>
            </div>
          </div>
        </a-modal>
    </div>
</template>

<script>
import {
    classExam,
    testResultList
} from '../../http/api'
export default {
    data() {
        return {
            class_id: '',
            exams: '',
            show: false,
            examVisible: false,
            examTitle: null,
            exam_type: null,
            examData: [],
            limit_time_format: '',
            test_id: '',
            ext: '',
            examItem:{},
            class_isFinish:0
        }
    },
    props: {
        classes: {}
    },
    created() {
        this.class_id =Array.isArray(this.$route.query.id)?this.$route.query.id[0]:this.$route.query.id
        classExam({
            id: this.class_id
        }).then(res => {
            this.show = true
            if(res.data.code == 200) {
                this.exams = res.data.data
            }
        })
    },
    methods: {
        seeExam(item) {
          this.$router.push({
            path: '/examination',
            query: {
              id: this.class_id,
              course_type: 'classroom',
              test_id: item.test_id,
              result_id: item.id? item.id: '',
              type:'ck'
            }
          })
        },
        start() {
          if(!this.examItem.is_testpaper_certificate){
              let sessionObj = JSON.parse(sessionStorage.session)
              
              let session = {
                certification: sessionObj.certification,
                token: sessionObj.token,
                type: 3, //考试人脸
                test_id:this.examItem.id,
                callback:'/examination?id='+this.class_id+'&course_type=classroom'
              }
              let sessionStr = JSON.stringify(session)
              sessionStorage.setItem('session', sessionStr)
              this.$store.commit("SETTOKEN", sessionObj.token)
              this.$store.commit('FACEVISIBLE', true);
              this.startCancel();
          }else{
            this.$router.push({
              path: '/examination',
              query: {
                id: this.class_id,
                course_type: 'classroom',
                test_id: this.test_id
              }
            })
          }
          
        },
        startCancel() {
            this.examVisible = false
        },
        goExam(item, i) {
          this.examItem = item;
          console.log('item',item)
            sessionStorage.result_id = ''
            this.exam_type = item.exam_type
            if(item.exam_type == 0) {
                this.examTitle = '未学完课程'
            }else if(item.exam_type == 1) { //else if(item.exam_type == 1) {
                this.examTitle = '开始考试'
                this.test_id = item.test_id
            } else if(item.remain_do_times>0){//(item.exam_type == 2) {
                this.examTitle = '开始补考'
            } else if(item.remain_do_times==0){//(item.exam_type == 3) {
                this.examTitle = '查看成绩'
            }
            if(item.exam_type != 0&&item.remain_do_times==0&&item.is_examed==0){
                this.examTitle = '开始考试'
                this.test_id = item.test_id
            }
            if(this.$store.state.token) {
                if(item.exam_type == 3) {
                testResultList({
                    test_id: item.id
                }).then(res => {
                    if(res.data.code == 200) {
                      this.examVisible = true
                      this.examData = res.data.data
                      this.ext = res.data.ext
                      this.test_id = res.data.ext.test_id
                      this.class_isFinish = item.class_isFinish
                    }
                })
                } else if(item.exam_type == 1) {
                this.limit_time_format = item.limit_time_format
                this.test_id = item.id
                this.examVisible = true
                this.class_isFinish = item.class_isFinish
                }
            } else {
                this.$store.commit("SHOWLOGIN", true)
            }
        },
        btnTxt(item) {
          console.log('item',item)
           if(item.exam_type != 0&&item.remain_do_times==0&&item.is_examed==0){
                return {
                  txt: '开始考试'
              }
            }
            if(item.exam_type == 0) {
            return {
                txt: '未学完课程'
            }
            } else if(item.remain_do_times>0&&item.is_examed==0) {
            return {
                txt: '开始考试'
            }
            } else if(item.remain_do_times == 0||item.is_examed==1) {
            return {
                txt: '查看成绩'
            }
            } else if(item.exam_type == 4) {
              return {
                txt: '已过期'
              }
            }
        },
    }
}
</script>

<style scoped>
.exam-icon {
  width: 72px;
  height: 63px;
}
.exam-item-wrap {
  padding: 0 30px;
}
.exam-btn-wrap {
  padding: 20px 0;
  text-align: center;
}
.exam-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  min-height: 104px;
}
.exams ul li{
    border: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin-bottom: 20px;
    position: relative;
    height: 160px;
}
.exams ul li h3 {
    font-weight: bold;
    text-align: left;
    margin-bottom: 5px;
}
.exams ul li .left {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exams ul li .left p {
  margin-bottom: 0;
  font-size: 12px;
  color: #888;
  text-align: left;
  margin-bottom: 15px;
}
.exams ul li .left p span {
  padding-right: 10px;
}
.exams ul li button {
  border: none;
  font-size: 14px;
}
.exams ul li .pass_icon {
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  height: 24px;
  width: 80px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
}
.side-bar-wrap {
  flex: 1;
}
.empty {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.empty img {
  width: 138px;
  height: 116px;
  margin-bottom: 20px;
}
.empty span {
  font-size: 12px;
  color: #666;
}
.btn-txt-info {
  font-size: 12px;
  color: #c00;
  line-height: 28px;
  padding: 0!important;
  display: inline-block;
}
.exam-start-wrap {
  padding: 20px;
}
.exam-top {
  background: #eee;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
}
.warn-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.warn-icon span {
  color: #333;
  font-size: 14px;
}
.start-txt {
  font-size: 15px;
  color: #333;
  line-height: 1;
  margin-bottom: 15px;
}
.start-do-wrap {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>