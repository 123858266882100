var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "ImageContent", staticClass: "examination" },
    [
      _c("div", { staticClass: "subject-top inner" }, [
        _c("div", { staticClass: "top-title" }, [
          _c("h3", [_vm._v(_vm._s(_vm.info.name))]),
          _c("p", [_vm._v(_vm._s(_vm.info.description))]),
          _c("div", { staticClass: "info_text" }, [
            _c("span", [_vm._v("考生姓名: " + _vm._s(_vm.realname))]),
            _c("span", [_vm._v("身份证号: " + _vm._s(_vm.idcard))]),
            _c("span", [_vm._v("考试时间: " + _vm._s(_vm.endTime))]),
          ]),
          _c("span", { staticClass: "texts" }, [
            _vm._v("考试企业: " + _vm._s(_vm.$store.state.tenant_name)),
          ]),
        ]),
        _vm.is_finish_exam == 0
          ? _c("div", { staticClass: "top-time" }, [
              _c("h3", [_vm._v("剩余考试时间")]),
              _vm.info.is_limited == 1
                ? _c("span", [
                    _vm._v(_vm._s(_vm.minute) + " : " + _vm._s(_vm.second)),
                  ])
                : _vm._e(),
              _vm.info.is_limited == 0
                ? _c("span", [_vm._v("不限制")])
                : _vm._e(),
            ])
          : _vm._e(),
        _c(
          "div",
          {
            staticStyle: { position: "relative", left: "52px", bottom: "13px" },
          },
          [
            _vm.is_finish_exam != 0
              ? _c(
                  "a-button",
                  {
                    style: {
                      background: _vm.$store.state.themeColor,
                      color: "#fff",
                      border: "none",
                      width: "80px",
                      marginTop: "30px",
                      fontSize: "14px",
                    },
                    attrs: { size: "large" },
                    on: {
                      click: function ($event) {
                        return _vm.exportPdf("#pdf")
                      },
                    },
                  },
                  [_vm._v("导出试卷")]
                )
              : _vm._e(),
          ],
          1
        ),
        _vm.is_finish_exam == 1
          ? _c("div", { staticClass: "top-score" }, [
              _vm.info.passed_status != ""
                ? _c("img", {
                    staticClass: "exam-icon",
                    staticStyle: {
                      position: "absolute",
                      top: "5px",
                      left: "-90px",
                      width: "72px",
                      height: "63px",
                    },
                    attrs: {
                      crossOrigin: "anonymous",
                      src:
                        _vm.info.passed_status == "passed"
                          ? require("../../assets/images/examYes.png") +
                            "?any_string_is_ok"
                          : require("../../assets/images/examNo.png?any_string_is_ok") +
                            "?any_string_is_ok",
                      alt: "",
                    },
                  })
                : _vm._e(),
              _c("h3", [_vm._v("本次考试成绩")]),
              _c("span", [_vm._v(_vm._s(_vm.test_result.score))]),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "subject-content inner" }, [
        _c(
          "div",
          {
            ref: "subjects",
            staticClass: "subject",
            class: { subject_active: _vm.subjectActive },
          },
          [
            _c(
              "div",
              {
                staticClass: "subject-child",
                class: { "subject-child_active": _vm.subjectActive },
              },
              _vm._l(_vm.exams, function (val, key, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "question" },
                  [
                    val.length > 0
                      ? _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.titleMapping(key) +
                                "（共" +
                                val.length +
                                " 道）"
                            )
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(val, function (item, is) {
                      return _c(
                        "div",
                        {
                          key: is,
                          staticClass: "qes-item",
                          attrs: { id: _vm.anchorMapping(key) + (is + 1) },
                        },
                        [
                          _c("p", { staticClass: "qes-title" }, [
                            _vm._v(
                              _vm._s(
                                is +
                                  1 +
                                  "，" +
                                  _vm.escape2Html(item.question.stem)
                              )
                            ),
                          ]),
                          _c("div", { staticClass: "answers-wrap" }, [
                            _c(
                              "div",
                              { staticClass: "answers" },
                              _vm._l(
                                item.question.choices,
                                function (items, iss) {
                                  return _c(
                                    "p",
                                    {
                                      key: iss,
                                      class: {
                                        selector:
                                          key == "single_choice" ||
                                          key == "determine"
                                            ? item.check_question_id ==
                                              item.id +
                                                "_" +
                                                item.question_id +
                                                "_" +
                                                iss
                                            : key == "multy_choice"
                                            ? items.is_choiced
                                            : "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.chooseAnswer(
                                            item,
                                            items,
                                            key,
                                            iss
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(items.item))]
                                  )
                                }
                              ),
                              0
                            ),
                            _c("p", { staticClass: "score-item" }, [
                              _vm._v("本题：" + _vm._s(item.score) + "分"),
                            ]),
                          ]),
                          _vm.is_finish_exam == 1 && _vm.info.show_answer == 1
                            ? _c("div", { staticClass: "answer-content" }, [
                                _c(
                                  "div",
                                  { staticStyle: { "margin-right": "30px" } },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#555",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [_vm._v("正确答案：")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          color: "#52c41a",
                                          "font-weight": "bold",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.iChoiced(item.question.answer)
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#555",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [_vm._v("你的答案：")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      style: {
                                        color:
                                          item.item_passed_status == "right"
                                            ? "#52c41a"
                                            : "#c00",
                                        fontWeight: "bold",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.resChecked(item.question.choices)
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ]
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "subject-number inner" },
          [
            _c("p", { staticClass: "number-title" }, [_vm._v("作答情况")]),
            _c("div", { staticClass: "unit-item" }, [
              _vm.exams.single_choice.length > 0
                ? _c("h3", [_vm._v("单选题")])
                : _vm._e(),
              _vm.is_finish_exam == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.single_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: { selector: item.check_question_id },
                          attrs: { href: "#s" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.single_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: { selector: item.check_question_id },
                          attrs: { href: "#s" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 1
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.single_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class:
                            item.item_passed_status == "right"
                              ? "selector-suc"
                              : "selector-err",
                          attrs: { href: "#s" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "unit-item" }, [
              _vm.exams.multy_choice.length > 0
                ? _c("h3", [_vm._v("多选题")])
                : _vm._e(),
              _vm.is_finish_exam == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.multy_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: {
                            selector: _vm.multy_is_choice(
                              item.question.choices
                            ),
                          },
                          attrs: { href: "#m" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.multy_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: {
                            selector: _vm.multy_is_choice(
                              item.question.choices
                            ),
                          },
                          attrs: { href: "#m" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 1
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.multy_choice, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class:
                            item.item_passed_status == "right"
                              ? "selector-suc"
                              : "selector-err",
                          attrs: { href: "#m" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _c("div", { staticClass: "unit-item" }, [
              _vm.exams.determine.length > 0
                ? _c("h3", [_vm._v("判断题")])
                : _vm._e(),
              _vm.is_finish_exam == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.determine, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: { selector: item.check_question_id },
                          attrs: { href: "#j" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 0
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.determine, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class: { selector: item.check_question_id },
                          attrs: { href: "#j" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.is_finish_exam == 1 && _vm.info.show_answer == 1
                ? _c(
                    "div",
                    { staticClass: "unit" },
                    _vm._l(_vm.exams.determine, function (item, i) {
                      return _c(
                        "a",
                        {
                          key: i,
                          class:
                            item.item_passed_status == "right"
                              ? "selector-suc"
                              : "selector-err",
                          attrs: { href: "#j" + (i + 1) },
                        },
                        [_vm._v(_vm._s(i + 1))]
                      )
                    }),
                    0
                  )
                : _vm._e(),
            ]),
            _vm.is_finish_exam == 0
              ? _c(
                  "a-button",
                  {
                    style: {
                      background: _vm.$store.state.themeColor,
                      color: "#fff",
                      border: "none",
                      width: "120px",
                      marginTop: "30px",
                    },
                    attrs: { size: "large" },
                    on: { click: _vm.submitExam },
                  },
                  [_vm._v("提交试卷")]
                )
              : _vm._e(),
            _vm.is_finish_exam == 1
              ? _c(
                  "a-button",
                  {
                    style: {
                      background: _vm.$store.state.themeColor,
                      color: "#fff",
                      border: "none",
                      width: "120px",
                      marginTop: "30px",
                    },
                    attrs: { size: "large" },
                    on: { click: _vm.examBack },
                  },
                  [_vm._v("返回")]
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: {
            title: null,
            closable: _vm.closable,
            footer: null,
            maskClosable: false,
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "module-content" },
            [
              !_vm.timeOver
                ? _c("div", { staticClass: "msg-wrap" }, [
                    _vm.answerNum == 0
                      ? _c("h3", [_vm._v("确定要提交试卷吗？")])
                      : _vm._e(),
                    _vm.answerNum > 0
                      ? _c("h3", [
                          _vm._v(
                            "还有 " +
                              _vm._s(_vm.answerNum) +
                              " 道题目没有作答，确定提交试卷吗？"
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.timeOver
                ? _c("div", { staticClass: "msg-wrap" }, [
                    _c("h3", [
                      _vm._v("考试时间已到，点击【确定】提交试卷并查看成绩"),
                    ]),
                  ])
                : _vm._e(),
              !_vm.timeOver
                ? _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          style: {
                            width: "120px",
                          },
                          attrs: { size: "large" },
                          on: { click: _vm.cancelSubmit },
                        },
                        [_vm._v("继续做题")]
                      ),
                      _c(
                        "a-button",
                        {
                          style: {
                            background: _vm.$store.state.themeColor,
                            color: "#fff",
                            border: "none",
                            width: "120px",
                            marginLeft: "30px",
                          },
                          attrs: { size: "large" },
                          on: { click: _vm.okSubmit },
                        },
                        [_vm._v("提交试卷")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.timeOver
                ? _c(
                    "a-button",
                    {
                      style: {
                        background: _vm.$store.state.themeColor,
                        color: "#fff",
                        border: "none",
                        width: "120px",
                      },
                      attrs: { size: "large" },
                      on: { click: _vm.okSubmit },
                    },
                    [_vm._v("确定")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modelText,
            closable: _vm.closable,
            footer: null,
            maskClosable: false,
          },
          model: {
            value: _vm.notAllow,
            callback: function ($$v) {
              _vm.notAllow = $$v
            },
            expression: "notAllow",
          },
        },
        [
          _c(
            "div",
            { staticClass: "module-content" },
            [
              !_vm.timeOver || _vm.classIsFinish
                ? _c("div", { staticClass: "msg-wrap" }, [
                    _c(
                      "p",
                      {
                        staticStyle: {
                          color: "red",
                          "margin-top": "-20px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v(_vm._s(_vm.notAllowText))]
                    ),
                    _c("p", [_vm._v("请务必保证学习真实性")]),
                  ])
                : _vm._e(),
              !_vm.timeOver && !_vm.classIsFinish
                ? _c(
                    "div",
                    [
                      _c(
                        "a-button",
                        {
                          style: {
                            background: _vm.$store.state.themeColor,
                            color: "#fff",
                            border: "none",
                            width: "120px",
                          },
                          attrs: { size: "large" },
                          on: { click: _vm.cancelSubmit },
                        },
                        [_vm._v("继续做题")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.classIsFinish
                ? _c(
                    "a-button",
                    {
                      style: {
                        background: _vm.$store.state.themeColor,
                        color: "#fff",
                        border: "none",
                        width: "120px",
                      },
                      attrs: { size: "large" },
                      on: { click: _vm.goBack },
                    },
                    [_vm._v("取消 ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }