<template>
  <div class="listModule">
    <div class="title-bg" :class="{'newbg':$store.state.tenant_idBind==3462}">
      <div class="title-inner">
        <img src="../../assets/images/notice-icon.png" alt="">
        <h3>{{titleMsg()}}</h3>
      </div>
    </div>

    <div class="news-tab-wrap">
      <ul class="news-tab">
        <li :style="index == item.id || $route.query.title == item.name? {color: $store.state.themeColor, borderColor: $store.state.themeColor}: {}" 
        v-for="(item, i) in menus" 
        :key="i"
        @click="checkTab(item, i)">{{item.name}}</li>
      </ul>
    </div>
    
    <transition name="fades">
      <div v-show="show">
            
            <div class="content" v-if="list.length != 0">
              <ul class="list">
                  <li @click="go(item, i)" v-for="(item, i) in list" :key="i">
                      <img :src="item.cover" alt="">
                      <div class="text-info">
                          <p class="title">{{item.title}}</p>
                          <span class="date">{{item.created_at.split(" ")[0]}}</span>
                          <p class="describe">{{item.infos}}</p>
                      </div>
                      
                  </li>
              </ul>
              <a-pagination v-if="total > 10" @change="changePage" v-model="page" :defaultPageSize="pageSize" :total="total" />
            </div>

            <div class="no" v-else>
              <img src="../../assets/images/no.png" alt="">
              <p>暂无数据</p>
            </div>
        </div>
    </transition>

    <div class="skeleton" v-show="!show">
      <a-skeleton active  v-for="(item, i) in 4" :key="i"/>
    </div>
    
    
  </div>
</template>

<script>
import {
  newsList,
  newsCate
} from '../../http/api'
export default {
    data() {
        return {
          page: 1,
          pageSize: 10,
          list: [],
          total: 0,
          show: false,
          index: 1,
          menus: [
          ]
        }
    },
    props: {
        type: String,
        cate: Number,
        title: String
    },
    created() {
        newsCate().then(res => {
            this.menus = res.data.data
        })
        this.update(this.page)
    },
    watch: {
      '$route.fullPath'(n, o) {
        this.index = n.split('&')[0].split('id=')[1]
      }
    },
    mounted() {
        this.index = this.$route.query.id
    },
    methods: {
      titleMsg() {
        if(this.type == 'notice') {
          return '公告通知'
        } else if(this.type == 'help') {
          return '使用帮助'
        } else if(this.type == 'qes') {
          return '常见问题'
        } else if(this.type == 'cert') {
          return '证书信息'
        } else if(this.type == 'other') {
          return '其他信息'
        } else {
          return this.title
        }
      },
      update(page) {
        newsList({
          category_id: this.cate,
          page: page,
          page_size: this.pageSize
        }).then(res => {
          this.list = res.data.data.news
          this.total = res.data.data.total
          this.show = true
        }).catch(err => {
            
        })
      },
      changePage(page, pageSize) {
        this.update(page)
      },
      go(item, i) {
        this.$router.push({
          path: '/article_detail',
          query: {
            id: item.id,
            type: this.type?this.type: this.$route.query.title,
            menuId: this.$route.query.id
          }
        })
      },
      checkTab(item, i) {
        this.index = item.id
          this.$router.push({
            path: item.path,
            query: {
              id: item.id,
              title: item.name
            }
          })
      }
    }
}
</script>

<style scoped>
.listModule {
  width: 100%;
  height: 100%;
  padding: 0 0 20px;
}
.listModule .list {
  width: 1200px;
  margin: 0 auto 20px;
}
.listModule .title {
  width: 840px;
  font-weight: bold;
  font-size: 22px;
  color: #333;
  text-align: left;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 30px;
}
.listModule .describe {
  text-align: left;
  color: #666;
  font-size: 14px;
  line-height: 30px;
  min-height: 120px;
  display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 4;
overflow: hidden;
margin-bottom: 0;
}
.listModule .date {
  display: block;
  text-align: left;
  color: #666;
}
.listModule .list li {
  cursor: pointer;
  padding: 20px 0;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  box-sizing: border-box;
  overflow: hidden;
}
.listModule .list li img {
  width: 340px;
  height: 100%;
  margin-right: 20px;
  object-fit: cover;
}
.listModule .text-info {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: flex-start; */
}
.skeleton {
  width: 1200px;
  margin: 0 auto;
}
.title-bg {
    height: 130px;
    background: url('../../assets/images/title-bg.png') no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
}
.title-inner {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.title-inner img {
  width: 54px;
  height: 54px;
  margin-right: 15px;
}
.title-inner h3 {
  font-size: 30px;
  margin-bottom: 0;
  background: linear-gradient(to right, #DCFBFE, #6EA2DF);
  -webkit-background-clip: text;
  color: transparent;
}

.news-tab-wrap {
  height: 60px;
  box-shadow: 0 3px 5px #ccc;
}
.news-tab {
  width: 1200px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.news-tab li {
  font-size: 16px;
  font-weight: bold;
  margin-right: 40px;
  cursor: pointer;
  text-align: left;
  border-bottom: 2px solid #fff;
}
.no {
  padding-top: 120px;
}
.no img {
  width: 100px;
  height: 100px;
}
.no p {
  color: #bbb;
}
</style>