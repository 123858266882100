<template>
    <div class="classes">
        <div class="classes-bg" :class="{'newbg':$store.state.tenant_idBind==3462}">
            <div class="inner">
                <div class="top">
                    <div class="title-wrap">
                        <img src="../../assets/images/class-title-icon.png" alt="">
                        <h3>{{hostname == 'bjadks.zjttv.cn' || hostname == 'bjadks.lllnet.cn'? '培训企业': hostname == 'lyedu.zjttv.cn'? '公益大讲堂': '班级计划'}}</h3>
                    </div>
                    <!-- <img style="width: 350px;" src="../../assets/images/bg_txt.png" alt=""> -->
                    <div class="search">
                        <a-input v-model="keywords" @keyup.enter="search" class="search-msg" placeholder="搜索感兴趣的内容"/>
                        <a-button @click="search" class="search-btn"></a-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bot">
            <div class="inner">
                <ul class="first-menus" :style="!moreMenuFlag?{maxHeight: '60px',overflow: 'hidden'}: {maxHeight: '1000px'}">
                    <li @click="firstScreening(item, i)" v-for="(item, i) in first" :key="i" :style="firstId == item.id?{borderColor: $store.state.themeColor,fontWeight: 'bold', color: $store.state.themeColor}:{}">{{item.name}}</li>
                </ul>
            </div>

            <div v-if="showMore" @click="showMoreMenu" class="menu-more">
                <!-- <span>{{moreMsg}}</span> -->
                <img v-if="moreMenuFlag" src="../../assets/images/more_up.png" alt="">
                <img v-if="!moreMenuFlag" src="../../assets/images/more_down.png" alt="">
            </div>
        </div>
        <div class="classes-content">
            <ul class="second-menus" v-if="firstId != 0 && second.length > 0">
                <li @click="secondScreening(item, i)" v-for="(item, i) in second" :key="i" :class="secondId == item.id? 'ac-bg': ''">{{item.name}}</li>
            </ul>
            <ul class="third-menus" v-if="secondId != 0">
                <li @click="thirdScreening(item, i)" v-for="(item, i) in third" :key="i" :class="third_cate_id == item.id? 'ac': ''">{{item.name}}</li>
            </ul>
            <div class="loadding" v-show="!show">
            <a-spin />
            </div>
            <transition name="fades">
            <div v-if="show">
                <ul class="classes-list">
                    <li class="classes-item" v-for="(item, i) in list" :key="i" @click="go(item, i)">
                        <div class="img">
                            <img class="img_a" :src="item.picture" alt="">
                            <div v-if="item.learn_status == 1" class="classes-sign-ing"><span style="padding-right: 20px">进行中</span></div> 
                            <div v-if="item.learn_status == 3" class="classes-sign-end">已结束</div>
                        </div>
                        <div class="classes-info">
                            <p class="classes-title">{{item.title}}</p>
                            <span class="describe" v-if="item.begin_time">开课时间：{{item.begin_time+' 至 '+item.end_time}}</span>
                            <p class="classes-about">{{item.about}}</p>
                            <p style="color: #333;margin-bottom: 0">{{item.course_num}}门课  |  {{item.period}}课时  |  {{item.learn_total}}人已学  | {{!item.personal_access?'非公开':'公开'}} <span v-if="item.member_limit">|</span> {{item.member_limit==0?'':'学员人数'+item.join_total+'/'+item.member_limit}}</p>
                            
                            <div class="classes-do-wrap">
                                <div v-if="$store.state.can_pay && $store.state.tenant_idBind != 22">
                                    <span v-if="item.price && item.price > 0" class="classes-sign">￥</span>
                                    <span class="classes-price">{{item.price > 0? item.price: '免费'}}</span>
                                </div>
                                <div class="classes-go">
                                    <div class="classes-go-img"></div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            <div class="no" v-if="list.length == 0">
                <img src="../../assets/images/no.png" alt="">
                <p>暂无数据</p>
            </div>
            </div>
            </transition>
            <a-pagination style="z-index:999" v-if="list.length > 0" @change="changePage" v-model="page" :pageSize="page_size" :total="totalPage" />
        </div>

    <a-modal v-model="visible" title="温馨提示">
        <span><a-icon type="exclamation-circle" style="color:orange;"/> 学员人数已达上限</span>
        <template slot="footer">
            <a-button key="back" @click="visible=false">
            确认
            </a-button>
        
        </template>
    </a-modal>
    </div>
</template>
<script>
import {
  first,
  second
} from '../../http/api'
import merge from 'webpack-merge'
export default {
    data() {
        return {
            visible:false,
            value: 5,
            page: 1,
            page_size: 9,
            totalPage: 0,
            keywords: '',
            first: [],
            second: [],
            third: [],
            zero_cate_id: 0,
            zero_cate_id: 1,
            firstId: 0,
            secondId: 0,
            list: [],
            show: false,
            showMore: false,
            moreMsg: '更多',
            moreMenuFlag: false,
            hostname: ''
        }
    },
    created() {
        
    },
    mounted() {
        this.hostname = window.location.hostname
        this.firstId = this.$route.query.id? this.$route.query.id: 0
        this.secondId = this.$route.query.second_cate_id? this.$route.query.second_cate_id: 0
        this.third_cate_id = this.$route.query.third_cate_id? this.$route.query.third_cate_id: 0

        first({
            zero_cate_id: this.zero_cate_id
        }).then(res => {
        if(res.data.code == 200) {
          this.first = res.data.data
          this.$nextTick(() => {
            let h = document.querySelector('.first-menus').scrollHeight
            if(h > 60) {
              this.showMore = true
            }
          })
          if(this.firstId) {
              first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId
            }).then(res => {
                if(res.data.code == 200) {
                    this.second = res.data.data
                }
            })
          }
          if(this.secondId) {
              first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId,
                second_cate_id: this.secondId
            }).then(res => {
                if(res.data.code == 200) {
                    this.third = res.data.data
                }
            })
          }
        }
      })
      this.update(this.page)
    },
    methods: {
        handleOk(){

        },
        showMoreMenu() {
            if(!this.moreMenuFlag) {
                this.moreMsg = '收起'
                this.moreMenuFlag = true
            } else {
                this.moreMsg = '更多'
                this.moreMenuFlag = false
            }
        },
        firstScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{id: item.id})
            })
            this.firstId = item.id
            this.page = 1
            this.secondId = 0
            this.keywords = ''
            if(item.id != 0) {
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                this.second = res.data.data
                }
            })
            } else {
            this.second = []
            }
            this.secondScreening({id: this.secondId})
        },
        secondScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{second_cate_id: item.id})
            })
            first({
                zero_cate_id: this.zero_cate_id,
                first_cate_id: this.firstId,
                second_cate_id: item.id
            }).then(res => {
                if(res.data.code == 200) {
                    this.third = res.data.data
                }
            })
            this.secondId = item.id
            this.third_cate_id = 0
            this.page = 1
            this.keywords = ''
            this.update(this.page)
        },
        thirdScreening(item, i) {
            this.$router.push({
                query:merge(this.$route.query,{third_cate_id: item.id})
            })
            this.third_cate_id = item.id
            this.page = 1
            this.keywords = ''
            this.update(this.page)
        },
        update(page) {
            this.show = false
            second({
            zero_cate_id: this.zero_cate_id,
            first_cate_id: this.firstId,
            second_cate_id: this.secondId,
            third_cate_id: this.third_cate_id,
            keywords: this.keywords,
            page: page,
            page_size: this.page_size
            }).then(res => {
            this.show = true
            if(res.data.code == 200) {
                this.list = res.data.data.courses
                this.totalPage = res.data.data.ext.total
            }
            }).catch(err => {
            this.show = true
            this.$message.error(err.response.data.message)
            })
        },
        changePage(page, pageSize) {
            this.update(page)
        },
        search() {
            this.page = 1
            this.update(this.page)
        },
        go(item, i) {
            if(item.is_member_limit){
                this.visible = true;
                //  this.$confirm({
                //     title: '学员人数以达上限',
                //     cancelText: '',
                //     okText: '确定',
                //     onOk: () => {
                //    return;
                //     },
                //     onCancel() {

                //     },
                // })
                // this.$message.warning('学员人数以达上限')
                return;
            }
            if(item.is_access) {
                this.$store.commit('CLASSID',item.id)
                this.$router.push({
                    path: '/class/catalog',
                    query: {
                        id: item.id,
                    }
                })
              

            } else {
                this.$message.error('学员已禁用，不能访问')
            }
        }
    }
}
</script>
<style scoped>
.classes-bg {
    background: url('../../assets/images/class-bg.png') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
    padding: 40px 0;
}
.inner {
    width: 1200px;
    margin: 0 auto;
}
.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.title-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-width: 200px;
}
.title-wrap img {
    width: 54px;
    height: 54px;
    margin-right: 15px;
}
.title-wrap h3 {
    font-size: 30px;
    margin-bottom: 0;
    background: linear-gradient(to right, #DCFBFE, #6EA2DF);
    -webkit-background-clip: text;
    color: transparent;
}
.search {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    overflow: hidden;
}
.search-msg {
    width: 400px;
    height: 50px;
    border-radius: 0;
}
.search-btn {
    width: 50px;
    height: 50px;
    background: url('../../assets/images/search.png') no-repeat;
    border: none;
    border-radius: 0;
}
.classes-content {
    padding: 30px 0 40px;
    width: 1200px;
    margin: 0 auto;
    min-height: 400px;
    display: flex;
    flex-direction: column;
}
.classes-list {
    padding-bottom: 40px;
    max-width: 1200px;
}
.classes-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 2px;
    overflow: hidden;
    padding: 20px;
    transition: all .5s;
}
.classes-item:hover .classes-go {
    background: #0880F1;
}
.classes-item:last-child {
    margin-bottom: 0;
}
.classes-item .img .classes-sign-ing {
    background: url('../../assets/images/classes-sign-ing.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 111px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img .classes-sign-end {
    background: url('../../assets/images/classes-sign-end.png') no-repeat;
    color: #fff;
    position: absolute;
    left: 0;
    top: 10px;
    width: 88px;
    height: 37px;
    line-height: 37px;
    font-size: 14px;
}
.classes-item .img {
    width: 430px;
    height: 240px;
    margin-right: 20px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
}
.classes-item .img img {
    object-fit: cover;
}
.classes-title {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    line-height: 30px;
    height: 30px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    cursor: pointer;
    margin-top: 10px;
}
.classes-about {
    min-height: 72px;
    font-size: 14px;
    color: #666666;
    line-height: 24px;
    margin-bottom: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.classes-sign {
    color: #FF5E57;
}
.classes-price {
    color: #FF5E57;
    font-size: 20px;
    font-weight: bold;
}
.classes-info {
    height: 240px;
    flex: 1;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.classes-do-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.classes-go {
    width: 86px;
    height: 34px;
    background: #F2F2F2;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .5s;
    overflow: hidden;
    border-radius: 2px;
}
.classes-item:hover {
    box-shadow: 0 0 8px #ccc;
}
.classes-item:hover .classes-go .classes-go-img {
    background: url('../../assets/images/classes-go-hover.png') no-repeat;
}
.classes-go .classes-go-img {
    background: url('../../assets/images/classes-go.png') no-repeat;
    width: 35px;
    height: 10px;
    transition: all .5s;
}
.bot {
    box-shadow: 0 3px 5px #ccc;
}
.bot .first-menus {
    min-height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: all 1s;
    padding-top: 18px;
}
.bot .first-menus li {
    color: #333;
    font-weight: bold;
    font-size: 16px;
    margin-right: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    padding-bottom: 2px;
    border-bottom: 2px solid #fff;
}
.second-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: all 1s;
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
}
.second-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.third-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    transition: 1s all;
    margin-bottom: 20px;
}
.third-menus li {
    padding: 0 10px;
    /* height: 36px; */
    background: #fff;
    color: #333;
    font-size: 14px;
    margin-right: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    line-height: 36px;
    text-align: left;
}
.loadding {
    flex: 1;
}
.ac-bg {
    color: #0880F1!important;
    background: rgba(194,224,252, .5)!important;
    border-radius: 4px;
    font-weight: bold;
}
.ac {
    color: #0880F1!important;
    font-weight: bold;
}
.no {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.no img {
    width: 100px;
    height: 100px;
}
.no p {
    color: #bbb;
}
.menu-more {
    color: #666;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-more img {
    width: 28px;
    height: 26px;
    padding: 5px;
    cursor: pointer;
}
</style>
