<template>
    <div class="link-detail">
        <ul class="detail-wrap">
            <li class="detail-item" v-for="(item, i) in data" :key="i" :style="{flexDirection: item.is_teacher?'row-reverse': ''}">
                <div class="user" :style="item.is_teacher?{marginLeft: '10px'}:{marginRight: '10px'}">
                    <img :src="item.user.avatar" alt="">
                    <span>{{item.is_teacher?'老师': item.user.realname}}</span>
                </div>
                <div class="content">
                    <p>{{item.content}}</p>
                    <span>{{item.created_time}}</span>
                </div>
                
            </li>
            <li v-if="ext && ext.total_pages" @click="more" class="more">点击加载更多...</li>
        </ul>
    </div>    
</template>

<script>
import {
  getThreads
} from '../../http/api'
export default {
    data() {
        return {
            page: 1,
            page_size: 10,
            data: [],
            ext: ''
        }
    },
    props: {
        thread_id: '',
        lesson_id: '',
        course_id: '',
        course_type: '',
    },
    created() {
        this.update()
    },
    methods: {
        update() {
            getThreads({
                thread_id: this.thread_id,
                course_id: this.course_id,
                course_type: this.course_type,
                lesson_id: this.lesson_id,
                page: this.page,
                page_size: this.page_size
            }).then(res => {
                if(res.data.code == 200) {
                    this.ext = res.data.data.ext
                    res.data.data.thread_post.map((item, i) => {
                        this.data.push(item)
                    })
                }
            })
        },
        more() {
            if(this.page < this.ext.total_pages) {
                ++this.page
                this.update()
            }
            
        }
    }
    
}
</script>

<style scoped>
.link-detail {
    width: 50%;
}
.detail-wrap {
    background: #f9f9f9;
    padding: 20px;
    width: 100%;
    border-radius: 6px;
}
.detail-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
}
.detail-item .user img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
    margin-bottom: 8px;
}
.detail-item .user span {
    font-size: 12px;
    line-height: 12px;
}
.detail-item .user {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.detail-item .content {
    background: #eee;
    min-height: 60px;
    flex:1;
    border-radius: 6px;
    padding:10px;
}
.detail-item .content p {
    text-align: left;
    line-height: 22px;
    font-size: 14px;
}
.detail-item .content span {
    font-size: 12px;
    color: #666;
    text-align: left;
    display: block;
}
.more {
    font-size: 12px;
    color: #666;
    cursor: pointer;
}
</style>