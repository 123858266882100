var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "notice",
      style: { width: _vm.width + "px", height: _vm.height + "px" },
    },
    [
      _c("div", { staticClass: "titleWrap" }, [
        _c("p", { staticClass: "title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm.type == "index"
          ? _c(
              "span",
              {
                staticClass: "more",
                on: {
                  click: function ($event) {
                    return _vm.more()
                  },
                },
              },
              [_vm._v("更多")]
            )
          : _vm._e(),
      ]),
      _c(
        "ul",
        { staticClass: "notice-content" },
        [
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.news.length == 0,
                  expression: "news.length == 0",
                },
              ],
            },
            [_vm._v("暂无数据")]
          ),
          _vm._l(_vm.news, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                style:
                  _vm.index == i ? { color: _vm.$store.state.themeColor } : {},
                on: {
                  click: function ($event) {
                    return _vm.go(item, i)
                  },
                  mouseover: function ($event) {
                    return _vm.addColor(item, i)
                  },
                  mouseout: function ($event) {
                    return _vm.removeColor(item, i)
                  },
                },
              },
              [
                _vm.type == "index"
                  ? _c("div", { staticClass: "notice-item-title" }, [
                      _c("span", { staticClass: "icon" }, [_vm._v("NEW")]),
                      _c("p", { staticClass: "articleT" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ])
                  : _vm._e(),
                _vm.type != "index"
                  ? _c("p", { staticClass: "articleT" }, [
                      _vm._v(_vm._s(item.title)),
                    ])
                  : _vm._e(),
                _c("span", [_vm._v(_vm._s(item.created_at.split(" ")[0]))]),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }