var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "listModule" },
    [
      _c(
        "div",
        {
          staticClass: "title-bg",
          class: { newbg: _vm.$store.state.tenant_idBind == 3462 },
        },
        [
          _c("div", { staticClass: "title-inner" }, [
            _c("img", {
              attrs: {
                src: require("../../assets/images/notice-icon.png"),
                alt: "",
              },
            }),
            _c("h3", [_vm._v(_vm._s(_vm.titleMsg()))]),
          ]),
        ]
      ),
      _c("div", { staticClass: "news-tab-wrap" }, [
        _c(
          "ul",
          { staticClass: "news-tab" },
          _vm._l(_vm.menus, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                style:
                  _vm.index == item.id || _vm.$route.query.title == item.name
                    ? {
                        color: _vm.$store.state.themeColor,
                        borderColor: _vm.$store.state.themeColor,
                      }
                    : {},
                on: {
                  click: function ($event) {
                    return _vm.checkTab(item, i)
                  },
                },
              },
              [_vm._v(_vm._s(item.name))]
            )
          }),
          0
        ),
      ]),
      _c("transition", { attrs: { name: "fades" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
          },
          [
            _vm.list.length != 0
              ? _c(
                  "div",
                  { staticClass: "content" },
                  [
                    _c(
                      "ul",
                      { staticClass: "list" },
                      _vm._l(_vm.list, function (item, i) {
                        return _c(
                          "li",
                          {
                            key: i,
                            on: {
                              click: function ($event) {
                                return _vm.go(item, i)
                              },
                            },
                          },
                          [
                            _c("img", { attrs: { src: item.cover, alt: "" } }),
                            _c("div", { staticClass: "text-info" }, [
                              _c("p", { staticClass: "title" }, [
                                _vm._v(_vm._s(item.title)),
                              ]),
                              _c("span", { staticClass: "date" }, [
                                _vm._v(_vm._s(item.created_at.split(" ")[0])),
                              ]),
                              _c("p", { staticClass: "describe" }, [
                                _vm._v(_vm._s(item.infos)),
                              ]),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.total > 10
                      ? _c("a-pagination", {
                          attrs: {
                            defaultPageSize: _vm.pageSize,
                            total: _vm.total,
                          },
                          on: { change: _vm.changePage },
                          model: {
                            value: _vm.page,
                            callback: function ($$v) {
                              _vm.page = $$v
                            },
                            expression: "page",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _c("div", { staticClass: "no" }, [
                  _c("img", {
                    attrs: {
                      src: require("../../assets/images/no.png"),
                      alt: "",
                    },
                  }),
                  _c("p", [_vm._v("暂无数据")]),
                ]),
          ]
        ),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "skeleton",
        },
        _vm._l(4, function (item, i) {
          return _c("a-skeleton", { key: i, attrs: { active: "" } })
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }