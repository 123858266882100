var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "study-detail" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.show,
              expression: "!show",
            },
          ],
          staticClass: "loadding",
        },
        [_c("a-spin")],
        1
      ),
      _c("transition", { attrs: { name: "fades" } }, [
        _vm.show
          ? _c(
              "div",
              [
                _vm.$store.state.template == 1
                  ? _c(
                      "a-breadcrumb",
                      [
                        _c(
                          "a-breadcrumb-item",
                          [
                            _c("router-link", { attrs: { to: "/course" } }, [
                              _vm._v("学习广场"),
                            ]),
                          ],
                          1
                        ),
                        _c("a-breadcrumb-item", [
                          _vm._v(_vm._s(_vm.course.title)),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.$store.state.template == 2 || _vm.$store.state.template == 3
                  ? _c("div", { staticStyle: { height: "30px" } })
                  : _vm._e(),
                _c("div", { staticClass: "classesWrap" }, [
                  _c("div", { staticClass: "img" }, [
                    _c("img", {
                      staticClass: "img_a",
                      attrs: { src: _vm.course.picture, alt: "" },
                    }),
                  ]),
                  _c("div", { staticClass: "info" }, [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.course.title)),
                    ]),
                    _c("p", { staticClass: "txt" }, [
                      _vm._v(_vm._s(_vm.course.infos)),
                    ]),
                    _vm.$store.state.can_pay == 1 &&
                    _vm.course.order_status == 0
                      ? _c("div", { staticClass: "price-info" }, [
                          _c("span", [_vm._v("价格")]),
                          _c("span", { staticClass: "red" }, [
                            _vm._v(
                              "￥" +
                                _vm._s(
                                  _vm.course.price > 0
                                    ? _vm.course.price
                                    : "免费"
                                )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "go-btn-wrap" },
                      [
                        _vm.course.type != "live" &&
                        _vm.course.order_status == 1
                          ? _c(
                              "a-button",
                              {
                                style: {
                                  background: _vm.studyBtnState(
                                    _vm.course.learn_status
                                  ).bgColor,
                                  color: _vm.studyBtnState(
                                    _vm.course.learn_status
                                  ).color,
                                  borderColor: _vm.studyBtnState(
                                    _vm.course.learn_status
                                  ).bdColor,
                                },
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    _vm.go(
                                      _vm.course,
                                      _vm.studyBtnState(_vm.course.learn_status)
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.studyBtnState(_vm.course.learn_status)
                                      .msg
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.$store.state.can_pay == 1 &&
                        _vm.course.order_status == 0 &&
                        _vm.course.price > 0
                          ? _c(
                              "a-button",
                              {
                                style: {
                                  background: "#FF8611",
                                  color: "#fff",
                                  border: "none",
                                },
                                attrs: { size: "large" },
                                on: { click: _vm.buy },
                              },
                              [_vm._v("立即购买")]
                            )
                          : _vm._e(),
                        _vm.tryBtnStatus()
                          ? _c(
                              "a-button",
                              {
                                style: {
                                  background: _vm.$store.state.themeColor,
                                  color: "#fff",
                                  borderColor: "none",
                                  marginLeft: "10px",
                                },
                                attrs: { size: "large" },
                                on: {
                                  click: function ($event) {
                                    return _vm.go(_vm.course)
                                  },
                                },
                              },
                              [_vm._v("免费试看")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm.course.learn_status == 1
                      ? _c("p", { staticStyle: { padding: "10px 0" } }, [
                          _vm._v(
                            "已学：" + _vm._s(_vm.course.gain_credit) + "课时"
                          ),
                        ])
                      : _vm._e(),
                    _vm.$store.state.open_share == 1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%",
                              display: "flex",
                              "flex-direction": "column",
                              "align-items": "flex-end",
                              "padding-top": "20px",
                            },
                          },
                          [_c("Share", { attrs: { imgData: _vm.share_img } })],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm.show
                  ? _c("Common", {
                      attrs: {
                        exams: _vm.exams,
                        course: _vm.course,
                        lessons: _vm.lessons,
                        news: _vm.news,
                        relate_courses: _vm.relate_courses,
                        course_wares: _vm.course_wares,
                        rate: _vm.rate,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }