<template>
  <div class="">
    <div class="loadding" v-show="!show">
      <a-spin />
    </div>
    <transition name="fades">
      <div class="personal" v-show="show" style="width: 100%">

    <div class="userInfo" v-if="$store.state.template == 1">
      <div class="inner">
        <div class="pho">
          <img class="objcover" :src="$store.state.avatar" alt="">
        </div>
        <span class="tel">{{userInfo.realname}}</span>
        <a-button @click="sign()" class="btn">{{signMsg}}</a-button>
        <div
          v-if="$store.state.is_show_peroid"
          class="classTime"
        >获得学时&nbsp;<span>{{userInfo.credit}}</span></div>
      </div>
    </div>

    <div v-if="$store.state.template == 2 || $store.state.template == 3" class="tem-two-user-info" :class="{'newbg':$store.state.tenant_idBind==3462}">
      <div class="tem-two-user-info-inner">
        <!-- <img style="width: 360px;height: 40px;margin-right: 250px;" src="../../assets/images/bg_txt.png" alt=""> -->
        <a-button @click="sign()" class="btn">{{signMsg}}</a-button>
      </div>
    </div>

    <div class="personalWrap">
      <div class="sidebar" :style="{paddingTop: $store.state.template == 2 || $store.state.template == 3?'180px': ''}">
        <div @click="checkMethod(item, i)" class="item" :style="i == index?{color: '#fff', background: $store.state.themeColor,backgroundImage: `linear-gradient(to right, #fff , ${$store.state.themeColor})`}: {}" v-for="(item, i) in menus" :key="i">
          <a-icon class="aIcon" :type="item.icon" />
          <span>{{item.name}}</span>
        </div>

        <div class="tem-two-user" v-if="$store.state.template == 2 || $store.state.template == 3">
          <div class="tem-two-user-inner">
            <div class="pho-wrap">
              <img :src="$store.state.avatar" alt="">
              <span v-show="changeVisible" class="changeColor">修改头像</span>
              <input id="up" type="file" class="up" @change="upImg" ref="inputer" @mouseover="showChange" @mouseout="outChange" title="">
            </div>
            
            <p class="tem-two-name">{{$store.state.realName}}</p>
            <a-button @click="syncPro" size="large" 
            :style="{
              background: $store.state.themeColor,
              color: '#fff!important',
              fontSize: '14px',
              marginBottom: '10px'
            }">同步学习进度</a-button>
            <p class="tem-two-num">获得学时&nbsp;{{userInfo.credit}}</p>
            <a-dropdown :trigger="['click']" style="margin-bottom: 10px;">
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                {{$store.state.currentTenantName}}<a-icon type="down" />
              </a>
              <a-menu slot="overlay">
                <a-menu-item key="0" v-for="(item, i) in tenants" :key="i" @click="checkEnterprise(item, i)">
                  <div class="item-wrap">
                    <span style="margin-right: 50px;">{{item.tenant_name}}{{i == 0? '': '-'}}{{item.platform_name}}</span>
                    <span v-if="i != 0" @click.stop="unbundling(item, i)" style="color: #888;"><img style="width: 14px;height: 14px;" src="../../assets/images/unbind.png" alt=""></span>
                  </div>
                 
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          
        </div>
      </div>

      <router-view v-if="show" class="content" :data="userInfo"/>
    </div>
    </div>
    </transition>

    <a-modal 
    v-model="visible"
    title="头像裁剪"
    :footer="null"
    width="550px"
    :maskClosable="false"
    :bodyStyle="{
      width: '550px',
      height: '550px',
      display: 'flex',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
      }">
      <div style="width: 500px; height: 500px; margin-bottom: 20px">
        <vueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.size"
          :outputType="option.outputType"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixedNumber="option.fixedNumber"
          :fixed="option.fixed"
          :autoCrop="true"
        ></vueCropper>
      </div>
      <div>
        <div style="display: flex;">
          <a-button size="large" @click="save()" :style="{background: $store.state.themeColor, marginRight: '20px', color: '#fff!important'}">保存</a-button>
          <label for="up" style="padding: 0 15px; border-radius: 6px; height: 40px; background: #eee; display: block; cursor: pointer; line-height: 40px; text-align: center;">重新上传</label>
        </div>
      </div>
    </a-modal>
    
    <a-modal v-model="visibleSync" width="380px" :centered="true" :footer="null" :title="null">
      <div class="txt-wrap">
        <p class="sync-txt">是否把您在爱迪云学院的学习记录同步至该企业？</p>
        <p class="sync-sub"><span style="color: red;">*</span>仅会同步相同课程的学习记录</p>
      </div>
      <div class="do-some">
        <a-button 
          @click="cancel"
          :style="{
            color: $store.state.themeColor+'!important',
            border: '1px solid'+ $store.state.themeColor+'!important',
            marginRight: '20px'
          }">放弃</a-button>
        <a-button 
          @click="ok"
          :loading="btnLoading"
          :style="{
            background: $store.state.themeColor,
            color: '#fff!important'
          }">确定</a-button>
      </div>
    </a-modal>

    <a-modal v-model="visibleDeal" width="380px" :centered="true" :footer="null" :title="null">
      <div class="txt-wrap">
        <p class="sync-txt">解除绑定后，您将无法登录该企业平台进行学习，是否立刻解除绑定？</p>
        <p class="sync-sub"><span style="color: red;">*</span>已购买、已学习的课程，以及学习记录等数据不受影响</p>
      </div>
      <div class="do-some">
        <a-button 
          :loading="btnLoading"
          @click="dealOk"
          :style="{
            color: $store.state.themeColor+'!important',
            border: '1px solid'+ $store.state.themeColor+'!important',
            marginRight: '20px'
          }">解除绑定</a-button>
        <a-button 
          @click="dealCancel"
          :style="{
            background: $store.state.themeColor,
            color: '#fff!important'
          }">再想想</a-button>
      </div>
    </a-modal>
    
    
  </div>
</template>

<script>
import {
  userInfo,
  userSign,
  setAvatar,
  getTenants,
  syncProgress,
  dealTenant
} from '../../http/api'
import { VueCropper }  from 'vue-cropper' 
export default {
    data() {
        return {
          changeVisible: false,
          visible: false,
          hostname: '',
          show: false,
          index: 0,
          signMsg: '',
          userInfo: {},
          menus: [
           
            {
              name: '学习档案',
              path: '/user/archives',
              icon: 'audit'
            },
            // {
            //   name: '班级计划',
            //   path: '/user/myclass',
            //   icon: 'book'
            // },
            {
              name: '资料设置',
              path: '/user/setuser',
              icon: 'container'
            },
            {
              name: '密码重置',
              path: '/user/setpassword',
              icon: 'lock'
            },
            // {
            //   name: '我的提问',
            //   path: '/user/question',
            //   icon: 'question'
            // }
          ],
          option: {
            img: '',
            size: 1,
            outputType: 'jpeg',
            fixed: true,
            autoCropWidth: 250,
            autoCropHeight: 250,
            fixedNumber: [1, 1]
          },
          tenants: [
            {
              platform_name: '',
              platform_id: 0,
              tenant_name: '显示所有企业学习数据',
              tenant_id: 0
            }
          ],
          visibleSync: false,
          btnLoading: false,
          visibleDeal: false,
          platform_id: '',
          tenant_id: ''
        }
    },
    components: {
      VueCropper
    },
    watch: {
      '$route.path'(n, o) {
        if(n == '/user/mycourse') {
          if(this.$store.state.can_class == 1) {
            this.index = 1
          }else{
            this.index = 0
          }
        }
        if(n == '/user/myclass') {
          if(this.$store.state.can_class == 1) {
            this.index = 0
          }else{
            this.index = 1
          }
        }
      }
    },
    created() {
      this.hostname = window.location.hostname
      this.getUserInfo()
      if(this.$store.state.can_class == 1) {
        
        if(this.hostname == 'bjadks.zjttv.cn' || this.hostname == 'bjadks.lllnet.cn') {
          this.menus.push({
              name: '培训企业',
              path: '/user/myclass',
              icon: 'book'
          })
        } else {
          this.menus.unshift({
              name: '班级计划',
              path: '/user/myclass',
              icon: 'book'
          })
        }
      }else{
         this.menus.unshift({
              name: '我的课程',
              path: '/user/mycourse',
              icon: 'read'
            })
      }
      if(this.$store.state.can_pdf_study == 1) {
        this.menus.push({
            name: '学习证明',
            path: '/user/testify',
            icon: 'file-pdf'
        })
      }
      if(this.$store.state.can_pay == 1) {
        this.menus.push({
          name: '我的订单',
          path: '/user/orders',
          icon: 'account-book'
        })
      }
      if(this.hostname == 'xian.zjttv.cn' || this.hostname == 'sxbddc.zjttv.cn') {
        this.menus = [
            {
              name: '资料设置',
              path: '/user/setuser',
              icon: 'container'
            },
            {
              name: '密码重置',
              path: '/user/setpassword',
              icon: 'lock'
            }
          ]
      }
    },
    mounted() {
      if(!this.$store.state.currentTenantName) {
        this.$store.commit("SETCURRENTNAME", '显示所有企业学习数据')
        this.$store.commit("SWITCHINGENTERPRISES", {
          product_id: 0,
          tenant_id: 0
        })
      }
      if(this.userInfo.is_sign == 0) {
        this.signMsg = '签到'
      } else {
        this.signMsg = '已签到'
      }
      this.menus.map((item, i) => {
        if(this.index==i){
          this.checkMethod(item,i)
        }
        if(this.$route.path == item.path) {
          this.index = i
        } else if (this.$route.path == '/user/orderdetail') {
          this.menus.map((item, i) => {
            if(item.path == '/user/orders') {
              this.index = i
            }
          })
        } 
        else if(this.$route.path == '/user/have_class_hours' || this.$route.path == '/user/signdata' || this.$route.path == '/user/exam_pass_number' || this.$route.path == '/user/obtained_number') {
          this.menus.map((item, i) => {
            if(item.path == '/user/archives') {
              this.index = i
            }
          })
        }
      })
      getTenants().then(res => {
        if(res.data.code == 200) {
          res.data.data.map((item ,i) => {
            this.tenants.push(item)
            if(item.tenant_id==this.$store.state.tenant_idBind){
              console.log('item.tenant_id',item.tenant_id)
              this.checkEnterprise(item,i+1)
            }
          })
        }
      })
    },
    methods: {
      unbundling(item, i) {
        this.tenant_id = item.tenant_id
        this.platform_id = item.platform_id
        this.visibleDeal = true
      },
      dealOk() {
        this.btnLoading = true
        dealTenant({
          tenant_id: this.tenant_id,
          platform_id: this.platform_id,
          type: 1
        }).then(res => {
          this.btnLoading = false
          if(res.data.code == 200) {
            this.btnLoading = false
            this.$message.success(res.data.message)
            setTimeout(() => {
              this.visibleDeal = false
            }, 800)
            getTenants().then(res => {
              if(res.data.code == 200) {
                this.tenants = [
                  {
                    platform_name: '',
                    platform_id: 0,
                    tenant_name: '显示所有企业学习数据',
                    tenant_id: 0
                  }
                ]
                res.data.data.map((item ,i) => {
                  this.tenants.push(item)
                })
              }
            })
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.btnLoading = false
        })
      },
      dealCancel() {
        this.visibleDeal = false
      },
      syncPro() {
        this.visibleSync = true
      },
      ok() {
        this.btnLoading = true
        syncProgress().then(res => {
          this.btnLoading = false
          if(res.data.code == 200) {
            this.$message.success(res.data.message)
            setTimeout(() => {
              this.visibleSync = false
            }, 800)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          this.btnLoading = false
        })
      },
      cancel() {
        this.visibleSync = false
      },
      checkEnterprise(item, i) {
        let icon = i == 0? '': '-'
        this.$store.commit("LISTENENTERPRISE", item.platform_id.toString() + item.tenant_id.toString())
        if(i == 0) {
          this.$store.commit("SETCURRENTNAME", '显示所有企业学习数据')
          this.$store.commit("SWITCHINGENTERPRISES", {
            product_id: 0,
            tenant_id: 0
          })
        } else {
          this.$store.commit("SETCURRENTNAME", item.tenant_name + icon +item.platform_name)
          this.$store.commit("SWITCHINGENTERPRISES", {
            product_id: item.platform_id,
            tenant_id: item.tenant_id
          })
        }
        // console.info(this.$store.state.tenant_id)
        // console.info(this.$store.state.product_id)

      },
      showChange() {
        this.changeVisible = true
      },
      outChange() {
        this.changeVisible = false
      },
      upImg() {
        let inputDOM = this.$refs.inputer
        let file = inputDOM.files
        // console.info(file[0])
        let reader = new FileReader()
        reader.readAsDataURL(file[0])
        reader.onload = () => {
          this.option.img = reader.result
          this.visible = true
        }
      },
      save() {
        this.$refs.cropper.getCropData((data) => {
          setAvatar({
            image: data
          }).then(res => {
            if(res.data.code == 200) {
              this.visible = false
              this.$store.commit("SETAVATAR", data)
            } else {
              this.$message.error(res.data.message)
            }
          })
        })
      },
      getUserInfo() {
        userInfo().then(res => {
          this.userInfo = res.data.data
          this.$store.commit("SETAVATAR", res.data.data.avatar)
          this.$store.commit("SETREALNAME", res.data.data.realname)
          this.show = true
          if(this.userInfo.is_sign == 0) {
            this.signMsg = '签到'
          } else {
            this.signMsg = '已签到'
          }
        }).catch(err => {

        })
      },
      sign() {
        if(this.userInfo.is_sign == 0) {
          userSign({}).then(res => {
          if(res.data.code == 200) {
              this.$message.success(res.data.message)
              this.getUserInfo()
              // this.signMsg = '已签到'
            }
          })
        }

      },
      checkMethod(item, i) {
        console.log('item',item)
        if(item.path != this.$route.path) {
          this.index = i
          this.$router.push(item.path)
        }
      }
    }
}
</script>

<style scoped>
.objcover {
  object-fit: cover;
}
button {
  color: #333!important;
  border: 1px solid #fff!important;
}
.aIcon {
  font-size: 18px;
  margin-right: 10px;
}
.pho {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  overflow: hidden;
  margin-right: 20px;
  border: 2px solid #fff;
}
.tel {
  font-weight: bold;
  color: #fff;
  font-size: 20px;
  margin-right: 80px;
}
.classTime {
  color: #fff;
  font-size: 20px;
}
.classTime span {
  font-weight: bold;
}
.btn {
  /* margin-right: 80px; */
  width: 100px;
  height: 40px;
}
.inner {
  width: 1200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 50px;
}
.userInfo {
  width: 100%;
  height: 180px;
  background: url('../../assets/images/personBg.png');
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.sidebar .item {
  height: 36px;
  line-height: 36px;
  width: 100%;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar {
  width: 200px;
  display: flex;
  flex-flow: column;
  align-items: center;
  border: 1px solid #eee;
  margin-right: 20px;
  min-height: 500px;
  padding:20px 0;
  border-radius: 4px;
  position: relative;
}
.content {
  flex: 1;
  width: 100%;
}
.personalWrap {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto 20px;
}
.personal {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .userInfo {
    height: auto;
    padding: 5px 0;
  }
  .inner {
    width: 100%;
    flex-direction: column;
    padding: 10px 0;
  }
  .pho {
    width: 70px;
    height: 70px;
    margin-right: 0;
    margin-bottom: 5px;
  }
  .tel {
    margin-right: 0;
    font-size: 16px;
    margin-bottom: 5px;
  }
  .btn {
    margin-right: 0;
    margin-bottom: 5px;
  }
  .classTime {
    font-size: 16px;
  }
  .personalWrap {
    width: 100%;
    flex-direction: column;
  }
  .sidebar {
    margin-right: 0;
    width: 100%;
    min-height: auto;
    padding: 0;
    margin-bottom: 20px;
  }
}
.tem-two-user-info {
  width: 100%;
  height: 130px;
  background: url('../../assets/images/title-bg.png') no-repeat;
  background-size: cover;
  margin-bottom: 20px;
}
.newbg{
    background: url('../../assets/images/class_newbg.jpg') no-repeat;
    background-size: cover;
}
.tem-two-user {
  width: 200px;
  background: #fff;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: -105px;
}
.tem-two-user-inner {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  border-bottom: 1px solid #D3D3D3;
  color: #333;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tem-two-user-inner img {
  width: 80px;
  height: 80px;
  border-radius: 2px;
  object-fit: cover;
}
.tem-two-user-inner .tem-two-name {
  font-size: 24px;
  margin-bottom: 10px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.tem-two-user-inner .tem-two-num {
  font-size: 14px;
  margin-bottom: 10px;
}
.tem-two-user-info-inner {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-left: 220px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 25px;
}
.changeColor {
  display: block;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  background: rgba(0,0,0,0.7);
  color: #fff;
  font-size: 12px;
  position: absolute;
  top: 54px;
  left: 0;
}
.pho-wrap {
  width: 80px;
  height: 80px;
  position: relative;
  border: 1px solid #eee;
  margin-bottom: 20px;
  box-sizing: border-box;
  overflow: hidden;
}
.up {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  cursor: pointer;
  opacity: 0;
}
/* modal */
.do-some {
  display: flex;
  justify-content: center;
  align-items: center;
}
.txt-wrap {
  padding: 20px 0;
}
.sync-txt {
  font-weight: bold;
}
.sync-sub {
  font-size: 12px;
  color: #888;
}



.item-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
